/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Grid
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import CallReceivedOutlinedIcon from '@mui/icons-material/CallReceivedOutlined';
import { ReceiverIncomingSummary } from './ReceiverIncomingSummary';
import { getLocalData } from '../../../utils';
import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const InventoryAlerts = ({ showAlert }) => {
  const navigate = useNavigate();

  // hook
  useEffect(() => {
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }

    // Now get the alerts
    getAllAlerts();
  }, []);

  // let userId = sessionStorage.getItem("UserId");

  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [activeAlertList, SetActiveAlertList] = useState([]);
  const [mutedAlertList, SetMutedAlertList] = useState([]);

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId = getLocalData('locationId');
  let encodedLocationId = encodeURIComponent(locationId);
  const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;
  const userId = sessionStorage.getItem('UserId');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilters, setIsFilters] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [allInventoryData, setAllInventoryData] = useState([]);
  const [allLocationsList, setAllLocationsList] = useState([]);
  const [transactionId, setTransactionId] = useState('');
  const [quantity, setQuantity] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');

  // get user role and provide access of that roles
  const franchiseeId = getLocalData('franchiseeId');
  const locationName = getLocalData('locationName');
  // const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    isActive: null,
    price: null,
    categoryName: null,
    sku: null
  });

  const [transactionDetails, setTransactionDetails] = useState({
    fromLocationId: '',
    toLocationId: '',
    fromLocationName: '',
    toLocationName: '',
    notes: '',
    createdBy: ''
  });

  const [transferDetails, setTransferDetails] = useState({
    fromLocationId: '',
    toLocationId: '',
    fromLocationName: '',
    toLocationName: '',
    notes: '',
    createdBy: userId,
    transactionId: ''
  });

  const [isError, setIsError] = useState({
    toLocationId: false,
    fromLocationId: false,
    notes: false
  });

  const handleTransferDetails = (type, value) => {
    setTransferDetails({ ...transferDetails, [type]: value });
    setIsError({ ...isError, [type]: false });
  };

  function defaultQuantity() {
    const defaultQuantities = {};
    inventoryData.forEach((item) => {
      // defaultQuantities[item.id] = 0;
      defaultQuantities[item.sortKey] = 0;
    });

    return defaultQuantities;
  }

  useEffect(() => {
    setQuantity(defaultQuantity());
  }, [inventoryData]);

  useEffect(() => {
    const data = allInventoryData?.filter((item) =>
      selectedRows?.includes?.(item.id)
    );
    setSelectedRowsData(data);
  }, [selectedRows]);

  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setSelectedRows([]);
      setSelectedRowsData([]);
      setQuantity(defaultQuantity());
      // setNotesData('');
      // setSelectedReason('Breakage/Waste');
      setIsInitialState(false);
      setBtnLoading(false);
      // now close the popup
      handleClose();
    }
  }, [showScreen, isInitialState]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const handleResetStates = () => {
    setQuantity(defaultQuantity());
    setSelectedRows([]);
    setSelectedRowsData([]);
    setIsFilters(false);
    setSearchQuery('');
    setIsInitialState(true);
    setShowScreen(1);

    setTransferDetails({
      fromLocationId: '',
      toLocationId: '',
      fromLocationName: '',
      toLocationName: '',
      notes: '',
      createdBy: ''
    });

    setTransactionDetails({
      fromLocationId: '',
      toLocationId: '',
      fromLocationName: '',
      toLocationName: '',
      notes: '',
      createdBy: ''
    });

    setIsError({
      toLocationId: false,
      fromLocationId: false,
      notes: false
    });

    setFilters({
      isActive: null,
      price: null,
      categoryName: null,
      sku: null
    });
  };

  function checkAllSelectedQuantity() {
    return selectedRows.every((itemId) => quantity[itemId] > 0);
  }

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleTransferSubmit = (status) => {
    if (transferDetails.toLocationId === '') {
      setIsError({
        ...isError,
        toLocationId: true
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (transferDetails.fromLocationId === '') {
      setIsError({
        ...isError,
        fromLocationId: true
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (transferDetails.notes === '') {
      setIsError({
        ...isError,
        notes: true
      });
    } else if (!selectedRows?.length) {
      setShowMessage(true);
      setErrorMessage('Please select atleast one inventory row');
    } else if (!checkAllSelectedQuantity()) {
      setShowMessage(true);
      setErrorMessage(
        'Please add a minimum quantity of 1 to all selected rows.'
      );
    } else {
      if (status === 'Draft') {
        postData('draft');
      } else if (status === 'Proceed') {
        // postData('proceed');
        setShowScreen(2);
      }

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  function isAnyFilterApplied() {
    return Object.values(filters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '';
    });
  }

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  function formatProductsArray(originalArray) {
    const convertedArray = originalArray.map((item) => {
      const obj = {};
      const keys = Object.keys(item);
      keys.forEach((key) => {
        const innerObj = item[key];
        const innerKeys = Object.keys(innerObj);
        innerKeys.forEach((innerKey) => {
          obj[innerKey] = innerObj[innerKey];
        });
      });
      return obj;
    });
  }

  useEffect(() => {}, [selectedRows, selectedRowsData]);

  useEffect(() => {}, [quantity]);

  function setSelectedProductRows(items) {
    // let array = formatProductsArray(items);
    let array = items;

    // if (!Array.isArray(array) || !array.length) {
    if (!array.length) {
      return false;
    }
    const idsToUpdate = array?.map(({ itemId }) => itemId);
    const updatedStateObject = { ...quantity };
    idsToUpdate.forEach((id) => {
      const matchingItem = array.find((item) => item.itemId === id);
      if (
        matchingItem
        // && Object.prototype.hasOwnProperty.call(updatedStateObject, id)
      ) {
        updatedStateObject[id] = matchingItem.quantity;
      }
    });

    setSelectedRows(idsToUpdate);
    setQuantity(updatedStateObject);

    return true;
  }

  function setAllPrefilledValues(data) {
    // let data
    data = data[0];
    if (
      data.transactionItems ||
      !data.fromLocationId ||
      !data.fromLocationName ||
      !data.toLocationId ||
      !data.toLocationName
    ) {
      // console.log('data: ', JSON.parse(data.transactionItems));
      // console.log('data: ', data);
      // let transactionId = searchParams.get("transactionId");
      // let encodetransactionId = transactionId ? encodeURIComponent(transactionId) : null;

      let details = {
        fromLocationId: data.fromLocationId,
        toLocationId: data.toLocationId,
        toLocationName: data.toLocationName,
        fromLocationName: data.fromLocationName,
        notes: data.notes,
        createdBy: data.createdBy,
        transactionId: data.transactionId
      };

      let locationsList = [
        {
          label: data.fromLocationName,
          // label: data.fromLocationId,
          value: data.fromLocationId
        },
        {
          label: data.toLocationName,
          // label: data.toLocationId,
          value: data.toLocationId
        }
      ];

      // setSelectedProductRows(JSON.parse(data.transactionItems));
      // setSelectedProductRows(data.transactionItems);
      setTimeout(() => {
        setSelectedProductRows(data.transactionItems);
        setIsLoading(false);
      }, 500);
      setTransactionDetails(details);
      setTransferDetails(details);
      setAllLocationsList(locationsList);
      setShowScreen(2);
      setLoading(false);
    } else {
      setShowMessage(true);
      setErrorMessage('Missing some values in response');
      setIsLoading(false);
      // fetchData()
    }
  }

  // Now fetch the transaction details
  const fetchData = async (transactionId) => {
    setIsLoading(true);
    setLoading(true);
    // for now we are using hardcoded transaction id, but when this page will access from notifications,
    // you have to pass any transactionId in params. like (http://localhost:3000/inventory-revise?transactionId=123123)
    // we will pick that id from params using parms fro react router, then will pass in this api as dynamic
    // let transactionId = '%23TRN%232QHrAjkn85jKspqhBApyNsj7O10';

    // let transactionId = searchParams.get("transactionId");

    // let transactionId = "2Rk5NE0uWAIjNp4bwJrEfWYEEqO";
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);

    try {
      // let transactionId = searchParams.get("transactionId");

      // check if franchisee id is available and it has a #FR# then do no attach #FR# again
      if (
        transactionId &&
        !transactionId.includes('#TRN#') &&
        transactionId !== 'undefined'
      ) {
        transactionId = '#TRN#' + transactionId;
      }
      let encodetransactionId = transactionId
        ? encodeURIComponent(transactionId)
        : null;

      setTransactionId(transactionId);
      const transactionResponse = await fetch(
        `${BASE_URL}inventory/getTransaction?locationId=${encodedLocationId}&transactionId=${encodetransactionId}`
      );

      const inventoryResponse = await fetch(
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1`
      );
      const locationsResponse = await fetch(
        `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`
      );

      const transactionData = await transactionResponse.json();
      const inventoryData = await inventoryResponse.json();
      const locationsData = await locationsResponse.json();

      if (!inventoryData.error) {
        const modifiedInventoryData = inventoryData?.data?.Items?.map(
          (item) => ({
            ...item,
            // id: item.PRDSort
            id: item.sortKey
          })
        );
        setAllInventoryData(modifiedInventoryData);
      } else {
        setShowMessage(true);
        setErrorMessage(
          inventoryData.message || 'Something went wrong with inventory'
        );
        setIsLoading(false);
      }

      if (!transactionData.error) {
        let data = transactionData.data.Items;
        setAllPrefilledValues(data);
      } else {
        setShowMessage(true);
        setErrorMessage(
          transactionData.message ||
            'Something went wrong with revise inventory'
        );
        setIsLoading(false);
      }

      if (!locationsData.error) {
        const modifiedInventoryData = locationsData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.locationId
          })
        );
        setAllLocationsList(modifiedInventoryData);
      } else {
        setShowMessage(true);
        setErrorMessage(
          locationsData.message || 'Something went wrong with locations'
        );
        setIsLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setShowMessage(true);
      setErrorMessage('Internal Server Error -- Please try again later');
      setIsLoading(false);
    }
  };

  const getFilteredInventory = async () => {
    let queryString = filtersQuery();
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      const inventoryResponse = await fetch(
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1&${queryString}`
      );
      const inventoryData = await inventoryResponse.json();

      if (!inventoryData.error) {
        const modifiedInventoryData = inventoryData?.data?.Items?.map(
          (item) => ({
            ...item,
            // id: item.PRDSort
            id: item.sortKey
          })
        );
        setInventoryData(modifiedInventoryData);
      } else {
        setShowMessage(true);
        setErrorMessage(
          inventoryData.message || 'Something went wrong with inventory'
        );
      }
    } catch (error) {
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  useEffect(() => {
    getFilteredInventory();
  }, [JSON.stringify(filters)]);

  // useEffect(() => {
  //   fetchData();
  // }, [searchParams]);

  function findLocationName(id) {
    // console.log(allLocationsList, id, "find location")
    const foundLocation = allLocationsList?.find(
      (location) => location.locationId === id
    );

    if (foundLocation) {
      return foundLocation.locationName;
    }

    return ''; // Return an empty string if no supplier with the given id is found
  }

  function findLocationAddress(id) {
    // console.log(allLocationsList, id, "find location")
    const foundLocation = allLocationsList?.find(
      (location) => location.locationId === id
    );

    if (foundLocation) {
      return foundLocation.addressLine1;
    }

    return ''; // Return an empty string if no supplier with the given id is found
  }

  // const calculateTotalPrice = () => {
  //   const totalPrice = selectedRowsData?.reduce((total, obj) => {
  //     const price = parseFloat(obj.price) || 0;
  //     return total + price;
  //   }, 0);

  //   return totalPrice?.toFixed(2);
  // };
  const calculateTotalPrice = () => {
    const totalPrice = selectedRowsData?.reduce((total, obj) => {
      const price = parseFloat(obj.price) || 0;
      // const quan = quantity[obj.id] || 0;
      const quan = quantity[obj.sortKey] || 0;
      return total + price * quan;
    }, 0);
    return totalPrice?.toFixed(2);
  };

  const postData = async (status) => {
    setBtnLoading(true);
    let idss = [];
    let itemsData = [];

    let orderStatus = status;

    selectedRowsData?.map((item) => {
      const quantityValue = quantity[item.sortKey]; // Get the corresponding quantity for the item
      idss.push({ itemId: item.sortKey });
      itemsData.push({
        itemId: item.sortKey,
        quantity: quantityValue,
        category: item?.categoryName,
        itemName: item?.fName
      });

      return null;
    });

    let data = {};
    let url = '';
    // let transactionId = searchParams.get("transactionId");
    // check if franchisee id is available and it has a #FR# then do no attach #FR# again
    if (
      transactionId &&
      !transactionId.includes('#TRN#') &&
      transactionId !== 'undefined'
    ) {
      let traId = '#TRN#' + transactionId;
      setTransactionId(traId);
    }
    // let encodetransactionId = transactionId ? encodeURIComponent(transactionId) : null;
    if (orderStatus === 'draft') {
      data = {
        notes: transferDetails.notes,
        totalCost: calculateTotalPrice(),
        numberOfItems: selectedRows.length,
        items: itemsData,
        itemIds: idss,
        fromLocationId: transferDetails.fromLocationId,
        fromLocationName: transferDetails.fromLocationName,
        toLocationId: transferDetails.toLocationId,
        toLocationName: transferDetails.toLocationName,
        createdBy: userId,
        transactionId: transactionId,
        transactionStatus: orderStatus,
        transferAddress: transferDetails.transferAddress
      };
      url = `${BASE_URL}inventory/updateDraftTransaction`;
    } else {
      data = {
        fromLocationId: transferDetails.fromLocationId,
        toLocationId: transferDetails.toLocationId,
        transactionId: transactionId,
        createdBy: userId
      };

      url = `${BASE_URL}inventory/receiveTransaction`;
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    try {
      const response = await fetch(
        // `${BASE_URL}/inventory/updateDraftTransaction`,
        `${url}`,
        options
      );
      const responseData = await response.json();
      if (!responseData.error) {
        // setIsInitialState(true);

        setShowScreen(1);
        // setShowMessage(true);
        // setErrorMessage(responseData?.data?.message);
        if (orderStatus !== 'draft') {
          // navigate('/home');
          setShowSuccessMessage(true);
          setSuccessMessage(
            response.data?.message ||
              'Inventory has been received successfully.'
          );
        }
        // handleResetStates();
        // fetchData();
        setBtnLoading(false);

        // navigate to notifications page here
      } else {
        setShowMessage(true);
        setErrorMessage(
          responseData.message || 'Something went wrong with inventory revise'
        );
        setBtnLoading(false);
      }
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  // Get the all alerts
  const getAllAlerts = async () => {
    // show loader
    setIsLoading(true);
    let alertUrl = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/alerts?limit=1000&locationId=${encodedLocationId}`;

    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && locationId) {
      alertUrl = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/alerts?limit=1000&locationId=${encodedLocationId}`;
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      locationId
    ) {
      alertUrl = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/alerts?limit=1000&locationId=${encodedLocationId}`;
    }

    fetch(`${alertUrl}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let item = data.data.Items;
          let active = item.filter(
            (element) =>
              !element.isMuted && element?.transactionStatus !== 'received'
          );
          let muted = item.filter((element) => element.isMuted);
          SetActiveAlertList(active);
          SetMutedAlertList(muted);
          // hide loader
          setIsLoading(false);
        }
      });
  };

  /* Function binded on close button of dialog */
  const handleClose = () => {
    setOpen(false);
    showAlert(false);
  };

  // Method to view the inventory details
  const viewInventory = (transactionId, alertId) => {
    // Set the transaction id
    setTransactionId(transactionId);

    // Now read the notification
    if (alertId) readAlertNotification(alertId);

    // Fetch the product transaction data
    fetchData(transactionId);

    // Now redirect to the details screen
    setShowScreen(2);
  };

  // Method to read the notification
  const readAlertNotification = async (alertId) => {
    let encodedalertId = encodeURIComponent(alertId);
    let url = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/markNotificationAsRead?alertId=${encodedalertId}&locationId=${encodedLocationId}`;

    try {
      await ApiService.get(url);
      // let Item = response?.data?.Items ?? [];
    } catch (error) {
      console.log(error);
    }
  };

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
    let crossicon = document.getElementsByClassName('details-remove-icon')[0];
    if (crossicon) crossicon.click();
    let role = localStorage.getItem('userRole');
    if (role === 'Front Desk') navigate('/receive-inventory');
    else navigate('/transfer-history');
  };

  return (
    <>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      {/* Added one more callback function to identify when close the popup */}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="details-dialog"
        className={`dialog-box dialog-box-right franchisee ${
          showScreen !== 2 && 'inventry-alert-dialog'
        }`}
        maxWidth={`${showScreen === 2 ? 'lg' : '400'}`}
        minWidth={`${showScreen === 2 ? 'lg' : '400'}`}
      >
        <Loader IsLoading={isLoading} />
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            {/* remove icon */}
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={handleClose}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          {showScreen !== 2 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className="text-primary font-20 mb-0 mt-10">
                Inventory Alerts
              </h4>
            </Box>
          )}
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            {(() => {
              switch (showScreen) {
                case 2:
                  return (
                    <Box className="main-alert-container">
                      <ReceiverIncomingSummary
                        handleOpenScreen={handleOpenScreen}
                        setIsInitialState={setIsInitialState}
                        selectedRowsData={selectedRowsData}
                        notesData={transferDetails.notes}
                        postData={postData}
                        quantity={quantity}
                        btnLoading={btnLoading}
                        totalCost={calculateTotalPrice()}
                        fromLocationName={
                          findLocationName(transferDetails.fromLocationId) ||
                          locationName
                        }
                        toLocationName={findLocationName(
                          transferDetails.toLocationId
                        )}
                        transferAddress={findLocationAddress(
                          transferDetails.toLocationId
                        )}
                        transactionId={transactionId}
                        fromLocationId={transferDetails.fromLocationId}
                      />
                    </Box>
                  );
                default:
                  return (
                    <Box className="main-alert-container">
                      <Box className="container-text-field m-0 active-alerts">
                        <Box>
                          <h4 className="text-black font-16">
                            Active Alerts (
                            {activeAlertList && activeAlertList.length})
                          </h4>
                        </Box>
                        <Box className="alert-list">
                          {activeAlertList && activeAlertList.length > 0 ? (
                            activeAlertList.map(
                              (alert, index) =>
                                alert?.transactionStatus !== 'received' && (
                                  <Card
                                    id="inventory-alerts"
                                    // className={`inventory-alerts ${index === 0 ? `unread-border` : `read-border`}`}
                                    className={`inventory-alerts ${
                                      alert?.readAlert
                                        ? `read-border`
                                        : `unread-border`
                                    }`}
                                  >
                                    <CardContent className="alert-content">
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={10}
                                          sm={11}
                                          className="input-field-cont"
                                        >
                                          <Box className="alert-label text-black font-16">
                                            {alert.incoming
                                              ? 'Incoming Alert'
                                              : 'Outgoing Alert'}{' '}
                                            <span className="volumn-icon cursor-pointer">
                                              <VolumeUpOutlinedIcon className="text-primary" />
                                            </span>
                                          </Box>
                                          <Box className="alert-desc">
                                            {alert.outgoingMessage ||
                                              alert.incomingMessage}
                                          </Box>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          sm={1}
                                          className="alert-arrow-navigate"
                                        >
                                          {alert.incoming ? (
                                            <CallReceivedOutlinedIcon
                                              className="alert-icon text-primary cursor-pointer"
                                              onClick={() =>
                                                viewInventory(
                                                  alert.transactionId,
                                                  alert.alertId
                                                )
                                              }
                                            />
                                          ) : (
                                            <CallMadeOutlinedIcon
                                              className="alert-icon text-primary cursor-pointer"
                                              onClick={() =>
                                                viewInventory(
                                                  alert.transactionId,
                                                  alert.alertId
                                                )
                                              }
                                            />
                                          )}
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                )
                            )
                          ) : (
                            <Box className="no-alerts">No Active Alerts</Box>
                          )}
                        </Box>
                      </Box>
                      {/* Mutted container */}
                      <Box className="container-text-field m-0 muted-alerts">
                        <Box>
                          <h4 className="text-black font-16">
                            Muted Alerts (
                            {mutedAlertList && mutedAlertList.length})
                          </h4>
                        </Box>
                        <Box className="alert-list">
                          {mutedAlertList && mutedAlertList.length > 0 ? (
                            mutedAlertList.map((alert) => (
                              <Card
                                id="inventory-alerts"
                                className="inventory-alerts read-border"
                              >
                                <CardContent className="alert-content">
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={10}
                                      sm={11}
                                      className="input-field-cont"
                                    >
                                      <Box className="alert-label text-black font-16">
                                        {alert.incoming || 'Alert Subject'}{' '}
                                        <span className="volumn-icon cursor-pointer">
                                          <VolumeOffOutlinedIcon className="text-primary" />
                                        </span>
                                      </Box>
                                      <Box className="alert-desc">
                                        {alert.outgoingMessage ||
                                          alert.incomingMessage}
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      sm={1}
                                      className="alert-arrow-navigate"
                                    >
                                      <CallMadeOutlinedIcon
                                        className="alert-icon text-primary cursor-pointer"
                                        onClick={() =>
                                          viewInventory(alert.transactionId)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            ))
                          ) : (
                            <Box className="no-alerts">No Muted Alerts</Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
              }
            })()}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InventoryAlerts;
