/* eslint-disable spaced-comment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from 'react';
import { HeaderContext } from 'src/contexts/HeaderContext';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Box,
  Grid,
  CircularProgress,
  Typography,
  Stack
} from '@mui/material';

import {
  TransferInventoryFilter,
  TransferInventoryTable,
  ReviseInventorySummary
} from 'src/components/Inventory/index';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import GlobalData from 'src/global';
import {
  CustomButton,
  SearchInput,
  CustomTextField,
  CustomSelect,
  CustomDatePicker
} from 'src/components/Global';
import { getLocalData, setFullLocationId } from 'src/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ErrorMessage from '../../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../../components/Loader';
import Header from 'src/components/Header';

const InventoryRevise = () => {
  // change the header values
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Revise Transfer Order');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Revise Transfer Order';
  const title = GlobalData[0].title;
  const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;
  const navigate = useNavigate();
  const userId = sessionStorage.getItem('UserId');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilters, setIsFilters] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [allInventoryData, setAllInventoryData] = useState([]);
  const [allLocationsList, setAllLocationsList] = useState([]);

  const [quantity, setQuantity] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);

  // get user role and provide access of that roles
  const franchiseeId = getLocalData('franchiseeId');
  const locationId = setFullLocationId(getLocalData('locationId'));
  const locationName = getLocalData('locationName');
  const [searchParams] = useSearchParams();
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const [filters, setFilters] = useState({
    status: null,
    price: null,
    categoryName: null,
    sku: null
  });

  const [transactionDetails, setTransactionDetails] = useState({
    fromLocationId: '',
    toLocationId: '',
    fromLocationName: '',
    toLocationName: '',
    notes: '',
    createdBy: ''
  });

  const [transferDetails, setTransferDetails] = useState({
    fromLocationId: '',
    toLocationId: '',
    fromLocationName: '',
    toLocationName: '',
    notes: '',
    createdBy: userId,
    transactionId: ''
  });

  const [isError, setIsError] = useState({
    toLocationId: false,
    fromLocationId: false,
    notes: false
  });

  const handleTransferDetails = (type, value) => {
    setTransferDetails({ ...transferDetails, [type]: value });
    setIsError({ ...isError, [type]: false });
  };

  function defaultQuantity() {
    const defaultQuantities = {};
    inventoryData.forEach((item) => {
      defaultQuantities[item.sortKey] = 0;
    });

    return defaultQuantities;
  }

  useEffect(() => {
    setQuantity(defaultQuantity());
  }, [inventoryData]);

  useEffect(() => {
    const data = allInventoryData?.filter((item) =>
      selectedRows?.includes?.(item.id)
    );
    setSelectedRowsData(data);
  }, [selectedRows]);

  useEffect(() => {
    if (showScreen == 1 && isInitialState) {
      setSearchQuery('');
      setSelectedRows([]);
      setSelectedRowsData([]);
      setQuantity(defaultQuantity());
      // setNotesData('');
      // setSelectedReason('Breakage/Waste');
      setIsInitialState(false);
      setBtnLoading(false);
    }
  }, [showScreen, isInitialState]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const handleResetStates = () => {
    setQuantity(defaultQuantity());
    setSelectedRows([]);
    setSelectedRowsData([]);
    setIsFilters(false);
    setSearchQuery('');
    setIsInitialState(true);
    setShowScreen(1);

    setTransferDetails({
      fromLocationId: '',
      toLocationId: '',
      fromLocationName: '',
      toLocationName: '',
      notes: '',
      createdBy: ''
    });

    setTransactionDetails({
      fromLocationId: '',
      toLocationId: '',
      fromLocationName: '',
      toLocationName: '',
      notes: '',
      createdBy: ''
    });

    setIsError({
      toLocationId: false,
      fromLocationId: false,
      notes: false
    });

    setFilters({
      status: null,
      price: null,
      categoryName: null,
      sku: null
    });
  };

  function checkAllSelectedQuantity() {
    return selectedRows.every((itemId) => quantity[itemId] > 0);
  }

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleTransferSubmit = (status) => {
    if (transferDetails.toLocationId == '') {
      setIsError({
        ...isError,
        toLocationId: true
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (transferDetails.fromLocationId == '') {
      setIsError({
        ...isError,
        fromLocationId: true
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      /* } else if (transferDetails.notes == '') {
      setIsError({
        ...isError,
        notes: true
      }); */
    } else if (!selectedRows?.length) {
      setShowErrorMessage(true);
      setErrorMessage('Please select atleast one inventory row');
      // toast.error('Please select atleast one inventory row');
    } else if (!checkAllSelectedQuantity()) {
      setShowErrorMessage(true);
      setErrorMessage(
        'Please add a minimum quantity of 1 to all selected rows.'
      );
      // toast.error('All selected rows must have quantity greater than 0');
    } else {
      if (status == 'Draft') {
        postData('draft');
      } else if (status == 'Proceed') {
        // postData('proceed');
        setShowScreen(2);
      }

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  function isAnyFilterApplied() {
    return Object.values(filters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '';
    });
  }

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === -1 ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  function formatProductsArray(originalArray) {
    const convertedArray = originalArray.map((item) => {
      const obj = {};
      const keys = Object.keys(item);
      keys.forEach((key) => {
        const innerObj = item[key];
        const innerKeys = Object.keys(innerObj);
        innerKeys.forEach((innerKey) => {
          obj[innerKey] = innerObj[innerKey];
        });
      });
      return obj;
    });
  }

  function setSelectedProductRows(items) {
    // let array = formatProductsArray(items);
    let array = items;

    // if (!Array.isArray(array) || !array.length) {
    if (!array.length) {
      return false;
    }
    const idsToUpdate = array?.map(({ itemId }) => itemId);
    const updatedStateObject = { ...quantity };
    idsToUpdate.forEach((id) => {
      const matchingItem = array.find((item) => item.itemId == id);
      if (
        matchingItem
        // && Object.prototype.hasOwnProperty.call(updatedStateObject, id)
      ) {
        updatedStateObject[id] = matchingItem.quantity;
      }
    });

    setSelectedRows(idsToUpdate);
    setQuantity(updatedStateObject);

    return true;
  }

  function setAllPrefilledValues(data) {
    data = data[0];
    if (
      data.transactionItems ||
      !data.fromLocationId ||
      !data.fromLocationId ||
      !data.toLocationId ||
      !data.toLocationId
    ) {
      // console.log('data: ', JSON.parse(data.transactionItems));
      // console.log('data: ', data);
      // let transactionId = searchParams.get("transactionId");
      // let encodetransactionId = transactionId ? encodeURIComponent(transactionId) : null;

      let details = {
        fromLocationId: data.fromLocationId,
        toLocationId: data.toLocationId,
        toLocationName: data.toLocationName,
        fromLocationName: data.fromLocationName,
        // toLocationName: data.toLocationId,
        // fromLocationName: data.fromLocationId,
        notes: data.notes,
        // notes: "test",
        createdBy: data.createdBy,
        transactionId: data.transactionId
        // transactionId: encodetransactionId
      };

      let locationsList = [
        {
          label: data.fromLocationName,
          // label: data.fromLocationId,
          value: data.fromLocationId
        },
        {
          label: data.toLocationName,
          // label: data.toLocationId,
          value: data.toLocationId
        }
      ];

      // setSelectedProductRows(JSON.parse(data.transactionItems));
      // setSelectedProductRows(data.transactionItems);
      setTimeout(() => {
        setSelectedProductRows(data.transactionItems);
      }, 500);
      setTransactionDetails(details);
      setTransferDetails(details);
      setAllLocationsList(locationsList);
      // setShowScreen(2);
      setLoading(false);
    } else {
      setShowErrorMessage(true);
      setErrorMessage('Missing some values in response');
      // toast.error('Missing some values in response');
      // fetchData()
    }
  }

  const fetchData = async () => {
    setLoading(true);
    //for now we are using hardcoded transaction id, but when this page will access from notifications,
    // you have to pass any transactionId in params. like (http://localhost:3000/inventory-revise?transactionId=123123)
    // we will pick that id from params using parms fro react router, then will pass in this api as dynamic
    // let transactionId = '%23TRN%232QHrAjkn85jKspqhBApyNsj7O10';

    // let transactionId = searchParams.get("transactionId");

    // let transactionId = "2Rk5NE0uWAIjNp4bwJrEfWYEEqO";
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);

    try {
      let transactionId = searchParams.get('transactionId');
      // check if franchisee id is available and it has a #FR# then do no attach #FR# again
      if (
        transactionId &&
        !transactionId.includes('#TRN#') &&
        transactionId !== 'undefined'
      ) {
        transactionId = '#TRN#' + transactionId;
      }
      let encodetransactionId = transactionId
        ? encodeURIComponent(transactionId)
        : null;
      // console.log("transactionId", transactionId);
      const transactionResponse = await fetch(
        // `${BASE_URL}/inventory/getTransaction?locationId=LOC%237bb65894-4e56-7p98-1q44-w456i50b47j0&transactionId=${transactionId}`
        `${BASE_URL}inventory/getTransaction?locationId=${encodedLocationId}&transactionId=${encodetransactionId}`
      );

      const inventoryResponse = await fetch(
        // `${BASE_URL}/inventory?locationId=LOC%239aa27842-2cc8-4c35-8c38-c184c50b25b9`
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1`
      );
      const locationsResponse = await fetch(
        // `${process.env.REACT_APP_API_END_POINT}locations?limit=1000&franchiseeId=${franchiseeId}`
        `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`
      );
      const locationsData = await locationsResponse.json();

      const transactionData = await transactionResponse.json();
      const inventoryData = await inventoryResponse.json();

      if (!inventoryData.error) {
        const modifiedInventoryData = inventoryData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.sortKey
          })
        );
        setAllInventoryData(modifiedInventoryData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          inventoryData.message || 'Something went wrong with inventory'
        );
        // toast.error(
        //   inventoryData.message || 'Something went wrong with inventory'
        // );
      }

      if (!transactionData.error) {
        let data = transactionData.data.Items;
        setAllPrefilledValues(data);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          transactionData.message ||
            'Something went wrong with revise inventory'
        );
        // toast.error(
        //   transactionData.message ||
        //   'Something went wrong with revise inventory'
        // );
      }

      if (!locationsData.error) {
        const modifiedInventoryData = locationsData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.locationId
          })
        );
        setAllLocationsList(modifiedInventoryData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          locationsData.message || 'Something went wrong with locations'
        );
      }
    } catch (error) {
      setLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error -- Please try again later');
      // toast.error('Internal Server Error -- Please try again later');
    }
  };

  const getFilteredInventory = async () => {
    let queryString = filtersQuery();
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      const inventoryResponse = await fetch(
        // `${BASE_URL}/inventory?locationId=LOC%239aa27842-2cc8-4c35-8c38-c184c50b25b9&${queryString}`
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1&${queryString}`
      );
      const inventoryData = await inventoryResponse.json();

      if (!inventoryData.error) {
        const modifiedInventoryData = inventoryData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.sortKey
          })
        );
        setInventoryData(modifiedInventoryData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          inventoryData.message || 'Something went wrong with inventory'
        );
        // toast.error(
        //   inventoryData.message || 'Something went wrong with inventory'
        // );
      }
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
      // toast.error('Internal Server Error');
    }
  };

  useEffect(() => {
    getFilteredInventory();
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  function findLocationName(id) {
    const foundLocation = allLocationsList?.find(
      (location) => location.locationId == id
    );

    if (foundLocation) {
      return foundLocation.locationName;
    }

    return ''; // Return an empty string if no supplier with the given id is found
  }

  function findLocationAddress(id) {
    const foundLocation = allLocationsList?.find(
      (location) => location.locationId == id
    );

    if (foundLocation) {
      return foundLocation.addressLine1;
    }

    return ''; // Return an empty string if no supplier with the given id is found
  }

  // const calculateTotalPrice = () => {
  //   const totalPrice = selectedRowsData?.reduce((total, obj) => {
  //     const price = parseFloat(obj.price) || 0;
  //     return total + price;
  //   }, 0);

  //   return totalPrice?.toFixed(2);
  // };
  const calculateTotalPrice = () => {
    const totalPrice = selectedRowsData?.reduce((total, obj) => {
      const price = parseFloat(obj.price) || 0;
      const quan = quantity[obj.sortKey] || 0;
      return total + price * quan;
    }, 0);
    return totalPrice?.toFixed(2);
  };

  const postData = async (status) => {
    setBtnLoading(true);
    let idss = [];
    let itemsData = [];

    let orderStatus = status;

    selectedRowsData?.map((item) => {
      // const quantityValue = quantity[item.id]; // Get the corresponding quantity for the item
      // idss.push({ itemId: item.PRDSort });
      // itemsData.push({
      //   itemId: item.PRDSort,
      //   quantity: quantityValue,
      //   category: item?.category?.categoryName,
      //   itemName: item?.productName
      // });
      const quantityValue = quantity[item.sortKey]; // Get the corresponding quantity for the item
      idss.push({ itemId: item.sortKey });
      itemsData.push({
        itemId: item.sortKey,
        quantity: quantityValue,
        category: item?.categoryName,
        itemName: item?.fName
      });

      return null;
    });

    let data = {};
    let url = '';
    let transactionId = searchParams.get('transactionId');
    if (transactionId) transactionId = decodeURIComponent(transactionId);
    // check if franchisee id is available and it has a #FR# then do no attach #FR# again
    if (
      transactionId &&
      !transactionId.includes('#TRN#') &&
      transactionId !== 'undefined'
    ) {
      transactionId = '#TRN#' + transactionId;
    }
    // let encodetransactionId = transactionId ? encodeURIComponent(transactionId) : null;
    // console.log("transactionId", transactionId);
    if (orderStatus === 'draft') {
      data = {
        notes: transferDetails.notes,
        totalCost: calculateTotalPrice(),
        numberOfItems: selectedRows.length,
        items: itemsData,
        itemIds: idss,
        fromLocationId: transferDetails.fromLocationId,
        fromLocationName: transferDetails.fromLocationName,
        toLocationId: transferDetails.toLocationId,
        toLocationName: transferDetails.toLocationName,
        createdBy: userId,
        // transactionId: encodetransactionId,
        transactionId: transactionId,
        // updatedBy:  '4444',
        transactionStatus: orderStatus,
        transferAddress:
          transferDetails.transferAddress ||
          findLocationAddress(transferDetails.toLocationId)
      };
      url = `${BASE_URL}inventory/updateDraftTransaction`;
    } else {
      data = {
        fromLocationId: transferDetails.fromLocationId,
        toLocationId: transferDetails.toLocationId,
        // transactionId: encodetransactionId,
        transactionId: transactionId,
        createdBy: userId
      };

      url = `${BASE_URL}inventory/receiveTransaction`;
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    try {
      const response = await fetch(
        // `${BASE_URL}/inventory/updateDraftTransaction`,
        `${url}`,
        options
      );
      const responseData = await response.json();
      if (!responseData.error) {
        setShowSuccessMessage(true);
        setSuccessMessage(responseData?.data?.message);
        // toast.success(responseData?.data?.message);
        if (orderStatus !== 'draft') {
          navigate('/home');
        }
        // handleResetStates();
        // fetchData();
        setBtnLoading(false);

        //navigate to notifications page here
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          responseData.message || 'Something went wrong with inventory revise'
        );
        // toast.error(
        //   responseData.message || 'Something went wrong with inventory revise'
        // );
        setBtnLoading(false);
      }
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };

  const closePopup = () => {
    setShowSuccessMessage(false);
    navigate('/home');
  };

  return (
    <>
      <Helmet>
        <title>Revise Transfer Order | {title}</title>
      </Helmet>
      <Loader IsLoading={loading} />
      <Header title="Revise Transfer Order" />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <Container maxWidth="100%" className="custom-container">
        {/* showMessage */}
        {showErrorMessage && (
          <ErrorMessage
            message={errorMessage}
            setShowMessage={setShowMessage}
          />
        )}

        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <ReviseInventorySummary
                  handleOpenScreen={handleOpenScreen}
                  setIsInitialState={setIsInitialState}
                  selectedRowsData={selectedRowsData}
                  // selectedReason={selectedReason}
                  notesData={transferDetails.notes}
                  postData={postData}
                  quantity={quantity}
                  btnLoading={btnLoading}
                  totalCost={calculateTotalPrice()}
                  fromLocationName={locationName}
                  toLocationName={findLocationName(
                    transferDetails.toLocationId
                  )}
                  transferAddress={findLocationAddress(
                    transferDetails.toLocationId
                  )}
                />
              );
            default:
              return (
                <Box
                  justifyContent="center"
                  direction="column"
                  className="custom-form"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid item width={'100%'}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        marginTop="20px"
                      >
                        <Grid item>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography
                              variant="variant2"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                textAlign: 'center'
                              }}
                              className="text-primary weight-600 font-20"
                            >
                              Transfer Inventory
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    columnSpacing={2}
                    columns={12}
                    mt={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="variant2"
                        color="#000"
                        fontWeight="400"
                        className="font-20"
                      >
                        From
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sm={4} marginRight="30px">
                      <CustomSelect
                        fullWidth
                        disabled
                        name="fromLocationId"
                        value={transferDetails.fromLocationId}
                        onChange={handleTransferDetails}
                        label="LOCATION # 1"
                        // options={allLocationsList}
                        error={isError.fromLocationId}
                        helperText={
                          isError.fromLocationId && 'Location Id is required'
                        }
                        options={[
                          {
                            label: transferDetails.fromLocationName,
                            value: transferDetails.fromLocationId
                          }
                        ]}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="variant2"
                        color="#000"
                        fontWeight="400"
                        className="font-20"
                      >
                        To
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                      <CustomSelect
                        fullWidth
                        disabled
                        name="toLocationId"
                        value={transferDetails.toLocationId}
                        onChange={handleTransferDetails}
                        label="LOCATION # 2"
                        error={isError.toLocationId}
                        helperText={
                          isError.toLocationId && 'Location Id is required'
                        }
                        // options={allLocationsList}
                        options={allLocationsList?.map((item) => ({
                          label: item.locationName,
                          value: item.locationId
                        }))}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{ borderBottom: '1px solid #DDDDDD' }}
                    mt={5}
                  ></Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    marginTop="10px"
                  >
                    <Grid item>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <h2 className="title mb-0 mt-10 primary-color">
                          Choose Products to Transfer
                        </h2>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                    marginTop="20px"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      // sx={{
                      //   display: 'flex',
                      //   justifyContent: 'flex-start'
                      // }}
                    >
                      <CustomButton
                        onClick={() => setIsFilters(!isFilters)}
                        variant={
                          isAnyFilterApplied()
                            ? 'contained'
                            : isFilters
                            ? 'contained'
                            : 'outlined'
                        }
                      >
                        Filters
                        <KeyboardArrowDownRoundedIcon
                          className={clsx(
                            !isFilters && classes.closeY,
                            isFilters && classes.openY
                          )}
                        />
                      </CustomButton>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <SearchInput
                        value={searchQuery}
                        setSearchQuery={setSearchQuery}
                        classPropName="height-auto"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    marginTop="10px"
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                    <>
                      {isFilters ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <TransferInventoryFilter
                            filters={filters}
                            setFilters={setFilters}
                            data={allInventoryData}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        <TransferInventoryTable
                          isRevise
                          tableRows={inventoryData}
                          searchQuery={searchQuery}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                        />
                      </Grid>
                    </>
                    {/* )} */}
                  </Grid>

                  <Grid container className="order-multiline-grid">
                    <Grid item xs={6} paddingRight="20px" marginTop="30px">
                      <CustomTextField
                        fullWidth
                        label="NOTES"
                        multiline
                        rows="5"
                        type="text"
                        name="notes"
                        // required
                        value={transferDetails.notes}
                        onChange={handleTransferDetails}
                        // error={isError.notes}
                        className="text-primary"
                        helperText={isError.notes && 'Notes is required'}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      width="100%"
                      maxWidth="400px !important"
                      marginX="auto"
                      paddingLeft="20px"
                      marginTop="30px"
                      rowGap="20px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      className="fullWidth-btn"
                    >
                      <CustomButton
                        variant="outlined"
                        // disabled
                        loading={btnLoading}
                        onClick={() => handleTransferSubmit('Draft')}
                      >
                        Save as Draft
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        disabled={btnLoading}
                        onClick={() => handleTransferSubmit('Proceed')}
                      >
                        Proceed to Summary
                      </CustomButton>
                      {/* <Stack
                display='flex'
                flexDirection='column'
                // direction='column'
                justifyContent='center'
                alignItems="center"
                // position="absolute"
                width="100%"
                // bottom="0"
                // right="4px"
              >
                <Box display="flex" gap={1} justifyContent="flex-end">
                  <CustomButton
                    variant="outlined"
                    // onClick={() => handleOrderFormSubmit('Draft')}
                  >
                    Save as Draft
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    // onClick={() => handleOrderFormSubmit('Arrived')}
                  >
                    Proceed to Summary
                  </CustomButton>
                </Box>
              </Stack> */}
                    </Grid>
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>
    </>
  );
};

export default InventoryRevise;
