import { Grid, Typography, Card,
  CardHeader,
 // Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow } from '@mui/material';
import { Box, Stack } from '@mui/system';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
// import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';

const SalesHistoryCard = ({ trophyDetails, handleOpenScreen }) => {
  console.log(trophyDetails, 'trophyDetailstrophyDetailstrophyDetails');
  const [tableData, setTableData] = useState([]);
 // const [selectedLocationLists, setSelectedLocationLists] = useState([]);

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  
  useEffect(() => {
    if (trophyDetails?.items?.length > 0) {
      const tableItem = trophyDetails?.items || [];
      console.log(tableItem, 'ItemItem');
     
      setTableData(tableItem);
    }
  }, [trophyDetails]);

  const dateFormate = (date) => {
    // let utc = moment(date).format('MMMM Do YYYY, h:mm:ss A');
    let utc;
    utc = date ? moment(date).format('MM-DD-YYYY') : '';
    return utc;
    // let dateFor = date && new Date(date);
    // // let utc = dateFor.toUTCString();
    // let finaldate = dateFor.toLocaleString();
    // return finaldate;
  };

  const numberFormate = (num) => {
    let value = num && num / 100;
    const formatted = Number(value).toLocaleString('en', options);
    // console.log(formatted);
    return formatted;
  };

  // Event handler to increment the quantity of an item
 /* const incrementQuantity = (itemId) => {
    const updatedItems = tableData?.map((item) => {
      if (item.itemId === itemId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setTableData(updatedItems);
  };

  // Event handler to decrement the quantity of an item
  const decrementQuantity = (itemId) => {
    const updatedItems = tableData?.map((item) => {
      if (item.itemId === itemId && item.quantity > 0) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
    setTableData(updatedItems);
  }; */

 /* const handleSelectAllLocationItems = (event) => {
    setSelectedLocationLists(
      event.target.checked
        ? tableData?.map((locationItem) => locationItem.itemId)
        : []
    );
  };

  const handleSelectOneLocationItem = (event, locationItemId) => {
    event.stopPropagation();
    // event.preventDefault();
    if (!selectedLocationLists.includes(locationItemId)) {
      setSelectedLocationLists((prevSelected) => [
        ...prevSelected,
        locationItemId
      ]);
    } else {
      setSelectedLocationLists((prevSelected) =>
        prevSelected.filter((itemId) => itemId !== locationItemId)
      );
    }
  };
  const selectedSomeLocationItems =
    selectedLocationLists?.length > 0 &&
    selectedLocationLists?.length < tableData?.length;
  const selectedAllLocationItems =
    selectedLocationLists?.length === tableData?.length; */

  // console.log(selectedLocationLists, 'clientDetailclientDetailclientDetail');
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        mt={1}
      >
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box
              onClick={() => handleOpenScreen(0)}
              className="back-arrow-close"
              sx={{ marginTop: '6px !important' }}
            >
              <ArrowLeft />
            </Box>
            <Typography className="header-blue-20">Purchase History</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button
      sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
      variant="contained"
      type="submit"
      className="btn-primary m-0"

    >
      Save
    </Button> */}
          </Box>
        </Grid>
      </Grid>
      <Box className="main-sales-detail" p={0} mt={2}>
        <Box className="detailbox-main">
          <Box className="detail-section" sx={{padding: '30px !important'}}>
            <Box className="section-main">
              <Box>
                <Stack>DATE :</Stack>
                <Stack>
                  {' '}
                  {trophyDetails?.dateOfPurchase
                    ? dateFormate(trophyDetails.dateOfPurchase)
                    : '-'}
                </Stack>
              </Box>
              <Box>
                <Stack>PAYMENT METHOD :</Stack>
                <Stack>
                  {trophyDetails?.paymentSourceType || '-'}
                </Stack>
              </Box>
            </Box>
            <Box className="section-main">
              <Box>
                <Stack>DOG :</Stack>

                <Stack>
                  {trophyDetails?.petName ? trophyDetails?.petName : '-'}
                </Stack>
              </Box>

              <Box>
                <Stack>CARD NUMBER :</Stack>

                <Stack>
                  {trophyDetails?.cardNumber ? trophyDetails?.cardNumber : '-'}
                </Stack>
              </Box>
            </Box>
            <Box className="section-main">
              <Box>
                <Stack>TOTAL AMOUNT :</Stack>
                <Stack>
                  {' '}
                  {(trophyDetails?.paymentAmount &&
                    '$' + numberFormate(trophyDetails?.paymentAmount)) ||
                    '$0.00'}
                </Stack>
              </Box>
              <Box>
                <Stack>DISCOUNT(%):</Stack>
                <Stack>
                  {(trophyDetails?.totalDiscount &&
                    trophyDetails.totalDiscount + '%') ||
                    0}
                </Stack>
              </Box>
            </Box>
            <Box className="section-main">
              <Box>
                <Stack>PAYMENT STATUS :</Stack>
                <Stack>
                  {trophyDetails?.sqaureOrderState}
                </Stack>
              </Box>
              <Box>
                <Stack>TRANSACTION ID:</Stack>
                <Stack>
                  {trophyDetails?.transactionId || trophyDetails?.orderId}
                </Stack>
              </Box>
            </Box>
          </Box>
          {trophyDetails?.items && (
            <Box className="detail-table">
              {/* <CustomTable
                data={tableData}
                handleQtyChange={handleQtyChange}
                isQty
                isCheckBox
              /> */}

              <Card
                sx={{
                  padding: '15px',
                  borderRadius: '19px 19px 0px 0px'
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '12px'
                    }}
                  >
                    <CardHeader
                      title="All Items"
                      className="table-header-name"
                      sx={{ fontSize: '20px', color: '#003087' }}
                    />
                  </Grid>
                </Grid>
                <TableContainer className="location-table-container">
                  <Table>
                    <TableHead>
                      <TableRow className="table-header">
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedAllLocationItems}
                            indeterminate={selectedSomeLocationItems}
                            onChange={handleSelectAllLocationItems}
                          />
                        </TableCell> */}
                        <TableCell>Item Name</TableCell>
                        <TableCell>Remaining Credits/Qty</TableCell>
                        <TableCell>Variant</TableCell>
                        <TableCell>Amount</TableCell>
                        {/* <TableCell /> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData &&
                        tableData?.map((tableItem) => {
                          // const isItemSelected = selectedLocationLists?.includes(tableItem?.itemId);
                          return (
                            <TableRow
                              hover
                              key={tableItem?.itemId}
                              // selected={isItemSelected}
                              className="table-row cursor-pointe no-hover"
                            >
                              {/* <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onChange={(event) =>
                                    handleSelectOneLocationItem(
                                      event,
                                      tableItem.itemId
                                    )
                                  }
                                  value={isItemSelected}
                                />
                              </TableCell> */}
                              <TableCell
                                className="table-td -flex"
                                sx={{ minWidth: '200px' }}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  sx={{ marginTop: 1 }}
                                  gutterBottom
                                >
                                  {tableItem?.itemName || '-'}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                {/* {isItemSelected ? (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={0.5}
                                  >
                                    <AiFillMinusCircle
                                      style={{
                                        color: '#003087',
                                        fontSize: '1.2rem',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() =>
                                        decrementQuantity(tableItem?.itemId)
                                      }
                                    />

                                    <Typography
                                      variant="body1"
                                      fontWeight="bold"
                                      color="text.primary"
                                      sx={{ padding: '8px', marginTop: '2px' }}
                                      gutterBottom
                                    >
                                      {tableItem?.quantity}
                                    </Typography>

                                    <AiFillPlusCircle
                                      style={{
                                        color: '#003087',
                                        fontSize: '1.2rem',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() =>
                                        incrementQuantity(tableItem?.itemId)
                                      }
                                    />
                                  </Stack>
                                ) : ( */}
                                  <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                    color="text.primary"
                                    sx={{ padding: '8px', marginTop: '2px' }}
                                    gutterBottom
                                  >
                                    {tableItem?.quantity}
                                  </Typography>
                                {/* )} */}
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {tableItem?.itemVariantType || '-'}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  ${tableItem?.price || 0.0}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Box>
          )}
        </Box>
      </Box>

    </>
  );
};

export default SalesHistoryCard;
