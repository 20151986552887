import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './style.css';
import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Box,
  FormGroup,
  Menu,
  Checkbox,
  FormControlLabel,
  Fade,
  Typography,
  Container,
  Select,
  MenuItem,
  CircularProgress
} from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { DateTime } from 'luxon';
import { CalenderSchedular, CalenderMenu } from './index';

// import { getLocalData } from 'src/utils';
// import { BookClassDialog } from 'src/components/Schedules';
import ListView from './ListView';
import { ApiService } from '../../services/ApiService';
// import { filtersQuery, dateFormat } from '../../utils/index';
// import Loader from '../Loader';
// import { OnlinePrediction } from '@mui/icons-material';
// import { convertToUserTimezoneAndFormat, convertUtcToUserLocalTime, convertToUserTimezoneAndFormats, convertToIsoDateTime } from 'src/utils';
import { getLocalData, dateFormat, convertFrom12To24Format } from "../../utils";

export function Calender(props) {
  const { handleOpenModal, setDialog, dialog, loadData,setAlignment,alignment } = props;

  // let locationId = 'LOC#54123';
  const locationId = getLocalData('locationId');

  const [trainerData, setTrainerData] = useState([]);
  const [schedularData, setSchedularData] = useState([]);
  const [holidayData, setHolidayData] = useState([]);
  const [operatHrsData, setoperatHrsData] = useState([]);

  const [servicesList, setServicesList] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  // const [alignment, setAlignment] = useState('week');
  const [view, setView] = useState('grid');

  const [startHour, setStartHour] = useState('');
  const [endHour, setEndHour] = useState('');

  const [scheduleFilter, setScheduleFilter] = useState({
    scheduleType: 'All',
    categoryName: ''
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (scheduleFilter.scheduleType === 'enrollment' || scheduleFilter.scheduleType === 'appointment' || scheduleFilter.scheduleType === 'All') {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleFilterChange = (value) => {
    setScheduleFilter({ scheduleType: value });
    getSelectedServiceCategories(value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    getOperatingHours();
    getStaffHolidayAndTimeOff();
    getAllTrainers();
  }, []);
  useEffect(() => {
    getStaffHolidayAndTimeOff();
    getAllTrainers();
  }, [currentDate]);
  useEffect(() => {
    if (trainerData)
      getAllAppointments(trainerData, holidayData);
  }, [currentDate, scheduleFilter, loadData, trainerData]);
 
  useEffect(() => {
    getSelectedServiceCategories(scheduleFilter.scheduleType);
  }, [scheduleFilter.scheduleType]);

  const convertTimeAndDate = (timeString, dateString) => {

    const dateTimeString = `${dateString} ${timeString}`;
    const dateTime = moment(dateTimeString, 'MM-DD-YYYY hh:mm A');
    const utcDate = dateTime.toDate();
    return utcDate.toISOString();
  };


  // function convertDateTimeForTrainer(dateStr, timeStr) {
  //   // Split the date string into day, month, and year
  //   const [month, day, year] = dateStr.split('-').map(Number);

  //   // Extract the hours and minutes from the time string
  //   const [hours, minutes] = timeStr.match(/\d+/g).map(Number);

  //   // Create a Date object using the extracted values
  //   const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

  //   // Format the date object to ISO 8601 format in UTC
  //   const formattedDate = date.toISOString();

  //   return formattedDate;
  // }

  function convertDateTimeToDesiredFormat(date, time) {
    // Parse the date
    const parsedDate = date.split('-');
    const year = parseInt(parsedDate[2]);
    const month = parseInt(parsedDate[0]) - 1; // Months are zero-based
    const day = parseInt(parsedDate[1]);

    // Parse the time
    const parsedTime = time.split(' ');
    const timeStr = parsedTime[0];
    const ampm = parsedTime[1];

    let hour = parseInt(timeStr.split(':')[0]);
    const minute = parseInt(timeStr.split(':')[1]);

    if (ampm === 'PM' && hour < 12) {
      hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
      hour = 0;
    }

    // Create a Date object and set it to the desired date and time
    const dateTime = new Date(year, month, day, hour, minute, 0, 0);

    // Get the ISO 8601 formatted string in UTC
    const formattedDateTime = dateTime.toISOString();

    return formattedDateTime;
  }

  // function convertDateFormat(inputDate) {
  //   const parts = inputDate?.split('-');

  //   return `${parts[1]}-${parts[2]}-${parts[0]}`;

  // }


  const getOperatingHours = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getLocationOperatingHours?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      setoperatHrsData(Item[0]?.operatingHours ?? []);
      setOperatinghours(Item[0]?.operatingHours, alignment);
      // Item.map((item) => (
      //   // eslint-disable-next-line array-callback-return        
      //   item.operatingHours.map((subItem) => {
      //     setStartHour(convertFrom12To24Format(subItem.startTime));
      //     setEndHour(convertFrom12To24Format(subItem.endTime));
      //   })
      // ));
    } catch (e) {
      console.log(e);
    }
    // Not needed as logic change
    // data[0]?.operatingHours.forEach((item) => {
    //   let day = '';
    //   if (item.day === 'Monday') {
    //     day = 'MO';
    //   } else if (item.day === 'Tuesday') {
    //     day = 'TU';
    //   } else if (item.day === 'Wednesday') {
    //     day = 'WE';
    //   } else if (item.day === 'Thursday') {
    //     day = 'TH';
    //   } else if (item.day === 'Friday') {
    //     day = 'FR';
    //   } else if (item.day === 'Saturday') {
    //     day = 'SA';
    //   } else {
    //     day = 'SU';
    //   }
    //   day = day ? `BYDAY=${day};` : '';
    //   formatData.push({
    //     "StartTime": convertTimeAndDate('12:00 AM', dateFormat(currentDate)),
    //     "EndTime": convertTimeAndDate(item.startTime, dateFormat(currentDate)),
    //     "RecurrenceRule": `FREQ=DAILY;${day}INTERVAL=1;`,
    //     "IsBlock": true,
    //   });
    //   formatData.push({
    //     "StartTime": convertTimeAndDate(item.endTime, dateFormat(currentDate)),
    //     "EndTime": convertTimeAndDate('11:59 PM', dateFormat(currentDate)),
    //     "RecurrenceRule": `FREQ=DAILY;${day}INTERVAL=1;`,
    //     "IsBlock": true
    //   });
    // });
    // setOPeratingHourseData(formatData);
  };


  const getStaffHolidayAndTimeOff = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getLocatinHoliday?locationId=${encodedLocationId}`
    );
    let data =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    let formatData = [];
    data.forEach((item) => {
      formatData.push({
        trainerName: item?.title ?? 'Holiday',
        StartTime: convertDateTimeToDesiredFormat(item?.startDate, '12:00 AM'),
        EndTime: convertDateTimeToDesiredFormat(item?.startDate, '11:59 PM'),
        IsAllDay: false,
        IsBlock: true,
        holiday: true,
        Timeoff: false
      });
    });
    setHolidayData(formatData);
  };

  const getSelectedServiceCategories = async (value) => {
    let encodedLocationId = encodeURIComponent(locationId);
    let apiUrl = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAllServiceCategories?locationId=${encodedLocationId}`;

    if (value !== 'All') {
      apiUrl += `&serviceType=${value}`;
    }

    let response = await ApiService.get(apiUrl);
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];

    if (Item) {
      // eslint-disable-next-line array-callback-return
      Item?.map((item) => {
        item.checked = false;
      });
    }
    setServicesList(Item);
  };

  const getAllTrainers = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAllTrainers?locationId=${encodedLocationId}`
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];

    let uniqueTrainers = Item?.map((obj, index) => ({
      eventClass: index === 0 ? 'borderLeft' : 'borderLeftAgain',
      color: index !== 1 ? '#D13A0033' : '#32B2AC33',
      designation: obj?.roles[0]?.role,
      trainerId: obj?.sortKey,
      trainerName: `${obj?.firstName} ${obj?.lastName}`,
      trainerProfileImage: obj?.profileImage,
      trainerAvailability: obj?.availableSchedules,
      unavailableSchedules: obj?.unavailableSchedules,
      //   [ {
      //     startTime: "11:00 AM",
      //     endTime: "08:30 PM",
      //     isBlock: true,
      //     Key: 'V3'
      //   }
      // ],
      ...obj
    }));
    setTrainerData(uniqueTrainers);
    // getAllAppointments(uniqueTrainers);
  };


  function convertToUTC(inputDate) {
    // Split the input date and time
    const [dateString, timeString] = inputDate.split(',');

    // Parse the date string into parts (month, day, year)
    const [, month, day, year] = dateString.match(/(\d+)\/(\d+)\/(\d+)/);

    // Parse the time string to get the hours and minutes
    // const [, hours, minutes, ampm] = timeString.match(/(\d+):(\d+)\s+(AM|PM)/);
    const [, hours, minutes, ampm] = timeString ? timeString.toUpperCase().match(/(\d+):(\d+)\s+(AM|PM)/) : '00:00';
    // Create a Date object for the given date and time in UTC
    const utcDate = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes)));

    // Adjust the UTC date if the time is in PM (and not 12 PM)
    if (ampm === 'PM' && hours !== '12') {
      utcDate.setUTCHours(utcDate.getUTCHours() + 12);
    } else if (ampm === 'AM' && hours === '12') {
      // If it's 12 AM, subtract one day (24 hours) to change the date
      utcDate.setUTCDate(utcDate.getUTCDate() - 1);
    }

    // Get the user's current timezone dynamically
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the UTC date to the user's timezone
    const options = { timeZone: userTimeZone, year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    const formattedDate = utcDate.toLocaleString('en-US', options);

    return formattedDate;
  }
  function convertUtcToUserTimeEdit(utcDates, utcTime) {
    let dateForFormat = utcDates.replaceAll('-', '/');
    let dateSorted = `${dateForFormat}, ${utcTime}`;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedInput = dateSorted.replace(/\s+/g, ''); // Remove all spaces
    const adjustedFormat = 'M/d/yyyy,h:mma';

    const utcDate = DateTime.fromFormat(formattedInput, adjustedFormat, {
      zone: 'utc'
    });

    if (!utcDate.isValid) {
      return 'Invalid UTC DateTime'; // Handle invalid input
    }

    const userDate = utcDate.setZone(userTimeZone);

    if (!userDate.isValid) {
      return 'Invalid User DateTime'; // Handle invalid conversion
    }

    // Format the date as '10/05/2023'
    const formattedDate = userDate.toFormat('MM/dd/yyyy');

    // Format the time as '8:00 PM'
    const formattedTime = userDate.toFormat('hh:mm a');

    return `${formattedDate}, ${formattedTime}`;
  }
  function convertToISO8601(inputDateTime) {
    // Ensure that inputDateTime is defined and not null

    // Split the input string by comma and remove any leading/trailing spaces
    const dateTimeParts = inputDateTime.split(',').map(part => part.trim());

    // Split the date and time components
    const dateComponents = dateTimeParts[0].split('/');
    const timeComponents = dateTimeParts[1].split(' ');

    // Extract month, day, year, hours, and minutes
    const month = parseInt(dateComponents[0]);
    const day = parseInt(dateComponents[1]);
    const year = parseInt(dateComponents[2]);
    const time = timeComponents[0];
    const ampm = timeComponents[1];

    // Extract hours and minutes from the time
    const timeParts = time.split(':');
    let hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    // Convert hours to 24-hour format
    if (ampm === 'PM' && hours < 12) {
      hours += 12;
    } else if (ampm === 'AM' && hours === 12) {
      hours = 0;
    }

    // Create a JavaScript Date object
    const date = new Date(year, month - 1, day, hours, minutes);

    // Convert to ISO 8601 format
    const isoDateTime = date.toISOString();

    return isoDateTime;



  }
  const getAllAppointments = async (uniqueTrainers, holidays) => {
    setLoading(true);
    try {
      let cdate = new Date(currentDate);
      let payload = {
        locationId: locationId,
        fromDate: dateFormat(new Date(
          cdate.getFullYear(),
          cdate.getMonth(),
          cdate.getDate() - 7
        )),
        toDate: dateFormat(currentDate, true)
      };
      if (scheduleFilter.scheduleType !== '') {
        if (scheduleFilter.scheduleType !== 'All') {
          payload.scheduleType = scheduleFilter.scheduleType;

        }
      }
      if (scheduleFilter.categoryName !== '') {
        payload.categoryName = scheduleFilter.categoryName;
      }
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAppointments`,
        payload
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      let updatedData = [];
      // added staff appoinments
      updatedData = Item?.map((obj) => {
        let fromDate = convertToUTC(obj?.fromDate);
        let toDate = convertToUTC(obj?.toDate);
        return {
          ...obj,
          trainerId: obj?.trainerId,
          StartTime: convertTimeAndDate(fromDate),
          EndTime: convertTimeAndDate(toDate)
        }

      });
      for (let i = 0; i < uniqueTrainers?.length; i++) {
        // added staff availibilities
        // let availability = uniqueTrainers[i].availableSchedules;
        // for (let j = 0; j < availability?.length; j++) {
        //   let obj = {            
        //     trainerId: uniqueTrainers[i].sortKey,
        //     trainerName: `${uniqueTrainers[i]?.firstName} ${uniqueTrainers[i]?.lastName}`,
        //     isBlock: false,
        //     availableschedules:true,
        //     StartTime: convertTimeAndDate(
        //       `${availability[j].startTime.trim()}`,
        //       dateFormat(currentDate)
        //     ),
        //     EndTime: convertTimeAndDate(
        //       `${availability[j].endTime.trim()}`,
        //       dateFormat(currentDate)
        //     )
        //   };
        //   console.log(obj);
        //   updatedData?.push(obj);
        // }

        // added staff unavailibilities
        let unavailability = uniqueTrainers[i]?.unavailableSchedules;

        for (let k = 0; k < unavailability?.length; k++) {
          const parts = unavailability[k]?.startDate?.split("-");
          const formattedFromDate = parts[1] + "-" + parts[2] + "-" + parts[0];
          const part = unavailability[k]?.endDate?.split("-");
          const formattedToDate = part[1] + "-" + part[2] + "-" + part[0];
          const fromDateFormat = convertUtcToUserTimeEdit(formattedFromDate, unavailability[k]?.startTime)
          const toDateFormat = convertUtcToUserTimeEdit(formattedToDate, unavailability[k]?.endTime)
          let obj = {
            trainerName: `Timeoff`,
            trainerId: uniqueTrainers[i]?.sortKey,
            IsBlock: true,
            isBlock: false,
            holiday: false,
            Timeoff: true,
            StartTime: convertToISO8601(fromDateFormat),
            EndTime: convertToISO8601(toDateFormat),
          };
          updatedData?.push(obj);
        }

        // Holiday      
        let holiday = holidays;
        for (let k = 0; k < holiday?.length; k++) {
          let obj = {
            ...holiday[k],
            trainerId: uniqueTrainers[i]?.sortKey,
            // trainerName: `${uniqueTrainers[i]?.firstName} ${uniqueTrainers[i]?.lastName}`,
          };
          updatedData?.push(obj);
          // added staff availibilities        
        }

      }
      setSchedularData(updatedData);
      setLoading(false);


    } catch (error) {
      setLoading(false);
      // Handle the error here, you can log it or show an error message to the user
      console.error("An error occurred:", error);
    } finally {
      // Set loader to false whether you got data or encountered an error
      setLoading(false);
    }
  };


  // const handleChange = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  // };

  // const handleChangeView = (event, value) => {     
  //   setView(value);
  // };

  const handleNextClick = () => {
    if (alignment === 'week') {
      const currentDateCopy = new Date(currentDate);
      currentDateCopy.setDate(currentDateCopy.getDate() + 7);
      setCurrentDate(currentDateCopy);
    } else {
      const currentDateCopy = new Date(currentDate);
      currentDateCopy.setDate(currentDateCopy.getDate() + 1);
      setCurrentDate(currentDateCopy);
    }
  };

  const handlePreviousClick = () => {
    if (alignment === 'week') {
      const currentDateCopy = new Date(currentDate);
      currentDateCopy.setDate(currentDateCopy.getDate() - 7);
      setCurrentDate(currentDateCopy);
    } else {
      const currentDateCopy = new Date(currentDate);
      currentDateCopy.setDate(currentDateCopy.getDate() - 1);
      setCurrentDate(currentDateCopy);
    }
  };

  const handleDateChange = (date) => {
    setCurrentDate(date);
  };
  // const handleDateChange = (value) => {
  //   const date = new Date(value);
  //   const year = date.getFullYear();
  //   const month = date.getMonth();
  //   const day = date.getDate();
  //   const convertedDate = new Date(year, month, day);
  //   let formDate =convertedDate?.replaceAll('-','/')
  //   setCurrentDate(formDate);
  // };

  const handleChangeFilter = (event, value) => {
    let checked = event.target.checked;
    let list = [...servicesList];
    // eslint-disable-next-line array-callback-return
    list?.map((item) => {
      if (item.sortKey === value.sortKey) {
        item.checked = checked;
        setScheduleFilter({
          ...scheduleFilter,
          categoryName: item.name
        });
      } else {
        item.checked = false;
      }
    });
    setServicesList(list);
  };

  const handleCalendarIconClick = () => {
    setView('grid');
  };

  const handleListIconClick = () => {
    setView('list');
  };

  const handleDayClick = () => {
    setAlignment('day');
  };

  const handleWeekClick = () => {
    setAlignment('week');
  };
  const compareDate = new Date(currentDate);
  // set operating hours
  useEffect(() => {
    setOperatinghours(operatHrsData, alignment);
  }, [operatHrsData, alignment, compareDate]);
  function convertUTCToUserTime(utcTime) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDateTime = DateTime.fromFormat(utcTime, 'h:mm a', { zone: 'UTC' });
    const userTime = utcDateTime.setZone(timeZone);
    return userTime.toFormat('hh:mm a');
  }
  const setOperatinghours = (operatHrsData, view) => {

    try {
      const options = { weekday: 'long' };
      const formattedTime = compareDate?.toLocaleString('en-US', options);
   
      // let weekData = getMaxTimeRange(operatHrsData);
      const earliestStartTime = findEarliestStartTime(operatHrsData);
      const latestEndTime = findLatestEndTime(operatHrsData);
  
      let dayData = operatHrsData?.filter((item) => item.day === formattedTime);
      let startTime = '00:00';
      let endTime = '24:00';

      if (view === 'day') {
        let sTime = convertUTCToUserTime(dayData[0]?.startTime);
        let eTime =convertUTCToUserTime(dayData[0]?.endTime)
        if (sTime) startTime = convertFrom12To24Format(sTime);
        if (eTime) endTime = convertFrom12To24Format(eTime);
      } else {
        if (earliestStartTime) startTime = convertFrom12To24Format(earliestStartTime);
        if (latestEndTime) endTime = convertFrom12To24Format(latestEndTime);
      }
      setStartHour(startTime);
      setEndHour(endTime);
    } catch (error) {
      console.log(error);
    }
  }

  const findEarliestStartTime = (operatHrsData) => {
    if (!operatHrsData || operatHrsData.length === 0) {
      return '12:00 AM';
    }

    let earliestStartTime = '12:00 PM';

    operatHrsData.forEach((item) => {
      const startTime = convertUTCToUserTime(item.startTime);
      if (compareTimes(startTime, earliestStartTime) < 0) {
        earliestStartTime = startTime;
      }
    });

    return earliestStartTime;
  };

  const findLatestEndTime = (operatHrsData) => {
    if (!operatHrsData || operatHrsData.length === 0) {
      return '11:59 PM';
    }

    let latestEndTime = '12:00 AM';

    operatHrsData.forEach((item) => {
      const endTime = convertUTCToUserTime(item.endTime);
      if (compareTimes(endTime, latestEndTime) > 0) {
        latestEndTime = endTime;
      }
    });

    return latestEndTime;
  };
  const compareTimes = (time1, time2) => {
    const date1 = new Date(`2023-01-01T${convertFrom12To24Format(time1)}`);
    const date2 = new Date(`2023-01-01T${convertFrom12To24Format(time2)}`);
    return date1 - date2;
  };

  return (
    <>
      {/* <Loader IsLoading={loading} /> */}
      <Container maxWidth="100%" className="main-container">
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className="add-btn-container"
        >
          <Typography variant="h1" color="primary" className='schedule-header'>
            Schedule
          </Typography>
          <Box>
            <Select
              value={scheduleFilter.scheduleType}
              onChange={(e) => handleFilterChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className=" scheduleFilter"
            >
              <MenuItem key={1} value={'All'}>
                All
              </MenuItem>
              <MenuItem key={2} value={'enrollment'}>
                Classes
              </MenuItem>
              <MenuItem key={3} value={'appointment'}>
                Appointment
              </MenuItem>
            </Select>
          </Box>
        </Grid>
      </Container>
      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          width="100%"
          className="calenderToggle"
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <CalenderMenu
             view={alignment}
              handlePreviousClick={handlePreviousClick}
              handleNextClick={handleNextClick}
              handleDateChange={handleDateChange}
              currentDate={dateFormat(currentDate)}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Button
                id="fade-button"
                // disabled={scheduleFilter.scheduleType === 'appointment'}
                className="filterMenuButton"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <FilterAltOutlinedIcon />
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorEl}
                open={open}
                style={{ maxHeight: '250px', marginTop: '10px' }}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <FormGroup >
                  {servicesList &&
                    servicesList.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        control={<Checkbox checked={item.checked} />}
                        onChange={(e) => handleChangeFilter(e, item)}
                        label={item.name}

                      />
                    ))}
                  {/* <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Obedience"
                  />
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Agility Class"
                  />
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Enrichment Class"
                  /> */}
                </FormGroup>
              </Menu>
            </Box>
            <ToggleButtonGroup
              color="primary"
              value={view}
              exclusive
              className="listToggle"
              // onChange={handleChangeView}
              aria-label="Platform"
            >
              <ToggleButton value="grid" onClick={handleCalendarIconClick}>
                <CalendarMonthIcon />
              </ToggleButton>
              <ToggleButton value="list" onClick={handleListIconClick}>
                <FormatListBulletedIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              className="weekToggles"
              exclusive
              // onChange={handleChange}
              aria-label="Days"
            >
              <ToggleButton value="week" onClick={handleWeekClick}>WEEK</ToggleButton>
              <ToggleButton value="day" onClick={handleDayClick}>DAY</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>        
        {loading ? (
          <CircularProgress sx={{position:'absolute',top:'50%'}}/>
          // <Loader IsLoading={loading} />
        ) : (
          <>
            {view === 'grid' ? (
              <Grid item width="100%">
                <CalenderSchedular
                  view={alignment}
                  dialog={dialog}
                  setDialog={setDialog}
                  currentDate={currentDate}
                  trainerData={trainerData || []}
                  data={schedularData || []}
                  handleOpenModal={handleOpenModal}
                  startHour={startHour}
                  endHour={endHour}
                  operatHrsData={operatHrsData}
                />
              </Grid>
            ) : (
              <ListView
                view={alignment}
                dialog={dialog}
                setDialog={setDialog}
                currentDate={new Date(currentDate)}
                trainerData={trainerData}
                data={schedularData || []}
                handleOpenModal={handleOpenModal}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
}