import React from 'react';

const Receipt = ({ selected }) => {
  const infoWidth = '50%'; // Width for the data above and below the table

  return (
    <div
      className="receipt"
      style={{ border: '1px solid #000', padding: '10px', textAlign: 'center' }}
    >
      <h2 style={{ textAlign: 'left', width: infoWidth }}>Receipt</h2>
      <div
        className="receipt-info"
        style={{
          borderBottom: '1px solid #000',
          marginBottom: '10px',
          textAlign: 'left',
          width: infoWidth,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '5px',
          }}
        >
          <p>Date:</p>
          <p>{new Date().toDateString()}</p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '5px',
          }}
        >
          <p>Customer:</p>
          <p>{`${selected?.client?.firstName} ${selected?.client?.lastName}` || ''}</p>
        </div>
      </div>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #000', padding: '8px', width: '50%' }}>
              Product
            </th>
            <th style={{ border: '1px solid #000', padding: '8px', width: '25%' }}>
              Quantity
            </th>
            <th style={{ border: '1px solid #000', padding: '8px', width: '25%' }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {selected?.items?.map((product, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                {product.itemName}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                {product?.quantity?.toFixed(2) || 1}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                $
                {product?.discountDetails?.memberShip?.discountPrice
                  ? product?.discountDetails?.memberShip?.discountPrice?.toFixed(2)
                  : product?.price?.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="totals" style={{ marginTop: '10px', textAlign: 'right', width: infoWidth }}>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Subtotal:</span>
          <span>${selected?.total?.toFixed(2)}</span>
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Tax:</span>
          <span>${selected?.tax?.toFixed(2)}</span>
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Discount:</span>
          <span>${selected?.discount?.toFixed(2) || 0}</span>
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Total:</span>
          <span>${selected?.totalPrice?.toFixed(2)}</span>
        </p>
      </div>
    </div>
  );
};

export default Receipt;
