/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Day,
  Inject,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective
} from '@syncfusion/ej2-react-schedule';
import './style.css';
import { extend } from '@syncfusion/ej2-base';
import { Stack } from '@mui/system';
import { Avatar, Typography, Box, Button } from '@mui/material';
import { DateTime } from 'luxon';


const StaffTimeOffCalender = ({
  currentDate,
  data,
  trainerData,
  handleOpenModal,
  startHour,
  endHour,
  setendTime,
  setfromTime,
  setendDate,
  setFromDate,
  setselectedTrainerData
}) => {
  let scheduleObj = useRef(null);
  const dataS = extend([], data, null, true);
  const navigate = useNavigate();
  console.log(startHour,endHour,'dateSorteddateSorted');
  const onRenderCell = (args) => {
    if (
      (args.element.classList.contains('e-work-cells') &&
        args.element.classList.length === 1) ||
      (args.element.classList.contains('e-work-cells') &&
        args.element.classList.contains('e-alternate-cells') &&
        args.element.classList.length === 2)
    ) {
      args.element.style.backgroundColor = '#ffff';
    }

    if (
      args.element.classList.contains('e-header-cells') ||
      args.element.classList.contains('e-all-day-cells')
    ) {
      args.element.style.display = 'none';
    }
  };

  function convertUtcToUserTimeEdit(utcDates, utcTime) {
    let dateForFormat = utcDates?.replaceAll('-', '/');
    let dateSorted = `${dateForFormat}, ${utcTime}`;

    const userTimeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
    const formattedInput = dateSorted?.replace(/\s+/g, ''); // Remove all spaces
    const adjustedFormat = 'M/d/yyyy,h:mma';
  
    const utcDate = DateTime?.fromFormat(formattedInput, adjustedFormat, {
      zone: 'utc'
    });
  
    if (!utcDate.isValid) {
      return 'Invalid UTC DateTime'; // Handle invalid input
    }
  
    const userDate = utcDate?.setZone(userTimeZone);
  
    if (!userDate.isValid) {
      return 'Invalid User DateTime'; // Handle invalid conversion
    }
  
    // Format the date as '10/05/2023'
    const formattedDate = userDate?.toFormat('MM/dd/yyyy');
  
    // Format the time as '8:00 PM'
    const formattedTime = userDate?.toFormat('hh:mm a');
  
    return `${formattedDate}, ${formattedTime}`;
}
  function formatDate(inputTime, inputDate) {
    // Ensure that inputTime and inputDate are defined and not null
    if (inputTime && inputDate) {
        // Split the input time string into time components
        const timeComponents = inputTime?.split(':');
        const ampmIndex =
            inputTime.indexOf('AM') !== -1
                ? inputTime.indexOf('AM')
                : inputTime.indexOf('PM');
        const ampm = inputTime.substring(ampmIndex, ampmIndex + 2);

        // Convert hours to 24-hour format
        let hours = parseInt(timeComponents[0]);
        if (ampm === 'PM' && hours < 12) {
            hours += 12;
        } else if (ampm === 'AM' && hours === 12) {
            hours = 0;
        }

        // Split the input date string into date components
        const dateComponents = inputDate.split('-');

        // Create a Date object using the components
        const formattedDate = new Date(
            parseInt(dateComponents[2]),
            parseInt(dateComponents[0]) - 1, // Months are zero-indexed
            parseInt(dateComponents[1]),
            hours,
            parseInt(timeComponents[1])
        );

        // Convert the Date object to ISO string
        const isoString = formattedDate.toISOString();

        return isoString;
    } 
        // Handle the case where inputTime or inputDate is undefined or null
         return console.error('inputTime or inputDate is undefined or null');
        // You can return a default value or handle the error as needed
        // Example: return 'N/A';
 
}
 
function convertToISO8601(inputDateTime) {
  // Ensure that inputDateTime is defined and not null

    // Split the input string by comma and remove any leading/trailing spaces
    
    const dateTimeParts = inputDateTime?.split(',').map(part => part.trim());
    if(dateTimeParts.length>1){
      // alert('hey')
      const dateComponents = dateTimeParts[0]?.split('/');
    const timeComponents = dateTimeParts[1]?.split(' ');

    // Extract month, day, year, hours, and minutes
    const month = parseInt(dateComponents[0]);
    const day = parseInt(dateComponents[1]);
    const year = parseInt(dateComponents[2]);
    const time = timeComponents[0];
    const ampm = timeComponents[1];

    // Extract hours and minutes from the time
    const timeParts = time?.split(':');
    let  hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    // Convert hours to 24-hour format
    if (ampm === 'PM' && hours < 12) {
      hours += 12;
    } else if (ampm === 'AM' && hours === 12) {
      hours = 0;
    }

    // Create a JavaScript Date object
    const date = new Date(year, month - 1, day, hours, minutes);

    // Convert to ISO 8601 format
    const isoDateTime = date.toISOString();

    return isoDateTime;
      }
    // Split the date and time components
    
 return null
   
  
}


  const splitData = [];

  trainerData.forEach((trainer) => {
    if (
      trainer.unavailableSchedules &&
      trainer.unavailableSchedules.length > 0
    ) {
      trainer.unavailableSchedules.forEach((item) => {
        const startDate = new Date(item.startDate);
        const endDate = new Date(item.endDate);
        const startTime = new Date(`1970-01-01T${item.startTime}`);
        const endTime = new Date(`1970-01-01T${item.endTime}`);

        while (startDate <= endDate) {
          const eventStartTime = new Date(startDate);
          eventStartTime.setHours(startTime.getHours());
          eventStartTime.setMinutes(startTime.getMinutes());

          const eventEndTime = new Date(startDate);
          eventEndTime.setHours(endTime.getHours());
          eventEndTime.setMinutes(endTime.getMinutes());

          splitData.push({
            ...item,
            startDate: formatDates(startDate), // Format date as needed
            endDate: formatDates(startDate) // Format date as needed
          });

          startDate.setDate(startDate.getDate() + 1);
        }
      });
    }
  });

  const combinedData = [];
  splitData.forEach((trainer) => {
    let FromDate = convertUtcToUserTimeEdit(trainer.startDate, trainer.startTime )
    let ToDate = convertUtcToUserTimeEdit(trainer.endDate, trainer.endTime)
    combinedData.push({
      Id: trainer.sortKey,
      Subject: `Unavailable`,
      StartTime: convertToISO8601(FromDate),
      EndTime: convertToISO8601(ToDate),
      IsAllDay: false,
      TrainerId: trainer.partitionKey,
      EventType: 'unavailability'
    });
  });

  function formatDates(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}-${day}-${year}`;
  }
console.log(combinedData,'combinedDatacombinedData');
  const resourceHeaderTemplate = (props) => {
    return (
      <Stack flexDirection="row">
        <Avatar
          alt={props?.resourceData?.trainerName}
          src={props?.resourceData?.trainerProfileImage}
        />
        <Stack flexDirection="column" marginLeft="10px">
          <Typography variant="h4">
            {props?.resourceData?.trainerName}
          </Typography>
          <Typography variant="body1">
            {props?.resourceData?.roles[0]?.role}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const onPopupOpen = (args) => {
    const detail = args?.data ? args?.data : args;
    console.log(detail, 'detaildetaildetail');

    const startDateTimeString = detail?.StartTime;

    const originalStartDate = new Date(startDateTimeString);

    const formattedStartTime = originalStartDate
      .toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      .toUpperCase();
    const formattedStartDate = `${
      originalStartDate.getMonth() + 1
    }/${originalStartDate.getDate()}/${originalStartDate.getFullYear()}`;

    const endDateTimeString = detail?.EndTime;

    const originalEndDate = new Date(endDateTimeString);

    const formattedEndTime = originalEndDate
      .toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      .toUpperCase();
    const formattedEndDate = `${
      originalEndDate.getMonth() + 1
    }/${originalEndDate.getDate()}/${originalEndDate.getFullYear()}`;

    setfromTime(formattedStartTime);
    setFromDate(formattedStartDate);
    setendTime(formattedEndTime);
    setendDate(formattedEndDate);
    setselectedTrainerData(detail?.TrainerId);
    handleOpenModal();

    if (
      args.type === 'QuickInfo' ||
      args.type === 'Editor' ||
      args.target.classList.contains('e-selected-cell') ||
      (args.target && args.target.classList.contains('e-work-cells'))
    ) {
      args.cancel = true;
    }
  };

  // navigate to staff managment
  const navigateToAddStaff = () => {
    navigate('/staff-management');
  };

  return (
    <div className="schedule-control-section">
      <div className="col-lg-12 control-section">
        {trainerData?.length > 0 ? (
          <div className="control-wrapper">
            <ScheduleComponent
              ref={(schedule) => (scheduleObj = schedule)}
              allowSwiping={false}
              showTimeIndicator
              cssClass="customStyling"
              width="100%"
              height="800px"
              currentView="Day"
              resourceHeaderTemplate={resourceHeaderTemplate}
              eventSettings={{
                dataSource: combinedData
              }}
              selectedDate={currentDate}
              popupOpen={onPopupOpen}
              renderCell={onRenderCell}
              startHour={startHour}
              endHour={endHour}
              showHeaderBar={false}
            >
              <ResourcesDirective>
                <ResourceDirective
                  field="TrainerId"
                  title="Trainer"
                  name="Trainer"
                  dataSource={trainerData}
                  idField="trainerId"
                  allowMultiple
                />
              </ResourcesDirective>

              <ViewsDirective>
                <ViewDirective
                  option="Day" 
                  group={{ resources: ['Trainer'],enableCompactView: false}}
                />
              </ViewsDirective>
              <Inject services={[Day]} />
            </ScheduleComponent>
          </div>
        ) : (
          <Box mt={5} align={'center'}>
            <Typography variant="h1" className="schedule-header" mb={5}>
              You first need to add a staff to set staff timeoff.
            </Typography>
            <Button
              variant="contained"
              className="top-button-style"
              sx={{ padding: '15px' }}
              onClick={navigateToAddStaff}
              mt={5}
            >
              {' '}
              Add Staff{' '}
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
};
export default StaffTimeOffCalender;