import { Box, Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";

import React, { useState,useEffect } from "react";
import { Helmet } from "react-helmet-async";
import CampaignDetail from "./CampaignDetail";
import ScheduleCampaign from "./ScheduleCampaign";
import { Stack } from "@mui/system";
import ReviewAndLaunchCampaign from "./ReviewAndLaunchCampaign";
import { DiscardPopup } from "./DiscardPopup";
import { ApiService } from "../../../services/ApiService";
import { getLocalData } from "../../../utils";
import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';

// import { ApiService } from "../../../services/ApiService";

const timeTypes = ["immediately", "once", "hourly", "daily", "weekly", "monthly"];
const steps = ["Campaign Details", "Scheduling", "Review & Launch"];

const CreateCampaign = ({ setActive, selected, isNew,getListData }) => {
  // const timeZone = "UTC"; // Pakistan Standard Time
 // const options = { timeZone: timeZone };
 // console.log(new Date().toLocaleString("en-US", options), "Current Time In AMerica");
  const locationId = getLocalData("locationId");
  const franchiseeId = getLocalData("franchiseeId");
  const [activeStep, setActiveStep] = useState(0);
  const [discardDialog, setDiscardDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
 // const [successMsg, setSuccessMsg] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [CampNameExistsErr, setCampNameExistsErr] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
 
  const initialValues = {
    step1: {
      campaignName: selected?.Name || "",
      selectedCohort: selected?.SegmentId || null,
      selectedTemplate: selected?.TemplateConfiguration?.EmailTemplate?.Name || selected?.TemplateConfiguration?.SMSTemplate?.Name || null,
      selectedChannel: selected?.Channel || "EMAIL",
      selectedMessageType: selected?.MessageType || "Immediate",
      senderId: selected?.SenderID || "",
      senderName: selected?.SenderName || "",
      senderEmail: selected?.SenderEmail || "",
      selectedCohortName: selected?.SegmentName || ""
    },
    step2: {
      schedulingOptionType: JSON.stringify(timeTypes.indexOf(selected?.ScheduleType)) || "0",
      // startDate: '',
      // endDate:'',
       startDate: timeTypes.indexOf(selected?.ScheduleType) !== 0 ? new Date(selected?.Schedule.StartTime) : null,
       endDate: timeTypes.indexOf(selected?.ScheduleType) !== 0 ? new Date(selected?.Schedule.EndTime) : null,
      startTime: timeTypes.indexOf(selected?.ScheduleType) !== 0 ? new Date(selected?.Schedule.StartTime) : new Date(),
      endTime: timeTypes.indexOf(selected?.ScheduleType) !== 0 ? new Date(selected?.Schedule.EndTime) : new Date()
    }


  };

  const [formData, setFormData] = useState(initialValues.step1);
  const [formDataSchedule, setFormDataSchedule] = useState(initialValues.step2);

  const isFormDataModified = () => {

    return Object.keys(formData).some(
      (key) => formData[key] !== initialValues.step1[key]
    );
  };
  const isFormDataScheduleModified = () => {
    return Object.keys(formDataSchedule).some(
      (key) => formDataSchedule[key] !== initialValues.step2[key]
    );

  };
  
  const createCampaign = async () => {
    setIsLoading(true)
    // setIsLoading(false)

    const currentDateTime = new Date();
    const isocurrentStartDateTime = currentDateTime?.toISOString();
    currentDateTime.setHours(23, 59, 59, 999);
    const isocurrentEndDateTime = currentDateTime?.toISOString();
     let combinedStrtDateTime;
     let combinedEndDateTimeforStart;
     let combinedEndDateTime;  
     let isoStartDateTime;
     let isoEndDateTime;
    let isocombinedEndDateTimeforStart;
    let sTime = convertTime(formDataSchedule.startTime);
    let eTime = convertTime(formDataSchedule.endTime);
    if(formDataSchedule.schedulingOptionType !== "0"){
     combinedStrtDateTime = new Date(
      new Date(formDataSchedule.startDate).getFullYear(),
      new Date(formDataSchedule.startDate).getMonth(),
      new Date(formDataSchedule.startDate).getDate(),
      new Date(sTime).getHours(),
      new Date(sTime).getMinutes()
    );
     combinedEndDateTimeforStart = new Date(
      new Date(formDataSchedule.endDate).getFullYear(),
      new Date(formDataSchedule.endDate).getMonth(),
      new Date(formDataSchedule.endDate).getDate(),
      new Date(sTime).getHours(),
      new Date(sTime).getMinutes()
    );
   
    if (formDataSchedule.schedulingOptionType === "2") {
     combinedEndDateTime = new Date(
      new Date(formDataSchedule.endDate).getFullYear(),
      new Date(formDataSchedule.endDate).getMonth(),
      new Date(formDataSchedule.endDate).getDate(),
      new Date(eTime).getHours(),
      new Date(eTime).getMinutes()
    );
    }
    
    if (formDataSchedule.schedulingOptionType !== "0") {
     isoStartDateTime = combinedStrtDateTime?.toISOString();
    }
      if(formDataSchedule.schedulingOptionType === "3"||formDataSchedule.schedulingOptionType === "4"||formDataSchedule.schedulingOptionType === "5")
     isocombinedEndDateTimeforStart = combinedEndDateTimeforStart?.toISOString();
    
    if (formDataSchedule.schedulingOptionType === "2") {
       isoEndDateTime =combinedEndDateTime?.toISOString();
    }
  }

    

    let payload = {
      "locationId": locationId,
      "franchiseeId": franchiseeId,
      "Name": formData.campaignName,
      "Channel": formData.selectedChannel.charAt(0).toUpperCase() + formData.selectedChannel.slice(1).toLowerCase(),
      "SegmentName": formData.selectedCohortName,
      // "Template": formData.selectedTemplate.TemplateName,
    
      "SenderName": formData.senderName || "",
      "MessageType": formData.selectedMessageType.charAt(0).toUpperCase() + formData.selectedMessageType.slice(1).toLowerCase(),
     // "SenderID":  formData.selectedChannel === 'Email' ? '' :  formData.senderId ,
      "SegmentId": formData.selectedCohort,
      "TemplateId": formData.selectedTemplate,
      "schedulingOption": {
        "schedulingOptionType": timeTypes[Number(formDataSchedule.schedulingOptionType)],
        "StartTime": formDataSchedule.schedulingOptionType === "0" ? isocurrentStartDateTime : isoStartDateTime , 
        // "EndTime": formDataSchedule.schedulingOptionType === "0" ? isoEndDateTime : combinedEndDateTime,
        "Frequency": timeTypes[Number(formDataSchedule.schedulingOptionType)]
      }
      
    };
    if (formDataSchedule.schedulingOptionType === "2") {
      payload.schedulingOption.EndTime = formDataSchedule.schedulingOptionType === "0" ? isocurrentEndDateTime : isoEndDateTime;
    }
    if (formDataSchedule.schedulingOptionType === "3"||formDataSchedule.schedulingOptionType === "4"||formDataSchedule.schedulingOptionType === "5") {
      payload.schedulingOption.EndTime = formDataSchedule.schedulingOptionType === "0" ? isocurrentEndDateTime : isocombinedEndDateTimeforStart;
    }
    // if(formData.selectedChannel !=='SMS'){
    //   if(formData.senderName){
    //     payload.SenderName= formData.senderName ? formData.senderName  : '' || "";
    //   }
      
    // }
    if(formData.selectedChannel.toUpperCase() !=='SMS'){
      payload.SenderEmail= formData.senderEmail || "";
    }
    if(formData.selectedChannel.toUpperCase() ==='SMS'){
      payload.SenderID= formData.senderId || "";
    }
    console.log('payload',payload)
    try{
      
    let URL = `${process.env.REACT_APP_API_END_POINT}pinpoint/createCampaign`;
    let response;
    if (selected && isNew) {
      // duplicate
      payload.Id = selected.Id;
      payload.partitionKey = selected.partitionKey;
      payload.sortKey = selected.sortKey;
      URL = `${process.env.REACT_APP_API_END_POINT}pinpoint/duplicateCampaign`;
      response = await ApiService.post(
        URL,
        payload
      );
    } else if (selected) {
      // Edit
      payload.Id = selected.Id;
      payload.sortKey = selected.sortKey;
      payload.partitionKey = selected.partitionKey;
      payload.Status = selected.State.CampaignStatus;
      URL = `${process.env.REACT_APP_API_END_POINT}pinpoint/updateCampaign`;
      response = await ApiService.put(
        URL,
        payload
      );
    } else {
      response = await ApiService.post(
        URL,
        payload
      );
    }

    if (response.statusCode === 200) {
      setIsLoading(false)
      setSuccessDialog(true);
     // setSuccessMsg(response?.message)
    }else{
      setIsLoading(false)

    }
  }catch(err){
    console.log(err)
    setIsLoading(false)
    setShowErrorMessage(true);
    setErrorMessage('Something went wrong, try later.');
  }

  };

useEffect(() => {
    getCampaignList();
  }, [formData.campaignName]);
  
  const getCampaignList = async () => {
   /// setCampNameExistsErr('')
   setCampNameExistsErr(false)
    try {
  
    let filtersParams = "";
    let encodedfranchiseeId = encodeURIComponent(franchiseeId);
    let response = await ApiService.get(
      `${process.env.REACT_APP_API_END_POINT}pinpoint/getAllCampaignList?partitionKey=${encodedfranchiseeId}${filtersParams}`
    );
    setIsLoading(false);
     let Item;
    if (response && response?.data && response?.data.Items) {
      setIsLoading(false);
      Item = response.data.Items;
      const filteredItem = Item.filter((item) => item.Name === formData.campaignName)[0];
      if(formData.campaignName === filteredItem.Name){
      setCampNameExistsErr(true)
      }else{
       setCampNameExistsErr(false)
      }
      
    } else {
      setCampNameExistsErr(false)
      setIsLoading(false);
     
    }
   
  } catch(err){
    setCampNameExistsErr(false)
    setIsLoading(false);  
    console.error("Error : ", err);
  }
  };

  
  const handleValidationForDetail = () => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const senderIdPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9\-_.]+$/;
  
    const newErrors = {};
  
    if (!formData.campaignName) {
      newErrors.campaignName = "Campaign name is required";
    }else if(CampNameExistsErr && isNew ){
      newErrors.campaignName = "Campaign name already exists";
    }
  
    if (!formData.selectedCohort) {
      newErrors.selectedCohort = "Please select a cohort";
    }
  
    if (!formData.selectedTemplate) {
      newErrors.selectedTemplate = "Please select a template";
    }
  
    if (!formData.senderEmail && formData.selectedChannel.toUpperCase() === "EMAIL") {
      newErrors.senderEmail = "Sender email is required";
    } else if (formData.senderEmail && !emailPattern.test(formData.senderEmail)) {
      newErrors.senderEmail = "Sender email is invalid";
    }
  
    if (!formData.senderId && formData.selectedChannel === "SMS" ) {
      newErrors.senderId = "Sender Id is required for SMS";
    } else if (formData.senderId  && !senderIdPattern.test(formData.senderId)) {
      newErrors.senderId = "Sender Id must be alphanumeric and should be 11 characters.";
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setActiveStep(activeStep + 1);
      setErrors({});
    }
  };
  
  const handleValidationForSchedule = () => {
    let newErrors = {};
   // let sTime = convertTime(formDataSchedule.startTime);
  //  let eTime = convertTime(formDataSchedule.endTime);
    // const selectedStartDate = formDataSchedule.startDate;
   // const selectedEndDate = formDataSchedule.endDate;
   // const now = new Date();
    if (formDataSchedule.schedulingOptionType === "-1") {
      newErrors.schedulingOptionType = "Scheduling option type is required.";
    } else if (formDataSchedule.schedulingOptionType !== "0") {
      if (!new Date(formDataSchedule.startDate).getTime()) {
        newErrors.startDate = "Please select a valid date.";
      }
      if (!convertTime(formDataSchedule.startTime)) {
        newErrors.startTime = "Please select a valid time.";
      }
      if (formDataSchedule.schedulingOptionType === "2") {
        if (!convertTime(formDataSchedule.endTime)) {
          newErrors.endTime = "Please select a valid time.";
        }
      }
      if (formDataSchedule.schedulingOptionType !== "1") {
        if (!new Date(formDataSchedule.endDate).getTime()) {
          newErrors.endDate = "Please select a valid date.";
        }
      }
  
     
  
      if (formDataSchedule.startTime && formDataSchedule.startDate) {
        const selectedStartDateTime = new Date(formDataSchedule.startDate + ' ' + formDataSchedule.startTime);
  
        if (selectedStartDateTime < (new Date())) {
          newErrors.startTime = 'Selected time can not be in the past';
        } 
      }
      if (formDataSchedule.endTime && formDataSchedule.endDate) {
        const selectedEndDateTime = new Date(formDataSchedule.endDate + ' ' + formDataSchedule.endTime);
  
        if (selectedEndDateTime < (new Date())) {
          newErrors.endTime = 'Selected time can not be in the past';
        } 
      }
    } else {
      newErrors = {}; // Reset newErrors when no errors are found
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    // if (Object.keys(newErrors).length > 0) {
    //   setErrors(newErrors);
    //   return;
    // }
  
    setActiveStep(activeStep + 1);
    setErrors({});
  };
  

  
  function convertTime(inputTime) {
    if (typeof inputTime !== 'string' || !/^\d{2}:\d{2} (AM|PM)$/i.test(inputTime)) {
      return null; // or some default value
  }
 
    // Split the input time into hours, minutes, and meridian
    const [, hours, minutes, meridian] = inputTime.match(/(\d{2}):(\d{2}) (AM|PM)/i);
 
    // Create a Date object
    const now = new Date();
 
    // Set the time based on the input time
    now.setHours(meridian.toUpperCase() === 'AM' ? parseInt(hours, 10) : parseInt(hours, 10) + 12);
    now.setMinutes(parseInt(minutes, 10));
    now.setSeconds(0);
 
    // Format the date to the desired format
    const options = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // Display time in 24-hour format
    };
 
    // Get the formatted date and time string
    const formattedDate = now.toLocaleString('en-US', options);
 
    // Format the time zone offset as GMT+0530
    const timeZoneOffset = 'GMT+0530';
 
    const formattedTime = formattedDate.replace(/,/g, '') + ' ' + timeZoneOffset + ' (India Standard Time)';
 
    return formattedTime;
  }
const inputTime = "10:25 AM";
console.log(convertTime(inputTime));

  
  const handleSteps = () => {
    if (activeStep === 0) {
      handleValidationForDetail();
    } else if (activeStep === 1) {
      handleValidationForSchedule();
     
    } else {
      createCampaign();
    }
  };
  const handlePreviousSteps = () => {
    if(isNew === 'Edit'){
     // formDataSchedule.schedulingOptionType = ''
      formDataSchedule.startDate = ''
      formDataSchedule.endDate = ''
      formDataSchedule.startTime = ''
      formDataSchedule.endTime = ''
    }
    
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    } else {
      setActive(0);
    }
  };
  const handleDiscardPopup = (type) => {

    if (type === "discard") {
      setFormData(initialValues.step1);
      setFormDataSchedule(initialValues.step2);
      setActive(0);
    }
    if (type === "save") {
      setDiscardDialog(false);
    }
    if (type === null) {
      setDiscardDialog(false);
    }
    if (type === "close") {
      setActive(0);
    }
  };
  const handleSuccessDialog = (type) => {

    if (type === "close") {
      setActive(0);
      setSuccessDialog(false);
    }
  };
  const handleBackClick = () => {
    if (isFormDataModified() && isFormDataScheduleModified()) {
      setDiscardDialog(true);
    } else if (selected) {
      setActive(0);
    } else {
      setActive(0);
    }

  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      <Box
      sx={{mt:2}}
        className="main-sales-details"
      >
        <Helmet >
          <title>{isNew ? "Create" : "Edit"} a Campaign</title>
        </Helmet>
        <Typography className="page-title" variant="h1">
          <Box
            className="back-arrow-close"
            onClick={handleBackClick}
            sx={{
              gap:10,
              marginLeft: "40px"
            }}
          >
            <svg
              width="27"
              height="19"
              viewBox="0 0 27 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                fill="#003087"
              />
            </svg>
           <span style={{marginLeft:4}}> {isNew ? "Create" : "Edit"} a Campaign</span>
          </Box>
        </Typography>

        <Box paddingY="12px">
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "16px",
              alignItems: "center",
              marginLeft: "0px",
              width: "100%"
            }}
          >
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                width: "100%",
                background: "transparent",
                paddingY: "30px"
              }}
              className="stepper-change"
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Box>

        {(() => {
          switch (activeStep) {
            case 1:
              return (
                <ScheduleCampaign formData={formDataSchedule} getListData={getListData} setFormData={setFormDataSchedule} errors={errors} />
              );
            case 2:
              return (
                <ReviewAndLaunchCampaign formData={{ ...formData, ...formDataSchedule }} CampaignDetail={selected}
                />
              );
            default:
              return (
                <CampaignDetail formData={formData} setFormData={setFormData} errors={errors} />
              );
          }
        })()}
        <Box className="campaign-footer">
          <Stack
            spacing={1}
            direction="row"
            className="gift-card-buttons"
          >

            <Button
              variant="outlined"
              className="top-button-style"
              onClick={handlePreviousSteps}
              // onClick={() => {
              //   if (activeStep !== 0) {
              //     setActiveStep(activeStep - 1);
              //   } else {
              //     setActive(0);
              //   }
              // }}
            >
              {activeStep !== 0 ? "Previous" : "Cancel"}
            </Button>

            <Button
              variant="contained"
              className="top-button-style"
              onClick={handleSteps}
            >
              {activeStep !== 2 ? "Next" : "Launch Campaign"}

            </Button>
          </Stack>
        </Box>

      </Box>
      <DiscardPopup open={successDialog} handleDialog={handleSuccessDialog}
                    success={{ title: "Congratulations", content: isNew ? "Campaign has been created successfully!" : "Campaign details has been updated successfully!"}} />
      <DiscardPopup open={discardDialog} handleDialog={handleDiscardPopup}
      />
    </>
  );
};
export default CreateCampaign;