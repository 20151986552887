import { React, useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Card,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Grid,
  Radio,
  CardHeader,
  TablePagination,
  Box
} from '@mui/material';
import './styles.css';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import SearchIcon from '@mui/icons-material/Search';
import { BundlesFilter } from '../../../components/Filters/BundlesFilter';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { filtersQuery } from '../../../utils/index';

const BundlesTable = ({
  setSelectItem,
  bundlesList,
  getBundle,
  setFiltersParams,
  selectItem
}) => {
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState(''); // Created new state for search functionality
  const [page, setPage] = useState(0);
  const [showTable] = useState(true);
  const handleBundleSelect = (bundle) => {
    // console.log(bundle, 'bundlebundle')
    setSelectedBundle(bundle);
    setSelectItem(bundle);
  };
  const handleRadioChange = (event, bundle) => {
    if (event.target.checked) {
      setSelectedBundle(bundle);
      setSelectItem(bundle);
    }
  };

  console.log(selectItem, 'selectedBundleselectedBundle');
  useEffect(() => {
    if (selectItem?.sortKey) {
      setSelectedBundle(selectItem);
      setSelectItem(selectItem);
    }
  }, []);

  let [filters, setFilters] = useState({
    status: 1,
    discount: ''
  });
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  let queryString = filtersQuery(filters);

  useEffect(() => {
    let filtersParams = queryString ? `&${queryString}` : '';
    setFiltersParams(filtersParams);
    if (filtersParams) getBundle();
  }, [filters]);

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredLists =
    bundlesList &&
    bundlesList.filter((item) => {
      return Object.values(item).some((value) =>
        value ? value.toString().toLowerCase().includes(searchText) : ''
      );
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedLists =
    filteredLists &&
    filteredLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  const showFilter = () => {
    setdisplayFilterForm(!displayFilterForm);
    console.log(displayFilterForm, 'displayFilterForm-------');
  };

  return (
    <>
      {showTable && (
        <Grid className="featured-items">
          {/* <Button variant="outlined" className="filter-search filter-btn">
            Filter (All)
            <KeyboardArrowDownRoundedIcon />
          </Button>
          <TextField
            type="search"
            label="Search"
            className="filter-search search-btn"
            InputProps={{ endAdornment: <SearchIcon /> }}
          /> */}
          <Grid xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                {/* <Button variant="outlined" className="filter-search filter-btn">
                  Filter (All)
                  <KeyboardArrowDownRoundedIcon />
                </Button> */}
                <Button
                  onClick={showFilter}
                  variant={displayFilterForm ? 'contained' : 'outlined'}
                >
                  Filters
                  <KeyboardArrowDownRoundedIcon
                    className={clsx(
                      !displayFilterForm && classes.closeY,
                      displayFilterForm && classes.openY
                    )}
                  />
                </Button>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
                className="top-filter-cont"
              >
                <TextField
                  type="search"
                  label="Search"
                  className="filter-search search-btn"
                  InputProps={{ endAdornment: <SearchIcon /> }}
                  onChange={handleSearchTextChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {displayFilterForm === true ? (
              <Grid item width={getFilterSize()} sx={{ marginTop: '30px' }}>
                {/* <Filter
                  filters={sideBarfilters}
                  setFilters={setSidebarFilters}
                  getStates={states}
                /> */}
                <BundlesFilter setFilters={setFilters} filters={filters} />
              </Grid>
            ) : null}
            <Grid
              item
              sx={{
                width: `calc(100% - ${getFilterSize()}px)`
              }}
            >
              <Card className="Card">
                {/* <h2 className="table-heading table-header-name">All Bundles</h2> */}
                <CardHeader
                  title="All Bundles"
                  className="table-header-name font-20 text-primary"
                />
                <TableContainer className="featured-table-container">
                  <Table>
                    <TableHead className="feature-table-head">
                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell>Bundle Name</TableCell>
                        <TableCell>Pricing Option & Products</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Bundle Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body-data">
                      {paginatedLists &&
                        paginatedLists.map((row) => (
                          <TableRow
                            onClick={() => handleBundleSelect(row)}
                            key={row.bundleId}
                            selected={selectedBundle?.sortKey === row?.sortKey}
                          >
                            <TableCell className="table-cell-data">
                              <Radio
                                color="primary"
                                checked={
                                  selectedBundle?.sortKey === row?.sortKey
                                }
                                onChange={(event) =>
                                  handleRadioChange(event, row)
                                }
                              />
                            </TableCell>
                            <TableCell className="table-cell-data">
                              {row.bundleName}
                            </TableCell>
                            <TableCell className="table-cell-data">
                              {row.items &&
                                row.items.slice(0, 3).map((item, index) => (
                                  <span key={index}>
                                    {item.itemName && item.itemName}{' '}
                                    {index < 2 ? ', ' : ''}
                                    {index === 2 ? '...' : ''}
                                  </span>
                                ))}

                            </TableCell>
                            <TableCell className="table-cell-data">
                              {row.discount && row.discount + '%'}
                            </TableCell>
                            <TableCell className="table-cell-data">
                              {row.bundlePrice && '$' + row.bundlePrice}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box p={2}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={filteredLists && filteredLists.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BundlesTable;
