import { React, useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Card,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Grid,
  Radio,
  CardHeader,
  Typography,
  TablePagination,
  Box
} from '@mui/material';
import './styles.css';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import SearchIcon from '@mui/icons-material/Search';
// import { BundlesFilter } from '../../../components/Filters/BundlesFilter';
import { MembershipFilters } from 'src/components/Memberships';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { filtersQuery } from '../../../utils/index';

const MembershipTable = ({
  setSelectItem,
  membershipList,
  getMembership,
  setFiltersMemberParams,
  selectItem
}) => {
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState(''); // Created new state for search functionality
  const [page, setPage] = useState(0);
  const [showTable] = useState(true);

  const handleMembershipSelect = (Membership) => {
    setSelectedMembership(Membership);
    setSelectItem(Membership);
  };

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredLists =
    membershipList &&
    membershipList.filter((item) => {
      return Object.values(item).some((value) =>
        value ? value.toString().toLowerCase().includes(searchText) : ''
      );
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedLists =
    filteredLists &&
    filteredLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const [filters, setFilters] = useState({
    status: 1,
    name: null,
    activeMembers: null,
    billingCycle: null
  });
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  let queryString = filtersQuery(filters);

  useEffect(() => {
    let filtersParams = queryString ? `&${queryString}` : '';
    setFiltersMemberParams(filtersParams);
    if (filtersParams) getMembership();
  }, [filters]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  const showFilter = () => {
    setdisplayFilterForm(!displayFilterForm);
  };

  function getBillingCycleValue(value) {
    if (value) {
      return 'Per Year';
    }
    return 'Per Month';
  }

  useEffect(() => {
    if (selectItem?.sortKey) {
      setSelectedMembership(selectItem);
      setSelectItem(selectItem);
    }
  }, []);

  return (
    <>
      {showTable && (
        <Grid className="featured-items">
          <Grid xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                {/* <Button variant="outlined" className="filter-search filter-btn">
                  Filter (All)
                  <KeyboardArrowDownRoundedIcon />
                </Button> */}
                <Button
                  onClick={showFilter}
                  variant={displayFilterForm ? 'contained' : 'outlined'}
                >
                  Filters
                  <KeyboardArrowDownRoundedIcon
                    className={clsx(
                      !displayFilterForm && classes.closeY,
                      displayFilterForm && classes.openY
                    )}
                  />
                </Button>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
                className="top-filter-cont"
              >
                <TextField
                  type="search"
                  label="Search"
                  className="filter-search search-btn"
                  InputProps={{ endAdornment: <SearchIcon /> }}
                  onChange={handleSearchTextChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {displayFilterForm === true ? (
              <Grid item width={getFilterSize()} sx={{ marginTop: '30px' }}>
                {/* <Filter
                  filters={sideBarfilters}
                  setFilters={setSidebarFilters}
                  getStates={states}
                /> */}
                {/* <BundlesFilter
                  setFilters={setFilters}
                  filters={filters}
                /> */}
                <MembershipFilters
                  filters={filters}
                  setFilters={setFilters}
                  data={membershipList}
                />
              </Grid>
            ) : null}
            <Grid
              item
              sx={{
                width: `calc(100% - ${getFilterSize()}px)`
              }}
            >
              <Card className="Card">
                {/* <h2 className="table-heading">All Memberships</h2> */}
                <CardHeader
                  title="All Memberships"
                  className="table-header-name font-20 text-primary"
                />
                <TableContainer className="featured-table-container">
                  <Table>
                    <TableHead className="feature-table-head">
                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell>Membership Name</TableCell>
                        <TableCell>Label</TableCell>
                        <TableCell>Active Members</TableCell>
                        {/* <TableCell>Pricing Options</TableCell> */}
                        <TableCell>Billing Frequency</TableCell>
                        <TableCell>Membership Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body-data">
                      {paginatedLists &&
                        paginatedLists.map((row) => (
                          <TableRow
                            onClick={() => handleMembershipSelect(row)}
                            key={row.membershipId}
                            selected={
                              selectedMembership?.sortKey === row?.sortKey
                            }
                          >
                            <TableCell className="table-cell-data">
                              <Radio
                                color="primary"
                                checked={
                                  selectedMembership?.sortKey === row?.sortKey
                                }
                              />
                            </TableCell>
                            <TableCell className="table-cell-data">
                              {row.name}
                            </TableCell>
                            <TableCell className="table-cell-data">
                              {/* {row.label} */}
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                                className="td-center"
                              >
                                <div
                                  className="membership-label-color"
                                  style={{ backgroundColor: row.label }}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell className="table-cell-data">
                              {row.activeMembers}
                            </TableCell>
                            {/* <TableCell className="table-cell-data">
                              {row?.benefits && row?.benefits[0]?.itemName}
                            </TableCell> */}
                            <TableCell className="table-cell-data">
                              {getBillingCycleValue(row?.billingCycle)}
                            </TableCell>
                            <TableCell className="table-cell-data">
                              {row.price && '$' + row.price}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box p={2}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={filteredLists && filteredLists.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MembershipTable;
