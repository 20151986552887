import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Slide
} from '@mui/material';

import { formatPhoneNumber, enterNumberOnly } from '../../../utils/index';
import Loader from '../../../components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddSupplier = ({ setShowScreen, locationId, refreshSupplierData }) => {
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Manage supplier';
  // const title = GlobalData[0].title;

  let userId = sessionStorage.getItem('UserId');
  /* States to set the value of form field */
  const [supplierName, setSupplierName] = useState(null);
  const [supplierContact, setSupplierContact] = useState(null);
  const [supplierEmail, setSupplierEmail] = useState(null);
  const [supplierPhone, setSupplierPhone] = useState(null);
  const [supplierUrl, setSupplierUrl] = useState(null);
  const [supplierAccountNumber, setSupplierAccountNumber] = useState(null);
  const [primaryAddress, setPrimaryAddress] = useState(null);
  const [address2, setAddress2] = useState(null);
  // const [state,setState] = useState(null);
  const [city, setCity] = useState(null);
  const [statesList, SetStatesList] = useState([]);
  const [stateValue, setStateValue] = useState(null);
  const [notes, setNotes] = useState(null);

  /* States to set the error messages */

  const [supplierNameError, setSupplierNameError] = useState('');
  // const [accountNumberError, setAccountNumberError] = useState('')
  const [supplierEmailError, setSupplierEmailError] = useState('');
  const [supplierURLError, setSupplierURLError] = useState('');

  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isCreated, setisCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [allFields,setAllFields] = useState(false);
  // get user role and provide access of that roles
  //   const isFranchisor = getLocalData('isFranchisor');
  //   const isPrimaryOwner = getLocalData('isPrimaryOwner');

  useEffect(() => {
    getStates();
  }, []);

  /* Function to clear all fields after closing form */

  const clearFields = () => {
    setSupplierName('');
    setSupplierContact('');
    setSupplierEmail('');
    setSupplierPhone('');
    setSupplierUrl('');
    setSupplierAccountNumber('');
    setPrimaryAddress('');
    setAddress2('');
    // setState('');
    setCity('');
    setStateValue('');
    setNotes('');
  };

  const handleClose = () => {
    if (isFieldUpdated) {
      setOpenConfirmDialog(true);
    } else {
      clearFields();
      // setOpenAddSupplier(false);
      // setOpen(false);
      setShowScreen(1);
    }
  };

  /* Function to close the confirmation dialog */

  const handleCloseConfirm = (val) => {
    if (val === 'Discard') {
      // setOpen(false);
      setOpenConfirmDialog(false);
      setIsFieldUpdated(false);
      setShowScreen(1);
      clearFields();
    }
    if (val === 'Continue') {
      setOpenConfirmDialog(false);
    }
    // setOpenConfirmDialog(false);
  };

  /* Function to get states */

  const getStates = () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}locations/getAllStates`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          SetStatesList(Item);
        }
      });
  };

  /* Function to set the values of input fields */

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'Supplier Name') {
      if (value === '') {
        setSupplierName('');
        setSupplierNameError('Supplier name is required');
      } else {
        setSupplierNameError('');
        setSupplierName(value);
        setIsFieldUpdated(true);
      }
    }

    if (name === 'Supplier Contact') {
      let formattedPhone = formatPhoneNumber(value);
      // setSupplierContact(value);
      setSupplierContact(formattedPhone);
      setIsFieldUpdated(true);
    }
    if (name === 'Supplier Email') {
      if (!/\S+@\S+\.\S+/.test(value)) {
        setSupplierEmailError('Please enter a valid email address');
        setIsFieldUpdated(true);
        setSupplierEmail(value);
      } else {
        setSupplierEmailError('');
        setSupplierEmail(value);
        setIsFieldUpdated(true);
      }
    }
    if (name === 'Supplier Phone') {
      let formattedPhone = formatPhoneNumber(value);
      setSupplierPhone(formattedPhone);
      setIsFieldUpdated(true);
    }
    if (name === 'Supplier Company URL') {
      const URL_REGEX = /^(http|https):\/\/([a-z]*\.)?[a-z]*\.[a-z]{2,}(\/)?$/;

      if (e.target.value && !URL_REGEX.test(e.target.value)) {
        setSupplierURLError('Supplier url is not valid');
        setIsFieldUpdated(true);
        setSupplierUrl(value);
      } else if (e.target.value === '') {
        setSupplierURLError('');
        setSupplierUrl(value);
        setIsFieldUpdated(false);
      } else {
        setSupplierURLError('');
        setSupplierUrl(value);
        setIsFieldUpdated(true);
      }
    }
    if (name === 'Account Number') {
      if (value === '') {
        setSupplierAccountNumber('');
        // setAccountNumberError('Account number is required');
        setIsFieldUpdated(true);
      } else {
        // setAccountNumberError('')
        setSupplierAccountNumber(value);
        setIsFieldUpdated(true);
        let formattedAccountNumber = enterNumberOnly(value);
        setSupplierAccountNumber(formattedAccountNumber);
      }
    }
    if (name === 'Primary Address') {
      setPrimaryAddress(value);
      setIsFieldUpdated(true);
    }
    if (name === 'Address 2') {
      setAddress2(value);
      setIsFieldUpdated(true);
    }
    if (name === 'state') {
      setStateValue(value);
      setIsFieldUpdated(true);
    }
    if (name === 'City') {
      setCity(e.target.value);
      setIsFieldUpdated(true);
    }
    if (name === 'NOTES') {
      setNotes(e.target.value);
      setIsFieldUpdated(true);
    }
  };

  /* Function to add supplier details */
  const addSupplier = () => {
    setIsLoading(true);
    let body = JSON.stringify({
      locationId: locationId || '',
      addressLine1: primaryAddress || '',
      addressLine2: address2 || '',
      city: city || '',
      createdBy: userId,
      email: supplierEmail || '',
      notes: notes || '',
      phone: supplierPhone || '',
      state: stateValue || '',
      supplierName: supplierName || '',
      websiteURL: supplierUrl || '',
      accountNumber: supplierAccountNumber || '',
    });
    //    body =  Object.keys(body).forEach(
    //         (key) => (body[key] === null) && delete body[key]);
    //     console.log(body);

    let encodedLocationId = encodeURIComponent(locationId);
    fetch(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}suppliers/addSupplier?locationId=${encodedLocationId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: body
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.statusCode && !data.error) {
          setIsLoading(false);
          setisCreated(true);
          setOpenConfirmDialog(true);
          refreshSupplierData();
        }
      });
  };

  /* Function to add supplier */
  const confirmAddSupplier = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!supplierName) {
      setSupplierNameError('Supplier name is required');
    }
    // if (!supplierAccountNumber) {
    //     setAccountNumberError('Account number is required');
    // }
    else {
      addSupplier();
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Box className="Main-container common-form-container">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box onClick={() => handleClose()} className="back-arrow-close">
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>
              <Typography className="header-blue-32">
                Add New Supplier
              </Typography>
            </Box>
          </Grid>
          
        </Grid> 
        <form>
          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={6} className="input-field-cont">
              <TextField
                fullWidth
                name="Supplier Name"
                autocomplete="Supplier Name"
                label="SUPPLIER NAME"
                value={supplierName}
                onChange={handleInputChange}
                error={Boolean(supplierNameError)}
                helperText={supplierNameError}
                className="input-field-styling required-field"
                InputLabelProps={{
                  className: 'label-style-create-franchisee required-label'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="input-field-cont">
              <TextField
                fullWidth
                name="Supplier Contact"
                autocomplete="Supplier Contact"
                label="SUPPLIER CONTACT"
                value={supplierContact}
                onChange={handleInputChange}
                // error={Boolean(supplierNameError)}
                // helperText={supplierNameError}
                className="input-field-styling "
                InputLabelProps={{
                  className: 'label-style-create-franchisee '
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="input-field-cont">
              <TextField
                fullWidth
                name="Supplier Email"
                autocomplete="Supplier Email"
                label="SUPPLIER EMAIL"
                value={supplierEmail}
                onChange={handleInputChange}
                error={Boolean(supplierEmailError)}
                helperText={supplierEmailError}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="input-field-cont">
              <TextField
                fullWidth
                name="Supplier Phone"
                autocomplete="Supplier Phone"
                label="SUPPLIER PHONE"
                value={supplierPhone}
                onChange={handleInputChange}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="input-field-cont">
              <TextField
                fullWidth
                name="Supplier Company URL"
                autocomplete="Supplier Company URL"
                label="SUPPLIER COMPANY URL"
                value={supplierUrl}
                onChange={handleInputChange}
                error={Boolean(supplierURLError)}
                helperText={supplierURLError}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="input-field-cont">
              <TextField
                fullWidth
                name="Account Number"
                autocomplete="Account Number"
                label="ACCOUNT NUMBER"
                value={supplierAccountNumber}
                onChange={handleInputChange}
                // error={Boolean(accountNumberError)}
                // helperText={accountNumberError}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                inputProps={{
                  maxLength:10
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="input-field-cont">
              <TextField
                fullWidth
                name="Primary Address"
                autocomplete="Primary Address"
                label="ADDRESS LINE 1"
                value={primaryAddress}
                onChange={handleInputChange}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="input-field-cont">
              <TextField
                fullWidth
                name="Address 2"
                autocomplete="Address 2"
                label="ADDRESS LINE 2"
                value={address2}
                onChange={handleInputChange}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth className="select-drop-down-label-styling">
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling"
                >
                  STATE
                </InputLabel>
                <Select
                  name="state"
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="STATE"
                  className="input-field-styling"
                  MenuProps={{
                    PaperProps: { style: { maxHeight: 200 } }
                  }}
                  value={stateValue}
                  onChange={handleInputChange}
                >
                  {statesList.map((state) => (
                    <MenuItem key={state.state} value={state.state}>
                      {state.state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className="input-field-cont">
              <TextField
                fullWidth
                name="City"
                autocomplete="City"
                label="CITY"
                value={city}
                onChange={handleInputChange}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{}}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                multiline
                id="my-textarea"
                name="NOTES"
                autocomplete="NOTES"
                label="NOTES"
                placeholder="Notes here . . . "
                variant="outlined"
                value={notes}
                type="text"
                className="location-description field-top-margin-edit-location"
                onChange={handleInputChange}
                // error={Boolean(productDescriptionError)}
                // helperText={productDescriptionError}
                // onChange={(e) =>
                //     productDescriptionSet(e.target.value)
                // }
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{ rows: 6 }}
              />
            </Grid>
          </Grid>
        </form>
        <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '10px'
            }}
          >
            <Button
              type="button"
              variant="contained"
              className="btn-primary common-button"
              onClick={(e) => confirmAddSupplier(e)}
            >
              <Typography className="button-text">
                Save Supplier Profile
              </Typography>
            </Button>
          </Grid>
      </Box>

      <Dialog
        open={openConfirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes, what do you want
                  <Typography className="title-text-sub">to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Supplier has been created successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Go back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddSupplier;