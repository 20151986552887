import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  //   TextField,
  TablePagination,
  CardHeader,
  Card,
  Grid,
  Box,
  Checkbox
} from '@mui/material';

const RoomsTable = ({
  data,
  label,
  handleOnClick = () => {
    console.log('no event');
  },
  selectedLists,
  setSelectedLists
}) => {
  //   const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [keys, setKeys] = useState([]);

  const filteredData = data.filter((item) => {
    return Object.values(item).some((value) =>
      value ? value.toString().toLowerCase() : ''
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const childOnClick = (e, rest) => {
    e.preventDefault();
    e.stopPropagation();
    handleOnClick(rest);
  };

  //   const onChange = (e, value) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     handleOnClick([value, value, 'Type']);
  //     setPromotionsType(value);
  //   };

  useEffect(() => {
    let key = data && data.length > 0 && data[0] ? Object.keys(data[0]) : '';
    if (key) setKeys(key);
    // setKeys(['name', 'age', 'profession']);
  }, [data]);

  // Handler for select all items
  const handleSelectAllItems = (event) => {
    setSelectedLists(
      event.target.checked ? data.map((Item) => Item.sortKey) : []
    );
  };

  // Handler for select one item
  const handleSelectOneItem = (event, ItemId) => {
    event.stopPropagation();
    // event.preventDefault();
    // if (selectedLists && selectedLists.length > 0) {
    if (selectedLists && !selectedLists.includes(ItemId)) {
      setSelectedLists((prevSelected) => [...prevSelected, ItemId]);
    } else {
      setSelectedLists((prevSelected) =>
        prevSelected.filter((id) => id !== ItemId)
      );
    }
    // }
  };

  // Handler for selected items
  const selectedSomeItems =
    (selectedLists && selectedLists.length) > 0 &&
    selectedLists.length < data.length;
  const selectedAllItems =
    (selectedLists && selectedLists.length) === data.length;

  return (
    <Box>
      <Grid container>
        <Grid item sx={{ width: '100%' }}>
          <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={5}
                className="d-flex align-item-center table-header-title"
              >
                <CardHeader
                  title={label}
                  className="table-header-name font-20 text-primary"
                />
              </Grid>
            </Grid>
            <TableContainer className="Custom-Table table-container common-table-container">
              <Table>
                <TableHead className="common-header">
                  <TableRow key={0}>
                    {/* Header checkbox */}
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={selectedAllItems}
                        indeterminate={selectedSomeItems}
                        onChange={handleSelectAllItems}
                      />
                    </TableCell>
                    {keys.map((item, indexhead) =>
                      indexhead > 1 ? (
                        <TableCell
                          key={indexhead}
                          className={
                            indexhead < 2 ? 'h-partitionKey h-sortKey' : ''
                          }
                        >
                          {item}
                        </TableCell>
                      ) : (
                        ''
                      )
                    )}
                    {/* <TableCell /> */}
                    {/* Add more table headers as needed */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((item, indexpage) => {
                    const isItemSelected =
                      selectedLists &&
                      selectedLists.length > 0 &&
                      selectedLists.includes(item.sortKey);
                    return (
                      <>
                        <TableRow
                          key={indexpage}
                          className="table-row cursor-pointer"
                          selected={isItemSelected}
                        >
                          {/* Checkbox for individual rows */}
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleSelectOneItem(event, item.sortKey)
                              }
                              value={isItemSelected}
                            />
                          </TableCell>
                          {keys.map((key, indexkey) =>
                            indexkey > 1 ? (
                              <TableCell
                                key={indexkey}
                                onClick={(e) =>
                                  childOnClick(e, [
                                    item['partitionKey'],
                                    item['sortKey'],
                                    'Detail'
                                  ])
                                }
                              >
                                {item[key]}
                              </TableCell>
                            ) : (
                              ''
                            )
                          )}
                          {/* <TableCell className="table-td">
                            <IconButton
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"
                              className="edit-btn"
                              id={'edit_btn_' + keys.sortKey}
                              onClick={(e) =>
                                childOnClick(e, [
                                  item['partitionKey'],
                                  item['sortKey'],
                                  'Edit'
                                ])
                              }
                            >
                              Edit
                            </IconButton>
                          </TableCell> */}
                          {/* Add more table cells as needed */}
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default RoomsTable;
