import React, { useState ,useEffect } from 'react';
import { Box, Checkbox, Divider, Stack, Typography, Grid, TextField, InputAdornment } from '@mui/material';
import { DynamicButton, DynamicModal } from '../../../../components/Checkout';
import { getLocalData } from 'src/utils';
import { CustomTable } from '../TableComponent/CustomTable';
import { ApiService } from 'src/services/ApiService';
import Loader from 'src/components/Loader';


export const OrderSummary = ({ selected , handlePrevious, setSuccess, setSelected, setActiveStep }) => {
  const userId = getLocalData('userId');
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');

  let BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  let DOMAIN_URL = `${process.env.REACT_APP_DOMAIN_END_POINT}`;
  let PAYMENT_APP = `${process.env.REACT_APP_PAYMENTAPPNAME}`;


  const [checkTerms, setCheckTerms] = useState(false);
  const [successOrder, setSuccessOrder] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [serviceFee, setServiceFee] = useState()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'service') {
      const formattedValue = limitDecimalDigits(value, 2);
      setServiceFee(formattedValue);
    }
  };

  console.log(serviceFee, "service fee is consoled here")

  function limitDecimalDigits(value, maxDigits) {
    const formattedValue = value?.replace(/[^0-9.]/g, '');
    const decimalParts = formattedValue?.split('.');
  
    if (decimalParts.length > 1) {
      decimalParts[1] = decimalParts[1]?.slice(0, maxDigits);
    }
    return decimalParts?.join('.');
  }

  const discount = selected?.totalDiscount || 0.0;
  useEffect(() => {
    // Update the active step value in local storage
    localStorage.setItem('activeStep', '3');
  }, []);

  const isContract = selected?.items?.filter(item => item?.itemType === 'Contract')?.length > 0;

  useEffect(() => {
    if (successOrder) {
      setSuccess(successOrder);
    }
    if (error) {
      setOpen(true);
    }
  }, [successOrder, error]);

  useEffect(()=> {
    calculateOrder()
  },[])

// FUNCTION TO GENERATE RANDOM IDEMPOTENCYKEY start ----

  const generateIdempotencyKey = () => {
    const hexDigits = '0123456789abcdef';
    let uuid = '';
    for (let i = 0; i < 36; i++) {
      if (i === 14) {
        uuid += '4'; // Version 4 UUID
      } else if (i === 19) {
        uuid += hexDigits[(Math.floor(Math.random() * 4) + 8)]; // Variant (8, 9, A, or B)
      } else {
        uuid += hexDigits[Math.floor(Math.random() * 16)];
      }
    }
    return uuid;
  };

  // FUNCTION TO GENERATE RANDOM IDEMPOTENCYKEY start ----

  const originalString = selected?.client?.clientId
  const updatedString = originalString?.replace('#CLIENT#', '')
  const accessToken = sessionStorage.getItem('accessToken')

  // FUNCTION TO OPEN POS APP WITH PARAMETERS START ----

  const openPosApp = (newPayload) => {
    const idempotencyKey = generateIdempotencyKey();
    console.log(idempotencyKey);
    console.log(newPayload, "newPayload is consoled here"); // Correct the variable name from newPaylaoad to newPayload

    try {
        const dataParameter = {
            amount_money: {
                amount: `${newPayload?.amount}`,
                currency_code: "USD"
            },
            location_id: newPayload?.squareLocationId,
            customer_id: newPayload?.squareSellerCustomerId,
            order_id: newPayload?.squareOrderId,
            idempotency_key: idempotencyKey,
            reference_id: locationId,
            accessToken: accessToken,
            cash: {
                franchiseeId: franchiseeId,
                clientId: selected?.client?.clientId,
                source: "CASH",
                amount: newPayload?.amount,
                // squareSellerCustomerId: newPayload?.squareSellerCustomerId,
                // squareDeveloperCustomerId: newPayload?.squareDeveloperCustomerId,
                // squareLocationId: newPayload?.squareLocationId,
                squareOrderId: newPayload?.squareOrderId,
                locationId: locationId,
                orderId: newPayload?.orderId,
                paymentMode: "CASH",
                cashDetails: {
                    buyerSuppliedMoney: {
                        amount: Number(newPayload?.amount),
                        currency: "USD"
                    },
                    changeBackMoney: {
                        amount: 0,
                        currency: "USD"
                    }
                }
            },
            callbackUrl: 
            `${DOMAIN_URL}sales-history/${updatedString}`
        };

        // console.log(dataParameter, "dataParameterdataParameter");

        const testSquareURL = `${PAYMENT_APP}://payment?data=` + encodeURIComponent(JSON.stringify(dataParameter));

        window.location.href = testSquareURL;

        // Capture the "Cancel" action when the user navigates back from TestSquare
        window.onbeforeunload = function() {
            alert("cancelled")
            // This function will execute when the user clicks "Cancel" or navigates back.
            // You can perform your desired action here.
            console.log("User clicked Cancel or navigated back.");
            // Call your cancel function here if needed
        };

        // Optionally, you can capture the "Open" action when the user returns to your page
        window.onfocus = function() {
          setSuccessOrder(true)
            // This function will execute when the user returns to your page after using TestSquare.
            // You can perform your desired action here.
            console.log("User returned to your page after using TestSquare.");
            // Call your "Open" function here if needed
        };
    } catch (error) {
        console.log("Unable to open the app");
    }
}
// FUNCTION TO OPEN POS APP WITH PARAMETERS END ----

console.log(`${DOMAIN_URL}sales-history/${updatedString}`, "domain url changes")

//  PAYLOAD FOR PLACE ORDER & CALCULATE ORDER API

const payload = {
  locationId: locationId,
  clientId: selected?.client?.sortKey || "",
  clientName: selected?.client?.firstName || "",
  guestClient: selected?.guestClient || false,
  createdBy: userId || '',
  queueId: selected?.queueId,
  petId: selected?.pet?.sortKey || "",
  petName: selected?.pet?.firstName || "",
  franchiseeId: franchiseeId,
};

// CALCULATE ORDER API FUNCTION START --

const calculateOrder = async () => {
  setLoading(true)
  let newItemsArr = payload?.items?.map?.((item)=> {
    delete item.promoCode
    return item
  })
  let payloadData = {...payload, items: newItemsArr}
  if(payload?.clientId === ""){
    delete payloadData?.clientId
    delete payloadData?.clientName
  }
  if(!selected?.pet){
    delete payloadData?.petId
    delete payloadData?.petName
  }
  
  try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/calculateOrder`,payloadData, {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.statusCode === 200) {
        const squareLocationId = response?.data?.squareLocationId
        const taxAmount = response?.data?.totalSquareTaxAmountInDollar
        const totalDiscount = response?.data?.totalSquareDiscountAmountInDollar
        const totalAmount = response?.data?.totalSquareAmountWithTaxInDollar
        const amountWithoutTax = response?.data?.totalSquareAmountWithoutTaxInDollar
        const subTotal = amountWithoutTax + totalDiscount

        setSelected({
                    ...selected,
                    subTotal: subTotal,
                    discount: totalDiscount,
                    tax: taxAmount,
                    squareLocationId: squareLocationId,
                    totalPrice: totalAmount
                  })
        }
        setLoading(false)    
  } catch (error) {
      console.error(error);
      setLoading(false)
      setSuccessOrder(false);
      setError(error?.data?.message || "Something went wrong");
  }
  
};

// CALCULATE ORDER API FUNCTION END --

// PLACE ORDER API START -- 

  const handleConfirm = async () => {
    setLoading(true)   
    let payloadData = {...payload}
    payloadData.serviceFee = parseFloat(serviceFee) || 0

    if(payload.clientId === ""){
      delete payloadData.clientId
      delete payloadData.clientName
    }
    // Remove Petname and PedId if they are not selected
    if(!selected?.pet){
      delete payloadData.petId
      delete payloadData.petName
    }
    
    try {
        let response = await ApiService.post(
          `${BASE_URL}checkout/placeOrder`,payloadData, {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
  
        if (response.statusCode === 200) {
          const sqaureOrderId = response?.data?.sqaureOrderId
          const squareLocationId = response?.data?.squareLocationId

          // Payload for POS App start --
          const createPayload = {
            source: "CASH",
            amount: Number(response?.data?.sqaureOrderTotalMoney),
            squareSellerCustomerId: response.data.squareSellerCustomerId,
            squareDeveloperCustomerId: response.data.squareCustomerId,
            squareLocationId: response.data.squareLocationId,
            squareOrderId: response.data.sqaureOrderId,
            locationId: locationId,
            orderId: response.data.ZROrderId,
            paymentMode: "CASH",
            cashDetails: {    
                buyerSuppliedMoney: {
                    amount: Number(response.data.sqaureOrderTotalMoney),
                    currency: "USD"
                },
                changeBackMoney: {    
                    amount: 0,         
                    currency: "USD"
                }
            }
          }
          // Payload for POS App end --

          setSelected({
                      ...selected,
                      sqaureOrderId: sqaureOrderId,
                      squareLocationId: squareLocationId
                    })
          // Function Call to open POS APP
          openPosApp(createPayload)
          }
          setLoading(false)    
    } catch (error) {
        console.error(error);
        setLoading(false)
        setSuccessOrder(false);
        setError(error?.data?.message || "Order not placed something went wrong");
    }
    
  };

  // PLACE ORDER API END -- 
  
  // CONFIRM BUTTON ONCLICK FUNCTION
  const handleProceed = () =>{
    handleConfirm()
  }

  // MAIN UI CONTENT
  return (
    <>
      <Loader IsLoading={loading} />
      <Typography
        variant="h1"
        align="center"
        color="primary"
        style={{ marginBottom: '15px' }}
      >
        Order Summary
      </Typography>

      <Stack direction="column" justifyContent="left" alignItems="left">
        <Box sx={{ maxWidth: '40%', margin: '0 auto 60px' }}>
          <Typography
            variant="h4"
            align="left"
            color="primary"
            style={{ marginBottom: '10px', marginLeft: '40px' }}
          >
            Client
          </Typography>
          <Box className="clientItem">
            <Box>
              {selected?.client?.firstName} {selected?.client?.lastName}
            </Box>
          </Box>
        </Box>
        <Box sx={{ alignItems: 'center' }}>
          <Typography variant="h4" color="primary" sx={{fontSize: "20px"}}>
            All Added Items
          </Typography>
          <CustomTable
            data={selected?.items || []}
            isEdit={false}
            isSearch={false}
            //  loading={loading}
          />
        </Box>
      </Stack>
      {isContract && <Box className="termsFlex">
        {/* <Box className="left">
          <Typography variant="body1">Terms</Typography>
          <Box className="checkoutTermsBox">
           
          </Box>
        </Box> */}
        
          <Box className="right">
            <Box>
              <Checkbox
                label="Accept Terms"
                checked={checkTerms}
                onChange={(e) => setCheckTerms(e.target.checked)}
              />
              <Typography variant="body1">Accept Terms</Typography>
            </Box>
            <Box title="by clicking this box you will find details">
              By purchasing the contracts above, I agree to the terms and conditions of the contract.
            </Box>
          </Box>
        
      </Box>}
      <Box sx={{display: "flex", justifyContent: "flex-end", mr: 1}}>
        <Grid 
          container 
          item 
          sm={6} md={4} lg={4} 
          // sx={{border: "1px solid #D9D9D9", borderRadius: "19px 19px 0 0"}}
        >
          <Box sx={{ marginTop: '2%', direction: 'column', padding: '10px', width: "100%" }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" margin="22px 0px">
            <Typography variant="h4" color="primary">
                SERVICE FEE
              </Typography>
              <Grid container item md={3.5} sm={4} xs={6}>
                <TextField
                  name="service"
                  fullWidth
                  id="outlined-required"
                  className="text-field-styling"
                  onChange={handleInputChange}
                  value={serviceFee}
                  sx={{
                    width: "100%",
                    height: "50px!important"
                  }}
                  InputLabelProps={{
                    className: 'label-style-text-field'
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Stack>
            <Stack direction="row" justifyContent="space-between" margin="22px 0px">
              <Typography variant="h4" color="primary">
                SUBTOTAL
              </Typography>
              <Typography variant="h5">
                ${parseFloat(
                  selected?.subTotal && serviceFee 
                    ? parseFloat(selected.subTotal) + parseFloat(serviceFee)
                    : selected?.subTotal || selected?.total || 0
                  )?.toFixed(2)
                  }
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" margin="22px 0px">
            <Typography variant="h4" color="primary" sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                DISCOUNT 
                {/* {discount} */}
                {/* <Typography variant="h4" sx={{color: '#000000 !important'}}>
                  % OFF
                </Typography> */}
            </Typography>
              <Typography variant="h5">${parseFloat(selected?.discount ?? discount)?.toFixed(2)}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" margin="22px 0px">
              <Typography variant="h4" color="primary">
                TAXES
              </Typography>
              <Typography variant="h5">${parseFloat(selected?.tax || 0.0)?.toFixed(2)}</Typography>
            </Stack>
            <Divider />
            <Stack direction="row" justifyContent="space-between" margin="22px 0px">
              <Typography variant="h4" color="primary">
                TOTAL
              </Typography>
              <Typography variant="h5" style={{ color: '#000' }}>
                {/* {discountedAmount || totalAmount} */}
                ${parseFloat(
                  (selected?.totalPrice) && serviceFee
                  ? parseFloat(selected.totalPrice) + parseFloat(serviceFee)
                  : selected?.totalPrice || 0
                  )?.toFixed(2)
                  }
              </Typography>
            </Stack>
            <Divider />
          </Box>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center" sx={{ paddingBottom: "20px"}}>
        <DynamicButton
          title={'Go Back'}
          variant="contained"
          onClick={handlePrevious}
          style={{
            padding: '10px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            maxWidth: 'max-content',
            fontWeight: '600',
            fontSize: '16px',
            marginRight: '10px',
            background: '#fff',
            color: '#003087',
            border: '2px solid #003087'
          }}
        />
        <DynamicButton
          onClick={handleProceed}
          variant={'contained'}
          title={'Confirm'}
          disabled={isContract && !checkTerms}
          style={{
            padding: '10px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            maxWidth: 'max-content',
            fontWeight: '600',
            fontSize: '16px',
            border: '2px solid #003087'
          }}
        />
      </Box>
      <DynamicModal
        className={'checkoutModal transactionModal'}
        open={open}
        handleDialog={() => {
          setOpen(false);
          setActiveStep(1)
        }}
        type
        selected={selected}
      />
    </>
  );
};
