import {
  alpha,
  Badge,
  Box,
  IconButton,
  Divider,
  Tooltip,
  Menu,
  MenuItem
} from '@mui/material';
import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
// import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { styled } from '@mui/material/styles';
import InventoryAlerts from '../../../../content/applications/InventoryManagement/InventoryAlerts.js';
import { getLocalData } from 'src/utils';
import { UserContext } from 'src/contexts/UserContext.js';
import Loader from '../../../../components/Loader';



// background-color: ${alpha(theme.palette.error.main, 0.1)};
const NotificationsBadge = styled(Badge)(
  ({ theme }) => `    
    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.error.main};
        min-width: 10px; 
        height: 10px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function AlertNotifications() {
  const ref = useRef(null);
  const locationId = getLocalData('locationId');
  // const [isOpen, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const navigate = useNavigate();
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  let encodedLocationId = encodeURIComponent(locationId);

  const [hasItems, setHasItems] = useState(false);
  const { isNotification } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = (e) => {
    // setOpen(true);
    if (isFranchisor === 'false') {
      navigate('/checkout');
      localStorage.removeItem('activeStep');
    } else {
      openMenu(e);
    }

  };

  const handleNotification = () => {
    // setOpen(false);
    setShowAlert(true);
    setHasItems(false);
  };


  const openMenu = (event) => {
    // setStaffId(staffItem.staffId);
    event.preventDefault();
    event.stopPropagation();

    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (event, value) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchor(null);

    if (value === 'liability') {
      console.log("liability");
      navigate('/liability');
    }
  };
  // hook
  useEffect(() => {
    // Now get the alerts
    getAllAlerts();
  }, [isNotification]);


  // Get the all alerts

  const getAllAlerts = async () => {
    setIsLoading(true)
    let alertUrl = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/alerts?limit=1000&locationId=${encodedLocationId}`;

    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && locationId) {
      alertUrl = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/alerts?limit=1000&locationId=${encodedLocationId}`;
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      locationId
    ) {
      alertUrl = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/alerts?limit=1000&locationId=${encodedLocationId}`;
    }

    // fetch(`${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/alerts?locationId=LOC%237bb65894-4e56-7p98-1q44-w456i50b47j0`, {
    fetch(`${alertUrl}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let item = data.data.Items;
          console.log(item, 'itemitem');
          const allTrue = item?.every((item) => item?.readAlert === true);
          console.log(allTrue, 'allTrueallTrue');
          if (allTrue) {
            setHasItems(false);
          } else {
            setHasItems(true);
          }
          // setHasItems(item.length > 0); // Update the state based on the condition
          setIsLoading(false);
        }
      });
    setIsLoading(false)
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {/* Alert notifications */}
      <Box className='alert-notification-cont'>
        {/* Top notification icons */}
        {isFranchisor === 'false' && (
          <>
            <Box className='icons-top-cont'>
              <Tooltip arrow title="Notifications">
                <IconButton color="primary" ref={ref} className='no-hover alert-notification-cont' onClick={handleNotification}>
                  <NotificationsBadge
                    // badgeContent={""}
                    variant={hasItems ? 'dot' : null}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    className='notification-badge'
                  >
                    <NotificationsOutlinedIcon className='alert-notification-icon' />
                  </NotificationsBadge>
                </IconButton>
              </Tooltip>
              {/* <Tooltip arrow title="Message">
                <IconButton color="primary" ref={ref} className='no-hover alert-notification-cont' onClick={handleOpen}>
                  <NotificationsBadge
                    // badgeContent={1}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <EmailOutlinedIcon className='alert-notification-icon' />
                  </NotificationsBadge>
                </IconButton>
              </Tooltip> */}
            </Box>
            <Divider className='divider' />
          </>
        )}
        {/* Bottom notification icons */}
        <Box className='icons-bottom-cont fixed align-center'>
          <Tooltip arrow title="Setting">
            <IconButton color="primary" ref={ref} className='no-hover alert-notification-cont' onClick={handleOpen}>
              <NotificationsBadge
                // badgeContent={1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <SettingsOutlinedIcon className='alert-notification-icon' />
              </NotificationsBadge>
            </IconButton>
          </Tooltip>
          <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={closeMenu}
            keepMounted
            PaperProps={{
              style: {
                maxHeight: 40 * 5,
                width: '25ch'
              }
            }}
          >
            <MenuItem
              className="edit-btn"
              id={'edit_btn'}
              onClick={(e) =>
                onMenuItemClick(
                  e,
                  'liability'
                )
              }
            // disabled
            >
              Liability Waiver
            </MenuItem>
          </Menu>
          {isFranchisor === 'false' && (
            <>
              <Divider className='divider m-20' />
              <Tooltip arrow title="Add">
                <IconButton color="primary" ref={ref} className='no-hover alert-notification-cont' onClick={handleOpen}>
                  <NotificationsBadge
                    // badgeContent={1}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <AddOutlinedIcon className='alert-notification-icon' />
                  </NotificationsBadge>
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>

      {/* View Alert dialog */}
      {showAlert === true && (
        <Box justifyContent="center" direction="column">
          <InventoryAlerts showAlert={setShowAlert} />
        </Box>
      )}
    </>
  );
}

export default AlertNotifications;
