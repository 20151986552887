/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {convertToUTC } from 'src/utils';
 
export function isOutOfTimeAvailability(timeIntervals, startTime, endTime) {
  const start = convertTimeTo24HoursFormat(startTime);
  const end = convertTimeTo24HoursFormat(endTime);
 
  for (let i = 0; i < timeIntervals?.length; i++) {
    const interval = timeIntervals[i];
    const intervalStart = interval.startTime;
    const intervalEnd = interval.endTime;
    const isBlock = interval.isBlock === 'true';
 
    if (
      (start >= intervalStart && start < intervalEnd) ||
      (end > intervalStart && end <= intervalEnd) ||
      (start <= intervalStart && end >= intervalEnd)
    ) {
      if (isBlock) {
        return true;
      }
    }
  }
 
  return false;
}
 
export function convertTimeTo24HoursFormat(time) {
  const [timeString, period] = time.split(' ');
  const [hour, minute] = timeString.split(':');
 
  let hour24 = parseInt(hour);
  const isPM = period.toLowerCase() === 'pm';
 
  if (isPM && hour24 !== 12) {
    hour24 += 12;
  } else if (!isPM && hour24 === 12) {
    hour24 = 0;
  }
 
  return `${hour24.toString().padStart(2, '0')}:${minute}`;
}
 
export function getValueFromId(id, array, matchValue, returnValue, extra) {
  const matchedObject = array?.find((obj) => obj[matchValue] == id);
  return matchedObject ? matchedObject[returnValue] : null;
}
 
export function isValidTimeFormat(timeString) {
  const regex = /^(0[0-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
  return regex.test(timeString);
}
 
export function generateTimeOptions() {
  const options = [];
  const hours = 12;
 
  for (let h = 0; h < hours; h++) {
    const hourLabel = `${h === 0 ? 12 : h.toString().padStart(2, '0')}:`;
    const hourValue = `${h.toString().padStart(2, '0')}:`;
 
    options.push(
      { label: `${hourLabel}00 AM`, value: `${hourLabel}00 AM` },
      { label: `${hourLabel}30 AM`, value: `${hourLabel}30 AM` }
    );
  }
 
  options.push(
    { label: '12:00 PM', value: '12:00 PM' },
    { label: '12:30 PM', value: '12:30 PM' }
  );
 
  for (let h = 1; h < hours; h++) {
    const hourLabel = `${h.toString().padStart(2, '0')}:`;
    const hourValue = `${(h + 12).toString().padStart(2, '0')}:`;
 
    options.push(
      { label: `${hourLabel}00 PM`, value: `${hourLabel}00 PM` },
      { label: `${hourLabel}30 PM`, value: `${hourLabel}30 PM` }
    );
  }
 
  return options;
}
 
export function isEndTimeGreaterOrEqual(startTime, endTime) {
  const startTimeParts = startTime.split(' ');
  const endTimeParts = endTime.split(' ');
 
  const startHour = parseInt(startTimeParts[0].split(':')[0]);
  const startMinute = parseInt(startTimeParts[0].split(':')[1]);
  const startPeriod = startTimeParts[1];
  const endHour = parseInt(endTimeParts[0].split(':')[0]);
  const endMinute = parseInt(endTimeParts[0].split(':')[1]);
  const endPeriod = endTimeParts[1];
 
  if (
    endPeriod === startPeriod &&
    startHour === endHour &&
    startMinute === endMinute
  ) {
    return false;
  } else if (endPeriod === 'AM' && startPeriod === 'PM') {
    return false;
  } else if (endPeriod === 'PM' && startPeriod === 'AM') {
    return true;
  } else if (endHour === 12 && startHour !== 12) {
    return false;
  } else if (endHour !== 12 && startHour === 12) {
    return true;
  } else if (endHour > startHour) {
    return true;
  } else if (endHour === startHour && endMinute >= startMinute) {
    return true;
  }
 
  return false;
}
function getShortDayOfTheWeek(dateString) {
  const dayNames = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
  const dateParts = dateString.split("-");
  const year = parseInt(dateParts[2], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based
  const day = parseInt(dateParts[0], 10);
  
  const date = new Date(year, month, day);
  const dayOfWeek = dayNames[date.getUTCDay()];
 
  return dayOfWeek;
}
 
// Example usage:
// This will output "Wednesday" for the date "11-01-2023" in Safari.
 
export function formatTimeOrDate(type, value) {
  if (type === 'date') {
    // Convert the date string to a Date object
    const dateObj = new Date(value);
    // Get the month, date, and year components
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const date = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    // Format the date as "MM-DD-YYYY"
    const formattedDate = `${month}-${date}-${year}`;
    return formattedDate;
  } else if (type === 'time') {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = value.split(':');
    // Convert hours to 12-hour format
    const formattedHours = String(hours % 12 || 12).padStart(2, '0');
    // Determine whether it's AM or PM
    const period = hours < 12 ? 'AM' : 'PM';
    // Format the time as "HH:MM AM/PM"
    const formattedTime = `${formattedHours}:${minutes} ${period}`;
    return formattedTime;
  }
}
export function convertDateTimeToUTC(dateTimeString) {
  dateTimeString = dateTimeString ? dateTimeString.replace(/-/g, '/') : dateTimeString;
  // Parse the input date and time string into a JavaScript Date object
  const userDate = new Date(dateTimeString);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userDateUtc = new Date(userDate.toLocaleString('en-US', { timeZone: userTimeZone }));
  return userDateUtc.toISOString();
}
 
function getUserTimeZone(){
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimeZone;
}
export function convertTimeToUTC(timeString) {
  const userTimeZone = getUserTimeZone();
  const now = new Date();
  const [time, ampm] = timeString.split(' ');
  const [hours, minutes] = time.split(':').map(Number);
  let adjustedHours = hours;
  if (ampm === 'PM' && hours !== 12) {
    adjustedHours += 12;
  } else if (ampm === 'AM' && hours === 12) {
    adjustedHours = 0;
  }
  now.setHours(adjustedHours, minutes, 0, 0);
  const utcTime = now.toLocaleString('en-US', { timeZone: userTimeZone });
  return utcTime;
}
 
function getDayName(dateStr) {
  const dayNames = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
  const [month, day, year] = dateStr.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  const dayIndex = date.getDay();
 
  return dayNames[dayIndex];
}
export function convertUTCToNormal(utcTimeString) {
  const utcDate = new Date(utcTimeString);
  let hours = utcDate.getUTCHours();
  const minutes = utcDate.getUTCMinutes();
  const ampm = hours >= 12 ? ' PM' : ' AM';
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }
 
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}${ampm}`;
 
  return formattedTime;
}
export function convertToCustomFormat(dateTimeString) {
  // Parse the input date and time string into a JavaScript Date object
  const userDate = new Date(dateTimeString);
 
  // Get the date components
  const day = userDate.getUTCDate();
  const month = userDate.getUTCMonth() + 1; // Months are zero-based
  const year = userDate.getUTCFullYear();
 
  // Get the time components
  const hours = userDate.getUTCHours();
  const minutes = userDate.getUTCMinutes();
 
  // Format the time as AM or PM
  const ampm = hours >= 12 ? ' PM' : ' AM';
 
  // Convert hours to 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
 
  // Create the formatted date and time string
  const formattedDateTime = `${month}-${day}-${year}, ${formattedHours}:${(minutes < 10 ? '0' : '') + minutes} ${ampm}`;
 
  return formattedDateTime;
}
 
export function getOccurencePayload(newSchedule) {

let fromTime = convertDateTimeToUTC(newSchedule.fromDate+',' +newSchedule.fromTime)
let toTime = convertDateTimeToUTC(newSchedule.toDate+',' +newSchedule.toTime)
let setFromDatetime = convertToCustomFormat(fromTime)
let setEndDatetime = convertToCustomFormat(toTime)
  let occurence = {};
  if (newSchedule.occurence == 'noRepeat') {
    let fromTimeNoRepeat = convertDateTimeToUTC(newSchedule.fromDate+',' +newSchedule.fromTime)
    let toTimeNoRepeat = convertDateTimeToUTC(newSchedule.fromDate+',' +newSchedule.toTime)
    let setFromDatetimeNoRepeat = convertToCustomFormat(fromTimeNoRepeat)
    let setEndDatetimeNoRepeat = convertToCustomFormat(toTimeNoRepeat)
    occurence = {
      freq: 'noRepeat',
      dtstart: setFromDatetimeNoRepeat,
      until: setEndDatetimeNoRepeat
      // wkst: getDayName(formatTimeOrDate('date', newSchedule.fromDate))
    };
  } else if (newSchedule.occurence == 'daily') {
 
    occurence = {
      freq: 'daily',
      interval:
        newSchedule.intervalValue > 0 ? Number(newSchedule.intervalValue) : 1,
      // byhour: 0,
      // byminute: 0,
      // bysecond: 0,
      dtstart: setFromDatetime,
      until: setEndDatetime,
      wkst: getShortDayOfTheWeek(newSchedule.fromDate)
    };
 
    if (newSchedule.intervalDays?.length ) {
      occurence['byweekday'] = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
    }
  } else if (newSchedule.occurence == 'weekly') {
    occurence = {
      freq: 'weekly',
      interval:
        newSchedule.intervalValue > 0 ? Number(newSchedule.intervalValue) : 1,
      // byhour: 0,
      // byminute: 0,
      // bysecond: 0,
      dtstart: setFromDatetime,
      until: setEndDatetime,
      wkst: getShortDayOfTheWeek(newSchedule.fromDate)
    };
    if (newSchedule.intervalDays?.length) {
      occurence['byweekday'] = newSchedule.intervalDays;
    }
  } else if (newSchedule.occurence == 'everyWeek') {
    occurence = {
      freq: 'weekly',
      interval:
        newSchedule.intervalValue > 0 ? Number(newSchedule.intervalValue) : 1,
      // byhour: 0,
      // byminute: 0,
      // bysecond: 0,
      dtstart: setFromDatetime,
      until: setEndDatetime,
      wkst: getShortDayOfTheWeek(newSchedule.fromDate),
      byweekday: ['mo', 'tu', 'we', 'th', 'fr', 'sa']
    };
  } else if (newSchedule.occurence == 'monthly') {
    occurence = {
      freq: 'monthly',
      interval:
        newSchedule.intervalValue > 0 ? Number(newSchedule.intervalValue) : 1,
      // byhour: 0,
      // byminute: 0,
      // bysecond: 0,
      dtstart: formatTimeOrDate('date', newSchedule.fromDate),
      until: formatTimeOrDate('date', newSchedule.toDate),
      wkst: getShortDayOfTheWeek(newSchedule.fromDate)
    };
    if (newSchedule.intervalDays?.length) {
      occurence['byweekday'] = newSchedule.intervalDays;
    }
    if (newSchedule.intervalMonths?.length) {
       occurence['bymonth'] = newSchedule.intervalMonths;
      // const monthNamesArray = newSchedule.intervalMonths.map(monthNumber => monthNames[monthNumber - 1]);
      //   occurence['bymonth'] = monthNamesArray;
    }
  }
  return occurence;
}
export function convertToLocalUTC(localDate) {
  const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
  return utcDate.toISOString();
}