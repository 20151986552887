/* eslint-disable no-lone-blocks */
/* Form to create new franchisee */
/* eslint-disable no-debugger */
import {
  Box,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Select,
  FormHelperText,
  MenuItem,
  Typography,
  Grid,
  Avatar,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider

  // Checkbox
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import '../CreateFranchisee/CreateFranchisee.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { formatPhoneNumber } from '../../../utils/index';
import heic2any from 'heic2any';
// import { HeaderContext } from 'src/contexts/HeaderContext';

// import DatePicker from '@mui/lab/DatePicker';
// // import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';

const AddAdditionalOwner = ({
  FranchiseeAdded,
  Transition,
  statesList,
  rolesList,
  setShowScreen,
  franchiseeItem
}) => {
  //     let elm = document.getElementsByClassName('page-header-text')[0];
  //   if (elm) elm.innerHTML = 'Franchisee Management';
  const navigate = useNavigate();

  // hook
  useEffect(() => {
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }

    // Now get the manager list
    // getManagerList();
  }, []);

  let userId = sessionStorage.getItem('UserId');
  const [imageUrl, setImageUrl] = useState('');
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Create Franchisee');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [imgObj, setImageObj] = useState([]);
  // const [locationId, setLocationId] = useState('');
  // const [unitHelperText, setUnitHelperText] = useState('');
  // const [unitName, setUnitName] = useState('');

  // let formatPhone;
  // const [imgName, setImageName] = useState('');

  /* States for getting values from input fields of create location form as follows */

  // const [brandName, setbrandName] = useState('');

  // const [location, setLocation] = useState('');
  // const [assignManager, setassignManager] = useState([]);
  // const [taxGrouping, setTaxGrouping] = useState(false);
  // const [open, setOpen] = React.useState(true);
  const [openDialog, setopenDialog] = useState(false);
  const [isCreated, setisCreated] = useState(false);
  // const [formattedPhone, setFormattedPhone] = useState('');

  // State that is to track whether the any of the form field is updated or not
  const [anyFieldUpdated, setanyFieldUpdated] = useState(false);

  /* States for showing error messages for create location data to user */

  // const [brandNameError, setbrandNameError] = useState(false);

  // const [locationError, setLocationError] = useState('');
  // const [assignManagerError, setassignManagerError] = useState(false);
  //  const [taxGroupingError, settaxGroupingError] = useState(false);

  // Prodile image error text
  const [profileImageError, setProfileImageError] = useState('');

  /* User part start */

  /* States for setting the values of form fields of the create user  */
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [emailuser, setEmailuser] = useState('');
  const [zoomRoomEmail, setZoomRoomEmail] = useState('');

  const [phoneNumber, setphoneNumber] = useState('');
  // const [workphoneNumber, setworkphoneNumber] = useState('');
  // No need to show Roles options as first user will create as Owner only | const [role, setRole] = useState("");
  const [addressuser, setAddressuser] = useState('');
  const [address2user, setAddress2user] = useState('');
  const [cityuser, setCityuser] = useState('');
  const [stateuser, setStateuser] = useState('');
  const [roleuser, setroleuser] = useState('');

  const [zipCodeUser, setzipCodeUser] = useState('');

  /* States for setting the validation error for create user form fields */

  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [emailErroruser, setemailErroruser] = useState(false);
  const [ZoomRoomEmailError, setZoomRoomEmailError] = useState(false);

  const [phoneNumberError, setphoneNumberError] = useState(false);
  // const [workphoneNumberError, setworkphoneNumberError] = useState(false);
  // No need to show Roles options as first user will create as Owner only | const [roleError, setRoleError] = useState(false);
  const [profileimageError, setProfileImage] = useState(false);
  const [addressErroruser, setAddressErroruser] = useState(false);
  const [cityErroruser, setCityErroruser] = useState(false);
  const [stateErroruser, setStateErroruser] = useState(false);
  const [roleErroruser, setRoleErroruser] = useState(false);

  const [zipCodeErroruser, setzipCodeErroruser] = useState(false);
  const [finalApiErr, setFinalApiErr] = useState(false);
  const [finalApiErrMsg, setFinalApiErrMsg] = useState('');
  // const [managerList, SetManagerList] = useState([]);

  // const [assignManagerName, setAssignManagerName] = useState('');
  // const [assignManagerId, setAssignManagerId] = useState('');

  /* User part ends */

  // Get the manager list from the api
  // const getManagerList = async () => {
  //   fetch(`${process.env.REACT_APP_API_END_POINT}users/getManagerList`, {
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json',
  //       'content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*'
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.statusCode && !data.error) {
  //         let Item = data.data.Items;
  //         SetManagerList(Item);
  //       }
  //     });
  // };

  /* Function binded on close button of create-franchisee/user form dialog */
  const handleClose = () => {
    if (anyFieldUpdated) {
      setopenDialog(true);
    } else {
      setShowScreen(0);
      // setOpen(false);

      // showForm(false);
      clear();
    }
  };
  console.log(anyFieldUpdated);

  /* Function for confirmation modal pop up(close,discard,continue) */

  const handleCloseConfirm = (value) => {
    /* if (value === "Discard") {
              setopenDialog(false);
              setOpen(true);
              showForm(true);
          }
          else if (value === "Close") {
              setopenDialog(false);
              setOpen(false);
              showForm(false);
              clear();
          }
          else if (value === "Continue") {
              setopenDialog(false);
              setOpen(false);
              showForm(false);
              clear();
          } */
    if (value === 'Discard') {
      setopenDialog(false);
      // setOpen(false);
      setShowScreen(0);
      // showForm(false);
      clear();
    } else if (value === 'Close') {
      setopenDialog(false);
      // setOpen(false);
      setShowScreen(0);
      // showForm(false);
      clear();
    } else if (value === 'Continue') {
      setopenDialog(false);
      // setOpen(true);
      // showForm(true);
    }
  };

  /* Function to upload image */
  /* Function to upload image */
  const handleFileInput = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg')
      ) {
        setImageUrl(URL.createObjectURL(file));
        setImageObj(file);
        setProfileImage(false);
      } else if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({ blob: file });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }

          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });

          setImageUrl(URL.createObjectURL(jpegFile));
          setImageObj(jpegFile);
          setProfileImage(false);
        } catch (error) {
          console.error('Error handling HEIC file:', error);
          setProfileImage(true);
          setImageUrl('');
          setImageObj([]);
          setProfileImageError('Error handling HEIC file.');
        }
      } else {
        setProfileImage(true);
        setImageUrl('');
        setImageObj([]);
        setProfileImageError(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  /* Function to clear form fields when dialog form is closed */
  const clear = () => {
    // setfranchiseeName('');
    // setbrandName('');
    // setPhone('');
    // setEmail('');
    // setstartDate('');
    // setEndDate('');
    // setAddress('');
    // setAddress2('');
    // setCity('');
    // setZipcode('');
    // setLocation('');
    // setassignManager('');
    // setAssignManagerId('');
    // setAssignManagerName('');
    // settaxId('');
    // setUnitName('');
  };

  /* Franchisee part ends */

  /* Create  User Part  starts  */

  /* Function to validate the Firstname entered by user */
  const validateFirstname = (firstName) => {
    if (!firstName) {
      setfirstName('');
      setfirstNameError('First name is required');
      setanyFieldUpdated(false);
    } else {
      setfirstNameError('');
      setfirstName(firstName);
      setanyFieldUpdated(true);
    }
  };
  /*
      const unitValidate = (unitName) => {
          if (!unitName) {
              setUnitHelperText('Unit is required');
              setStepTwoValid(false);
              setanyFieldUpdated(false);
          } else {
              setUnitHelperText('');
              setUnitName(unitName);
              setStepTwoValid(true);
              setanyFieldUpdated(true);
          }
      } */

  /* Function to validate the last name */

  const validateLastname = (lastName) => {
    if (!lastName) {
      setlastName('');
      setlastNameError('Last name is required');
      setanyFieldUpdated(false);
    } else {
      setlastNameError('');
      setlastName(lastName);
      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the email */

  const validateEmailUser = (Email) => {
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!Email) {
      setEmailuser('');
      setemailErroruser('Email is required');

      setanyFieldUpdated(false);
    } else if (!validateEmail.test(Email)) {
      setemailErroruser('Email is invalid');

      setanyFieldUpdated(true);
    } else {
      setemailErroruser('');
      setEmailuser(Email);

      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the email */

  const validateZoomRoomEmailUser = (Email) => {
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!Email) {
      setZoomRoomEmail('');
      setZoomRoomEmailError('Zoom room email is required');

      setanyFieldUpdated(false);
    } else if (!validateEmail.test(Email)) {
      setZoomRoomEmailError('Zoom room email is invalid');

      setanyFieldUpdated(true);
    } else {
      setZoomRoomEmailError('');
      setZoomRoomEmail(Email);

      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the phone number entered by user */

  const validatePhonenumber = (phonenumber) => {
    // const mobileNumber = e.target.value;
    const validation = /^\d{3}-\d{3}-\d{4}$/;

    if (phonenumber) {
      let formatWorkPhoneNumber = formatPhoneNumber(phonenumber);
      setphoneNumber(formatWorkPhoneNumber);
      setanyFieldUpdated(true);

      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        setphoneNumberError(
          'Please enter a valid phone number in format: xxx-xxx-xxxx'
        );
      } else {
        setphoneNumberError('');
      }
    } else {
      setphoneNumberError('');
      setphoneNumber('');
    }
  };

  /* Function to validate work phone number */

  // const validateWorkPhonenumber = (workPhoneNumber) => {
  //   // const validateWorkPhonenumber = /^\d{3}-\d{3}-\d{4}$/;
  //   // let formatedPhoneNumber = formatPhoneNumber(workPhoneNumber);
  //   // setworkphoneNumber(formatedPhoneNumber);

  //   if (!workPhoneNumber) {
  //     setworkphoneNumber('');
  //     setworkphoneNumberError('Work phone number is required');
  //     setStepTwoValid(false);
  //     setanyFieldUpdated(false);
  //   } else {
  //     setworkphoneNumberError('');
  //     let formatedPhoneNumber = formatPhoneNumber(workPhoneNumber);
  //     setworkphoneNumber(formatedPhoneNumber);
  //     setStepTwoValid(true);
  //     setanyFieldUpdated(true);
  //   }

  //   // if (!workPhoneNumber) {
  //   //     setworkphoneNumber('');
  //   //     setworkphoneNumberError("Work phone number is required");
  //   //     setStepTwoValid(false);
  //   //     setanyFieldUpdated(false);
  //   // }
  //   // else if (!validateWorkPhonenumber.test(workPhoneNumber)) {
  //   //     setworkphoneNumberError("Invalid phone number");
  //   //     setStepTwoValid(false);
  //   //     setanyFieldUpdated(true);
  //   // }
  //   // else {

  //   //     setworkphoneNumber(workPhoneNumber);
  //   //     setStepTwoValid(true);
  //   //     setanyFieldUpdated(true);
  //   // }
  // };

  // No need to show Roles options as first user will create as Owner only |
  /* Function to check the role values to be selected 
      const roleValue = (role) => {
          setRoleError(false);
          setRole(role);
          setanyFieldUpdated(true);
      }
      */

  /* Function for validation of address entered by the user */
  const validateAddressUser = (address) => {
    if (!address) {
      setAddressuser('');
      setAddressErroruser('Address is required');

      setanyFieldUpdated(false);
    } else {
      setAddressErroruser('');
      setAddressuser(address);

      setanyFieldUpdated(true);
    }
  };

  /* Function for validation of address2 entered by user */
  const validateAddress2User = (address2) => {
    // if (!address2) {
    //     setAddress2Erroruser("Address 2 is required");
    //     setStepTwoValid(false);
    // } else {
    //     setAddress2Erroruser("");
    //     setAddress2user(address2);
    //     setStepTwoValid(true);
    // }
    if (!address2) {
      setAddress2user('');
      setanyFieldUpdated(false);
    } else {
      setAddress2user(address2);
      setanyFieldUpdated(true);
    }
  };

  /* Function to select city from drop-down */

  const cityValueUser = (city) => {
    if (!city) {
      setCityuser('');
      setCityErroruser('City is requried');
      setanyFieldUpdated(false);
    } else {
      setCityErroruser('');
      setCityuser(city);
    }
  };

  /* Function to select state of user from drop-down */
  const stateValueUser = (state) => {
    setStateErroruser(false);
    setStateuser(state);

    setanyFieldUpdated(true);
  };

  /* Function to select role of user from drop-down */
  const roleValueUser = (role) => {
    setRoleErroruser(false);
    setroleuser(role);

    setanyFieldUpdated(true);
  };

  /* Function to validate entered zip code */

  const validateZipCodeUser = (zipCodeUser) => {
    const validateZipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (!zipCodeUser) {
      setzipCodeUser('');
      setzipCodeErroruser('Zip code is required');

      setanyFieldUpdated(false);
    } else if (!validateZipcode.test(zipCodeUser)) {
      setzipCodeErroruser('Invalid zip code');

      setanyFieldUpdated(true);
    } else {
      setzipCodeErroruser('');
      setzipCodeUser(zipCodeUser);

      setanyFieldUpdated(true);
    }
  };

  /* Function call when user is going to submit the form for user creation */
  const formSubmitValue = (e) => {
    // const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // eslint-disable-next-line no-unused-vars
    e.preventDefault();
    e.stopPropagation();

    if (!firstName) {
      setfirstNameError('First name is required');
    }
    if (!lastName) {
      setlastNameError('Last name is required');
    }
    if (!emailuser) {
      setemailErroruser('Email is required');
    }
    if (!zoomRoomEmail) {
      setZoomRoomEmailError('Zoom room email is required');
    }
    if (!phoneNumber) {
      setphoneNumberError('Mobile number is required');
    }
    // if (!workphoneNumber) {
    //   setworkphoneNumberError('Work phone number is required');
    // }
    // No need to show Roles options as first user will create as Owner only |
    /*
          if (!role) {
              setRoleError(true);
          }
          */
    if (!addressuser) {
      setAddressErroruser('Address is required');
    }
    if (!cityuser) {
      // setCityErroruser(true);
      setCityErroruser('City is requried');
    }
    if (!stateuser) {
      setStateErroruser(true);
    }
    // if (!roleuser) {
    //   setRoleErroruser(true);
    // }
    if (!zipCodeUser) {
      setzipCodeErroruser('Zip code is required');
    }

    if (
      phoneNumberError.length > 0 ||
      !firstName ||
      !lastName ||
      !emailuser ||
      !zoomRoomEmail ||
      !phoneNumber ||
      // !workphoneNumber ||
      !addressuser ||
      profileimageError ||
      !cityuser ||
      !stateuser ||
      // !roleuser ||
      !zipCodeUser
    ) {
      setanyFieldUpdated(true);
    } else {
      setanyFieldUpdated(true);

      // if (isStepTwoValid) {
      // Now call the create franchise service method
      //   if (!franchiseeId) createFranchisee();
      // else if (franchiseeId) createLocation(franchiseeId);
      if (franchiseeItem.franchiseeId && franchiseeItem.franchiseeName)
        createUser();

      // }
    }

    /* if (isStepTwoValid) {
              // Now call the create franchise service method
              createFranchisee();
          } else {
              // Set step two is not valid
              setStepTwoValid(false);
          } */
  };

  // Method to check the email already exist or not from API
  const checkEmailExist = (email) => {
    // check the email is valid or not
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!validateEmail.test(email.value)) {
      setanyFieldUpdated(false);
    } else {
      let emailid = email.value.toLowerCase();
      // Now check the email is already exist or not
      fetch(
        `${process.env.REACT_APP_API_END_POINT}users/checkEmailExists?email=${emailid}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            // Now check the response is true or false
            let emailExist = res.isExists;
            let msg = res.message;
            if (res && emailExist) {
              // Now email is already exist
              if (email.name === 'email') {
                setanyFieldUpdated(false);
              } else {
                setemailErroruser(msg);
                // setZoomRoomEmailError(msg);
                setanyFieldUpdated(false);
              }
            } else {
              // Now email is not exist
              // setZoomRoomEmailError('');
            }
          } else {
            // Now email is not exist
          }
        })
        .catch((err) => {
          console.log(err);
          // Now email is not exist
          setZoomRoomEmailError(err.msg);
          setemailErroruser(err.msg);

          setanyFieldUpdated(false);
        });
    }
  };

  // Method to create user
  const createUser = () => {
    setIsLoading(true);

    // let locanid = locationId ? String(locationId) : '';
    // let imagePath = (imgObj) ? imgObj : {};
    // let imagePath = 'Imageurl';
    // Create formdata object
    let formData = new FormData();
    formData.append('franchiseeId', franchiseeItem.franchiseeId);
    formData.append('franchiseeName', franchiseeItem.franchiseeName);
    // formData.append('roleId', '#ROLE#1');
    // formData.append('roleName', 'Owner');
    // formData.append('locationName', location);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', emailuser.toLowerCase());
    formData.append('zoomRoomEmail', zoomRoomEmail.toLowerCase());
    formData.append('phoneNumber', phoneNumber);
    if (imgObj) formData.append('profileImage', imgObj);
    formData.append('addressLine1', addressuser);
    formData.append('addressLine2', address2user);
    // formData.append('locationId', locanid);
    formData.append('city', cityuser);
    formData.append('state', stateuser);
    formData.append('postalCode', zipCodeUser.toString());
    formData.append('status', 1);

    formData.append('createdBy', userId);
    formData.append('isPrimaryOwner', false);
    formData.append('involvementLevel', roleuser);

    // formData.append('workPhoneNumber', workphoneNumber);

    // formData.append('country', 'USA');
    // formData.append('isActive', true);

    /* let body = JSON.stringify({
                  franchiseeId: franchiseid,
                  locationId: locanid,
                  roleId: role,
                  firstName: firstName,
                  lastName: lastName,
                  email: emailuser.toLowerCase(),
                  gender: gender,
                  phoneNumber: phoneNumber,
                  workPhoneNumber: workphoneNumber,
                  addressLine1: addressuser,
                  addressLine2: address2user,
                  city: cityuser,
                  state: stateuser,
                  postalCode: zipCodeUser.toString(),
                  country: 'USA',
                  profileImage: imagePath,
                  isActive: true
              });
              */

    // Calling of create user API when user submit details
    fetch(`${process.env.REACT_APP_FEATURED_API_END_POINT}users/createOwner`, {
      method: 'POST',
      headers: {
        Accept: '*/*',
        // 'content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      },
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          // Now hide the loader
          setIsLoading(false);
          setisCreated(true);
          setopenDialog(true);
          // setFinalApiErr("Email id, username already exist")
          // let errmsg = data.response.message;
          // navigate('/franchise-management')
          // Trigger the child component method to parent and refresh the table
          FranchiseeAdded();
        } else if (data.response.error) {
          // Now hide the loader
          setIsLoading(false);
          if (data.response.statusCode === 400) {
            // set error message
            setFinalApiErr(true);
            // setFinalApiErrMsg(
            //   'User account was successfully created, but unfortunately, there was a failure in sending the corresponding email.'
            // );
            setFinalApiErrMsg(data.response.message);
            setisCreated(false);
          } else {
            let errmsg = data.response.message;
            if (errmsg) {
              setFinalApiErr(true);
              setFinalApiErrMsg(errmsg);
              setisCreated(false);
              setIsLoading(false);
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        let errmsg = error.message;
        // set error message
        setFinalApiErr(true);
        setFinalApiErrMsg(errmsg);
        // Now hide the loader
        setIsLoading(false);
      });
  };

  /* User Part */
  return (
    <>
      <Loader IsLoading={isLoading} />
      <Box className="common-form-container">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item sx={{ paddingLeft: '2rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box onClick={() => handleClose()} className="back-arrow-close">
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>

              <Typography className="header-blue-32">Add Owner</Typography>
            </Box>
          </Grid>
        </Grid>
        <>
          <Box>
            {/* <Box className="container-text-field"> */}
            <Box className="container-text-field" sx={{ padding: '0 2rem' }}>
              <Card
                component="form"
                id="user-form"
                className="create-franchise-form"
                onSubmit={formSubmitValue}
                sx={{ border: '0.4px solid #C5C5C5' }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="First name"
                        autocomplete="First name"
                        label="FIRST NAME"
                        placeholder="First name"
                        className="input-field-styling required-field"
                        defaultValue={firstName}
                        onChange={(e) => validateFirstname(e.target.value)}
                        error={Boolean(firstNameError)}
                        helperText={firstNameError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Last Name"
                        autocomplete="Last Name"
                        label="LAST NAME"
                        placeholder="Last name"
                        defaultValue={lastName}
                        className="input-field-styling required-field"
                        onChange={(e) => validateLastname(e.target.value)}
                        error={Boolean(lastNameError)}
                        helperText={lastNameError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="EmailUser"
                        autocomplete="Email"
                        label="PERSONAL EMAIL"
                        placeholder="PERSONAL EMAIL"
                        defaultValue={emailuser}
                        className="input-field-styling required-field"
                        onChange={(e) => validateEmailUser(e.target.value)}
                        onKeyUp={(e) => checkEmailExist(e.target)}
                        error={Boolean(emailErroruser)}
                        helperText={emailErroruser}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="ZoomRoomEmailUser"
                        autocomplete="ZoomRoomEmail"
                        label="ZOOM ROOM EMAIL"
                        placeholder="ZOOM ROOM EMAIL"
                        defaultValue={zoomRoomEmail}
                        className="input-field-styling required-field"
                        onChange={(e) =>
                          validateZoomRoomEmailUser(e.target.value)
                        }
                        // onKeyUp={(e) => checkEmailExist(e.target)}
                        error={Boolean(ZoomRoomEmailError)}
                        helperText={ZoomRoomEmailError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Phone number"
                        autoComplete="off"
                        label="MOBILE NUMBER"
                        placeholder="777-777-7777"
                        value={phoneNumber}
                        onChange={(e) => validatePhonenumber(e.target.value)}
                        error={Boolean(phoneNumberError)}
                        helperText={phoneNumberError}
                        className="input-field-styling required-field"
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl
                        error={roleErroruser}
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling "
                        >
                          ROLE
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          defaultValue={roleuser}
                          label="ROLE"
                          className="input-field-styling"
                          onChange={(e) => roleValueUser(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 224,
                                overflowY: 'auto'
                              }
                            }
                          }}
                        >
                          {rolesList.map((data) => (
                            <MenuItem value={data.role}>{data.role}</MenuItem>
                          ))}
                        </Select>
                        {roleErroruser && (
                          <FormHelperText>Please select role</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name="Work phone number"
                            autocomplete="Work phone number"
                            label="WORK PHONE NUMBER"
                            placeholder="777-777-7777"
                            value={workphoneNumber}
                            onChange={(e) =>
                              validateWorkPhonenumber(e.target.value)
                            }
                            error={Boolean(workphoneNumberError)}
                            helperText={workphoneNumberError}
                            className="input-field-styling required-field"
                            InputLabelProps={{
                              className:
                                'label-style-create-franchisee required-label'
                            }}
                            InputProps={{}}
                          />
                        </Grid> */}

                    <Grid item xs={12} sm={2}>
                      <Avatar
                        sx={{ height: '90px', width: '90px' }}
                        className="user-profile-img"
                        src={imageUrl}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <span className="add-profile-text">
                        Add your profile image
                      </span>
                      <Box error={profileimageError}>
                        <input
                          accept="image/png, image/jpeg, image/jpg"
                          multiple
                          type="file"
                          id="select-file"
                          style={{ display: 'none' }}
                          onChange={(event) => handleFileInput(event)}
                        />
                        <label htmlFor="select-file">
                          <Button
                            variant="contained"
                            component="span"
                            className="upload-image"
                          >
                            <Typography className="upload-text">
                              Choose{' '}
                            </Typography>
                          </Button>
                        </label>
                      </Box>
                      {profileimageError && (
                        <FormHelperText className="error-message">
                          {profileImageError}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <Typography className="table-heade header-blue-20">
                        Home Address
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Address"
                        autocomplete="Address"
                        label="ADDRESS 1"
                        placeholder="Address"
                        defaultValue={addressuser}
                        onChange={(e) => validateAddressUser(e.target.value)}
                        error={Boolean(addressErroruser)}
                        helperText={addressErroruser}
                        className="input-field-styling required-field"
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Address 2"
                        autocomplete="Address 2"
                        label="ADDRESS 2"
                        placeholder="Apartment, Suite etc"
                        defaultValue={address2user}
                        onChange={(e) => validateAddress2User(e.target.value)}
                        // error={Boolean(address2Erroruser)}
                        // helperText={address2Erroruser}
                        className="input-field-styling"
                        InputLabelProps={{
                          className: 'label-style-create-franchisee'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        error={stateErroruser}
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          STATE
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          defaultValue={stateuser}
                          label="STATE"
                          className="input-field-styling"
                          onChange={(e) => stateValueUser(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 224,
                                overflowY: 'auto'
                              }
                            }
                          }}
                        >
                          {statesList.map((data) => (
                            <MenuItem value={data.state}>{data.state}</MenuItem>
                          ))}
                        </Select>
                        {stateErroruser && (
                          <FormHelperText>Please select state</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="City"
                        autocomplete="City"
                        label="CITY"
                        placeholder="City"
                        className="input-field-styling required-field"
                        defaultValue={cityuser}
                        onChange={(e) => cityValueUser(e.target.value)}
                        error={Boolean(cityErroruser)}
                        helperText={cityErroruser}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin ">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Zip code"
                        autocomplete="Zip code"
                        label="ZIP CODE"
                        placeholder="44444"
                        className="input-field-styling required-field"
                        defaultValue={zipCodeUser}
                        onChange={(e) => validateZipCodeUser(e.target.value)}
                        error={Boolean(zipCodeErroruser)}
                        helperText={zipCodeErroruser}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label'
                        }}
                        inputProps={{ maxLength: 5 }}
                      />
                    </Grid>
                  </Grid>
                  {finalApiErr && (
                    <FormHelperText className="Mui-error final-error">
                      {finalApiErrMsg}
                    </FormHelperText>
                  )}
                  <Grid container spacing={2} className="field-top-margin ">
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingTop: '20px'
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          className="next-button common-button p-0"
                        >
                          <Typography className="next-button-text">
                            Save
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleCloseConfirm('Cancel')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
            {isCreated ? 'Congratulations!' : 'Discard Changes?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                {!isCreated ? (
                  <Typography className="title-text-sub">
                    You have unsaved changes, what do you want
                    <Typography className="title-text-sub">to do?</Typography>
                  </Typography>
                ) : (
                  <Typography className="title-text-sub">
                    Owner has been created successfully!
                  </Typography>
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleCloseConfirm('Discard')}
            >
              {isCreated ? 'Close' : 'Discard Changes'}
            </Button>
            {!isCreated && <Divider className="vertical-divider" />}
            {!isCreated && (
              <Button
                className="font-17 no-hover"
                onClick={() => handleCloseConfirm('Continue')}
              >
                Go back to Editing
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default AddAdditionalOwner;
