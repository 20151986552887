/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  // Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  MenuList,
  TablePagination,
  Box,
  Grid,
} from '@mui/material';
import Popover from '@material-ui/core/Popover';
import { DateTime } from "luxon";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { dateFormat } from '../../utils/index';
// import { convertUtcToUserTimeEdit } from 'src/utils';

const ListView = (props) => {
  const { currentDate, data, handleOpenModal, setDialog,
    dialog, view } = props;

  const [calendarDate, setCalendarDate] = useState();
  const [listItem, setListItem] = useState([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [itemData, setItemData] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleRowClick = (event, item) => {
    
      setPopUpOpen(true);
      setItemData(item)
      event.preventDefault();
      event.stopPropagation();
      setSelectedRow({ item, target: event.currentTarget });

  };

  

  const handleClosePopUp = () => {
    setPopUpOpen(false);
  };

  const onClassDetail = (item, key) => {
    setPopUpOpen(false);
    handleOpenModal(itemData, 'data', key);
  };
  function convertUtcToLocal(utcDateTimeString) {
    const localTimeZone = Intl.DateTimeFormat()?.resolvedOptions().timeZone;
    const utcDate = new Date(utcDateTimeString);
    const localDate = new Date(utcDate?.toLocaleString('en-US', { timeZone: localTimeZone }));

    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);

    return formattedDate;
  }
  function convertUtcToUserTimeEdit(utcDateTimeString) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Adjust the format to match your input format (no space after date, space before AM/PM)
    const formattedInput = utcDateTimeString?.replace(/\s+/g, ''); // Remove all spaces
    const adjustedFormat = 'M/d/yyyy,h:mma';

    const utcDate = DateTime.fromFormat(formattedInput, adjustedFormat, { zone: 'utc' });

    if (!utcDate.isValid) {
      return 'Invalid UTC DateTime'; // Handle invalid input
    }

    const userDate = utcDate.setZone(userTimeZone);

    if (!userDate.isValid) {
      return 'Invalid User DateTime'; // Handle invalid conversion
    }

    // Format the time as '8:00 PM'
    const formattedTime = userDate.toFormat('hh:mm a');

    return formattedTime
  }

  useEffect(() => {
    const compareDate = new Date(currentDate);
    const startOfWeek = new Date(currentDate);
    const endOfWeek = new Date(currentDate);

    const currentDay = compareDate.getDay();
    // Calculate the start and end dates of the current week
    startOfWeek.setDate(currentDate.getDate() - (currentDay + 1) % 7 + 1);
    endOfWeek.setDate(currentDate.getDate() + (7 - currentDay)); // End on Saturday
    const filteredList = data.filter((item) => {
      if (item.fromDate) {
        try {
          // Parse the API date string from item.fromDate
          const convertedDate = convertUtcToLocal(item.fromDate);
          const itemDate = new Date(convertedDate);
          if (!(itemDate instanceof Date) || Number.isNaN(itemDate)) {
            throw new Error('Invalid Date');
          }
          let isSameDate = new Date();          
          if (view === 'week') {
             isSameDate = itemDate >= startOfWeek && itemDate <= endOfWeek;             
          } else {
            // Compare the date part (year, month, and day) of the current date and itemDate
             isSameDate = compareDate.toDateString() === itemDate.toDateString();
          }

          // Check if the API time is within the desired range (e.g., 9:00 AM to 10:00 PM)
          // const itemHours = itemDate.getHours();
          // const isWithinTimeRange = itemHours >= 9 && itemHours < 22; // Adjust time range as needed

          // Return true if both date and time match the criteria
          return isSameDate
      } catch (error) {
        console.error(`Error processing item: ${error.message}`);
        // Handle the error or return false if necessary
      }
    }
console.log(startOfWeek,endOfWeek,'startOfWeek');
      // If item.fromDate is not available, or it doesn't match the criteria, return false
      return false;
  });

  setListItem(filteredList);
  const dateParts = dateFormat(currentDate).split('-');
  const formattedDate = new Date(
    `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
  ).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  setCalendarDate(formattedDate);
}, [data, currentDate,view]);

function dateFormatWithMonth() {
  const dateParts = dateFormat(currentDate).split('-');
  const formattedDate = new Date(
    `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
  ).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  return formattedDate
}
const paginatedLists = listItem?.slice(
  page * rowsPerPage,
  page * rowsPerPage + rowsPerPage
);
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0); // Reset page when rows per page changes
};
// useEffect(() => {
//   let listItem = [];

//   data.forEach((item) => {
//     if (item.fromDate && item.fromDate === dateFormat(currentDate)) {
//       listItem.push(item);
//     }
//   });

//   setList(listItem);

//   const dateParts = dateFormat(currentDate).split('-');
//   const formattedDate = new Date(
//     `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
//   ).toLocaleDateString('en-US', {
//     month: 'long',
//     day: 'numeric',
//     year: 'numeric'
//   });

//   setCalendarDate(formattedDate);

// Reset groupedData
// const initialGroupedData = {};
// listItem.forEach((item) => {
//   const { categoryName, room, startTime, endTime, trainerName } = item;
//   const key = `${categoryName}_${room.name}_${startTime}_${endTime}_${trainerName}`;
//   if (!initialGroupedData[key]) {
//     initialGroupedData[key] = {
//       count: 1,
//       roomLimit: room.roomLimit,
//     };
//   } else {
//     initialGroupedData[key].count--;
//   }
// });
// setGroupedData(initialGroupedData);
// }, [data, currentDate]);
function formatDateWithMonth(inputDate) {
  const date = new Date(inputDate);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedDate = `${dayOfWeek}, ${month} ${day < 10 ? '0' : ''}${day}, ${year}`;
  
  return formattedDate;
}
return (
  <>
 
  <TableContainer
    className="location-table-container servies-details-table"
    sx={{ borderRadius: '19px 19px 0px 0px', marginTop: '60px' }}
  >
    <Table>
      <TableHead>
        <TableRow className="table-header">
          <TableCell colSpan={6}>
            {/* {dateFormat(currentDate) === dateFormat(new Date())
      ? 'Today'
      : ''}{' '} */}
            {/* <span>{formatDateWithMonth(currentDate)}</span> */}
            <span>Schedules</span>
            {/* <span>{calendarDate}</span> */}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Schedule Name</TableCell>
          <TableCell>Trainer</TableCell>
          <TableCell>Schedule Date</TableCell>
          <TableCell>Schedule Time</TableCell>
          <TableCell>Booking Availability</TableCell>
          <TableCell>Room</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {paginatedLists?.map((item, index) => {
          let bookingAvailability;
        if(item.scheduleType==='enrollment'){
          bookingAvailability = `${item.clientDetails?.length ?? 0} / ${item.maximumCapacity}`;
        }else{
          bookingAvailability = `${item.clientDetails?.length ?? 0} / ${item.clientDetails?.length}`;
        }
        console.log(bookingAvailability,'item.fromDate');

            // For other schedule types with clientDetails as an array
            // bookingAvailability = `${item.clientDetails?.length ?? 0} / ${item.maximumCapacity}`;
          
          return (
            <TableRow key={index} onClick={(event) => handleRowClick(event, item)}>
              <TableCell>{item.serviceName}</TableCell>
              <TableCell>{item.trainerName}</TableCell>
              <TableCell>{formatDateWithMonth(item.fromDate)}</TableCell>
              <TableCell>{`${convertUtcToUserTimeEdit(item.fromDate)} - ${convertUtcToUserTimeEdit(item.toDate)}`}</TableCell>
              <TableCell>{bookingAvailability}</TableCell>
              <TableCell>{item.room.name}</TableCell>
            </TableRow>
          );
        })}


      </TableBody>
    </Table>
    {selectedRow && (
      <Popover
        open={popUpOpen}
        onClose={handleClosePopUp}
        anchorEl={selectedRow.target}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right', // Set to 'left' for left-side positioning
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right', // Set to 'left' for left-side positioning
        }}
      >
        <MenuList>
          <MenuItem onClick={() => onClassDetail(props)}>View Class Details</MenuItem>
          <MenuItem onClick={() => {
            onClassDetail(props, 'outSideData');
            setDialog({ ...dialog, bookClass: true });
          } }>Enroll a Pet</MenuItem>
        </MenuList>
      </Popover>
    )}
    <Box p={2} 
    sx={{display:'flex-end'}}>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={listItem?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage} />
    </Box>
  </TableContainer>

    </>
)
};
export default ListView;