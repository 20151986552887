
/* eslint-disable no-lonely-if */
/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import {
  CustomDatePicker,
} from '../CustomDatePicker';
import {
  Box,
  Stack,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableContainer,
  TablePagination,
  Checkbox,
  Card,
  Grid,
  Paper
} from '@mui/material';
import { CustomButton, SearchInput } from '../Global';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { getLocalData } from 'src/utils';
import Loader from '../Loader';
import { ApiService } from '../../services/ApiService';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';

export const AllOccurences = (props) => {
  const {
    newSchedule,
    setNewSchedule,
    handleOpenScreen,
    selectedRows,
    setSelectedRows,
    dialog,
    setDialog,
    btnLoading
  } = props;



  // create a custom theme with updated hover and close icon colors


  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const locationId = getLocalData('locationId');
  const encodedLocationId = encodeURIComponent(locationId);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openFilter, setOpenFilter] = useState({
    datefilter: false,
  });
  const changeStartDate = (date) => {
    if (date === "01-01-1970") {
      date = null;
    }
    setstartDate(date === '' ? null : date); // Set to null when date is empty string
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date === '' ? null : date, // Set to null when date is empty string
    }));
  };
  
  const changeEndDate = (date) => {
    if (date === "01-01-1970") {
      date = null;
    }
    setEndDate(date === '' ? null : date); // Set to null when date is empty string
    setFilters((prevState) => ({
      ...prevState,
      toDate: date === '' ? null : date, // Set to null when date is empty string
    }));
  };
  
  // const changeStartDate = (date) => {
  //   if (date === "01-01-1970") {
  //     date = null;
  //     return;
  //   }
  //   setstartDate(date);
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     fromDate: date
  //   }));
  // };
  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      datefilter: field === 'datefilter' ? !prevState.datefilter : false,
    }));
  };

  // const changeEndDate = (date) => {
  //   if (date === "01-01-1970") {
  //     date = null;
  //     return;
  //   }
  //   setEndDate(date);
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     toDate: date
  //   }));
  // };

  const clearFilters = () => {
    setEndDate(null);
    setstartDate(null);
    setFilters({
      // isActive: filters.isActive,
      openingDate: null,
      openingEndDate: null
    });

    setOpenFilter({
      datefilter: false,
    });
  };


  const [loading, setLoading] = useState(true);
  const [allOccurencesList, setAllOccurencesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilters, setIsFilters] = useState(false);

  const [filters, setFilters] = useState({
    isActive: null,
    orderTitle: null,
    supplierName: null
  });

  const handleDialogClose = (type) => {
    if (dialog.isDelete) {
      handleOpenScreen(3);
    } else {
      handleOpenScreen(2);
      setNewSchedule({ ...newSchedule, editTypeValue: 'Reschedule' })
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [tablePagination, setTablePagination] = useState({
    page: 0,
    limit: 5
  });

  // To change the page into the table
  const handlePageChange = (event, newPage) => {
    setTablePagination({ ...tablePagination, page: newPage });
  };

  // To change the limit
  const handleLimitChange = (event) => {
    setTablePagination({
      ...tablePagination,
      limit: parseInt(event.target.value)
    });
  };

  // Apply paination on table
  const applyPagination = (allOccurencesList) => {
    const page = tablePagination.page;
    const limit = tablePagination.limit;
    return allOccurencesList.slice(page * limit, page * limit + limit);
  };

  // Apply search filter
  const applyFilters = (allOccurencesList) => {
    let arrayOfMatchedObjects = [];
    if (searchQuery) {
      arrayOfMatchedObjects = allOccurencesList.filter((object) => {
        return JSON.stringify(object)
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    } else {
      arrayOfMatchedObjects = allOccurencesList;
    }
    return arrayOfMatchedObjects;
  };

  // Apply filter from object and below is the pagination order
  const filteredRows = applyFilters(allOccurencesList);
  const paginatedRows = applyPagination(filteredRows);
  const handleClick = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredRows.map((n) => n.id);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const classes = useStyles();
  const autoCompleteClasses = useAutocompleteStyles();

  function getDayAndMonth(dateString) {
    if (!dateString) {
      return null;
    }
    const dateParts = dateString?.split('-');
    const month = parseInt(dateParts[0]) - 1; // Months are zero-based in JavaScript
    const day = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);

    const date = new Date(year, month, day);
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
  }

  function isAnyFilterApplied() {
    return Object.values(filters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '';
    });
  }

  function formatDateString(dateString, format) {
    const dateParts = dateString.split('-');
    const month = dateParts[0].padStart(2, '0');
    const day = dateParts[1].padStart(2, '0');
    const year = dateParts[2];

    const date = new Date(`${month}-${day}-${year}`);

    if (format === 'date') {
      return `${month}/${day}/${year}`;
    }

    if (format === 'day') {
      const options = { weekday: 'long' };
      const formattedDay = date.toLocaleDateString('en-US', options);
      return formattedDay;
    }

    return null;
  }

  const handleUpdateCustom = () => {
    if (!selectedRows?.length) {
      toast.error('Please select atleast one occurence');
    } else {
      if (dialog.isDelete) {
        setDialog({ ...dialog, state: true, isDeleteCustom: true, notify: true });
      }
      else {
        setDialog({ ...dialog, state: true, isEditCustom: true, notify: true });
      }

    }
  };

  // =================Get all Occurences =======================

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (key == 'categoryName' && !filtersParams[key].length) {
        delete filtersParams[key];
      }

      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }
  const getAllOccurences = async () => {
    let data = {
      locationId: locationId,
      appointmentId: newSchedule.appointmentId,
      fromDate: startDate,
      toDate: endDate
    };

    let queryString = filtersQuery();

    try {
      const response = await ApiService.post(
        `${BASE_URL}/schedule/getAppointments`,
        data
      );

      if (!response.data?.error) {
        const modifiedData = response.data?.Items?.map((item) => ({
          ...item,
          fromDateFormated: formatDateString(item.fromDate, 'date'),
          id: item.sortKey
        }));

        setAllOccurencesList(modifiedData);
      } else {
        toast.error(
          response.data?.message ||
          'Something went wrong with getting all occurences'
        );
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error('Something went wrong, Try again!');
    }
  };

  useEffect(() => {
    getAllOccurences();
  }, [JSON.stringify(filters)]);

  return (
    <div className="service-container order-container">
      <Box
        sx={{ width: '100%' }}
        paddingBottom="20px"
        marginTop="20px"
        className="Main-container common-form-container"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          className="container"
          alignItems="center"
          marginTop="12px"
        >
          <Typography
            variant="variant2"
            sx={{
              fontSize: '28px',
              textAlign: 'left',
              display: 'flex',
              alignItems: 'center',
              gap: '-5px'
            }}
            className="header-blue-32"
          >
            <Box
              sx={{ marginBottom: '15px' }}
              onClick={() => handleDialogClose()}
              className="back-arrow-close">
              <ArrowLeft />
            </Box>
            {newSchedule.serviceName}

          </Typography>

          <Box display="flex" gap="16px" className="align-right">
            <CustomButton
              variant="contained"
              loading={btnLoading['cancel_occurence']}
              onClick={handleUpdateCustom}
            >
              Update
            </CustomButton>
          </Box>
        </Stack>

        <Box paddingLeft="40px" paddingRight="15px">
          <Stack gap={5} direction="row" className="container" marginTop="24px">
            <Typography
              variant="variant6"
              sx={{
                fontSize: '15px',
                textAlign: 'left',
                fontWeight: '600',
                color: 'rgba(0, 0, 0, 0.70)'
              }}
            >
              {getDayAndMonth(newSchedule.fromDate)}
            </Typography>

            {newSchedule.fromDate != newSchedule.toDate ? (
              <Typography
                variant="variant6"
                sx={{
                  fontSize: '15px',
                  textAlign: 'left',
                  fontWeight: '600',
                  color: 'rgba(0, 0, 0, 0.70)'
                }}
              >
                - {getDayAndMonth(newSchedule.toDate)}
              </Typography>
            ) : null}

            <Typography
              variant="variant6"
              sx={{
                fontSize: '15px',
                textAlign: 'left',
                fontWeight: '600',
                color: 'rgba(0, 0, 0, 0.70)'
              }}
            >
              {newSchedule.fromTime} - {newSchedule.toTime}
            </Typography>
          </Stack>

          <Stack gap={5} direction="row" className="container" marginTop="24px">
            <Typography
              variant="variant6"
              sx={{
                fontSize: '15px',
                textAlign: 'left',
                fontWeight: '600',
                color: 'rgba(0, 0, 0, 0.70)'
              }}
              className="capitalize"
            >
              <span style={{ marginRight: '16px' }}>Trainer:</span>{' '}
              {newSchedule.trainerName}
            </Typography>

            <Typography
              variant="variant6"
              sx={{
                fontSize: '15px',
                textAlign: 'left',
                fontWeight: '600',
                color: 'rgba(0, 0, 0, 0.70)'
              }}
              className="capitalize"
            >
              <span style={{ marginRight: '16px' }}>Room:</span>{' '}
              {newSchedule.room}
            </Typography>
          </Stack>

          <Stack
            gap={0}
            direction="column"
            className="container"
            marginTop="24px"
          >
            <Typography
              variant="variant6"
              sx={{
                fontSize: '15px',
                textAlign: 'left',
                fontWeight: '600',
                color: 'rgba(0, 0, 0, 0.70)'
              }}
            >
              Description:
            </Typography>

            <Typography
              variant="variant6"
              sx={{
                fontSize: '15px',
                textAlign: 'left',
                fontWeight: '400',
                color: 'rgba(0, 0, 0, 0.70)'
              }}
            >
              {newSchedule.description}
            </Typography>
          </Stack>

          <Stack direction="column" className="container" marginTop="40px">
            <Typography
              variant="variant2"
              sx={{
                fontSize: '20px',
                textAlign: 'left'
              }}
              className="detail-title"
            >
              All Occurrences
            </Typography>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={6}
                marginTop="20px"
                sx={{
                  marginTop: '20px'
                }}
              >
                <CustomButton
                  onClick={() => setIsFilters(!isFilters)}
                  variant={
                    isAnyFilterApplied()
                      ? 'contained'
                      : isFilters
                        ? 'contained'
                        : 'outlined'
                  }
                >
                  Filter (All)
                  <KeyboardArrowDownRoundedIcon
                    className={clsx(
                      !isFilters && classes.closeY,
                      isFilters && classes.openY
                    )}
                  />
                </CustomButton>

              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px'
                }}
              >
                <SearchInput
                  value={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              marginTop="10px"
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              {loading ? (
                // <CircularProgress sx={{ marginTop: '80px' }} />
                <Loader IsLoading={loading} />
              ) : (
                <>
                  {isFilters ? (

                    <Grid sx={{ marginLeft: '-30px' }} item width={isFilters ? 300 : 0}>
                      <Card className="filter-content">
                        <Grid xs={12} onClick={() => handleOpenFilter('datefilter')} pt={0.5}>
                          <Paper>
                            <Grid
                              container
                              alignItems="center"
                              justify="space-between"
                              className="pointer"
                            >
                              <Grid item>
                                <KeyboardArrowRightRoundedIcon
                                  className={clsx(
                                    !openFilter.datefilter && classes.closeY,
                                    openFilter.datefilter && classes.openY
                                  )}
                                />
                              </Grid>
                              <Grid item sx={{color: '#003087', fontWeight: '800'}}>
                                <Typography>Date Range</Typography>
                              </Grid>
                              <Grid item onClick={clearFilters}>
                                <Typography
                                  className="filter-text"
                                  sx={{
                                    fontWeight: '600',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    marginLeft: '100px'
                                  }}
                                >
                                  Clear
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                        {openFilter.datefilter && (
                          <>

                            <Box marginTop={1}>
                              <CustomDatePicker value={startDate || ''}  label="Start Date" onChange={changeStartDate} />
                            </Box>
                            <Box marginTop={1}>
                              <CustomDatePicker
                                label="End Date"
                                onChange={changeEndDate}
                                value={endDate || ''}
                                minDate={new Date(startDate)}
                                disabled={Boolean(!startDate)}
                              />
                            </Box>

                          </>
                        )}
                        {/* Filters component will here - same like other components like purchase order*/}
                      </Card>
                    </Grid>

                  ) : null}

                  <Grid item width={`calc(100% - ${isFilters ? 300 : 0}px)})`}>
                    <TableContainer
                      className="location-table-container"
                      sx={{ borderRadius: '19px 19px 0px 0px' }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow className="table-header">
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  selectedRows?.length > 0 &&
                                  selectedRows?.length < filteredRows?.length
                                }
                                checked={
                                  filteredRows?.length > 0 &&
                                  selectedRows?.length === filteredRows?.length
                                }
                                onChange={handleSelectAllClick}
                                inputProps={{
                                  'aria-label': 'select all'
                                }}
                              />
                            </TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Day</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Schedule</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {paginatedRows?.map((item, index) => {
                            const isItemSelected = isSelected(item.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <>
                                <TableRow
                                  onClick={(event) =>
                                    handleClick(event, item.id)
                                  }
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={item.id}
                                  selected={isItemSelected}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      inputProps={{
                                        'aria-labelledby': labelId
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {item.fromDateFormated}
                                  </TableCell>
                                  <TableCell>
                                    {formatDateString(item.fromDate, 'day')}
                                  </TableCell>
                                  <TableCell>
                                    {item.startTime} - {item.endTime}
                                  </TableCell>
                                  <TableCell>{item.serviceName}</TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Box p={2}>
                      <TablePagination
                        component="div"
                        count={filteredRows?.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={tablePagination.page}
                        rowsPerPage={tablePagination.limit}
                        rowsPerPageOptions={[5, 10, 15]}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Stack>
        </Box>
      </Box>
    </div>
  );
};