import React, { useState, useEffect, useContext } from 'react';
import {
  AddToCart,
  OrderSuccess,
  OrderSummary,
  PaymentMethod,
  SelectClient,
  Discount
} from './CheckoutSteps/index';
import {
  Box,
  // Button,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Container
} from '@mui/material';
import './style.css';
import Header from 'src/components/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { DynamicButton, DynamicPopup } from '../../../components/Checkout';
import { Helmet } from 'react-helmet-async';
import { ArrowLeft } from '../../../assets/SVG/SvgIcons';
import { CheckoutContext } from 'src/contexts/CheckoutContext';

function CheckoutMainPage() {
  let elm = document.getElementsByClassName('page-header-text')[0];
  if (elm) elm.innerHTML = '';
  const location = useLocation();
  const dataReceived = location?.state;
  const navigate = useNavigate();
  console.log('dataReceived ==>', dataReceived);
  const steps = [
    'Select Client',
    'Add to Cart',
    'Discount',
    'Order Summary',
    'Payment Method'
  ];
  // const [activeStep, setActiveStep] = useState(0);
  const { activeStep, setActiveStep } = useContext(CheckoutContext);

  const handleNext = (e) => {
    e.preventDefault();
    if (activeStep === 0 && dataReceived?.type === 'gift') {
      setActiveStep(activeStep + 2);
    } else if (activeStep === 0 && dataReceived?.type === 'schedule') {
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
    localStorage.setItem('activeStep', JSON.stringify(activeStep + 1));
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    if (activeStep === 2 && dataReceived?.type === 'gift') {
      setActiveStep(activeStep);
      window.history.back();
    } else if (activeStep === 2 && dataReceived?.type === 'schedule') {
      setActiveStep(activeStep);
      window.history.back();
    } else {
      setActiveStep(activeStep - 1);
    }
    localStorage.setItem('activeStep', JSON.stringify(activeStep - 1));
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const [popup, setPopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    client: null,
    pet: null,
    items: [],
    discount: null,
    paymentMethod: null,
    values: [],
    totalAmount: 0.0
  });

 
  useEffect(() => {
    setActiveStep(0);
    setSelectedItems({
      client: null,
      pet: null,
      items: [],
      discount: null,
      paymentMethod: null,
      values: []
    });
  }, []);

  useEffect(() => {
    return () => {
      localStorage.setItem('prevStep', 0)
      localStorage.removeItem('checkoutData')
    }
  }, [])

  useEffect(() => {
    const typeArr = ['schedule', 'enrollment', 'appointment'];
    const stateData = location.state;
    if (stateData) {
      if(stateData.transfer){
        setIsSuccess(true)
      }
      let itemsData = stateData.items[0]
      let totalAmount = 
        itemsData.discount 
          ? itemsData.discount
          : itemsData.giftCardValue
            ? itemsData.giftCardValue
          : 0.0
      setSelectedItems({
        ...selectedItems,
        items: [{
          itemId: stateData.itemId,
          itemName: itemsData.giftCardName,
          itemType: "GiftCard",
          quantity: 1,
          price: totalAmount,
          // discount: itemsData.discount,
          // cardNumber: itemsData.giftCardNumber 
        }],
        // totalAmount: totalAmount
      });
      if (activeStep === 0 && typeArr.includes(stateData.type)) {
        setActiveStep(activeStep + 2);
      }
      if (activeStep === 0 && stateData?.type === 'gift' && stateData.client) {
        setActiveStep(activeStep + 2);
      }
    }
  }, [location]);

  useEffect(()=> {
    if(activeStep !== 0 && selectedItems.client === null) setActiveStep(0)
  })

  const handlePopupChange = (type) => {
    if (type !== 'edit') {
      setActiveStep(0);
      setSelectedItems({
        client: null,
        pet: null,
        items: [],
        discount: null,
        paymentMethod: null,
        values: []
      });
      setPopup(false);
    } else {
      setPopup(false);
    }
  };

  useEffect(() => {
    // Retrieve the active step from localStorage
    const storedActiveStep = localStorage.getItem('activeStep');
    if (storedActiveStep) {
      setActiveStep(JSON.parse(storedActiveStep));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      <Container maxWidth="100%" className="main-container">
        {activeStep === 0 && <Header title="Check Out" />}
      </Container>
      <Box style={{ position: 'relative' }}>
        {isSuccess ? (
          <OrderSuccess
            handlePage={() => {
              setActiveStep(0);
              setIsSuccess(false);
              setSelectedItems({
                client: null,
                pet: null,
                items: [],
                discount: null,
                paymentMethod: null,
                values: []
              });
              if(dataReceived?.type === 'gift') navigate('/checkout', { state: null} )
            }}
            selected={selectedItems}
            from={location?.state?.transfer}
            setActiveStep={setActiveStep}
            setIsSuccess = {setIsSuccess}
          />
        ) : (
          <Box
            style={{
              position: 'relative',
              minHeight: '90vh',
              padding: '0 30px'
            }}
          >
            <Stack
              direction="row"
              marginTop="20px"
              alignItems="center"
              justifyContent={
                activeStep === 1 || (activeStep === 2 && !location.state)
                  ? 'space-between'
                  : 'center'
              }
            >
              {(activeStep === 1 || (activeStep === 2  && !location.state)) && (
                <Box onClick={handlePrevious} className="back-arrow-close">
                  <ArrowLeft />
                </Box>
              )}
              <Typography
                variant="h1"
                align="center"
                color="primary"
                style={{ marginTop: '30px' }}
              >
                Check Out
              </Typography>
              {(activeStep === 1 || (activeStep === 2 && !location.state)) && (
                <DynamicButton
                  disabled={
                    selectedItems?.items?.length > 0 && activeStep === 1
                      ? false
                      : activeStep !== 2
                  }
                  onClick={handleNext}
                  variant={'contained'}
                  title={activeStep === 2 ? 'Order Summary' : 'Next'}
                  style={{
                    padding: '10px 23px',
                    textTransform: 'capitalize',
                    width: '190px'
                  }}
                />
              )}
              {activeStep === 3 && (
                <Box />
              )}
            </Stack>

            <Box paddingY="12px">
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: '16px',
                  alignItems: 'center',
                  marginLeft: '0px',
                  width: '100%'
                }}
              >
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    width: '100%',
                    background: 'transparent',
                    paddingY: '30px'
                  }}
                  // className="stepper-change"
                >
                  {steps.map((label) => (
                    <Step key={label} className="stepper-change-check-out">
                      <StepLabel className="step-labeling-check-out">{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Box>

            {(() => {
              switch (activeStep) {
                case 1:
                  return (
                    <AddToCart
                      setSelected={setSelectedItems}
                      selected={selectedItems}
                      setActiveStep={setActiveStep}
                      main={!!location.state}
                      activeStep={activeStep}
                    />
                  );
                case 2:
                  return (
                    <Discount
                      setSelected={setSelectedItems}
                      selected={selectedItems}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                      main={!!location.state}
                    />
                  );
                case 3:
                  return (
                    <OrderSummary
                      selected={selectedItems}
                      setSuccess={setIsSuccess}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                      main={!!location.state}
                      setSelected={setSelectedItems}
                      setActiveStep={setActiveStep}
                    />
                  );

                case 4:
                  return (
                    <PaymentMethod
                      setSelected={setSelectedItems}
                      selected={selectedItems}
                      setSuccess={setIsSuccess}
                      handleNext={() => setActiveStep(0)}
                      setActiveStep={setActiveStep}
                      handlePrevious={handlePrevious}
                      main={!!location.state}
                    />
                  );

                default:
                  return (
                    <SelectClient
                      handleNext={handleNext}
                      setSuccess={setIsSuccess}
                      setSelected={setSelectedItems}
                      selected={selectedItems}
                      main={!!location.state}
                    />
                  );
              }
            })()}
          </Box>
        )}
      </Box>
      
      <DynamicPopup
        handleDialog={handlePopupChange}
        open={popup}
        created={false}
      />
    </>
  );
}

export default CheckoutMainPage;
