import React from 'react';
import {Typography,Card,CardContent,CardHeader,
  
} from '@mui/material';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { useNavigate } from 'react-router-dom';
// import Header from '../../../components/Header';
import CallMadeIcon from '@mui/icons-material/CallMade';


export const DashboardCardComponent = ({title,timeFrame,units}) =>{
    return(
        <>
          <Card
            //   className="pos-card"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                className="font-18 drodown_menu"
                // title="UNPAID SERVICES"
                action={<CallMadeIcon className="icon-call" />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography className="pos-text-size-30">{title}</Typography>
                <Typography className="pos-text-size-12">{timeFrame}</Typography>
                <Typography className="pos-text-size-12">{units}</Typography>
              </CardContent>
            </Card>
        </>
    )
}
