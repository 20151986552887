import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { ApiService } from "../../../services/ApiService";
import { getLocalData } from "../../../utils";
import { Helmet } from "react-helmet-async";
import { DiscardPopup } from "./DiscardPopup";
import moment from 'moment';

const timeTypes = ["immediately", "once", "hourly", "daily", "weekly", "monthly"];
const SpecificCampaign = ({ selected, setActive, handleClick }) => {


  const franchiseId = getLocalData("franchiseeId");

  const [CampaignDetail, setCampaignDetail] = useState(null);
  const [pauseStatus, setPauseStatus] = useState(false);
  const status = selected?.State?.CampaignStatus;
  useEffect(() => {
   setTimeout(() => {
    getCampaignDetail();
   }, 0);
  }, [selected]);


  const getCampaignDetail = async () => {
    let encodedFranchiseId = encodeURIComponent(franchiseId);
    // let encodedOrderId = encodeURIComponent(detail.sortKey);
    let encodedCampaignId = encodeURIComponent(selected.sortKey);

    let response = await ApiService.get(
      `${process.env.REACT_APP_API_END_POINT}pinpoint/getCampaignDetails?partitionKey=${encodedFranchiseId}&sortKey=${encodedCampaignId}`
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
        console.log('camp specific details', Item[0])
    setCampaignDetail(Item[0]);
  };
  

  const campaignPauseResume = async () => {
    const status = CampaignDetail?.IsPaused;
    let payload = {
      "sortKey": selected.sortKey,
      "Id": CampaignDetail.Id,
      "IsPaused": !status,
      partitionKey: franchiseId
    };
    let response = await ApiService.post(
      `${process.env.REACT_APP_API_END_POINT}pinpoint/pauseResumeCampaign`,
      payload
    );
    if (response.statusCode === 200) {

      setCampaignDetail({ ...CampaignDetail, IsPaused: !CampaignDetail?.IsPaused });
     
      setPauseStatus(true);
    }

  };


 

  const handleCloseModal = () => {
    setPauseStatus(false);
    getCampaignDetail();
  };

  // function extractDateAndTime(isoString) {
  //   const date = isoString;
  //   if (date) {
  //     const extractedDate = date?.split("T")[0] || isoString;
  //     return `${extractedDate} `;
  //   }

  //   return isoString;
  // }
  function convertIsoToNormalDate(isoDate) {
    const normalDate = moment(isoDate).format('MM-DD-YYYY');
    return normalDate;
  }

 // method to capitalize first letter
 function capitalizeStatus(status) {
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
}
  return (
    <>
      <Box sx={{mt:2}}>
        <Helmet>
          <title>Campaign</title>
        </Helmet>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
            justifyContent: "space-between",
            flexDirection: "row"
          }}
        >
          <Typography className="page-title" variant="h1" onClick={() => setActive(0)}>
            <svg
              width="27"
              height="19"
              viewBox="0 0 27 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                fill="#003087"
              />
            </svg>
           <span style={{marginLeft:4}}> {CampaignDetail?.Name || "Campaign"}</span>
          </Typography>
          <Box>
            <Stack
              spacing={1}
              direction="row"
              className="gift-card-buttons"
            >

              <Button
                variant="outlined"
                className="top-button-style"
                onClick={() => handleClick("CampaignMetrics", CampaignDetail)}
              >
                Campaign Metrics
              </Button>
              <Button
                variant="outlined"
                className="top-button-style"
                onClick={() => handleClick("Duplicate", CampaignDetail)}
              >
                Duplicate
              </Button>
              {status === "SCHEDULED" ?
              <Button
                variant="outlined"
                className="top-button-style"
                onClick={campaignPauseResume}
              >
                {CampaignDetail?.IsPaused ? "Resume" : "Pause"}

              </Button>
                :  null }
             <Box>
             {status === "COMPLETED" ? null :
              <Button
                variant="contained"
                className="top-button-style"
                onClick={() => handleClick("Edit", CampaignDetail)}
              >
                Edit

              </Button> }
             </Box>
            </Stack>
          </Box>


        </Box>


        <Box className="review-main">
          <Typography className={"blue-heading"}>Campaign Detail</Typography>
          <Box className="field-section-main">
            <Box className={"field-item"}>
              <Stack>NAME</Stack>
              <Stack>{CampaignDetail?.Name || ""}</Stack>
            </Box>
            <Box className={"field-item"}>
              <Stack>CHANNEL</Stack>
              <Stack>{CampaignDetail?.Channel || ""}</Stack>
            </Box>
            <Box className={"field-item"}>
              <Stack>COHORT</Stack>
              <Stack>{CampaignDetail?.SegmentName || ""}</Stack>
            </Box>
            <Box className={"field-item"}>
              <Stack>TEMPLATE NAME</Stack>
              {/* <Stack>{CampaignDetail?.TemplateConfiguration?.EmailTemplate?.Name || ""}</Stack> */}
              <Stack>{selected.Channel === 'Email' ? CampaignDetail?.TemplateConfiguration.EmailTemplate?.Name : CampaignDetail?.TemplateConfiguration.SMSTemplate?.Name}</Stack>

            </Box>
            <Box className={"field-item"}>
              <Stack>{selected.Channel === 'Email' ? 'SENDER NAME' : 'MESSAGE TYPE'}</Stack>
              {/* <Stack>{CampaignDetail?.MessageType || ""}</Stack> */}
              <Stack>{selected.Channel === 'Email' ? CampaignDetail?.SenderName : CampaignDetail?.MessageType}</Stack>

            </Box>
            <Box className={"field-item"}>
              <Stack>{selected.Channel === 'Email' ? 'SENDER EMAIL' : 'SENDER ID'}</Stack>
              {/* <Stack>{CampaignDetail?.SenderEmail || "None"}</Stack> */}
              <Stack>{selected.Channel === 'Email' ? CampaignDetail?.SenderEmail : CampaignDetail?.SenderID}</Stack>

            </Box>

          </Box>
          <Typography className={"blue-heading"}>Template Preview</Typography>
          {selected.Channel === 'Email' ? (
            <>
              <Box className="field-section-main">
                <Box className={"field-item"}>
                  <Stack>SUBJECT</Stack>
                  <Stack>{CampaignDetail?.Subject}</Stack>
                </Box>
              </Box>
              <Box className={"template-main"}>
                <Typography className={"heading"}>
                  BODY
                </Typography>
                <Box dangerouslySetInnerHTML={{ __html: CampaignDetail?.HtmlPart }} />
              </Box>
            </>
                ) : (
              <Box className={"template-main"}>
                <Typography className={"heading"}>
                Message
                </Typography>
                <Box> {CampaignDetail?.Body } </Box>
              </Box>
              ) }


            
            {/* <Typography className={"description"} > */}
            {/*  We hope this message finds you and your beloved pets in great spirits. As the holiday season approaches, */}
            {/*  we have some tail-waging news to share. */}
            {/* </Typography> */}
            {/* <Typography className={"description"}> */}
            {/*  We hope this message finds you and your beloved pets in great spirits. As the holiday season approaches, */}
            {/*  we have some tail-waging news to share. */}
            {/* </Typography> */}
            {/* <Typography className={"heading"}> */}
            {/*  Here's a sneak peak of What's in Store: */}
            {/*  <ul className={"description"}> */}
            {/*    <li>We hope this message finds you and your beloved pets in great spirits. As the holiday season */}
            {/*      approaches, we have some tail-waging news to share. */}
            {/*    </li> */}
            {/*    <li>We hope this message finds you and your beloved pets in great spirits. As the holiday season */}
            {/*      approaches, we have some tail-waging news to share. */}
            {/*    </li> */}
            {/*    <li>We hope this message finds you and your beloved pets in great spirits. As the holiday season */}
            {/*      approaches, we have some tail-waging news to share. */}
            {/*    </li> */}
            {/*    <li>We hope this message finds you and your beloved pets in great spirits. As the holiday season */}
            {/*      approaches, we have some tail-waging news to share. */}
            {/*    </li> */}
            {/*    <li>We hope this message finds you and your beloved pets in great spirits. As the holiday season */}
            {/*      approaches, we have some tail-waging news to share. */}
            {/*    </li> */}

            {/*  </ul> */}
            {/* </Typography> */}
         
          <Typography className={"blue-heading"}>Scheduling</Typography>
          <Box className="field-section-main">
            <Box className={"field-item"}>
              <Stack>START DATE</Stack>
              <Stack>{timeTypes.indexOf(CampaignDetail?.ScheduleType) === 0 ? 'Immediately on Launch' : convertIsoToNormalDate(CampaignDetail?.Schedule?.StartTime)}</Stack>
            </Box>
            <Box className={"field-item"}>
              <Stack>FREQUENCY</Stack>
              <Stack>{CampaignDetail?.ScheduleType ? capitalizeStatus(CampaignDetail?.ScheduleType) : "Immediately on Launch"}</Stack>
              {/* <Stack>{CampaignDetail?.Schedule?.Frequency ? capitalizeStatus(CampaignDetail?.Schedule?.Frequency) : 
               timeTypes.indexOf(CampaignDetail?.ScheduleType) === 0 ?  "Immediately on Launch" :
               capitalizeStatus(timeTypes[(CampaignDetail?.schedulingOptionType)]) 
                  }</Stack> */}
   

              {/* <Stack>{clientDetail?.Schedule?.Frequency ? capitalizeStatus(clientDetail?.Schedule?.Frequency) : 
            timeTypes.indexOf(clientDetail?.ScheduleType) === 0 ?  "Immediately on Launch" :
            capitalizeStatus(timeTypes[(formData?.schedulingOptionType)]) 
               }</Stack> */}

            </Box>
          </Box>
        </Box>
      </Box>
      <DiscardPopup open={pauseStatus} handleDialog={handleCloseModal}
                    success={{
                      title: "Successful",
                      content: `Campaign ${!CampaignDetail?.IsPaused ? "RESUMED" : "PAUSED"} successfully.`
                    }} />
    </>
  );
};
export default SpecificCampaign;