import { ApiService } from "./ApiService";
import { setLocalData } from "src/utils";

// method to get the location list
export const getLocationList = async (data) => {
  let getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations/getUserLocations`;
  let payload = {
    userId: data?.userId,
    franchiseeId: data?.franchiseeId,
    role: data?.role,
    isFranchisor: data?.isFranchisor
  };
  try {
    let response = await ApiService.post(getLocationsUrl, payload);
    let Item = response?.data?.Items ?? [];

    // Check if location is available in local storage then do not set the location by default, after location switch and revisit, default going to be set
    if (Item[0]?.locationId) {
      setLocalData('locationId', Item[0]?.locationId ?? '');
      setLocalData('locationName', Item[0]?.locationName ?? '');
      setLocalData('locationStatus', Item[0]?.status ?? '');
    }
  } catch (error) {
    console.log(error);
  }
};
