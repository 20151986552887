import React, { useState, useEffect } from 'react';
import {
  DynamicTabs,
  FilterAndSearchComponent
} from '../../../../components/Promotion';
import { getLocalData } from '../../../../utils';
import { ApiService } from '../../../../services/ApiService';
import {
  Typography,
  CircularProgress,
} from '@mui/material';
import { filtersQuery } from '../../../../utils/index';

// , tempSelectedItems, setTempSelectedItems
export const PromotionTargetTable = ({ selected, setSelected }) => {
  const locationId = getLocalData('locationId');
  const BASE_URL = `${process.env.REACT_APP_API_END_POINT}`;
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('service');
  const [allProducts, setAllProducts] = useState([]);

  const [allPricingOptions, setAllPricingOptions] = useState([]);

  const [allBundles, setAllBundles] = useState([]);
  const [allContracts, setAllContracts] = useState([]);
  const [allMemberships, setAllMemberships] = useState([]);
  const [allServiceCategories, setAllServiceCategories] = useState([]);
  const [allProductCategories, setAllProductCategories] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tabList, setTabList] = useState([]);
  const [discountData, setDiscountdata] = useState([]);
  const [tempSelectedItems, setTempSelectedItems] = useState([]);

  console.log(errorMessage, showErrorMessage); 

  const contractType = [
    { label: '3 Months', value: '3 months' },
    { label: '6 Months', value: '6 months' },
    { label: '9 Months', value: '9 months' },
    { label: '12 Months', value: '12 months' },
    { label: 'Ongoing', value: 'Ongoing' }
  ];

  const contractBillingCycle = [
    { label: 'Monthly', value: 'every month' },
    { label: 'Quarterly', value: 'every quarter' },
    { label: 'Every 6 months', value: 'every 6 months' },
    { label: 'Every year', value: 'every year' }
  ];

  const membershipBillingCycle = [
    { label: 'Every 1 months', value: '0' },
    { label: 'Every 1 year', value: '1' }
  ];

  // const serviceType = [
  //   { label: 'Enrollments', value: 'enrollment' },
  //   { label: 'Appointments', value: 'appointment' }
  // ];
  
  const sellOnline = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const [filters, setSidebarFilters] = useState({
    typeOfService: null,
    contractType: null,
    billingCycle: null,
    status: 1,
    categoryName: null,
    discount: null,
    sellOnline: null
  });

  function getBillingCycleValue(value) {
    if (value) {
      return 'Every 1 Year';
    }
    return `Every 1 Month`;
  }

  // Get all service categories
  const getAllServiceCategory = async () => {
    // let encodedLocationId = encodeURIComponent(locationId);
    try {
      const categoriesResponse = await ApiService.get(
        // `${process.env.REACT_APP_API_END_POINT}services/getAllServiceCategories`
        `${process.env.REACT_APP_API_END_POINT}pricing-option/getAllServiceCategories`
      );
      if (!categoriesResponse?.data?.error) {
        // console.log('all category', categoriesResponse?.data?.data?.Items);
        setAllServiceCategories(categoriesResponse?.data?.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          categoriesResponse.message ||
          'Something went wrong with Service categories'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  // get All product category
  const getProductCategories = async () => {
    // const encodedLocationId = encodeURIComponent(locationId);
    const data = await ApiService.get(`${process.env.REACT_APP_PRODUCT_API_END_POINT}category`);
    if (data.statusCode && !data.error) {
      let Item = data.data.Items;
      setAllProductCategories(Item);
    }
  };

  // get all bundles for render tables
  const getBundles = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}/promotions/getAllBundles?locationId=${encodedLocationId}`);
      let Item = response && response.data && response.data.Items ? response.data.Items : [];
      const discount = Array.from(new Set(Item.map(obj => obj.discount)));
      setDiscountdata(discount);
    } catch (error) {
      console.log(error);
    }
  };

  // get all products for render tables
  const getAllProducts = async () => {
    if (type === 'products') {
      delete filters['contractType'];
    }
    // let queryString = await filtersQuery(filters);
    let queryString = await filtersQuery(filters, ['typeOfService', 'billingCycle']);

    let filtersParams = queryString ? `?${queryString}` : '';
    // let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}products${filtersParams}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      // console.log(Item, "Item---9ItemItemItem");
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllProducts(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // get all service for render tables
  // const getAllPricingOptions = async () => {
  //   if (type === 'contracts') {
  //     delete filters['typeOfService'];
  //   } else if (type === 'service') {
  //     delete filters['contractType'];
  //   }

  //   let queryString = await filtersQuery(filters, ['billingCycle']);
  //   let filtersParams = queryString ? `?${queryString}` : '';
  //   // let encodedLocationId = encodeURIComponent(locationId);
  //   try {
  //     let response = await ApiService.get(
  //       `${BASE_URL}services/getAllPricingOptions${filtersParams}`
  //     );
  //     let Item =
  //       response && response.data && response.data.Items
  //         ? response.data.Items
  //         : [];
  //     if (response?.statusCode === 200) {
  //       // console.log(Item, 'Item');
  //       setAllPricingOptions(Item);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // get all pricing options for render tables
  const getAllPricingOptions = async () => {
    if (type === 'contracts') {
      delete filters['typeOfService'];
    } else if (type === 'service') {
      delete filters['contractType'];
    }

    let queryString = await filtersQuery(filters, ['billingCycle', 'typeOfService']);
    let filtersParams = queryString ? `?${queryString}` : '';
    // let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}promotions/getAllPricingOptions${filtersParams}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllPricingOptions(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllBundles = async () => {
    let queryString = await filtersQuery(filters, ['typeOfService', 'billingCycle', 'category']);
    // let queryString = await filtersQuery(filters);
    let filtersParams = queryString ? `?${queryString}` : '';
    // let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        // `${BASE_URL}checkout/getAllPricingOptions?locationId=${encodedLocationId}${filtersParams}`
        `${BASE_URL}promotions/getAllBundles${filtersParams}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllBundles(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllContracts = async () => {
    if (type === 'contracts') {
      delete filters['typeOfService'];
      delete filters['sellOnline'];
    }
    // let queryString = await filtersQuery(filters);
    let queryString = await filtersQuery(filters, ['categoryName', 'sellOnline']);
    let filtersParams = queryString ? `?${queryString}` : '';
    // let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}contracts${filtersParams}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllContracts(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllMemberships = async () => {
    if (type === 'membership') {
      delete filters['contractType'];
      delete filters['typeOfService'];
      delete filters['categoryName'];
    }

    // let queryString = await filtersQuery(filters);
    let queryString = await filtersQuery(filters, ['typeOfService', 'category']);
    let filtersParams = queryString ? `?${queryString}` : '';
    // let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}memberships/allMembership${filtersParams}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        // console.log(Item, 'Item');
        setAllMemberships(Item);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {   
    getAllServiceCategory();
    getProductCategories();
    getBundles();
    getAllPricingOptions();
    getAllProducts();
    getAllBundles();
    getAllContracts();
    getAllMemberships();
  }, []);

  useEffect(() => {
    if (type === 'service' || type === 'pricingoptions') {
      getAllPricingOptions();
    }
    if (type === 'products') {
      getAllProducts();
    }
    if (type === 'bundles') {
      getAllBundles();
    }
    if (type === 'contracts') {
      getAllContracts();
    }
    if (type === 'membership') {
      getAllMemberships();
    }
  }, [filters]);

  // below state used for selecting presetting values in Edit Mode 
  useEffect(() => { 
    setTimeout(() => { 
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (allPricingOptions && allProducts && allBundles && allContracts && allMemberships && allServiceCategories && discountData) {
      setTabList([
        {
          label: tables[0]?.label,
          customLabel: 'Pricing Options',
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              filterOptions={filtersOptions}
              loading={loading}
              setSidebarFilters={setSidebarFilters}
              title={tables[0]?.label}
              dataList={allPricingOptions}
              isCheckBox
              columns={tables[0]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('pricingoptions')}
              message={'No pricing options are available'}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems}    
            />
          )
        },
        {
          label: tables[1]?.label,
          customLabel: 'Products',
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              loading={loading}
              filterOptions={filtersOptions}
              title={tables[1]?.label}
              setSidebarFilters={setSidebarFilters}
              dataList={allProducts}
              isCheckBox
              columns={tables[1]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('products')}
              message={'No products are available'}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems} 
            />
          )
        },
        {
          label: tables[2]?.label,
          customLabel: 'Bundles',
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              loading={loading}
              filterOptions={filtersOptions}
              title={tables[2]?.label}
              setSidebarFilters={setSidebarFilters}
              dataList={allBundles}
              isCheckBox
              columns={tables[2]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('bundles')}
              message={'No bundles are available'}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems} 
            />
          )
        },
        {
          label: tables[3]?.label,
          customLabel: 'Contracts',
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              isCheckBox
              loading={loading}
              filterOptions={filtersOptions}
              title={tables[3]?.label}
              setSidebarFilters={setSidebarFilters}
              dataList={allContracts}
              columns={tables[3]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('contracts')}
              message={'No contracts are available'}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems} 
            />
          )
        },
        {
          label: tables[4]?.label,
          customLabel: 'Memberships',
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              loading={loading}
              isCheckBox
              filterOptions={filtersOptions}
              title={tables[4]?.label}
              setSidebarFilters={setSidebarFilters}
              dataList={allMemberships}
              columns={tables[4]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('membership')}
              message={'No memberships are available'}
              tempSelectedItems={tempSelectedItems}
              setTempSelectedItems={setTempSelectedItems} 
            />
          )
        }
      ]);      
    }
    setLoading(false);
  }, [selected, allPricingOptions, allProducts, allBundles, allContracts, allMemberships, allServiceCategories, allProductCategories, discountData]);

  const [tables] = useState([
    {
      label: 'All Pricing Options',
      columns: [
        { field: 'name', headerName: 'Pricing Option', sortable: false },
        { field: 'numberOfSessions', headerName: '# of Sessions', sortable: false },
        { field: 'price', headerName: 'Price', sortable: false },
      ]
    },
    {
      label: 'All Products',
      columns: [
        { field: 'fName', headerName: 'Product Name', sortable: false },
        {
          field: 'price',
          headerName: 'Price',
          sortable: false,
          renderCell: (params) => {
            return (
              <Typography level="body1">${params.row.price || 0}</Typography>
            );
          }
        },
        {
          field: 'categoryName',
          sortable: false,
          headerName: 'Category',
          renderCell: (params) => {
            return (
              <Typography level="body1">
                {params?.row?.categoryName || ''}
              </Typography>
            );
          }
        },
        {
          field: 'isDiscontinued',
          headerName: 'In Stock',
          renderCell: (params) => {
            return (
              <Typography level="body1">
                {params?.row?.isDiscontinued ? 'No' : 'Yes' || ''}
              </Typography>
            );
          }
        },
      ]
    },
    {
      label: 'All Bundles',
      columns: [
        { field: 'bundleName', headerName: 'Bundle Name', sortable: false },
        {
          field: 'items',
          sortable: false,
          headerName: 'Pricing Options & Products',
          renderCell: (params) => {
            return (
              <Typography level="body1">
                {/* {(params?.row?.items && params?.row?.items.length > 0) ? params?.row?.items.map((item) => item.itemValue.name).join(', ') : ''} */}
                {(params?.row?.items && params?.row?.items.length > 0) ? params?.row?.items.map((item) => item.itemName).join(', ') : ''}
              </Typography>
            );
          }
        },
        { field: 'discount', headerName: 'Discount', sortable: false, renderCell: (params) => <Typography level="body1">{params?.row?.discount && params.row.discount +'%' }</Typography> },
        {
          field: 'bundlePrice',
          headerName: 'Price',
          sortable: false,
          renderCell: (params) => {
            return (
              <Typography level="body1">${params.row.bundlePrice || 0}</Typography>
            );
          }
        }
      ]
    },
    {
      label: 'All Contracts',
      columns: [
        { field: 'contractName', headerName: 'Contract Name', sortable: false },
        { field: 'contractType', headerName: 'Contract Type', sortable: false },
        {
          field: 'billingCycle',
          headerName: 'Billing Cycle',
          sortable: false,
          renderCell: (params) => {
            return (
              <Typography level="body1" className='txt-Transform'>
                {/* Every {params.row.contractDuration} months */}
                {params.row.billingCycle}
              </Typography>
            );
          }
        },
        {
          field: 'price',
          sortable: false,
          headerName: 'Price',
          renderCell: (params) => {
            return (
              <Typography level="body1">${params.row.price || 0}</Typography>
            );
          }
        }
      ]
    },
    {
      label: 'All Memberships',
      columns: [
        { field: 'name', headerName: 'Membership', sortable: false },

        {
          field: 'billingCycle', headerName: 'Billing Cycle',
          sortable: false,
          renderCell: (params) => {
            return (
              <Typography level="body1">
                {/* {params.row.billingCycle > 0 ? 'Every ' + params.row.billingCycle + ' months' : 0} */}
                {getBillingCycleValue(params?.row?.billingCycle)}
              </Typography>
            );
          }
        },
        {
          field: 'price',
          sortable: false,
          headerName: 'Price',
          renderCell: (params) => {
            return (
              <Typography level="body1">${params.row.price || 0}</Typography>
            );
          }
        }
      ]
    }
  ]);

  const [filtersOptions, setFiltersOptions] = useState([
    {
      label: 'Status',
      value: 'status',
      enable: false,
      options: [
        { label: 'All', value: '', disabled: true },
        { label: 'Active', value: 1, disabled: false },
        { label: 'Inactive', value: 0, disabled: true },
        { label: 'Expired', value: 3, disabled: true }
      ],
      subFilters: [
        {
          label: 'Sell Online', // Type
          value: 'sellOnline', // typeOfService
          enable: false,
          options: []
        },
        {
          label: 'Billing Cycle',
          value: 'billingCycle',
          enable: false,
          options: []
        },
        {
          label: 'Category',
          value: 'categoryName',
          enable: false,
          options: []
        },
        {
          label: 'Discount',
          value: 'discount',
          enable: false,
          options: []
        }
      ]
    }
  ]);

  useEffect(() => {
    // Now clear the filter
    setSidebarFilters({ ...filters, sellOnline: null, billingCycle: null, categoryName: null, contractType: null, discount: '' });
    // set the filters options as per the tabs selected
    if (type === 'pricingoptions') {
      filtersOptions[0].subFilters[0].label = 'Sell Online';
      filtersOptions[0].subFilters[0].value = 'sellOnline';
      filtersOptions[0].subFilters[0].options = sellOnline;
      // filtersOptions[0].subFilters[0].value = 'typeOfService';
      // filtersOptions[0].subFilters[0].options = serviceType;
      filtersOptions[0].subFilters[2].options = allServiceCategories.map((item) => ({
        label: item.name,
        value: item.name
      }));
    } else if (type === 'products') {
      filtersOptions[0].subFilters[2].options = allProductCategories.map((item) => ({
        label: item.categoryName,
        value: item.categoryName
      }));
    } else if (type === 'membership') {
      filtersOptions[0].subFilters[1].options = membershipBillingCycle;
    } else if (type === 'contracts') {
      filtersOptions[0].subFilters[1].options = contractBillingCycle;
      filtersOptions[0].subFilters[0].label = 'Type';
      filtersOptions[0].subFilters[0].value = 'contractType';
      filtersOptions[0].subFilters[0].options = contractType;
    } else if (type === 'bundles') {
      filtersOptions[0].subFilters[3].options = discountData.map((item) => ({
        label: item,
        value: item
      }));
    }
  }, [type, allServiceCategories, allProductCategories]);

  // Handle the filter change
  const handleChangeFilter = (type) => {
    // Now clear the filter // typeOfService
    setSidebarFilters({ ...filters, sellOnline: null, billingCycle: null, categoryName: null, contractType: null, discount: '' });
    setType(type);
    if (type === 'pricingoptions') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = true;
      filtersOptions[0].subFilters[1].enable = false;
      filtersOptions[0].subFilters[2].enable = true;
      filtersOptions[0].subFilters[3].enable = false;
    } else if (type === 'products') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = false;
      filtersOptions[0].subFilters[1].enable = false;
      filtersOptions[0].subFilters[2].enable = true;
      filtersOptions[0].subFilters[3].enable = false;
    } else if (type === 'bundles') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = false;
      filtersOptions[0].subFilters[1].enable = false;
      filtersOptions[0].subFilters[2].enable = false;
      filtersOptions[0].subFilters[3].enable = true;
    } else if (type === 'contracts') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = true;
      filtersOptions[0].subFilters[1].enable = true;
      filtersOptions[0].subFilters[2].enable = false;
      filtersOptions[0].subFilters[3].enable = false;
    } else if (type === 'membership') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = false;
      filtersOptions[0].subFilters[1].enable = true;
      filtersOptions[0].subFilters[2].enable = false;
      filtersOptions[0].subFilters[3].enable = false;
    }
    setFiltersOptions(filtersOptions);
  };

  return (
    <>
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DynamicTabs list={tabList || []} />
      )}
    </>
  );
};

export default PromotionTargetTable;