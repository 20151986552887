import { Box, Typography } from "@mui/material";
import React, { useState,useEffect } from "react";
import { Stack } from "@mui/system";
import { ApiService } from "../../../services/ApiService";
// import moment from 'moment';

// import { getLocalData } from "../../../utils";
 import { dateFormat } from "../../../utils";
// import moment from 'moment';

 const timeTypes = ["immediately", "once", "hourly", "daily", "weekly", "monthly"];

const ReviewAndLaunchCampaign = ({ formData, CampaignDetail}) => {
   const [clientDetail] = useState(formData);
 // const [clientDetail] = useState(CampaignDetail || formData);
 const [getDetails, setCampaignDetails] = useState(null);
 // let schedulingOptionType =  JSON.stringify(timeTypes.indexOf(formData?.schedulingOptionType)) || "0";
  console.log('detailsss',formData, CampaignDetail)
  // function convertIsoToNormalDate(isoDate) {
  //   const normalDate = moment(isoDate).format('MM-DD-YYYY');
  //   return normalDate;
  // }

  useEffect(() => {
    getCampaignDetail();
  }, []);

  const getCampaignDetail = async () => {
    let response = await ApiService.get(
      `${process.env.REACT_APP_API_END_POINT}pinpoint/getEmailTemplate?type=${formData.selectedChannel.toUpperCase()}&TemplateName=${formData.selectedTemplate}`
    );
   
    console.log(response)
    let Item = response ;
    setCampaignDetails(Item);
  };

  // function extractDateAndTime(isoString) {
  //   const date = isoString;
  //   if (date) {
  //     const extractedDate = date?.split("T")[0] || isoString;
  //     return `${extractedDate} `;
  //   }

  //   return isoString;
  // }
  // function convertIsoToNormalDate(isoDate) {
  //   const normalDate = moment(isoDate).format('MM-DD-YYYY');
  //   return normalDate;
  // }

// method to capitalize first letter
 function capitalizeStatus(status) {
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
}

  return (
    <>


      <Box className="review-main">
        <Typography className={"blue-heading"}>Campaign Detail</Typography>
        <Box className="field-section-main">
          <Box className={"field-item"}>
            <Stack>NAME</Stack>
            <Stack>{clientDetail?.campaignName || ""}</Stack>
          </Box>
          <Box className={"field-item"}>
            <Stack>CHANNEL</Stack>
            <Stack>{capitalizeStatus(clientDetail?.selectedChannel) || ""}</Stack>
          </Box>
          <Box className={"field-item"}>
            <Stack>COHORT</Stack>
            <Stack>{clientDetail?.selectedCohortName || ""}</Stack>
          </Box>
          <Box className={"field-item"}>
            <Stack>TEMPLATE NAME</Stack>
            {/* <Stack>{CampaignDetail?.TemplateConfiguration?.EmailTemplate?.Name || ""}</Stack> */}
            <Stack>{clientDetail?.selectedTemplate || ""}</Stack>
          </Box>
          <Box className={"field-item"}>
          <Stack>{clientDetail.selectedChannel.toUpperCase() === "EMAIL" ? 'SENDER NAME' : 'MESSAGE TYPE'}</Stack>
          <Stack>{clientDetail.selectedChannel.toUpperCase() === "EMAIL" ? clientDetail?.senderName : clientDetail?.selectedMessageType}</Stack>
          </Box>
          <Box className={"field-item"}>
          <Stack>{clientDetail.selectedChannel.toUpperCase() === "EMAIL" ? 'SENDER EMAIL' : 'SENDER ID'}</Stack>
          <Stack>{clientDetail.selectedChannel.toUpperCase() === "EMAIL" ? clientDetail?.senderEmail : clientDetail?.senderId}</Stack>
          </Box>

        </Box>
        <Typography className={"blue-heading"}>Template Preview</Typography>
       
            {clientDetail.selectedChannel.toUpperCase() === "EMAIL" ? (
              <>
                <Box className="field-section-main">
                  <Box className={"field-item"}>
                    <Stack>SUBJECT</Stack>
                    <Stack>{getDetails?.Subject}</Stack>
                  </Box>
                </Box>
                <Box className={"template-main"}>
                  <Typography className={"heading"}>
                    BODY
                  </Typography>
                  <Box dangerouslySetInnerHTML={{ __html: getDetails?.HtmlPart }} />
                </Box>
              </>
                  ) : (
                <Box className={"template-main"}>
                  <Typography className={"heading"}>
                  Message
                  </Typography>
                  <Box> {getDetails?.Body } </Box>
                </Box>
                ) }   
        
       
        <Typography className={"blue-heading"}>Scheduling</Typography>
        <Box className="field-section-main">
          <Box className={"field-item"}>
            <Stack>START DATE</Stack>
            {/* <Stack>{timeTypes.indexOf(clientDetail?.ScheduleType) === 0 ? capitalizeStatus('Immediately') : dateFormat(clientDetail?.startDate)}</Stack> */}

            {/* <Stack>{(clientDetail?.startDate) ? dateFormat(clientDetail?.startDate) : "Immediately on Launch"}</Stack> */}
            {/* <Stack>
                  {!new Date(clientDetail.startDate).getDate() ?
                    "Immediately on Launch" :
                    dateFormat(clientDetail?.startDate)
                  }
                </Stack> */}
            <Stack>
                  {(formData?.schedulingOptionType) === "0" ?  "Immediately on Launch" :
                    dateFormat(clientDetail?.startDate)
                  }
                </Stack>

            </Box>
          <Box className={"field-item"}>
            <Stack>FREQUENCY</Stack>
            <Stack>{clientDetail?.Schedule?.Frequency ? capitalizeStatus(clientDetail?.Schedule?.Frequency) : 
            timeTypes.indexOf(clientDetail?.ScheduleType) === 0 ?  "Immediately on Launch" :
            capitalizeStatus(timeTypes[(formData?.schedulingOptionType)]) 
               }</Stack>
            {/* <Stack>{(timeTypes(formData?.schedulingOptionType)) || "0"}</Stack> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ReviewAndLaunchCampaign;