/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  CardHeader,
  Card,
  Grid,
  Box,
  Button,
  IconButton,
  useTheme,
  Popover,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
  //   FormControl,
  //   InputLabel,
  //   Select,
  //   MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const CustomContractTable = ({
  data,
  label,
  filterCompoenent,
  //   options = {},
  handleOnClick = (itemType) => {
    // console.log(itemType);
  },
  // setTerminationDialog,
  setContactId,
  setPricingDialog,
  duplicateContract,
  deactivateContract,
  setContractStatus
}) => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keys, setKeys] = useState([]);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [contractDialog, setContractDialog] = useState(false);
  const [Isduplicate, setIsduplicate] = useState(false);
  const [type, setType] = useState('');
  //   const [promotionsType, setPromotionsType] = useState('All');

  const theme = useTheme();

  // const handleOnItemClick = (itemType) => {
  //   console.log(itemType, 'itemType');
  //   setOpen(false);
  //   setSelectedItem(itemType); // Set the selectedItem before calling handleOnClick
  //   handleOnClick(itemType);
  // };


  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));
  const classes = useStyles();

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredData = data.filter((item) => {
    return Object?.values(item)?.some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const showFilter = () => {
    setdisplayFilterForm(!displayFilterForm);
  };

  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  const childOnClick = (e, rest) => {
    e.preventDefault();
    e.stopPropagation();

    handleOnClick(rest);
  };
  const childPauseCLick = () => {
    setAnchorEl(null);
    setPricingDialog(true);
  };
  // const childTerminateClick = (item) => {
  //   setAnchorEl(null);
  //   setTerminationDialog(true);
  //   setContactId(item['sortKey']);
  // };

  // Methdo to handle duplicate
  const handleDuplicate = (e, sortKey) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
    setIsduplicate(true);
    setContractDialog(true);
    setContactId(sortKey);
  };

  // Methdo to handle duplicate
  const handleDeactivate = (e, sortKey, status) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(status, 'item');
    setContractStatus(0);
    setType('deactivate');
    setAnchorEl(null);
    setIsduplicate(false);
    setContractDialog(true);
    setContactId(sortKey);
  };
  
  // Methdo to handle duplicate
  const handleActivate = (e, sortKey, status) => {
    e.preventDefault();
    e.stopPropagation();
    setContractStatus(1);
    setType('activate');
    console.log(status, 'item')
    setAnchorEl(null);
    setIsduplicate(false);
    setContractDialog(true);
    setContactId(sortKey);
  };

  useEffect(() => {
    let key = data && data.length > 0 && data[0] ? Object.keys(data[0]) : '';
    if (key) setKeys(key);
    // setKeys(['name', 'age', 'profession']);
  }, [data]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;
  const handlePopoverOpen = (event, rest) => {
    setAnchorEl(event.currentTarget);
    setselectedItem(rest);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Methdo to handle the delete dialog
  const handleContractDialog = (type) => {
    if (type === 'cancel') {
      setContractDialog(false);
      setIsduplicate(false);
    } else {
      setContractDialog(false);
      // check if duplicate or deactivate
      if (Isduplicate) {
        duplicateContract();
        setIsduplicate(false);
      } else {
        // deactivate contract
        deactivateContract();
        setIsduplicate(false);
      }

    }
  };

  return (
    <>
      <Box mt={3}>
        {/* Search input with filter */}
        <Grid item xs={12} sx={{ padding: '0 1rem' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid
              item
              sx={{
                marginTop: '10px',
                marginBottom: '20px'
              }}
            >
              {filterCompoenent && (
                <Button
                  onClick={showFilter}
                  variant={displayFilterForm ? 'contained' : 'outlined'}
                >
                  Filters
                  <KeyboardArrowDownRoundedIcon
                    className={clsx(
                      !displayFilterForm && classes.closeY,
                      displayFilterForm && classes.openY
                    )}
                  />
                </Button>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
                marginBottom: '20px'
              }}
              className="top-filter-cont"
            >
              <TextField
                type="search"
                className="Search-field"
                label="Search"
                InputProps={{
                  style: {},
                  endAdornment: <SearchIcon />
                }}
                onChange={handleSearchTextChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* END */}

        <Grid container spacing={2} sx={{ padding: '0 1rem' }}>
          {displayFilterForm === true ? (
            <Grid item width={getFilterSize()}>
              {filterCompoenent}
            </Grid>
          ) : null}

          <Grid item sx={{ width: `calc(100% - ${getFilterSize()}px)` }}>
            <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
              <CardHeader
                title={label}
                className="table-header-name"
                sx={{ fontSize: '20px', color: '#003087' }}
              />
              <TableContainer className="Custom-Table table-container table-header-capitalization">
                <Table>
                  <TableHead>
                    <TableRow key={0}>
                      {keys.map((item, indexhead) =>
                        indexhead > 1 ? (
                          item !== 'contractStatus' && (
                            <TableCell
                              key={indexhead}
                              className={
                                indexhead < 2 ? 'h-partitionKey h-sortKey' : ''
                              }
                            >
                              {item}
                            </TableCell>
                          )) : (
                          ''
                        )
                      )}
                      <TableCell />
                      {/* Add more table headers as needed */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map((item, indexpage) => {
                      return (
                        <>
                          <TableRow
                            key={indexpage}
                            className="table-row cursor-pointer txt-Transform"
                          >
                            {keys.map((key, indexkey) => {
                              return indexkey > 1 ? (
                                key === 'Pause Contract' ? (
                                  <TableCell
                                    key={indexkey}
                                    onClick={(e) => {
                                      childPauseCLick(e);
                                      setContactId(item['sortKey']);
                                    }}
                                  >
                                    {item[key]}
                                  </TableCell>
                                ) : (
                                  key !== 'contractStatus' &&
                                  (
                                    <TableCell
                                      key={indexkey}
                                      onClick={(e) =>
                                        childOnClick(e, [
                                          item['partitionKey'],
                                          item['sortKey'],
                                          'Detail'
                                        ])
                                      }
                                    >
                                      {item[key]}
                                    </TableCell>
                                  )
                                )
                              ) : (
                                ''
                              );
                            })}
                            <TableCell className="table-td">
                              <IconButton
                                sx={{
                                  '&:hover': {
                                    background: theme.colors.primary.lighter
                                  },
                                  color: theme.palette.primary.main
                                }}
                                color="inherit"
                                size="small"
                                className={`edit-btn ${item['contractStatus'] === 3 && 'invisible'}`}
                                id={'edit_btn_' + keys.sortKey}
                                onClick={(e) =>
                                  childOnClick(e, [
                                    item['partitionKey'],
                                    item['sortKey'],
                                    'Edit'
                                  ])
                                }
                              >
                                Edit
                              </IconButton>
                              <IconButton
                                onClick={(e) => handlePopoverOpen(e, item)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>

                            {/* Add more table cells as needed */}
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <Box
                  p={2}
                  sx={{
                    background: 'white',
                    color: 'black',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                  }}
                >
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={(e) =>
                      childOnClick(e, [
                        selectedItem['partitionKey'],
                        selectedItem['sortKey'],
                        'Detail'
                      ])
                    }
                  >
                    View
                  </Typography>
                  <Typography sx={{ cursor: 'pointer' }}
                    onClick={(e) =>
                      handleDuplicate(e,
                        selectedItem['sortKey'])
                    }
                  >Duplicate</Typography>
                  {/* <Typography
                  sx={{ cursor: 'pointer' }}
                  onClick={() => childTerminateClick(selectedItem)}
                >
                  Terminate
                </Typography> */}
                  {selectedItem?.contractStatus !== 1 ? (
                    <Typography sx={{ cursor: 'pointer' }}
                      onClick={(e) =>
                        handleActivate(e,
                          selectedItem['sortKey'], selectedItem['contractStatus'])
                      }
                    >Activate</Typography>
                  ) : (
                    <Typography sx={{ cursor: 'pointer' }}
                      onClick={(e) =>
                        handleDeactivate(e,
                          selectedItem['sortKey'], selectedItem['contractStatus'])
                      }
                    >Deactivate</Typography>
                  )}
                </Box>
              </Popover>

              {/* Pagination */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={contractDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleContractDialog('cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center txt-Transform">
          {Isduplicate ? 'Duplicate Contract' : `${type} Contract`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                {Isduplicate ? 'Do you want to create duplicate contract?' : `Are you sure, you want to ${type} this contract?`}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleContractDialog('yes')}
          >
            Yes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleContractDialog('cancel')}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
