/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { CustomButton, SearchInput } from 'src/components/Global';
import ErrorMessage from '../../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../../components/Loader';
// import { getLocalData } from 'src/utils';
import { NotesFilters } from './NotesFilters';
import { NotesTable } from './NotesTable';


const Notes = (props) => {
  const { clientId, invokeRefresh,setInvokeRefresh } = props;
  // const locationId = getLocalData('locationId');
  // const userId = sessionStorage.getItem('UserId');
  const [loading, setLoading] = useState(true);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [alertTypeList, SetAlertTypeList] = useState([]);

  const [filters, setFilters] = useState({
    status: null,
    notesFor: null
  });

  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const openDetail = (id, screen, e) => {
    e.preventDefault();
    e.stopPropagation();

    handleOpenScreen(screen);
  };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  //   ===============call api here=============

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined ||
        (Array.isArray(filtersParams[key]) && filtersParams[key].length === 0)
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  const getAllNotes = async () => {
    let cid = localStorage.getItem('clientId');
    let encodedClientId = encodeURIComponent(clientId) || encodeURIComponent(cid);
    setLoading(true);
    setTableLoading(true);
    let queryString = filtersQuery();
    try {
      const [
        trophyResponse
      ] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllNotes?clientId=${encodedClientId}&${queryString}`
        )
      ]);

      if (!trophyResponse?.data?.data?.error) {
        const modifiedData = trophyResponse?.data?.data?.Items.map(
          (item) => ({
            ...item,
            id: item.sortKey
          })
        );
        setServicesData(modifiedData);
        setLoading(false);
        setTableLoading(false);
      } else {
        setLoading(false);
       // setShowErrorMessage(true);
        setErrorMessage(
          trophyResponse.message || 'Something went wrong with Services'
        );
      }
      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setLoading(false);
      setTableLoading(false);
      // setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  useEffect(() => {
    getAllNotes();
  }, [JSON.stringify(filters), clientId]);

  // State change for invoke refresh
  useEffect(() => {
    if(invokeRefresh) {
      getAllNotes();
      setInvokeRefresh(false);
    }
  }, [invokeRefresh]);

  return (
    <>
      <Loader IsLoading={loading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          getDetails={getAllNotes}
        />
      )}

      <Container maxWidth="100%" className="custom-container p-0">
        {(() => {
          switch (showScreen) {
            default:
              return (
                <Box justifyContent="center" direction="column">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid item width={'100%'}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          <Grid item marginY="20px"
                            xs={12} sm={6} >
                            <CustomButton
                              onClick={() => setIsFilters(!isFilters)}
                              variant={isFilters ? 'contained' : 'outlined'}
                            >
                              Filter (All)
                              <KeyboardArrowDownRoundedIcon
                                className={clsx(
                                  !isFilters && classes.closeY,
                                  isFilters && classes.openY
                                )}
                              />
                            </CustomButton>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            // marginY={{ sm: '20px' }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <SearchInput
                              value={searchQuery}
                              setSearchQuery={setSearchQuery}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                    <>
                      {isFilters ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <NotesFilters
                            filters={filters}
                            setFilters={setFilters}
                            tableRows={servicesData}
                            // alertTypeList={alertTypeList}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        <NotesTable
                          tableRows={servicesData}
                          searchQuery={searchQuery}
                          openDetail={openDetail}
                          loading={tableLoading}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          selectedStatus={selectedStatus}
                          setSelectedStatus={setSelectedStatus}
                        />
                      </Grid>
                    </>
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>
    </>
  );
};

export default Notes;
