import { useState, useEffect } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Autocomplete,
  DialogActions
} from '@mui/material';
import './Addteam.css';
import { ApiService } from '../../../services/ApiService';
import Loader from '../../../components/Loader';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddTeam({
  setRefreshTeams,
  openAddteam,
  setopenAddteam,
  setIsAddTeamOpen,
 // getAllTeams
}) {
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });
  const autoCompleteClasses = useAutocompleteStyles();
  /* States to set the data */
  const [teamName, setteamName] = useState('');

  /* Team members array to get team names from api */
  const [teamMembers, setteamMembers] = useState([]);
  /* Team members array to store team members name */
  const [teamMembersValue, setteamMembersValue] = useState([]);
  const [description, setdescription] = useState('');
  /* States to set error messages */
  const [teamNameError, setteamNameError] = useState('');
  const [teamMembersError, setteamMembersError] = useState('');

  /* State to open confirmation dialog box */
  const [open, setOpen] = useState(false);
  /* State to check whether the value is added/updated  */
  const [isUpdated, setIsUpdated] = useState(false);
  /* State for checking the state of field update */
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  // const [teamSelected, setTeamSelected] = useState([]);

  useEffect(() => {
    getUsersList();
    setOpen(false);
    setopenAddteam(true);
    setIsUpdated(false);
  }, []);

  /* Variable getting user id from local storage */
  let userId = sessionStorage.getItem('UserId');

  /* Function to get the team members  */

  const getUsersList = () => {
    ApiService.get( `${process.env.REACT_APP_API_END_POINT}users/getAllUsers?limit=500&status=1&roleName=Manager` )
      .then((response) => response)
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Set the list of Teams
          setteamMembers(data.data.Items);
          // Now hide the loader
          // setIsLoading(false);
        }
      });
  };
  /* Function to set team name */

  const teamNameSet = (data) => {
    if (!data) {
      setteamNameError('Team name is required');
    } else {
      setteamNameError('');
      setteamName(data);
      setIsFieldUpdated(true);
    }
  };

 
  /* Function to set description */
  const descriptionSet = (description) => {
    if (description) {
      setdescription(description);
      setIsFieldUpdated(true);
    }
  };

  /* Function to add team  */

  const AddTeam = () => {
    if (!teamName) {
      setteamNameError('Team name is required');
    }
    if (teamMembersValue.length === 0) {
      setteamMembersError('Team members are required');
      setIsFieldUpdated(true);
    }
    if (teamName && teamMembersValue.length > 0) {
      AddTeamData();
    }
  };

  /* Function to call add team api */

  const AddTeamData = () => {
    // Shwo loader
    setIsLoading(true);

    let teamData = {
      teamName: teamName,
      description: description,
      teamMembers: teamMembersValue,
      createdBy: userId,
      isActive: true
    };
    ApiService.post(`${process.env.REACT_APP_API_END_POINT}teams`, teamData)
      .then((response) => response)
      .then((data) => {
        if (data.statusCode) {
          // Now call method to refresh the team list
          // getAllTeams();
          setopenAddteam(false);
          setIsUpdated(true);
          // Shwo loader
          setIsLoading(false);
          setOpen(true);
          setRefreshTeams(true);
        }
      });
  };

  /* Function to close confirm dialog */

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      setopenAddteam(true);
    } else if (val === 'Discard') {
      setIsAddTeamOpen(false);
      setopenAddteam(false);
      // setOpenTeams(true);
      setOpen(false);
      setopenAddteam(false);
      setIsFieldUpdated(false);
    //  getAllTeams();
      clearAll();
    }
  };

  /* Function to clear all fields and error */

  const clearAll = () => {
    setteamName('');
    teamMembersValue.length = 0;
    setdescription('');
    setteamNameError('');
    setteamMembersError('');
  };

  /* Function to close the add item dialog */

  const handleAddteamClose = () => {
    if (isFieldUpdated) {
      setopenAddteam(true);
      setOpen(true);
    } else {
      setIsAddTeamOpen(false);
      setopenAddteam(false);
      // setOpenTeams(true);
      setopenAddteam(false);
      setOpen(false);
      clearAll();
    }
  };

  // Method to handle team change and set filters
  const handleSelectUsers = (value) => {
    const userId = value.map((val) => {
      return val.userId;
    });
    setteamMembersError('');
    // Remove the same value of array from teamId
    const uniqueUserId = userId.filter(function (item, pos) {
      return userId.indexOf(item) === pos;
    });
    setteamMembersValue(uniqueUserId);
    setIsFieldUpdated(true);
    // setFilters((prevState) => ({ ...prevState, teamId: uniqueteamId }));
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        open={openAddteam}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleAddteamClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right not "
        maxWidth="sm"
        sx={{ maxHeight: '600px', mt: 15, ml: 2 }}
      >
        <Box>
          <DialogTitle className="dialog-box-right-addTask -team-container-white-box">
            {/* this class is commented for the above code className="alert-title align-right dialog-header" */}
            <Box
              className="dialog-text "
              sx={{ display: 'flex', justifyContent: 'space-between' , fontWeight:'700'}}
            >
              <Typography className="text-primary">ADD TEAM</Typography>
              {/* remove icon */}
              <Button
                className="details-remove-icon task-cross p-0 no-hover"
                onClick={() => handleAddteamClose('Close')}
              >
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                    fill="#E64646"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
        </Box>
        <DialogContent className="-team-container-white-box">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            <TextField
              fullWidth
              name="Team name"
              autocomplete="Team name"
              label="TEAM NAME"
              placeholder="Team name"
              defaultValue={teamName}
              onChange={(e) => teamNameSet(e.target.value)}
              className="input-field-styling field-top-margin-edit-location location-field required-field"
              //   error={Boolean(teamNameError)}
              helperText={teamNameError}
              InputLabelProps={{
                className: 'label-style-create-franchisee required-label'
              }}
              InputProps={{}}
              // sx={{
              //   height: '50px !important',
              // }}
            />

            <Autocomplete
              multiple
              classes={autoCompleteClasses}
              className="status-field field-label-css status-field-lbl"
              onChange={(event, newValue) => {
                handleSelectUsers(newValue);
                // setTeamSelected(newValue);
              }}
              // onChange={teamMembersSet}
              // options={teamMembers.map(
              //   (option) => option.firstName + ' ' + option.lastName
              // )}
              options={teamMembers}
              getOptionLabel={(option) =>
                option.firstName + ' ' + option.lastName
              }
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={params?.label || 'TEAM MEMBERS'}
                  //   error={Boolean(teamMembersError)}
                  helperText={teamMembersError}
                  className="field-top-margin-edit-location -min-field-height required-field"
                  // className="field-top-margin-edit-location required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                  }}
                />
              )}
            />

            <TextField
              fullWidth
              multiline
              id="my-textarea"
              name="Description"
              autocomplete="Description"
              label="DESCRIPTION"
              placeholder="Notes here . . . "
              variant="outlined"
              defaultValue={description}
              className="field-top-margin-edit-location "
              onChange={(e) => descriptionSet(e.target.value)}
              InputLabelProps={{
                className: 'label-style-create-franchisee'
              }}
              InputProps={{ rows: 6 }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="button-style-add-team field-top-margin-edit-location"
              onClick={AddTeam}
            >
              <Typography className="next-button-text">Submit</Typography>
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {!isUpdated ? 'Save Changes' : 'Congratulations'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                  What do you want to do?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                   Team has been added successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Go Back to editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddTeam;
