import "./style.css";
import { Box } from "@mui/system";
// import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
// import { CustomTable } from "../../../components/CustomTable";
import { CampaignFilters } from "../../../components/Filters/CampaignFilters";
import CreateCampaign from "./CreateCampaign";
import CampaignMetrics from "./CampaignMetrics";
import Header from 'src/components/Header';
import React, { useEffect, useState } from "react";
import { getLocalData } from 'src/utils';
import SpecificCampaign from "./SpecificCampaign";
import { filtersQuery } from "../../../utils";
import { ApiService } from "../../../services/ApiService";
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
// import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../components/Loader';
import { CampaignTable } from "./CampaignTable";

const CampaignMain = () => {
  let elm = document.getElementsByClassName("page-header-text")[0];
  if (elm) elm.innerHTML = "";
  const now = new Date();
  const offset = now.getTimezoneOffset();
  const utcDate = new Date(now.getTime() + offset * 60000);
  const utcString = utcDate.toUTCString();
  console.log(now, "now", utcString, "utcStrings");
  // const locationId = getLocalData('locationId');
  const [filters, setFilters] = useState({
    status: "",
    SegmentId: "",
    ScheduleType: "",
    Channel: ""

  });
  const franchiseeId = getLocalData('franchiseeId');
  const [showScreen, setShowScreen] = useState(0);
  const [selected, setSelected] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedDuplicate, setSelectedDuplicate] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [isMsg, setIsMsg] = useState(false)
  const [msg, setmsg] = useState('')


  const [tableData, setTableData] = useState([{
    sortKey: "",
    partitionKey: "",
    "Campaign Name": "Black Friday Sale",
    Channel: "Email",
    Cohort: "No Activity",
    Template: "Template #01",
    Schedule: "Daily",
    Status: "Completed"
  }]);
  useEffect(() => {
    getCampaignList();
  }, [filters, showScreen]);
  const getCampaignList = async () => {

    try {
      setIsLoading(true);
    let queryString = filtersQuery(filters);
    let filtersParams = queryString ? `&${queryString}` : "";
    let encodedfranchiseeId = encodeURIComponent(franchiseeId);
    let response = await ApiService.get(
      `${process.env.REACT_APP_API_END_POINT}pinpoint/getAllCampaignList?partitionKey=${encodedfranchiseeId}${filtersParams}`
    );
    setIsLoading(false);
    setmsg('')
    let Item;
    if (response && response?.data && response?.data.Items) {
      setmsg('')
      setIsLoading(false);
      Item = response.data.Items;
      
    } else {
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage(response?.data.message || 'No records found');
      setmsg(response?.data.message);
      Item = []; // Set Item as an empty array to avoid errors later
    }

    const result = Item.map((obj) => {
      const {
        partitionKey,
        sortKey,
        Name,
        Channel,
        TemplateConfiguration,
        ScheduleType,
        SegmentName,
        status
      } = obj;
     // const statusText = status === 'COMPLETED' ? 'Completed' : status;
     // const ScheduleText = ScheduleType === 'immediately' ? 'Immediately' : ScheduleType;
      return {
        sortKey: sortKey,
        partitionKey: partitionKey,
        "Campaign Name": Name,
        Channel: Channel,
        Cohort: SegmentName || "No Activity",
        Template: TemplateConfiguration?.EmailTemplate?.Name || TemplateConfiguration?.SMSTemplate?.Name,
        Schedule: capitalizeStatus(ScheduleType),
        Status: capitalizeStatus(status)
      };
    });
    setSelectedRow(response?.data.Items)
    setTableData(result);
  } catch(err){
    setIsLoading(false);
    setShowErrorMessage(true);
    setErrorMessage('Something went wrong, try later.');
    console.error("Error : ", err);
  }
  };

  // method to capitalize first letter
  function capitalizeStatus(status) {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }
  const handleTableClick = (rest) => {
    const [, sortKey] = rest;
   // const list = [...tableData];
   console.log('tableData',tableData)
    const filteredItem = selectedRow.filter((item) => item.sortKey === sortKey)[0];
   setSelectedType('Edit');
    setSelectedDuplicate(filteredItem);
    setSelected(filteredItem);
    console.log('rest',selectedRow)
    if(rest[2]=== 'Edit'){
      setShowScreen(1);

    }else{
      setShowScreen(2);
    }
    
    
  };
  const handleCampaignDetailClick = (type, data) => {

    setSelectedType(type);
    if (type === "Duplicate" || type === "Edit") {
      setShowScreen(1);
      console.log(data, "handleClick", data);
      setSelectedDuplicate(data);
    } else {
      setShowScreen(3);
    }
  };
  const handleButtonClick = () => {
    setSelectedType('');
    setShowScreen(1);
    setSelected(null);
    setSelectedDuplicate(null);
  };

  const buttons = [
    {
      label: 'New Campaign',
      onClick: handleButtonClick,
      theam: true
    },

  ];
  return (
    <>
 
 {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <CreateCampaign setActive={setShowScreen} filters={filters} getListData={selectedRow}  rowData={selected} selected={selectedDuplicate} isNew={selectedType !== 'Edit'} />
            );
          case 2:
            return (
              <SpecificCampaign setActive={setShowScreen} selected={selected} handleClick={handleCampaignDetailClick} />
            );
          case 3:
            return (
              <CampaignMetrics setActive={setShowScreen} selected={selectedDuplicate} handleClick={handleCampaignDetailClick} />  );
          default:
            return (
              <Box>
                <Helmet>
                  <title>Campaign</title>
                </Helmet>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "40px",
                    marginRight: "40px",
                    justifyContent: "space-between",
                    flexDirection: "row"

                  }}
                >
                  {showScreen === 0 && <Header title="Campaign" buttons={buttons} />}
                  {/* <Typography className="page-title" variant="h1">
                    Campaign
                  </Typography>
                  <Button
                    variant="contained"
                    className="top-button-style campaign-add-btn"
                    onClick={() => {
                      setShowScreen(1);
                      setSelected(null);
                      setSelectedDuplicate(null);
                    }}
                  >
                    New Campaign
                  </Button> */}

                </Box>
                <Box className="detail-table campaign-table">
                <Loader IsLoading={isLoading} />
               
               
                  <CampaignTable
                    filterCompoenent={
                      <CampaignFilters setFilters={setFilters} filters={filters} />
                    }
                    data={tableData || []}
                    label={"All Campaigns"}
                    isFilter
                    isSearch
                    isPagination
                    handleOnClick={handleTableClick}
                    error =  {msg || 'No data available'}
                  />
                 
                </Box>
                
              </Box>
            );
        }
      })()}

    </>
  );
};
export default CampaignMain;