/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';

import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Hidden,
  Stack,
  Typography,
  CircularProgress,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { CustomButton, SearchInput } from 'src/components/Global';
import Header from '../../../components/Header';

import GlobalData from 'src/global';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../components/Loader';

import { Summary } from './Summary';
import { ServicesFilters } from './ServicesFilters';
import { ServicesTable } from './ServicesTable';
import { AddService } from './AddService';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Services = () => {
  const title = GlobalData[0].title;
  const userId = sessionStorage.getItem('UserId');
  const [loading, setLoading] = useState(true);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allPreReq, setAllPreReq] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [serviceDetails, setServiceDetails] = useState();
  const [isServiceEdit, setIsServiceEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);

  const [filters, setFilters] = useState({
    // isActive: null,
    status: 1,
    categoryName: [],
    typeOfService: null,
    difficultyLevel: [],
    free: null,
    cost: null
  });

  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);

  useEffect(() => {
    const data = servicesData?.filter((item) =>
      selectedRows?.includes?.(item.id)
    );
    setSelectedRowsData(data);
  }, [selectedRows]);

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleResetStates = () => {
    setIsInitialState(true);
    setShowScreen(1);
    setIsServiceEdit(false);
    setServiceDetails();
    setSelectedRows([]);
    setSelectedRowsData([]);
    setSelectedStatus('');

    //call fetch api for getting latest data of services
    fetchData();
    getAllServices();
  };

  function findObjectById(id) {
    return servicesData?.find((obj) => obj.id === id);
  }

  const openDetail = (id, screen, e) => {
    e.preventDefault();
    e.stopPropagation();

    setServiceDetails(findObjectById(id));
    setIsServiceEdit(true);
    handleOpenScreen(screen);
  };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  //   ===============call api here=============

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === -1 ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  const getAllServices = async () => {
    setLoading(true);
    setTableLoading(true);
    let queryString = filtersQuery();

    try {
      const [
        servicesResponse,
        levelsResponse,
        preReqResponse,
        categoriesResponse
      ] = await Promise.all([
        ApiService.get(
          `${process.env.REACT_APP_API_END_POINT}services/getAllServices?${queryString}`
        )
      ]);

      if (!servicesResponse?.data?.error) {
        const modifiedData = servicesResponse?.data?.Items?.map((item) => ({
          ...item,
          id: item.sortKey
        }));
        setServicesData(modifiedData || []);
        setLoading(false);
      } else {
        setLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(
          servicesResponse.message || 'Something went wrong with Services'
        );
        // toast.error(
        //   servicesResponse.message || 'Something went wrong with Services'
        // );
      }
      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setLoading(false);
      setTableLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
      // toast.error('Internal Server Error');
    }
  };
  const fetchData = async () => {
    setTableLoading(true);
    let queryString = filtersQuery();

    try {
      const [
        // servicesResponse,
        preReqResponse,
        categoriesResponse,
        levelsResponse
      ] = await Promise.all([
        // axios.get(
        //   `${process.env.REACT_APP_API_END_POINT}services/getAllServices?${queryString}`
        // ),
        ApiService.get(`${process.env.REACT_APP_API_END_POINT}trophy`),
        ApiService.get(
          `${process.env.REACT_APP_API_END_POINT}services/getAllServiceCategories`
        ),
        ApiService.get(
          `${process.env.REACT_APP_API_END_POINT}services/getAllLevels`
        )
      ]);

      // if (!servicesResponse?.data?.data?.error) {
      //   const modifiedData = servicesResponse?.data?.data?.Items?.map(
      //     (item) => ({
      //       ...item,
      //       id: item.serviceId
      //     })
      //   );
      //   setServicesData(modifiedData);
      // } else {
      //   toast.error(
      //     servicesResponse.message || 'Something went wrong with Services'
      //   );
      // }

      if (!levelsResponse?.data?.error) {
        setAllLevels(levelsResponse?.data?.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          levelsResponse.message || 'Something went wrong with service levels'
        );
        // toast.error(
        //   levelsResponse.message || 'Something went wrong with service levels'
        // );
      }

      if (!categoriesResponse?.data?.error) {
        setAllCategories(categoriesResponse?.data?.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          categoriesResponse.message ||
            'Something went wrong with Service categories'
        );
        // toast.error(
        //   categoriesResponse.message ||
        //   'Something went wrong with Service categories'
        // );
      }
      if (!preReqResponse?.data?.error) {
        setAllPreReq(preReqResponse?.data?.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          preReqResponse.message ||
            'Something went wrong with Service preRequistie'
        );
        // toast.error(
        //   preReqResponse.message ||
        //   'Something went wrong with Service preRequistie'
        // );
      }

      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setLoading(false);
      setTableLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
      // toast.error('Internal Server Error');
    }
  };

  useEffect(() => {
    // fetchData();
    getAllServices();
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    fetchData();
  }, []);

  function getServiceIds() {
    let array = [];
    selectedRowsData?.map((item) => array.push(item.sortKey));

    return array;
  }

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setLoading(true);
    setOpen(false);
    setBtnLoading(true);

    const data = {
      // isActive: selectedStatus === 'Active',
      status: selectedStatus,
      updatedBy: userId,
      serviceIds: getServiceIds()
    };

    try {
      // const encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}services/updateBulk`,
        data
      );

      if (!response.data?.error) {
        setLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage('All the selected sevices have been updated successfully!');
        // toast.success(response.data?.message || 'Service status updated');
        // handleResetStates()
        setIsUpdated(true);
        handleResetStates();

        // setOpen(true);
      } else {
        setLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(
          response.data?.message ||
            'Something went wrong with update service status'
        );
        // toast.error(
        //   response.data?.message ||
        //   'Something went wrong with update service status'
        // );
      }

      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        handleResetStates();
        setIsUpdated(false);
      }
    }
  };
  const handleButtonClick = () => {
    handleOpenScreen(2);
  };
  const buttons = [
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theam: true
    }
  ];
  return (
    <>
      <Helmet>
        <title>Service Management | {title}</title>
      </Helmet>
      <Loader IsLoading={loading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          getDetails={getAllServices}
        />
      )}

      <Container maxWidth="100%" className="custom-container">
        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <Box justifyContent="center" direction="column">
                  <AddService
                    handleOpenScreen={handleOpenScreen}
                    handleResetStates={handleResetStates}
                    isServiceEdit={isServiceEdit}
                    setIsServiceEdit={setIsServiceEdit}
                    allCategories={allCategories}
                    allLevels={allLevels}
                    allPreReq={allPreReq}
                    serviceDetails={serviceDetails}
                  />
                </Box>
              );

            case 3:
              return (
                <Box justifyContent="center" direction="column">
                  <Summary
                    handleOpenScreen={handleOpenScreen}
                    handleResetStates={handleResetStates}
                    serviceDetails={serviceDetails}
                    allPreReq={allPreReq}
                  />
                </Box>
              );

            default:
              return (
                <Box justifyContent="center" direction="column">
                  <Header title="Service Management" buttons={buttons} />
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid item width={'100%'}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          <Grid item marginY="20px">
                            <CustomButton
                              onClick={() => setIsFilters(!isFilters)}
                              variant={isFilters ? 'contained' : 'outlined'}
                            >
                              Filter (All)
                              <KeyboardArrowDownRoundedIcon
                                className={clsx(
                                  !isFilters && classes.closeY,
                                  isFilters && classes.openY
                                )}
                              />
                            </CustomButton>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            marginY={{ sm: '20px' }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <SearchInput
                              value={searchQuery}
                              setSearchQuery={setSearchQuery}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <>
                      {isFilters ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <ServicesFilters
                            filters={filters}
                            setFilters={setFilters}
                            allCategories={allCategories}
                            allLevels={allLevels}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        <ServicesTable
                          tableRows={servicesData}
                          searchQuery={searchQuery}
                          openDetail={openDetail}
                          loading={tableLoading}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          selectedStatus={selectedStatus}
                          setSelectedStatus={setSelectedStatus}
                          handleBulkAdd={handleBulkAdd}
                          btnLoading={btnLoading}
                        />
                      </Grid>
                    </>
                    {/* )} */}
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Save Changes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                Are you sure you want to mark Activate/Deactivate,
                <Typography className="title-text-sub">
                all the selected items?
              </Typography>
              </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected sevices have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Services;
