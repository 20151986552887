import { Helmet } from 'react-helmet-async';
import React, { useEffect, useRef, useState } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import Footer from 'src/components/Footer';
import { Box } from '@mui/material';
import {
  Container,
  Grid,
  Slide
  // Divider,
} from '@mui/material';

import FranchiseListAPI from './FranchiseeListAPI';
import { useNavigate } from 'react-router-dom';
import DrawFranchiseeForm from '../CreateFranchisee/DrawFranchiseeForm';
import GlobalData from '../../../global';
import Header from '../../../components/Header';
import AddAdditionalOwner from './AddAdditionalOwner';

function FranchiseeList() {
  const navigate = useNavigate();
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Franchisee Management');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Franchisee Management';

  // Get the title from global data
  const title = GlobalData[0].title;
  const childRef = useRef(null);

  // set add button click event
  // const [addBtnClick, setAddBtnClick] = useState(false);
  // const [open, setOpen] = React.useState(false);
  // const [addBtnTrigger, setAddBtnTrigger] = useState(false);
  // const [showForm, setshowForm] = useState(false);
  const [statesList, setstatesList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [franchiseeItem, setFranchiseeItem] = useState(null);

  const [showScreen, setShowScreen] = useState(0);

  useEffect(() => {
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
    getStates();
    getRoles();
    //  getFranchise();
    // let headerelm = document.getElementsByClassName('add-btn-container')[0];
    // if (headerelm) headerelm.setAttribute('style', "visibility: unset !important");
  }, []);

  /* Function to get all states for drop-down */
  const getStates = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`,
      {
        method: 'GET',
        headers: {
          // Accept: 'application/json',
          // 'content-Type': 'application/json',
          Accept: '*/*',
          // 'content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        }
      }
    );

    const data = await response.json();
    console.log(data.data.Items);
    // set the states
    setstatesList(data.data.Items);
  };

  /* Function to get all roles for drop-down */
  const getRoles = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_FEATURED_API_END_POINT}users/getInvolvementLevel`,
      {
        method: 'GET',
        headers: {
          // Accept: 'application/json',
          // 'content-Type': 'application/json',
          Accept: '*/*',
          // 'content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        }
      }
    );

    const data = await response.json();
    console.log(data.data.Items);
    // set the states
    setRolesList(data.data.Items);
  };

  // const addFranchiser = () => {
  //   // navigate("/app/addfranchise");
  //   // setAddBtnClick(true);
  //   handleClickOpen();
  //   // setshowForm(true);
  //   setShowScreen(1)
  //   // console.log("addBtnClick", addBtnClick);
  // }
  // Method to open the dialog
  // const handleClickOpen = () => {
  //   // setOpen(true);
  // };

  // console.log("addBtnTrigger", addBtnTrigger);
  // if(addBtnTrigger) setOpen(true);

  // Method to close the dialog
  const handleClose = () => {
    // if (val === 'Delete') {
    // call the api to delete the location
    // deleteLocation();
    // }
    // trigger the parent method to refresh the data
    // refreshFranchiseeData();
    // setOpen(false);
    // setshowForm(false)
  };

  // Method to close the franchisee form
  const closeFranchiseeDialog = () => {
    // When popup is open then not call the loader
    // setIsPopup(true);

    // trigger the parent method to refresh the data
    // childRef.current.refreshFranchiseeData();

    handleClose();

    // Not call the get location method again
    // getFranchise();
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  const handleButtonClick = () => {
    setShowScreen(1);
  };

  const buttons = [
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theme: true
    }
  ];

  return (
    <>
      <Helmet>
        <title>Franchise Management | {title}</title>
      </Helmet>

      {/* container */}

      {/* View Location dialog */}
      <Box sx={{ pl: '35px', pr: '50px' }}>
        {showScreen === 0 && (
          <Header title="Franchise Management" buttons={buttons} />
        )}
      </Box>
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <Box sx={{ width: '100%' }}>
                <DrawFranchiseeForm
                  FranchiseeAdded={closeFranchiseeDialog}
                  // showForm={setshowForm}
                  Transition={Transition}
                  statesList={statesList}
                  setShowScreen={setShowScreen}
                  rolesList={rolesList}
                />
              </Box>
            );

          case 4:
            return (
              <Box sx={{ width: '100%' }}>
                <AddAdditionalOwner
                  FranchiseeAdded={closeFranchiseeDialog}
                  // showForm={setshowForm}
                  Transition={Transition}
                  statesList={statesList}
                  setShowScreen={setShowScreen}
                  rolesList={rolesList}
                  franchiseeItem={franchiseeItem}
                />
              </Box>
            );

          default:
            return (
              <>
                <Container
                  maxWidth="100%"
                  sx={showScreen === 0 && { mt: 3 }}
                  className="main-container relative"
                >
                  <Grid container justifyContent="end" alignItems="center">
                    <Grid item width={'100%'}>
                      {/* Now draw the franchisee table */}
                      <FranchiseListAPI
                        ref={childRef}
                        showScreen={showScreen}
                        setShowScreen={setShowScreen}
                        setFranchiseeItem={setFranchiseeItem}
                        franchiseeItem={franchiseeItem}
                        rolesList={rolesList}
                      />
                    </Grid>
                  </Grid>
                </Container>
              </>
            );
        }
      })()}

      <Footer />
    </>
  );
}

export default FranchiseeList;
