import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { ApiService } from '../../../services/ApiService';
import { getLocalData } from '../../../utils';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';

const refundMethodOptions = [
  { label: 'Cash', value: 'cash' },
  // { label: 'Credit', value: 'credit' },
  // { label: 'Check', value: 'check' },
  // { label: 'Comp', value: 'comp' },
  { label: 'Store Credit', value: 'storeCredit' }
];

const SalesDetail = ({ detail, setActive, selectedOrderId }) => {
  console.log(detail, 'detail selected');
  const locationId = getLocalData('locationId');
  const [clientDetail, setClientDetail] = useState(detail);
  const [returnStatus, setReturnStatus] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedLocationLists, setSelectedLocationLists] = useState([]);

  // const [searchText, setSearchText] = useState('');
  // const [page, setPage] = useState(0);

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  const [formData, setFormData] = useState({
    refundMethod: null,
    refundReason: null,
    refundAmount: null
  });
  const getClientDetail = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedOrderId = encodeURIComponent(selectedOrderId);

    let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/paymentOrderDetail?locationId=${encodedLocationId}&orderId=${encodedOrderId}`
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    setClientDetail(Item[0]);

    if (Item.length > 0) {
      const tableItem = Item[0]?.items || [];
      console.log(tableItem, 'ItemItem');
      // const modifiedArray = tableItem?.map((membership) => ({
      //   sortKey: membership?.itemId || '',
      //   partitionKey: locationId || '',
      //   'Item Name': membership?.itemName || '',
      //   'Remaining Credits/Qty': membership?.quantity || 0,
      //   Variant: membership?.itemVariantType || '-',
      //   Amount: membership?.price || 0.0
      // }));

      setTableData(tableItem);
    }
  };

  useEffect(() => {
    getClientDetail();
  }, [detail]);
  useEffect(() => {
    setFormData({ ...formData, refundAmount: clientDetail.refundAmount });
  }, [clientDetail]);

  const returnProceedFtn = async () => {
    const payload = {
      locationId: locationId,
      clientId: clientDetail.clientId,
      petId: clientDetail.petId,
      orderId: selectedOrderId,
      refundMethod: formData.refundMethod.value,
      refundAmount: formData.refundAmount.toString(),
      refundReason: formData.refundReason
    };
    let response = await ApiService.post(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/returnAndRefund`,
      payload
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    if (response.statusCode === 200) {
      setActive(3);
    } else {
      setActive(2);
    }
    console.log(Item, 'helllllllo');
  };

  const dateFormate = (date) => {
    // let utc = moment(date).format('MMMM Do YYYY, h:mm:ss A');
    let utc;
    utc = date ? moment(date).format('MM-DD-YYYY') : '';
    return utc;
    // let dateFor = date && new Date(date);
    // // let utc = dateFor.toUTCString();
    // let finaldate = dateFor.toLocaleString();
    // return finaldate;
  };

  const numberFormate = (num) => {
    let value = num && num / 100;
    const formatted = Number(value).toLocaleString('en', options);
    // console.log(formatted);
    return formatted;
  };

  // Event handler to increment the quantity of an item
  const incrementQuantity = (itemId) => {
    const updatedItems = tableData?.map((item) => {
      if (item.itemId === itemId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setTableData(updatedItems);
  };

  // Event handler to decrement the quantity of an item
  const decrementQuantity = (itemId) => {
    const updatedItems = tableData?.map((item) => {
      if (item.itemId === itemId && item.quantity > 0) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
    setTableData(updatedItems);
  };

  const handleSelectAllLocationItems = (event) => {
    setSelectedLocationLists(
      event.target.checked
        ? tableData?.map((locationItem) => locationItem.itemId)
        : []
    );
  };

  const handleSelectOneLocationItem = (event, locationItemId) => {
    event.stopPropagation();
    // event.preventDefault();
    if (!selectedLocationLists.includes(locationItemId)) {
      setSelectedLocationLists((prevSelected) => [
        ...prevSelected,
        locationItemId
      ]);
    } else {
      setSelectedLocationLists((prevSelected) =>
        prevSelected.filter((itemId) => itemId !== locationItemId)
      );
    }
  };
  const selectedSomeLocationItems =
    selectedLocationLists?.length > 0 &&
    selectedLocationLists?.length < tableData?.length;
  const selectedAllLocationItems =
    selectedLocationLists?.length === tableData?.length;

  console.log(selectedLocationLists, 'clientDetailclientDetailclientDetail');

  return (
    <>
      <Box className="main-sales-detail">
        <Typography className="page-title" variant="h1">
          <Box
            className="back-arrow-close"
            onClick={() => setActive(0)}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '15px'
            }}
          >
            <svg
              width="27"
              height="19"
              viewBox="0 0 27 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                fill="#003087"
              />
            </svg>
            Order: {clientDetail?.transactionId || clientDetail?.orderId}
          </Box>
        </Typography>
        <Box className="detailbox-main">
          <Box className="detail-section">
            <Box className="section-main">
              <Box>
                <Stack>PAYMENT METHOD :</Stack>
                <Stack>{clientDetail?.paymentSourceType || '-'}</Stack>
              </Box>
              <Box>
                <Stack>PURCHASE DATE :</Stack>
                <Stack>
                  {' '}
                  {clientDetail?.orderDate
                    ? dateFormate(clientDetail.orderDate)
                    : clientDetail?.dateOfPurchase &&
                      dateFormate(clientDetail.dateOfPurchase)}
                </Stack>
              </Box>
            </Box>
            <Box className="section-main">
              <Box>
                <Stack>TRANSACTION ID :</Stack>
                <Stack>
                  {clientDetail?.transactionId || clientDetail?.orderId}
                </Stack>
              </Box>
              <Box>
                <Stack>LAST FOUR DIGIT OF CARD NUMBER :</Stack>

                <Stack>
                  {clientDetail?.cardNumber?.substring(
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    clientDetail?.cardNumber.length - 4
                  )}
                </Stack>
              </Box>
            </Box>
            <Box className="section-main">
              <Box>
                <Stack>TOTAL TRANSACTION AMOUNT :</Stack>
                <Stack>
                  {' '}
                  {(clientDetail?.paymentAmount &&
                    '$' + numberFormate(clientDetail.paymentAmount)) ||
                    '$0.00'}
                </Stack>
              </Box>
              <Box>
                <Stack>DISCOUNT:</Stack>
                <Stack>
                  ${' '}
                  {(clientDetail?.totalDiscount &&
                    clientDetail.totalDiscount) ||
                    0}
                </Stack>
              </Box>
            </Box>
            <Box className="section-main">
              <Box>
                <Stack>DOG :</Stack>
                <Stack>{clientDetail?.petName || ''}</Stack>
              </Box>
              <Box>
                <Stack>PAYMENT STATUS:</Stack>
                <Stack>
                  {clientDetail?.paymentStatus || ''}
                </Stack>
              </Box>
            </Box>
          
          </Box>
          {clientDetail?.items && (
            <Box className="detail-table">
              {/* <CustomTable
                data={tableData}
                handleQtyChange={handleQtyChange}
                isQty
                isCheckBox
              /> */}

              <Card
                sx={{
                  padding: '15px',
                  borderRadius: '19px 19px 0px 0px'
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '12px'
                    }}
                  >
                    <CardHeader
                      title="All Locations"
                      className="table-header-name"
                      sx={{ fontSize: '20px', color: '#003087' }}
                    />
                  </Grid>
                </Grid>
                <TableContainer className="location-table-container">
                  <Table>
                    <TableHead>
                      <TableRow className="table-header">
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedAllLocationItems}
                            indeterminate={selectedSomeLocationItems}
                            onChange={handleSelectAllLocationItems}
                          />
                        </TableCell>
                        <TableCell>Item Name</TableCell>
                        <TableCell>Remaining Credits/Qty</TableCell>

                        <TableCell>Variant</TableCell>

                        <TableCell>Amount</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData &&
                        tableData?.map((tableItem) => {
                          const isItemSelected =
                            selectedLocationLists?.includes(tableItem?.itemId);
                          return (
                            <TableRow
                              hover
                              key={tableItem?.itemId}
                              selected={isItemSelected}
                              className="table-row cursor-pointer"
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onChange={(event) =>
                                    handleSelectOneLocationItem(
                                      event,
                                      tableItem.itemId
                                    )
                                  }
                                  value={isItemSelected}
                                />
                              </TableCell>
                              <TableCell
                                className="table-td -flex"
                                sx={{ minWidth: '200px' }}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  sx={{ marginTop: 1 }}
                                  gutterBottom
                                >
                                  {tableItem?.itemName || '-'}
                                </Typography>
                              </TableCell>

                              <TableCell className="table-td">
                                {isItemSelected ? (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={0.5}
                                  >
                                    <AiFillMinusCircle
                                      style={{
                                        color: '#003087',
                                        fontSize: '1.2rem',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() =>
                                        decrementQuantity(tableItem?.itemId)
                                      }
                                    />

                                    <Typography
                                      variant="body1"
                                      fontWeight="bold"
                                      color="text.primary"
                                      sx={{ padding: '8px', marginTop: '2px' }}
                                      gutterBottom
                                    >
                                      {tableItem?.quantity}
                                    </Typography>

                                    <AiFillPlusCircle
                                      style={{
                                        color: '#003087',
                                        fontSize: '1.2rem',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() =>
                                        incrementQuantity(tableItem?.itemId)
                                      }
                                    />
                                  </Stack>
                                ) : (
                                  <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                    color="text.primary"
                                    sx={{ padding: '8px', marginTop: '2px' }}
                                    gutterBottom
                                  >
                                    {tableItem?.quantity}
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {tableItem?.itemVariantType || '-'}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {tableItem?.price || 0.0}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Box>
          )}
        </Box>
        {returnStatus && (
          <Box className="return-main">
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '27px' }}>
              <FormControl
                pt={2}
                fullWidth
                variant="outlined"
                sx={{
                  width: '49%'
                }}
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  className="input-field-dropdown-styling required-label"
                  style={{
                    fontWeight: '800',
                    fontSize: '16px',
                    backgroundColor: '#fff'
                  }}
                >
                  REFUND METHOD
                </InputLabel>
                <Select
                  value={formData.refundMethod}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      refundMethod: event.target.value
                    })
                  }
                  label="REFUND METHOD"
                  className="input-field-styling"
                >
                  {refundMethodOptions?.length > 0 &&
                    refundMethodOptions.map((item, index) => (
                      <MenuItem key={index} id={index} value={item}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                name="Card Id"
                fullWidth
                sx={{
                  width: '49%'
                }}
                value={`$${formData.refundAmount}`}
                id="outlined-required"
                label="REFUND AMOUNT*"
                className="text-field-styling"
                // onChange={handleInputChange}
                disabled
                InputLabelProps={{
                  className: 'label-style-text-field'
                }}
              />
            </Box>
            <Grid container>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  multiline
                  id="my-textarea"
                  label="REASON FOR RETURN"
                  placeholder="REASON FOR RETURN"
                  variant="outlined"
                  value={formData.refundReason}
                  className="location-description required-field"
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      refundReason: event.target.value
                    })
                  }
                  InputLabelProps={{
                    className: 'label-style-text-field required-label'
                  }}
                  InputProps={{
                    style: { color: 'black' }, // Set the text color to black here
                    rows: 6
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        <Box className="detail-buttons-main" mb={2}>
          <Stack spacing={1} direction="row" className="gift-card-buttons">
            <Button
              variant="outlined"
              className="top-button-style"
              onClick={() => {
                setReturnStatus(false);
                setActive(0);
              }}
            >
              Cancel
            </Button>
            {!returnStatus ? (
              <>
                <Button
                  variant="contained"
                  className="top-button-style"
                  onClick={() => setReturnStatus(true)}
                >
                  Return
                </Button>
                <Button variant="outlined" className="top-button-style">
                  Print Receipt
                </Button>
                <Button variant="outlined" className="top-button-style">
                  Email Receipt
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                className="top-button-style"
                onClick={returnProceedFtn}
              >
                Proceed Now
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default SalesDetail;
