import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Grid,
  Slide,
  styled,
  Typography
} from '@mui/material';

import Loader from '../../../components/Loader';
import { getLocalData } from 'src/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PricingOptionsDetails = ({
  setShowScreen,
  setOpenEditPage,
  setIsOpenEdit,
  selectedData,
  getPricingOptionDetail
}) => {
  const locationId = getLocalData('locationId');
  const userId = sessionStorage.getItem('UserId');
  let details = selectedData[0];
  let service = selectedData[0].services;
  let expiresOn = selectedData[0] && selectedData[0].expiresOn;
  const serviceNames = service.map((service) => service.name);
  console.log(service);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const isFranchisor = getLocalData('isFranchisor');
  // const [day, setDay] = useState('');
  // const [duration, setDuration] = useState('');
  console.log(details, 'detailsdetails');
  const PricingOptionDetails = styled(Container)(
    ({ theme }) => `
              margin-top: ${theme.spacing(2)};
      `
  );

  useEffect(() => {
    // console.log(selectedData);
    // data=selectedData[0];
    // console.log(data.name);
    console.log(selectedData);
    getData();
  }, []);

  /* Function to get data and  fill the form fields */
  const getData = () => {
    let expireDuration =
      expiresOn && expiresOn.expireDuration ? expiresOn.expireDuration : '';
    let key = Object.keys(expireDuration);
    let value = Object.values(expireDuration);
    console.log(key);
    console.log(value);
    // if (value) setDay(value[0]);
    // if (key) setDuration(key);
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  /* Function to close the supplier details page */
  const handleClose = (val) => {
    if (val === 'Cancel') {
      setOpenDeleteDialog(false);
    } else if (val === 'message-close') {
      setOpenMessageDialog(false);
      setShowScreen(0);
    } else if (val === 'Delete') {
      setOpenDeleteDialog(false);
      deleteRecord();
    }
  };

  const deleteRecord = () => {
    let url;
    let requestBody;
    if (isFranchisor === 'false') {
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}/pricing-options/deletePricingOption`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}/pricing-option/deletePricingOption`;
    }
    setIsLoading(true);
    // console.log(details)
    // let locationId = encodeURIComponent(details.partitionKey);
    // let sortKey = encodeURIComponent(details.sortKey);
    // console.log("sortKey =" + sortKey)

    if (isFranchisor === 'false') {
      requestBody = {
        locationId: locationId,
        id: details.sortKey,
        userId: userId
      };
    } else {
      requestBody = {
        id: details.sortKey,
        userId: userId
      };
    }

    fetch(`${url}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(requestBody) // Include the request body here
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          setIsLoading(false);
          setOpenMessageDialog(true);
          getPricingOptionDetail();
        }

        // console.log(data);
      });
  };

  const handleClosePricingOptionDetails = () => {
    setShowScreen(0);
  };

  const handleClickEdit = () => {
    setIsOpenEdit(true);
    setOpenEditPage(true);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <PricingOptionDetails
        maxWidth={'100%'}
        className="location-details-wrapper"
      >
        <Grid
          className="location-header-container"
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleClosePricingOptionDetails()}
                className="back-arrow-close"
              >
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>
              <Typography className="header-blue-32">{details.name}</Typography>
            </Box>
          </Grid>
          {/* Delete icon and edit button */}
          <Grid xs={4} sm={4} md={4} lg={4} className="align-right">
            {/* remove icon */}

            {/* Edit Button */}
            <Button
              className="details-remove-icon no-hover"
              onClick={handleClickOpenDeleteDialog}
            >
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                  fill="#8098C3"
                />
              </svg>
            </Button>
            <Button
              sx={{ mt: 1 }}
              variant="contained"
              onClick={handleClickEdit}
              className="btn-primary edit-button common-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Box className="location-details-container">
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">CATEGORY</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {details?.categoryName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">PRICE</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {/* {detailsData.price} */}
                {/* {supplierDetails.email} */}
                {details?.price && `$${details?.price}`}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">SERVICES</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">{serviceNames}</Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">FREE</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {/* {details.free.toString()} */}
                {details.free ? 'Yes' : 'No'}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">NAME</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">{details.name}</Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">NO OF SESSIONS </Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {details.numberOfSessions}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">Sharable</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {/* {details.sellOnline.toString()} */}
                {details.sharable ? 'Yes' : 'No'}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">Restricted</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {/* {details.sellOnline.toString()} */}
                {details.restricted ? 'Yes' : 'No'}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">SELL ONLINE</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {/* {details.sellOnline.toString()} */}
                {details.sellOnline ? 'Yes' : 'No'}
              </Box>
            </Grid>
          </Grid>
          {/* <Grid container mt={3} className="location-details-row">
                        <Grid item xs={12} sm={1.5} className="location-details-col">
                            <Box className="location-details-label">INCLUDE IN PROMOTION</Box>
                        </Grid>
                        <Grid item xs={12} sm={4.5} className="location-details-col">
                            <Box className="location-details-value">
                                {details.includeInPromotion ? "Yes" : "No"}
                            </Box>
                        </Grid>
                    </Grid> */}

          {details?.validThroughStartDate && details?.validThroughEndDate && (
            <>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={3}
                className="header-blue-22 mt-20"
              >
                <span className="location-name text-wrap">Valid Through</span>
              </Grid>
              <Grid container className="location-details-row">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  mt={2}
                  className="location-details-col"
                >
                  <Box className="location-details-label">START DATE</Box>
                  <Box className="location-details-value">
                    {details?.validThroughStartDate}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  mt={2}
                  className="location-details-col"
                >
                  <Box className="location-details-label">END DATE</Box>
                  <Box className="location-details-value">
                    {details?.validThroughEndDate}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          {/* <Grid container className="location-details-row">
                        {details?.validThrough === 'After Activation' ? (
                            <Grid item xs={12} sm={12} mt={2} className="location-details-col">
                                <Box className="location-details-label">EXPIRES IN</Box>
                                <Box className="location-details-value">
                                {day} {duration+'s'} {details?.validThrough}
                                </Box>
                            </Grid>
                        ) : (
                            details?.validThrough === 'Never Expired' ? (
                                <Grid item xs={12} sm={12} mt={2} className="location-details-col">
                                    <Box className="location-details-label">EXPIRES ON</Box>
                                    <Box className="location-details-value">
                                        {details?.validThrough}
                                    </Box>
                                </Grid>
                            ) : (
                                details?.validThrough === 'Start Date and End Date' && (
                                    <>
                                        <Grid item xs={12} sm={12} mt={2} className="location-details-col">
                                            <Box className="location-details-label">START DATE</Box>
                                            <Box className="location-details-value">
                                                {details?.expiresOn?.startDate}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} mt={2} className="location-details-col">
                                            <Box className="location-details-label">END DATE</Box>
                                            <Box className="location-details-value">
                                                {details?.expiresOn?.endDate}
                                            </Box>
                                        </Grid>
                                    </>
                                )
                            )
                        )}
                    </Grid> */}
          {/* Scheduling Limitations */}

          {details?.schedulingLimitation?.setWeekDays && (
            <>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={3}
                className="header-blue-22 mt-20"
                container
              >
                <span className="location-name text-wrap">
                  Scheduling Limitations
                </span>
              </Grid>
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={1.5} className="location-details-col">
                  <Box className="location-details-label">Will Be used On</Box>
                </Grid>
                <Grid item xs={12} sm={4.5} className="location-details-col">
                  <Box className="location-details-value">
                    {details?.schedulingLimitation?.weekDays?.map(
                      (day, index, array) => (
                        <React.Fragment key={day}>
                          {day}
                          {index < array.length - 1 && ', '}
                        </React.Fragment>
                      )
                    )}
                  </Box>
                </Grid>

                {details?.startTime && details?.endTime && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={1.5}
                      className="location-details-col"
                    >
                      <Box className="location-details-label">At</Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4.5}
                      className="location-details-col"
                    >
                      <Box className="location-details-value">
                        {details?.startTime} to {details?.endTime}
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}

          {details?.startTime &&
            details?.endTime &&
            !details?.schedulingLimitation?.setWeekDays && (
              <>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  mt={3}
                  className="header-blue-22 mt-20"
                  container
                >
                  <span className="location-name text-wrap">
                    Scheduling Limitations
                  </span>
                </Grid>
                <Grid container mt={3} className="location-details-row">
                  <Grid item xs={12} sm={1.5} className="location-details-col">
                    <Box className="location-details-label">
                      Will Be used On
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4.5} className="location-details-col">
                    <Box className="location-details-value">
                      <Box className="location-details-value">
                        {details?.startTime} to {details?.endTime}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}

          {/* Credit Validity */}
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            mt={3}
            className="header-blue-22 mt-20"
            container
          >
            <span className="location-name text-wrap">Credit Validity</span>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">Starts</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {details?.creditsValidityStarts &&
                  details?.creditsValidityStarts}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="location-details-col">
              <Box className="location-details-label">Expires In</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="location-details-col">
              <Box className="location-details-value">
                {details?.creditsValidityExpires &&
                  details?.creditsValidityExpires?.duration &&
                  details?.creditsValidityExpires?.duration}{' '}
                {details?.creditsValidityExpires &&
                  details?.creditsValidityExpires?.unit &&
                  details?.creditsValidityExpires?.unit}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </PricingOptionDetails>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactviate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMessageDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('message-close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Congratulations!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected pricing option has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('message-close')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PricingOptionsDetails;
