import {
  Box,
  Card,
  Typography,
  Container,
  // Divider,
  Button,
  // FormControl,
  // OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import '../../style.css';
import Cookies from 'universal-cookie';
import { useEffect, useState, useContext } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import Environment from '../../../../environment';
import Loader from '../../../../components/Loader';
import GlobalData from '../../../../global';
import { setLocalData } from '../../../../utils';
import { UserContext } from '../../../../contexts/UserContext';
import { getLocationList } from 'src/services/getUserLocations';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Login() {

  // Get the title from global data
  const title = GlobalData[0].title;

  const navigate = useNavigate();
  const cookies = new Cookies();
  // const domain = Environment[0].apiAuthEndPoint;
  const [givePass, givePassword] = useState(false);
  const [checkedError] = useState(false)
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const { setUserData } = useContext(UserContext);
  const paramKey = sessionStorage.getItem('paramKey')

  useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      navigate('/home', { replace: true });
    }
  }, []);
  useEffect(() => {
    // check cookie from cookies
    // console.log(cookies.get('access_token'));
    let refreshToken = cookies.get('refresh_token');
    if (refreshToken) checkRefreshToken(refreshToken);

  }, []);

  const fetchdata = () => {
    // Now show the loader
    setIsLoading(true);

    fetch(
      `${process.env.REACT_APP_API_AUTH_END_POINT}api/auth/login`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": " *",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          // { "username": "harshit.khatri@ignatiuz.com", "password": "Ign@123123" }
          { username: email.toLowerCase(), password: password }
        ),
      }
    ).then((response) => response.json())
      .then( async (response) => {
        if (response.success) {

          let uname = response.userDetails.name;
          let userDetails = response.userDetails;
          let refreshToken = response.refreshToken;
          let accessToken = response.accessToken;
          let idToken = response.idToken;
          let userId = userDetails['custom:userId'] || userDetails.sub;
          let expires = new Date();
          expires.setTime(expires.getTime() + (288 * 60 * 60 * 1000)); // 288hours
          console.log(expires, expires.toGMTString());
          // document.cookie = cookieName + " = " + cookieValue + "; expires = " + expires.toGMTString();
          // Assign value to a key
          sessionStorage.setItem("Login_Sucess", 'true');
          sessionStorage.setItem("UserName", uname);
          sessionStorage.setItem("UserId", userId);
          sessionStorage.setItem("refreshToken", refreshToken);
          sessionStorage.setItem("accessToken", accessToken);
          sessionStorage.setItem("idToken", idToken);
          sessionStorage.setItem("UserDetails", JSON.stringify(userDetails));

          let newUserRole = userDetails && userDetails['custom:isFranchisor'] && userDetails['custom:isFranchisor'] === 'true' && userDetails['custom:role'] === 'Manager' ? `Super ${userDetails['custom:role']}` : userDetails['custom:role'];

          // setting up the user details and roles in local storage 
          setLocalData("UserDetails", JSON.stringify(userDetails));
          setLocalData("userRole", newUserRole);
          // setLocalData("userRole", userDetails['custom:role']); 
          setLocalData("isFranchisor", userDetails['custom:isFranchisor']);
          setLocalData("isPrimaryOwner", userDetails['custom:isPrimaryOwner']);
          setLocalData("userId", userId);
          setLocalData("userEmail", userDetails['email']);
          setLocalData("franchiseeId", userDetails['custom:franchiseeId']);
          setLocalData("locationId",userDetails['custom:locationId']);

          // saving user data in context API
          setUserData({
            UserDetails: userDetails,
            franchiseeId: userDetails['custom:franchiseeId'],
            isFranchisor: userDetails && userDetails['custom:isFranchisor'] ? userDetails['custom:isFranchisor'] : false,
            isPrimaryOwner: userDetails && userDetails['custom:isPrimaryOwner'] ? userDetails['custom:isPrimaryOwner'] : false,
            userRole: newUserRole,
            locationId:userDetails['custom:locationId'], 
            // userRole: userDetails['custom:role'],
            refreshToken: refreshToken,
            accessToken: accessToken
          });

          // check if remember me is checked then set cookies
          if (rememberMe) {
            cookies.set('access_token', accessToken, { path: '/', expires });
            cookies.set('refresh_token', refreshToken, { path: '/', expires });
          }
          let data = {
            userId: userId,
            franchiseeId: userDetails['custom:franchiseeId'],
            role: newUserRole,
            isFranchisor: userDetails && userDetails['custom:isFranchisor'] ? userDetails['custom:isFranchisor'] : false
          };
          await getLocationList(data);
          // Now hide the loader
          setIsLoading(false);
          if(paramKey && paramKey !== 'undefined'){
            navigate(`/sales-history/${paramKey}`, { replace: true });
          }else{
            navigate("/home", { replace: true });
          }
        } else {
          let newPassCheck = response.newPasswordRequired;
          // Assign value to a key and store in local storage
          localStorage.setItem("email", email.toLowerCase());
          // Now hide the loader
          setIsLoading(false);

          // Check if new password is needed to be updated
          if (newPassCheck) {
            navigate("/update-password", { replace: true });
          } else {
            setPasswordError("The username or password you entered is incorrect");
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        let errmsg = error.message;
        // set error message
        setPasswordError(errmsg);
        // Now hide the loader
        setIsLoading(false);
      });
  };

  // Method to check if refresh token is available
  const checkRefreshToken = (refreshToken) => {
    // Now show the loader
    setIsLoading(true);

    let token = refreshToken;
    let body = JSON.stringify({ "refreshToken": token });

    if (token)
      fetch(
        `${process.env.REACT_APP_API_AUTH_END_POINT}api/auth/refreshToken`,
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": " *",
            "Content-Type": "application/json",
          },
          body: body,
        }
      ).then((response) => response.json())
        .then((response) => {
          if (response.success) {

            let uname = response.userDetails.name;
            let userId = response.userDetails.sub;
            let userDetails = response.userDetails;
            let refreshToken = response.refreshToken;
            let accessToken = response.accessToken;
            let idToken = response.idToken;

            // Assign value to a key
            sessionStorage.setItem("Login_Sucess", 'true');
            sessionStorage.setItem("UserName", uname);
            sessionStorage.setItem("UserId", userId);
            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem("accessToken", accessToken);
            sessionStorage.setItem("idToken", idToken);
            sessionStorage.setItem("UserDetails", JSON.stringify(userDetails));
            // Now hide the loader
            setIsLoading(false);

            navigate("/home", { replace: true });
          } else {
            // Now hide the loader
            setIsLoading(false);
          }
        }).catch((error) => {
          console.error('Error:', error);
          let errmsg = error.message;
          // set error message
          setPasswordError(errmsg);
          // Now hide the loader
          setIsLoading(false);
        });
  }

  const passShow = () => {
    if (givePass) {
      givePassword(false);
    } else {
      givePassword(true);
    }
  };

  // handle remember me checkbox change
  const handleRememberMeChange = (event) => {
    const { checked } = event.target;

    // If checked is true, then set the value of rememberMe to true
    if (checked) {
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value.trim();
    const emailRex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!email) {
      return setEmailError("Please enter your email");
      // return false;
    }
    if (!emailRex.test(email)) {
      return setEmailError("Please enter a valid email");
      // return false;
    }

    setEmailError("");

    // Password validation
    if (!password) {
      return setPasswordError("Please enter your password");
      // return false;
    }
    if (password.length < 8) {
      return setPasswordError("Password must be at least 8 characters");
      // return false;
    }
    fetchdata();
    setPasswordError("");

    // } else {
    //   fetchdata();
    //   setPasswordError("");
    // }

    return true;
  }
  return (
    <>
      <Loader IsLoading={isLoading} />
      <Helmet>
        <title>Login | {title}</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="xxl" sx={{ padding: '0px !important' }}>
          <div className='main'>
            <div className='parent-container-main'>
              <div className='logo-text'>
                <div className="zoom-room-logo">
                  <img src={'/static/images/logo/zrlogo.png'} style={{ maxWidth: '100%' }} className="logo-image" alt='zoom-room-logo' />
                </div>
                <div className="hero-text">
                  <Typography className='hero-message'>WE DON'T TRAIN DOGS.</Typography>
                  <Typography className='hero-message'>WE TRAIN THE PEOPLE WHO LOVE THEM.</Typography>
                </div>
              </div>
              <div className='form-section'>
                <div className='zoom-room-line'>
                  <div className='horizontal-line' />
                </div>
                <div className='form1'>
                  <Card className="form-area">
                    <Container component="main" maxWidth="xs" className="login-page" >
                      <Box
                        sx={{
                          marginTop: 0,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'

                        }}
                      >
                        <Typography className="title-text">
                          SIGN IN
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit}>
                          <TextField
                            margin="dense"
                            fullWidth
                            id="email"
                            placeholder='Email'
                            name='Email'
                            value={email}
                            error={Boolean(emailError)}
                            helperText={emailError}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                            label='EMAIL'
                            className="Email-field field-label-css"
                            // className='input-field'
                            // autoComplete="email"
                            // autoFocus
                            // type={giveemail ? 'text' : 'email'}
                            // style={{ borderRadius: '12px', padding: '5px' }}
                            InputProps={{
                              style: { borderRadius: '12px', padding: '19px 17px', fontSize: '16px', color: '#053288', height: '76px', boxSizing: 'border-box' }
                            }}
                          />
                          <TextField
                            margin="normal"
                            fullWidth
                            id="password"
                            name='Password'
                            placeholder='Password'
                            value={password}
                            type={givePass ? "text" : "password"}
                            error={Boolean(passwordError)}
                            helperText={passwordError}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="Password"
                            label='PASSWORD'
                            className="Pass-field field-label-css"
                            InputProps={{
                              endAdornment: (

                                <InputAdornment position="end">
                                  <IconButton onClick={passShow}>
                                    {givePass ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>

                              ),
                              // style: { borderRadius: '12px', padding: '5px', fontSize: '16px', color: '#525252' }
                              style: { borderRadius: '12px', padding: '20px 17px', fontSize: '16px', color: '#053288', height: '76px' }

                            }}

                          />
                          <FormControlLabel
                            control={<Checkbox value="remember" className='check-box-span' onChange={handleRememberMeChange} color="default" sx={{
                              '&.Mui-checked': {
                                color: '#003087'
                              },
                            }} />}

                            label={<Typography className='check-box-text'>Remember me</Typography>}
                          />
                          {checkedError && (
                            <Typography component="h1" variant="h5" style={{ fontSize: '14px', color: 'red', fontFamily: 'Myriad Pro', fontWeight: 'bold' }}>
                              {checkedError}
                            </Typography>
                          )}
                          {/* <Grid container  > */}
                          <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: '-48px' }}   >
                            <Grid item>
                              <Link to="/forgot-password" variant="body2" className='link-style forgot-pass'>
                                FORGOT PASSWORD?
                              </Link>
                              {/* </Grid> */}

                            </Grid>
                          </Grid>
                          {/* </Grid> */}


                          <Button

                            type="submit"
                            fullWidth
                            variant="contained"
                            className='button-style'
                          >
                            <Typography className='button-text'>
                              LOG IN
                            </Typography>
                          </Button>

                        </Box>
                      </Box>
                      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                    </Container>

                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </MainContent>
    </>
  );
}

export default Login;
