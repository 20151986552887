import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack, Autocomplete, TextField } from '@mui/material';
import { DynamicButton } from '../../../../components/Checkout';
import cancelRed from '../../../../assets/Images/cancelRed.svg';
import { DynamicTextField } from '../../../../components/GlobalComponents';
import { getLocalData } from '../../../../utils';
import { ApiService } from '../../../../services/ApiService';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { formatPhoneNumber } from 'src/utils/index';

const useStyles = makeStyles({
  option: {
    '&:hover': {
      backgroundColor: '#00308733 !important',
      color: '#fff'
    }
  }
});
export const SelectClient = ({
  handleNext,
  //   setSuccess,
  setSelected,
  selected
}) => {
  const styles = useStyles();
  const navigate = useNavigate()

  const locationId = getLocalData('locationId');
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const API_BASE_URL = `${process.env.REACT_APP_API_END_POINT}`;

  const [clientList, setClientList] = useState([]);
  const [petList, setPetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientLoading, setClientLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)
  const [fieldValidation, setFieldValidation] = useState([
    {
      nameError: '',
      phoneError: '',
      emailError: '',
    }
  ]);

  const [guestCheckout, setGuestCheckout] = useState({
    status: false,
    email: '',
    contact: '',
    name: ''
  });

  // REFRESH WARNING IF UNSAVED DATA 
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (selected.client) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [selected.client]);

// API TO GET THE LIST OF CLIENTS

  const getClientList = async (searchText) => {
    setClientLoading(true)
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/getClientsBySearch?locationId=${encodedLocationId}&searchText=${searchText}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        setClientList(Item);
      } else {
        setClientList([]);
      }
      setClientLoading(false)
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
      setClientLoading(false)
    }
  };

// API TO GET THE LIST OF PETS

  const getPetList = async (clientId) => {
    setLoading(true)
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedClientId = encodeURIComponent(clientId)
    try {
      let response = await ApiService.get(
        `${API_BASE_URL}client/getClientDetails?locationId=${encodedLocationId}&clientId=${encodedClientId}`
      );
      console.log(response, "new value consoled")
      let Item =
        response && response.data && response.data.Items[0]
          ? response.data.Items[0]?.pets
          : [];
      if (response?.statusCode === 200) {
        setPetList(Item);
      } else {
        setPetList([]);
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };
 
  useEffect(() => {
    // Define the event handler function
    const handleBeforeUnload = (event) => {
      if (guestCheckout.status) {
        event.preventDefault();
        event.returnValue = 'Changes you made may not be saved';
      }
    };
    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [guestCheckout.status]);
 
  const handleSelectedClient = (client) => {
    setSelected({ ...selected, client: client });
  };
  const removeClient = () => {
    setSelected({ ...selected, client: null, pet: null });
    setSearchValue("")
  };

  const handleChange = (name, value) => {
    // setGuestCheckout({ ...guestCheckout, [name]: value });

    if (name === 'name') {
      if (value) {
        // if (!/^[A-Za-z\s]+$/.test(value)) {
        if (!/^[a-z]/gi.test(value)) {
          const allErr = { ...fieldValidation };
          allErr['nameError'] = 'Please enter a valid name.';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['nameError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['nameError'] = 'Name is required.';
        setFieldValidation(allErr);
      }
      setGuestCheckout({ ...guestCheckout, [name]: value });
    } else if (name === 'email') {
      if (value) {
        if (!/\S+@\S+\.\S+/.test(value)) {
          const allErr = { ...fieldValidation };
          allErr['emailError'] = 'Please enter a valid email address.';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['emailError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['emailError'] = 'Email is required.';
        setFieldValidation(allErr);
      }
      setGuestCheckout({ ...guestCheckout, [name]: value });
    } else if (name === 'contact') {
      let formatWorkPhoneNumber = formatPhoneNumber(value);
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      if (value) {
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          const allErr = { ...fieldValidation };
          allErr['phoneError'] =
            'Please enter a valid phone number in format: xxx-xxx-xxxx';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['phoneError'] = '';
          setFieldValidation(allErr);
        }
      }else {
        const allErr = { ...fieldValidation };
        allErr['phoneError'] = '';
        setFieldValidation(allErr);
      }
      setGuestCheckout({ ...guestCheckout, [name]: formatWorkPhoneNumber });}
  };

  const handleInputChange = (event, newValue) => {
    handleSelectedClient(newValue);
    getPetList(newValue.clientId,newValue.sortKey)
    setOpen(false)
    setSearchValue("")
  };

  const handlePetChange = (event, newValue) => {
    handleSelectedPet(newValue);
    getPetList(newValue.clientId)
  };

  const handleSelectedPet = (pet) => {
    setSelected({ ...selected, pet: pet});
  };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleSearchClient = (e) => {
    let value = ""
    value = e.target.value
    setSearchValue(value)
    if(value.length){
      getClientList(value)
    }else setClientList([])
  }

  useEffect(()=> {
    if(searchValue === ''){
      setOpen(false)
    }else{
      
      setOpen(true)
    }
  },[searchValue])
  
  console.log(window, 'windowloction');
  console.log(open, "openopenopenopen")
  useEffect(() => {
    // Update the active step value in local storage
    localStorage.setItem('activeStep', '0');
  }, []);

// Component UI 
  return (
    <>
      <Loader IsLoading={loading} />
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: '16px',
          marginLeft: '0px',
          width: '100%',
          justifyContent: 'center'
        }}
      >
        <Box className="clientSearch searchBar">
          <Autocomplete
            style={{
              width: '400px'
            }}
            classes={{
              option: styles.option
            }}
            open={open}
            // clearOnBlur={false}
            disabled={selected.client || guestCheckout.status}
            value={selected?.client}
            onChange={handleInputChange}
            className="status-field field-label-css"
            noOptionsText={clientLoading ? 'Loading...' : "No client found"}
            options={clientList ?? []}
            getOptionLabel={(option) => option && `${option.firstName} ${option.lastName}`}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <SearchIcon />
                }}
                label={'Search Client *'}
                variant="outlined"
                autoComplete="off"
                value={searchValue}
                onChange={handleSearchClient}
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: '200px', // Set the desired height for the options card
              }
            }}
            renderOption={(props, item) => { 
              return <li {...props} key={item.clientId}>{item.firstName + ' ' + item.lastName}</li> 
              }}
          />
        </Box>
        <Box className="clientSearch searchBar clientItem">
          <Autocomplete
              style={{
                width: '400px'
              }}
              classes={{
                option: styles.option
              }}
              disabled={!selected.client || selected.pet || guestCheckout.status}
              value={selected.pet}
              onChange={handlePetChange}
              noOptionsText="No dogs found"
              className="status-field field-label-css"
              options={petList ?? []}
              getOptionLabel={(option) => option?.firstName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps
                  }}
                  label={'Select Dog *'}
                  variant="outlined"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: '200px', // Set the desired height for the options card
                }
              }}
            />
        </Box>
        {selected.client !== undefined && selected.client !== null && (
          <Box className="clientItem remove-client-button" sx={{ marginBottom: '10px' }}>
            <Box style={{width: "400px", borderRadius: "10px"}}>
              {selected?.client?.firstName} {selected?.client?.lastName} 
              {selected?.pet?.firstName ? ` - (${selected?.pet?.firstName})` : ""}
            </Box>
            <DynamicButton
              onClick={() => removeClient(selected.client)}
              variant={'contained'}
              src={cancelRed}
              type={'cancel'}
            />
          </Box>
          
        )}

        {guestCheckout?.status && (
          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={12} sm={6}>
              <DynamicTextField
                className="input-field-styling required-field"
                type="text"
                name="name"
                label="NAME"
                required
                error={guestCheckout?.name === ''}
                helperText={ fieldValidation?.nameError}
                fullWidth
                value={guestCheckout?.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DynamicTextField
                className="input-field-styling required-field"
                type="text"
                name="email"
                label="EMAIL"
                required
                helperText={fieldValidation?.emailError}
                fullWidth
                value={guestCheckout?.email}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DynamicTextField
                className="input-field-styling required-field"
                type="text"
                name="contact"
                label="PHONE NUMBER"
                required={false}
                fullWidth
                helperText={guestCheckout?.contact && fieldValidation?.phoneError}
                value={guestCheckout?.contact}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )}
        {
          selected?.client !== null &&
          selected?.client !== undefined
          ? (
            <Box
              style={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              
              <DynamicButton
                title={'Go Back'}
                variant="contained"
                onClick={() => {
                  window.history.back();
                }}
                style={{
                  padding: '10px 23px',
                  borderRadius: '10px',
                  textTransform: 'capitalize',
                  maxWidth: 'max-content',
                  fontWeight: '600',
                  fontSize: '16px',
                  marginRight: '10px',
                  background: '#fff',
                  color: '#003087',
                  border: '2px solid #003087'
                }}
              />

              <DynamicButton
                onClick={handleNext}
                variant={'contained'}
                disabled={
                  !selected?.pet ||
                  selected?.pet === null ||
                  selected?.pet === undefined
                }
                title={'Add Items'}
                style={{
                  padding: '11px 23px',
                  borderRadius: '10px',
                  textTransform: 'capitalize',
                  maxWidth: 'max-content',
                  fontWeight: '600',
                  fontSize: '16px'
                }}
              />
            </Box>
        ) : guestCheckout.status ? (
          <Box
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: '0'
            }}
          >
            <DynamicButton
              title={'Go Back'}
              variant="contained"
              onClick={() => {
                if (guestCheckout.status) {
                  setGuestCheckout({ ...guestCheckout, status: false });
                } else {
                  window.history.back();
                }
              }}
              style={{
                padding: '10px 23px',
                borderRadius: '10px',
                textTransform: 'capitalize',
                maxWidth: 'max-content',
                fontWeight: '600',
                fontSize: '16px',
                marginRight: '10px',
                background: '#fff',
                color: '#003087',
                border: '2px solid #003087'
              }}
            />
            <DynamicButton
              title={'Save & Proceed'}
              variant="contained"
              onClick={(e) => {
                handleNext(e);
                setSelected({
                  ...selected,
                  client: {
                    firstName: guestCheckout.name,
                    lastName: '',
                    email: guestCheckout.email,
                    phone: guestCheckout.contact
                  }
                });
              }}
              disabled={
                guestCheckout.name === '' ||
                guestCheckout.email === '' ||
                fieldValidation?.emailError ||
                fieldValidation?.nameError ||
                fieldValidation?.phoneError
              }
              style={{
                padding: '10px 23px',
                borderRadius: '10px',
                textTransform: 'capitalize',
                maxWidth: 'max-content',
                fontWeight: '600',
                fontSize: '16px',
                border: '2px solid #003087'
              }}
            />
          </Box>
        ) : (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            bottom="80px"
            right="0"
            left={'0'}
          >
            <DynamicButton
              onClick={() => navigate('/client-management')}
              variant={'contained'}
              disabled={selected.client}
              title={'Add New Client'}
              style={{
                padding: '11px 23px',
                borderRadius: '10px',
                textTransform: 'capitalize',
                maxWidth: 'max-content',
                fontWeight: '600',
                fontSize: '16px',
                margin: '0 auto 24px',
                backgroundColor: '#003087', 
                color: '#ffffff',
              }}
            />
            <DynamicButton
              onClick={() =>
                setGuestCheckout({ ...guestCheckout, status: true })
              }
              variant={'text'}
              title={'Check Out As Guest'}
              style={{
                padding: '0',
                maxWidth: 'max-content',
                background: 'transparent',
                textDecoration: 'underline',
                fontWeight: '600',
                fontSize: '15px'
              }}
              
            />
          </Stack>
        )}
      </Grid>
    </>
  );
};