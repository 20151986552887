/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './style.css';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import Header from 'src/components/Header';
import StaffTimeOffCalender from './StaffTimeOffCalender';
import StaffCalenderMenu from './StaffCalenderMenu';
// import { getLocalData } from "src/utils";
import { ApiService } from '../../../services/ApiService';
import {
  getLocalData,
  dateFormat,
  convertFrom12To24Format
} from '../../../utils';
import { DateTime } from 'luxon';
import { AddTimeOffModal } from './AddTimeOffModal';
import Loader from '../../../components/Loader';

export default function StaffTimeOffMain(props) {
  const [modalOpen, setModalOpen] = useState(false);

  // let locationId = 'LOC#54123';
  const locationId = getLocalData('locationId');

  const [trainerData, setTrainerData] = useState([]);
  const [schedulerData, setSchedulerData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [startHour, setStartHour] = useState('00:00');
  const [endHour, setEndHour] = useState('24:00');

  // States for populating data in modal
  const [fromTime, setfromTime] = useState('');
  const [endTime, setendTime] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [selectedTrainerData, setselectedTrainerData] = useState('');

  useEffect(() => {
    getAllTrainers();
  }, []);
  useEffect(() => {
    if (trainerData) {
      getAllStaffTimeOff(trainerData);
    }
  }, [currentDate]);

  const getAllTrainers = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAllTrainers?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      console.log(Item, 'Item');
      let uniqueTrainers = Item?.map((obj, index) => ({
        eventClass: index === 0 ? 'borderLeft' : 'borderLeftAgain',
        color: index !== 1 ? '#D13A0033' : '#32B2AC33',
        designation: obj.designationName,
        trainerId: obj.sortKey,
        trainerName: `${obj.firstName || ''} ${obj.lastName || ''}`,
        trainerProfileImage: obj.profileImage,
        trainerAvailability: obj.availability,
        ...obj
      }));
      setTrainerData(uniqueTrainers);
    } catch (e) {
      setIsLoading(false);
    }
  };
  const getAllStaffTimeOff = async (uniqueTrainers) => {
    console.log(uniqueTrainers, 'uniqueTrainersuniqueTrainersuniqueTrainers');
    setIsLoading(true);
    // let payload = {
    //   locationId: locationId,
    //   fromDate: dateFormat(currentDate),
    // };
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getLocationOperatingHours?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      // const modifiedItem = [];
      setOperatinghours(Item[0]?.operatingHours);
      // console.log(modifiedItem, "modifiedItem");
      // setSchedulerData(modifiedItem);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  const compareDate = new Date(currentDate);
  function convertUTCToUserTime(utcTime) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDateTime = DateTime.fromFormat(utcTime, 'h:mm a', { zone: 'UTC' });
    const userTime = utcDateTime.setZone(timeZone);
    return userTime.toFormat('hh:mm a');
  }
  const setOperatinghours = (operatHrsData) => {

    try {
      let startTime = '00:00';
      let endTime = '24:00';
      const earliestStartTime = findEarliestStartTime(operatHrsData);
      const latestEndTime = findLatestEndTime(operatHrsData);
      if (earliestStartTime) startTime = convertFrom12To24Format(earliestStartTime);
      if (latestEndTime) endTime = convertFrom12To24Format(latestEndTime);

      setStartHour(startTime);
      setEndHour(endTime);
    } catch (error) {
      console.log(error);
    }
  }
  const findEarliestStartTime = (operatHrsData) => {
    if (!operatHrsData || operatHrsData.length === 0) {
      return '12:00 AM';
    }

    let earliestStartTime = '12:00 PM';

    operatHrsData.forEach((item) => {
      const startTime = convertUTCToUserTime(item.startTime);
      if (compareTimes(startTime, earliestStartTime) < 0) {
        earliestStartTime = startTime;
      }
    });

    return earliestStartTime;
  };

  const findLatestEndTime = (operatHrsData) => {
    if (!operatHrsData || operatHrsData.length === 0) {
      return '11:59 PM';
    }

    let latestEndTime = '12:00 AM';

    operatHrsData.forEach((item) => {
      const endTime = convertUTCToUserTime(item.endTime);
      if (compareTimes(endTime, latestEndTime) > 0) {
        latestEndTime = endTime;
      }
    });

    return latestEndTime;
  };
  const compareTimes = (time1, time2) => {
    const date1 = new Date(`2023-01-01T${convertFrom12To24Format(time1)}`);
    const date2 = new Date(`2023-01-01T${convertFrom12To24Format(time2)}`);
    return date1 - date2;
  };

  const handleNextClick = () => {
    const convertedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );
    setCurrentDate(convertedDate);
  };

  const handlePreviousClick = () => {
    const convertedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 1
    );
    setCurrentDate(convertedDate);
  };

  const handleDateChange = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const convertedDate = new Date(year, month, day);
    setCurrentDate(convertedDate);
  };
  const convertTimeAndDate = (timeString, dateString) => {
    const dateTimeString = `${dateString} ${timeString}`;
    const dateTime = moment(dateTimeString, 'MM-DD-YYYY hh:mm A');
    const utcDate = dateTime.toDate();
    return utcDate.toISOString();
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Container maxWidth="100%" className="main-container">
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className="add-btn-container"
        >
          <Header title=" Staff Time Off" />
        </Grid>
      </Container>
      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          width="100%"
          className="calenderToggle"
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            marginLeft={2.5}
            alignItems="center"
          >
            <StaffCalenderMenu
              handlePreviousClick={handlePreviousClick}
              handleNextClick={handleNextClick}
              handleDateChange={handleDateChange}
              currentDate={(dateFormat(currentDate))}
            />
          </Box>
        </Grid>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item width="100%" marginLeft={2.5}>
              <StaffTimeOffCalender
                currentDate={currentDate}
                trainerData={trainerData}
                data={schedulerData || []}
                handleOpenModal={() => setModalOpen(true)}
                startHour={startHour}
                endHour={endHour}
                setendTime={setendTime}
                setfromTime={setfromTime}
                setendDate={setendDate}
                setFromDate={setFromDate}
                setselectedTrainerData={setselectedTrainerData}
              />
            </Grid>
          </>
        )}
      </Grid>
      <AddTimeOffModal
        getAllTrainers={getAllTrainers}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        trainers={trainerData}
        endTime={endTime}
        fromTime={fromTime}
        fromDate={fromDate}
        endDate={endDate}
        selectedTrainerData={selectedTrainerData}
        setendTime={setendTime}
        setfromTime={setfromTime}
        setendDate={setendDate}
        setFromDate={setFromDate}
        setselectedTrainerData={setselectedTrainerData}
      />
    </>
  );
}
