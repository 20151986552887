// THIS IS THE FILTER COMPONENT FOR THE PRODUCT MANAGEMENT PAGE
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
  Card,
  Grid,
  Paper,
  Autocomplete,
  Divider,
  Box
  // Checkbox,
  // FormControlLabel
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import MenuItem from '@mui/material/MenuItem';

// Import Styles from Material UI
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const Filter = ({ setFilters, filters, categoryList, supplierList }) => {
  // console.log(categoryList, 'categoryList------------------------');
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        // color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const classes = useStyles();
  const autoCompleteClasses = useAutocompleteStyles();

  // const [openBestSeller, setOpenBestSeller] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [openStock, setOpenStock] = useState(false);

  const handleActiveChange = (value) => {
    // setFilters((prevState) => ({ ...prevState, isDiscontinued: value }));
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const handleCategoryChange = (value) => {
    setFilters((prevState) => ({ ...prevState, categoryName: value }));
  };

  const handleSupplierChange = (value, event) => {
    console.log(event, 'valuevalue');
    setFilters((prevState) => ({ ...prevState, supplierName: value }));
  };

  const handleStockChange = (value) => {
    setFilters((prevState) => ({ ...prevState, stock: value }));
  };

  // const handleBestSellerChange = (event) => {
  //   let { checked } = event.target;
  //   if (!checked) {
  //     checked = null;
  //   }
  //   setFilters((prevState) => ({ ...prevState, isBestSeller: checked }));
  // };

  const handleCategoryClick = () => {
    setOpenCategory(!openCategory);
    // setOpenBestSeller(false);
    setOpenSupplier(false);
    setOpenStock(false);
  };

  const handleSupplierClick = () => {
    setOpenSupplier(!openSupplier);
    // setOpenBestSeller(false);
    setOpenCategory(false);
    setOpenStock(false);
  };

  const handleStockClick = () => {
    setOpenStock(!openStock);
    setOpenCategory(false);
    // setOpenBestSeller(false);
    setOpenSupplier(false);
  };

  // const handleBestSeller = () => {
  //   setOpenBestSeller(!openBestSeller);
  //   setOpenCategory(false);
  //   setOpenSupplier(false);
  // };

  const handleClearFilter = () => {
    // setStartDate(null);
    // setEndDate(null);
    setFilters({
      // isDiscontinued:  filters.isDiscontinued,
      status: filters.status,
      categoryName: null,
      isBestSeller: null,
      supplier: null,
      stock: null
    });
    setOpenStock(false);
    setOpenCategory(false);
    // setOpenBestSeller(false);
    setOpenSupplier(false);
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Status</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) => handleActiveChange(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value="All">All</MenuItem>
            {/* <MenuItem value={Boolean(false)}>Active</MenuItem>
            <MenuItem value={Boolean(true)}>Inactive</MenuItem> */}
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={handleClearFilter}>
              <Typography
                className="filter-text cursor-pointer"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'cursor-pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* CATEGORY */}
        <Grid onClick={handleCategoryClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openCategory && classes.closeY,
                    openCategory && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Category</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openCategory === true && (
          <Box marginTop={1} sx={{ background: 'white' }}>
            <Autocomplete
              classes={autoCompleteClasses}
              // value={getCity || ''}
              value={filters.categoryName ?? ''}
              onChange={(event, newValue) => {
                handleCategoryChange(newValue);
              }}
              // onInputChange={(event, newInputValue) => {
              //   handleCategoryChange(newInputValue);
              // }}
              className="status-field field-label-css"
              options={categoryList.map((option) => option.categoryName)}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="primary-color"
                  label="Search Category"
                />
              )}
            />
          </Box>
        )}

        {/* Supplier */}
        <Grid onClick={handleSupplierClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openSupplier && classes.closeY,
                    openSupplier && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Supplier</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openSupplier === true && (
          <Box marginTop={1} sx={{ background: 'white' }}>
            <Autocomplete
              classes={autoCompleteClasses}
              // value={getCity || ''}
              value={filters.supplier ?? ''}
              onChange={(event, newValue) => {
                handleSupplierChange(newValue, event);
              }}
              // onInputChange={(event, newInputValue) => {
              //   handleCategoryChange(newInputValue);
              // }}
              className="status-field field-label-css"
              options={supplierList.map((option) => option.businessName)}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="primary-color"
                  label="Search Supplier"
                />
              )}
            />
          </Box>
        )}

        {/* BEST SELLER==> */}
        {/* <Grid xs={12} onClick={handleBestSeller} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openBestSeller && classes.closeY,
                    openBestSeller && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>BestSeller</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openBestSeller && (
          <Box marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.isBestSeller ?? ''}
                  className="check-box-span"
                  onChange={handleBestSellerChange}
                  color="default"
                  sx={{
                    '&.Mui-checked': {
                      color: '#003087'
                    }
                  }}
                />
              }
              label={
                <Typography className="check-box-text">Best Seller</Typography>
              }
            />
          </Box>
        )} */}

        <Grid
          item
          onClick={() => handleStockClick()}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openStock && classes.closeY,
                    openStock && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Stock</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openStock && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Stock</InputLabel>
              <Select
                onChange={(event) => handleStockChange(event.target.value)}
                value={filters.stock}
                sx={{
                  width: '100%'
                }}
                label="Select Level"
                className="status-field field-label-css"
              >
                <MenuItem value="All">Show All</MenuItem>
                <MenuItem value="inStock">In Stock</MenuItem>
                <MenuItem value="outOfStock">Out Of Stock</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      </Card>
    </>
  );
};

export default Filter;
