/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Avatar
} from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DeleteIcon } from 'src/assets/SVG/SvgIcons'; // ArrowLeft
import { ArrowLeft, PictureSnapshot } from 'src/assets/SVG/SvgIcons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// Trophy page style wrapper
const TrophyDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

export const JourneyDetails = (props) => {
  const { trophyDetails, handleResetStates, handleOpenScreen } = props;

  // const trophyId = trophyDetails?.sortKey && encodeURIComponent(trophyDetails.sortKey);
  const trophyId = trophyDetails?.sortKey && trophyDetails.sortKey;
  const [btnLoading, setBtnLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  // Get the loggedIn user Id
  const userId = sessionStorage.getItem('UserId');

  const handleDeleteDialog = (type) => {
    if (type === 'cancel') {
      setDeleteDialog(false);
    } else {
      deleteTrophy();
    }
  };

  // Delete trophy
  const deleteTrophy = async () => {
    let body = {
      trophyId: trophyId,
      userId: userId
    };
    setBtnLoading(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_END_POINT}/trophy/deleteTrophy`,
        { data: body }
      );
      console.log('delete response', response.data);
      if (!response.data?.error) {
        toast.success(response.data?.message);
        handleResetStates();
      } else {
        toast.error(
          response.data?.message || 'Something went wrong with delete Service'
        );
      }

      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      toast.error('Something went wrong, Try again!');
    }
  };

  return (
    <div className="custom-summary">
      <TrophyDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleResetStates()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {trophyDetails?.title}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button
              disabled={btnLoading}
              className="details-remove-icon no-hover"
              onClick={() => setDeleteDialog(true)}
            >
              <DeleteIcon />
            </Button>

            <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              onClick={() => handleOpenScreen(2)}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Box className="location-details-container">
          <Grid container mt={3} >
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">Title</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {trophyDetails?.title}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">Junior Trophy</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {trophyDetails?.juniorTrophy ? 'Yes' : 'No'}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                Assignment Based
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value">
                {trophyDetails?.assignmentOnly ? 'Yes' : 'No'}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                Description
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value">
                {trophyDetails?.description}
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                Trophy IMAGE
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value">
                {trophyDetails?.trophyImage}
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {trophyDetails.trophyImage ? (
                  <Avatar
                    src={trophyDetails?.trophyImage}
                  />
                ) : (
                  // <PictureSnapshot />
                  <Avatar
                    src={''}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                STATUS
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value">
                {trophyDetails?.status === 1 && 'Active'}
                {trophyDetails?.status === 0 && 'Inactive'}
                {trophyDetails?.status === 2 && 'Deleted'}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TrophyDetailsWrapper >

      <Dialog
        open={deleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDeleteDialog('cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Delete'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to delete this record?
                <Typography className="title-text-sub">
                  It will send the record to inactive state.
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleDeleteDialog('delete')}
          >
            Delete
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleDeleteDialog('cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};
