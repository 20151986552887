import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  TextField,
  Grid,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Divider,
  Slide,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  useTheme,
  InputAdornment
} from '@mui/material';
import { DeleteIcon, EyeIcon } from 'src/assets/SVG/SvgIcons.js';
import axios from 'axios';
import { CustomDatePicker } from 'src/components/CustomDatePicker.js';

// import { HeaderContext } from 'src/contexts/HeaderContext.js';
// import remove from '/static/images/logo/remove.png';
// import { addStaffUrl, getStatesUrl, getLocationsUrl, getDesignationsUrl } from '../../../Constants.js';
// import Alerts from '../../../customFields/Alerts.js';

// import DialogBox from '../../../customFields/DialogBox.js';Stack, CircularProgress,
import Loader from '../../../components/Loader';
import { formatPhoneNumber } from 'src/utils/index';
import { getLocalData } from '../../../utils';
import heic2any from 'heic2any';
import { ImageSlider } from 'src/components/Global';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%'
    }
  },
  addStaffField: {
    margin: '12px 5px'
  },
  addStaffRoleField: {
    margin: '0px'
  },
  notchedOutline: {
    borderColor: '#053288 !important',
    color: '#053288 !important',
    borderRadius: '12px'
  }
}));

const EditStaff = ({ getStaff, setShowScreen, staffItem, profileUserData, setIsEdit,setIsUserUpdate,isUserUpdate }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [multipleDocs, setMultipleDocs] = useState([]);
  const [staffId, setStaffId] = useState('');
  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      role: '',
      payRate: ''
    }
  ]);
  // const [roleIndex, setRoleIndex] = useState([]);
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Add Staff');
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  // const locationId = getLocalData('locationId');
  // const locationName = getLocalData('locationName');
  const franchiseeId = getLocalData('franchiseeId');
  // console.log(isFranchisor, isPrimaryOwner, franchiseeId);

  let getStatesUrl = `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`;
  let getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations?limit=1000`;
  let getDesignationsUrl = `${process.env.REACT_APP_API_END_POINT}staff/getAllStaffDesignation`;
  let getRolesUrl = `${process.env.REACT_APP_API_END_POINT}users/getFranchiseeUserRoles`;
  // let encodedfranchiseeId = encodeURIComponent(franchiseeId);
  let encodedfranchiseeId = encodeURIComponent(franchiseeId);
  // console.log(encodedfranchiseeId, 'encodedfranchiseeIdencodedfranchiseeIdencodedfranchiseeId')

  if (isFranchisor === 'false' && isPrimaryOwner === 'true' && franchiseeId) {
    getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedfranchiseeId}`;
  } else if (
    isFranchisor === 'false' &&
    isPrimaryOwner === 'false' &&
    franchiseeId
  ) {
    getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedfranchiseeId}`;
  }
  let userId = sessionStorage.getItem('UserId');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [fieldValidation, setFieldValidation] = useState([
    {
      firstNameError: '',
      lastNameError:'',
      phoneError: '',
      ePhoneError: '',
      addressError: '',
      stateError: '',
      zipcodeError: '',
      emailError: '',
      emergencyContactPerosnError: '',
      designationError: '',
      address2Error: '',
      cityError: '',
      locationError: ''
    }
  ]);

  // show error message
  const [showError, setShowError] = useState(false);

  // const [loader, setLoader] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openConfirmationPop, setOpenConfirmationPopUp] = useState(false);
  const [designationId, setDesignationId] = useState('');
  const [duplicateRoleError, setDuplicateRoleError] = useState('');
  const [allInputFields, setAllInputFields] = useState({
    locationId: '',
    firstName: '',
    lastName:'',
    email: '',
    phone: '',
    emergencyPhone: '',
    staffProfileImage: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    payRateType: 'Salaried',
    gender: '',
    emerengencyContactName: '',
    designationId: '',
    designationName: '',
    roles: [],
    rolesWithRate: [],
    startDate: '',
    profileImage: '',
    terminationDate: ''
    // isActive: true
  });


  // const [roleOptions] = useState([
  //   { label: 'Developer', value: 'Developer', isDisabled: false },
  //   { label: 'Tester', value: 'Tester', isDisabled: false },
  //   { label: 'HR', value: 'HR', isDisabled: false },
  //   { label: 'Manager', value: 'Manager', isDisabled: false }
  // ]);
  const [startDate, setStartDate] = useState('');
  const [startDateError, setStartDateError] = useState('');

  const [endDate, setEndDate] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [stateOptions, setStateOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [selectedLocationName, setSelectedLocationName] = useState('');
  const [multipleImage, setMultipleImage] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [imageError, setImageError] = useState('');
  const [errorHandler, setErrorHandler] = useState({
    errorType: '',
    errorMsg: '',
    showDialog: false
  });
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [payRateError, setDuplicatePayrateError] = useState('');
  const locationId = getLocalData('locationId');
  const [uploadDocsError, setUploadDocsError] = useState('');
  // const [open, setOpen] = React.useState(false);
  // const [openAddClient, setopenAddClient] = useState(false);
  // const [showRoleError, setShowRoleError] = useState(false);
  // const [roleErrorMessage, setRoleErrorMessage] = useState('');

  // Method to open the dialog
  useEffect(() => {
    getStaffProfile();
  }, [staffId]);
  const handleClickOpen = () => {
    setOpenPopup(true);
  };
  console.log(uploadDocsError)
  const getStaffProfile = () => {
    // setIsLoading(true);
    if (!staffId) return;
    let id = encodeURIComponent(staffId);
    let encodedLocationId = encodeURIComponent(locationId);

    let url = `${process.env.REACT_APP_API_END_POINT}staff/getStaffProfileDetails?staffId=${id}&locationId=${encodedLocationId}`;
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          let items = data.data.Items;
          if (items) {
            if (items[0] && items[0]?.attachments) {
              let uploadedAttachments = items[0]?.attachments;
              setMultipleDocs(
                [...multipleDocs, ...uploadedAttachments].slice(0, 5)
              );
              // setUploadedDocs(items[0]?.attachments);
            }
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);

        setIsLoading(false);
      });
  };

  // const addInputField = () => {
  //   setInputFields([
  //     ...inputFields,
  //     {
  //       role: '',
  //       payRate: ''
  //     }
  //   ]);
  // };

  // Method to remove the role list
  // const removeInputFields = (index) => {
  //   const rows = [...inputFields];

  //   rows.splice(index, 1);
  //   setInputFields(rows);

  //   // Now update the role options after removing the role
  //   if (rows.length > 0) {
  //     for (let k = 0; k < rows.length; k++) {
  //       if (!rows[rows.findIndex(x => x.role === "Developer")]) {
  //         roleOptions[0]['isDisabled'] = false;
  //       }
  //       if (!rows[rows.findIndex(x => x.role === "Tester")]) {
  //         roleOptions[1]['isDisabled'] = false;
  //       }
  //       if (!rows[rows.findIndex(x => x.role === "HR")]) {
  //         roleOptions[2]['isDisabled'] = false;
  //       }
  //       if (!rows[rows.findIndex(x => x.role === "Manager")]) {
  //         roleOptions[3]['isDisabled'] = false;
  //       }
  //     }
  //   }
  // };
  // const removeInputFields = (index) => {
  //   const rows = [...inputFields];
  //   rows.splice(index, 1);
  //   setInputFields(rows);

  //   // When removing a role input field, update the disabled status of role options
  //   const updatedRoleOptions = roleOptions.map((option) => {
  //     if (!rows.some((row) => row.role === option.role)) {
  //       return {
  //         ...option,
  //         isDisabled: false
  //       };
  //     }
  //     return option;
  //   });
  //   setRoleOptions(updatedRoleOptions);
  // };

  // Method to handle the change of roles
  // const handleChangeRoles = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [...inputFields];
  //   list[index][name] = value;
  //   const roleList = [...roleOptions];
  //   // console.log(roleList[roleList.findIndex(x => x.value === value)], "0000----");

  //   // Now update the role options after removing the role
  //   for (let j = 0; j < roleList.length; j++) {
  //     if (roleList[j]['value'] === value) {
  //       roleList[j]['isDisabled'] = true;

  //       if (list.length > 0) {
  //         for (let k = 0; k < list.length; k++) {

  //           if (!list[list.findIndex(x => x.role === "Developer")]) {
  //             roleList[0]['isDisabled'] = false;
  //           } else if (!list[list.findIndex(x => x.role === "Tester")]) {
  //             roleList[1]['isDisabled'] = false;
  //           } else if (!list[list.findIndex(x => x.role === "HR")]) {
  //             roleList[2]['isDisabled'] = false;
  //           } else if (!list[list.findIndex(x => x.role === "Manager")]) {
  //             roleList[3]['isDisabled'] = false;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   setInputFields(list);

  //   // to store in main object
  //   const lists = { ...allInputFields };
  //   lists['rolesWithRate'] = list;
  //   if (name === 'role') {
  //     const roles = { role: value };
  //     lists['roles'][index] = roles;
  //   }
  //   setAllInputFields(lists);
  //   setIsFieldUpdated(true);

  // };

  const addStaffDocs = async (formData) => {

    await fetch(
      `${process.env.REACT_APP_FEATURED_API_END_POINT}staff/uploadDocument`,
      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          // 'content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        },

        body: formData
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsUpdated(true);
        // setopenAddproduct(false);
        // setopenProductPopup(false);
        // setOpen(true);
        console.log(data, 'data');
        // clear();
      }
      );


  };

  const handleChangeRoles = (index, evnt,field) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    if (field === 'payrate') {
      let payVal = evnt.target.value
      payVal = payVal.replace(/[^0-9.]/g, '');
    
      const decimalIndex = payVal.indexOf('.');
      if (decimalIndex !== -1) {
        const parts = payVal.split('.');
        payVal = parts[0] + '.' + parts[1].slice(0, 2);
      }
      list[index][name] = payVal;
    } else {
      list[index][name] = value;
    }
    setInputFields(list);

    // Collect the selected roles in the input fields with non-empty payRate
    const selectedRolesWithPayRate = list.reduce((selectedRoles, row) => {
      if (row.role && row.payRate !== '') {
        selectedRoles.push(row.role);
      }
      return selectedRoles;
    }, []);

    // Update the disabled status of role options based on selected roles
    const updatedRoleOptions = roleOptions.map((option) => {
      const isOptionSelected = selectedRolesWithPayRate.includes(option.role);
      return {
        ...option,
        isDisabled: isOptionSelected
      };
    });

    setRoleOptions(updatedRoleOptions);

    // Check if the selected role already exists in other input fields
    if (name === 'role') {
      for (let i = 0; i < list.length; i++) {
        if (i !== index && list[i].role === value) {
          // If the selected role already exists in another input field,
          // reset the role for the current field to avoid duplicates.
          setDuplicateRoleError(`Role already exists in another input field.`);
          list[index].role = '';
          setInputFields(list);
          return;
        }
      }
      setDuplicateRoleError('');
    }

    // to store in the main object
    const lists = { ...allInputFields };
    lists['rolesWithRate'] = list;
    if (name === 'role') {
      const roles = { role: value };
      lists['roles'][index] = roles;
    }
    if (name === 'payRate') {
      if (value) setDuplicatePayrateError('');
    }
    setAllInputFields(lists);
    setIsFieldUpdated(true);
  };

  // console.log(locationName);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const [checked, setChecked] = React.useState(false);
  // console.log(open, handleOpen);

  const handleChange = (evnt) => {
    const { name, value } = evnt.target;
    const list = { ...allInputFields };
    const validationNumber = /^[0-9]+$/;
    if (name === 'firstName') {
      if (value) {
        setIsFieldUpdated(true);
        // if (!/^[A-Za-z\s]+$/.test(value)) {
        if (!/^[a-z]/gi.test(value)) {
          const allErr = { ...fieldValidation };
          allErr['firstNameError'] = 'Please enter a valid first name.';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['firstNameError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['firstNameError'] = 'FirstName is required.';
        setFieldValidation(allErr);
      }
      list[name] = value;
    } 
    else if (name === 'lastName') {
      if (value) {
        setIsFieldUpdated(true);
        // if (!/^[A-Za-z\s]+$/.test(value)) {
        if (!/^[a-z]/gi.test(value)) {
          const allErr = { ...fieldValidation };
          allErr['lastNameError'] = 'Please enter a valid last name.';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['lastNameError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['lastNameError'] = 'LastName is required.';
        setFieldValidation(allErr);
      }
      list[name] = value;
    }

     else if (name === 'email') {
      if (value) {
        setIsFieldUpdated(true);
        if (!/\S+@\S+\.\S+/.test(value)) {
          const allErr = { ...fieldValidation };
          allErr['emailError'] = 'Please enter a valid email address.';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['emailError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['emailError'] = 'Email is required.';
        setFieldValidation(allErr);
      }
      list[name] = value;
    } else if (name === 'designationId') {
      const allErr = { ...fieldValidation };
      if (value === '') {
        // list[name] = '';
        // allErr['designationError'] = 'Designation is required.';
        // setFieldValidation(allErr);
      } else {
        setIsFieldUpdated(true);
        // list['designationValue'] = value;
        setDesignationId(value);
        list['designationId'] = value.split('|')[0];
        list['designationName'] = value.split('|')[1];
        allErr['designationError'] = '';
        setFieldValidation(allErr);
      }
    } else if (name === 'locationId') {
      // Location validation logic
      const selectedLocation = locationOptions.find(
        (location) => location.sortKey === value
      );

      const allErr = { ...fieldValidation };
      if (value === '') {
        list[name] = '';
        allErr['locationError'] = 'Location is required.';
        setFieldValidation(allErr);
      } else {
        setIsFieldUpdated(true);
        list[name] = value;
        setSelectedLocationId(value); // Update selectedLocationId
        setSelectedLocationName(selectedLocation.locationName); // Update selectedLocationName
        allErr['locationError'] = '';
        setFieldValidation(allErr);
      }
    } else if (name === 'phone') {
      let formatWorkPhoneNumber = formatPhoneNumber(value);
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      if (value) {
        setIsFieldUpdated(true);
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          const allErr = { ...fieldValidation };
          allErr['phoneError'] =
            'Please enter a valid phone number in format: xxx-xxx-xxxx';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['phoneError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['phoneError'] = 'Phone number is required';
        setFieldValidation(allErr);
      }
      list[name] = formatWorkPhoneNumber;
    } else if (name === 'emerengencyContactName') {
      if (value) {
        setIsFieldUpdated(true);
        // if (!/^[A-Za-z\s]+$/.test(value)) {
        if (!/^[a-z]/gi.test(value)) {
          const allErr = { ...fieldValidation };
          allErr['emergencyContactPerosnError'] = 'Please enter a valid name.';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['emergencyContactPerosnError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['emergencyContactPerosnError'] = 'Name is required.';
        setFieldValidation(allErr);
      }
      list[name] = value;
    } else if (name === 'emergencyPhone') {
      let formatWorkPhoneNumber = formatPhoneNumber(value);
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      if (value) {
        setIsFieldUpdated(true);
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          const allErr = { ...fieldValidation };
          allErr['ePhoneError'] =
            'Please enter a valid phone number in format: xxx-xxx-xxxx';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['ePhoneError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['ePhoneError'] = 'Emergency Phone number is required';
        setFieldValidation(allErr);
      }
      list[name] = formatWorkPhoneNumber;
    } else if (name === 'addressLine1') {
      if (value === '') {
        const allErr = { ...fieldValidation };
        allErr['addressError'] = 'Address is required.';
        setFieldValidation(allErr);
      } else {
        setIsFieldUpdated(true);
        const allErr = { ...fieldValidation };
        allErr['addressError'] = '';
        setFieldValidation(allErr);
      }
      list[name] = value;
    } else if (name === 'city') {
      if (value === '') {
        const allErr = { ...fieldValidation };
        allErr['cityError'] = 'City is required.';
        setFieldValidation(allErr);
      } else {
        setIsFieldUpdated(true);
        const allErr = { ...fieldValidation };
        allErr['cityError'] = '';
        setFieldValidation(allErr);
      }
      list[name] = value;
    } else if (name === 'state') {
      if (value === '') {
        const allErr = { ...fieldValidation };
        allErr['stateError'] = 'State is required.';
        setFieldValidation(allErr);
      } else {
        setIsFieldUpdated(true);
        const allErr = { ...fieldValidation };
        allErr['stateError'] = '';
        setFieldValidation(allErr);
      }
      list[name] = value;
    } else if (name === 'gender') {
      if (value === '') {
        const allErr = { ...fieldValidation };
        allErr['genderError'] = 'Gender is required.';
        setFieldValidation(allErr);
      } else {
        setIsFieldUpdated(true);
        const allErr = { ...fieldValidation };
        allErr['genderError'] = '';
        setFieldValidation(allErr);
      }
      list[name] = value;
    } else if (name === 'postalCode' && validationNumber.test(value)) {
      if (value) {
        setIsFieldUpdated(true);
        if (value.toString().length > 5) {
          const allErr = { ...fieldValidation };
          allErr['zipcodeError'] =
            'Zip code should not be greater than 5 digit.';
          setFieldValidation(allErr);
        } else if (value.toString().length < 5) {
          const allErr = { ...fieldValidation };
          allErr['zipcodeError'] = 'Zip code should not be less than 5 digit.';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['zipcodeError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['zipcodeError'] = 'Zip code is required.';
        setFieldValidation(allErr);
      }
      // const formattednum = formatPhoneNumber(value);
      list[name] = value;
    } else {
      if (name !== 'postalCode' || value === "") list[name] = value;
      setIsFieldUpdated(true);
    }

    setAllInputFields(list);
  };

  useEffect(() => {
    // setopenAddClient(true);

    if (profileUserData) {
      setTimeout(() => {
        setAllInputFields(
          {
            locationId: profileUserData?.locationId,
            // name: `${profileUserData?.firstName} ${profileUserData?.lastName}`,
            firstName:profileUserData?.firstName,
            lastName:profileUserData?.lastName,
            email: profileUserData?.email,
            phone: profileUserData?.phone,
            emergencyPhone: profileUserData?.emergencyPhone,
            staffProfileImage: profileUserData?.staffProfileImage,
            addressLine1: profileUserData?.addressLine1,
            addressLine2: profileUserData?.addressLine2,
            city: profileUserData?.city,
            state: profileUserData?.state,
            postalCode: profileUserData?.postalCode,
            payRateType: 'Salaried',
            gender: profileUserData?.gender,
            emerengencyContactName: profileUserData?.emerengencyContactName,
            designationId: profileUserData?.designationId,
            designationName: profileUserData?.designationName,
            roles: profileUserData.roles,
            rolesWithRate: profileUserData.rolesWithRate,
            startDate: profileUserData?.startDate,
            profileImage: profileUserData?.profileImage,
            terminationDate: profileUserData?.terminationDate,
            // isActive: true
          }
        )
      }, [500])
    } else {
      setAllInputFields((prevState) => ({
        ...prevState,
        startDate: startDate,
        terminationDate: endDate
      }));
    }
  }, [endDate, startDate]);




  const saveStaff = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowError('');
    const body = allInputFields;
    // console.log('body-', body);

    const allErr = { ...fieldValidation };
    if (body.firstName === '') {
      allErr['firstNameError'] = 'FirstName is required.';
      setFieldValidation(allErr);
      // return;
    }
    if (body.lastName === '') {
      allErr['lastNameError'] = 'LastName is required.';
      setFieldValidation(allErr);
      // return;
    }


    if (body.email === '') {
      allErr['emailError'] = 'Email is required.';
      setFieldValidation(allErr);
    } else if (!/\S+@\S+\.\S+/.test(body.email)) {
      allErr['emailError'] = 'Please enter a valid email address';
      setFieldValidation(allErr);
    }
    if (body.phone === '') {
      // const allErr = { ...fieldValidation };
      allErr['phoneError'] = 'Phone is required.';
      setFieldValidation(allErr);
    }
    // if (body.designationId === '' || !body.designationId) {
    //   allErr['designationError'] = 'Designation is required.';
    //   setFieldValidation(allErr);
    // }
    if (body.emerengencyContactName === '') {
      // const allErr = { ...fieldValidation };
      allErr['emergencyContactPerosnError'] =
        'Emergency Contact Person is required.';
      setFieldValidation(allErr);
    }
    if (body.emergencyPhone === '') {
      // const allErr = { ...fieldValidation };
      allErr['ePhoneError'] = 'Emergency Phone is required.';
      setFieldValidation(allErr);
    }
    // if (body.designationName === '' || !body.designationName) {
    //   // const allErr = { ...fieldValidation };
    //   allErr['designationError'] = 'Designation is required.';
    //   setFieldValidation(allErr);
    // }
    if (body.locationId === '') {
      // const allErr = { ...fieldValidation };
      allErr['locationError'] = 'Location is required.';
      setFieldValidation(allErr);
    }
    if (body.addressLine1 === '') {
      // const allErr = { ...fieldValidation };
      allErr['addressError'] = 'Address is required.';
      setFieldValidation(allErr);
    }
    if (body.state === '') {
      // const allErr = { ...fieldValidation };
      allErr['stateError'] = 'State is required.';
      setFieldValidation(allErr);
    }
    if (body.city === '') {
      // const allErr = { ...fieldValidation };
      allErr['cityError'] = 'City is required.';
      setFieldValidation(allErr);
    }
    if (body.postalCode === '') {
      // const allErr = { ...fieldValidation };
      allErr['zipcodeError'] = 'Zip code is required.';
      setFieldValidation(allErr);
    }

    if (body.gender === '') {
      // const allErr = { ...fieldValidation };
      allErr['genderError'] = 'Gender is required.';
      setFieldValidation(allErr);
    }

    // if (body.profileImage === '' || !body.profileImage) {
    //   allErr['staffProfileImageError'] = 'Staff Profile is required.';
    //   setFieldValidation(allErr);
    // }

    if (body.roles.length === 0) {
      setDuplicateRoleError('Role is required.');
    }

    let value = Object.values(body.rolesWithRate);
    let payrateValue;
    if (value) payrateValue = value[0]?.payRate && value[0].payRate;

    // console.log("eeee", body.roles, body.rolesWithRate, payrateValue);
    if (!payrateValue) {
      setDuplicatePayrateError('Pay rate is required.');
    }
    if (!startDate) {
      setStartDateError('Please select start date.');
    }

    // if (!endDate) {
    //   setEndDateError('Please select end date.')
    // }
    // Check if the required field is not filled then return
    if (
      !fieldValidation.zipcodeError &&
      !endDateError &&
      body &&
      body.firstName &&
      body.lastName &&
      body.email &&
      // body.designationId &&
      body.phone &&
      body.emerengencyContactName &&
      body.locationId &&
      body.emergencyPhone &&
      // body.designationId &&
      // body.designationName &&
      body.addressLine1 &&
      body.state &&
      body.city &&
      body.postalCode &&
      // body.profileImage &&
      body.gender &&
      // body.terminationDate &&
      body.startDate &&
      // body.staffProfileImage &&
      // body.roles?.length > 0 &&
      payrateValue
    ) {

      // Now show the loader
      // setIsLoading(true);
      // setLoader(true);
      setOpenConfirmationPopUp(true);
    }
  };
  const handleConfirmClose = (val) => {
    if (val === 'Yes') {
      // EditBundlesData()
      saveStaffdata();
      setOpenConfirmationPopUp(false);
    }
    if (val === 'No') {
      setOpenConfirmationPopUp(false);
    }
  };

  const saveStaffdata = () => {
    const body = allInputFields;
    setIsLoading(true);
    let roles = body.roles.length > 0 ? JSON.stringify(body.roles) : [];
    let rolesWithRate =
      body.rolesWithRate.length > 0 ? JSON.stringify(body.rolesWithRate) : [];
    const formData = new FormData();
    // formData.append('locationId', selectedLocationId);
    formData.append('locationName', selectedLocationName);
    formData.append('firstName', body.firstName);
    formData.append('lastName', body.lastName);
    formData.append('email', body.email);
    formData.append('phone', body.phone);
    formData.append('emerengencyContactName', body.emerengencyContactName);
    formData.append('emergencyPhone', body.emergencyPhone);
    formData.append('addressLine1', body.addressLine1);
    formData.append('addressLine2', body.addressLine2);
    formData.append('city', body.city);
    formData.append('state', body.state);
    formData.append('postalCode', body.postalCode);
    formData.append('payRateType', body.payRateType);
    /* commented for staff api designationId and designationName said to not allowed */
    formData.append('designationId', body.designationId);
    formData.append('designationName', body.designationName);
    formData.append('roles', roles);
    formData.append('rolesWithRate', rolesWithRate);
    formData.append('startDate', body.startDate);
    formData.append('terminationDate', body.terminationDate);
    // formData.append('isActive', body.isActive);
    formData.append('gender', body.gender);
    formData.append('updatedBy', userId);
    // formData.append('profileImage', body.profileImage);
    if (multipleImage) {
      formData.append('profileImage', multipleImage[0]?.file);
    }
    let encodedLocationId = encodeURIComponent(selectedLocationId);
    let encodedStaffId = encodeURIComponent(staffId);
    let editStaffUrl = `${process.env.REACT_APP_API_END_POINT}staff/updateStaff?locationId=${encodedLocationId}&staffId=${encodedStaffId}`;
    axios
      .put(editStaffUrl, formData)
      .then((response) => {
        // setLoader(false)
        // setAlertShow(true);
        // setAlertMsg(response.data.message);
        if (response.data.statusCode === 200) {
          if (multipleDocs && multipleDocs.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const image of multipleDocs) {
              const formData = new FormData();
              let imagename = image.documentName ?? image?.file.name
              let imageSource = image?.file ?? image
              formData.append('userId', response.data.data.Attributes.sortKey || userId);
              formData.append('documentName', imagename);
              formData.append('locationId', locationId);
              formData.append('attachment', imageSource);

              addStaffDocs(formData);
            }
          }

          let crossicon = document.getElementsByClassName('staff-close')[0];
          if (crossicon) crossicon.click();
          let res = { ...errorHandler };
          res.errorType = 'Success';
          res.errorMsg = response.data.message;
          res.showDialog = true;
          setErrorHandler(res);

          // Now show the loader
          setIsLoading(false);
          setIsUpdated(true);
          // handleOpen();
          handleClickOpen();
          // To refresh the data after adding the staff
          setIsUserUpdate(!isUserUpdate)
          getStaff();
        } else {
          setShowError(response?.data.message);
          // Now hide the loader
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, 'err--', err.response?.data.error);
        // Now show the loader
        setIsLoading(false);
        if (err.response?.data.error === true) {
          // setLoader(false)
          let er = { ...errorHandler };
          er.errorType = 'Error';
          er.errorMsg = err.response?.data.message;
          er.showDialog = true;
          let msg = err.response?.data.message;
          setShowError(msg);
          setErrorHandler(er);
          // setAlertShow(true);
          // setAlertMsg(err.response.data.message);
          // Now show the loader
          // setIsLoading(false);
        }
      });
  }
  /* Function binded on close button of create-franchisee/user form dialog */
  // const handleClosePopup = () => {
  //   setOpenPopup(false);
  // };

  useEffect(() => {
    axios
      .get(getStatesUrl)
      .then((response) => {
        setStateOptions(response.data.data.Items);
      })
      .catch((err) => {
        console.log('err', err);
      });

    axios
      .get(getLocationsUrl)
      .then((response) => {
        setLocationOptions(response.data.data.Items);
      })
      .catch((err) => {
        console.log('err', err);
      });

    axios
      .get(getRolesUrl)
      .then((response) => {
        setRoleOptions(response.data.data.Items);
      })
      .catch((err) => {
        console.log('err', err);
      });

    axios
      .get(getDesignationsUrl)
      .then((response) => {
        setDesignationOptions(response.data.data.Items);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);


  useEffect(() => {
    setAllInputFields({
      ...staffItem,
      firstName:staffItem?.firstName,
      lastName:staffItem?.lastName,
       // Copy all properties from staffItem
      // name: `${staffItem.firstName} ${staffItem.lastName}` // Combine first name and last name
    });
    setStartDate(staffItem.startDate ?? profileUserData?.startDate);
    setEndDate(staffItem.terminationDate ?? profileUserData?.terminationDate);
    setInputFields(staffItem?.rolesWithRate ?? profileUserData?.rolesWithRate);
    setSelectedLocationId(staffItem.locationId ?? profileUserData?.locationId);
    setSelectedLocationName(staffItem.locationName ?? profileUserData?.locationName);
    setStaffId(staffItem.sortKey ?? profileUserData?.sortKey);
    let obj = {
      previewURL: staffItem?.profileImage ?? profileUserData?.profileImage
    };
    setMultipleImage([obj]);
    if (staffItem?.designationId) {
      setDesignationId(staffItem.designationId + '|' + staffItem.designationName)
    } else if (profileUserData?.designationId) {
      setDesignationId(profileUserData.designationId + '|' + profileUserData.designationName)
    }
  }, []);


  // useEffect(() => {


  //   if (designationOptions?.length)
  //     setDesignationId(
  //       designationOptions[0].sortKey + '|' + designationOptions[0].designation
  //     );
  // }, [designationOptions]);


  //   const clearForm = () => {
  //     allInputFields['name'] = '';
  //     allInputFields['email'] = '';
  //     allInputFields['phone'] = '';
  //     allInputFields['emerengencyContactName'] = '';
  //     allInputFields['emergencyPhone'] = '';
  //     allInputFields['addressLine1'] = '';
  //     allInputFields['addressLine2'] = '';
  //     allInputFields['city'] = '';
  //     allInputFields['state'] = '';
  //     allInputFields['postalCode'] = '';
  //     allInputFields['payRateType'] = 'Salaried';
  //     allInputFields['designationId'] = '';
  //     allInputFields['designationName'] = '';
  //     allInputFields['roles'] = [];
  //     allInputFields['rolesWithRate'] = [];
  //     fieldValidation['nameError'] = '';
  //     fieldValidation['emailError'] = '';
  //     fieldValidation['phoneError'] = '';
  //     fieldValidation['emergencyContactPerosnError'] = '';
  //     fieldValidation['ePhoneError'] = '';
  //     fieldValidation['addressError'] = '';
  //     fieldValidation['cityError'] = '';
  //     fieldValidation['stateError'] = '';
  //     fieldValidation['zipcodeError'] = '';
  //     fieldValidation['locationError'] = '';
  //     fieldValidation['designationError'] = '';
  //     fieldValidation['genderError'] = '';
  //     fieldValidation['staffProfileImageError'] = '';
  //     fieldValidation['locationIdError'] = '';
  //     fieldValidation['locationId'] = '';
  //     setDesignationId('');
  //     allInputFields.locationId = '';
  //     allInputFields.gender = '';
  //     setAllInputFields(allInputFields);
  //     setInputFields([
  //       {
  //         role: '',
  //         payRate: ''
  //       }
  //     ]);

  //     // set image to be blank
  //     allInputFields['staffProfileImage'] = '';
  //     setParentImage('');
  //     setDuplicatePayrateError('');
  //     setDuplicateRoleError('');
  //   };

  // Function to close modal popUp

  const handleClose = () => {
    if (profileUserData) {
      setIsEdit(false)
    } else {
      setIsUpdated(false);
      if (isFieldUpdated) {
        setOpenPopup(true);
        // setopenAddClient(true);
      } else {
        setOpenPopup(true);
        // setShowScreen(0);
        // setOpenPopup(false);
        // openModal(false);
        // setopenAddClient(false);
      }
    }

  };
  const handleClosePopup = (val) => {
    if (val === 'Save') {
      setOpenPopup(false);
      // setopenAddClient(true);
      // openModal(true);
    } else if (val === 'Discard') {
      if (profileUserData) {
        setIsEdit(false)
      }
      setOpenPopup(false);
      getStaff();
      // setopenAddClient(false);
      // openModal(false);
      setShowScreen(0);
    }
  };

  /* Function to close the add item dialog */

  const handleUploadDocsClose = () => {
    // if (isFieldUpdated) {
    //   setopenAddteam(true);
    //   setOpen(true);
    // } else {
    //   setIsOpen(false);
    //   setopenAddteam(false);
    //   // setOpenTeams(true);
    //   setopenAddteam(false);
    //   setOpen(false);
    //   clearAll();
    // }
    setIsOpen(false);
  };


  /* Function to get uploaded image */
  const handleFileInput = (event) => {
    setUploadDocsError('');
    const allowedExtensions = [
      'pdf',
      'jpg',
      'jpeg',
      'png',
      'doc',
      'docx',
      'xls',
      'xlsx'
    ];
    const fileList = event.target.files;

    if (fileList.length !== 0) {
      const uploadedFiles = Array.from(fileList)
        .filter((file) => {
          const fileExtension = file.name.split('.').pop().toLowerCase();
          return allowedExtensions.includes(fileExtension);
        })
        .map((file) => {
          // Create an object for each file with a unique ID and the file itself
          return {
            documentId: Math.random().toString(36).substring(7),
            file: file,
            previewURL: URL.createObjectURL(file)
          };
        });

      setMultipleDocs([...multipleDocs, ...uploadedFiles].slice(0, 5));
    }
  };
  const deletefDocs = async (idToRemove) => {
    let encodedLocationId = selectedLocationId;
    let encodedStaffId = staffId;
    await fetch(
      `${process.env.REACT_APP_FEATURED_API_END_POINT}staff/deleteDocument`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'userId': encodedStaffId,
          'locationId': encodedLocationId,
          'documentId': idToRemove
        })
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsUpdated(true);
        // setopenAddproduct(false);
        // setopenProductPopup(false);
        // setOpen(true);
        console.log(data, 'data');
        // clear();
      }
      );


  };

  const removeImage = (idToRemove) => {
    const updatedImages = multipleDocs.filter((image) => {
      return image.documentId !== idToRemove
    });
    setMultipleDocs(updatedImages);
    deletefDocs(idToRemove)
  };




  const startDateFunc = (date) => {
    if (date === '01-01-1970') {
      return;
    }
    if (endDate) {
      let checkYear = date[6] + date[7] + date[8] + date[9]
      let getStaffYear = endDate[6] + endDate[7] + endDate[8] + endDate[9]

      if (endDate >= date && getStaffYear >= checkYear) {
        setEndDateError('');

      } else if (checkYear >= getStaffYear) {
        setEndDateError('Termination date must be greater than the start');
      } else {
        setEndDateError('');

      }
    }
    // let newDate;
    // let objectDate = new Date(val);
    // // setStartdateObj(objectDate);
    // let date = objectDate.getDate();
    // if (date < 10) {
    //   newDate = '0' + date;
    // } else {
    //   newDate = date;
    // }
    // let month = objectDate.getMonth() + 1;
    // // let newMonth = "0" + month;
    // let newMonth;
    // newMonth = month < 10 ? '0' + month : month;
    // let year = objectDate.getFullYear();
    // let finalDate = newMonth + '-' + newDate + '-' + year;
    setStartDateError('');
    // setIsFieldUpdated(true);
    setStartDate(date);
  };

  const endDateFunc = (date) => {
    let checkYear = startDate[6] + startDate[7] + startDate[8] + startDate[9]
    let getStaffYear = date[6] + date[7] + date[8] + date[9]
    if (date === '01-01-1970') {
      return;
    }
    if (startDate && date <= startDate && getStaffYear <= checkYear && startDate !== date) {
      setEndDate(date);
      // setAllInputFields(
      //   ...allInputFields,
      //   (allInputFields['terminationDate'] = date)
      //   );
      setEndDateError('Termination date must be greater than the start')
    } else if (checkYear > getStaffYear) {
      setEndDateError('Termination date must be greater than the start')
    } else {
      setEndDate(date);
      setEndDateError('');
      setTimeout(() => {
        setAllInputFields(
          ...allInputFields,
          (allInputFields['terminationDate'] = date)
        );
      }, 100);
    }
    // let newDate;
    // let objectDate = new Date(val);
    // // setStartdateObj(objectDate);
    // let date = objectDate.getDate();
    // if (date < 10) {
    //   newDate = '0' + date;
    // } else {
    //   newDate = date;
    // }
    // let month = objectDate.getMonth() + 1;
    // // let newMonth = "0" + month;
    // let newMonth;
    // newMonth = month < 10 ? '0' + month : month;
    // let year = objectDate.getFullYear();
    // let finalDate = newMonth + '-' + newDate + '-' + year;
    // setIsFieldUpdated(true);
  };

  /* Function to get uploaded image */
  const handleImageInput = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg') ||
        fileName.endsWith('.heic')
      ) {
        if (fileName.endsWith('.heic')) {
          try {
            const jpegBlob = await heic2any({ blob: file });
            if (!jpegBlob) {
              throw new Error('Conversion failed.');
            }

            const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
              type: 'image/jpeg'
            });

            const uploadedImage = {
              previewURL: URL.createObjectURL(jpegFile),
              id: Math.random().toString(36).substring(7),
              file: jpegFile
            };

            // Replace the existing image with the new one
            setMultipleImage([uploadedImage]);
            setImageError('');
            setIsFieldUpdated(true);
          } catch (error) {
            console.error('Error handling HEIC file:', error);
            setImageError('Error handling HEIC file.');
          }
        } else if (file.type === 'image/gif') {
          setImageError(
            'Please upload a supported image format (excluding GIF and HEIC).'
          );
        } else {
          const uploadedImages = Array.from(event.target.files).map((file) => ({
            previewURL: URL.createObjectURL(file),
            id: Math.random().toString(36).substring(7),
            file: file
          }));

          // Replace the existing image with the new one
          setMultipleImage(uploadedImages);
          setImageError('');
          setIsFieldUpdated(true);
        }
      } else {
        setImageError(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
  };

  useEffect(() => {
    if (multipleImage) {
      let images = multipleImage.map((item) => item.previewURL);
      // let imagesArr = images.map((item) => ({
      //   image: item
      // }));
      // setMultipleImages(images);
      setProductImage(images);
    }
  }, [multipleImage]);

  // const removeImages = (idToRemove) => {
  //   const updatedImages = multipleImage.filter(
  //     (image) => image.id !== idToRemove
  //   );
  //   setMultipleImage(updatedImages);
  // };
  return (
    <>
      {/* <Dialog
        open={openAddClient}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right franchisee"
        maxWidth="lg"
      > */}
      {/* <DialogTitle className="alert-title align-right dialog-header">
          <Box> */}
      {/* remove icon */}
      {/* <Button
              className="details-remove-icon p-0 no-hover"
              onClick={handleClose}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle> */}
      {/* <DialogContent> */}
      <Box
        component="form"
        onSubmit={saveStaff}
        sx={{ margin: 'auto', width: '98%', padding: '0 2rem' }}
      >
        <Loader IsLoading={isLoading} />
        {/* <Grid container spacing={1}> */}
        {/* <Grid container item xs={12} spacing={3}> */}
        {/* <Grid item xs={6}>
              <h4 className={[" primary-color"]}>Add Staff</h4>
            </Grid> */}
        {/* <Grid item xs={12}> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box onClick={() => handleClose()} className="back-arrow-close">
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>
              {/* </Box> */}

              <Typography
                className="header-blue-32"
              // style={{ marginTop: '-5px', marginLeft: '5px' }}
              >
                Edit Staff
              </Typography>
            </Box>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <Button
              variant="contained"
              type="submit"
              style={{ background: '#053288', margin: '5px' }}
              className="common-button-staff"
            >
              Update
            </Button>

            <Button
              variant="outlined"
              style={{ color: '#053288', margin: '5px' }}
              className="common-button-staff"
              onClick={() => setIsOpen(true)}
            >
              Upload Docs
            </Button>
          </Box>
        </Grid>

        {/* </Grid> */}

        {/* <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h1 className="title mb-0 mt-10 primary-color">
                  Add Staff
                </h1>
              </Box> */}
        {/* </Grid> */}
        {/* <Grid
              item
              xs={6}
              className={[' primary-color']}
              style={{ textAlign: 'right', marginTop: '20px' }}
            >
              <Button
                variant="contained"
                type="submit"
                style={{ background: '#053288', margin: '5px' }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                style={{ color: '#053288', margin: '5px' }}
              >
                Upload Docs
              </Button>
            </Grid> */}
        {/* </Grid> */}
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <Box className="product-details-img align-center relative Main-card ">
              {multipleImage && multipleImage.length > 0 && multipleImage[0].previewURL !== "undefined" ? (
                <div>
                  {/* <Slider {...settings}>
                                  {multipleImages.map((media, index) => (
                                    <div key={index} style={{}}>
                                      <img
                                        className=" product-img"
                                        src={media.media}
                                        alt="product-icon"
                                      />
                                      <Box
                                        class="delete-icon"
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-end'
                                        }}
                                      >
                                        <DeleteIcon onClick={deleteMedia} />
              </Box>
            </div>
                                  ))}
</Slider> */}
                  <ImageSlider images={productImage} hideNavigator />
                </div>
              ) : (
                <img
                  src="/static/images/products/ProductImage.png"
                  alt="product-icon"
                  className="product-img"
                />
              )}
            </Box>
            <Box mt={2}>
              <Typography className="add-picture-text weight-700 text-black font-16 required-label">
                Upload Profile Image
              </Typography>
              <input
                accept="image/*"
                multiple
                type="file"
                id="select-product-add-file"
                style={{ display: 'none' }}
                onChange={(event) => handleImageInput(event)}
              />
              <label htmlFor="select-product-add-file">
                <Button
                  type="button"
                  variant="contained"
                  component="span"
                  className="upload-image image-with-txt common-button"
                >
                  <Typography className="upload-text">Upload</Typography>
                </Button>
              </label>
            </Box>
            {/* <Box className="card-image-carousel">
              {multipleImage.map((image) => (
                <div className="single-uploaded-images" key={image.id}>
                  <img src={image.previewURL} alt="Uploaded" />
                  <Box
                    className="remove-image text-blue"
                    onClick={() => {
                      removeImages(image.id);
                      setImageError('');
                    }}
                  >
                    x
                  </Box>
                </div>
              ))}
            </Box> */}
            <Box sx={{ float: 'left' }}>
              {imageError && (
                <FormHelperText className="error-message">
                  {imageError}
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={8}>
            <h2 className="primary-color mb-1">Personal Information</h2>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
              <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="FIRST NAME"
                  type="text"
                  placeholder="FIRST NAME"
                  onChange={handleChange}
                  value={allInputFields.firstName}
                  error={fieldValidation.firstNameError}
                  helperText={fieldValidation.firstNameError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                    // shrink: 'true'
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="LAST NAME"
                  type="text"
                  placeholder="LAST NAME"
                  onChange={handleChange}
                  value={allInputFields.lastName}
                  error={fieldValidation.lastNameError}
                  helperText={fieldValidation.lastNameError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                    // shrink: 'true'
                  }}
                />
              </Grid>
             <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="EMAIL"
                  type="text"
                  placeholder="EMAIL"
                  onChange={handleChange}
                  value={allInputFields.email}
                  error={fieldValidation.emailError}
                  helperText={fieldValidation.emailError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                    // shrink: 'true'
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  label="PHONE"
                  type="text"
                  placeholder="PHONE"
                  onChange={handleChange}
                  value={allInputFields.phone}
                  error={fieldValidation.phoneError}
                  helperText={fieldValidation.phoneError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                    // shrink: 'true'
                  }}
                  inputProps={{ maxLength: 12 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  error={fieldValidation.genderError}
                  fullWidth
                  className="select-drop-down-label-styling required-field"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling required-label"
                  >
                    GENDER
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={handleChange}
                    value={allInputFields.gender}
                    name="gender"
                    label="gender"
                    className="input-field-styling"
                    InputProps={
                      {
                        // classes: { notchedOutline: classes.notchedOutline }
                      }
                    }
                  >
                    {/* <MenuItem value="">
                            <em>None</em>
                          </MenuItem> */}
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  <FormHelperText className="field-error-text">
                    {fieldValidation.genderError}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  error={fieldValidation.stateError}
                  fullWidth
                  className="select-drop-down-label-styling"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling"
                  >
                    DESIGNATION
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    className="input-field-styling required-field"
                    id="designationId"
                    label="designationId"
                    name="designationId"
                    value={designationId}
                    onChange={handleChange}
                  >
                    {designationOptions.map((r, i) => (
                      <MenuItem key={i} value={r.sortKey + '|' + r.designation}>
                        {r.designation}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className="field-error-text">
                    {fieldValidation.designationError}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  id="emerengencyContactName"
                  name="emerengencyContactName"
                  label="EMERGENCY CONTACT PERSON"
                  type="text"
                  placeholder="EMERGENCY CONTACT PERSON"
                  onChange={handleChange}
                  value={allInputFields.emerengencyContactName}
                  error={fieldValidation.emergencyContactPerosnError}
                  helperText={fieldValidation.emergencyContactPerosnError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                    // shrink: 'true'
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  id="emergencyPhone"
                  name="emergencyPhone"
                  label="EMERGENCY PHONE"
                  type="text"
                  placeholder="EMERGENCY PHONE"
                  onChange={handleChange}
                  value={allInputFields.emergencyPhone}
                  error={fieldValidation.ePhoneError}
                  helperText={fieldValidation.ePhoneError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                    // shrink: 'true'
                  }}
                  inputProps={{ maxLength: 12 }}
                />
              </Grid>

             

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  name="addressLine1"
                  label="ADDRESS1"
                  placeholder="ADDRESS"
                  onChange={handleChange}
                  value={allInputFields.addressLine1}
                  error={Boolean(fieldValidation.addressError)}
                  helperText={fieldValidation.addressError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                    // shrink: 'true'
                  }}
                  InputProps={{}}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  name="addressLine2"
                  label="ADDRESS 2 (OPTIONAL)"
                  placeholder="ADDRESS 2"
                  onChange={handleChange}
                  value={allInputFields.addressLine2}
                  className="input-field-styling"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee'
                  }}
                  InputProps={{}}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  error={fieldValidation.stateError}
                  fullWidth
                  className="select-drop-down-label-styling required-field"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling required-label"
                  >
                    STATE
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="STATE"
                    name="state"
                    onChange={handleChange}
                    value={allInputFields.state}
                    error={Boolean(fieldValidation.stateError)}
                    className="input-field-styling"
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 200 } }
                    }}
                  >
                    {stateOptions.map((r, i) => (
                      <MenuItem key={i} value={r.abbreviation}>
                        {r.state}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldValidation.stateError && (
                    <FormHelperText error>
                      {fieldValidation.stateError}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  label="CITY"
                  placeholder="CITY"
                  name="city"
                  onChange={handleChange}
                  value={allInputFields.city}
                  error={Boolean(fieldValidation.cityError)}
                  helperText={fieldValidation.cityError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                    // shrink: 'true'
                  }}
                  inputProps={{}}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  type='text'
                  label="ZIP CODE"
                  placeholder="ZIP CODE"
                  name="postalCode"
                  onChange={handleChange}
                  value={allInputFields.postalCode}
                  error={Boolean(fieldValidation.zipcodeError)}
                  helperText={fieldValidation.zipcodeError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                    // shrink: 'true'
                  }}
                  inputProps={{ maxLength: 5 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  error={fieldValidation.locationError}
                  fullWidth
                  className="select-drop-down-label-styling required-field"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling required-label"
                  >
                    LOCATION
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="locationId"
                    onChange={handleChange}
                    value={allInputFields.locationId}
                    label="Location"
                    className="input-field-styling"
                    error={Boolean(fieldValidation.cityError)}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 200 } }
                    }}
                  >
                    {locationOptions.map((r, i) => (
                      <MenuItem key={i} value={r.sortKey}>
                        {r.locationName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className="field-error-text">
                    {fieldValidation.locationError}
                  </FormHelperText>
                </FormControl>
              </Grid>
             

              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  fullWidth
                  component="fieldset"
                  className={classes.addStaffField}
                >
                  <FormLabel
                    component="legend"
                    style={{ textAlign: 'left', color: '#000' }}
                  >
                    Payrate Type
                  </FormLabel>
                  <RadioGroup
                    aria-label="payRateType"
                    name="payRateType"
                    // value={value}
                    onChange={handleChange}
                    value={allInputFields.payRateType}
                  // onChange={handleChangePayrate}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          value="Salaried"
                          control={<Radio color="secondary" />}
                          label="Salaried"
                          color="secondary"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          value="Hourly"
                          control={<Radio />}
                          label="Hourly"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* <Grid item xs={12} sm={6} md={6} /> */}

              <Grid container spacing={3} className={classes.addStaffField}>
                <Grid item xs={6} style={{ textAlign: 'left' }}>
                  <b style={{ color: '#053288' }}>Roles</b>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  {/* {roleOptions.length > inputFields.length && (
                    <Box
                      onClick={addInputField}
                      onKeyDown={addInputField}
                      sx={{
                        width: '160px !important',
                        float: 'right !important'
                      }}
                    >
                      <b
                        style={{
                          color: '#053288',
                          borderBottom: '1px solid #053288',
                          cursor: 'pointer'
                        }}
                      >
                        + ADD ANOTHER ROLE
                      </b>
                    </Box>
                  )} */}
                </Grid>
              </Grid>
              {inputFields?.map((data, index) => {
                const { role, payrate, disabled } = data;
                console.log(role, payrate, disabled);
                return (
                  <>
                    {/* <Grid
                      container
                      spacing={3}
                      className={classes.addStaffRoleField}
                      key={index}
                    > */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{
                        paddingTop: '0px !important',
                        paddingBottom: '15px !important'
                      }}
                    >
                      <FormControl
                        fullWidth
                        className="select-drop-down-label-styling"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling "
                        >
                          ROLE {index + 1}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // onChange={handleChange}
                          className="input-field-styling"
                          onChange={(evnt) => handleChangeRoles(index, evnt)}
                          value={inputFields[index].role}
                          name="role"
                          label="role 01"
                          InputProps={
                            {
                              // classes: {
                              //   notchedOutline: classes.notchedOutline
                              // } disabled={(inputFields.length > 0 && inputFields[i] && inputFields[i].role)}
                            }
                          }
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                            //  shrink: 'true'
                          }}
                        >
                          {roleOptions.map((r, i) => (
                            //  id={index} disabled={(inputFields.length > 0 && inputFields[i] && inputFields[i].disabled) || (r.value === role)}
                            <MenuItem
                              key={i}
                              value={r.role}
                              disabled={r.isDisabled}
                            >
                              {r.role}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText className="field-error-text">
                          {duplicateRoleError}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{
                        paddingTop: '0px !important',
                        paddingBottom: '15px !important'
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic"
                          label="PAYRATE"
                          variant="outlined"
                          name="payRate"
                          type="text"
                          className="input-field-styling"
                          onChange={(evnt) => handleChangeRoles(index, evnt,'payrate')}
                          value={inputFields[index].payRate}
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                            // shrink: 'true'
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                  }}
                                >
                                  $
                                </span>
                              </InputAdornment>
                            )
                          }}
                        />
                      </FormControl>
                      <FormHelperText className="field-error-text">
                        {payRateError}
                      </FormHelperText>
                    </Grid>

                    {/* <Grid item xs={2}> */}
                    {/* marginTop: '23px',  */}
                    {/* // <div className='aa' tabIndex="0" role="button" onClick={removeInputFields} onKeyDown={removeInputFields}> */}
                    {/* {inputFields.length !== 1 && (
                          <span
                            className="aa"
                            tabIndex="0"
                            role="button"
                            onClick={() => removeInputFields(index)}
                            onKeyDown={() => removeInputFields(index)}
                          >
                            <img
                              alt="cross"
                              src="/static/images/logo/remove.png"
                              style={{ cursor: 'pointer' }}
                            />
                          </span>
                        )}
                      </Grid> */}
                    {/* </Grid> */}
                  </>
                );
              })}
              {/* {showRoleError && <FormHelperText className='field-error-text Mui-error final-error'>{roleErrorMessage}</FormHelperText>} */}

              <Grid item xs={12} sm={6} md={6}>
                <CustomDatePicker
                  label="START DATE"
                  fieldValue={allInputFields.startDate}
                  onChange={startDateFunc}
                  error={Boolean(startDateError)}
                  required={Boolean(true)}
                />
                <FormHelperText className="field-error-text">
                  {startDateError}
                </FormHelperText>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <CustomDatePicker
                  label="TERMINATION DATE"
                  fieldValue={allInputFields.terminationDate}
                  onChange={endDateFunc}
                  error={Boolean(endDateError)}
                // required={Boolean(true)}
                />
                <FormHelperText className="field-error-text">
                  {endDateError}
                </FormHelperText>
              </Grid>

              {showError && (
                <FormHelperText className="field-error-text">
                  {showError}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Box>
      {/* </DialogContent>
      </Dialog> */}

      {/* Delete confirmation dialog */}
      <Dialog
        open={openPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">What do you want to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Staff details has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClosePopup('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmationPop}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Save Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Please confirm that you want to save your changes?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('No')}
          >
            Go Back to Editing
          </Button>
          {<Divider className="vertical-divider" />}

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Yes')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload Docs Dialog old --------- */}

      {/* Upload Docs Dialog */}
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleUploadDocsClose('cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right not "
        maxWidth="sm"
        sx={{ maxHeight: '426px', mt: 25, ml: 2 }}
      >
        <Box>
          <DialogTitle className="dialog-box-right-addTask -team-container-white-box">
            {/* this class is commented for the above code className="alert-title align-right dialog-header" */}
            <Box
              className="dialog-text "
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography
                textAlign="center"
                sx={{
                  marginBottom: '10px',
                  fontWeight: '700',
                  color: '#003087 !important',
                  fontSize: '30px'
                }}
              >
                UPLOAD DOCS
              </Typography>
              {/* remove icon */}
              <Button
                className="details-remove-icon task-cross p-0 no-hover"
                onClick={() => handleUploadDocsClose('cancel')}
              >
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                    fill="#E64646"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
        </Box>
        <DialogContent className="-team-container-white-box">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={6}>
                <div className="add-img-cont-staff align-center radius-5">
                  <input
                    accept="image/*"
                    multiple
                    type="file"
                    id="select-product-add-files-docs"
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileInput(event)}
                    disabled={multipleDocs.length >= 5}
                  />
                  <label htmlFor="select-product-add-files-docs">
                    <Typography
                      textAlign="center"
                      sx={{
                        marginBottom: '10px',
                        fontWeight: '700',
                        color: `${multipleDocs.length >= 5
                          ? 'grey !important'
                          : '#003087 !important'
                          }`,
                        fontSize: '16px'
                      }}
                      mt={1}
                    >
                      UPLOAD DOCS
                    </Typography>
                  </label>
                </div>
                {/* <Box className="card-image-carousel">
                  {multipleDocs.map((image) => (
                    <div className="single-uploaded-images" key={image.id}>
                      <img src={image.previewURL} alt="Uploaded" />
                      <Box
                        className="remove-image text-blue"
                        onClick={() => removeImage(image.id)}
                      >
                        x
                      </Box>
                    </div>
                  ))}
                </Box> */}
              </Grid>

              {multipleDocs && multipleDocs.length > 0 && (
                <Grid
                  item
                  sx={{
                    width: '100%'
                  }}
                >
                  <Card
                    sx={{
                      padding: '15px',
                      borderRadius: '19px 19px 0px 0px'
                    }}
                  >
                    <TableContainer className="location-table-container">
                      <Table>
                        <TableHead>
                          <TableRow className="table-header">
                            <TableCell>File Name</TableCell>

                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {multipleDocs.map((image) => {
                            return (
                              <TableRow
                                hover
                                key={image?.sortKey}
                                // selected={isLocationItemSelected}
                                className="table-row cursor-pointer"
                              // onClick={(e) => handleViewLocation(locationItem, e)}
                              >
                                <TableCell className="table-td">

                                  <Typography>{image.documentName ?? image?.file?.name}</Typography>
                                </TableCell>

                                <TableCell className="table-td">
                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background:
                                          theme.colors.secondary.lighter
                                      },
                                      color: theme.palette.primary.main,
                                      borderRadius: '50% !important'
                                    }}
                                    color="inherit"
                                    size="small"
                                    borderRadius="50% !important"
                                    onClick={() =>
                                      window.open(image.documentUrl ?? image.previewURL, '_blank')
                                    }
                                  >
                                    {/* Delete Icon */}
                                    <EyeIcon />
                                  </IconButton>

                                  <IconButton
                                    sx={{
                                      '&:hover': {
                                        background:
                                          theme.colors.secondary.lighter
                                      },
                                      color: theme.palette.primary.main,
                                      borderRadius: '50% !important'
                                    }}
                                    color="inherit"
                                    size="small"
                                    borderRadius="50% !important"
                                    onClick={() => {
                                      removeImage(image.documentId)
                                    }}

                                  // onClick={(e) =>
                                  //   onMenuItemClick(owner, e, 'editOwners')
                                  // }
                                  >
                                    {/* Delete Icon */}
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditStaff;
