import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
  FormHelperText,
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  Slide
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApiService } from '../../../services/ApiService';
import Loader from '../../../components/Loader';
// import './createUser.css';
import { formatPhoneNumber } from 'src/utils';
import heic2any from 'heic2any';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// UserEdit  page main method
const UserEditDetails = ({
  selectedData,
  refreshUserList,
  // showForm,
  setShowScreen,
  setSearchText,
  setIsEdit,
  profileUserData,
  userRoles,
  statesList,
  setIsUserUpdate,
  isUserUpdate
}) => {
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();

  // show loader when login button is clicked
  // const [isLoading, setIsLoading] = useState(false);
  let userDetails;

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [workPhoneNumber, setWorkPhoneNumber] = useState('');
  const [role, setRole] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [roleName, setRoleName] = useState('');
  const [roles, setRoles] = useState();
  const [gender, setgender] = useState('');
  const [teamId, setTeamId] = useState('');
  const [entityType, setEntityType] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setpostalCode] = useState('');
  const [imageUrl, setImageUrl] = useState('');

 // const [statesList, SetStatesList] = useState([]);
  // const [cityList, SetCityList] = useState([]);
//  const [userRoles, setUserRoles] = useState([]);
  const [userTeams, setUserTeams] = useState([]);
  const [firstNameHelperText, setFirstNameHelperText] = useState('');
  const [lastNameHelperText, setLastNameHelperText] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState(false);
  const [workPhoneNumberHelperText, setWorkPhoneNumberHelperText] =
    useState(false);
  const [roleHelperText, setRoleIdHelperText] = useState(false);
  const [genderHelperText, setgenderHelperText] = useState(false);
  const [teamHelperText, setTeamHelperText] = useState(false);
  const [addressLine1HelperText, setAddressLine1HelperText] = useState(false);
  const [stateHelperText, setStateHelperText] = useState(false);
  const [cityHelperText, setCityHelperText] = useState(false);
  const [postalCodeHelperText, setpostalCodeHelperText] = useState(false);
  const [editUserId, setEditUserId] = useState('');
  const [profileimageError, setProfileImage] = useState(false);
  const [profileImageError, setProfileImageError] = useState('');

  const [imgObj, setImageObj] = useState([]);
  // Set the final error message
  const [finalApiErrMsg, setFinalApiErrMsg] = useState('');
  const [finalApiErr, setFinalApiErr] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  // State for save changes icon click modal
  const [open, setOpen] = React.useState(false);
  // State for CLose icon click modal
  const [openConfirmPopup, setOpenConfirmPopup] = React.useState(false);
  let userId = sessionStorage.getItem('UserId');
  const [selectedStatus, setSelectedStatus] = useState('');
  // const [userDetails,setUserDetailsSelect]=useState('')
  
  useEffect(() => {
    setIsLoading(true)
    // eslint-disable-line no-debugger
    userDetails = selectedData;
    console.log(userDetails);
    // Set the location details
    setUserDetails(userDetails);
  }, [selectedData]);

  useEffect(() => {
  //  getStates();
  //  getUserRoles();
    getTeams();
  }, []);

  // Method to open the update dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  /* Method to get the User details */
  const setUserDetails = (userDetails) => {
    let setValue =
      userDetails && userDetails.roleId + '|' + userDetails.roleName;
    setFirstName(userDetails?.firstName);
    setLastName(userDetails?.lastName);
    setEmail(userDetails?.email);
    setPhoneNumber(userDetails?.phoneNumber);
    setWorkPhoneNumber(userDetails?.workPhoneNumber);
    setRoleId(userDetails?.roleId);
    setRoles(setValue);
    setRoleName(userDetails?.roleName);
    setgender(userDetails?.gender);
    setTeamId(userDetails?.teamId);
    setAddressLine1(userDetails?.addressLine1);
    setAddressLine2(userDetails?.addressLine2);
    setState(userDetails?.state);
    setCity(userDetails?.city);
    setpostalCode(userDetails?.postalCode);
    setEntityType(userDetails?.entityType);
    setImageUrl(userDetails?.profileImage);
    setEditUserId(userDetails?.userId);

    setSelectedStatus(userDetails?.status);
    console.log(userDetails, 'userDetailsuserDetailsuserDetails');
    setTimeout(function(){

      setIsLoading(false);

    }, 1000);
    
  };

  const checkEmailExist = (email) => {
    // check the email is valid or not
    // const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let emailid = email.value.toLowerCase();
    // Now check the email is already exist or not
    ApiService.get(
      `${process.env.REACT_APP_API_END_POINT}users/checkEmailExists?email=${emailid}`
    )
      .then((res) => res)
      .then((res) => {
        if (res.statusCode === 200) {
          // Now check the response is true or false
          console.log(res);
          let emailExist = res.isExists;
          let msg = res.message;
          if (res && emailExist) {
            if (msg === 'Email already exists in Cognito') {
              setEmailHelperText('Email already exist');
            } else {
              setEmailHelperText(msg);
            }
          } else {
            // Now email is not exist
          }
        } else {
          // Now email is not exist
        }
      })
      .catch((err) => {
        console.log(err);
        // Now email is not exist
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'firstName') {
      setFirstName(e.target.value);
      // setFirstNameError(false);
      setIsFieldUpdated(true);
      setFirstNameHelperText('');
      // showForm(isFieldUpdated);
    }

    if (name === 'lastName') {
      setLastName(e.target.value);
      // setLastNameError(false);
      setIsFieldUpdated(true);
      setLastNameHelperText('');
      // showForm(isFieldUpdated);
    }

    if (name === 'email') {
      setEmail(e.target.value);
      // setEmailError(false);
      setIsFieldUpdated(true);
      // showForm(isFieldUpdated);
      setEmailHelperText('');
    }

    // if (name === 'phoneNumber') {
    //   let formatNumber = formatPhoneNumber(e.target.value);
    //   setPhoneNumber(formatNumber);
    //   // setPhoneNumberError(false);
    //   setIsFieldUpdated(true);
    //   showForm(isFieldUpdated);
    //   setPhoneNumberHelperText('');
    // }

    if (name === 'phoneNumber') {
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      setIsFieldUpdated(true);
      // showForm(isFieldUpdated);
      if (e && e.target.value) {
        let formatWorkPhoneNumber = formatPhoneNumber(e.target.value);
        setPhoneNumber(formatWorkPhoneNumber);
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          setPhoneNumberHelperText(
            'Please enter a valid phone number in format: xxx-xxx-xxxx'
          );
        } else {
          setPhoneNumberHelperText('');
        }
      } else {
        setPhoneNumberHelperText('Phone number is required');
        setPhoneNumber('');
      }
    }

    // if (name === 'workPhoneNumber') {
    //   let formatWorkPhoneNumber = formatPhoneNumber(e.target.value);
    //   setWorkPhoneNumber(formatWorkPhoneNumber);
    //   // setWorkPhoneNumberError(false);
    //   setIsFieldUpdated(true);
    //   showForm(isFieldUpdated);
    //   setWorkPhoneNumberHelperText('');
    // }
    if (name === 'workPhoneNumber') {
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      setIsFieldUpdated(true);
      // showForm(isFieldUpdated);
      if (e && e.target.value) {
        let formatWorkPhoneNumber = formatPhoneNumber(e.target.value);
        setWorkPhoneNumber(formatWorkPhoneNumber);
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          setWorkPhoneNumberHelperText(
            'Please enter a valid phone number in format: xxx-xxx-xxxx'
          );
        } else {
          setWorkPhoneNumberHelperText('');
        }
      } else {
        setWorkPhoneNumberHelperText('');
        setWorkPhoneNumber('');
      }
    }

    // if (name === 'roleId') {
    //   setRoleId(e.target.value);
    //   console.log(e.target.value);
    //   // setRoleIdError(false);
    //   setIsFieldUpdated(true);
    //   // showForm(isFieldUpdated);
    //   setRoleIdHelperText('');
    // }
    if (name === 'roleId') {
      if (value) {
        const [Id, Name] = value.split('|');
        console.log(value, Id, Name, 'rolessss');
        setRole(value);
        setRoles(value);
        setRoleId(Id);
        setRoleName(Name);
        setIsFieldUpdated(true);
        // setRole(e.target.value);
      }
    }
    if (name === 'teamId') {
      console.log(e.target.value);
      setTeamId(e.target.value);
      // setTeamError(false);
      setIsFieldUpdated(true);
      // showForm(isFieldUpdated);
      setTeamHelperText('');
    }
    // if (name === 'locationId') {
    //   setLocationId(e.target.value);
    //   setIsFieldUpdated(true);
    //   // showForm(isFieldUpdated);
    //   // setLocationName(e.target.value);
    //   // setLocationError(false);
    //   setLocationHelperText('');
    // }

    if (name === 'addressLine1') {
      setAddressLine1(e.target.value);
      setIsFieldUpdated(true);
      // showForm(isFieldUpdated);
      // setAddressLine1Error(false);
      setAddressLine1HelperText('');
    }

    if (name === 'addressLine2') {
      setAddressLine2(e.target.value);
      setIsFieldUpdated(true);
      // showForm(isFieldUpdated);
      // setAddressLine2Error(false);
      // setAddressLine2HelperText('');
    }
    if (name === 'gender') {
      setgender(e.target.value);
      // setLocationError(false);
      setgenderHelperText('');
      setIsFieldUpdated(true);
    }

    if (name === 'state') {
      // getCityList(e.target.value);
      setIsFieldUpdated(true);
      // showForm(isFieldUpdated);
      setState(e.target.value);
      // setStateError(false);
      setStateHelperText('');
    }

    if (name === 'city') {
      setCity(e.target.value);
      setIsFieldUpdated(true);
      // showForm(isFieldUpdated);
      // setCityError(false);
      setCityHelperText('');
    }

    if (name === 'postalCode') {
      const validateZip = /^[0-9]{5}(?:-[0-9]{4})?$/;
      // setpostalCode(e.target.value);
      // setpostalCodeError(false);
      //  setpostalCodeHelperText('');
      //  setisFieldUpdated(true);
      if (!validateZip.test(e.target.value)) {
        setpostalCodeHelperText('Invalid zip code');
        setpostalCode(e.target.value);
        setIsFieldUpdated(true);
      } else {
        setpostalCodeHelperText('');
        setpostalCode(e.target.value);
        setIsFieldUpdated(true);
      }
    }

    if (name === 'status') {
      setSelectedStatus(e.target.value);
      setIsFieldUpdated(true);
    }
  };
  // showForm(isFieldUpdated);

  /* Function to upload image */
  const handleProfileImageChange = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();
      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg')
      ) {
        setIsFieldUpdated(true);
        setImageUrl(URL.createObjectURL(file));
        setImageObj(file);
        setProfileImage(false);
      } else if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({ blob: file });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }
          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });
          setIsFieldUpdated(true);
          setImageUrl(URL.createObjectURL(jpegFile));
          setImageObj(jpegFile);
          setProfileImage(false);
        } catch (error) {
          console.error('Error handling HEIC file:', error);
          setProfileImage(true);
          setProfileImageError('Error handling HEIC file.');
        }
      } else {
        setProfileImage(true);
        setProfileImageError(
          'Please upload only png, jpeg, jpg, or heic image formats'
        );
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let emailValid = true;
    let phoneValid = true;
    let zipCodeValid = true;
    setIsUpdated(false);
    if (!firstName) {
      // setFirstNameError(true);
      setFirstNameHelperText('First name is required');
    }

    if (!lastName) {
      // setLastNameError(true);
      setLastNameHelperText('Last name is required');
    }

    if (!email) {
      // setEmailError(true);
      setEmailHelperText('Email is required');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      // setEmailError(true);
      emailValid = false;
      setEmailHelperText('Please enter a valid email address');
      setIsFieldUpdated(true);
    }

    if (!phoneNumber) {
      // setPhoneNumberError(true);
      setPhoneNumberHelperText('Phone number is required');
    } else if (!/^\d{3}-\d{3}-\d{4}$/.test(phoneNumber)) {
      // setPhoneNumberError(true);
      phoneValid = false;
      setPhoneNumberHelperText(
        'Please enter a valid phone number in format: xxx-xxx-xxxx'
      );
    }

    if (workPhoneNumber && !/^\d{3}-\d{3}-\d{4}$/.test(workPhoneNumber)) {
      // setWorkPhoneNumberError(true);
      phoneValid = false;
      setWorkPhoneNumberHelperText(
        'Please enter a valid phone number in format: xxx-xxx-xxxx'
      );
    }

    if (!roles) {
      // setRoleIdError(true);
      setRoleIdHelperText('Role is required');
    }
    if (!gender) {
      setgenderHelperText('Gender is required');
    }

    if (!addressLine1) {
      // setAddressLine1Error(true);
      setAddressLine1HelperText('Address Line 1 is required');
    }

    if (!state) {
      // setStateError(true);
      setStateHelperText('State is required');
    }

    if (!city) {
      // setCityError(true);
      setCityHelperText('City is required');
    }

    if (!postalCode) {
      // setpostalCodeError(true);
      setpostalCodeHelperText('Zip Code is required');
    }
    // else if (postalCode && !/^(0|[1-9]\d*)$/.test(postalCode)) {
    //   // setpostalCodeError(true);
    //   zipCodeValid = false;
    //   setpostalCodeHelperText('Please enter a valid zipCode');
    // }
    setProfileImageError('');
    // if (imgObj.length === 0) {
    //   setProfileImage(true);
    // }
    if (
      phoneValid &&
      emailValid &&
      zipCodeValid &&
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      phoneNumber !== '' &&
      roleId !== '' &&
      gender !== '' &&
      addressLine1 !== '' &&
      city !== '' &&
      state !== '' &&
      postalCode !== ''
    ) {
      // createUser();
      // updateUser()
      if (isFieldUpdated) {
        handleClickOpen();
      }
    }

    // Do something with form data
  };
  console.log(role);

  const updateUser = async () => {
    setFinalApiErr(false);
    setFinalApiErrMsg('');

    // Now show the loader
    setIsLoading(true);

    let formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email?.toLowerCase());
    formData.append('phoneNumber', phoneNumber?.toString());
    // if (workPhoneNumber)
    formData.append('workPhoneNumber', workPhoneNumber?.toString());
    formData.append('roleId', roleId);
    formData.append('roleName', roleName);
    formData.append('gender', gender);
    formData.append('teamId', teamId);
    formData.append('addressLine1', addressLine1);
    formData.append('addressLine2', addressLine2);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('postalCode', postalCode?.toString());
    formData.append('country', 'USA');
    formData.append('entityType', entityType);
    if (imgObj.name) formData.append('profileImage', imgObj);
    // formData.append('createdBy', userId);
    // formData.append('isActive', true);
    formData.append('status', selectedStatus);
    formData.append('updatedBy', userId);

    ApiService.put(
      `${
        process.env.REACT_APP_API_END_POINT
      }users/updateUser?id=${encodeURIComponent(editUserId)}`,
      formData
    )
      .then((response) => response)
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Now close the parent modal to trigger the cross icon
          let crossElm = document.getElementsByClassName(
            'user-details-remove-icon'
            )[0];
          if (crossElm) crossElm.click();

          // Now set to all location data updated
          setIsUpdated(true);
          // Now show the loader
          setIsLoading(false);
          // Now open the confirmation dialog
          handleClickOpen();
          // set field updated to false
          setIsFieldUpdated(false);
          setSearchText('');
          refreshUserList();
        } else {
          let errmsg = data.response.message;
          setFinalApiErr(true);
          setFinalApiErrMsg(errmsg);
          setIsLoading(false);
        }
        setIsUserUpdate(!isUserUpdate)
      })
      .catch((error) => {
        console.error('Error:', error);
        let errmsg = error.message;
        setFinalApiErr(true);
        setFinalApiErrMsg(errmsg);
        setIsLoading(false);
      });
  };

  // const getStates = async () => {
  //   ApiService.get(`${process.env.REACT_APP_API_END_POINT}locations/getAllStates`)
  //     .then((response) => response)
  //     .then((data) => {
  //       if (data.statusCode && !data.error) {
  //         let Item = data.data.Items;
  //         SetStatesList(Item);
  //       }
  //     });
  // };

  // const getUserRoles = async () => {
  //   ApiService.get(
  //     `${process.env.REACT_APP_API_END_POINT}users/getFranchisorUserRoles`)
  //     .then((response) => response)
  //     .then((data) => {
  //       if (data.statusCode && !data.error) {
  //         let Item = data.data.Items;
  //         setUserRoles(Item);
  //       }
  //     });
  // };

  // const getCityList = async (selectedState) => {
  //   fetch(
  //     `${process.env.REACT_APP_API_END_POINT}locations/getAllCities?state=${selectedState}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //         'content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*'
  //       }
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.statusCode && !data.error) {
  //         let Item = data.data.Items;
  //         SetCityList(Item);
  //       }
  //     });
  // };

  // Method to get the teams
  const getTeams = async () => {
    // Now show the loader
    // setIsLoading(true);

    ApiService.get(`${process.env.REACT_APP_API_END_POINT}locations/getAllStates`)
      .then((response) => response)
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Set the list of location
          // setUserTeams('testdata');
          setUserTeams(data.data.Items);
          // Now hide the loader
          // setIsLoading(false);
        }
      });
  };

  // Function to close the edit location dialog box

  const handleEditUserClose = () => {
    if (profileUserData) {
      setIsEdit(false);
    } else if (isFieldUpdated) {
      setOpenConfirmPopup(true);
    } else {
      setShowScreen(0);

      setOpenConfirmPopup(false);
    }
  };

  const handleConfirmClose = (val) => {
    if (val === 'Save') {
      setOpen(false);

      setOpenConfirmPopup(false);
    } else if (val === 'Discard') {
      if (profileUserData) {
        setIsEdit(false);
      }
      setShowScreen(0);
      setOpen(false);
      setOpenConfirmPopup(false);
      setIsFieldUpdated(false);
      setUserDetails();
    }
  };

  // Method to close the update dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      updateUser();
    } else if (val === 'Discard') {
      setOpen(false);
      setShowScreen(0);
      setOpenConfirmPopup(false);
    }
  };

  // Method to get the teams value to populate the dropdown
  // const getTeamValue = () => {
  //   if (userTeams.length === 0) {
  //     return undefined;
  //   }
  //   const selectedTeam = userTeams.findIndex((value) => {
  //     return value.teamId === teamId;
  //   });
  //   return userTeams[selectedTeam];
  // };


  if (!userTeams.length) {
    return null;
  }

  return (
    <>
      <Loader IsLoading={isLoading} />

      <Box className="Main-container create-user-form edit-user-form  common-form-container">
        {/* FOrm Starts */}
        <form>
          <Grid mt={1}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box
                    onClick={() => handleEditUserClose()}
                    className="back-arrow-close"
                  >
                    <svg
                      width="27"
                      height="19"
                      viewBox="0 0 27 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                        fill="#003087"
                      />
                    </svg>
                  </Box>

                  <Typography className="header-blue-32">Edit user</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{ mt: { xs: 0, md: 0 } }}
                    variant="contained"
                    // id={userDetails.userId}
                    onClick={handleSubmit}
                    className="btn-primary edit-button"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {/* <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <h1 className="title mb-0 mt-10 primary-color">
                          Edit User
                        </h1>
                      </Box>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          marginTop: '20px'
                        }}
                      >
                        <Typography className="next-button-text">
                          Save
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid> */}
          </Grid>
          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="FIRST NAME"
                type="text"
                className="input-field-styling  required-field"
                value={firstName}
                onChange={handleInputChange}
                // error={firstNameError}
                helperText={firstNameHelperText}
                InputLabelProps={{
                  className: 'form-field-label  required-label'
                }}
                InputProps={{}}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="LAST NAME"
                type="text"
                className="input-field-styling required-field"
                value={lastName}
                onChange={handleInputChange}
                // error={lastNameError}
                helperText={lastNameHelperText}
                InputLabelProps={{
                  className: 'form-field-label  required-label'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="EMAIL"
                placeholder="test@gmail.com"
                value={email}
                className="input-field-styling required-field"
                // error={emailError}
                helperText={emailHelperText}
                // defaultValue={email}
                disabled
                onChange={handleInputChange}
                onKeyUp={(e) => checkEmailExist(e.target)}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                InputProps={{}}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label="PHONE NUMBER"
                type="text"
                value={phoneNumber}
                placeholder="777-777-7777"
                className="input-field-styling required-field"
                onChange={handleInputChange}
                // error={phoneNumberError}
                helperText={phoneNumberHelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                inputProps={{ maxLength: 12 }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="workPhoneNumber"
                name="workPhoneNumber"
                label="WORK PHONE NUMBER"
                value={workPhoneNumber}
                className="input-field-styling"
                type="text"
                placeholder="777-777-7777"
                onChange={handleInputChange}
                // error={workPhoneNumberError}
                helperText={workPhoneNumberHelperText}
                InputLabelProps={{
                  className: 'form-field-label '
                }}
                inputProps={{ maxLength: 12 }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  GENDER
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleInputChange}
                  value={gender}
                  name="gender"
                  label="gender"
                  className="input-field-styling"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                <FormHelperText className="field-error-text">
                  {genderHelperText}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              <Autocomplete
                classes={autoCompleteClasses}
                disabled
              //  value={getTeamValue()}
                onChange={(event, newValue) => {
                  if (newValue && newValue.teamId) {
                    handleInputChange({
                      target: {
                        name: 'teamId',
                        value: newValue.teamId ? newValue.teamId : ''
                      }
                    });
                  } else {
                    handleInputChange({
                      target: { name: 'teamId', value: '' }
                    });
                  }
                }}
                className="dropdown-field field-label-css status-field-lbl"
                options={userTeams || []}
                getOptionLabel={(option) => option.teamName}
                sx={{
                  width: '100%'
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params?.label || 'TEAM'}
                    // error={teamError}
                    helperText={teamHelperText}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl
                pt={2}
                fullWidth
                variant="outlined"
                sx={{
                  width: '100%'
                }}
                className="select-drop-down-label-styling  required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  ROLE
                </InputLabel>
                <Select
                  onChange={handleInputChange}
                  id="roleId"
                  name="roleId"
                  label="Role"
                  value={roles}
                  className="dropdown-field field-label-css "
                  // error={roleError}
                  // helperText={roleHelperText}
                >
                  <MenuItem value="">Select Role</MenuItem>
                  {userRoles?.map((statusOption, index) => (
                    <MenuItem
                      key={index}
                      id={statusOption.sortKey}
                      value={`${statusOption.sortKey}|${statusOption.role}`}
                    >
                      {statusOption.role}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="error-message">
                  {roleHelperText}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid container xs={4}>
              <Grid
                item
                sx={{
                  marginLeft: '10px'
                }}
              >
                <Avatar sx={{ height: '90px', width: '90px' }} src={imageUrl} />
              </Grid>
              <Grid
                item
                sx={{
                  marginLeft: '10px'
                }}
              >
                <span className="add-profile-text">Upload Profile Image</span>
                <Box>
                  <input
                    multiple
                    type="file"
                    id="select-file-edituser"
                    name="profileImage"
                    style={{ display: 'none' }}
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleProfileImageChange}
                  />
                  <label htmlFor="select-file-edituser">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload-image"
                    >
                      <Typography className="upload-text">Upload</Typography>
                    </Button>
                  </label>

                  {/* {profileimageError && (
                    // <FormHelperText className=" Mui-error">
                    //   Please select profile image
                    // </FormHelperText>
                  )} */}
                </Box>
              </Grid>
              {profileimageError && (
                <FormHelperText className="error-message">
                  {profileImageError}
                </FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="addressLine1"
                name="addressLine1"
                label="ADDRESS LINE 1"
                type="text"
                className="input-field-styling required-field"
                value={addressLine1}
                onChange={handleInputChange}
                // error={addressLine1Error}
                helperText={addressLine1HelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                InputProps={{}}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="addressLine2"
                name="addressLine2"
                label="ADDRESS LINE 2"
                className="input-field-styling"
                value={addressLine2}
                type="text"
                onChange={handleInputChange}
                InputLabelProps={{
                  className: 'form-field-label'
                }}
                InputProps={{}}
              />
            </Grid>

            <Grid item xs={4}>
              <Autocomplete
                value={state}
                classes={autoCompleteClasses}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: { name: 'state', value: newValue }
                  });
                }}
                className="dropdown-field field-label-css status-field-lbl required-field"
                options={statesList?.map((option) => option?.state || [])}
                sx={{
                  width: '100%'
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params?.label || 'STATE'}
                    // error={stateError}
                    helperText={stateHelperText}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                      // shrink: 'true'
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              {/* <Autocomplete
                      disablePortal
                      name="city"
                      value={city}
                      classes={autoCompleteClasses}
                      onChange={(event, newValue) => {
                        handleInputChange({
                          target: { name: 'city', value: newValue }
                        });
                      }}
                      className="dropdown-field field-label-css"
                      options={cityList.map((option) => option.city)}
                      sx={{
                        width: '100%'
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={params?.label || 'CITY'}
                          // error={cityError}
                          helperText={cityHelperText}
                        />
                      )}
                    /> */}
              <TextField
                fullWidth
                id="city"
                name="city"
                label="CITY"
                type="text"
                className="input-field-styling required-field"
                onChange={handleInputChange}
                value={city}
                helperText={cityHelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="postalCode"
                name="postalCode"
                label="ZIP CODE"
                className="input-field-styling required-field"
                value={postalCode}
                type="text"
                onChange={handleInputChange}
                // error={postalCodeError}
                helperText={postalCodeHelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                inputProps={{ maxLength: 5 }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                className="select-drop-down-label-styling"
                // pt={2}
                // variant="outlined"
                sx={{
                  width: '100%'
                }}
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling"
                >
                  STATUS
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="status"
                  name="status"
                  label="STATUS"
                  className="input-field-styling dropdown-field field-label-css"
                  value={selectedStatus}
                  onChange={handleInputChange}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
        {finalApiErr && (
          <FormHelperText className="Mui-error final-error">
            {finalApiErrMsg}
          </FormHelperText>
        )}
        {/* FOrm End */}
      </Box>
      {/* </DialogContentText>
         </DialogContent>
     </Dialog> */}
      {/* confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Save Changes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  please confirm that you want to save your changes?
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  User details has beenupdate successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => {
              if (isUpdated) {
                handleConfirmClose('Discard');
              } else {
                setOpen(false);
              }
            }}
          >
            {isUpdated ? 'Close' : 'Go Back to Editing'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* confirmation dialog */}
      <Dialog
        open={openConfirmPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub"> What do you want to do?</Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('Discard')}
          >
            Discard changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Go Back to editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserEditDetails;
