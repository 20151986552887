/* eslint-disable eqeqeq */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Checkbox
} from '@mui/material';
import { Stack, Avatar } from '@mui/material';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
// import { addTwoDecimalPlaces } from "../../utils/index";

export const InventoryTable = (props) => {
  const {
    tableRows,
    searchQuery,
    quantity,
    setQuantity,
    selectedRows,
    setSelectedRows,
    purchaseStatus,
    prevQuantity,
    unitCost,
    setUnitCost
  } = props;

  const [tablePagination, setTablePagination] = useState({
    page: 0,
    limit: 10
  });

  // To change the page into the table
  const handlePageChange = (event, newPage) => {
    setTablePagination({ ...tablePagination, page: newPage });
  };

  // To change the limit
  const handleLimitChange = (event) => {
    setTablePagination({
      ...tablePagination,
      limit: parseInt(event.target.value)
    });
  };

  // Apply paination on table
  const applyPagination = (tableRows) => {
    const page = tablePagination.page;
    const limit = tablePagination.limit;
    return tableRows.slice(page * limit, page * limit + limit);
  };

  // Apply search filter
  const applyFilters = (tableRows) => {
    let arrayOfMatchedObjects = [];
    if (searchQuery) {
      arrayOfMatchedObjects = tableRows.filter((object) => {
        return JSON.stringify(object)
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    } else {
      arrayOfMatchedObjects = tableRows;
    }
    return arrayOfMatchedObjects;
  };

  // Apply filter from object and below is the pagination order
  const filteredRows = applyFilters(tableRows);
  const paginatedRows = applyPagination(filteredRows);

  const handleUnitCost = (e, id) => {
    // let value = Number(e.target.value);

    let value = e.target.value;
    // let formattedCost = addTwoDecimalPlaces(value);
    // value = formattedCost;
    // value = value.toString();
    // Define a regular expression pattern to match the format
    // const pattern = /^\d{1,4}(\.\d{0,2})?$/;
    // const pattern = /^\d{0,4}(\.\d{0,2})?$/;
    // const isNumber = /^\d{0,4}(\.\d{0,2})?$/.test(value);
    // const pattern = /^[0-9]{1,4}(?:\.[0-9]{0,2})?$/;

    const pattern = /^\d{0,4}(\.\d{0,2})?$/;

    // Test the input value against the pattern
    if (pattern.test(value)) {
      // Valid input
      // console.log("value", value);
      if (value || value >= 0) {
        setUnitCost((prev) => ({
          ...prev,
          [id]: value
        }));
      }
    }
    // else {
    // Invalid input
    // console.log("value", value, "invalid");
    // validationMessage.textContent = 'Please enter a number with up to 4 digits before the decimal and up to 2 digits after the decimal.';
    // value = ''; // Clear the input value

    // }

    // if ((value || value >= 0) && value.length <= 4) {

    /* if (value <= max) {
      if (purchaseStatus == 'receive') {
        if (value <= prevQuantity[id]) {
          setUnitCost((prevQuantities) => ({
            ...prevQuantities,
            [id]: value
          }));
        }
      } else {
        setUnitCost((prevQuantities) => ({
          ...prevQuantities,
          [id]: value
        }));
      }
    } else {
      setUnitCost((prevQuantities) => ({
        ...prevQuantities,
        [id]: 1
      }));
    } */
  };

  const handleQuantity = (e, id, max) => {
    let value = Number(e.target.value);

    if (value <= max) {
      if (purchaseStatus == 'receive') {
        if (value <= prevQuantity[id]) {
          setQuantity((prevQuantities) => ({
            ...prevQuantities,
            [id]: value
          }));
        }
      } else {
        // check if current quantity is equal to 0 and row is already selected then set selected the row
        if (value > 0 && !isSelected(id)) {
          handleClick(e, id);
        }
        // if quantity is 0 then remove the selected row
        if (value === 0) {
          setSelectedRows((prevSelectedRows) => {
            let newSelected = [];
            newSelected = newSelected.concat(
              prevSelectedRows.filter((row) => row !== id)
            );
            return newSelected;
          });
        }
        setQuantity((prevQuantities) => ({
          ...prevQuantities,
          [id]: value
        }));
      }
    }
    // } else {
    //   setQuantity((prevQuantities) => ({
    //     ...prevQuantities,
    //     [id]: 1
    //   }));
    // }
  };

  const handleQuantityBtn = (event, type, id, max) => {
    event.stopPropagation();
    setQuantity((prevQuantities) => {
      const currentQuantity = prevQuantities[id];

      if (type === 'increment' && currentQuantity < max) {
        // check if current quantity is equal to 0 and row is already selected then set selected the row
        if (currentQuantity === 0 && !isSelected(id)) {
          handleClick(event, id);
        }

        // if (purchaseStatus == 'receive') {
        //   if (currentQuantity < prevQuantity[id]) {
        //     return {
        //       ...prevQuantities,
        //       [id]: currentQuantity + 1
        //     };
        //   }
        // } else {
        //   return {
        //     ...prevQuantities,
        //     [id]: currentQuantity + 1
        //   };
        // }
        return {
          ...prevQuantities,
          [id]: currentQuantity + 1
        };
      } else if (type === 'decrement' && currentQuantity > 0) {
        // if quantity is 0 then remove the selected row
        if (currentQuantity === 1 && purchaseStatus !== 'receive') {
          setSelectedRows((prevSelectedRows) => {
            let newSelected = [];
            newSelected = newSelected.concat(
              prevSelectedRows.filter((row) => row !== id)
            );
            return newSelected;
          });
        }
        return {
          ...prevQuantities,
          [id]: currentQuantity - 1
        };
      }
      return prevQuantities;
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredRows.map((n) => n.sortKey);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];
    console.log("selectedIndex", selectedIndex);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  console.log(selectedRows, " consolasdfasdfasdf")
  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  return (
    <div>
      <Card
        sx={{
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    disabled={purchaseStatus !== 'create'}
                    indeterminate={
                      selectedRows?.length > 0 &&
                      selectedRows?.length < filteredRows?.length
                    }
                    checked={
                      filteredRows?.length > 0 &&
                      selectedRows?.length === filteredRows?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all'
                    }}
                  />
                </TableCell>
                <TableCell> Image </TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>variant</TableCell>
                <TableCell>variant Type</TableCell>
                <TableCell>Total in Stock</TableCell>
                <TableCell>Unit Cost</TableCell>
                <TableCell>Qty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.map((row, index) => {
                // console.log("row", row);
                const isItemSelected = isSelected(row.sortKey);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    onClick={(event) =>
                      purchaseStatus == 'create' &&
                      handleClick(event, row.sortKey)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.sortKey}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                    className="table-row cursor-pointer"
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        disabled={purchaseStatus !== 'create'}
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId
                        }}
                      />
                    </TableCell>

                    <TableCell className="table-td table-td-avatar">
                      <Avatar
                        alt="icon"
                        src={
                          row?.media[0]?.media ||
                          '/static/images/logo/cardboardicon.png'
                        }
                        sx={{ marginRight: 2, float: 'left' }}
                        className="card-avatar-img"
                      />
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {/* {row.productName} */}
                        {row.fName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {/* {row?.category?.categoryName} */}
                        {row?.categoryName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {/* {row?.category?.categoryName} */}
                        {row?.variant?.value || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {/* {row?.category?.categoryName} */}
                        {row?.variant?.variantType || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {/* {row.productQTY} */}
                        {row?.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        {/* <AiFillMinusCircle
                          style={{
                            color: '#003087',
                            fontSize: '1.2rem',
                            cursor: 'pointer'
                          }}
                          onClick={(event) =>
                            handleQuantityBtn(
                              event,
                              'decrement',
                              row.id,
                              row.productQTY
                            )
                          }
                        /> */}
                        <input
                          type="text"
                          // min="0"
                          // maxLength="6"
                          // pattern="[0-9]+([,\.][0-9]+)?"
                          // pattern="/^-?\d+\.?\d*$/"
                          // pattern="\d+(\.\d*)?"
                          // step="1.00"
                          style={{
                            textAlign: 'center',
                            width: '85px',
                            borderRadius: '45px',
                            border: '1px solid #D0D0D0',
                            outline: 'none',
                            padding: '1px 0',
                            margin: '0 5px'
                          }}
                          value={unitCost[row.sortKey]}
                          onClick={(e) => e.stopPropagation()}
                          // onKeyPress={if(event.target.value.length == 4) return false;}
                          onChange={(e) => handleUnitCost(e, row.sortKey)}
                        />
                        {/* <AiFillPlusCircle
                          style={{
                            color: '#003087',
                            fontSize: '1.2rem',
                            cursor: 'pointer'
                          }}
                          onClick={(event) =>
                            handleQuantityBtn(
                              event,
                              'increment',
                              row.id,
                              row.productQTY
                            )
                          }
                        /> */}
                      </Stack>
                    </TableCell>
                    <TableCell className="table-td">
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        <AiFillMinusCircle
                          style={{
                            color: '#003087',
                            fontSize: '1.2rem',
                            cursor: 'pointer'
                          }}
                          onClick={(event) =>
                            handleQuantityBtn(
                              event,
                              'decrement',
                              row.sortKey,
                              999
                              // row.quantity
                            )
                          }
                        />
                        <input
                          type="number"
                          min="0"
                          style={{
                            textAlign: 'center',
                            width: '50px',
                            borderRadius: '45px',
                            border: '1px solid #D0D0D0',
                            outline: 'none',
                            padding: '1px 0',
                            margin: '0 5px'
                          }}
                          value={quantity[row.sortKey]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            // handleQuantity(e, row.sortKey, row.quantity)
                            handleQuantity(e, row.sortKey, 999)
                          }
                        />
                        <AiFillPlusCircle
                          style={{
                            color: '#003087',
                            fontSize: '1.2rem',
                            cursor: 'pointer'
                          }}
                          onClick={(event) =>
                            handleQuantityBtn(
                              event,
                              'increment',
                              row.sortKey,
                              999
                              // row.quantity
                            )
                          }
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            component="div"
            count={filteredRows?.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={tablePagination.page}
            rowsPerPage={tablePagination.limit}
            rowsPerPageOptions={[5, 10, 20]}
          />
        </Box>
      </Card>
    </div>
  );
};
