import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
// import { toast } from 'react-toastify';
import axios from 'axios';
import { CustomTable } from '../../../../components/CustomTable';
import CreateBundles from './CreateBundles';
import { getLocalData } from '../../../../utils';
import BundlesDetails from './BundlesDetails';
import BundleEdit from './BundleEdit';
import Loader from '../../../../components/Loader';
import { ApiService } from '../../../../services/ApiService';
import { BundlesFilter } from '../../../../components/Filters/BundlesFilter';
import { filtersQuery } from '../../../../utils/index';
import {
  ActiveStatus,
  InactiveStatus,
  ExpiredStatus
} from 'src/assets/SVG/SvgIcons';

import ErrorMessage from '../../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DrawBundlesTable = ({ setAddBundleModal, setOpenAddBundle, openAddBundleModal, openAddBundle, showScreen, setShowScreen }) => {
  const locationId = getLocalData('locationId');
  const navigate = useNavigate();
  useEffect(() => {
    // check if the user is logged in or not
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [DrawBundlesTable, navigate]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]); 
  const [openEditPage, setOpenEditPage] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [selectedLists, setSelectedLists] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const userId = sessionStorage.getItem('UserId');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [productData, setProductData] = useState('');
  const [pricingData, setPricingData] = useState('');

  let [filters, setFilters] = useState({
    status: 1,
    discount: ''
  });

  const handleOnClick = (record) => {
    let arr = [record[1]];
    let filterRecord = data?.filter((i) => arr.includes(i.sortKey));
    setSelectedData(filterRecord);

    if (record[2] === 'Detail') {
      setShowScreen(1);
    }
    if (record[2] === 'Edit') {
      setIsOpenEdit(true);
      setOpenEditPage(true);
    }
  };

  const getBundles = async () => {
    setLoading(true);
    // let encodedLocationId = encodeURIComponent(locationId);
    let queryString = await filtersQuery(filters);   
    let filtersParams = queryString ? `?${queryString}` : '';
    console.log("QueryString:", filtersParams);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}bundles${filtersParams}`       
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      setLoading(false);
      const maxItemsToShow = 2;
      const result = Item.map((obj) => {
        const {
          partitionKey,
          sortKey,
          bundleName,
          bundlePrice,
          discount,
          items,
          status
        } = obj;
        let pricingOptionProducts = '';
        if (items && items.length > 0) {
          const itemNames = items.map((item) => item.itemName);
          pricingOptionProducts = itemNames.slice(0, maxItemsToShow).join(', ');
      
          if (items.length > maxItemsToShow) {
            pricingOptionProducts += '...'; // Add an ellipse when there are more items
          }
        }
        return {
          partitionKey,
          sortKey,
          'Bundle Name': bundleName,
          'Pricing Option & Products':
          pricingOptionProducts,
          Discount: discount + '%',
          'Bundle Price': '$' + bundlePrice,
          Status:
            status === 1 ? (
              <ActiveStatus />
            ) : status === 3 ? (
              <ExpiredStatus />
            ) : (
              <InactiveStatus />
            )
        };
      });
      setData(Item);
      setTableData(result);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getBundles();
    console.log(filters);
  }, [filters]);
  // const viewBundle = () => {
  //     setShowScreen(1);
  //   };

  // method to handle bulk update
  const handleBulkAdd = async () => {
    console.log('handleBulkAdd');
    setIsUpdated(false);
    setOpen(true);
  };

  // Method to handle bulk update status final confirm
  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    setLoading(true);
    // show loader
    // setIsLoading(true);
    const data = {
      status: selectedStatus,
      updatedBy: userId,
      bundleIds: selectedLists
    };

    try {
      const encodedLocationId = encodeURIComponent(locationId);
      const response = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}bundles/updateBulkStatus?locationId=${encodedLocationId}`,
        data
      );

      if (!response.data?.error) {
        setLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage('All the selected bundles have been updated successfully!');
        // toast.success(response.data?.message || 'Bundles status updated');
        setSelectedStatus('');
        setSelectedLists([]);
        setIsUpdated(true);
        // setOpen(true);
        setOpen(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.data?.message || 'Something went wrong with update bundles status')

        // toast.error(
        //   response.data?.message ||
        //     'Something went wrong with update bundles status'
        // );
      }
      // show loader
      // setIsLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      // show loader
      // setIsLoading(false);
      // toast.error('Something went wrong, Try again!');
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!')
    }
  };

  useEffect(() =>{
    getProducts();
    getPricingOptions();
  }, [])

  const getPricingOptions = async () => {
    // let encodedLocationId = encodeURIComponent(locationId);
    try {
      // let response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}bundles/getAllServices?locationId=${encodedLocationId}`);
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}bundles/getAllPricingOptions?status=1`
      );
      setPricingData(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async () => {
    // let encodedLocationId = encodeURIComponent(locationId);
    try {
      // let response = await ApiService.get(`${process.env.REACT_APP_PRODUCT_API_END_POINT}products?locationId=${encodedLocationId}`);
      let response = await ApiService.get(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}products?sortType=true&status=1&isDiscontinued=false`
      );
      setProductData(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("Data before filtering:", data);
  console.log("Filters:", filters);


  // Method to handle close of dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getBundles();
        setIsUpdated(false);
      }
    }
  };

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
    getBundles();
  };

  return (
    <>
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <BundlesDetails
                getBundles={getBundles}
                selectedData={selectedData}
                setShowScreen={setShowScreen}
                openEditPage={openEditPage}
                setOpenEditPage={setOpenEditPage}
                isOpenEdit={isOpenEdit}
                setIsOpenEdit={setIsOpenEdit}
              />
            );
          default:
            return (
              <>
                <Loader IsLoading={loading} />
                {showErrorMessage &&
                  <ErrorMessage message={errorMessage} setShowMessage={setShowErrorMessage} />
                }

                {/* Added one more callback function to identify when close the popup */}
                {showSucessMessage &&
                  <SuccessMessage message={sucessMessage} setShowMessage={setSuccessMessage} closePopup={closePopup} />
                }
                {/* <Container maxWidth="100%">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  > */}
                {/* <Typography class="page-title header-blue-32" /> */}
                {/* <Stack spacing={2} direction="row">
                      <Button
                        variant="contained"
                        className="top-button-style"
                        onClick={AddBundle}
                      >
                        Add New
                      </Button>
                    </Stack> */}
                {/* </Box> */}
                <CustomTable
                  data={tableData}
                  handleOnClick={handleOnClick}
                  filterCompoenent={
                    <BundlesFilter
                      setFilters={setFilters}
                      filters={filters}
                    />
                  }
                  label="All Bundle"
                  selectedLists={selectedLists}
                  setSelectedLists={setSelectedLists}
                  open={open}
                  setOpen={setOpen}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  handleBulkAdd={handleBulkAdd}
                  btnLoading={btnLoading}
                />
                {openAddBundle ? (
                  <CreateBundles
                    setOpenAddBundle={setOpenAddBundle}
                    openAddBundleModal={openAddBundleModal}
                    setAddBundleModal={setAddBundleModal}
                    getBundles={getBundles}
                    setShowScreen={setShowScreen}
                    productData={productData}
                    pricingData={pricingData}
                  />
                ) : null}
                {/* </Container> */}
              </>
            );
        }
      })()}
      {isOpenEdit ? (
        <BundleEdit
          selectedData={selectedData}
          openEditPage={openEditPage}
          setOpenEditPage={setOpenEditPage}
          setIsOpenEdit={setIsOpenEdit}
          getBundles={getBundles}
          setShowScreen={setShowScreen}
        />
      ) : null}

      {/* Status Update Dialog confirmation */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                Are you sure you want to mark Activate/Deactivate,
                <Typography className="title-text-sub">
                all the selected items?
              </Typography>
              </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected bundles have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DrawBundlesTable;
