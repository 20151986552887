/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TemplateFilter from './TemplateFilter';
import clsx from 'clsx';
// import { CustomButton, CustomSelect } from 'src/components/Global';
import {
  // Tooltip,
  Button,
  // Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  // Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  TextField,
  Grid,
  Slide,
  Container,
  CardContent,
  Checkbox
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CustomButton, CustomSelect } from 'src/components/Global';

// const [showScreen, setShowScreen] = useState(0);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const TemplateTable = ({
  setShowScreen,
  showScreen,
  TemplateList,
  refreshLocationData,
  sideBarfilters,
  setSidebarFilters,
  selectedTemplateLists,
  handleBulkAdd,
  btnLoading,
  setSelectedTemplateLists,
  selectedStatus,
  setSelectedStatus
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [states, SetStates] = useState([]);

  const [searchText, setSearchText] = useState(''); // Created new state for search functionality
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keys, setKeys] = useState([]);
  console.log(keys);


  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

 
  function convertIsoToNormalDate(isoDate) {
    const normalDate = moment(isoDate).format('MM-DD-YYYY');
    return normalDate;
  }
  useEffect(() => {
    setPage(0);
  }, [TemplateList]);

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredTemplateLists = TemplateList?.filter((item) => {
    return Object.values(item).some((value) =>
      value ? value.toString().toLowerCase().includes(searchText) : ''
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
  const paginatedTemplateLists = filteredTemplateLists.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  console.log(paginatedTemplateLists, 'filteredTemplateLists');
  const showFilter = () => {
    if (states.length === 0) 
    setdisplayFilterForm(!displayFilterForm);
    console.log(displayFilterForm, 'displayFilterForm-------');
  };


  const theme = useTheme();

  console.log('TemplateList', TemplateList);
  useEffect(() => {
    let key =
    TemplateList && TemplateList.length > 1 && TemplateList[0]
        ? Object.keys(TemplateList[0])
        : '';
    if (key) setKeys(key);
    // setKeys(['name', 'age', 'profession']);
  }, [TemplateList]);

  const handleSelectAllTemplateItems = (event) => {
    setSelectedTemplateLists(
      event.target.checked
        ? TemplateList.map((templateItem) => templateItem.sortKey)
        : []
    );
  };

  const handleSelectOneTemplateItem = (event, templateItemId) => {
    event.stopPropagation();
    // event.preventDefault();
    if (!selectedTemplateLists.includes(templateItemId)) {
        setSelectedTemplateLists((prevSelected) => [
        ...prevSelected,
        templateItemId
      ]);
    } else {
        setSelectedTemplateLists((prevSelected) =>
        prevSelected.filter((id) => id !== templateItemId)
      );
    }
  };

 // method to capitalize first letter
 function capitalizeStatus(status) {
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
}

  const selectedSomeTemplateItems =
  selectedTemplateLists.length > 0 &&
  selectedTemplateLists.length < TemplateList.length;
  const selectedAllTemplateItems =
  selectedTemplateLists.length === TemplateList.length;
  return (
    <>
    
              <Container maxWidth="100%" className="main-container">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={0}
                >
            
                  <Grid xs={12} sx={{ padding: '0 1rem' }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Grid
                        item
                        sx={{
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                      >
                        <Button
                          onClick={showFilter}
                          variant={displayFilterForm ? 'contained' : 'outlined'}
                        >
                          Filters
                          <KeyboardArrowDownRoundedIcon
                            className={clsx(
                              !displayFilterForm && classes.closeY,
                              displayFilterForm && classes.openY
                            )}
                          />
                        </Button>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                        className="top-filter-cont"
                      >
                        <TextField
                          type="search"
                          className="Search-field"
                          label="Search"
                          InputProps={{
                            style: {},
                            endAdornment: <SearchIcon />
                          }}
                          onChange={handleSearchTextChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ padding: '0 1rem' }}>
                    {displayFilterForm === true ? (
                      <Grid item width={getFilterSize()}>
                        <TemplateFilter
                        TemplateList={TemplateList}
                          filters={sideBarfilters}
                          setFilters={setSidebarFilters}
                        />
                      </Grid>
                    ) : null}

                    <Grid
                      item
                      sx={{
                        width: `calc(100% - ${getFilterSize()}px)`
                      }}
                    >
                      <Card
                        sx={{
                          padding: '15px',
                          borderRadius: '19px 19px 0px 0px'
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                          >
                            <CardHeader
                              title="All Templates"
                              className="table-header-name"
                              sx={{ fontSize: '20px', color: '#003087' }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                            className="membership-status-container status-field-ddl"
                          >
                            <CustomSelect
                              fullWidth
                              name="selectedStatus"
                              disabled={!selectedTemplateLists?.length}
                              value={selectedStatus}
                              onChange={(type, value) =>
                                setSelectedStatus(value)
                              }
                              label="Select Status"
                              options={[
                                {
                                  label: 'Shared',
                                  value: 'Shared'
                                },
                                {
                                  label: 'Private',
                                  value: 'Private'
                                }
                              ]}
                            />
                            <CustomButton
                              variant="contained"
                              disabled={
                                selectedStatus === '' ||
                                !selectedTemplateLists?.length
                              }
                              onClick={handleBulkAdd}
                              loading={btnLoading}
                            >
                              Update
                            </CustomButton>
                          </Grid>
                        </Grid>
                        <TableContainer className="location-table-container">
                          <Table>
                            <TableHead>
                              <TableRow className="table-header">
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={selectedAllTemplateItems}
                                    indeterminate={selectedSomeTemplateItems}
                                    onChange={handleSelectAllTemplateItems}
                                  />
                                </TableCell>
                                <TableCell>Template Name</TableCell>
                                <TableCell>Channel</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Last Modified</TableCell>
                                <TableCell >Status</TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {paginatedTemplateLists.map((templateItem) => {
                                const isTemplateItemSelected =
                                selectedTemplateLists.includes(
                                    templateItem.sortKey
                                  );
                                return (
                                  <TableRow
                                    hover
                                    key={templateItem.sortKey}
                                    selected={isTemplateItemSelected}
                                    className="table-row cursor-pointer"
                                    
                                  >
                                     <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        checked={isTemplateItemSelected}
                                        onChange={(event) =>
                                            handleSelectOneTemplateItem(
                                            event,
                                            templateItem.sortKey
                                          )
                                        }
                                        value={isTemplateItemSelected}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="table-td -flex"
                                      sx={{ minWidth: '200px' }}
                                    >
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        sx={{ marginTop: 1 }}
                                        gutterBottom
                                      >
                                        {templateItem.TemplateName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography>
                                        {templateItem.TemplateType==='SMS'? 'Text' : capitalizeStatus(templateItem.TemplateType)}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                    <Typography>
                                        {convertIsoToNormalDate(templateItem.CreationDate)}
                                      </Typography>
                                    </TableCell>
                                   <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {typeof convertIsoToNormalDate(templateItem.LastModifiedDate) === "string" && convertIsoToNormalDate(templateItem.LastModifiedDate)}
                                      </Typography>
                                    </TableCell>
                                     <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {templateItem.status}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box p={2}>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={filteredTemplateLists.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
    </>
  );
};

TemplateTable.propTypes = {
    TemplateList: PropTypes.array.isRequired
};

TemplateTable.defaultProps = {
    TemplateList: []
};

export default TemplateTable;
