import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography ,FormHelperText, Grid} from "@mui/material";
import {AutoComplete} from '../../../components/Global';
import React from "react";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
 import { CustomDatePicker } from 'src/components/CustomDatePicker';
// import dayjs from 'dayjs';
 import moment from 'moment';
import {generateTimeOptions,
  // isValidTimeFormat,
 // isEndTimeGreaterOrEqual,
 // formatTimeOrDate,
// convertUTCToNormal,
 // convertTimeToUTC,

} from '../../../components/Schedules/Const';


const ScheduleCampaign = ({ formData, setFormData, errors }) => {

  // const dateFormat = (date, time) => {
  //   const dateObject = new Date(date);
  //   const year = dateObject.getFullYear();
  //   const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month because it's zero-indexed
  //   const day = dateObject.getDate().toString().padStart(2, "0");
  //   if (time) {
  //     const hours = dateObject.getHours().toString().padStart(2, "0");
  //     const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  //     const seconds = dateObject.getSeconds().toString().padStart(2, "0");
  //
  //     return `${hours}:${minutes}:${seconds}`;
  //   }
  //   return `${year}-${month}-${day}`;
  //
  // };

  

 
  const handleDateTimeChange = (type, newValue) => {

    if(type === 'startDate' && newValue){
      errors.startDate = '';     
    }
    if(type === 'endDate' && newValue){
      errors.endDate = '';     
    }
    if(type === 'startTime' && newValue){
      errors.startTime = '';     
    }
    if(type === 'endTime' && newValue){
      errors.endTime = '';     
    }
   
    setFormData({ ...formData, [type]: newValue });

   

  };
  console.log('inputString',formData)
//   function replaceHyphensWithSlashes(inputString) {
   
//     if (inputString && inputString?.includes('-')) {
//         return inputString?.replaceAll('-', '/');
//     } 
//         return null;
    
// }
  
  const handleOptionSelection = (newValue) => {
   
    
    errors.startDate = '';
    errors.startTime = '';
    errors.endDate = '';
    errors.endTime = '';
   // (formData.startDate) = null;
   formData.startTime = ''
   // formData.endDate = null;
    formData.endTime = '';
   
    setFormData({ ...formData, startDate: '' })
    setFormData({ ...formData, schedulingOptionType: newValue })
    
    if(errors['schedulingOptionType']){
      errors.schedulingOptionType = '';
    }
  };
  return (
    <>

      <Box className={"campaign-form"}>
        <Box className="schedule-campaign">

          <Box className={"form-radio  schedule-campaign-radios"}>
            {/*  Radio buttons  with label */}
            <Typography className="blue-heading">Choose when to send the campaign</Typography>
            <Box sx={{ width: "100%" }}>
              <FormControl className="custom-radio-group">
                <RadioGroup
                  defaultValue="0"
                  name="radio-buttons-group"
                 // onChange={(e) => setFormData({ ...formData, schedulingOptionType: e.target.value })}
                  onChange={(e) => handleOptionSelection(e.target.value)}
                  value={formData.schedulingOptionType}
                >
                  <Box className={"radio-item-grouped"}>
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Immediately"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Hourly"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="Weekly"
                      labelPlacement="end"
                    />

                  </Box>
                  <Box className={"radio-item-grouped"}>
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Once"

                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Daily"
                      labelPlacement="end"
                    />


                    <FormControlLabel
                      value={5}
                      control={<Radio />}
                      label="Monthly"
                      labelPlacement="end"
                    />
                  </Box>

                </RadioGroup>
              </FormControl>
              {errors.schedulingOptionType && (
                          <FormHelperText>
                           {errors.schedulingOptionType}
                          </FormHelperText>
                        )}
             </Box>
           </Box>
             {/*          date and time display */}
             {formData.schedulingOptionType !== "0" && 
                   <Box className="schedule-date-section">
                    <Grid container xs={12} sm={12} spacing={2}>
                      <Grid item  xs={2} sm={3}>
                      {/* <CustomDatePicker
                    label="FROM *"
                    name="startDate"
                    fullWidth
                    disablePast
                    inputFormat="MM/dd/yyyy"
                    value={replaceHyphensWithSlashes(formData?.startDate)}
                    onChange={handleDateTimeChange}          
                             error={!!formData?.startDate}
                    helperText={formData?.startDate &&
                      (formData?.startDate > formData?.endDate
                        ? 'Start date must be smaller than end date'
                        : 'Start date is required')}
                         /> */}
                          <CustomDatePicker
                            disablePast
                            label="Start Date"
                            name="startDate"
                            fieldValue={formData.startDate ? moment(formData.startDate).format("MM-DD-YYYY") :  null}
                            maxDate={formData.endDate ? new Date(moment(formData.endDate)?.format("MM-DD-YYYY")) : null}
                            onChange={(value) => handleDateTimeChange("startDate", value)}
                            error={!!errors.startDate}
                           // helperText={errors.startDate}
                          />
                        {errors.startDate && (
                              <FormHelperText>{errors.startDate}</FormHelperText>
                            )}
                   </Grid>
                     <Grid item xs={2} sm={3}>
                     <AutoComplete
                    fullWidth
                    freeSolo
                    disableClearable
                    label="Time"
                    name="startTime"
                  //  disabled
                    className='text-field-styling input-field-styling'
                    options={generateTimeOptions()}
                    value={formData.startTime}
                   // onChange={handleDateTimeChange}
                    onChange={handleDateTimeChange}
                    error={!!errors.startTime}
                    helperText={errors.startTime}

                        />                      
                     </Grid>
                    
          
               {formData.schedulingOptionType !== "1" ?
                <>            
               
                 <Grid item xs={2} sm={3}>
                         <CustomDatePicker
                            disablePast
                            label="End Date"
                            name="endDate"
                            fieldValue={formData.endDate ? moment(formData.endDate).format("MM-DD-YYYY") : null}
                            minDate={formData.startDate ? new Date(moment(formData.startDate)?.format("MM-DD-YYYY")) : null}
                            onChange={(value) => handleDateTimeChange("endDate", value)}
                            error={!!errors.endDate}
                           // helperText={errors.endDate}
                          
                          />
                          {errors.endDate && (<FormHelperText>{errors.endDate}</FormHelperText> )}
                 </Grid>
                
                {formData.schedulingOptionType === "2" && 
                   <Grid  item xs={2} sm={3}  >
                     <AutoComplete
                    fullWidth
                    freeSolo
                    disableClearable
                    label="Time"
                    name="endTime"
                  //  disabled
                    className='text-field-styling input-field-styling'
                    options={generateTimeOptions()}
                    value={formData.endTime}
                    onChange={handleDateTimeChange}
                    error={!!errors.endTime}
                    helperText={errors.endTime}

                        />
                       
                   </Grid>

                }
             
             
              </>
               :

                <Box />
               
                }
             </Grid>
          </Box>
        
          }


        </Box>

      </Box>
    </>
  );
};
export default ScheduleCampaign;