import React from 'react';
import {
  TextField,
  Grid
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


export const SearchInput = ({ setSearchQuery, label, className, classPropName }) => {
  return (
   
      <Grid
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '10px',
              marginBottom: '20px'
            }}
            className="top-filter-cont"
          >
            {/* Search box on table */}
            <TextField
              type="search"
              className={`Search-field textfield-search ${className}`}
              label={label || "Search"}
              InputProps={{ style: {}, className: classPropName, endAdornment: <SearchIcon /> }}
              onChange={(e)=>setSearchQuery(e.target.value)}
              onClick={()=>setSearchQuery('')}
            />
          </Grid>
  
  );
};
