import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';
// import GlobalData from '../../../global';
// import Avatar from '@mui/material/Avatar';
import Loader from '../../../components/Loader';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { ArrowLeft, DeleteIcon } from 'src/assets/SVG/SvgIcons';
import { ImageSlider } from 'src/components/Global';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ProductDetailsWrapper = styled(Container)(
  ({ theme }) => `
          margin-top: ${theme.spacing(2)};
  `
);

// LocationDetails page main method
const productDetails = ({
  data,
  getItemForEdit,
  setShowScreen,
  refreshProductData
}) => {
  const navigate = useNavigate();
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let productDetails = data;
  const [open, setOpen] = React.useState(false);
  // const [productData] = useState(productDetails);
  // Get the loggedIn user Id
  const userId = sessionStorage.getItem('UserId');
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [multipleImages, setMultipleImages] = useState([]);
  const [showDeleteProductSuccess, setShowDeleteProductSuccess] =
    useState(false);

  // Method to open the delete dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      // call the api to delete the location
      deleteProduct();
    }
    setOpen(false);
  };
  useEffect(() => {
    if (data) {
      let images = data?.media?.map((item) => item?.media);
      setMultipleImages(images);
    }
  }, [data]);

  // Method to open the edit dialog
  const handleClickEdit = (e) => {
    let crossElm = document.getElementsByClassName('details-remove-icon')[0];
    if (crossElm) crossElm.click();
    // navigate("/location-management");

    let target = e && e.target;
    let lid;
    if (target) lid = target.getAttribute('id');
    // let cls = 'edit_btn_' + lid;
    let cls = lid;
    // let elm = document.getElementsByClassName(cls)[0];
    // if (elm) elm.click();
    // console.log(elm, cls, 'elm');
    getItemForEdit(cls);
    setShowScreen(2);
  };

  // useeffect state starts here
  useEffect(() => {
    // if (id) setSearchParams({ Id: id });

    // check if the user is logged in or not
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [productDetails, navigate]);

  // Method to delete the location
  const deleteProduct = () => {
    let productId = productDetails.sortKey;
    if (productId) {
      // let encodedProductId = encodeURIComponent(productId);
      // Now show the loader
      setIsLoading(true);
      let body = {
        productId: productId,
        userId: userId
      };
      // call the api to delete the location
      // let url = `${process.env.REACT_APP_PRODUCT_API_END_POINT}products?productId=${encodedProductId}&userId=${userId}`;
      let url = `${process.env.REACT_APP_PRODUCT_API_END_POINT}products`;

      // execute the api
      fetch(`${url}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then((response) => {
          // console.log("response", response);
          return response.json();
        })
        .then((data) => {
          if (data) {
            // console.log("data", data);
            // navigate to the location list page
            // let crossElm = document.getElementsByClassName(
            //   'details-remove-icon'
            // )[0];
            // if (crossElm) crossElm.click();

            // Now hide the loader
            setIsLoading(false);
            // Now move on the list page
            setShowDeleteProductSuccess(true);
            // refresh the data
            refreshProductData();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          // set error message
          setShowError(true);
          setErrorMessage(error.message);
          // Now hide the loader
          setIsLoading(false);
        });
    }
  };

  // setShowScreen(0);

  // const settings = {
  //   prevArrow: (
  //     <img
  //       src='/static/images/logo/prev-icon.png'
  //       alt="Previous"
  //       style={{
  //         position: 'absolute',
  //         left: 0,
  //         top: '50%',
  //         transform: 'translateY(-50%)'
  //       }}
  //     />
  //   ),
  //   nextArrow: (
  //     <img
  //       src='/static/images/logo/next-icon.png'
  //       alt="Next"
  //       style={{
  //         position: 'absolute',
  //         left: 0,
  //         top: '50%',
  //         transform: 'translateY(-50%)'
  //       }}
  //     />
  //   )
  // };

  const handleCloseDetails = () => {
    setShowScreen(0);
  };

  return (
    <>
      {/* show loaded element */}
      <Loader IsLoading={isLoading} />

      <ProductDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                onClick={() => handleCloseDetails()}
                className="back-arrow-close"
              >
                {/* left arrow icon */}
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                Product Details
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                className="details-remove-icon no-hover"
                onClick={handleClickOpen}
              >
                {/* Delete Icon */}
                <DeleteIcon />
              </Button>
              {/* Edit Button */}
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                id={productDetails.sortKey}
                onClick={handleClickEdit}
                className="btn-primary edit-button"
              >
                Edit
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid container className="location-header-container">
          <Grid
            xs={8}
            sm={8}
            md={8}
            lg={8}
            className="page-header title-adjust"> */}

        {/* <Box sx={{display:'flex'}}>
                                <ArrowBackIcon
                                  className="back-arrow-style"
                                  style={{ margin: '-3px 2px',fontSize: '30px'}}
                                  onClick={() => handleCloseDetails()}
                                />
                               <h4 className="location-name" style={{marginTop:'-10px',marginLeft:'5px'}}>{productDetails.fName}</h4>
                    </Box> */}

        {/* {locationId ? <span className="location-id text-wrap">#{locationId}</span> : null} */}
        {/* <span className="location-image"> */}
        {/* <Avatar
                                      alt="icon"
                                      src={productDetails.productImage
                                          || '/static/images/logo/cardboardicon.png'}
                                      sx={{ marginLeft: 2 }}
                                      className='card-avatar-img'
                                  /> */}
        {/* </span>
          </Grid> */}
        {/* Delete icon and edit button */}
        {/* <Grid xs={4} sm={4} md={4} lg={4} className="align-right"> */}
        {/* remove icon */}
        {/* <Button className="details-remove-icon" onClick={handleClickOpen}>
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                  fill="#8098C3"
                />
              </svg>
            </Button> */}
        {/* Edit Button */}
        {/* <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              id={productDetails.productId}
              onClick={handleClickEdit}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid> */}
        <Box className="location-details-container" display={'flex'}>
          <Grid container xs={12} sm={5}>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={12} className="location-details-col">
                <Box className="product-details-img align-center relative">
                  {productDetails &&
                  productDetails.media &&
                  productDetails.media.length !== 0 ? (
                    productDetails.media.length > 1 ? (
                      <div>
                        {/* <Slider {...settings}>
                          {productDetails.productMedia.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                // display: 'flex',
                                // alignItems: 'center',
                                // ?justifyContent: 'center',
                                // height: '100%'
                              }}
                            >
                              <img
                                className=" product-img"
                                src={item.media}
                                alt="product-icon"
                              />
                            </div>
                          ))}
                        </Slider> */}

                        {/* const images = productDetails.productMedia.map((item) => item.media); */}
                        <ImageSlider images={multipleImages} />
                      </div>
                    ) : (
                      productDetails.media[0] && (
                        <img
                          className="absoloute product-img"
                          src={productDetails.media[0].media}
                          alt="product-icon"
                        />
                      )
                    )
                  ) : (
                    <img
                      className=" product-img no-media"
                      src="/static/images/products/ProductImage.png"
                      alt="product-icon"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} sm={7} className="product-right-cont">
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  ZOOM ROOM PRODUCT NAME{' '}
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} ml={5} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails.fName}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  MANUFACTURER PRODUCT NAME
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} ml={5} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails.mfgName}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  SKU CODE
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} ml={5} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails.sku}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  MANUFACTURER
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} ml={5} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails.manufacturer}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  CATEGORY
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} ml={5} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {/* {productDetails &&
                  productDetails.category &&
                  productDetails.category.length !== 0 ? (
                    <span>{productDetails.category.categoryName}</span>
                  ) : null} */}
                  {productDetails?.categoryName}
                </Box>
              </Grid>
            </Grid>

            {/* <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  MANUFACTURER URL
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={productDetails.mfgUrl}
                  >
                    {productDetails.mfgUrl}
                  </a>
                </Box>
              </Grid>
            </Grid> */}
            {productDetails?.variant?.variantType && (
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={3} className="location-details-col">
                  <Box className="location-details-label summary-heading font-16">
                    VARIANT TYPE
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  ml={5}
                  className="location-details-col"
                >
                  <Box className="location-details-value summary-value capitalize">
                    {productDetails &&
                    productDetails.variant &&
                    productDetails.variant.length !== 0 ? (
                      <span>{productDetails.variant.variantType}</span>
                    ) : null}
                    {/* <span>{productDetails.variant.VariantName}</span> */}
                  </Box>
                </Grid>
              </Grid>
            )}

            {productDetails?.variant?.value && (
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={3} className="location-details-col">
                  <Box className="location-details-label summary-heading font-16">
                    VARIANT
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  ml={5}
                  className="location-details-col"
                >
                  <Box className="location-details-value summary-value capitalize">
                    {productDetails &&
                    productDetails.variant &&
                    productDetails.variant.length !== 0 ? (
                      <span>{productDetails.variant.value}</span>
                    ) : null}
                    {/* <span>{productDetails.variant.VariantName}</span> */}
                  </Box>
                </Grid>
              </Grid>
            )}

            {/* <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  SUPPLIER
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails?.supplierName}
                </Box>
              </Grid>
            </Grid> */}
            {productDetails?.price && (
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={3} className="location-details-col">
                  <Box className="location-details-label summary-heading font-16">
                    MSRP
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  ml={5}
                  className="location-details-col"
                >
                  <Box className="location-details-value summary-value capitalize">
                    {productDetails.price && '$' + productDetails.price}
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  BEST SELLER
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} ml={5} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails?.isBestSeller ? 'Yes' : 'No'}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  REQUIRED
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} ml={5} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails?.isRequired ? 'Yes' : 'No'}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  TAX EXEMPT
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} ml={5} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails?.taxExempt ? 'Yes' : 'No'}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  DISCONTINUED
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} ml={5} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails.isDiscontinued ? 'Yes' : 'No'}
                </Box>
              </Grid>
            </Grid>

            {/* <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  REQUIRED
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails.isRequired ? 'Yes' : 'No'}
                </Box>
              </Grid>
            </Grid>

            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  BESTSELLER
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails.isBestSeller ? 'Yes' : 'No'}
                </Box>
              </Grid>
            </Grid>

            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  TAX EXEMPT
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails.taxExempt ? 'Yes' : 'No'}
                </Box>
              </Grid>
            </Grid> */}

            {productDetails?.productDescription && (
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={3} className="location-details-col">
                  <Box className="location-details-label summary-heading font-16">
                    DESCRIPTION
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  ml={5}
                  className="location-details-col"
                >
                  <Box className="location-details-value summary-value capitalize">
                    {productDetails.productDescription}
                  </Box>
                </Grid>
              </Grid>
            )}

            {productDetails?.sellingPoints && (
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={3} className="location-details-col">
                  <Box className="location-details-label summary-heading font-16">
                    SELLING POINT
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  ml={5}
                  className="location-details-col"
                >
                  <Box className="location-details-value summary-value capitalize">
                    {productDetails.sellingPoints}
                  </Box>
                </Grid>
              </Grid>
            )}

            {/* {productDetails.expiryDate && (
              <Grid container mt={3} className="location-details-row">
                <Grid item xs={12} sm={3} className="location-details-col">
                  <Box className="location-details-label summary-heading font-16">
                    EXPIRY DATE
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3} className="location-details-col">
                  <Box className="location-details-value summary-value capitalize">
                    {productDetails.expiryDate}
                  </Box>
                </Grid>
              </Grid>
            )}

            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-label summary-heading font-16">
                  STATUS
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} className="location-details-col">
                <Box className="location-details-value summary-value capitalize">
                  {productDetails.status === 1 && 'Active'}
                  {productDetails.status === 0 && 'Inactive'}
                  {productDetails.status === 2 && 'Deleted'}
                </Box>
              </Grid>
            </Grid> */}
          </Grid>
          {showError && (
            <Grid container mt={3} className="location-details-row">
              <Box className="error-message">{errorMessage}</Box>
            </Grid>
          )}
        </Box>
      </ProductDetailsWrapper>

      {/* {delete success popup} */}
      <Dialog
        open={showDeleteProductSuccess}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setShowDeleteProductSuccess(false);
          setShowScreen(0);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Congratulations!'}
         </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected product has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => {
              setShowDeleteProductSuccess(false);
              setShowScreen(0);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactviate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default productDetails;
