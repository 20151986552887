/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import {
 
  Grid,
  Slide,
  Box
  // Divider
} from '@mui/material';

import TemplateListAPI from './TemplateList';
import Footer from 'src/components/Footer';
import React, { useEffect, useState, useRef } from "react";
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import GlobalData from '../../../global';
// import AddLocation from './AddLocation';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function LocationList() {
  const navigate = useNavigate();
  const title = GlobalData[0].title;
  const childRef = useRef(null);

  const [showScreen, setShowScreen] = useState(0);
  useEffect(() => {

    let isLogin = sessionStorage.getItem("Login_Sucess");
    if (isLogin !== 'true') {
      navigate("/login");
    }

  }, []);
  const handleClose = () => {
    

  };

// Method to close the location form
  const closeLocationDialog = () => {
    console.log("dialog closed--add location");
    // trigger the parent method to refresh the data
     childRef.current.refreshLocationData();

    handleClose();
  }

  return (
    <>
      <Helmet>
        <title>Template | {title}</title>
      </Helmet>
      {/* maxWidth="lg" */}
      <Box  sx={{pl:"45px", pr: '22px'}} >
      {showScreen===0 &&<Header title="Template" sx={{marginX:"10px"}} />}
              </Box>
          <Grid item width={'100%'}>
            {/* Now draw the location table */}
            <TemplateListAPI ref={childRef} setShowScreen={setShowScreen} showScreen={showScreen} />
          </Grid>
      </>
            );
        }
      <Footer />


export default LocationList;
