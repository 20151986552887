import { React, useEffect, useState } from 'react';
import Header from '../../../../components/Header';
import GlobalData from 'src/global';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import DrawBundlesTable from './drawBundlesTable';
import { useNavigate } from 'react-router-dom';

const Bundles = () => {
  const navigate = useNavigate();
  const [showScreen, setShowScreen] = useState(0);
  const [openAddBundleModal, setAddBundleModal] = useState(false);
  const [openAddBundle, setOpenAddBundle] = useState(false);
  const title = GlobalData[0].title;
  console.log(showScreen, 'showScreenshowScreenshowScreen');
  useEffect(() => {
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [Bundles, navigate]);
  const handleButtonClick = () => {
    setOpenAddBundle(true);
    setAddBundleModal(true);
  };
  const buttons = [
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theam: true
    },

  ];

  return (
    <>
      <Box>
        <Helmet>
          <title>Bundle | {title}</title>
        </Helmet>
        <Box sx={{ paddingX: "30px" }} >
          {showScreen === 0 && <Header title="Bundles Management" buttons={buttons} />}
        </Box>
      </Box>
      <DrawBundlesTable
        setAddBundleModal={setAddBundleModal}
        openAddBundle={openAddBundle}
        openAddBundleModal={openAddBundleModal}
        setOpenAddBundle={setOpenAddBundle}
        showScreen={showScreen}
        setShowScreen={setShowScreen}
      />

    </>
  )
}
export default Bundles;