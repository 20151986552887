/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DeleteIcon } from 'src/assets/SVG/SvgIcons'; // ArrowLeft
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { getLocalData, setFullLocationId } from 'src/utils';
import Loader from '../../../components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ServiceDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

export const Summary = (props) => {
  const { serviceDetails, allPreReq, handleResetStates, handleOpenScreen, setShowScreen } =
    props;

  // const BASE_URL = 'https://vtqf4ke0yj.execute-api.us-east-1.amazonaws.com/dev';
  const serviceId = serviceDetails.sortKey;
  const locationId = setFullLocationId(getLocalData('locationId'));

  const [btnLoading, setBtnLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Get the loggedIn user Id
  const userId = sessionStorage.getItem('UserId');

  const getPreReqNames = (ids) => {
    const names = ids?.map((id) => {
      const match = allPreReq.find((item) => item.prerequisiteId == id.id);
      return match ? match.name : '';
    });

    return names?.join(', ');
  };

  const handleDeleteDialog = (type) => {
    if (type === 'cancel') {
      setDeleteDialog(false);
    } else {
      setDeleteDialog(false);
      deleteService();
    }
  };

  const handleClose = (val) => {
   if (val === 'message-close') {
    setOpenMessageDialog(false);
    setShowScreen(1);
    handleResetStates();
    }
  }

  const deleteService = async () => {
    setBtnLoading(true);
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}/business-entity/deleteBusinessEntityById`,
        {
          method: 'DELETE',
          headers: {
            Accept: '*/*',
            // 'content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
          },
          data: {
            locationId: locationId,
            id: serviceId,
            updatedBy: userId
          }
        }
      );
      console.log('delete response', response.data);
      if (!response.data?.error) {
        // toast.success(response.data?.message);
        setIsLoading(false);
        setOpenMessageDialog(true)
     //   handleResetStates();
      } else {
        // toast.error(
        //   response.data?.message || 'Something went wrong with delete Service'
        // );
      }

      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      toast.error('Something went wrong, Try again!');
    }
  };

  return (
    <div className="custom-summary">
      <Loader IsLoading={isLoading} />
      <ServiceDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        {/* <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          backgroundColor="#d1dced"
          height="50px"
          marginBottom="20px"
        >
          <div className="container summary-close-btn">
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={() => handleResetStates()}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </div>
        </Box> */}
        {/* <Grid container className="location-header-container">
          <Grid
            xs={8}
            sm={8}
            md={8}
            lg={8}
            className="page-header title-adjust"
          >
            <span className="location-name text-wrap">
              {serviceDetails.name}
            </span>
      
          </Grid>

          <Grid xs={4} sm={4} md={4} lg={4} className="align-right">
            <Button
              disabled={btnLoading}
              className="details-remove-icon"
              onClick={() => setDeleteDialog(true)}
            >
              <DeleteIcon />
            </Button>

            <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              onClick={() => handleOpenScreen(2)}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleResetStates()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {serviceDetails.businessName}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button
              disabled={btnLoading}
              className="details-remove-icon no-hover"
              onClick={() => setDeleteDialog(true)}
            >
              <DeleteIcon />
            </Button>

            <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              onClick={(e) => handleOpenScreen(serviceDetails.sortKey, 4, e)}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Box className="location-details-container" mt={5}>
          <Typography className="table-heade header-blue-20">
            Business Information
          </Typography>
          {/* <Grid container spacing={2} > */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                BUSINESS ENTITY TYPE
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.businessEntityType}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                BUSINESS EMAIL
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.businessEmail}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                BUSINESS PHONE
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.phone}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                BUSINESS URL
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.businessUrl}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                ADDRESS
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.addressLine1}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                ADDRESS 2
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.addressLine2}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                STATE
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.state}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">CITY</Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.city}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                ZIPCODE
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.postalCode}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="location-details-container" mt={5}>
          <Typography className="table-heade header-blue-20">
            Primary Contact Information
          </Typography>
          {/* <Grid container spacing={2} > */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                CONTACT NAME
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.contactName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                CONTACT EMAIL
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.contactEmail}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                CONTACT PHONE
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.contactPhone}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                NOTES
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {serviceDetails.notes}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ServiceDetailsWrapper>

      <Dialog
        open={deleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDeleteDialog('cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactviate this record?
               </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleDeleteDialog('delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleDeleteDialog('cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
          open={openMessageDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('message-close')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
          {'Congratulations!'}
         </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                  Selected business entity has been deactivate successfully!
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('message-close')}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
};
