import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  // useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer
} from '@mui/material';
import moment from 'moment';

export const PurchaseHistoryTable = (props) => {
  const { tableRows, searchQuery, openDetail } = props;
  // const theme = useTheme();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    setPage(0);
  }, [tableRows]);

  const dateFormate = (date) => {
    // let utc = moment(date).format('MMMM Do YYYY, h:mm:ss A');
    let utc;
    utc = date ? moment(date).format('MM-DD-YYYY') : '';
    return utc;
    // let dateFor = date && new Date(date);
    // // let utc = dateFor.toUTCString();
    // let finaldate = dateFor.toLocaleString();
    // return finaldate;
  };

  const numberFormate = (num) => {
    let value = num && num / 100;
    const formatted = Number(value).toLocaleString('en', options);
    // console.log(formatted);
    return formatted;
  };

  return (
    <div>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        {/* <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="table-heade header-blue-20">
              {' '}
              All Alerts
            </Typography>
          </Grid>
        </Grid> */}
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Date</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Payment Method</TableCell>
                <TableCell>Card Number</TableCell>
                <TableCell>Dog</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Discount(%)</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(paginatedData &&
                paginatedData.length > 0 &&
                paginatedData.map((row) => {
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      key={row.sortKey}
                      role="checkbox"
                      tabIndex={-1}
                      className="table-row cursor-pointer"
                      onClick={(e) => openDetail(row.sortKey, 3, e)}
                    >
                      {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId
                        }}
                        sx={{ color: '#003087' }}
                        onClick={(event) => handleClick(event, row.sortKey)}
                      />
                    </TableCell> */}
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          // sx={{ marginTop: 1 }}
                          gutterBottom
                          noWrap
                        >
                          {row?.orderDate
                            ? dateFormate(row.orderDate)
                            : row?.dateOfPurchase &&
                              dateFormate(row.dateOfPurchase)}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.sqaureOrderState}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.paymentSourceType}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.cardNumber}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.petName}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {(row?.paymentAmount &&
                            '$' + numberFormate(row.paymentAmount)) ||
                            '$0.00'}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {(row?.totalDiscount && row.totalDiscount + '%') || 0}
                        </Typography>
                      </TableCell>

                      {/* <TableCell className="table-td">
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        className="edit-btn"
                        id={'edit_btn_' + row.sortKey}
                        onClick={(e) => openDetail(row.sortKey, 2, e)}
                      >
                        Edit
                      </IconButton>
                    </TableCell> */}
                    </TableRow>
                  );
                })) || (
                <TableRow>
                  <TableCell colSpan={6} className="table-td">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ marginTop: 1 }}
                      gutterBottom
                      noWrap
                    >
                      No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
