/* eslint-disable no-lonely-if */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import {
  Box,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Select,
  FormHelperText,
  MenuItem,
  Typography,
  Grid,
  Avatar,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { formatTaxId, formatPhoneNumber } from '../../../utils/index';
import moment from 'moment';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';
import heic2any from 'heic2any';
// import { HeaderContext } from "src/contexts/HeaderContext";

// import { CustomSelect } from 'src/components/Global';

const FranchiseeEdit = ({
  data,
  refreshFranchiseeData,
  Transition,
  rolesList,
  statesList,
  setShowScreen
}) => {
  const navigate = useNavigate();
  let franchiseeItemId;
  let franchiseeDetails = data;

  // hook
  useEffect(() => {
    franchiseeItemId = franchiseeDetails.franchiseeId;
    // console.log("franchiseeItemId", franchiseeItemId)

    // call the get franchisee details method
    if (franchiseeItemId) getFranchiseeDetails(franchiseeItemId);

    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }

    // Now get the manager list
    getManagerList();
  }, []);

  // statesList = [];
  // console.log(data, 'dataaaaaaaaaaaa');

  let userId = sessionStorage.getItem('UserId');
  const [activeStep, setActiveStep] = useState(0);
  const [imageUrl, setImageUrl] = useState('');

  // show loader when login button is clicked
  const [mud, setMud] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isStepOneValid, setStepOneValid] = useState(false);
  const [isStepTwoValid, setStepTwoValid] = useState(false);
  const [imgObj, setImageObj] = useState([]);
  const [roleErroruser, setRoleErroruser] = useState(false);
  const [zoomRoomEmail, setZoomRoomEmail] = useState('');
  const [franchiseeId, setFranchiseeId] = useState('');
  const [locationId, setLocationId] = useState('');
  const [updateUserId, setUserId] = useState('');
  const [entityType, setEntityType] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');
  const [assignManagerName, setAssignManagerName] = useState('');
  const [assignManagerId, setAssignManagerId] = useState('');
  const [isPrimaryLocation, setIsPrimaryLocation] = useState(false);
  // const [cognitoId, setCognitoId] = useState('');
  const [ZoomRoomEmailError, setZoomRoomEmailError] = useState(false);
  const [roleuser, setroleuser] = useState('');
  // const [createdBy] = useState('');
  // const [createdAt, setCreatedAt] = useState('');
  // const [updatedAt, setUpdatedAt] = useState('');
  // console.log(imgObj, updatedBy);
  console.log(updatedBy);
  // show error message
  // const [showError, setShowError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');

  // console.log(showError, errorMessage);
  const [managerList, SetManagerList] = useState([]);

  /* User part ends */

  // Get the manager list from the api
  const getManagerList = async () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}users/getManagerList`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          SetManagerList(Item);
        }
      });
  };
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Edit Franchisee');
  // let formatPhone;
  // const [imgName, setImageName] = useState('');
  const steps = ['Edit Franchise', 'Edit Owners(s)'];

  // Function to move to create user form page
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  // Function to go back to create location form page for editing of form
  const handlePrev = () => {
    setActiveStep(activeStep - 1);
    // setstartDate('');
    // setEndDate('');
    // setEndDateError("");
  };
  const roleValueUser = (role) => {
    setRoleErroruser(false);
    setroleuser(role);
    setStepTwoValid(true);
    setanyFieldUpdated(true);
  };

  /* States for getting values from input fields of create location form as follows */
  const [franchiseeName, setfranchiseeName] = useState('');
  // const [brandName, setbrandName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [stateVal, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [location, setLocation] = useState('');
  const [assignManager, setassignManager] = useState([]);
  const [taxGrouping, setTaxGrouping] = useState(false);
  const [taxId, settaxId] = useState('');
  // const [open, setOpen] = React.useState(true);
  const [openDialog, setopenDialog] = useState(false);
  const [isCreated, setisCreated] = useState(false);
  const [isEmailExist, setisEmailExist] = useState(false);
  // const [formattedPhone, setFormattedPhone] = useState('');

  // State that is to track whether the any of the form field is updated or not
  const [anyFieldUpdated, setanyFieldUpdated] = useState(false);

  /* States for showing error messages for create location data to user */

  const [franchiseeNameError, setfranchiseeNameError] = useState(false);
  // const [brandNameError, setbrandNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [address2Error] = useState(false); // , setAddress2Error
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [locationError, setLocationError] = useState('');
  const [assignManagerError, setassignManagerError] = useState(false);
  //  const [taxGroupingError, settaxGroupingError] = useState(false);
  const [taxIdError, settaxIdError] = useState(false);
  // Prodile image error text
  const [profileImageError, setProfileImageError] = useState('');

  /* User part start */

  /* States for setting the values of form fields of the create user  */
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [emailuser, setEmailuser] = useState('');

  const [phoneNumber, setphoneNumber] = useState('');
  const [workphoneNumber, setworkphoneNumber] = useState('');
  // const [role, setRole] = useState("");
  const [addressuser, setAddressuser] = useState('');
  const [address2user, setAddress2user] = useState('');
  const [cityuser, setCityuser] = useState('');
  const [stateuser, setStateuser] = useState('');
  const [zipCodeUser, setzipCodeUser] = useState('');

  /* States for setting the validation error for create user form fields */

  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [emailErroruser, setemailErroruser] = useState(false);
  const [phoneNumberError, setphoneNumberError] = useState(false);
  const [workphoneNumberError, setworkphoneNumberError] = useState(false);
  // const [roleError, setRoleError] = useState(false);
  const [profileimageError, setProfileImage] = useState(false);
  const [addressErroruser, setAddressErroruser] = useState(false);
  const [cityErroruser, setCityErroruser] = useState(false);
  const [stateErroruser, setStateErroruser] = useState(false);
  const [zipCodeErroruser, setzipCodeErroruser] = useState(false);
  const [finalApiErr, setFinalApiErr] = useState(false);
  const [finalApiErrMsg, setFinalApiErrMsg] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [openConfirmPopUp, setOpenConfirmPopup] = useState(false);
  /* User part ends */

  /* Function binded on close button of create-franchisee/user form dialog */
  const handleClose = () => {
    if (anyFieldUpdated) {
      setopenDialog(true);
    } else {
      // setOpen(false);
      // showForm(false);
      setShowScreen(0);
      clear();
    }
  };
  const validateZoomRoomEmailUser = (Email) => {
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!Email) {
      setZoomRoomEmail('');
      setZoomRoomEmailError('Zoom room email is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else if (!validateEmail.test(Email)) {
      setZoomRoomEmailError('Zoom room email is invalid');
      setStepTwoValid(false);
      setanyFieldUpdated(true);
    } else {
      setZoomRoomEmailError('');
      setZoomRoomEmail(Email);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };
  // Method to get the franchisee details
  const getFranchiseeDetails = (franchiseeId) => {
    // Now show the loader
    console.log(franchiseeId, 'franchiseeeeeeeeiddddddd');
    setIsLoading(true);
    // call the api to get the location details
    let url = `${
      process.env.REACT_APP_API_END_POINT
    }franchisees/getFranchiseDetail?id=${encodeURIComponent(franchiseeId)}`;
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        // console.log("response", response);
        return response.json();
      })
      .then((data) => {
        if (data) {
          // set the location details
          let items = data.data.Items;
          let detailsObj;
          if (items) detailsObj = items;
          console.log(detailsObj, 'detailsss obj');
          // SetLocationDetails(locationDetails);

          // set the length
          // setLength(items.length);
          // console.log("franchiseeDetails", detailsObj);
          // set the location details
          if (detailsObj) setLocationDetails(detailsObj);
          // Now hide the loader
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // set error message
        // setShowError(true);
        // setErrorMessage(error.message);
        // Now hide the loader
        setIsLoading(false);
      });
  };
  const SetMud = (val) => {
    setMud(val);
    setStepOneValid(true);
    setanyFieldUpdated(true);
  };

  // Method to set the value of the location and user details
  const setLocationDetails = (locationDetails) => {
    console.log(locationDetails, '---Location details----');
    // set the location details
    if (locationDetails) {
      // set the location details
      setUpdatedBy(locationDetails.updatedBy);
      // setUpdatedAt(locationDetails.updatedAt);

      setfranchiseeName(locationDetails.franchiseeName);
      // setbrandName(locationDetails.brandName);
      // setPhone(locationDetails.phone)
      setPhone(locationDetails.phone);
      setEmail(locationDetails.email);
      setstartDate(locationDetails.faSigningDate);
      setEndDate(locationDetails.faExpirationDate);
      setSelectedStatus(locationDetails.status);
      setAddress(locationDetails.addressLine1);
      setAddress2(locationDetails.addressLine2);
      setCity(locationDetails.city);
      setState(locationDetails.state);
      setZipcode(locationDetails.postalCode);
      // setLocation(
      //   locationDetails.locations && locationDetails.locations.locationName
      // );
      // setassignManager(
      //   locationDetails.locations &&
      //   locationDetails.locations.managerId +
      //   '|' +
      //   locationDetails.locations.managerName
      // );
      // setAssignManagerId(
      //   locationDetails.locations && locationDetails.locations.managerId
      // );
      // setAssignManagerName(
      //   locationDetails.locations && locationDetails.locations.managerName
      // );
      // setTaxGrouping(
      //   locationDetails.locations && locationDetails.locations.taxGrouping
      // );
      setMud(locationDetails.mud);

      settaxId(locationDetails.fein);
      setFranchiseeId(locationDetails.franchiseeId);
      // setLocationId(
      //   locationDetails.locations && locationDetails.locations.locationId
      // );
      // setIsPrimaryLocation(
      //   locationDetails.locations && locationDetails.locations.isPrimaryLocation
      // );
      // setSelectedStatus(
      //   locationDetails.locations && locationDetails.status
      // );

      // setEntityType(locationDetails.users && locationDetails.users.entityType)
      // set the user details
      console.log(locationDetails.owners[0], 'locationDetails.owners');
      if (locationDetails.owners[0]) {
        setfirstName(
          locationDetails.owners[0] && locationDetails.owners[0].firstName
        );
        setlastName(
          locationDetails.owners[0] && locationDetails.owners[0].lastName
        );
        setEmailuser(
          locationDetails.owners[0] && locationDetails.owners[0].email
        );
        setphoneNumber(
          locationDetails.owners[0] && locationDetails.owners[0].phoneNumber
        );
        setworkphoneNumber(
          locationDetails.owners[0] && locationDetails.owners[0].phoneNumber
        );
        setZoomRoomEmail(
          locationDetails.owners[0] && locationDetails.owners[0].zoomRoomEmail
        );
        // setRole((locationDetails.users && locationDetails.users.roleId));
        setAddressuser(
          locationDetails.owners[0] && locationDetails.owners[0].addressLine1
        );
        setroleuser(
          locationDetails.owners[0] &&
            locationDetails.owners[0].involvementLevel
        );
        setAddress2user(
          locationDetails.owners[0] && locationDetails.owners[0].addressLine2
        );
        setCityuser(
          locationDetails.owners[0] && locationDetails.owners[0].city
        );
        setStateuser(
          locationDetails.owners[0] && locationDetails.owners[0].state
        );
        setzipCodeUser(
          locationDetails.owners[0] && locationDetails.owners[0].postalCode
        );
        setUserId(
          locationDetails.owners[0] && locationDetails.owners[0].sortKey
        );
        setImageUrl(
          locationDetails.owners[0] && locationDetails.owners[0].profileImage
        );
        setImageObj(
          locationDetails.owners[0] && locationDetails.owners[0].profileImage
        );

        //  setCreatedBy((locationDetails.users && locationDetails.users.createdBy));
        // setCreatedAt((locationDetails.users && locationDetails.users.createdAt));
        // setCognitoId((locationDetails.users && locationDetails.users.cognitoId));
      }
    }
  };

  /* Function for confirmation modal pop up(close,discard,continue) */
  const handleCloseConfirm = (value) => {
    /* if (value === "Discard") {
            setopenDialog(false);
            setOpen(true);
            showForm(true);
        }
        else if (value === "Close") {
            setopenDialog(false);
            setOpen(false);
            showForm(false);
            clear();
        }
        else if (value === "Continue") {
            setopenDialog(false);
            setOpen(false);
            showForm(false);
            clear();
        } */
    if (value === 'Discard') {
      setopenDialog(false);
      setShowScreen(0);
      // setOpen(false);
      // showForm(false);
      clear();
    } else if (value === 'Close') {
      setopenDialog(false);
      setShowScreen(0);
      // setOpen(false);
      // showForm(false);
      clear();
    } else if (value === 'Continue') {
      setopenDialog(false);
      // setOpen(true);
      // showForm(true);
    }
  };

  /* Function to upload image */
  const handleFileInput = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg')
      ) {
        setImageUrl(URL.createObjectURL(file));
        setImageObj(file);
        setProfileImage(false);
      } else if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({ blob: file });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }

          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });

          setImageUrl(URL.createObjectURL(jpegFile));
          setImageObj(jpegFile);
          setProfileImage(false);
        } catch (error) {
          console.error('Error handling HEIC file:', error);
          setProfileImage(true);
          setImageUrl('');
          setImageObj([]);
          setProfileImageError('Error handling HEIC file.');
        }
      } else {
        setProfileImage(true);
        setImageUrl('');
        setImageObj([]);
        setProfileImageError(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  /* Function to clear form fields when dialog form is closed */
  const clear = () => {
    setfranchiseeName('');
    // setbrandName('');
    setPhone('');
    setEmail('');
    setstartDate('');
    setEndDate('');
    setAddress('');
    setAddress2('');
    setCity('');
    setState('');
    setZipcode('');
    setLocation('');
    setassignManager('');
    settaxId('');
    setAssignManagerId('');
    setAssignManagerName('');
  };

  /* Franchisee part this are the function for validation of data for create franchisee  */

  /* Function to validate the franchisee name */
  const franchiseeValidate = (franchiseeName) => {
    if (!franchiseeName) {
      setfranchiseeName('');
      setfranchiseeNameError('Franchisee name is required');
      // formValid = false;
      setStepOneValid(false);
      setanyFieldUpdated(false);
    } else {
      setfranchiseeNameError('');
      setfranchiseeName(franchiseeName);
      setStepOneValid(true);
      setanyFieldUpdated(true);
      // formValid = true;
    }
  };

  /* Function  for validation of brand name */

  // const brandnameValidate = (brandname) => {
  //   if (!brandname) {
  //     setbrandName('');
  //     setbrandNameError('Brand name is required');
  //     // formValid = false;
  //     setStepOneValid(false);
  //     setanyFieldUpdated(false);
  //   } else {
  //     setbrandNameError('');
  //     setbrandName(brandname);
  //     // formValid = true;
  //     setStepOneValid(true);
  //     setanyFieldUpdated(true);
  //   }
  // };

  /* Function for validation of phone  number  */
  /* Function for validation of phone  number  */
  const validatePhone = (phone) => {
    // if (!phone) {
    //     setPhone('');
    //     setPhoneError("Phone number is required");
    //     setanyFieldUpdated(false);
    // }
    // else {
    //     let phoneFormatted = formatPhoneNumber(phone);
    //     setPhone(phoneFormatted);
    //     setPhoneError("");
    //     // formValid = true;
    //     setStepOneValid(true);
    //     setanyFieldUpdated(true);
    // }
    const validation = /^\d{3}-\d{3}-\d{4}$/;
    setanyFieldUpdated(true);
    if (phone) {
      let formatWorkPhoneNumber = formatPhoneNumber(phone);
      setPhone(formatWorkPhoneNumber);
      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        setPhoneError(
          'Please enter a valid phone number in format: xxx-xxx-xxxx'
        );
      } else {
        setPhoneError('');
        setStepTwoValid(true);
      }
    } else {
      // setPhoneError('Phone number is required');
      setPhoneError('');

      setPhone('');
    }
  };

  /* const updatePhone = (phone) => {
         // e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,10)
         let value = Math.max(0, parseInt(phone)).toString().slice(0,10);
         if(value.length > 10) return;
 
         let val = formatPhoneNumber(value);
         console.log("phone", value, val);
         setPhone(val);
         setPhoneError("");
         setanyFieldUpdated(true);
     } */

  /*
        const formatPhoneNumber = (phoneNumberString) => {
            let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return match[1] + '-' + match[2] + '-' + match[3];
            }
            return null;
        } */

  // {
  /* const Phoneformat = (phone) => {
      // validate phone number and format it as needed
      const regex = /^\d{3}-\d{3}-\d{4}$/;
      const formatted = phone.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');

      if (regex.test(formatted)) {
          
          setPhone(formatted);
          setFormattedPhone(formatted);
          setPhoneError('');
      } else {
          setPhone(phone);
          setFormattedPhone('');
          setPhoneError('Please enter a valid phone number.');
      }
  } */
  // }

  /* Function for validation of email */

  const validateEmail = (email) => {
    const validation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      setEmailError('');
      setEmail('');
      // setEmailError('Email is required');
      setStepOneValid(false);
      setanyFieldUpdated(false);
    } else if (email && !validation.test(email)) {
      console.log(email, 'emailemail');
      setEmailError('Email is invalid');
      setEmail(email);
      setStepOneValid(false);
      setanyFieldUpdated(true);
    } else {
      setEmailError('');
      setEmail(email);
      setStepOneValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function for end date formatting */
  const formatDateForComparison = (dateString) => {
    // Parse the input date string and format it to 'YYYY-MM-DDTHH:mm:ss'
    return moment(dateString, 'MM-DD-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss');
  };

  const validateStartDate = (startDate) => {
    if (startDate === '01-01-1970') {
      return;
    }

    // Convert startDate and endDate to a compatible format
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);

    setstartDate(startDate);

    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError(
        'FA Expiration Date should be greater than or equal to the FA Signing Date'
      );
      setStepOneValid(false);
      setanyFieldUpdated(true);
    } else {
      // setEndDateError('');
      setStartDateError('');
      setStepOneValid(true);
      setanyFieldUpdated(true);
    }
  };

  const validateEndDate = (endDate) => {
    if (endDate === '01-01-1970') {
      return;
    }

    // Convert startDate and endDate to a compatible format
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);

    setEndDate(endDate);

    // Check the end date is greater than start date
    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError(
        'FA Expiration Date should be greater than or equal to the FA Signing Date'
      );
      setStepOneValid(false);
      setanyFieldUpdated(true);
    } else {
      setEndDateError('');
      setStepOneValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function for validation of address */

  const validateAddress = (address) => {
    if (!address) {
      setAddress('');
      setAddressError('Address is required');
      setStepOneValid(false);
      setanyFieldUpdated(false);
    } else {
      setAddressError('');
      setAddress(address);
      setStepOneValid(true);
      setanyFieldUpdated(true);
    }
    // setAddress(address);
  };

  /* Function for validation of address2 */

  const validateAddress2 = (address2) => {
    // if(!address) {
    //     setAddress2Error("Address 2 is required");
    //     setStepOneValid(false);
    // } else {
    //     setAddress2Error("");
    //     setAddress2(address2);
    //     setStepOneValid(true);
    // }

    if (!address2) {
      setAddress2('');
      setanyFieldUpdated(false);
    } else {
      setAddress2(address2);
      setanyFieldUpdated(true);
    }
  };

  /* Function for validation of city format */

  const validateCity = (city) => {
    // const validation = /^[a-zA-Z]+$/;
    const validation = /^[a-zA-Z ]*$/;
    if (!city) {
      setCity('');
      setCityError('City is required');

      // formValid = false;
      setStepOneValid(false);
      setanyFieldUpdated(false);
    } else if (!validation.test(city)) {
      setCityError('Enter alphabet characters only');
      // formValid = false;
      setStepOneValid(false);
      setanyFieldUpdated(true);
    } else {
      setCityError('');
      setCity(city);
      // formValid = true;
      setStepOneValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function to validate of zip code */

  const validateZipCode = (zipCodeFranchisee) => {
    const validateZip = /^[0-9]{5}(?:-[0-9]{4})?$/;

    if (!zipCodeFranchisee) {
      setZipcode('');
      setZipcodeError('Zip code is required');
      // formValid = false;
      setStepOneValid(false);
      setanyFieldUpdated(false);
    } else if (!validateZip.test(zipCodeFranchisee)) {
      setZipcodeError('Invalid zip code');
      setZipcode(zipCodeFranchisee);
      // formValid = false;
      setStepOneValid(false);
      setanyFieldUpdated(true);
    } else {
      setZipcodeError('');
      setZipcode(zipCodeFranchisee);
      // formValid = true;
      setStepOneValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function for state drop-down to be filled */

  const stateValue = (stateValue) => {
    if (!stateValue) {
      setState('');
      setStepOneValid(false);
      setanyFieldUpdated(false);
    } else {
      // formValid = true;
      setStepOneValid(true);
      setStateError(false);
      setState(stateValue);
      setanyFieldUpdated(true);
    }
  };

  /* Function for location drop-down to be filled */

  const locationValue = (location) => {
    if (!location) {
      setLocation('');
      setLocationError('Location name is required');
      setStepOneValid(false);
      setanyFieldUpdated(false);
    } else {
      setLocationError('');
      setLocation(location);
      // formValid = true;
      setStepOneValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function for assign manager drop down to be filled */
  const assignManagerValue = (assignManager) => {
    // if (!assignManager) {
    //     setassignManager('');
    //     setassignManagerError(true);
    //     setStepOneValid(false);
    //     setanyFieldUpdated(false);
    // }
    // else {
    //     // formValid = true;
    //     setStepOneValid(true);
    //     setassignManagerError(false);
    //     setassignManager(assignManager);
    //     setanyFieldUpdated(true);
    // }
    // setassignManager(assignManager);
    // setanyFieldUpdated(true);
    if (!assignManager) {
      setassignManager('');
      setassignManagerError(true);
      setStepOneValid(false);
      setanyFieldUpdated(false);
      setAssignManagerName('');
      setAssignManagerId('');
    } else {
      // formValid = true;
      setStepOneValid(true);
      setassignManagerError(false);
      setassignManager(assignManager);
      setAssignManagerName(assignManager.split('|')[1]);
      setAssignManagerId(assignManager.split('|')[0]);
      setanyFieldUpdated(true);
    }

    console.log('assignManager', assignManager);
    // setassignManager(assignManager);
    // setanyFieldUpdated(true);
  };

  /* Function for validation of tax id */

  const taxIdValidate = (taxId) => {
    const taxIdValidate = /^\d{2}-\d{7}$/;
    settaxId(formatTaxId(taxId));
    if (!taxId) {
      settaxId('');
      settaxIdError('');

      setStepOneValid(false);
      setanyFieldUpdated(false);
    } else if (!taxIdValidate.test(taxId)) {
      // settaxId(formatTaxId(taxId));
      settaxIdError('Please enter a valid FEIN');
      setanyFieldUpdated(true);
    } else {
      settaxIdError('');
      // settaxId(formatTaxId(taxId));
      // settaxId(taxID);
      setanyFieldUpdated(true);
    }
  };

  /* Function call when user going to  submit create location  form */

  const locationStep = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);

    if (!franchiseeName) {
      setfranchiseeNameError('Business entity name is required');
    }
    // if (!brandName) {
    //   setbrandNameError('Brand name is required');
    // }

    // if (!email) {
    //   setEmailError('Email is required');
    // }
    if (!startDate) {
      setStartDateError('FA signing date is required');
    }

    if (!endDate) {
      setEndDateError('FA expiration date is required');
    }
    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError(
        'FA Expiration Date should be greater than or equal to the FA Signing Date'
      );
    }
    if (!address) {
      setAddressError('Address is required');
    }
    if (!city) {
      setCityError('City is required');
    }
    if (!stateVal) {
      setStateError(true);
    }
    if (!zipcode) {
      setZipcodeError('Zip code is required');
    }
    if (!location) {
      // setLocationError(true);
      setLocationError('Location name is required');
    }

    if (assignManager && assignManager.length === 0) {
      setassignManagerError(true);
    }
    // if (!taxId) {
    //   settaxIdError('Tax id is required');
    // }

    if (
      taxIdError.length > 0 ||
      !franchiseeName ||
      // !brandName ||
      // !phone ||
      // !email ||
      // isEmailExist ||
      !startDate ||
      !endDate ||
      moment(formattedStartDate).isAfter(moment(formattedEndDate)) ||
      !address ||
      !city ||
      !stateVal ||
      !zipcode
      // !location ||
      // (assignManager && assignManager.length === 0) ||
      // !taxId
    ) {
      setStepOneValid(false);
      setanyFieldUpdated(true);
    } else {
      setStepOneValid(true);
      setanyFieldUpdated(false);
      // If all field valid then go next
      // if (isStepOneValid) handleNext();
      console.log(isStepOneValid);
      handleNext();
    }

    /* if (isStepOneValid) {
            // eslint-disable-next-line no-unused-vars
            // If all field valid then go next
            handleNext();
        } else {
            // step one is valid
            setStepOneValid(false);
        } */
    // handleNext();
  };

  /* Franchisee part ends */

  /* Create  User Part  starts  */

  /* Function to validate the Firstname entered by user */
  const validateFirstname = (firstName) => {
    if (!firstName) {
      setfirstName('');
      setfirstNameError('First name is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else {
      setfirstNameError('');
      setfirstName(firstName);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the last name */

  const validateLastname = (lastName) => {
    if (!lastName) {
      setlastName('');
      setlastNameError('Last name is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else {
      setlastNameError('');
      setlastName(lastName);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the email */

  const validateEmailUser = (Email) => {
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!Email) {
      setEmailuser('');
      setemailErroruser('Email is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else if (!validateEmail.test(Email)) {
      setemailErroruser('Email is invalid');
      setEmailuser(Email);
      setStepTwoValid(false);
      setanyFieldUpdated(true);
    } else {
      setemailErroruser('');
      setEmailuser(Email);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function to validate the phone number entered by user */

  // const validatePhonenumber = (phoneNumber) => {
  //     const validatePhonenumber = /^\d{3}-\d{3}-\d{4}$/;

  //     if (!phoneNumber) {
  //         setphoneNumber('');
  //         setphoneNumberError("Phone number is required");
  //         setStepTwoValid(false);
  //         setanyFieldUpdated(false);
  //     }
  //     else if (!validatePhonenumber.test(phoneNumber)) {
  //         setphoneNumberError("Invalid phone number");
  //         setphoneNumber(phoneNumber);
  //         setStepTwoValid(false);
  //         setanyFieldUpdated(true);
  //     }
  //     else {
  //         setphoneNumberError("");
  //         setphoneNumber(phoneNumber);
  //         setStepTwoValid(true);
  //         setanyFieldUpdated(true);
  //     }
  // }

  const validatePhonenumber = (phonenumber) => {
    // const validatePhonenumber = /^\d{3}-\d{3}-\d{4}$/;

    // if (!phonenumber) {
    //     setphoneNumber('');
    //     setphoneNumberError("Phone number is required");
    //     setStepTwoValid(false);
    //     setanyFieldUpdated(true);
    // }

    // else {
    //     setphoneNumberError("");
    //     let formatedPhoneNumber = formatPhoneNumber(phonenumber);
    //     setphoneNumber(formatedPhoneNumber);
    //     setStepTwoValid(true);
    //     setanyFieldUpdated(true);
    // }

    const validation = /^\d{3}-\d{3}-\d{4}$/;
    setanyFieldUpdated(true);
    if (phonenumber) {
      let formatWorkPhoneNumber = formatPhoneNumber(phonenumber);
      setphoneNumber(formatWorkPhoneNumber);
      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        setphoneNumberError(
          'Please enter a valid phone number in format: xxx-xxx-xxxx'
        );
      } else {
        setphoneNumberError('');
        setStepTwoValid(true);
      }
    } else {
      // setphoneNumberError('Phone number is required');
      setphoneNumber('');
    }
  };

  /* Function to validate work phone number */

  const validateWorkPhonenumber = (workPhoneNumber) => {
    const validation = /^\d{3}-\d{3}-\d{4}$/;
    setanyFieldUpdated(true);
    if (workPhoneNumber) {
      let formatWorkPhoneNumber = formatPhoneNumber(workPhoneNumber);
      setworkphoneNumber(formatWorkPhoneNumber);
      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        setworkphoneNumberError(
          'Please enter a valid phone number in format: xxx-xxx-xxxx'
        );
      } else {
        setworkphoneNumberError('');
        setStepTwoValid(true);
      }
    } else {
      setworkphoneNumberError('Mobile number is required');

      setworkphoneNumber('');
    }
  };

  /* Function to check the role values to be selected */
  /* const roleValue = (role) => {
         setRoleError(false);
         setRole(role);
         setanyFieldUpdated(true);
     } */

  /* Function for validation of address entered by the user */
  const validateAddressUser = (address) => {
    if (!address) {
      setAddressuser('');
      setAddressErroruser('Address is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else {
      setAddressErroruser('');
      setAddressuser(address);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  /* Function for validation of address2 entered by user */
  const validateAddress2User = (address2) => {
    // if (!address2) {
    //     setAddress2Erroruser("Address 2 is required");
    //     setStepTwoValid(false);
    // } else {
    //     setAddress2Erroruser("");
    //     setAddress2user(address2);
    //     setStepTwoValid(true);
    // }
    if (!address2) {
      setAddress2user('');
      setanyFieldUpdated(false);
    } else {
      setAddress2user(address2);
      setanyFieldUpdated(true);
    }
  };

  /* Function to select city from drop-down */

  const cityValueUser = (city) => {
    if (!city) {
      setCityuser('');
      setCityErroruser('City is required');
      setanyFieldUpdated(false);
    } else {
      setCityErroruser('');
      setCityuser(city);
      setStepTwoValid(true);
    }
  };

  /* Function to select state of user from drop-down */
  const stateValueUser = (state) => {
    setStateErroruser(false);
    setStateuser(state);
    setStepTwoValid(true);
    setanyFieldUpdated(true);
  };

  /* Function to validate entered zip code */

  const validateZipCodeUser = (zipCodeUser) => {
    const validateZipcode = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (!zipCodeUser) {
      setzipCodeUser('');
      setzipCodeErroruser('Zip code is required');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else if (!validateZipcode.test(zipCodeUser)) {
      setzipCodeErroruser('Invalid zip code');
      setzipCodeUser(zipCodeUser);
      setStepTwoValid(false);
      setanyFieldUpdated(true);
    } else {
      setzipCodeErroruser('');
      setzipCodeUser(zipCodeUser);
      setStepTwoValid(true);
      setanyFieldUpdated(true);
    }
  };

  // Method to change the status
  const handleStatusChange = (val) => {
    setSelectedStatus(val);
    setanyFieldUpdated(true);
  };

  /* Function call when user is going to submit the form for user creation */
  const formSubmitValue = (e) => {
    // const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // eslint-disable-next-line no-unused-vars
    e.preventDefault();
    e.stopPropagation();

    if (!firstName) {
      setfirstNameError('First name is required');
    }
    if (!lastName) {
      setlastNameError('Last name is required');
    }
    if (!emailuser) {
      setemailErroruser('Email is required');
    }
    if (!zoomRoomEmail) {
      setZoomRoomEmail('Zoom room email is required');
    }

    if (!workphoneNumber) {
      setworkphoneNumberError('Mobile number is required');
    }
    // if (!role) {
    //     setRoleError(true);
    // }
    if (!addressuser) {
      setAddressErroruser('Address is required');
    }
    if (!cityuser) {
      // setCityErroruser(true);
      setCityErroruser('City is required');
    }
    if (!stateuser) {
      setStateErroruser(true);
    }
    if (!zipCodeUser) {
      setzipCodeErroruser('Zip code is required');
    }

    if (
      !firstName ||
      !lastName ||
      !emailuser ||
      !workphoneNumber ||
      !addressuser ||
      profileimageError ||
      !cityuser ||
      !stateuser ||
      !zipCodeUser
    ) {
      setStepTwoValid(false);
      setanyFieldUpdated(true);
    } else {
      setStepTwoValid(true);
      setanyFieldUpdated(true);

      // if (isStepTwoValid) {
      // Now call the create franchise service method
      if (franchiseeId) {
        setOpenConfirmPopup(true);
      }
      // else if (franchiseeId) createLocation(franchiseeId);
      // else if (franchiseeId && locationId) updateUser(franchiseeId, locationId);

      // }
    }

    /* if (isStepTwoValid) {
            // Now call the create franchise service method
            createFranchisee();
        } else {
            // Set step two is not valid
            setStepTwoValid(false);
        } */
  };

  // Method to check the email already exist or not from API
  const checkEmailExist = (email) => {
    // check the email is valid or not
    const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!validateEmail.test(email.value)) {
      // setEmailError('Invalid email');
      setStepTwoValid(false);
      setanyFieldUpdated(false);
    } else {
      let emailid = email.value.toLowerCase();
      // Now check the email is already exist or not
      fetch(
        `${process.env.REACT_APP_API_END_POINT}franchisees/checkEmailExists?email=${emailid}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            // Now check the response is true or false
            let emailExist = res.isExists;
            let msg = res.message;
            if (res && emailExist) {
              // Now email is already exist
              if (email.name === 'Email') {
                setisEmailExist(true);
                setEmailError(msg);
                setStepOneValid(false);
                setanyFieldUpdated(false);
              } else {
                setisEmailExist(true);
                setemailErroruser(msg);
                setanyFieldUpdated(false);
              }
            } else {
              // Now email is not exist
              setisEmailExist(false);
              setEmailError('');
            }
          } else {
            // Now email is not exist
            setisEmailExist(false);
            setEmailError('');
          }
        })
        .catch((err) => {
          console.log(err);
          // Now email is not exist
          setEmailError(err.message);
          setStepOneValid(false);
          setanyFieldUpdated(false);
        });
    }
  };

  // Method to create franchisee
  const updateFranchisee = () => {
    if (franchiseeId || isStepTwoValid) {
      // Now show the loader
      setIsLoading(true);
      setFinalApiErr(false);
      setFinalApiErrMsg('');

      // JSON body for create franchisee
      const body = JSON.stringify({
        franchiseeName: franchiseeName,
        faExpirationDate: endDate || '',
        // brandName: brandName,
        email: email.toLowerCase(),
        addressLine1: address,
        addressLine2: address2,
        city: city,
        // country: 'USA',
        updatedBy: userId,
        // isActive: true,
        fein: taxId,
        mud: mud,
        phone: phone,
        postalCode: zipcode.toString(),
        faSigningDate: startDate || '',
        state: stateVal,
        status: selectedStatus
      });
      // return false;
      console.log(franchiseeId, 'franchiseeeeee');
      // Create franchisee API calling
      fetch(
        `${
          process.env.REACT_APP_API_END_POINT
        }franchisees/updateFranchise?id=${encodeURIComponent(franchiseeId)}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          body: body
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200 && data.data.Attributes.franchiseeId) {
            let franchiseeId =
              data && data.data && data.data.Attributes.franchiseeId
                ? data.data.Attributes.franchiseeId
                : null;
            let franchiseeName =
              data && data.data && data.data.Attributes.franchiseeName
                ? data.data.Attributes.franchiseeName
                : null;
            // set the franchisee id

            // Now call the update location method
            if (franchiseeId) updateOwner(franchiseeId, franchiseeName);
          } else {
            // Now hide the loader
            setIsLoading(false);
            let errmsg = data.message;
            if (data.error && errmsg) {
              setFinalApiErr(true);
              setFinalApiErrMsg(errmsg);
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          let errmsg = error.message;
          // set error message
          setFinalApiErr(true);
          setFinalApiErrMsg(errmsg);
          // Now hide the loader
          setIsLoading(false);
        });
    } else {
      // Now hide the loader
      setIsLoading(false);
      setFinalApiErr(true);
      setFinalApiErrMsg('Something went wrong. Please try again later.');
    }
  };

  // Method to update location
  // const updateLocation = (franchiseeId) => {

  //   if (locationId) {
  //     let franchId = franchiseeId;
  //     // let imagePath = (imgObj) ? imgObj : {};
  //     // let managerInfo = (assignManager.length > 0) ? JSON.stringify(assignManager) : [];
  //     // create form data object
  //     let formData = new FormData();
  //     formData.append('franchiseeId', franchId);
  //     formData.append('locationName', location);
  //     formData.append('locationDescription', '');
  //     formData.append('phone', phone);
  //     formData.append('email', email.toLowerCase());
  //     formData.append('openingDate', startDate || '');
  //     // formData.append('managerInfo[]', managerInfo);
  //     formData.append('managerId', assignManagerId);
  //     formData.append('managerName', assignManagerName);
  //     formData.append('addressLine1', address);
  //     formData.append('addressLine2', address2);
  //     formData.append('city', city);
  //     formData.append('state', stateVal);
  //     formData.append('country', 'USA');
  //     formData.append('postalCode', zipcode.toString());
  //     formData.append('longitude', '-111.891867');
  //     formData.append('latitude', '40.973588');
  //     formData.append('taxGrouping', taxGrouping);
  //     formData.append('taxId', taxId);
  //     // formData.append('createdBy', createdBy);
  //     formData.append('updatedBy', userId);
  //     // formData.append('isActive', true);
  //     formData.append('status', selectedStatus);
  //     formData.append('unit#', '');
  //     formData.append('isPrimaryLocation', isPrimaryLocation);
  //     // console.log(createdBy);

  //     /* let body = JSON.stringify({
  //               franchiseeId: franchId,
  //               locationName: location,
  //               locationDescription: "",
  //               phone: phone,
  //               email: email.toLowerCase(),
  //               openingDate: startDate,
  //               managerInfo: assignManager || [],
  //               addressLine1: address,
  //               addressLine2: address2,
  //               city: city,
  //               state: state,
  //               country: 'USA',
  //               postalCode: zipcode.toString(),
  //               longitude: "-111.891867",
  //               latitude: "40.973588",
  //               taxGrouping: true,
  //               taxId: taxId,
  //               createdBy: userId,
  //               isActive: true,
  //               locationImage: imagePath
  //           });
  //           */

  //     // API Calling for create location
  //     fetch(`${process.env.REACT_APP_API_END_POINT}locations?id=${encodeURIComponent(locationId)}`, {
  //       method: 'PUT',
  //       headers: {
  //         Accept: '*/*',
  //         // 'content-Type': 'multipart/form-data',
  //         'Access-Control-Allow-Origin': '*'
  //       },
  //       body: formData
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.statusCode === 200) {
  //           // localStorage("locationId", data.data.locationId);
  //           let locationId =
  //             data && data.data && data.data.Attributes.locationId
  //               ? data.data.Attributes.locationId
  //               : null;
  //           // set the location id
  //           setLocationId(locationId);

  //           // Now call the update user method
  //           if (franchId && locationId) updateUser(franchId, locationId);
  //           // handleNext();
  //           // navigate('/franchise-management')
  //         } else {
  //           // Now hide the loader
  //           setIsLoading(false);
  //           let errmsg = data.message;
  //           if (data.error && errmsg) {
  //             setFinalApiErr(true);
  //             setFinalApiErrMsg(errmsg);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error);
  //         let errmsg = error.message;
  //         // set error message
  //         setFinalApiErr(true);
  //         setFinalApiErrMsg(errmsg);
  //         // Now hide the loader
  //         setIsLoading(false);
  //       });
  //   } else {
  //     // Now hide the loader
  //     setIsLoading(false);
  //     setFinalApiErr(true);
  //     setFinalApiErrMsg('Something went wrong. Please try again later.');
  //   }
  // };

  // Method to update user
  const updateOwner = (franchId, franchiseeName) => {
    if (franchId && franchiseeName) {
      // let franchiseid = (franchId) ? String(franchId) : '';
      // let locanid = locationId ? String(locationId) : '';
      // let imagePath = (imgObj) ? imgObj : {};
      // let imagePath = 'Imageurl';
      // Create formdata object
      let formData = new FormData();
      // formData.append('franchiseeId', franchiseid);
      // formData.append('locationId', locanid);
      formData.append('franchiseeName', franchiseeName);
      formData.append('franchiseeId', franchId);
      formData.append('roleId', '#ROLE#1');
      formData.append('roleName', 'Owner');
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', emailuser.toLowerCase());
      formData.append('zoomRoomEmail', zoomRoomEmail.toLowerCase());
      formData.append('phoneNumber', workphoneNumber);
      // formData.append('workPhoneNumber', workphoneNumber);
      formData.append('addressLine1', addressuser);
      formData.append('addressLine2', address2user);
      formData.append('city', cityuser);
      formData.append('state', stateuser);
      formData.append('postalCode', zipCodeUser.toString());
      // formData.append('country', 'USA');
      // formData.append('isPrimaryOwner', true); Not needed as this is not visible field
      // formData.append('entityType', entityType);
      formData.append('profileImage', imgObj);
      // formData.append('isActive', true);
      formData.append('status', selectedStatus);
      // formData.append('createdAt', createdAt);
      // formData.append('updatedAt', updatedAt);
      // formData.append('cognitoId', cognitoId);
      formData.append('updatedBy', userId);
      formData.append('involvementLevel', roleuser);
      // formData.append('userId', updateUserId);
      // formData.append('createdBy', userId);
      // formData.append('teamId', '');

      /* let body = JSON.stringify({
                franchiseeId: franchiseid,
                locationId: locanid,
                roleId: role,
                firstName: firstName,
                lastName: lastName,
                email: emailuser.toLowerCase(),
                gender: gender,
                phoneNumber: phoneNumber,
                workPhoneNumber: workphoneNumber,
                addressLine1: addressuser,
                addressLine2: address2user,
                city: cityuser,
                state: stateuser,
                postalCode: zipCodeUser.toString(),
                country: 'USA',
                profileImage: imagePath,
                isActive: true
            });
            */
      // Calling of create user API when user submit details
      fetch(
        `${
          process.env.REACT_APP_API_END_POINT
        }users/updateOwner?id=${encodeURIComponent(updateUserId)}`,
        {
          method: 'PUT',
          headers: {
            Accept: '*/*',
            // 'content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
          },
          body: formData
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            // Now hide the loader
            setIsLoading(false);
            setisCreated(true);
            setopenDialog(true);
            // setFinalApiErr("Email id, username already exist")
            // let errmsg = data.response.message;
            // navigate('/franchise-management')
            // Trigger the child component method to parent and refresh the table
            refreshFranchiseeData();
          } else if (data.response.error) {
            // Now hide the loader
            setIsLoading(false);
            if (data.response.statusCode === 400) {
              // set error message
              setFinalApiErr(true);
              setFinalApiErrMsg(
                'User account was successfully updated, but unfortunately, there was a failure in sending the corresponding email.'
              );
              setisCreated(false);
            } else {
              let errmsg = data.response.message;
              if (errmsg) {
                setFinalApiErr(true);
                setFinalApiErrMsg(errmsg);
                setisCreated(false);
              }
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          let errmsg = error.message;
          // set error message
          setFinalApiErr(true);
          setFinalApiErrMsg(errmsg);
          // Now hide the loader
          setIsLoading(false);
        });
    } else {
      // Now hide the loader
      setIsLoading(false);
      setFinalApiErr(true);
      setFinalApiErrMsg('Something went wrong. Please try again later.');
    }
  };

  /* User Part */
  return (
    <>
      <Loader IsLoading={isLoading} />
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingRight: '10px', paddingleft: '10px' }}>
                <h1 className="franchisee-name">Create Franchisee</h1>
            </Box> */}

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box onClick={() => handleClose()} className="back-arrow-close">
              <svg
                width="27"
                height="19"
                viewBox="0 0 27 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                  fill="#003087"
                />
              </svg>
            </Box>

            <Typography className="header-blue-32">Edit Franchise</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button
                    sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
	            variant="contained"
                     type="submit"
		    className="btn-primary m-0">

                  Save

                </Button> */}
          </Box>
        </Grid>
      </Grid>

      {/* <Box sx={{ display: 'flex' }}>
                <ArrowBackIcon
                    className="back-arrow-style"
                    style={{ margin: '14px', fontSize: '30px' }}
                    onClick={() => handleClose()}
                />
                <h1 className="franchisee-name mb-0 mt-10">Edit Franchise</h1>
            </Box> */}

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ width: '40%', background: 'none' }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel className="step-labeling">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {activeStep === 0 && (
        <>
          <Box>
            <Box className="container-text-field">
              <Card
                component="form"
                id="location-form"
                className="create-franchise-form"
                onSubmit={locationStep}
                sx={{ border: '0.4px solid #C5C5C5' }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className="input-field-cont">
                      <TextField
                        fullWidth
                        name="Franchisee name"
                        autocomplete="Franchise name"
                        label="BUSINESS ENTITY NAME"
                        placeholder="BUSINESS ENTITY NAME"
                        value={franchiseeName}
                        onChange={(e) => franchiseeValidate(e.target.value)}
                        error={Boolean(franchiseeNameError)}
                        helperText={franchiseeNameError}
                        className="input-field-styling required-field"
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Brand Name"
                        autocomplete="Brand Name"
                        label="BRAND NAME"
                        placeholder="Brand"
                        value={brandName}
                        onChange={(e) => brandnameValidate(e.target.value)}
                        error={Boolean(brandNameError)}
                        helperText={brandNameError}
                        className="input-field-styling required-field"
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid> */}

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Phone"
                        autocomplete="Phone"
                        label="PHONE"
                        // defaultValue={formattedPhone}
                        placeholder="eg 777-777-7777"
                        onChange={(e) => validatePhone(e.target.value)}
                        error={Boolean(phoneError)}
                        helperText={phoneError}
                        value={phone}
                        className="input-field-styling "
                        InputLabelProps={{
                          className: 'label-style-create-franchisee ',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Email"
                        autocomplete="email"
                        label="EMAIL"
                        placeholder="Email"
                        className="input-field-styling "
                        value={email}
                        onChange={(e) => validateEmail(e.target.value)}
                        onKeyUp={(e) => checkEmailExist(e.target)}
                        error={Boolean(emailError)}
                        helperText={emailError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="form-datepicker-container"
                    >
                      {/* <TextField
                        fullWidth
                        name="Start date"
                        label="START DATE"
                        className="location-owner input-field-styling required-field"
                        placeholder="start date"
                        type="date"
                        error={Boolean(startDateError)}
                        helperText={startDateError}
                        value={startDate}
                        onChange={(e) => validateStartDate(e.target.value)}
                        InputLabelProps={{
                          className:
                            'label-style-datePicker-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      /> */}
                      <CustomDatePickerForAllBrowser
                        label="FA SIGNING DATE"
                        onChange={validateStartDate}
                        required={Boolean(true)}
                        fieldValue={startDate}
                      />
                      {startDateError && (
                        <FormHelperText>Start Date is required</FormHelperText>
                      )}
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                            <DatePicker
                                                                onChange={validateStartDate}
                                                                renderInput={(params) => <TextField {...params} fullWidth
                                                                    className="location-owner input-field-styling"
                                                                    label="START DATE"
                                                                    name="Start date"
                                                                    type='date'
                                                                    defaultValue={startDate}
                                                                    error={Boolean(startDateError)}
                                                                    helperText={startDateError}
                                                                    InputLabelProps={{
                                                                        className: 'label-style-datePicker-create-franchisee',
                                                                        shrink: true
                                                                    }}
                                                                    placeholder="MM/DD/YYYY" InputProps={{}} />} />
                                                        </LocalizationProvider> */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="form-datepicker-container"
                    >
                      {/* <TextField
                        fullWidth
                        name="End date"
                        autocomplete="End date"
                        label="END DATE"
                        placeholder="End date"
                        value={endDate}
                        type="date"
                        className="input-field-styling"
                        onChange={(e) => validateEndDate(e.target.value)}
                        error={Boolean(endDateError)}
                        helperText={endDateError}
                        InputLabelProps={{
                          className: 'label-style-datePicker-create-franchisee',
                          shrink: true
                        }}
                        InputProps={{}}
                      /> */}
                      <CustomDatePickerForAllBrowser
                        label="FA EXPIRATION DATE"
                        onChange={validateEndDate}
                        // minDate={new Date(startDate)}
                        disabled={Boolean(!startDate)}
                        fieldValue={endDate}
                        required={Boolean(true)}
                      />
                      {endDateError && (
                        <FormHelperText>{endDateError}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <Typography className="table-heade header-blue-20">
                        Business Entity Address
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Address"
                        autocomplete="Address"
                        label="ADDRESS 1"
                        placeholder="Address"
                        className="input-field-styling required-field"
                        value={address}
                        onChange={(e) => validateAddress(e.target.value)}
                        error={Boolean(addressError)}
                        helperText={addressError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Address 2"
                        autocomplete="Address 2"
                        label="ADDRESS 2"
                        className="input-field-styling"
                        placeholder="Apartments, Suite etc."
                        value={address2}
                        onChange={(e) => validateAddress2(e.target.value)}
                        error={Boolean(address2Error)}
                        helperText={address2Error}
                        InputLabelProps={{
                          className: 'label-style-create-franchisee',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        error={stateError}
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          STATE
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="STATE"
                          value={stateVal}
                          className="input-field-styling"
                          onChange={(e) => stateValue(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 224,
                                overflowY: 'auto'
                              }
                            }
                          }}
                        >
                          {statesList.length > 0 &&
                            statesList.map((data) => (
                              <MenuItem value={data.state}>
                                {data.state}
                              </MenuItem>
                            ))}
                        </Select>
                        {stateError && (
                          <FormHelperText>Please select state</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="City"
                        autocomplete="City"
                        label="CITY"
                        placeholder="City"
                        className="input-field-styling required-field"
                        value={city}
                        onChange={(e) => validateCity(e.target.value)}
                        error={Boolean(cityError)}
                        helperText={cityError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Zip code"
                        autocomplete="Zip code"
                        label="ZIP CODE"
                        placeholder="44444"
                        className="input-field-styling required-field"
                        value={zipcode}
                        onChange={(e) => validateZipCode(e.target.value)}
                        error={Boolean(zipcodeError)}
                        helperText={zipcodeError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        inputProps={{ maxLength: 5 }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Location"
                        autocomplete="Location"
                        label="LOCATION"
                        placeholder="Location"
                        className="input-field-styling required-field"
                        value={location}
                        onChange={(e) => locationValue(e.target.value)}
                        error={Boolean(locationError)}
                        helperText={locationError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid> */}

                    {/* <Grid item xs={12} sm={6} >
                                            <FormControl error={countryError} fullWidth className='select-drop-down-label-styling'>
                                                <InputLabel id="demo-simple-select-helper-label" className="input-field-dropdown-styling">COUNTRY</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    label="COUNTRY"
                                                    defaultValue={country}
                                                    className="input-field-styling"
                                                    onChange={(e) => countryValue(e.target.value)}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"USA"}>USA</MenuItem>
                                                </Select>
                                                {countryError && <FormHelperText>Please select country</FormHelperText>}
                                            </FormControl>
                                        </Grid> */}
                    {/* </Grid> */}
                    {/* <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}> */}
                    {/* error={assignManagerError} */}
                    {/* <FormControl
                        error={assignManagerError}
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          ASSIGN MANAGER
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="ASSIGN MANAGER"
                          className="input-field-styling"
                          value={assignManager}
                          onChange={(e) => assignManagerValue(e.target.value)}
                        > */}
                    {/* <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                <MenuItem value={"A"}>A</MenuItem>
                                                                <MenuItem value={"B"}>B</MenuItem>
                                                                <MenuItem value={"C"}>C</MenuItem> */}
                    {/* {managerList.length > 0 &&
                            managerList.map((item) => (
                              <MenuItem
                                key={item.userId}
                                id={item.userId}
                                // value={item.userId}
                                value={
                                  item.userId +
                                  '|' +
                                  item.firstName +
                                  ' ' +
                                  item.lastName
                                }
                              >
                                {item.firstName + ' ' + item.lastName}
                              </MenuItem>
                            ))}
                        </Select>
                        {assignManagerError && (
                          <FormHelperText>
                            Please select assign manager
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Tax id"
                        autocomplete="Tax id"
                        label="FEIN"
                        placeholder="88-8888888"
                        value={taxId}
                        className="input-field-styling "
                        onChange={(e) => taxIdValidate(e.target.value)}
                        error={Boolean(taxIdError)}
                        helperText={taxIdError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        className="select-drop-down-label-styling"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling"
                        >
                          STATUS
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="STATUS"
                          className="input-field-styling"
                          value={selectedStatus}
                          onChange={(e) => handleStatusChange(e.target.value)}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="remember"
                            className="check-box-span"
                            color="default"
                            checked={taxGrouping}
                            sx={{
                              color: '#003087',
                              '&.Mui-checked': {
                                color: '#003087'
                              }
                            }}
                          />
                        }
                        label={
                          <Typography className="label-style-create-franchisee">
                            Tax Grouping
                          </Typography>
                        }
                        onChange={(e) => setTaxGrouping(e.target.checked)}
                      />
                    </Grid>
                  </Grid> */}
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box className="radio-button-heading">MUD</Box>
                      <FormControl className=" radio-button-text">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          onChange={(e) => SetMud(e.target.value)}
                          value={mud}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <FormControlLabel
                              className="radio-button-style"
                              value="true"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className="radio-button-style"
                              value="false"
                              control={<Radio />}
                              label="No"
                            />
                          </Box>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingTop: '10px'
                    }}
                  >
                    <Button
                      type="submit"
                      className="next-button common-button"
                      variant="contained"
                      // sx={{ paddingRight: '0px' }}
                    >
                      <Typography className="next-button-text">NEXT</Typography>
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </>
      )}

      {/* ---------- Create USER form starts -----*/}

      {activeStep === 1 && (
        <>
          <Box>
            <Box className="container-text-field">
              <Card
                component="form"
                id="user-form"
                className="create-franchise-form"
                onSubmit={formSubmitValue}
                sx={{ border: '0.4px solid #C5C5C5' }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="First name"
                        autocomplete="First name"
                        label="FIRST NAME"
                        placeholder="eg John"
                        className="input-field-styling required-field"
                        value={firstName}
                        onChange={(e) => validateFirstname(e.target.value)}
                        error={Boolean(firstNameError)}
                        helperText={firstNameError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Last Name"
                        autocomplete="Last Name"
                        label="LAST NAME"
                        placeholder="Last name"
                        value={lastName}
                        className="input-field-styling required-field"
                        onChange={(e) => validateLastname(e.target.value)}
                        error={Boolean(lastNameError)}
                        helperText={lastNameError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="EmailUser"
                        autocomplete="Email"
                        label="PERSONAL EMAIL"
                        placeholder="PERSONAL EMAIL"
                        value={emailuser}
                        className="input-field-styling required-field"
                        onChange={(e) => validateEmailUser(e.target.value)}
                        onKeyUp={(e) => checkEmailExist(e.target)}
                        error={Boolean(emailErroruser)}
                        helperText={emailErroruser}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="ZoomRoomEmailUser"
                        autocomplete="ZoomRoomEmail"
                        label="ZOOM ROOM EMAIL"
                        placeholder="ZOOM ROOM EMAIL"
                        defaultValue={zoomRoomEmail}
                        className="input-field-styling required-field"
                        onChange={(e) =>
                          validateZoomRoomEmailUser(e.target.value)
                        }
                        // onKeyUp={(e) => checkEmailExist(e.target)}
                        error={Boolean(ZoomRoomEmailError)}
                        helperText={ZoomRoomEmailError}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="mobile number"
                        autocomplete="mobile number"
                        label="MOBILE NUMBER"
                        placeholder="eg 777-777-7777"
                        value={workphoneNumber}
                        onChange={(e) =>
                          validateWorkPhonenumber(e.target.value)
                        }
                        error={Boolean(workphoneNumberError)}
                        helperText={workphoneNumberError}
                        className="input-field-styling required-field"
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl
                        error={roleErroruser}
                        fullWidth
                        className="select-drop-down-label-styling "
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          ROLE
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={roleuser}
                          label="ROLE"
                          className="input-field-styling"
                          onChange={(e) => roleValueUser(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 224,
                                overflowY: 'auto'
                              }
                            }
                          }}
                        >
                          {rolesList.map((data) => (
                            <MenuItem value={data.role}>{data.role}</MenuItem>
                          ))}
                        </Select>
                        {roleErroruser && (
                          <FormHelperText>Please select role</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={2}>
                      <Avatar
                        sx={{ height: '90px', width: '90px' }}
                        className="user-profile-img"
                        src={imageUrl}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <span className="add-profile-text">
                        Add your profile image
                      </span>
                      <Box error={profileimageError}>
                        <input
                          accept="image/png, image/jpeg, image/jpg"
                          multiple
                          type="file"
                          id="select-file"
                          style={{ display: 'none' }}
                          onChange={(event) => handleFileInput(event)}
                        />
                        <label htmlFor="select-file">
                          <Button
                            variant="contained"
                            component="span"
                            className="upload-image"
                          >
                            <Typography className="upload-text">
                              Choose{' '}
                            </Typography>
                          </Button>
                        </label>
                      </Box>
                      {profileimageError && (
                        <FormHelperText className="error-message">
                          {profileImageError}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <Typography className="table-heade header-blue-20">
                        Home Address
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Address"
                        autocomplete="Address"
                        label="ADDRESS 1"
                        placeholder="Address"
                        value={addressuser}
                        onChange={(e) => validateAddressUser(e.target.value)}
                        error={Boolean(addressErroruser)}
                        helperText={addressErroruser}
                        className="input-field-styling required-field"
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Address 2"
                        autocomplete="Address 2"
                        label="ADDRESS 2"
                        placeholder="Apartment, Suite etc"
                        value={address2user}
                        onChange={(e) => validateAddress2User(e.target.value)}
                        // error={Boolean(address2Erroruser)}
                        // helperText={address2Erroruser}
                        className="input-field-styling"
                        InputLabelProps={{
                          className: 'label-style-create-franchisee',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        error={stateErroruser}
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          STATE
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={stateuser}
                          label="STATE"
                          className="input-field-styling"
                          onChange={(e) => stateValueUser(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 224,
                                overflowY: 'auto'
                              }
                            }
                          }}
                        >
                          {statesList.length > 0 &&
                            statesList.map((data) => (
                              <MenuItem value={data.state}>
                                {data.state}
                              </MenuItem>
                            ))}
                        </Select>
                        {stateErroruser && (
                          <FormHelperText>Please select state</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="City"
                        autocomplete="City"
                        label="CITY"
                        placeholder="City"
                        className="input-field-styling required-field"
                        value={cityuser}
                        onChange={(e) => cityValueUser(e.target.value)}
                        error={Boolean(cityErroruser)}
                        helperText={cityErroruser}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin ">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="Zip code"
                        autocomplete="Zip code"
                        label="ZIP CODE"
                        placeholder="44444"
                        className="input-field-styling required-field"
                        value={zipCodeUser}
                        onChange={(e) => validateZipCodeUser(e.target.value)}
                        error={Boolean(zipCodeErroruser)}
                        helperText={zipCodeErroruser}
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee required-label',
                          shrink: true
                        }}
                        inputProps={{ maxLength: 5 }}
                      />
                    </Grid>
                  </Grid>
                  {finalApiErr && (
                    <FormHelperText className="Mui-error final-error">
                      {finalApiErrMsg}
                    </FormHelperText>
                  )}
                  {/* <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingTop: '20px',
                      width: '104%',
                      marginLeft: '-17px'
                    }}
                  >
                    <Button
                      type="button"
                      className="back-button"
                      onClick={handlePrev}
                    >
                      <Typography className="back-button-text">Back</Typography>
                    </Button>
                    <Button type="submit" className="next-button">
                      <Typography className="next-button-text">
                        Update
                      </Typography>
                    </Button>
                  </Box> */}
                  <Grid container spacing={2} className="field-top-margin ">
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingTop: '20px'
                        }}
                      >
                        <Button
                          type="button"
                          className="back-button common-button p-0"
                          variant="contained"
                          onClick={handlePrev}
                        >
                          <Typography className="back-button-text">
                            Back
                          </Typography>
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          className="next-button common-button p-0"
                        >
                          <Typography className="next-button-text">
                            Update
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </>
      )}

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                    What do you want to do?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Franchise has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClick={() => setOpenConfirmPopup(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Save Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Please confirm that you want to save your changes?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => setOpenConfirmPopup(false)}
          >
            {/* No */}
            Go Back to Editing
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => {
              updateFranchisee();
              setOpenConfirmPopup(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FranchiseeEdit;
