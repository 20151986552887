import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Divider
} from '@mui/material';
import { Toggle } from 'src/components/Global';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { ApiService } from '../../services/ApiService';
import {
  getLocalData,  
  dateFormat
} from '../../utils';
import DynamicDateField from '../DynamicDateField';

export const GiftCardFilter = ({ setFilters, filters }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));
  const locationId = getLocalData('locationId');
  const classes = useStyles();
  // const [expired, SetExpired] = useState(true);
  // const [service, SetService] = useState([]);

  const [pricingDataPrice, setPricingDataPrice] = useState([]); // getting dynamic list
  //   const [pricingDataValue, setPricingDataValue] = useState([]); // getting dynamic list

  const [openFilter, setOpenFilter] = useState({
    giftCardValue: false,
    includedInSale: false,
    expired: false
  });
  const [expandValidThrough, setExpandValidThrough] = useState(false);

  const getGiftCard = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/getAllGiftCardPrices?locationId=${encodedLocationId}`
      );
      // setPricingData(response.data.Items);
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      const giftcardprice = Array.from(new Set(Item.map((obj) => obj.price)));
      //   const giftcardvalue = Array.from(
      //     new Set(Item.map((obj) => obj.giftCardPriceValue))
      //   );
      setPricingDataPrice(giftcardprice);
      //   setPricingDataValue(giftcardvalue);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {    
    if(!expandValidThrough) setFilters((prevState) => ({...prevState,validThrough: ''})); 
    // get all categories
    getGiftCard();
  }, [expandValidThrough]);

  const changeType = (value) => {
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const ChangeValue = (value) => {
    setFilters((prevState) => ({ ...prevState, giftCardValue: value }));
  };
  const ChangeValidThrough = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      validThrough: dateFormat(value)
    }));
  };
  //   const ChangeIncludeInSale = (value) => {
  //     setFilters((prevState) => ({ ...prevState, includeInSale: value }));
  //   };

  // const ChangeExpired = (value) => {
  //   setFilters((prevState) => ({ ...prevState, expired: value }));
  // };
  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      expired: field === 'expired' ? !prevState.expired : false,
      // category: field === 'category' ? !prevState.category : false,

      includedInSale:
        field === 'includedInSale' ? !prevState.includedInSale : false,
      giftCardValue:
        field === 'giftCardValue' ? !prevState.giftCardValue : false
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: '',
      includedInSale: '',
      validThrough: '',
      giftCardValue: ''
    });

    setOpenFilter({
      giftCardValue: false,
      includedInSale: false,
      expired: false
    });
    setExpandValidThrough(false);
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) => changeType(event.target.value)}
            label="Type"
            className="status-field field-label-css"
          >
            <MenuItem key={1} id={1} value={-1}>
              All
            </MenuItem>
            <MenuItem key={2} id={2} value={1}>
              Active
            </MenuItem>
            <MenuItem key={3} id={3} value={0}>
              Inactive
            </MenuItem>
            <MenuItem key={3} id={3} value={3}>
              Expired
            </MenuItem>
          </Select>
        </FormControl>
        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>
            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Category filter */}
        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter('includedInSale')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.includedInSale && classes.closeY,
                    openFilter.includedInSale && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Include In Sale</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.includedInSale && (
          <Box marginTop={1} paddingLeft="30px">
            <FormControl fullWidth variant="outlined">
              <Toggle
                value={filters.includedInSale}
                onChange={() =>
                  setFilters({
                    ...filters,
                    includedInSale: !filters.includedInSale
                  })
                }
              />
            </FormControl>
          </Box>
        )}
        {/* //price  */}
        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter('giftCardValue')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.giftCardValue && classes.closeY,
                    openFilter.giftCardValue && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Value</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.giftCardValue && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Value</InputLabel>
              <Select
                onChange={(event) => ChangeValue(event.target.value)}
                value={filters.giftCardValue ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Value"
                className="status-field field-label-css"
                MenuProps={{
                  PaperProps: {
                      style: {
                          maxHeight: 224,
                          overflowY: 'auto',
                      },
                  },
                }}
              >
                {/* <MenuItem key={0} id={0} value="">
                  None
                </MenuItem> */}
                {pricingDataPrice.length > 0 &&
                  pricingDataPrice.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {/* expired */}
        <Grid item xs={12} onClick={() => handleOpenFilter('expired')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.expired && classes.closeY,
                    openFilter.expired && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Expired</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.expired && (
          <Box marginTop={1} >
            <FormControl fullWidth variant="outlined">
              <Toggle                
                value={expandValidThrough}
                onChange={() => setExpandValidThrough(!expandValidThrough)}
              />
              {expandValidThrough && (
                <DynamicDateField
                  label="Valid Through"
                  name="validThrough"
                  setDateValue={ChangeValidThrough}
                  dateValue={filters.validThrough}
                />
              )}
            </FormControl>
          </Box>
        )}
      </Card>
    </>
  );
};
