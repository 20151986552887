import React, { useEffect, useState } from 'react';
import './style.css';
import { Container, Grid } from '@mui/material';
import { getLocalData } from 'src/utils';
import OperationHoursCalender from './OperationHoursCalender';
import { ApiService } from '../../../services/ApiService';
import { SetOperationHoursModal } from './SetOperationHoursModal';
import moment from 'moment';
import Header from 'src/components/Header';

// import moment from 'moment-timezone';
import Loader from '../../../components/Loader';

export default function StaffOperationHourMain(props) {
  const { setDialog, dialog } = props;
  // const locationId = getLocalData("locationId")
  const [schedularData, setSchedularData] = useState([]);
  const [isEdit, setIsEdit] = useState(false)
  // const [currentDate] = useState(new Date('10-01-2023'));
  const [currentDate] = useState(new Date('2023-10-02T07:30:00.000Z'));
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(false);
  const locationId = getLocalData('locationId');

  useEffect(() => {
    getAllOperatingHours();
  }, [data]);

 

  const getAllOperatingHours = async () => {
    setLoading(true);
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getLocationOperatingHours?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      setSchedularData(Item)
      setLoading(false);
      console.log(Item, 'Item');
      const modifiedItem = [];
      Item?.map((item) =>
        // eslint-disable-next-line array-callback-return
        item?.operatingHours?.map((subItem, index) => {
          const date = getDateByDayOfWeek(subItem.day);
          console.log(date);
          if (date) {
            modifiedItem.push({
              Id: index,
              Subject: 'Operating Hours',
              StartTime: convertTimeAndDate(subItem.startTime, date),
              EndTime: convertTimeAndDate(subItem.endTime, date)
            });
          }
        })
      );
      console.log(modifiedItem);
      // setSchedularData(modifiedItem);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleClosModal = () => {
    setModalOpen(false);
  };

  function getDateByDayOfWeek(dayOfWeek) {
    // const today = new Date(); // Get the current date
    // const targetDay   = dayOfWeek.toLowerCase(); // Convert the input day to lowercase for comparison

    // we assume that calaender has fixed dates as on this calander view we just need to use days.
    const daysOfWeek = {
      Sunday: '10-01-2023',
      Monday: '10-02-2023',
      Tuesday: '10-03-2023',
      Wednesday: '10-04-2023',
      Thursday: '10-05-2023',
      Friday: '10-06-2023',
      Saturday: '10-07-2023'
    };

    // const currentDayIndex = today.getDay(); // Get the current day of the week as an index (0 = Sunday, 1 = Monday, etc.)

    // // Find the index of the target day in the daysOfWeek array
    // const targetDayIndex = daysOfWeek.indexOf(targetDay);

    // // Calculate the difference in days to reach the target day
    // const daysUntilTarget = (targetDayIndex + 7 - currentDayIndex) % 7;

    // // Clone the current date and add the calculated days
    // const currentDate = new Date(today);
    // currentDate.setDate(today.getDate() + daysUntilTarget);

    // Format the date as MM-DD-YYYY
    return `${daysOfWeek[dayOfWeek]}`;
  }

  const convertTimeAndDate = (timeString, dateString) => {
    const dateTimeString = `${dateString} ${timeString}`;
    const dateTime = moment(dateTimeString, 'MM-DD-YYYY hh:mm A');
    const utcDate = dateTime.toDate();
    return utcDate.toISOString();
  };
  const handleButtonClick = () => {
    setModalOpen(true)
  };
  const buttons = [
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theam: true
    },

  ];
  return (
    <>
      <Container maxWidth="100%" className="main-container">
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className="add-btn-container"
        >
          <Header title="Set Operating Hours" buttons={buttons} />
        </Grid>
      </Container>
      <Grid
        container
        spacing={2}
        ml={1.5}
        maxWidth="99%"
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        {loading ? (
          <Loader IsLoading={loading} />
        ) : (
          <>
            <Grid item width="100%">
              <OperationHoursCalender
                dialog={dialog}
                setDialog={setDialog}
                currentDate={currentDate}
                getAllOperatingHours={getAllOperatingHours}
                data={schedularData || []}
                handleOpenModal={() => setModalOpen(true)}
              />
            </Grid>
          </>
        )}
      </Grid>
      <SetOperationHoursModal
        getAllOperatingHours={getAllOperatingHours}
        open={modalOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setOpen={setModalOpen}
        handleClose={handleClosModal}
        setData={setData}

      />
    </>
  );
}
