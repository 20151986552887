import React, { useState, useEffect } from 'react';
import { DynamicButton } from '../../../../components/Checkout';
import {
  Box,
  Grid,
  Typography,
  Stack,
  FormHelperText,
  TextField
} from '@mui/material';
import { DynamicTextField } from '../../../../components/GlobalComponents';
import { ApiService } from '../../../../services/ApiService';
import { getLocalData } from '../../../../utils';
import Loader from 'src/components/Loader';

export const Discount = ({
  setSelected,
  selected,
  handleNext,
  handlePrevious,
  main
}) => {
  const locationId = getLocalData('locationId');
  const userId = getLocalData('userId');
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const [loading, setLoading] = useState(false);
  const [queueId, setQueueId] = useState(selected?.queueId ?? '');
  const [isAvailable, setIsAvailable] = useState(false);
  const [codeError, setCodeError] = useState('');
  const [promoCode, setPromoCode] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardNumberError, setCardNumberError] = useState('');
  const [isGiftCardAvailable, setIsGiftCardAvailable] = useState(false);
  const [memberships, setMemberships] = useState([])
  const [giftCardId, setGiftCardId]= useState("")
  const [isGiftApplied, setIsGiftApplied] = useState(false)
  const payload = {
    locationId: locationId,
    clientId: selected?.client?.sortKey || '',
    clientName: selected?.client?.firstName || '',
    items: selected?.items?.map(({ TotalPrice, sortKey, ...rest }) => rest),
    createdBy: userId || ''
  };

  // TO FILTER MEMBERSHIP DETAILS IF SAME APPLIED ON MORE THAN 1 ITEM start ---- 
  useEffect(() => {
    let uniqueItems = []
    const newArr = selected?.items?.map?.((item) => {
      if (item?.discountDetails?.memberShip?.memberShipName) {
        return item?.discountDetails;
      }
      return null;
    });
    const uniqueMemberShipIds = new Set();

    newArr?.forEach((item) => {
      if (!uniqueMemberShipIds?.has(item?.memberShipId)) {
        uniqueMemberShipIds?.add(item?.memberShipId);
        uniqueItems?.push(item);
      }
    });
    setMemberships(uniqueItems)
  }, [selected?.items]);
  // TO FILTER MEMBERSHIP DETAILS IF SAME APPLIED ON MORE THAN 1 ITEM end ---- 

// HANDLE CHANGE FOR PROMOCODE TEXTFIELD start ----
  const handleChange = (type, value) => {
    setIsAvailable(false);
    setIsApplied(false);
    let code = '';
    if (value) {
      code = value?.toUpperCase();
    } else {
      setCodeError('');
    }
    setPromoCode(code);
    if (code) {
      checkPromoCodeExists(code);
    }
  };
  // HANDLE CHANGE FOR PROMOCODE TEXTFIELD end ----

  // HANDLE CHANGE GIFT CARD FIELD start -------
  const handleCardNumberChange = (e) => {
    setIsGiftApplied(false)
    const { name, value } = e.target;
    if (name === 'Card Number') {
      setCardNumberError('')
      if (value?.length === 9) {
        getCardNumberDetail(value.replace(/-/g, ''));
      }
      if (value?.length < 9) {
        setCardNumberError('Card number must be 9 characters long.');
        setIsGiftCardAvailable(true);
      } else {
        
        setIsGiftCardAvailable(false);
      }
      if (value?.length <= 9) {
        setCardNumber(value);
        setIsGiftCardAvailable(false);
      }
    }
  };
  // HANDLE CHANGE GIFT CARD FIELD end -------

// FUNCTION CALL FOR APPLYING PROMOCODE DISCOUNT
  const handlePromoCode = () => {
    getDiscountFromPromoCode();
  };


  useEffect(() => {
    if (isApplied) {
      setCodeError('');
    }
  }, [isApplied]);

    useEffect(() => {
    if (isGiftApplied) {
      setCardNumberError('');
    }
  }, [isGiftApplied]);

  // API FOR CHECKING PROMOCODE AVAILABILITY start ----
  const checkPromoCodeExists = async (promoValue) => {
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      let response = await ApiService.get(
        `${BASE_URL}promotions/checkPromoCodeExists?locationId=${encodedLocationId}&promoCode=${promoValue}`
      );

      if (response.statusCode === 200) {
        setIsAvailable(response?.isExists);
        if (!response?.isExists) {
          setCodeError(`Promo code - ${promoCode} is not available`);
        } else {
          setCodeError('');
        }
      } else {
        setIsAvailable(false);
        setCodeError(`Promo code - ${promoValue} is not available`);
      }
    } catch (error) {
      setCodeError(`Promo code - ${promoValue} is not available`);
      console.error(error);
      setIsAvailable(false);
    }
  };
  // API FOR CHECKING PROMOCODE AVAILABILITY end ----


  // API FOR CHECKING GIFT CARD AVAILABILITY start ----
  const getCardNumberDetail = async (value) => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/isCardExists?locationId=${encodedLocationId}&giftCardNumber=${value}`
      );

      if (response.statusCode === 200) {
        if (response?.data?.result?.Items?.length) {
          // setDiscount(response?.data?.result?.Items[0]?.discount);
          setGiftCardId(response?.data?.result?.Items[0]?.sortKey)
          setCardNumber(response?.data?.result?.Items[0]?.giftCardNumber)
          setCardNumber(value);
          if (response?.data?.result?.Items?.length > 0) {
            // setIsExist(true);
            setIsGiftCardAvailable(true);
          }
          // eslint-disable-next-line array-callback-return
          response?.data?.result?.Items?.map((item) => {
            item.giftCardPriceValue = item.giftCardValue;
          });
          // setCardValue(response?.data?.result?.Items[0]);
        } else {
          setCardNumberError(response?.message);
          console.log(response);
        }
      } else {
        setCardNumberError(response?.message);
        setIsGiftCardAvailable(false);
      }
    } catch (error) {
      console.log(error);
      setCardNumberError(error.message);
      setIsGiftCardAvailable(false);
    }
  };
  // API FOR CHECKING GIFT CARD AVAILABILITY end ----


  // API TO GET THE MEMBERSHIP DISCOUNT DETAILS start ----
  const getMemberShipDetails = async () => {
    setLoading(true);
    let newItemsArr = payload?.items?.map?.((item)=> {
      delete item.items
      delete item.taxRate
      delete item.taxAmount

      return item
    })
    let payloadData = {...payload, items: newItemsArr}
    try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/applyMemberShipDiscounts`,
        payloadData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.statusCode === 200) {
        let data = response.data;
        setQueueId(data?.queueId);
        setSelected({
          ...selected,
          items: data.items,
          subTotal: data.total,
          total: data.subTotal,
          totalDiscount: data.totalDiscount,
          totalPrice: data.totalPrice,
          queueId: data.queueId
          // discount: data.discount,
          // tax: data.tax,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  // API TO GET THE MEMBERSHIP DISCOUNT DETAILS end ----


  // API FOR PROMO CODE DISCOUNT DETAILS start ----
  const getDiscountFromPromoCode = async () => {
    setLoading(true);
    // let encodedLocationId = encodeURIComponent(locationId);
    let promoPayload = {
      locationId: locationId,
      promoCode: promoCode,
      queueId: queueId
    };
    try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/getPromoCodeDetails`,
        promoPayload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.statusCode === 200) {
        setIsApplied(true);

        if (response.data) {
          let data = response.data;
          setSelected({
            ...selected,
            items: data.items,
            subTotal:data.subTotal,
            total: data.subTotal,
            totalDiscount: data.totalDiscount,
            totalPrice: data.totalPrice,
            queueId: data.queueId
          });
        }
      } else {
        setIsApplied(false);
      }
      setLoading(false);
    } catch (error) {
      setIsApplied(false);
      setLoading(false);
    }
  };
  // API FOR PROMO CODE DISCOUNT DETAILS end ----


  // API FOR GIFT CARD DISCOUNT DETAILS start ----
  const applyGiftCardDiscount = async () => {
    setLoading(true);
    // let encodedLocationId = encodeURIComponent(locationId);
    let promoPayload = {
      locationId: locationId,
      giftCardId: giftCardId,
      queueId: queueId
    };
    try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/applyGiftCardDiscount`,
        promoPayload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.statusCode === 200) {
        setIsGiftApplied(true);
      } else {
        setIsGiftApplied(false);
      }
      setLoading(false);
    } catch (error) {
      setIsGiftApplied(false);
      setLoading(false);
    }
  };
  // API FOR GIFT CARD DISCOUNT DETAILS end ----


  useEffect(() => {
    // Update the active step value in local storage
    localStorage.setItem('activeStep', '2');
  }, []);

  useEffect(() => {
    getMemberShipDetails();
  }, []);

  useEffect(() => {
    localStorage.setItem('prevStep', 2);
  }, []);

  // MAIN UI

  return (
    <Grid
      container
      spacing={2}
      className="giftCardWrap"
      sx={{
        marginTop: '16px',
        marginLeft: '0px',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <Loader IsLoading={loading} />

      <Box sx={{ width: '420px', textAlign: 'left' }}>
        <Box marginTop="10px">
          <Typography
            variant="h4"
            align="left"
            color="primary"
            style={{ marginBottom: '10px' }}
          >
            Promo Code
          </Typography>
          <Box className="clientItem">
            <div
              style={{
                margin: '0 auto 10px 0',
                width: '380px'
              }}
            >
              <DynamicTextField
                className={`input-field-styling required-field ${
                  codeError ? 'failed' : 'success'
                }`}
                type="text"
                name="code"
                label="Enter Code"
                required={false}
                fullWidth
                error={codeError}
                // helperText={
                //   promoCode &&
                //   codeError}
                value={promoCode}
                onChange={handleChange}
              />
              {promoCode && !isAvailable && (
                <FormHelperText className="failed">
                  {`Promo code - ${promoCode} is not available`}
                </FormHelperText>
              )}
              {promoCode && isApplied && (
                <FormHelperText sx={{ color: 'green' }}>
                  {`Your promo code - ${promoCode} has been successfully applied.`}
                </FormHelperText>
              )}
            </div>
          </Box>
          <Box>
            <DynamicButton
              disabled={!isAvailable}
              title={'Apply Promo Code'}
              variant="contained"
              onClick={handlePromoCode}
              style={{
                width: '380px',
                borderRadius: '10px',
                fontWeight: '600',
                fontSize: '16px',
                border: '2px solid #003087'
              }}
            />
          </Box>
        </Box>
        <Box marginTop="10px">
          <Typography
            variant="h4"
            align="left"
            color="primary"
            style={{ marginBottom: '10px' }}
          >
            Gift Card
          </Typography>
          <Box className="clientItem">
            <div
              style={{
                margin: '0 auto 10px 0',
                width: '380px'
              }}
            >
          <TextField
            sx={{ marginBottom: '10px', width: '380px' }}
            name="Card Number"
            fullWidth
            id="outlined-required"
            label="CARD NUMBER"
            className="location-field input-field-styling required-field"
            onChange={handleCardNumberChange}
            error={!!cardNumberError}
            helperText={cardNumber && cardNumberError}
            value={cardNumber}
            InputLabelProps={{
              className: 'label-style-create-franchisee'
            }}
          />
              {isGiftApplied && (
                <FormHelperText sx={{ color: 'green' }}>
                  {`Your gift card - ${cardNumber} has been successfully applied.`}
                </FormHelperText>
              )}
               </div>
              </Box>
          <Box>
            <DynamicButton
              disabled={!isGiftCardAvailable}
              title={'Apply  Gift Card'}
              variant="contained"
              onClick={applyGiftCardDiscount}
              style={{
                width: '380px',
                borderRadius: '10px',
                fontWeight: '600',
                fontSize: '16px',
                border: '2px solid #003087'
              }}
            />
          </Box>
        </Box>
        <Box marginTop="10px">
          {selected?.items?.filter(
            (item) => item?.discountDetails?.memberShip?.memberShipName
          )?.length > 0 && (
            <Typography
              variant="h4"
              align="left"
              color="primary"
              style={{ marginBottom: '10px' }}
            >
              Memberships
            </Typography>
          )}
          {selected?.items?.filter(
            (item) => item?.discountDetails?.memberShip?.memberShipName)?.length > 0 && 
            memberships?.map?.((item) => {
              return (
                <Box className="memBox" style={{ textAlign: 'left' }}>
                  <Box style={{ width: '380px', margin: '0 0 0 auto' }}>
                    <Stack justifyContent="space-between" flexDirection="row">
                      <Typography>
                        {item?.memberShip?.memberShipName}
                      </Typography>
                      <Typography fontStyle="italic">
                        {/* Flat */}
                        {item?.memberShip?.discountApplied}%
                        off
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              );
          })}
        </Box>
      </Box>
      {main && (
        <Box
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: '0'
          }}
        >
          <DynamicButton
            title={'Go Back'}
            variant="contained"
            onClick={handlePrevious}
            style={{
              padding: '10px 23px',
              borderRadius: '10px',
              textTransform: 'capitalize',
              maxWidth: 'max-content',
              fontWeight: '600',
              fontSize: '16px',
              marginRight: '10px',
              background: '#fff',
              color: '#003087',
              border: '2px solid #003087'
            }}
          />
          <DynamicButton
            title={'Order Summary'}
            variant="contained"
            onClick={handleNext}
            style={{
              padding: '10px 23px',
              borderRadius: '10px',
              textTransform: 'capitalize',
              maxWidth: 'max-content',
              fontWeight: '600',
              fontSize: '16px',
              border: '2px solid #003087'
            }}
          />
        </Box>
      )}
    </Grid>
  );
};
