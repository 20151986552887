import {
  Button,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import DrawLocationTable from './DrawLocationTable';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import Loader from '../../../components/Loader';
// import { toast } from 'react-toastify';
import axios from 'axios';
import { getLocalData, setFullLocationId } from 'src/utils';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
// import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function LocationListAPI({
  ref,
  setShowScreen,
  showScreen,
  franchiseeList,
  managerList,
  statesList
}) {
  // const domain = Environment[0].apiEndPoint;
  const [locationList, setLocation] = useState([]);
  const [dataCount, setdataCount] = useState(0);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [ispopup, setIsPopup] = useState(false);
  const userId = sessionStorage.getItem('UserId');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  // const [franchiseeId, setFranchiseeId] = useState(false);
  let [filters, setSidebarFilters] = useState({
    // isActive: null,
    status: 1,
    state: null,
    managerId: null,
    city: '',
    openingStartDate: null,
    openingEndDate: null
  });
  const locationId = setFullLocationId(getLocalData('locationId'));
  // const franchiseeId = getLocalData('franchiseeId');

  const [selectedLocationLists, setSelectedLocationLists] = useState([]);
  const [open, setOpen] = React.useState(false);
//  const [showSucessMessage, setShowSuccessMessage] = useState(false);
 // const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);

  // Get locations method to get the list of location
  const getLocations = async () => {
    // const encodedFranchiseeId = encodeURIComponent(franchiseeId);
    // Now show the loader
    if (!ispopup) setIsLoading(true);

    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === -1 ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    fetch(`${process.env.REACT_APP_API_END_POINT}locations?${queryString}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Set the list of location
          setLocation(data.data.Items);
          // Set the list of location count
          setdataCount(data.data.TotalCount);
          // Now hide the loader
          setIsLoading(false);
          // let elm = document.getElementsByClassName('page-header-text')[0];
          // if (elm) elm.innerHTML = 'Location Management';
        }
      });
  };
  // setFranchiseeId(locationList.partitionKey);
  // gets call the method to get the data

  useEffect(() => {
    // Not call the get location method
    getLocations();
  }, [JSON.stringify(filters)]);

  // Getting hooks from parent to child
  useImperativeHandle(ref, () => ({
    refreshLocationData: () => {
      // Not call the get location method again
      getLocations();
    }
  }));

  // Handle the event from child to parent. It is being used for refresh the data after edit or delete
  const refreshLocationData = () => {
    // When popup is open then not call the loader
    setIsPopup(true);
    // Not call the get location method again
    getLocations();
  };

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
    /* setBtnLoading(true);
   const data = {
     isActive: selectedStatus === 'Active',
     updatedBy: userId,
     locationIds: selectedLocationLists
   };

   try {
     const encodedLocationId = encodeURIComponent(locationId);
     console.log('encodedLocationId', encodedLocationId);
     // ?locationId=${encodedLocationId}
     const response = await axios.put(
       // `${BASE_URL}/memberships/updateBulkStatus?locationId=LOC%23227a3546-10a3-446b-ba5f-0971212d47bf`,
       `${process.env.REACT_APP_API_END_POINT}locations/updateBulk`,
       data
     );

     if (!response.data?.error) {
       toast.success(response.data?.message || 'Location status updated');
       setSelectedStatus('');
       getLocations();
     } else {
       toast.error(
         response.data?.message ||
         'Something went wrong with update location status'
       );
       setSelectedLocationLists([]);
       setSelectedStatus('');
       getLocations();
     }

     setBtnLoading(false);
   } catch (error) {
     console.error(error);
     setBtnLoading(false);
     toast.error('Something went wrong, Try again!');
     setSelectedLocationLists([]);
       setSelectedStatus('');
       getLocations();
   }
   */
  };

  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    // show loader

    setIsLoading(true);
    const data = {
      // isActive: selectedStatus === 'Active',
      status: selectedStatus,
      updatedBy: userId,
      locationIds: selectedLocationLists
      // franchiseeId: franchiseeId
    };

    try {
      const encodedLocationId = encodeURIComponent(locationId);
      console.log('encodedLocationId', encodedLocationId);
      // ?locationId=${encodedLocationId}
      const response = await axios.post(
        // `${BASE_URL}/memberships/updateBulkStatus?locationId=LOC%23227a3546-10a3-446b-ba5f-0971212d47bf`,
        `${process.env.REACT_APP_FEATURED_API_END_POINT}locations/updateBulk`,
        data
      );

      if (!response.data?.error) {
      //  setShowSuccessMessage(true);
      //  setSuccessMessage('');
        // toast.success(response.data?.message || 'Location status updated');
        setSelectedStatus('');
        setSelectedLocationLists([]);
        setIsUpdated(true);
        setOpen(true);
        // getLocations();
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          response.data?.message ||
          'Something went wrong with update location status'
        );

        // toast.error(
        //   response.data?.message ||
        //   'Something went wrong with update location status'
        // );
      }
      // show loader
      setIsLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      // show loader
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getLocations();
        setIsUpdated(false);
      }
    }
  };

  return (
    <Grid className="card-table-main" xs={12}>
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {/* {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={getLocations}
        />
      )} */}
      <Loader IsLoading={isLoading} />
      {/* If data count is greater the 0 then pass the value into the parameter */}
      {/* { dataCount > 0 ? 
        <DrawLocationTable locationLists={ locationList } refreshLocationData={ refreshLocationData } />
      : <div className='no-data'>No Data Found</div> } */}
      <DrawLocationTable
        locationLists={locationList}
        sideBarfilters={filters}
        setSidebarFilters={setSidebarFilters}
        refreshLocationData={refreshLocationData}
        dataCount={dataCount}
        setShowScreen={setShowScreen}
        showScreen={showScreen}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleBulkAdd={handleBulkAdd}
        btnLoading={btnLoading}
        selectedLocationLists={selectedLocationLists}
        setSelectedLocationLists={setSelectedLocationLists}
        open={open}
        setOpen={setOpen}
        franchiseeList={franchiseeList}
        managerList={managerList}
        statesList={statesList}
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Are you sure you want to mark Activate/Deactivate,
                  <Typography className="title-text-sub">
                    all the selected items?
                  </Typography>
                </Typography>
              ) : (

                <Typography className="title-text-sub">
                  All the selected locations have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default React.forwardRef(LocationListAPI);
