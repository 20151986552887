import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const AllContractsTable = ({ title, dataItems, columns }) => {
  return (
    <>
      <div className="allContrTableSec">
        <h3 className="themeColor">{title}</h3>
        <div className="dataTableMain" style={{ height: 400, width: '100%' }}>
          <DataGrid
            /* className="custom-table col-4 editable" */
            rows={dataItems}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 }
              }
            }}
            disableRowSelectionOnClick
            pageSizeOptions={[5, 10, 50]}
            localeText={{ noRowsLabel: 'No contracts are available' }}
          />
        </div>
      </div>
    </>
  );
};
export default AllContractsTable;
