/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Card,
  InputLabel,
  TextField,
  Select,
  FormControl,
  MenuItem
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DeleteIcon } from 'src/assets/SVG/SvgIcons'; // ArrowLeft
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { getLocalData, setFullLocationId } from 'src/utils';
import { mt } from 'date-fns/locale';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ApiService } from '../../../services/ApiService';
import dayjs from 'dayjs';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ServiceDetailsWrapper = styled(Container)(
  ({ theme }) => `
          margin-top: ${theme.spacing(2)};
  `
);

export const CohortsDetail = (props) => {
  const { selectedRow, setShowScreen ,getCohorts} =
    props;
  // console.log(selectedRow.Segments[0].DimensionsAttributes, 'ddattributeValues');
  // const BASE_URL = 'https://vtqf4ke0yj.execute-api.us-east-1.amazonaws.com/dev';
  // const cohortsId = selectedRow.sortKey;
  const locationId = setFullLocationId(getLocalData('locationId'));
  const [getattributeData, setAllAttributeData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [oneRowData, setOneRowData] = useState([])
  const [msg, setmsg] = useState('')
  const [isMsg, setIsMsg] = useState(false)
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [operatorData, setOperatorData] = useState([]);
  useEffect(() => {
    getAllAttribute()
    getAllOperator()
    // Not call the get location method
    getAllAttributeValues()

  }, []);
  console.log(selectedRow, 'selectedRowd');
  const getAllAttributeValues = () => {
    if (selectedRow) {
      if (selectedRow.Segments && selectedRow.Segments[0] && selectedRow.Segments[0].DimensionsAttributes) {
        setIsMsg(false)
        setmsg('')
        const data = selectedRow.Segments[0].DimensionsAttributes;
        setAllAttributeData([data]);
      } else {
        setOneRowData([selectedRow]);
        setIsMsg(true)
        setmsg('No Criteria available')
      }
    } else {
      setIsMsg(true)
      setmsg('No Criteria available')
      // Handle the case when selectedRow is undefined or null
    }
  };


  function convertIsoToNormalDate(isoDate) {
    const normalDate = moment(isoDate).format('MM-DD-YYYY');
    return normalDate;
  }
  function convertIsoToNormalDateAndTime(isoDate) {
    const normalDate = moment.utc(isoDate).format('MMMM Do YYYY, h:mm, [UTC]');
    return normalDate;
  }
  console.log(selectedRow?.Segments?.ImportDefinition?.Size, 'oneRowData');
  console.log(getattributeData, 'oneRowData');


  const getAllAttribute = async () => {
    try {
      let response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllCriteria`);

      if (response.statusCode && !response.error) {
        setAttributeOptions(response?.data?.Items)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getAllOperator = async () => {
    try {
      let response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllOperators`);

      if (response.statusCode && !response.error) {
        setOperatorData(response?.data?.Items)
      }
    } catch (error) {
      console.log(error);
    }
  }

  function getDateFormat(value) {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    });

    return formattedDate;
  }


  const handleResetStates = () => {
    setShowScreen(0)
  }

  const handleOpenScreen = () => {
    setShowScreen(2)
  }
  const handleOpenScreenEdit = () => {
    setShowScreen(3)
  }
  return (
    <div className="custom-summary">
      <ServiceDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"

      >

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleResetStates()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {selectedRow?.Name}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            {/* <Button
                disabled={btnLoading}
                className="details-remove-icon no-hover"
                onClick={() => setDeleteDialog(true)}
              >
                <DeleteIcon />
              </Button> */}

            <Grid>
              <Button className='cohorts-outlined-btn' variant='outlined' onClick={(e) => handleOpenScreen()}>
                Duplicate
              </Button>



              {selectedRow && selectedRow.createdBy !== 'Franchisor' && (
                <Button
                  sx={{ ml: '10px' }}
                  variant="contained"
                  onClick={(e) => handleOpenScreenEdit()}
                  className="btn-primary edit-button template-edit-btn"
                >
                  Edit
                </Button>
              )}
            </Grid>

          </Grid>
        </Grid>
        <Box className="location-details-container" mt={5} ml={3}>
          {/* <Grid container spacing={2} > */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                TOTAL CLIENTS
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {selectedRow?.Segments?.ImportDefinition ?  selectedRow?.Segments?.ImportDefinition?.Size : ''}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                COHORT ID
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {selectedRow?.sortKey}
              </Box>
            </Grid>

          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                CREATED BY
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {selectedRow?.createdBy}
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                CREATED AT
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {convertIsoToNormalDateAndTime(selectedRow?.CreationDate)}
              </Box>
            </Grid>

          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label summary-heading">
                LAST MODIFIED
              </Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value summary-value">
                {convertIsoToNormalDateAndTime(selectedRow?.LastModifiedDate)}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Card
          sx={{
            padding: '15px',
            borderRadius: '19px 19px 0px 0px',
            mt: 5
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            ml={2}
          >
            <Grid item>
              {/* <CardHeader title="All Services" className="table-header" /> */}
              {/* <h2 className="table-heading">All Services</h2> */}
              <Typography className="table-heade header-blue-20">
                {' '}
                Criteria
              </Typography>
            </Grid>
          </Grid>

          {/* *************************get and set all attribute values ***************************** */}


          {getattributeData[0]?.map((item, index) => (
            <Grid
              key={index}
              container
              mt={3}
              ml={2}
              gap={3}
            >

              {item.operator === '' &&
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  ml={2}
                  sx={12}
                  sm={12}
                >
                  <Grid item sx={4.5} sm={4.5} mt={5}>
                    <Box className='horizontal-line-cohorts'>
                      <hr />
                    </Box>
                  </Grid>
                  <Grid item sx={2.5} sm={2.5} alignItems='center'>
                    <FormControl
                      fullWidth
                      className="top-margin-cohorts"
                    >
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="status"
                        value={'conditionalOperator'}
                        className="input-field-styling cohort-input-styling"
                      >
                        <MenuItem value={'AND'}>
                          AND
                        </MenuItem>
                        <MenuItem value={'OR'}>
                          OR
                        </MenuItem>
                        <MenuItem value={'NOR'}>
                          NOR
                        </MenuItem>
                      </Select>

                    </FormControl>
                  </Grid>
                  <Grid item sx={4.5} sm={4.5} mt={5} mr={4}>
                    <Box className='horizontal-line-cohorts'>
                      <hr />
                    </Box>
                  </Grid>
                </Grid>
              }
              <Grid container mt={3} ml={2} gap={3}>
                <Grid item xs={3.8} sm={3.8}>
                  <Typography className="cohorts-detail-header">
                    Attribute
                  </Typography>
                  <FormControl
                    className="top-margin-cohorts"
                    fullWidth
                  >
                    <Select
                      disabled
                      name='attribute'
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      className="input-field-styling cohort-input-styling"
                      value={item?.attribute}
                      MenuProps={{
                        PaperProps: { style: { maxHeight: 200 } }
                      }}
                    >
                      {attributeOptions?.map((option) => (
                        <MenuItem value={option.name} key={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>

                </Grid>
                <Grid item xs={3.8} sm={3.8}>
                  <Typography className="cohorts-detail-header">
                    Operator
                  </Typography>
                  <FormControl
                    className="top-margin-cohorts"
                    fullWidth
                  >
                    <Select
                      disabled
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      className="input-field-styling cohort-input-styling"
                      name='operator'
                      value={item.operator}
                    >
                      {operatorData?.map((option) => (
                        <MenuItem value={option.name} key={option.sortKey}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </Grid>
                <Grid item xs={3.8} sm={3.8}>
                  <Typography className="cohorts-detail-header">
                    Value
                  </Typography>
                  {

                    (item.operator === 'IS' || item.operator === 'IS NOT' || item.operator === 'CONTAINS' ? (
                      <TextField
                        key={index}
                        disabled
                        fullWidth
                        autoComplete="SENDER NAME"
                        label=""
                        name="values"
                        className="location-field input-field-styling top-margin-cohorts cohort-input-styling"
                        value={item.values[0]}

                        InputLabelProps={{
                          className: 'label-style-create-franchisee edit-address-label'
                        }} />
                    ) : item.operator === 'BETWEEN' ? (
                      <Grid container mt={0.2} spacing={2} key={index}>
                        <Grid item sx={{width:'50%'}}>
                          <CustomDatePicker name="values" disabled  fieldValue={convertIsoToNormalDate(item.values[0])} />
                          {/* {getDateFormat(item.values[0])} */}
                        </Grid>
                        <Grid item sx={{width:'50%'}}>
                          <CustomDatePicker name="values" disabled  fieldValue={convertIsoToNormalDate(item.values[1])}  />
                        </Grid>
                      </Grid>

                    ) : (
                      <Grid sx={{mt:2.2}} key={index}>
                        <CustomDatePicker name="values"  fieldValue={convertIsoToNormalDate(item.values[0])}  disabled />

                      {/* <TextField
                        name="values"
                        disabled
                        value={convertIsoToNormalDate(item.values[0])}
                        fullWidth
                        className="location-field input-field-styling top-margin-cohorts cohort-input-styling"
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <CalendarTodayIcon />
                            </IconButton>
                          ),
                        }}
                      /> */}


                      </Grid>

                    )

                    )

                  }
                </Grid>
              </Grid>




            </Grid>
          ))}
          <Typography className="table-header " sx={{ mx: 2, mt: 3 }}>
            {isMsg ? msg : ''}

          </Typography>

        </Card>
      </ServiceDetailsWrapper>


    </div>
  );
};
