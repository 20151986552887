import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import Loader from '../../../components/Loader';
import { ApiService } from '../../../services/ApiService';
import { getLocalData } from '../../../utils';
import {
  DeleteIcon,
  ArrowLeft,
  PictureSnapshot
} from 'src/assets/SVG/SvgIcons';
// import { HeaderContext } from 'src/contexts/HeaderContext';
// import rows from './data';
import {
  Box,
  Button,
  Container,
  Grid,
  Slide,
  Typography,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  DialogActions
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function FeaturedDetail({ setShowScreen, selectedData, getFeaturedItem }) {
  let userId = sessionStorage.getItem('UserId');
  const [open, setOpen] = React.useState(false);
  const [locationData, setLocationData] = useState([]);
  const [status, setStatus] = useState([]);
  const [locationName, setLocationName] = useState('');
  const franchiseeId = getLocalData('franchiseeId');
  let details = selectedData[0];
  let itemsData = details.itemData;
  console.log(selectedData[0], 'itemsData[0].name');
  console.log('details', details);
  const navigate = useNavigate();
  const handleCloseDetails = () => {
    setShowScreen(0);
    // setOpen(true);
  };
  const handleClosePopup = (val) => {
    if (val === 'Save') {
      setOpen(false);
      // setopenAddClient(true);
      // openModal(true);
    } else if (val === 'Discard') {
      setOpen(false);
      // setopenAddClient(false);
      // openModal(false);
      setShowScreen(0);
    }
  };
  useEffect(() => {
    // if (id) setSearchParams({ Id: id });

    // check if the user is logged in or not
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
    getLocation();
  }, [FeaturedDetail, navigate]);
  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  // const { setHeaderText } = useContext(HeaderContext);
  //   setHeaderText(details.title);
  const [isLoading, setIsLoading] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleClickEdit = () => {
    setShowScreen(3);
  };
  const handleClose = (val) => {
    if (val === 'Cancel') {
      setOpenDeleteDialog(false);
    } else if (val === 'message-close') {
      setOpenMessageDialog(false);
      setShowScreen(0);
    } else if (val === 'Delete') {
      setOpenDeleteDialog(false);
      deleteRecord();
    }
  };
  const getLocation = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}featured-items/getAllLocations?franchiseeId=${encodedFranchiseeId}`
      );
      setLocationData(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (details.status === 1) {
      setStatus('Active');
    } else if (details.status === 0) {
      setStatus('Inactive');
    }
    // Find the first matching location and set the locationName state
    const foundLocation = locationData.find(
      (value) => value.locationId === details.locationId
    );

    if (foundLocation) {
      setLocationName(foundLocation.locationName);
    }
  }, [locationData, details.locationId]);

  function getBillingCycleValue(value) {
    if (value) {
      return 'Per Year';
    }
    return 'Per Month';
  }

  const deleteRecord = () => {
    let updatedBy = userId;
    setIsLoading(true);
    // let locationId = encodeURIComponent(details.partitionKey);
    // let sortKey = encodeURIComponent(details.sortKey);
    let locationId = details.partitionKey;
    let sortKey = details.sortKey;
    let body = {
      locationId: locationId,
      id: sortKey,
      userId: updatedBy
    };

    fetch(
      // `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}featured-items/deleteFeaturedItem?locationId=${locationId}&id=${sortKey}&updatedBy=${updatedBy}`,
      `${process.env.REACT_APP_API_END_POINT}featured-items/deleteFeaturedItem`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(body)
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          setIsLoading(false);
          setOpenMessageDialog(true);
          getFeaturedItem();
        }

        // console.log(data); sx={{ marginLeft: '30px' }}
      });
  };
  return (
    <>
      <Loader IsLoading={isLoading} />
      <Container maxWidth="100%">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ mt: 1, mb: 3 }}
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                onClick={() => handleCloseDetails()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {details.title}
              </Typography>
            </Box>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                className="details-remove-icon no-hover"
                onClick={handleClickOpenDeleteDialog}
              >
                <DeleteIcon />
              </Button>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                onClick={handleClickEdit}
                className="btn-primary "
              >
                Edit
              </Button>
            </Box>
          </Grid>

          {/* <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box
                                onClick={() => handleCloseDetails()}
                                className="back-arrow-close featured-item-back-arrow"
                            >
                                <svg
                                    width="27"
                                    height="19"
                                    viewBox="0 0 27 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                                        fill="#003087"
                                    />
                                </svg>
                            </Box>
                            <Typography
                                className="featured-add-header"
                            >
                                Featured Item
                            </Typography>
                        </Box>
                    </Grid> */}

          {/* <Grid item>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                            <Box
                                onClick={() => handleCloseDetails()}
                                className="back-arrow-close">

                                <svg
                                    width="27"
                                    height="19"
                                    viewBox="0 0 27 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">

                                    <path
                                        d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                                        fill="#003087" />
                                </svg>
                            </Box>

                            <Typography className="featured-add-header">Featured Item</Typography>
                        </Box>
                    </Grid> */}
          {/* <Grid item>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button className="details-remove-icon"
                                onClick={handleClickOpenDeleteDialog}
                            >
                                <svg
                                    width="24"
                                    height="26"
                                    viewBox="0 0 24 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                                        fill="#8098C3" />
                                </svg>
                            </Button>
                            
                            <Button
                                sx={{ mt: { xs: 0, md: 0 } }}
                                variant="contained"
                                //   id={productDetails.productId}
                                onClick={handleClickEdit}
                                className="btn-primary edit-button"
                            >
                                Edit
                            </Button>
                        </Box>
                    </Grid> */}
        </Grid>
        <Box className="user-details-container">
          {/* <Grid container spacing={2} > */}
          <Grid container mt={2} className="details-row">
            <Grid item xs={12} sm={1.5} className="details-col">
              <Box className="details-label"> TITLE</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="details-col">
              <Box className="details-value">{details?.title}</Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="details-col">
              <Box className="details-label">LOCATION</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="details-col">
              <Box className="details-value">{locationName}</Box>
            </Grid>
          </Grid>
          <Grid container mt={2} className="details-row">
            <Grid item xs={12} sm={1.5} className="details-col">
              <Box className="details-label"> DESCRIPTION</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="details-col">
              <Box className="details-value">{details?.description}</Box>
            </Grid>
            <Grid item xs={12} sm={1.5} className="details-col">
              <Box className="details-label">STATUS</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="details-col">
              <Box className="details-value">{status}</Box>
            </Grid>
          </Grid>
          <Grid container mt={2} className="details-row">
            <Grid item xs={12} sm={1.5} className="details-col">
              <Box className="details-label">Image</Box>
            </Grid>
            <Grid item xs={12} sm={4.5} className="details-col">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {details?.featuredImage ? (
                  <Avatar
                    sx={{
                      height: '165px',
                      width: '165px',
                      borderRadius: '10%',
                      marginRight: '30px'
                    }}
                    src={details?.featuredImage}
                  />
                ) : (
                  <PictureSnapshot />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container sx={{ marginTop: '50px' }} maxWidth="100%">
        <h2 className="header-blue-22"> Item Added</h2>
        <Box className="user-details-container">
          {/* First Row */}
          <Grid container mt={2} className="details-row">
            <Grid item xs={12} sm={2} className="details-col">
              {details.itemType === 'Memberships' && (
                <Box className="details-label">Membership NAME</Box>
              )}
              {details.itemType === 'Contracts' && (
                <Box className="details-label"> Contract Name</Box>
              )}
              {details.itemType === 'Bundles' && (
                <Box className="details-label"> Bundle Name</Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4} className="details-col">
              <Box className="details-value">
                {
                  details.itemType === 'Memberships'
                    ? itemsData[0]?.name
                    : details.itemType === 'Contracts'
                    ? itemsData[0]?.contractName
                    : details.itemType === 'Bundles'
                    ? itemsData[0]?.bundleName
                    : null /* Add a default value or handle other cases */
                }
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="details-col">
              {details.itemType === 'Memberships' && (
                <Box className="details-label">Pricing Options</Box>
              )}
              {details.itemType === 'Contracts' && (
                <Box className="details-label"> Billing Frequency</Box>
              )}
              {details.itemType === 'Bundles' && (
                <Box className="details-label">PRICING OPTIONS & PRODUCTS</Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4} className="details-col">
              {details?.itemType === 'Memberships' &&
                itemsData[0]?.benefits.map((item, index) => (
                  <Box key={index} className="details-value">
                    {item?.itemName}
                  </Box>
                ))}
              {details?.itemType === 'Contracts' && (
                <Box className="details-value">
                  {itemsData?.[0]?.billingCycle ?? ''}
                </Box>
              )}
              {details?.itemType === 'Bundles' &&
                itemsData[0]?.items.map((item, index) => (
                  <span key={index} className="details-value">
                    {item?.itemName}
                    {index < itemsData[0].items.length - 1 && ', '}
                  </span>
                ))}
            </Grid>
          </Grid>

          {/* Second Row */}
          <Grid container mt={2} className="details-row">
            <Grid item xs={12} sm={2} className="details-col">
              {details.itemType === 'Memberships' && (
                <Box className="details-label">Active Members</Box>
              )}
              {details.itemType === 'Contracts' && (
                <Box className="details-label"> Contract Type</Box>
              )}
              {details.itemType === 'Bundles' && (
                <Box className="details-label">DISCOUNT</Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4} className="details-col">
              <Box className="details-value">
                {details.itemType === 'Memberships' &&
                  itemsData[0]?.activeMembers}
                {details.itemType === 'Contracts' && itemsData[0]?.contractType}
                {details.itemType === 'Bundles' && itemsData[0].discount + '%'}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="details-col">
              {details.itemType === 'Memberships' && (
                <Box className="details-label">Membership Price</Box>
              )}
              {details.itemType === 'Contracts' && (
                <Box className="details-label"> Sold Online</Box>
              )}
              {details.itemType === 'Bundles' && (
                <Box className="details-label"> Bundle Price</Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4} className="details-col">
              <Box className="details-value">
                {details.itemType === 'Memberships'
                  ? itemsData[0]?.price
                    ? '$' + itemsData[0].price
                    : ''
                  : ''}
                {details.itemType === 'Contracts'
                  ? itemsData[0]?.soldOnline
                    ? 'Yes'
                    : 'No'
                  : ''}
                {details.itemType === 'Bundles'
                  ? itemsData[0]?.bundlePrice
                    ? '$' + itemsData[0].bundlePrice
                    : ''
                  : ''}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={2} className="details-row">
            <Grid item xs={12} sm={2} className="details-col">
              {details.itemType === 'Memberships' && (
                <Box className="details-label">Billing Frequency</Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4} className="details-col">
              <Box className="details-value">
                {details.itemType === 'Memberships' &&
                  getBillingCycleValue(itemsData[0]?.billingCycle)}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Congratulations!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes, what do you want
                <Typography className="title-text-sub">to do?</Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            Discard changes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleClosePopup('Save')}
          >
            Go back to Editing
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactviate this record?
                
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMessageDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('message-close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Congratulations!'}
         </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected Featured Item has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('message-close')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default FeaturedDetail;
