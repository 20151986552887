/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Avatar,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Card,
  TableContainer,
  IconButton,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  CircularProgress
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useEffect, useState, useRef } from 'react'; // useRef
import { getLocalData } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { ArrowLeft, DeleteIcon } from 'src/assets/SVG/SvgIcons';
import TrophyAssign from './TrophyAssign';
import AddAlertNotes from './AddAlertNotes/AddAlertNotes';
import Alert from './Alert';
import Notes from './Notes';
import PurchaseHistory from './PurchaseHistory';
import UpcomingSchedule from './UpcomingSchedule';
import Credits from './Credits';
import { DynamicTabs } from '../../../components/ClientManagement';
import ClientContract from './ClientContract';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// ClientDetails page style wrapper
const ClientDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

// ClientDetails page main method

const ClientDetails = ({
  data,
  getItemForEdit,
  getEventToRefresh,
  setShowScreen,
  getSelectedPet,
  setSelectedClientItem
}) => {
  const childRef = useRef(null);
  const navigate = useNavigate();
  let clientData = data;
  let clientDetails = clientData;

  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  // Get the loggedIn user Id
  const userId = sessionStorage.getItem('UserId');
  // get the client id
  const [getClientId, setClientId] = useState('');
  let clientId;
  const [open, setOpen] = React.useState(false);
  // pet dialog
  // const [openPet, setOpenPet] = React.useState(false);
  // pet final age
  const [petAge, setPetAge] = useState('');
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [tabList, setTabList] = useState([]);

  // State to open the another pet dialog
  //   const [openAnotherPet, setOpenAnotherPet] = useState(false);

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const locationId =
    isFranchisor === 'true'
      ? localStorage.getItem('SelectedLocationId')
      : getLocalData('locationId');
  // set the pet details
  const [petDetails, setPetDetails] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    breed: '',
    bordetellaExpiration: '',
    biting: '',
    barking: '',
    allergies: '',
    advanced: '',
    acquisitionSource: '',
    dhppExpiration: '',
    notes: '',
    possessive: '',
    profileImage: '',
    rabiesExpiration: '',
    social: '',
    strangers: '',
    veterinarian: '',
    completed: {
      bordetella: false,
      dhpp: false,
      agility: false,
      rabies: false,
      swimming: false,
      treadmill: false
    }
  });

  // set the pet details
  const [allPets, setAllPets] = useState([]);
  const [childId, setPetId] = useState('');
  const theme = useTheme();
  const [anchor, setAnchor] = useState(null);
  const [getPetItem, setGetPetItem] = React.useState([]);
  /* State for opening of add team dialog box */
  const [isAddTeamOpen, setIsAddTeamOpen] = useState(false);
  const [openAddteam, setopenAddteam] = useState(false);
  const [openAlertNotes, setOpenAlertNotes] = useState(false);
  const [openAlertNotesDialog, setOpenAlertNotesDialog] = useState(false);
  const [invokeRefresh, setInvokeRefresh] = useState(false);

  // calculate age
  const calculateAge = (birthday) => {
    // milliseconds in a year 1000*24*60*60*365.24 = 31556736000;
    let today = new Date();
    // birthay has 'Dec 25 1998'
    // Split the string into an array based on the "-"
    let parts = birthday.split('-');
    // Reorder the array elements to reverse the date
    let reversedBirthday = parts[2] + '-' + parts[0] + '-' + parts[1];
    let dob = new Date(reversedBirthday);
    // let dob = new Date(birthday);
    // difference in milliseconds
    let diff = today.getTime() - dob.getTime();
    // convert milliseconds into years
    let years = Math.floor(diff / 31556736000);
    // 1 day has 86400000 milliseconds
    let daysDiff = Math.floor((diff % 31556736000) / 86400000);
    // 1 month has 30.4167 days
    let months = Math.floor(daysDiff / 30.4167);
    let days = Math.floor(daysDiff % 30.4167);
    let weeks = Math.floor(daysDiff / 7);
    if (years === 0 && months === 0 && days === 0) {
      return 'less than a day';
    }
    if (years === 0 && months === 0) {
      if (weeks === 1 || weeks < 15) {
        return `${weeks} weeks`;
      }
      return `${days} days`;
    }

    if (years === 0 && days === 0) {
      return `${months} months`;
    }

    if (months === 0 && days === 0) {
      return `${years} years`;
    }

    if (years === 0) {
      // if months is less then 15 week then show week else show months

      if (weeks < 15) {
        return `${weeks} weeks`;
      }
      return `${months} months ${days} days`;
    }

    if (months === 0) {
      return `${years} years ${days} days`;
    }

    if (days === 0) {
      return `${years} years ${months} months`;
    }

    let finalage = `${years} years ${months} months ${days} days`;
    finalage = finalage && finalage.replace(/-/g, ' ');
    return finalage;
    // return `${years} years ${months} months ${days} days`;
    // return `${years} years`;
  };

  // Method to open the delete dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      // call the api to delete the location
      deleteClient();
    }
    setOpen(false);
  };

  // Method to open the edit dialog
  const handleClickEdit = (e) => {
    let crossElm = document.getElementsByClassName('details-remove-icon')[0];
    if (crossElm) crossElm.click();

    let target = e && e.target;
    let lid;
    if (target) lid = target.getAttribute('id');

    // find an element from body
    let cls = 'edit_btn_' + lid;
    // getEventToRefresh();
    getItemForEdit(cls);

    // let elm = document.getElementsByClassName(cls)[0];
    // if (elm) elm.click();
    setShowScreen(2);
  };

  // Method to add another pet
  const addAnotherPet = () => {
    // open model to add another pet
    // setOpenAnotherPet(true);
    setShowScreen(4);
  };

  // useeffect state starts here
  useEffect(() => {
    clientId = clientDetails.sortKey;

    // if (clientDetails.clientId) setClientId(clientDetails.clientId);

    localStorage.setItem('clientId', clientId);
    // call the get client details method
    if (clientId) getClientDetails(clientId);
    // if (id) setSearchParams({ Id: id });

    // check if the user is logged in or not
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
    /*
        // remove page header text and add button hide
        let elm = document.getElementsByClassName('page-header-text')[0];
        if (elm) elm.innerHTML = '';
        let headerelm = document.getElementsByClassName('add-btn-container')[0];
        if (headerelm) headerelm.style.visibility = 'hidden';

        // Now get the location id from the url
        if (!hasRunRef.current) {
            // call the api to get the location details based on the id
            let locationId = searchParams.get("Id");

            // call the get location details method
            if (locationId) getLocationDetails(locationId);
        }
        */
  }, [clientDetails, navigate]);

  useEffect(() => {
    // Scrolling window on top at initial
    window.scroll(0, 0);
  }, [clientId]);

  // Method to delete the client
  const deleteClient = () => {
    // let clientId = clientId;
    // let clientEmail = clientDetails.email;

    // if (clientId) {
    // Now show the loader
    setIsLoading(true);
    // call the api to delete the location
    let url = `${process.env.REACT_APP_API_END_POINT}client`;

    let locId =
      isFranchisor === 'true'
        ? localStorage.getItem('SelectedLocationId')
        : clientDetails.locationId;

    const dataToDelete = {
      locationId: locId,
      email: clientDetails.email,
      clientId: clientDetails.sortKey,
      userId: userId
    };
    // execute the api
    fetch(`${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToDelete)
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          setOpenMessageDialog(true);
          // Now hide the loader
          setIsLoading(false);
          // // Hit the refresh method
          // getEventToRefresh();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // set error message
        setShowError(true);
        setErrorMessage(error.message);
        // Now hide the loader
        setIsLoading(false);
      });
  };

  // Method to get the client details

  const getClientDetails = (clientId) => {
    // call the api to get the location details
    setIsLoading(true);
    let encodedClientId = encodeURIComponent(clientId);
    let encodedLocationId = encodeURIComponent(locationId);
    let url = `${process.env.REACT_APP_API_END_POINT}client/getClientDetails?clientId=${encodedClientId}&locationId=${encodedLocationId}`;
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          setIsLoading(false);
          // set the location details
          let items = data.data.Items;
          setSelectedClientItem(items);
          if (items) clientDetails = items[0];
          let petage =
            clientDetails.pets && clientDetails.pets.length > 0
              ? clientDetails.pets[0].birthDate
              : '';
          // console.log('petage', petage, clientDetails.pets);
          if (petage) setAge(petage);
          else setPetAge('');

          // set pet details
          if (clientDetails.pets && clientDetails.pets.length > 0) {
            setAllPets(clientDetails.pets);
            setClientId(clientDetails.sortKey);
            setPetDetails(clientDetails.pets[0]);
          } else {
            setAllPets([]);
            setPetDetails({});
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
        // set error message
        setShowError(true);
        setErrorMessage(error.message);
      });
  };

  // set age
  const setAge = (age) => {
    let finalage = calculateAge(age);
    finalage = finalage.replace(/-/g, ' ');
    setPetAge(finalage);
    return finalage;
  };

  // Method to edit the pet
  const editPet = (petId, e, getClientId) => {
    e.stopPropagation();
    e.preventDefault();

    // set the pet id
    setPetId(petId);
    // open confirmation dialog
    // setOpenEditPet(true);
    getSelectedPet(petId, getClientId);
    setShowScreen(5);
  };

  // Method to delete the pet
  // const deletePet = (petId) => {
  //     // set the pet id
  //     setPetId(petId);
  //     // open confirmation dialog
  //     setOpenPet(true);
  // }

  // Method to close the delete dialog
  // const handlePetClose = (val) => {
  //     if (val === 'Delete') {
  //         // call the api to delete the location
  //         confirmDeletePet();
  //     }
  //     setOpenPet(false);
  // };

  // Method to delete the pet
  // const confirmDeletePet = () => {
  //     clientId = clientDetails.clientId;
  //     console.log("clientId", clientId, childId);
  //     if (childId) {
  //         // Now show the loader
  //         setIsLoading(true);
  //         // call the api to delete the location
  //         let url = `${process.env.REACT_APP_API_END_POINT}client/deleteChild?id=${childId}`;

  //         // execute the api
  //         fetch(`${url}`, {
  //             method: 'DELETE',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //             },
  //         })
  //             .then((response) => {
  //                 // console.log("response", response);
  //                 return response.json();
  //             })
  //             .then((data) => {
  //                 if (data) {
  //                     setPetId('');
  //                     // Now hide the loader
  //                     setIsLoading(false);
  //                     // Hit the refresh method
  //                     if (clientId) getClientDetails(clientId);
  //                 }
  //             })
  //             .catch((error) => {
  //                 console.error('Error:', error);
  //                 // set error message
  //                 setShowError(true);
  //                 setErrorMessage(error.message);
  //                 // Now hide the loader
  //                 setIsLoading(false);
  //             });
  //     }
  // }

  const handleCloseDetails = () => {
    setShowScreen(0);
  };

  // View location details
  const handleViewPetDetails = (petId, e, getClientId) => {
    e.preventDefault();
    e.stopPropagation();
    if (e && e.target && !e.target.classList.contains('MuiBackdrop-root')) {
      getSelectedPet(petId, getClientId);
      setShowScreen(6);
    }
  };

  const handleDelete = () => {
    setShowScreen(0);
    setOpenMessageDialog(false);
    // Hit the refresh method
    getEventToRefresh();
  };

  const openMenu = (pet, event) => {
    event.preventDefault();
    event.stopPropagation();
    setGetPetItem(pet);

    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (pet, event, value) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchor(null);

    if (value === 'addAlert') {
      setOpenAlertNotes(true);
      setOpenAlertNotesDialog(true);
    } else if (value === 'assignTrophy') {
      setIsAddTeamOpen(true);
    }
  };

  // Method to refresh the alert and notes tab
  const refreshAlertNotes = () => {
    // Hit the refresh method
    setInvokeRefresh(true);
  };

  useEffect(() => {
    // if (getClientId) {
    setTabList([
      {
        label: 'Alerts',
        customLabel: 'Alerts',
        component: (
          <Alert
            clientId={getClientId}
            invokeRefresh={invokeRefresh}
            setInvokeRefresh={setInvokeRefresh}
          />
        )
      },
      {
        label: 'Notes',
        customLabel: 'Notes',
        component: (
          <Notes
            clientId={getClientId}
            invokeRefresh={invokeRefresh}
            setInvokeRefresh={setInvokeRefresh}
          />
        )
      },
      {
        label: 'Purchase History',
        customLabel: 'Purchase History',
        component: <PurchaseHistory clientId={getClientId} allPets={allPets} />
      },
      {
        label: 'Upcoming Schedule',
        customLabel: 'Upcoming Schedule',
        component: <UpcomingSchedule clientId={getClientId} allPets={allPets} />
      },
      {
        label: 'Credits',
        customLabel: 'Credits',
        component: (
          // <Box className=' p-20 font-16'>
          //   <Typography variant="h4" sx={{ mb: 2 }}>
          //     No credits available
          //   </Typography>
          // </Box>
          <Credits clientId={getClientId} />
        )
      },
      {
        label: 'Contract',
        customLabel: 'Contract',
        component: (
          <ClientContract clientId={getClientId} />
        )
      }
    ]);
    setLoading(false);
    // }
  }, [getClientId, invokeRefresh]);
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Loader IsLoading={isLoading} />

      {/* {openAnotherPet && <AddAnotherPet petopen={openAnotherPet} setPetOpen={setOpenAnotherPet} clientId={clientId} getEventToRefresh={getEventToRefresh} />} */}

      <ClientDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper"
        // sx={{padding: "2rem"}}
      >
        {/* <Grid container className="location-header-container">
          <Grid
            xs={6}
            sm={6}
            md={6}
            lg={6}
            className="page-header title-adjust"
          >
            <Box
              onClick={() => handleCloseDetails()}
              className="back-arrow-close"
            >
              <svg
                width="27"
                height="19"
                viewBox="0 0 27 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                  fill="#003087"
                />
              </svg>
            </Box>
            <Typography
              className="header-blue-32"
  
            >
              Add Pet
            </Typography>
          </Grid>

          <Grid xs={6} sm={6} md={6} lg={6} className="align-right">

            <Button className="details-remove-icon no-hover" onClick={handleClickOpen}>
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                  fill="#8098C3"
                />
              </svg>
            </Button>
            <Button
              sx={{
                mt: { xs: 0, md: 0 },
                mr: 2,
                border: '2px solid #003087 !important',
                color: '#003087 !important',
                padding: '6px 20px !important'
              }}
              variant="outlined"
              className="btn-primary"
              id={clientDetails.clientId}
              onClick={addAnotherPet}
            >
              Add Another Pet
            </Button>

            <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              id={clientDetails.clientId}
              onClick={handleClickEdit}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid> */}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleCloseDetails()}
                className="back-arrow-close"
              >
                {/* left arrow icon */}
                <ArrowLeft />
              </Box>
              <Typography className="header-blue-32">
                {clientData.firstName} {clientData.lastName}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                className="details-remove-icon no-hover"
                onClick={handleClickOpen}
              >
                {/* delete icon */}
                <DeleteIcon />
              </Button>
              <Button
                sx={{
                  mt: { xs: 0, md: 0 },
                  mr: 2,
                  border: '2px solid #003087 !important',
                  color: '#003087 !important',
                  padding: '6px 20px !important'
                }}
                variant="outlined"
                className="btn-primary"
                id={clientDetails.clientId}
                onClick={addAnotherPet}
              >
                Add Another Dog
              </Button>
              {/* Edit Button */}
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                id={clientDetails.clientId}
                onClick={handleClickEdit}
                className="btn-primary edit-button"
              >
                Edit
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box className="location-tab-container" marginY="40px">
          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            <DynamicTabs list={tabList || []} />
          )}
        </Box>

        <Box className="location-details-container">
          {/* <Grid container spacing={2} > */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">LOCATION</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.locationName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">NAME</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.firstName} {clientDetails.lastName}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">EMAIL</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.email || ''}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">MOBILE NUMBER</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.mobilePhone}
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label">WORK PHONE</Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.workPhone}
              </Box>
            </Grid> */}
          </Grid>
          <Grid container mt={3} className="location-details-row">
            {/* <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label">HOME PHONE</Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.homePhone}
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">DATE OF BIRTH</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.birthDate}
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">ADDRESS</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.addressLine1} {clientDetails?.addressLine2},{' '}
                {clientDetails?.city}, {clientDetails?.state},{' '}
                {clientDetails?.postalCode}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">STATUS</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.status === 1 && 'Active'}
                {clientDetails.status === 0 && 'Inactive'}
                {clientDetails.status === 2 && 'Deleted'}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid
              item
              xs={12}
              sm={2}
              className="location-details-col d-flex align-items-center"
            >
              <Box className="location-details-label">PROFILE IMAGE</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value location-image">
                <Avatar
                  alt="icon"
                  src={
                    clientDetails.profileImage ||
                    '/static/images/logo/cardboardicon.png'
                  }
                  // sx={{ marginLeft: 2 }}
                  sx={{ width: '60px', height: '60px' }}
                  className="card-avatar-img"
                />
              </Box>
            </Grid>
          </Grid>
          {/* <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={1.4} className="location-details-col">
              <Box className="location-details-label">STATUS</Box>
            </Grid>
            <Grid item xs={12} sm={4.6} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.status === 1 && 'Active'}
                {clientDetails.status === 0 && 'Inactive'}
                {clientDetails.status === 2 && 'Deleted'}
              </Box>
            </Grid>
          </Grid> */}
          {/* <Grid container mt={3} className="location-details-row">
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">PROFILE IMAGE</Box>
                            <Box className="location-details-value location-image">
                                <Avatar
                                    alt="icon"
                                    src={clientDetails.profileImage || '/static/images/logo/cardboardicon.png'}
                                    sx={{ marginLeft: 2 }}
                                    className='card-avatar-img'
                                />
                            </Box>
                        </Grid>
                    </Grid> */}

          {/* Emergency Contact Person */}
          {/* <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="header-blue-22 mt-20"
          >
            <span className="location-name text-wrap mb-20">
              Emergency Contact Person
            </span>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">NAME</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.emergencyContactName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">EMAIL</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.emergencyContactEmail}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">PHONE</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.emergencyContactPhone}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">RELATIONSHIP</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails.emergencyContactRelationShip}
              </Box>
            </Grid>
          </Grid> */}

          {/* PET Details */}
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="header-blue-22 mt-10 mb-10"
          >
            <span className="location-name text-wrap mt-10">
              Associated Dog
            </span>
          </Grid>
          <Grid container spacing={2}>
            <Grid width={1} item>
              {/* Add new team condition block sx={{ paddingLeft: '0px !important' }} */}
              <Card className="p-15">
                {/* <CardHeader
              action={
                <Button
                  sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                  variant="contained"
                  className="btn-primary"
                  onClick={addNewTeam}
                >
                  Add Team
                </Button>
              }
              title="All Teams"
              className="table-header-name text-primary font-20"
            /> */}

                {/* <Divider /> */}
                <TableContainer className="franchise-table-container">
                  <Table>
                    <TableHead>
                      <TableRow className="table-header">
                        <TableCell sx={{ minWidth: '250px' }}>
                          Dog Name
                        </TableCell>
                        <TableCell>Age</TableCell>
                        <TableCell>Breed</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(allPets.length > 0 &&
                        allPets.map((pet) => {
                          return (
                            <TableRow
                              hover
                              key={pet.sortKey}
                              // selected={isTeamItemSelected}
                              className="table-row cursor-pointer"
                              onClick={(e) =>
                                handleViewPetDetails(
                                  pet.sortKey,
                                  e,
                                  getClientId
                                )
                              }
                            >
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  sx={{ marginTop: 1 }}
                                  gutterBottom
                                  className="d-flex align-items-center"
                                >
                                  <Typography>
                                    <Avatar
                                      alt="icon"
                                      src={
                                        pet.profileImage ||
                                        '/static/images/logo/cardboardicon.png'
                                      }
                                      className="card-avatar-img mr-10"
                                    />
                                  </Typography>
                                  <Typography>
                                    {' '}
                                    {/* {pet.firstName + ' ' + pet.lastName} */}
                                    {pet?.firstName} {pet?.lastName}
                                  </Typography>
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                >
                                  {pet.birthDate
                                    ? calculateAge(pet.birthDate)
                                    : null}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                >
                                  {pet.breed}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                {/* <IconButton
                                                                sx={{
                                                                    '&:hover': { background: theme.colors.error.lighter },
                                                                    color: theme.palette.error.main,
                                                                    borderRadius:"50% !important"
                                                                }}
                                                                color="inherit"
                                                                size="medium"
                                                                onClick={() => deletePet(pet.childId)}
                                                            >
                                                                <DeleteOutlineOutlinedIcon fontSize="medium" />
                                                            </IconButton> */}

                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.secondary.lighter
                                    },
                                    color: theme.palette.primary.main,
                                    borderRadius: '50% !important'
                                  }}
                                  color="inherit"
                                  size="medium"
                                  borderRadius="50% !important"
                                  onClick={(e) =>
                                    editPet(pet.sortKey, e, getClientId)
                                  }
                                >
                                  <EditTwoToneIcon fontSize="medium" />
                                </IconButton>
                              </TableCell>
                              <TableCell className="table-td">
                                <MoreVertIcon
                                  onClick={(e) => openMenu(pet, e)}
                                  color="grey"
                                  variant="contained"
                                  className="three-dots-icon"
                                />

                                <Menu
                                  open={Boolean(anchor)}
                                  anchorEl={anchor}
                                  onClose={closeMenu}
                                  keepMounted
                                  PaperProps={{
                                    style: {
                                      maxHeight: 40 * 5,
                                      width: '25ch'
                                    }
                                  }}
                                >
                                  {/* <MenuItem
                                    onClick={(e) =>
                                      onMenuItemClick(pet, e, 'RemoveDog')
                                    }
                                    disabled
                                  >
                                    Remove Dog
                                  </MenuItem> */}
                                  <MenuItem
                                    onClick={(e) =>
                                      onMenuItemClick(pet, e, 'addAlert')
                                    }
                                    // disabled
                                  >
                                    Add Alert & Notes
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) =>
                                      onMenuItemClick(pet, e, 'assignTrophy')
                                    }
                                  >
                                    Assign Trophy
                                  </MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        })) || <Box className="p-15">No dogs found</Box>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>

          {/* <Grid container mt={3} className="location-details-row">
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">NAME</Box>
                            <Box className="location-details-value">{petDetails.firstName}</Box>
                        </Grid>
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">GENDER</Box>
                            <Box className="location-details-value">{petDetails.gender}</Box>
                        </Grid>
                    </Grid>
                    <Grid container mt={3} className="location-details-row">
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">Acquisition Source</Box>
                            <Box className="location-details-value">{petDetails.acquisitionSource}</Box>
                        </Grid>
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">BREED</Box>
                            <Box className="location-details-value">{petDetails.breed}</Box>
                        </Grid>
                    </Grid>
                    <Grid container mt={3} className="location-details-row">
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">Veterinarian</Box>
                            <Box className="location-details-value">{petDetails.veterinarian ? 'Yes' : 'No'}</Box>
                        </Grid>
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">ALLERGIES</Box>
                            <Box className="location-details-value">{petDetails.allergies}</Box>
                        </Grid>
                    </Grid>
                    <Grid container mt={3} className="location-details-row">
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">CALCULATED AGE</Box>
                            <Box className="location-details-value">Your dog is {petAge} old</Box>
                        </Grid>
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">PROFILE IMAGE</Box>
                            <Box className="location-details-value">
                                <Box className="location-details-value location-image">
                                    <Avatar
                                        alt="icon"
                                        src={petDetails.profileImage || '/static/images/logo/cardboardicon.png'}
                                        sx={{ marginLeft: 2 }}
                                        className='card-avatar-img'
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container mt={3} className="location-details-row">
                        <Table className="client-table-container">
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell>Question</TableCell>
                                    <TableCell>Answer</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {"Does your dog bark at other dogs when out on a walk or at you for attention?"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {petDetails.barking}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {"Is your dog afraid of stranger or certain groups of people?"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {petDetails.strangers}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {"Has your dog ever bitten a person or another dog, regardless or injury (not including nipping or play biting)?"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {petDetails.biting}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {"Is your dog very possessive about his/her food or toys?"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {petDetails.possessive}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {"Do you believe your dog is able to skip basic obedience and take advanced classes or agility?"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {petDetails.advanced}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {"Is your dog social?"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                        >
                                            {petDetails.social}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid container mt={3} className="location-details-row">
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">NOTES</Box>
                            <Box className="location-details-value">{petDetails.notes}</Box>
                        </Grid>
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">COMPLETED</Box>
                            <Box className="location-details-value">
                                {petDetails && petDetails?.completed?.agility === "Yes" ? "Agility" : ""}
                                {petDetails && petDetails?.completed?.evaluation === "Yes" || petDetails?.completed?.orientation === "Yes" || petDetails?.completed?.tricks === "Yes" ? ", " : ""}
                                {petDetails && petDetails?.completed?.evaluation === "Yes" ? "Evaluation" : ""}
                                {petDetails && petDetails?.completed?.orientation === "Yes" || petDetails?.completed?.tricks === "Yes" ? ", " : ""}
                                {petDetails && petDetails?.completed?.orientation === "Yes" ? "Orientation" : ""}
                                {petDetails && petDetails?.completed?.tricks === "Yes" ? ", " : ""}
                                {petDetails && petDetails?.completed?.tricks === "Yes" ? "Tricks" : ""}
                            </Box>
                        </Grid>
                    </Grid> */}

          {/* Vaccination Details Details */}
          {/* <Grid xs={12} sm={12} md={12} lg={12} className="page-header title-adjust mt-10">
                        <span className="location-name text-wrap">Vaccination Details</span>
                    </Grid>
                    <Grid container mt={3} className="location-details-row">

                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">RABIES EXP DATE</Box>
                            <Box className="location-details-value">{petDetails.rabiesExpiration}</Box>
                        </Grid>
                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">BORDETELLA EXP DATE</Box>
                            <Box className="location-details-value">{petDetails.bordetellaExpiration}</Box>
                        </Grid>
                    </Grid>
                    <Grid container mt={3} className="location-details-row">

                        <Grid item xs={12} sm={6} className="location-details-col">
                            <Box className="location-details-label">COMBO SHOT EXP DATE</Box>
                            <Box className="location-details-value">{petDetails.dhppExpiration}</Box>
                        </Grid>
                    </Grid> */}

          {/* </Grid> */}
          {showError && (
            <Grid container mt={3} className="location-details-row">
              <Box className="error-message">{errorMessage}</Box>
            </Grid>
          )}
        </Box>
      </ClientDetailsWrapper>
      {isAddTeamOpen && (
        <TrophyAssign
          openAddteam={openAddteam}
          setopenAddteam={setopenAddteam}
          setIsAddTeamOpen={setIsAddTeamOpen}
          getPetItem={getPetItem}
          getEventToRefresh={getEventToRefresh}
        />
      )}

      {/* Add alert and notes */}
      <Box>
        {openAlertNotes && (
          <AddAlertNotes
            openAlertNotesDialog={openAlertNotesDialog}
            setOpenAlertNotesDialog={setOpenAlertNotesDialog}
            setOpenAlertNotes={setOpenAlertNotes}
            // getEventToRefresh={getEventToRefresh}
            refreshAlertNotes={refreshAlertNotes}
            getClientItem={getPetItem}
            type={'pet'}
          />
        )}
      </Box>

      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactviate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMessageDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDelete('message-close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Congratulations!'}
         </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected client has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleDelete('message-close')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete pet confirmation dialog */}
      {/* <Dialog
                open={openPet}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handlePetClose("Cancel")}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="alert-title align-center">{'Delete'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className="alert-decription align-center">
                        <Box display="flex" justifyContent="center" direction="column">
                            <Typography className="title-text-sub">
                                Are you sure you want to delete this pet?
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions className="alert-button-control">
                    <Button className="font-17 no-hover" onClick={() => handlePetClose("Delete")}>Delete</Button>
                    <Divider className="vertical-divider" />
                    <Button className="font-17 cancel-btn no-hover" onClick={() => handlePetClose("Cancel")}>Cancel</Button>
                </DialogActions>
            </Dialog> */}
    </>
  );
};

export default ClientDetails;
