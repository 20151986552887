/* eslint-disable no-unused-vars */
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';
//   import { FiUsers } from 'react-icons/fi';
//   import { FaCalendarAlt } from 'react-icons/fa';
import { TransferAccordian } from 'src/components/Inventory/TransferAccordian';
import { CustomButton } from 'src/components/Global';
import { getLocalData } from '../../../utils';

// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';

export const ReceiverIncomingSummary = (props) => {
    const {
        handleOpenScreen,
        setIsInitialState,
        notesData,
        selectedReason,
        selectedRowsData,
        postData,
        quantity,
        btnLoading,
        totalCost,
        fromLocationName,
        toLocationName,
        transferAddress,
        transactionId,
        fromLocationId
    } = props;
    const navigate = useNavigate();
    const locationId = getLocalData('locationId');
    const handleSummaryClose = (type) => {
        let transId = transactionId;
        console.log(transactionId);

        if (type === 'close') {
            handleOpenScreen(1);
            setIsInitialState(true);
            // now close the popup
            // setOpen(false);
            navigate(
                // '/inventory-transfer?transactionId=' + encodeURIComponent(transId)
                '/inventory-revise?transactionId=' + encodeURIComponent(transId)
            );
        }
    };

    // const date = new Date();
    // const day = date.getDate().toString().padStart(2, '0');
    // const month = (date.getMonth() + 1).toString().padStart(2, '0');
    // const year = date.getFullYear().toString();
    // const currentDate = `${day}-${month}-${year}`;

    return (
        <div className="service-container order-container">
            <Box
                sx={{ width: '100%' }}
                paddingBottom="20px"
                className="Main-container common-form-container display-inline mt-10"
            >
                <Stack direction="column" className="container">
                    <Typography
                        variant="variant2"
                        sx={{
                            fontSize: '24px',
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}
                        className='text-primary weight-600'
                    >
                        {/* <KeyboardBackspaceIcon
                color="primary"
                sx={{
                  marginTop: '2px',
                  width: '30px',
                  height: '30px',
                  cursor: 'pointer'
                }}
                onClick={handleSummaryClose}
              /> */}
                        Summary: {locationId === fromLocationId ? 'Outgoing Inventory' : 'Incoming Inventory'}
                    </Typography>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell />
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box display="flex" alignItems="center" gap={4}>
                                            <svg
                                                width="25"
                                                height="25"
                                                viewBox="0 0 25 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M19.0807 9.43359L19.6807 12.4336H5.7607L6.3607 9.43359H19.0807ZM20.7207 4.43359H4.7207V6.43359H20.7207V4.43359ZM20.7207 7.43359H4.7207L3.7207 12.4336V14.4336H4.7207V20.4336H14.7207V14.4336H18.7207V20.4336H20.7207V14.4336H21.7207V12.4336L20.7207 7.43359ZM6.7207 18.4336V14.4336H12.7207V18.4336H6.7207Z"
                                                    fill="#E35205"
                                                />
                                            </svg>
                                            <h3 className="accordian-header">Transferring Store</h3>
                                        </Box>
                                        <Typography className="accordian-header">
                                            {fromLocationName}
                                        </Typography>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box display="flex" alignItems="center" gap={4}>
                                            <svg
                                                width="25"
                                                height="25"
                                                viewBox="0 0 25 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M19.0807 9.43359L19.6807 12.4336H5.7607L6.3607 9.43359H19.0807ZM20.7207 4.43359H4.7207V6.43359H20.7207V4.43359ZM20.7207 7.43359H4.7207L3.7207 12.4336V14.4336H4.7207V20.4336H14.7207V14.4336H18.7207V20.4336H20.7207V14.4336H21.7207V12.4336L20.7207 7.43359ZM6.7207 18.4336V14.4336H12.7207V18.4336H6.7207Z"
                                                    fill="#E35205"
                                                />
                                            </svg>
                                            <h3 className="accordian-header">Receiving Store</h3>
                                        </Box>
                                        <Typography className="accordian-header">{toLocationName}</Typography>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box display="flex" alignItems="center" gap={4}>
                                            <svg
                                                width="25"
                                                height="25"
                                                viewBox="0 0 25 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12.7207 6.93359C13.3837 6.93359 14.0196 7.19699 14.4885 7.66583C14.9573 8.13467 15.2207 8.77055 15.2207 9.43359C15.2207 9.7619 15.156 10.087 15.0304 10.3903C14.9048 10.6936 14.7206 10.9692 14.4885 11.2014C14.2563 11.4335 13.9807 11.6177 13.6774 11.7433C13.3741 11.8689 13.049 11.9336 12.7207 11.9336C12.0577 11.9336 11.4218 11.6702 10.9529 11.2014C10.4841 10.7325 10.2207 10.0966 10.2207 9.43359C10.2207 8.77055 10.4841 8.13467 10.9529 7.66583C11.4218 7.19699 12.0577 6.93359 12.7207 6.93359ZM12.7207 2.43359C14.5772 2.43359 16.3577 3.17109 17.6705 4.48385C18.9832 5.7966 19.7207 7.57708 19.7207 9.43359C19.7207 14.6836 12.7207 22.4336 12.7207 22.4336C12.7207 22.4336 5.7207 14.6836 5.7207 9.43359C5.7207 7.57708 6.4582 5.7966 7.77096 4.48385C9.08371 3.17109 10.8642 2.43359 12.7207 2.43359ZM12.7207 4.43359C11.3946 4.43359 10.1229 4.96038 9.18517 5.89806C8.24749 6.83574 7.7207 8.10751 7.7207 9.43359C7.7207 10.4336 7.7207 12.4336 12.7207 19.1436C17.7207 12.4336 17.7207 10.4336 17.7207 9.43359C17.7207 8.10751 17.1939 6.83574 16.2562 5.89806C15.3186 4.96038 14.0468 4.43359 12.7207 4.43359Z"
                                                    fill="#E35205"
                                                />
                                            </svg>
                                            <h3 className="accordian-header">Transfer Address</h3>
                                        </Box>
                                        <Typography className="accordian-header">{transferAddress}</Typography>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TransferAccordian
                                    selectedReason={selectedReason}
                                    notesData={notesData}
                                />
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>

                <Stack direction="column" className="container" marginTop={2}>
                    <Typography
                        variant="variant2"
                        sx={{ fontSize: '24px', textAlign: 'left' }}
                        className='text-primary weight-600'
                    >
                        Transfer Items
                    </Typography>
                    <Table>
                        {/* <TableHead>
                        <TableRow>
                        <TableCell className="accordian-header">Title</TableCell>
                        <TableCell>Packing</TableCell>
                        <TableCell>Code</TableCell>
                        <TableCell>Expiry Date</TableCell>
        
                        <TableCell className="accordian-header">Qty</TableCell>
                        </TableRow>
                      </TableHead> */}
                        <TableBody>
                            {selectedRowsData?.map((item) => {
                                return (
                                    <>
                                        <TableRow>
                                            <TableCell className='text-black'>{item.fName}</TableCell>
                                            <TableCell className='text-black'>{item.mfgName}</TableCell>
                                            <TableCell className='text-black'>{item.sku}</TableCell>
                                            <TableCell className='text-black'>{item.expiryDate}</TableCell>
                                            <TableCell className='text-black'>{quantity[item.sortKey]}</TableCell>
                                        </TableRow>
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Stack>

                <Stack direction="column" className="container" marginTop={2}>
                    <Typography
                        variant="variant2"
                        sx={{ fontSize: '24px', textAlign: 'left' }}
                        className='text-primary weight-600'
                    >
                        Inventory Value
                    </Typography>
                    <Table>

                        <TableBody>
                            {/* return (
                    <> */}
                            <TableRow>
                                <TableCell className='text-black'>TOTAL</TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell className='text-black align-right'>${totalCost}</TableCell>
                            </TableRow>
                            {/* </>
                  ); */}
                        </TableBody>
                    </Table>
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={2}
                    marginTop={5}
                    className="summary-actions"
                >
                    {/* <CustomButton
                        disabled={btnLoading}
                        variant="outlined"
                        // onClick={handleSummaryClose}
                        onClick={() => handleSummaryClose('close')}
                    >
                        Revise Transfer Order
                    </CustomButton> */}
                    {locationId !== fromLocationId &&
                        <CustomButton
                            loading={btnLoading}
                            variant="contained"
                            onClick={() => postData('received')}
                        >
                            Confirm & Receive
                        </CustomButton>
                    }
                </Stack>
            </Box>
        </div>
    );
};  