import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState, useRef } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import Footer from 'src/components/Footer';
import { Box, Container, Grid } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import CreateUser from './createUser';
import GlobalData from '../../../global';
import UserListAPI from './userListAPI';
import AllTeams from './AllTeams';
import Header from '../../../components/Header';
// import { ApiService } from '../../../services/ApiService';

// const [isDialogClosing, setIsDialogClosing] = React.useState(false);

function UserList() {
  const navigate = useNavigate();
  // Set the header text
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('User Management');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'User Management';

  // Get the title from global data
  const title = GlobalData[0].title;
  const childRef = useRef(null);

  // const [openUser, setUserOpen] = React.useState(false);
  // new user or all teams
  // const [isTeams, setIsTeams] = React.useState(false);
  // all teams list
 // const [allTeams, setAllTeams] = React.useState([]);
  // const [openTeams, setOpenTeams] = React.useState(true);
  // const [refreshTeams,setRefreshTeams]=useState(false);
  // const [isDialogClosing, setIsDialogClosing] = React.useState(false);
  /* State to check the field is update or not */

  const [isFieldUpdated, setisFieldUpdated] = useState(false);

  const [showScreen, setShowScreen] = useState(0);

  useEffect(() => {
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }

    // Get all the teams
   // getAllTeams();
  }, []);

  //  useEffect(() => {
  //   getAllTeams();

  //  },refreshTeams)

  //  const refreshTeamsTable = () => {
  //   setRefreshTeams(false);
  //  }

  // Method to open the dialog
  // const handleClickOpen = () => {
  //   setUserOpen(true);
  // };

  // Method to close the dialog
  // const handleClose = () => {
  //   // setIsDialogClosing(true);
  //   setOpenTeams(true);
  //   setUserOpen(false);
  // };

  // Method to close the dialog
  // const handleClose = (val) => {
  //   // eslint-disable-next-line no-debugger

  //   if (val === 'Close') {
  //     if (isFieldUpdated) {
  //       setOpen(true);
  //       setUserOpen(true);
  //     } else {
  //       setOpen(false);
  //       setUserOpen(false);
  //     }
  //   }
  // };

  // const closeAllTeams = () => {
  //   getAllTeams();
 
  // };

  // Method to close the franchisee form
  //   const closeFranchiseeDialog = () => {
  //     // trigger the parent method to refresh the data
  //     childRef.current.refreshUserList();
  // };
  // const handleManageClick = () => {
  //   setShowScreen(4);
  // };

  const handleButtonClick = () => {
    setShowScreen(1);
  };

  const buttons = [
    {
      label: 'Add User',
      onClick: handleButtonClick,
      theam: true
    },
    // {
    //   label: 'Teams',
    //   theam: false,
    //   onClick: handleManageClick
    // }
  ];
  // Get All the teams method
  // const getAllTeams = async () => {
  //   ApiService.get(`${process.env.REACT_APP_API_END_POINT}teams`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.statusCode && !data.error) {
  //         // Set the list of teams
  //         setAllTeams(data.data.Items);
  //       }
  //     });
  // };

  return (
    <>
      <Helmet>
        <title>Users Management | {title}</title>
      </Helmet>

      <Container
        maxWidth="100%"
        sx={{ mt: 0 }}
        className="main-container relative"
      >
        {(() => {
          switch (showScreen) {
            case 1:
              return (
                <CreateUser
                  setisFieldUpdated={setisFieldUpdated}
                  isFieldUpdated={isFieldUpdated}
                  // UserAdded={closeFranchiseeDialog}
                  setShowScreen={setShowScreen}
                />
              );

            case 4:
              return (
                <Box justifyContent="center" direction="column">
                  <AllTeams
                 //   teamsLists={allTeams}
                  //  getAllTeams={getAllTeams}
                  //  closeAllTeams={closeAllTeams}
                    setShowScreen={setShowScreen}
                  />
                </Box>
              );

            default:
              return (
                // <Grid
                //   container
                //   direction="row"
                //   justifyContent="center"
                //   alignItems="stretch"
                //   spacing={0}
                // >

                <Grid item width={'100%'}>
                  {showScreen === 0 && (
                    <Header
                      title="Users Management"
                      buttons={buttons}
                      sx={{ mx: 0 }}
                    />
                  )}
                  {/* Now draw the USer table */}
                  <UserListAPI
                    ref={childRef}
                    showScreen={showScreen}
                    setShowScreen={setShowScreen}
                  />
                </Grid>
                // </Grid>
              );
          }
        })()}

        {/* User dialog */}
        {/* {openTeams ? (
          <Dialog
            open={openUser}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleClose('Cancel')}
            aria-describedby="details-dialog" */}
        {/* if it is all teams then show the popup in center not from right */}
        {/* className={isTeams ? 'not dialog-box dialog-box-right client' : 'dialog-box dialog-box-right client'}
            maxWidth="lg"
          > */}
        {/* <DialogTitle className={isTeams ? 'not alert-title align-right dialog-header' : 'alert-title align-right dialog-header'}> */}
        {/* {isTeams && <Box>All Teams</Box>} */}
        {/* <Box> */}
        {/* remove icon */}
        {/* <Button type='button' className="details-remove-icon user-close p-0 no-hover" onClick={() => handleClose('Close')}> */}
        {/* If it is all teams then show the close icon red */}
        {/* {isTeams ? (
                  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z" fill="#E64646" />
                </svg>
                ) : ( */}
        {/* <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="13" cy="13" r="13" fill="white" />
                    <path
                      d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                      fill="#003087"
                    />
                  </svg> */}
        {/* )} */}
        {/* </Button>
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription"
              >
                
              </DialogContentText>
            </DialogContent> */}
        {/* <Divider /> {/* <EditFranchisee data={ selectedFranchiseeItem } /> */}
        {/* <DialogActions className="alert-button-control">
          <Button className="font-17" onClick={() => handleClose("Delete")}>Delete</Button>
          <Divider className="vertical-divider" />
          <Button className="font-17 cancel-btn" onClick={() => handleClose("Cancel")}>Cancel</Button>
        </DialogActions> */}
        {/* </Dialog>
        ) : 

        } */}
      </Container>
      <Footer />
    </>
  );
}

export default UserList;
