import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import './styles.css';
import FeaturedDetail from './FeaturedItemDetails';
import { CustomTable } from '../../../components/CustomTable';
import { getLocalData } from '../../../utils';
import { FeaturedItemsFilter } from '../../../components/Filters/FeaturedItemsFilter';
import CreateFeaturedItem from './createFeaturedItem';
import FeaturedItemEdit from './FeaturedItemEdit';
import { ApiService } from '../../../services/ApiService';
import { filtersQuery } from '../../../utils/index';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import Loader from '../../../components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DrawFeaturedItemTable = ({ showScreen, setShowScreen }) => {
  const locationId = getLocalData('locationId');
  // const [showScreen, setShowScreen] = useState(0);
  // const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [isView, setIsView] = useState(0); // 0 = main view, 1 = detail, 2 = edit view
  const [open, setOpen] = React.useState(false);
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
 // const [isLoading, setIsLoading] = useState(false);
  const [selectedLists, setSelectedLists] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const userId = sessionStorage.getItem('UserId');

  let [filters, setFilters] = useState({
    // isActive: '',
    status: 1,
    itemType: ''
  });

  const getFeaturedItem = async () => {
    setLoading(true);
    let queryString = await filtersQuery(filters);
    let filtersParams = queryString ? `&${queryString}` : '';
    const response = await ApiService.get(
      `${
        process.env.REACT_APP_API_END_POINT
      }featured-items/getAllFeaturedItems?locationId=${encodeURIComponent(
        locationId
      )}${filtersParams}`
    );
    setLoading(false);
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    const result = Item.map((obj) => {
      const { partitionKey, sortKey, title, description, itemType, status } =
        obj;
        const descriptionWords = description.split(' ').slice(0, 7).join(' ');
      return {
        partitionKey,
        sortKey,
        title,
        description: descriptionWords + (descriptionWords !== description ? '...' : ''),
        'Item Included': itemType,
        // Status: status === 1 ? 'Active' : 'InActive'
        status: status === 1 ? <ActiveStatus /> : <InactiveStatus />
      };
    });
    setTableData(result);
    // setData(Item);
    console.log(Item);
  };

  const handleOnClick = async (record) => {
    let id = encodeURIComponent(record[1]);
    const response = await ApiService.get(
      `${
        process.env.REACT_APP_API_END_POINT
      }featured-items/getFeaturedItemById?locationId=${encodeURIComponent(
        locationId
      )}&id=${id}`
    );
    let selectItem =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    // let arr = [record[1]]
    // let filterRecord = data?.filter(i => arr.includes(i.sortKey));
    // console.log(filterRecord)
    setSelectedData(selectItem);
    if (record[2] === 'Edit') {
      setShowScreen(3);
    } else if (record[2] === 'Detail') {
      setShowScreen(2);
    }
  };

  useEffect(() => {
    getFeaturedItem();
    console.log('Filters---->', filters);
  }, [filters, showScreen]);

  // const addFeaturedItem = () => {
  //   setShowScreen(1);
  // };

  // method to handle bulk update
  const handleBulkAdd = async () => {
    console.log('handleBulkAdd');
    setIsUpdated(false);
    setOpen(true);
  };

  // Method to handle bulk update status final confirm
  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    // show loader
    setLoading(true);
    const data = {
      status: selectedStatus,
      updatedBy: userId,
      featureItemIds: selectedLists
    };

    try {
      const encodedLocationId = encodeURIComponent(locationId);
      const response = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}featured-items/updateBulkStatus?locationId=${encodedLocationId}`,
        data
      );

      if (!response.data?.error) {
        // toast.success(response.data?.message || 'Featured status updated');
        setSelectedStatus('');
        setSelectedLists([]);
        setIsUpdated(true);
        setLoading(false);
        setOpen(true);
      } 
      // show loader
      // setIsLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      // show loader
      // setIsLoading(false);
      toast.error('Something went wrong, Try again!');
    }
  };

  // Method to handle close of dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getFeaturedItem();
        setIsUpdated(false);
      }
    }
  };

  return (
    <>
      <Loader IsLoading={loading} />
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <Box sx={{ width: '100%' }}>
                <CreateFeaturedItem setShowScreen={setShowScreen} />
              </Box>
            );
          case 2:
            return (
              <Box sx={{ width: '100%' }}>
                <FeaturedDetail
                  getFeaturedItem={getFeaturedItem}
                  setShowScreen={setShowScreen}
                  selectedData={selectedData}
                />
              </Box>
            );
          case 3:
            return (
              <Box sx={{ width: '100%' }}>
                <FeaturedItemEdit
                  setShowScreen={setShowScreen}
                  selectedData={selectedData}
                />
              </Box>
            );
          default:
            return (
              <>
                {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '40px', paddingLeft: '40px' }}>
                  <Typography className="page-title header-blue-40" /> */}
                {/* Featured Item</Typography> */}
                {/* <Stack spacing={2} direction="row">
                    <Button variant="contained" className="top-button-style" onClick={addFeaturedItem}>Add New</Button>
                  </Stack> */}
                {/* </Box> */}
                <CustomTable
                  data={tableData}
                  handleOnClick={handleOnClick}
                  filterCompoenent={
                    <FeaturedItemsFilter
                      setFilters={setFilters}
                      filters={filters}
                    />
                  }
                  label="All Featured Item"
                  selectedLists={selectedLists}
                  setSelectedLists={setSelectedLists}
                  open={open}
                  setOpen={setOpen}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  handleBulkAdd={handleBulkAdd}
                  btnLoading={btnLoading}
                />
              </>
            );
        }
      })()}

      {/* Status Update Dialog confirmation */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
               <Typography className="title-text-sub">
               Are you sure you want to mark Activate/Deactivate,
               <Typography className="title-text-sub">
               all the selected items?
             </Typography>
             </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected featured items have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DrawFeaturedItemTable;
