import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  // Avatar,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';
// import GlobalData from '../../../global';
import Loader from '../../../components/Loader';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ArrowLeft, DeleteIcon } from 'src/assets/SVG/SvgIcons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const LocationDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

// LocationDetails page main method
const LocationDetails = ({
  data,
  getItemForEdit,
  refreshLocationData,
  setShowScreen,
  setSearchText
}) => {
  const navigate = useNavigate();
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let locationDetails = data;
  console.log(locationDetails, 'locationDetailslocationDetails');
  // Get the loggedIn user Id
  const userId = sessionStorage.getItem('UserId');
  // Get the title from global data
  // const title = GlobalData[0].title;

  // const [length, setLength] = useState(10);
  // setLength(locationDetails.length);
  // const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);

  // get the manager info and convert it into string
  // let manager = (locationDetails && locationDetails.managerInfo) ? JSON.parse(locationDetails.managerInfo).toString() : '';
  // let manager = (locationDetails && locationDetails.managerInfo) ? locationDetails.managerInfo : '';
  // let manager = '';

  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openMessageDialog, setOpenMessageDialog] = useState(false);

  // get the id from the url
  // let id = searchParams.get("Id");
  // const hasRunRef = useRef(false);
  // const theme = useTheme();

  // Method to open the delete dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      // call the api to delete the location
      setIsLoading(true);
      deleteLocation();
    } else {
      setOpen(false);
      // setShowScreen(0);
    }
  };

  // Method to open the edit dialog
  const handleClickEdit = (e) => {
    let crossElm = document.getElementsByClassName('details-remove-icon')[0];
    if (crossElm) crossElm.click();
    // navigate("/location-management");

    let target = e && e.target;
    let lid;
    if (target) lid = target.getAttribute('id');

    // find an element from body
    let cls = 'edit_btn_' + lid;
    getItemForEdit(cls);
    // let elm = document.getElementsByClassName(cls)[0];
    // if (elm) elm.click();
    // console.log(elm);
    setShowScreen(2);
  };
  const handleCloseDetails = () => {
    setSearchText('');
    setShowScreen(0);
  };

  const handleCloseDialog = (val) => {
    if (val === 'Close') {
      setOpenMessageDialog(false);
      setShowScreen(0);
      setSearchText('');
      refreshLocationData();
    }
  };
  // useeffect state starts here
  useEffect(() => {
    // if (id) setSearchParams({ Id: id });

    // check if the user is logged in or not
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
    /*
        // remove page header text and add button hide
        let elm = document.getElementsByClassName('page-header-text')[0];
        if (elm) elm.innerHTML = '';
        let headerelm = document.getElementsByClassName('add-btn-container')[0];
        if (headerelm) headerelm.style.visibility = 'hidden';

        // Now get the location id from the url
        if (!hasRunRef.current) {
            // call the api to get the location details based on the id
            let locationId = searchParams.get("Id");

            // call the get location details method
            if (locationId) getLocationDetails(locationId);
        }
        */
  }, [locationDetails, navigate]);

  // Method to delete the location
  const deleteLocation = () => {
    let locationId = locationDetails.sortKey;
    let franchiseeId = locationDetails.franchiseeId;
    if (locationId) {
      // Now show the loader

      // call the api to delete the location
      let url = `${
        process.env.REACT_APP_API_END_POINT
      }locations/deleteLocation?locationId=${encodeURIComponent(
        locationId
      )}&updatedBy=${encodeURIComponent(
        userId
      )}&franchiseeId=${encodeURIComponent(franchiseeId)}`;

      // execute the api
      fetch(`${url}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          // console.log("response", response);
          return response.json();
        })
        .then((data) => {
          if (data) {
            // console.log("data", data);
            // navigate to the location list page
            let crossElm = document.getElementsByClassName(
              'details-remove-icon'
            )[0];
            if (crossElm) crossElm.click();
            // Now hide the loader
            setOpen(false);
            setOpenMessageDialog(true);
            setIsLoading(false);
            // Now refresh the location table
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          // set error message
          setShowError(true);
          setErrorMessage(error.message);
          // Now hide the loader
          setIsLoading(false);
        });
    }
  };

  // Method to get the location details
  /*
    const getLocationDetails = (locationId) => {
        // call the api to get the location details
        let url = `${process.env.REACT_APP_API_END_POINT}locations/${locationId}`;
        fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                // console.log("response", response);
                return response.json();
            })
            .then((data) => {
                if (data) {
                    // set the location details
                    let items = data.data.Items;
                    if (items) locationDetails = items[0];
                    // SetLocationDetails(locationDetails);

                    // set the length
                    setLength(items.length);
                    // console.log("locationDetails", locationDetails.length, items);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                // set error message
                setShowError(true);
                setErrorMessage(error.message);
            });
    }
    */

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Grid sx={{ padding: '0 2rem' }}>
        <LocationDetailsWrapper
          maxWidth={'100%'}
          className="location-details-wrapper p-0"
        >
          {/* <Grid container className="location-header-container">
            <Grid
              xs={8}
              sm={8}
              md={8}
              lg={8}
              className="page-header title-adjust"
            >
              <Box sx={{ display: 'flex', marginTop: '25px' }}>
                <ArrowBackIcon
                  className="back-arrow-style"
                  style={{ fontSize: '30px' }}
                  onClick={() => handleCloseDetails()}
                />

                <h4
                  className="location-name"
                  style={{ marginTop: '-10px', marginLeft: '5px' }}
                >
                  {locationDetails.locationName}
                </h4>
              </Box>

              <span className="location-image">
                <Avatar
                  alt="icon"
                  src={
                    locationDetails.locationImage ||
                    '/static/images/logo/cardboardicon.png'
                  }
                  sx={{ marginLeft: 2 }}
                  className="card-avatar-img"
                />
              </span>
            </Grid>

            <Grid xs={4} sm={4} md={4} lg={4} className="align-right">
              <Button className="details-remove-icon" onClick={handleClickOpen}>
                <svg
                  width="24"
                  height="26"
                  viewBox="0 0 24 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                    fill="#8098C3"
                  />
                </svg>
              </Button>

              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                id={locationDetails.locationId}
                onClick={handleClickEdit}
                className="btn-primary edit-button"
              >
                Edit
              </Button>
            </Grid>
          </Grid> */}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ paddingBottom: '15px' }}
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseDetails()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>
                <Typography className="header-blue-32 d-flex">
                  <span className="location-name">
                    {' '}
                    {locationDetails.locationName}{' '}
                  </span>
                  {/* <span className="location-image">
                    <Avatar
                      alt="icon"
                      src={
                        locationDetails.locationImage ||
                        '/static/images/logo/cardboardicon.png'
                      }
                      sx={{ marginLeft: 2 }}
                      className="card-avatar-img"
                    />
                  </span> */}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="details-remove-icon no-hover"
                  onClick={handleClickOpen}
                >
                  <DeleteIcon />
                </Button>

                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  id={locationDetails.locationId}
                  onClick={handleClickEdit}
                  className="btn-primary edit-button"
                >
                  Edit
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box className="location-details-container">
            {/* <Grid container spacing={2} > */}
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">LOCATION NAME</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.locationName}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">UNIT#</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails['unit#']}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.6} className="location-details-col">
                <Box className="location-details-label">
                  LOCATION PHONE NUMBER
                </Box>
              </Grid>
              <Grid item xs={12} sm={4.4} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.phone}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">LOCATION EMAIL</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.email}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">LOCATION NOTES</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.notes}
                  {/* {(locationDetails && locationDetails.managerInfo && locationDetails.managerInfo.length > 0 && locationDetails.managerInfo.map((Item) => {
                                    // item = Item.toString();
                                    return (
                                        <Box className="field-value">
                                            {Item}
                                            <br />
                                        </Box>
                                    )
                                })
                                )}  */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">Opening Date</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.openingDate}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">SUPPORT SPECIALIST</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.managerName}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">BUSINESS ENTITY</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.franchiseeName}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">STATUS</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.openingStatus}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">
                  PRIMARY CONTACT NAME
                </Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.primaryContactName}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">PRIMARY EMAIL</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.primaryContactEmail}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">PRIMARY PHONE</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.primaryContactPhone}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">ADDRESS</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.addressLine1} {locationDetails?.addressLine2}
                  , {locationDetails?.city}, {locationDetails?.state},{' '}
                  {locationDetails?.postalCode}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">LOCATION URL</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.locationUrl}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">FACEBOOK URL</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.facebookUrl}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.5} className="location-details-col">
                <Box className="location-details-label">INSTAGRAM HANDLE</Box>
              </Grid>
              <Grid item xs={12} sm={4.5} className="location-details-col">
                <Box className="location-details-value">
                  {locationDetails.instagramUrl}
                </Box>
              </Grid>
            </Grid>
            {/* <Grid container mt={3} className="location-details-row">
                            <Grid item xs={12} sm={6} className="location-details-col">
                                <Box className="location-details-label">LOCATION IMAGE</Box>
                                <Box className="location-details-value location-image">
                                    <Avatar
                                        alt="icon"
                                        src={locationDetails.locationImage || '/static/images/logo/cardboardicon.png'}
                                        sx={{ marginLeft: 2 }}
                                        className='card-avatar-img'
                                    />
                                </Box>
                            </Grid>
                        </Grid> */}
            {/* </Grid> */}
            {showError && (
              <Grid container mt={3} className="location-details-row">
                <Box className="error-message">{errorMessage}</Box>
              </Grid>
            )}
          </Box>
        </LocationDetailsWrapper>
      </Grid>

      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactviate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMessageDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseDialog('Close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Congratulations!'}
         </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected location has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleCloseDialog('Close')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LocationDetails;
