import React, { forwardRef, useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography
} from '@mui/material';
import { DynamicButton } from './DynamicButton';
import { DynamicDropdown } from './DynamicDropdown';
import cancelRed from '../../assets/Images/cancelRed.svg';
import { getLocalData } from '../../utils';
import { ApiService } from '../../services/ApiService';
import { toast } from 'react-toastify';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const DynamicModal = ({
  handleDialog,
  open,
  type,
  className,
  selected
}) => {
  const locationId = getLocalData('locationId');
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const [allTrainers, setAllTrainers] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [notification, setNotification] = useState(null);
  console.log(selected, 'selected');
  useEffect(() => {
    getAllTrainersClients();
  }, []);

  const getAllTrainersClients = async () => {
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      let trainerResponse = await ApiService.get(
        `${BASE_URL}schedule/getAllTrainers?locationId=${encodedLocationId}`
      );
      let clientResponse = await ApiService.get(
        `${BASE_URL}schedule/getAllClients?locationId=${encodedLocationId}`
      );
      if (trainerResponse?.statusCode === 200) {
        setAllTrainers(
          trainerResponse.data.Items.filter(
            (user) => user.email !== undefined && user.email !== ''
          ).map((user) => user.email)
        );
      } else {
        toast.error(trainerResponse?.message || 'Something went Wrong');
      }
      if (clientResponse?.statusCode === 200) {
        setAllClients(
          clientResponse.data.Items.filter(
            (user) => user.email !== undefined && user.email !== ''
          ).map((user) => user.email)
        );
      } else {
        toast.error(clientResponse?.message || 'Something went Wrong');
      }
    } catch (error) {
      toast.error(error?.message || 'Something went Wrong');
    }
  };

  const sendNotification = async () => {
    // let encodedLocationId = encodeURIComponent(locationId);
    let allEmails = [...allTrainers, ...allClients];
    let payload = {
      toEmail:
        notification === 'trainer'
          ? allTrainers
          : notification === 'client'
          ? allClients
          : allEmails,
      fromEmail: selected?.client?.email || 'abc@gmail.com'
    };
    try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/sendNotification`,
        payload
      );

      if (response?.statusCode === 200) {
        toast.success(response?.message);
        handleDialog();
      } else {
        toast.error(response?.message || 'Something went Wrong');
      }
    } catch (error) {
      toast.error(error?.message || 'Something went Wrong');
    }
  };
  const handleNotification = (name, value) => {
    console.log(name, 'name', value);
    setNotification(value);
  };
  const onClickButton = () => {
    if (type) {
      handleDialog();
    } else {
      sendNotification();
    }
  };
  return (
    <>
      <Dialog
        open={open}
        className={className}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          padding: "5px 20px"
        }}
      >
        {!type && (
          <DynamicButton
            src={cancelRed}
            onClick={handleDialog}
            style={{
              justifyContent: 'end',
              background: 'transparent'
            }}
          />
        )}

        <DialogTitle className="alert-title align-center dialogTitle ">
          {type ? (
            <Typography variant="h1" align="center" color="primary">
              Order Declined
            </Typography>
          ) : (
            <Typography
              variant=""
              align="center"
              style={{
                color: '#000000',
                display: 'block',
                textAlign: 'left',
                textTransform: 'uppercase',
                fontWeight: '700',
                fontSize: '20px'
              }}
            >
              Whom do you want to send it to?
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {type ? (
                <Typography
                  variant="body2"
                  align="center"
                  color="primary"
                  style={{ marginBottom: '15px' }}
                >
                  Please review your item selection and try again.
                </Typography>
              ) : (
                <DynamicDropdown
                  fullWidth
                  required
                  name="notification"
                  value={notification}
                  onChange={handleNotification}
                  label="NOTIFICATION"
                  options={[
                    { label: 'Send Notification to trainer', value: 'trainer' },
                    { label: 'Send Notification to client', value: 'client' },
                    { label: 'Send Notification to all', value: 'all' }
                  ]}
                />
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-button-control">
          <DynamicButton
            variant={'contained'}
            onClick={onClickButton}
            title={type ? 'Close' : 'Submit'}
            style={{
              padding: '11px 23px',
              borderRadius: '10px',
              textTransform: 'capitalize',
              width: `${type ? 'max-content' : '100%'}`,
              fontWeight: '600',
              fontSize: '16px',
              margin: '0 auto 24px'
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
