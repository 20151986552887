import React, { useState } from 'react';
import { getLocalData } from '../../../utils';
import { Grid } from '@mui/material';
import { Box, Typography, Container, Button, Stack } from '@mui/material';
import DiscardDialog from './DiscardDialog';
import { ApiService } from '../../../services/ApiService';
import { dateFormatWithSlash } from '../../../utils/index';
import Loader from '../../../components/Loader';

const GiftCardPricingDetail = ({
  setShowScreen,
  selectedItem,
  setOpenDetailsPricing
}) => {
  let userId = getLocalData('userId');
  const locationId = getLocalData('locationId');
  console.log(selectedItem, 'selectedItem');
  const [open, setOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = (type) => {
    console.log(type, 'handleClose');
    setOpen(false);
  };
  const handleCloseConfirm = (type) => {
    console.log(type, 'handleCloseConfirm');
    if (type === 'Deactivated') {
      deleteGiftCardPricing();
    }
    if (type === 'Cancel') {
      setOpen(false);
      setOpenDetailsPricing(false);
      // setShowScreen(1);
    }
    if (type === 'Close') {
      setOpen(false);
      setOpenDetailsPricing(false);
      setShowScreen(1);
    }
  };

  const handleClosePricingOptionDetails = () => {
    setShowScreen(1);
  };

  const deleteGiftCardPricing = async () => {
    setIsLoading(true);
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedUserId = encodeURIComponent(locationId);
    let priceId = encodeURIComponent(selectedItem?.sortKey);

    userId = encodeURI(userId);
    try {
      let response = await ApiService.delete(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/deleteGiftCardPrice?locationId=${encodedLocationId}&id=${priceId}&updatedBy=${encodedUserId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      console.log(Item, 'deleted');
      setReadOnly(true);
      setIsLoading(false);
    } catch (e) {
      console.log(e, 'error');
      setIsLoading(false);
    }
  };
  const onPressEditing = () => {
    setShowScreen(false);
  };
  return (
    <>
      <Loader IsLoading={isLoading} />
      <Container maxWidth="100%">
        <Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
              mb: 2
            }}
          >
            <Box
              onClick={handleClosePricingOptionDetails}
              className="back-arrow-close"
            >
              <svg
                width="27"
                height="19"
                viewBox="0 0 27 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                  fill="#003087"
                />
              </svg>
            </Box>
            <Typography className="page-title header-blue-32" variant="h1">
              {selectedItem?.giftCardPriceName}
            </Typography>

            <Box sx={{ marginLeft: 'auto', marginRight: '40px' }}>
              <Button
                className="details-remove-icon no-hover"
                onClick={() => setOpen(true)}
              >
                <svg
                  width="24"
                  height="26"
                  viewBox="0 0 24 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                    fill="#8098C3"
                  />
                </svg>
              </Button>

              <Button
                variant="contained"
                className="top-button-style"
                onClick={onPressEditing}
                sx={{ mt: 1 }}
              >
                Edit
              </Button>
            </Box>
          </Box>

          <Grid container spacing={2} padding="30px">
            <Stack
              display="flex"
              flexDirection="row"
              width="100%"
              marginBottom={3}
              justifyContent="space-between"
            >
              <Box className="location-details-label" flex="1">
                <strong>PRICING NAME</strong>
              </Box>
              <Box
                className="location-details-value"
                {...{ flex: 1, marginLeft: 10 }}
              >
                {selectedItem?.giftCardPriceName}
              </Box>

              <Box className="location-details-label" flex="1">
                <strong>CARD VALUE</strong>
              </Box>
              <Box
                className="location-details-value"
                {...{ flex: 1, marginLeft: 10 }}
              >
                {selectedItem?.giftCardPriceValue &&
                  `$${selectedItem?.giftCardPriceValue}`}
              </Box>
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              width="100%"
              marginBottom={3}
              justifyContent="space-between"
            >
              <Box flex="1" className="location-details-label">
                <strong>PRICE</strong>
              </Box>
              <Box
                className="location-details-value"
                {...{ flex: 1, marginLeft: 10 }}
              >
                {selectedItem.price && `$${selectedItem?.price}`}
              </Box>

              <Box className="location-details-label" flex="1">
                <strong>VALID THROUGH</strong>
              </Box>
              <Box
                className="location-details-value"
                {...{ flex: 1, marginLeft: 10 }}
              >
                {selectedItem?.validThrough &&
                  dateFormatWithSlash(selectedItem?.validThrough)}
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <DiscardDialog
          handleClose={handleClose}
          open={open}
          handleCloseConfirm={handleCloseConfirm}
          title={readOnly ? 'Congratulation' : 'Deactivated'}
          text={
            readOnly
              ? 'Selcted gift card pricing has been deactivated successfully!'
              : `Are you sure you want to deactviated this record?`
          }
          firstButton={readOnly ? 'Close' : 'Deactivated'}
          secondButton={readOnly ? null : 'Cancel'}
          readOnly={readOnly}
        />
      </Container>
    </>
  );
};

export default GiftCardPricingDetail;
