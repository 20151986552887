import { Helmet } from 'react-helmet-async';
import '../../pages/style.css';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Grid,
  Slide,
  Typography
} from '@mui/material';
import ContractDetail from './ContractDetail';
import AddNewContract from './AddNewContract';
import Footer from 'src/components/Footer';
import {
  ContentCard
  // DynamicRightSidebar
  // FilterAndSearchComponent
} from '../../../components/GlobalComponents/index';
import React, { useEffect, useState } from 'react';
import GlobalData from '../../../global';
import { filtersQuery, getLocalData, setFullLocationId } from 'src/utils';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import { CustomContractTable } from './CustomContractTable';
import { ContractFilter } from 'src/components/Filters/ContractFilter';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ApiService } from 'src/services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Header from 'src/components/Header';
import Loader from 'src/components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function FrachisorContract() {

  const [pricingDialog, setPricingDialog] = useState(false);
  // const [selectedRow, setSelectedRow] = useState('');
  const locationId = setFullLocationId(getLocalData('locationId'));
  const encodedLocationId = encodeURIComponent(locationId);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [first, setfirst] = useState(false);

  const title = GlobalData[0].title;
  const [showAddContract, setShowAddContract] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [contracts, setContract] = useState([]);
  const [contractsDetail, setContractDetails] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState(false);
  const [endDate, setendDate] = useState(null);
  const [endDateError, setEndDateError] = useState(false);
  const [terminationDate, setTerminationDate] = useState(null);
  const [terminationDateError, setTerminationDateError] = useState(false);
  const [terminationDialog, setTerminationDialog] = useState(false);
  const [contactId, setContactId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contractStatus, setContractStatus] = useState(0);

  let [filters, setFilters] = useState({
    // isActive: '',
    status: 1,
    contractType: '',
    typeOfService: null,
    contractName: ''
  });

  const API_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const fetchData = async () => {
    setIsLoading(true);

    let queryString = await filtersQuery(filters);
    let filtersParams = queryString ? `&${queryString}` : '';

    try {
      let contractsData = await ApiService.get(
        `${API_URL}contracts?locationId=${encodedLocationId}${filtersParams}`
      );
      if (contractsData.statusCode && !contractsData.error) {
        let Item =
          contractsData && contractsData.data ? contractsData.data : [];
        const result = Item.Items.map((obj) => {

          const {
            partitionKey,
            sortKey,
            contractName,
            contractType,
            // price,
            // soldOnline,
            billingCycle,
            // description,
            autopay,
            // contractDuration,
            status,
            // paymentFrequency
          } = obj;

          return {
            partitionKey,
            sortKey,
            'Name': contractName,
            'Contract Duration': contractType,
            'Payment Cycle': billingCycle,
            'Auto Pay': autopay ? 'Enabled' : 'Disabled',
            // 'Sold Online': soldOnline ? <ActiveStatus /> : <InactiveStatus />,
            status: status === 1 ? <ActiveStatus /> : <InactiveStatus />,
            contractStatus: status
          };
        });
        setContract(result);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          contractsData.message || 'Something went wrong with inventory'
        );
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
      // toast.error('Internal Server Error');
    }
  };

  useEffect(() => {
    fetchData();
  }, [pricingDialog, filters]);

  const handleResetStates = () => {
    setContractDetails('');
    setShowAddContract(false);
  };
  const filterContracts = (contracts, type) => {

    if (type) {
      return contracts?.filter((item) => item?.status === type);
    }
    return contracts;
  };

  const handleOnClick = (record) => {
    let arr = [record[1]];
    let filterRecord = contracts?.filter((i) => arr.includes(i.sortKey));
    //  console.log(filterRecord, 'filterRecord====', record);

    setContractDetails(filterRecord);
    if (record[2] === 'Edit') {
      setShowAddContract(true);
      setfirst(true);
    } else if (record[2] === 'Detail') {
      // console.log('Detail');
    }
  };

  const validateStartDate = (selectedDate) => {
    setStartDate(selectedDate);
    if (!selectedDate) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };
  const validateEndDate = (selectedDate) => {
    setendDate(selectedDate);
    if (!selectedDate) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  };

  const validateTerminationDate = (selectedDate) => {
    setTerminationDate(selectedDate);
    if (!selectedDate) {
      setTerminationDateError(true);
    } else {
      setTerminationDateError(false);
    }
  };

  const handleClose = () => {
    setPricingDialog(false);
    setContactId('');
    setTerminationDialog(false);
  };

  const pauseContract = async () => {
    const data = {
      locationId: locationId,
      contractId: contactId,
      fromDate: startDate,
      toDate: endDate
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}contracts/pauseContract`,
        data
      );

      if (response.statusCode && !response.error) {
        setPricingDialog(false);
        setShowSuccessMessage(true);
        setSuccessMessage(response.message);
        // toast.success(response.message);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          response.message || 'Something went wrong with add Membership'
        );
      }
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };
  const terminationContract = async () => {
    const data = {
      locationId: locationId,
      contractId: contactId,
      terminationDate: terminationDate
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}contracts/terminateContract`,
        data
      );

      if (response.statusCode && !response.error) {
        fetchData();
        setTerminationDialog(false);
        setShowSuccessMessage(true);
        setSuccessMessage(response.message);
        // toast.success(response.message);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          response.message || 'Something went wrong with add Membership'
        );
        // toast.error(
        //   response.message || 'Something went wrong with add Membership'
        // );
      }
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };

  // Method to duplicate contract
  const duplicateContract = async () => {
    // show loader
    setIsLoading(true);
    
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedContractId = encodeURIComponent(contactId);

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}contracts/duplicateContract?locationId=${encodedLocationId}&contractId=${encodedContractId}`
      );

      if (response.statusCode && !response.error) {
        setContactId('');
        fetchData();
        // hide loader
        setIsLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage(response.message);
      } else {
        // hide loader
        setIsLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(
          response.message || 'Something went wrong with add Membership'
        );
      }
    } catch (error) {
      console.error(error);
      // hide loader
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  // Methdo to deactivate the contract
  const deactivateContract = async () => {
    // To show loader
    setIsLoading(true);
    
    let userId = sessionStorage.getItem('UserId');
    let body = {
      contractId: contactId,
      userId: userId,
      locationId: locationId,
      status: contractStatus
    };

    try {
      const response = await ApiService.put(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}contracts/updateContractStatus`, body);

      if (response.statusCode === 200) {
        // To hide loader
        setIsLoading(false);
        fetchData();
        setTimeout(() => {
          setShowSuccessMessage(true);
          setSuccessMessage(response.message);
        }, 500);
        
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.message);
        // To hide loader
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      // To hide loader
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong');
    }
  };

  const AddBundlesData = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!startDate) {
      setStartDateError('Start Date is required');
    }
    if (!endDate) {
      setEndDateError('End Date is required');
    }

    // if (!services || !product) {
    //     setProductError("Please select at least one product or service");
    // }
    else {
      pauseContract();
    }
  };

  const handleDeleteDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!terminationDate) {
      setTerminationDateError('End Date is required');
    } else {
      terminationContract();
    }
  };

  const handleButtonClick = () => {
    setShowAddContract(true);
  };

  const buttons = [
    {
      label: 'Add New Contract',
      onClick: handleButtonClick,
      theam: true
    }
  ];

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <div className="myContainerRight">
        {showErrorMessage && (
          <ErrorMessage
            message={errorMessage}
            setShowMessage={setShowErrorMessage}
          />
        )}

        {showSucessMessage && (
          <SuccessMessage
            message={sucessMessage}
            setShowMessage={setShowSuccessMessage}
            closePopup={closePopup}
          />
        )}
        <Helmet>
          <title>Contract Management | {title}</title>
        </Helmet>

        {showAddContract ? (
          <AddNewContract
            handleOpenScreen={() => setShowAddContract(true)}
            handleClose={() => setShowAddContract(false)}
            contractsDetail={contractsDetail}
            type={1}
            fetchData={fetchData}
            setfirst={setfirst}
            first={first}
            setContractDetails={setContractDetails}
            setShowSuccessMessage={setShowSuccessMessage}
            setSuccessMessage={setSuccessMessage}
            showSucessMessage={showSucessMessage}
            sucessMessage={sucessMessage}
          />
        ) : contractsDetail !== '' ? (
          <ContractDetail
            data={contractsDetail}
            handleResetStates={handleResetStates}
            handleOpenScreen={setShowAddContract}
            fetchData={fetchData}
          />
        ) : (
          <Container maxWidth="100%" className="main-container">
            {!showAddContract && contractsDetail === '' && (
              <Header title="Contracts" buttons={buttons} />
            )}
            {/* <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className="add-btn-container"
            >
              <Typography variant="h1" color="primary" />
              <Grid item>
                <Button
                  sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                  variant="contained"
                  className="btn-primary"
                  onClick={() => setShowAddContract(true)}
                >
                  Add New Contract
                </Button>
              </Grid>
            </Grid> */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
              mb={2}
              mt={4}
            >
              <ContentCard content={contracts} />
            </Grid>
            {/* <DynamicTabsTwo list={tabList} /> */}
            <CustomContractTable
              data={filterContracts(contracts)}
              handleOnClick={handleOnClick}
              setPricingDialog={setPricingDialog}
              setTerminationDialog={setTerminationDialog}
              filterCompoenent={
                <ContractFilter setFilters={setFilters} filters={filters} />
              }
              label="All Contracts"
              setContactId={setContactId}
              duplicateContract={duplicateContract}
              deactivateContract={deactivateContract}
              setContractStatus={setContractStatus}
            />
          </Container>
        )}
        {/* <DynamicRightSidebar
          open={pricingDialog}
          setOpen={setPricingDialog}
          // selectedContract={selectedRow}
        /> */}

        <Dialog
          open={terminationDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="alert-dialog-slide-description"
        >
          {' '}
          <Box sx={{ position: 'absolute', right: 0 }} my={1}>
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={handleClose}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '200px' // Optional: Set a minimum height to prevent content from collapsing
            }}
          >
            <Container maxWidth="100%">
              <Grid
                container
                component="form"
                spacing={3}
                onSubmit={(e) => handleDeleteDialog(e)}
                justifyContent="center"
                alignItems="center"
                p={6}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    textAlign="center"
                    sx={{
                      marginBottom: '10px',
                      fontWeight: 'bold',
                      color: '#003087',
                      fontSize: '30px'
                    }}
                  >
                    Termination Confirmation
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      marginBottom: '10px',
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '24px'
                    }}
                    textAlign="center"
                  >
                    Termination Date
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <CustomDatePicker
                    label="MM/DD/YY"
                    value={endDate}
                    onChange={validateTerminationDate}
                  />

                  {terminationDateError && (
                    <FormHelperText error>
                      Termination Date is required
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Button onClick={handleClose} fullWidth variant="outlined">
                    Cancel
                  </Button>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Button type="submit" variant="contained" fullWidth>
                    Proceed
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </DialogContentText>
        </Dialog>

        {/* popup for pause contract */}
        <Dialog
          open={pricingDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="details-dialog"
          className="dialog-box dialog-box-right client bg-white"
          maxWidth="xs"
        >
          <DialogTitle className="alert-title align-right dialog-header">
            <Box>
              <Button
                className="details-remove-icon p-0 no-hover"
                onClick={handleClose}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13" cy="13" r="13" fill="white" />
                  <path
                    d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                    fill="#003087"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ position: 'relative' }}>
            <DialogContentText id="alert-dialog-slide-description">
              <Container maxWidth="100%" sx={{ paddingTop: '10px' }}>
                <Typography
                  className="header-blue-20"
                  sx={{ marginBottom: '20px' }}
                >
                  Pause Contract
                </Typography>

                <Typography
                  sx={{
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    color: 'black'
                  }}
                >
                  Pause Till
                </Typography>
                <Grid
                  container
                  component="form"
                  spacing={3}
                  onSubmit={(e) => AddBundlesData(e)}
                >
                  <Grid item md={12} sm={12} xs={12}>
                    <CustomDatePicker
                      label="FROM"
                      value={startDate}
                      onChange={validateStartDate}
                    />
                    {startDateError && (
                      <FormHelperText error>
                        Start Date is required
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <CustomDatePicker
                      label="TO(optional)"
                      value={endDate}
                      onChange={validateEndDate}
                    />

                    {endDateError && (
                      <FormHelperText error>
                        End Date is required
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </DialogContentText>

            {/* Move the "Pause" button here */}
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{ position: 'fixed', width: '400px', bottom: '20px' }}
            >
              <Button
                type="submit"
                className="submit-button"
                fullWidth
                onClick={(e) => AddBundlesData(e)}
              >
                Pause
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>

        <Footer />
      </div>
    </>
  );
}

export default FrachisorContract;
