/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Card,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  TextField,
  FormHelperText
} from '@mui/material';
import moment from 'moment';
import dayjs from 'dayjs';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import React, { useEffect, useState } from 'react';
import { ApiService } from '../../../services/ApiService';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
// import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { getLocalData } from 'src/utils';
import Loader from '../../../components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { isDate } from 'date-fns';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ServiceDetailsWrapper = styled(Container)(
  ({ theme }) => `
            margin-top: ${theme.spacing(2)};
    `
);
let valueArr = []
export const EditCohorts = (props) => {
  const { selectedRow, setShowScreen,getFranchisee ,getCohorts } = props;
  const [isFiendUpdated, setIsFiendUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [attributeData, setAttributeData] = useState([]);
  const [operatorData, setOperatorData] = useState([]);
  const [open, setOpen] = useState(false);
  const [criteriaCount, setCriteriaCount] = useState(1);
  const [cohortNameError, setCohortNameError] = useState('');
  const franchiseeId = getLocalData('franchiseeId');
  const locationId = getLocalData('locationId');
  const [cohortExist, setCohortExist] = useState([]);
  const [isSimplified, setIsSimplified] = useState(false);
 // const [newCriteriaErrors, setNewCriteriaErrors] = useState([]);
 const [newCriteriaErrors, setNewCriteriaErrors] = useState({ attribute: false, 
  operator: false,
   values: false,
   endDateError: false,

   });
   const [openConfirmationPop, setOpenConfirmationPopUp] = useState(false);
  const [segmentState, setSegmentState] = useState([]);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('')
  const [inputValues, setInputValues] = useState([
    { attribute: '', operator: '', values: [] }
  ]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedOperators, setSelectedOperators] = useState(
    Array(inputValues?.length).fill('')
  );
  console.log(selectedRow, 'selectedRowselectedRow');
  useEffect(() => {
    if (selectedRow) {
      setCohortName(selectedRow.Name);
    }
    if (selectedRow?.Segments) {
      setInputValues(selectedRow?.Segments[0]?.DimensionsAttributes,)
      setCriteriaCount(selectedRow?.Segments[0]?.DimensionsAttributes.length)
      const criteriaData = selectedRow?.Segments[0]?.DimensionsAttributes;
      if (criteriaData && criteriaData.length > 0) {
        const extractedOperators = criteriaData.map(criteria => criteria.operator);
        setSelectedOperators(extractedOperators);
      }
    }
    setOpen(false);
  }, []);
  useEffect(() => {
    getAllAttribute();
  }, []);
  const addCreteriaOpen = () => {
    setInputValues([...inputValues, { attribute: "", operator: "", values: [] }])
   // setNewCriteriaErrors({ attribute: false, operator: false, values: false });
    // Clear the selected attribute
    // setAttributeName('');
    setCriteriaCount(criteriaCount + 1);
  };



  console.log(segmentState, 'segmentState');

 
  const handleCreateCohortClose = () => {
    if (isFiendUpdated) {
      setOpen(true);
    } else {
       setShowScreen(0);
      setOpen(false);
    }
  };

  function formatDate(dateString) {
    return moment(dateString).format("MM-DD-YYYY");
  }
  const handleInputChange2 = (dateValue, i) => {
    let newForm = [...inputValues];
    let valueArr = newForm[i]['values'];
  
    // Assuming that dateValue is a valid date string
    const formattedCurrentDateTime = dayjs(dateValue, { format: 'MM-DD-YYYY' }).format('YYYY-MM-DDTHH:mm:ss[Z]');
  
    if (dateValue !== '') {
      // When dateValue is not empty, remove the values error
      let updatedErrors = { ...newCriteriaErrors[i] };
      updatedErrors.values = false;
      
      setNewCriteriaErrors((prevErrors) => {
        const newErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
        newErrors[i] = updatedErrors;
        return newErrors;
      });
    }
  
    if (valueArr[1] && formattedCurrentDateTime < valueArr[1]) {
      // When the start date is less than the end date, remove the dateError
      let updatedErrors = { ...newCriteriaErrors[i] };
      updatedErrors.dateError = false;
  
      setNewCriteriaErrors((prevErrors) => {
        const newErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
        newErrors[i] = updatedErrors;
        return newErrors;
      });
    }
  
    valueArr[0] = formattedCurrentDateTime;
    newForm[i]['values'] = valueArr;
  
    setInputValues(newForm);
  };
  
  const handleInputChange4 = (e, i) => {
    
    let valueArr = ["",""]
    // setIsFiendUpdated(true);
    let newForm = [...inputValues];
    valueArr = newForm[i]['values']
    const formattedCurrentDateTime = dayjs(e, { format: 'MM-DD-YYYY' }).format('YYYY-MM-DDTHH:mm:ss[Z]');
    valueArr[1] = formattedCurrentDateTime
    newForm[i]['values'] = valueArr;
    if(valueArr[0] && valueArr[1]){
      newCriteriaErrors[i] = { ...newCriteriaErrors[i], endDateError: false };

    }
    setInputValues(newForm)
  }
  
  //   useEffect(()=> {
  //     setInputValues()
  //   },[betweenValue])
  const handleInputChange3 = (dateValue, i) => {
    let newForm = [...inputValues];
  
    let updatedErrors = { ...newCriteriaErrors[i] }; // Copy the current error state
  
    // Assuming that dateValue is a valid date string
    if (dateValue !== '') {
      updatedErrors.values = false;
    }
  
    // Update the error state for the current field
    setNewCriteriaErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[i] = updatedErrors;
      return newErrors;
    });
    
    setIsFiendUpdated(true);
  
    // Assuming `dateValue` is a valid date string
    const formattedCurrentDateTime = dayjs(dateValue, { format: 'MM-DD-YYYY' }).format('YYYY-MM-DDTHH:mm:ss[Z]');
    newForm[i]['values'] = [formattedCurrentDateTime];
    setInputValues(newForm);
  }
  const handleConfirmClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
    } else if (val === 'Discard') {
      setOpen(false);
      setOpenAddDialog(false);
      setShowScreen(0)
    } else if (val === 'Add') {
      if (!cohortName) {
        setCohortNameError('Cohort name is required');
      } 
      setOpenAddDialog(false);
    }
  };

  

  const handleSubmit = async () => {
    let newCriteriaErrors = new Array(inputValues.length).fill({
      attribute: false,
      operator: false,
      values: false,
      endDateError: false,

    });

    // Loop through each criteria field and check for errors
    for (let i = 0; i < inputValues.length; i++) {
      const criteria = inputValues[i];

      if (!criteria.attribute) {
        newCriteriaErrors[i] = { ...newCriteriaErrors[i], attribute: true };
      }
      if (!criteria.operator) {
        newCriteriaErrors[i] = { ...newCriteriaErrors[i], operator: true };
      }

      if (!criteria.values || criteria.values.length === 0) {
        newCriteriaErrors[i] = { ...newCriteriaErrors[i], values: true };
      }
      if(criteria.operator === "BETWEEN"){
        if(criteria?.values[0] && !criteria?.values[1]) {
          newCriteriaErrors[i] = { ...newCriteriaErrors[i], endDateError: true };
        }
      }
     
      if (!cohortName) {
        setCohortNameError('Cohort name is required');
      }
    }

    setNewCriteriaErrors(newCriteriaErrors);

    
      // await CreateCohorts();
      if (!cohortName) {
        setCohortNameError('Cohort name is required');
      }
      const hasErrors = newCriteriaErrors.some(
        (error) => error.attribute || error.operator || error.values || error.endDateError
      );
    
      if (!hasErrors && !cohortNameError) {
        // No errors, call CreateCohorts()
        try {
          setOpenConfirmationPopUp(true);
         // await CreateCohorts();
        } catch (error) {
          console.error('An error occurred while creating cohorts:', error);
        }
      }
    
  };

  console.log(cohortExist, 'cohortExist');
  const handleInputChange1 = (e, i) => {
    let newForm = [...inputValues];
    let updatedErrors = { ...newCriteriaErrors[i] }; // Copy the current error state

  if (e.target.name === 'attribute') {
    updatedErrors.attribute = false;
  }
  if (e.target.name === 'operator' && e.target.value !== '') {
    updatedErrors.operator = false;
  }
  if (e.target.name === 'values') {
    updatedErrors.values = false;
  }
  // Update the error state for the current field
  setNewCriteriaErrors((prevErrors) => {
    const newErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
    newErrors[i] = updatedErrors;
    return newErrors;
  });
    if (e.target.name === 'operator') {
      newForm[i]['values'] = [];

      const updatedSelectedOperators = [...selectedOperators];
      updatedSelectedOperators[i] = e.target.value;
      setSelectedOperators(updatedSelectedOperators);
    }
    if (e.target.name === 'values') {
      console.log(e.target.value, 'jjjjj');
    //  newForm[i][e.target.name] = [e.target.value.split(',')];
    const valuesArray = e.target.value.split(',').map(value => value.trim()); // Split and trim values
    newForm[i][e.target.name] = valuesArray;
    } else {
      newForm[i][e.target.name] = e.target.value;
    }
    //newForm[i][e.target.name] = e.target.value;
    setInputValues(newForm);
  };
  const handleInputChange = (e) => {
    if (e.target.name === 'cohortName') {
      setIsFiendUpdated(true);
      setCohortName(e.target.value)
      setCohortNameError('')
    }
  }
  console.log(cohortName, 'cohortName');
  // const checkCohortNameExist = async () => {
  //   let templateNameCheck = cohortName;
  //   const data = {
  //     Name: templateNameCheck || ''
  //   };
  //   try {
  //     let response = await ApiService.post(
  //       `${process.env.REACT_APP_API_END_POINT}pinpoint/isExistingCohort`,
  //       data
  //     );

  //     if (response.statusCode && !response.error) {
  //       setCohortExist(response.data);
  //       if (response.data.isExists === true) {
  //         setCohortNameError(
  //           'Cohort name already exists.Please choose unique name.'
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleResetStates = () => {
    setShowScreen(0);
    getCohorts();
      setTimeout(()=>{
        setShowScreen(0);
      },600)
  }
  const handleRemoveCriteria = (indexToRemove) => {
    const updatedCriteria = [...inputValues];
      updatedCriteria.splice(indexToRemove, 1); 
    setCriteriaCount(updatedCriteria.length);
      setInputValues(updatedCriteria);
  };

  const handleConfirmClosedialog = (val) => {
    if (val === 'Yes') {
      CreateCohorts();
      setOpenConfirmationPopUp(false);
    }
    if (val === 'No') {
      setOpenConfirmationPopUp(false);
    }
  };

  const CreateCohorts = async () => {
    setIsLoading(true);
    let data = {
      segmentId:selectedRow.sortKey,
      Id: selectedRow.Id,
      locationId: locationId,
      franchiseeId: franchiseeId,
      logicalOperator: 'AND',
      Name: cohortName,
      updatedBy: getFranchisee,
      Segments: [{ DimensionsAttributes: inputValues }]
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/updateCohorts`,
        data
      );

      if (!response.data?.error) {
        setIsLoading(false);
        setIsSimplified(false);
        setShowSuccessMessage(true);
        setSuccessMessage( 'Cohort details has been updated successfully!');
        // setShowScreen(0);
      }else{
        setIsLoading(false);
        setShowMessage(true);
        setErrorMessage(
          response.data?.message || 'Something went wrong, try later.'
        );

      }
    } catch (error) {
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later.');
      setIsLoading(false);
      console.log(error);
    }
  };
  const getAllAttribute = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAllCriteria`
      );

      if (response.statusCode && !response.error) {
        setAttributeData(response?.data?.Items);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllOperator = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAllOperators`
      );

      if (response.statusCode && !response.error) {
        setOperatorData(response?.data?.Items);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllOperator();
  }, []);
  console.log(operatorData, 'response');
  const closePopup = () => {
    setShowSuccessMessage(false);
    setShowScreen(0)
    getCohorts();
  };
  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
        {showSucessMessage &&(
   <SuccessMessage message={sucessMessage} setShowMessage={setSuccessMessage} closePopup={closePopup} />
        )}
               
      <div className="custom-summary">
        <ServiceDetailsWrapper
          maxWidth={'100%'}
          className="location-details-wrapper p-0"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCreateCohortClose()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>

                <Typography className="header-blue-32">
                  Edit Cohorts
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box className="location-details-container" mt={5} ml={3}>
            <Grid container xs={12} sm={12} mt={2}>
              <Grid item xs={6} sm={6} mt={2}>
                <TextField
                  fullWidth
                  name="cohortName"
                  error={Boolean(cohortNameError)}
                  helperText={cohortNameError}
                  // onKeyUp={(e) => checkCohortNameExist(e.target)}
                  autoComplete="SENDER NAME"
                  label="COHORT NAME *"
                  placeholder="Cohort Name"
                  className="location-field input-field-styling"
                  value={cohortName}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    className:
                      'label-style-create-franchisee edit-address-label'
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Card
            sx={{
              padding: '15px',
              borderRadius: '19px 19px 0px 0px',
              mt: 5
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              ml={2}
            >
              <Grid item>
                <Typography className="table-heade header-blue-20">
                  {' '}
                  Criteria
                </Typography>
              </Grid>
            </Grid>
            {Array.from({ length: criteriaCount }, (_, index) => {
              const criteria = inputValues[index];
              console.log(selectedOperators, 'selectedOperators[index]');
              return (
                <>
                  <Grid container mt={3} ml={2} gap={3} mb={2}>
                    <Grid item xs={3.5} sm={3.5}>
                      <Typography className="cohorts-detail-header">
                        Attribute
                      </Typography>
                      <FormControl
                        className="top-margin-cohorts"
                        fullWidth
                        error={newCriteriaErrors[index]?.attribute}
                      >
                        <Select
                          onChange={(e) => handleInputChange1(e, index)}
                          name='attribute'
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          className="input-field-styling cohort-input-styling"
                          value={criteria?.attribute}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } }
                          }}
                        >
                          {attributeData?.map((option) => (
                            <MenuItem value={option.name} key={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      
                      </FormControl>
                      {newCriteriaErrors[index]?.attribute && (
                          <FormHelperText>
                            Attribute Name is required
                          </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={3.5} sm={3.5}>
                      <Typography className="cohorts-detail-header">
                        Operator
                      </Typography>
                      <FormControl
                        className="top-margin-cohorts"
                        fullWidth
                        error={newCriteriaErrors[index]?.operator}
                      >
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          className="input-field-styling cohort-input-styling"
                          name='operator'
                          onChange={(e) => handleInputChange1(e, index)}
                          value={criteria?.operator}
                        >
                          {operatorData?.map((option) => (
                            <MenuItem value={option.name} key={option.sortKey}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {newCriteriaErrors[index]?.operator && (
                        <FormHelperText>
                          Operator Name is required
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={3.5} sm={3.5}>
                      <Typography className="cohorts-detail-header">
                        Value
                      </Typography>

                      {selectedOperators[index] === 'IS' ||
                        selectedOperators[index] === 'IS NOT' ||
                        selectedOperators[index] === 'CONTAINS' ? (
                        <TextField
                          fullWidth
                          // error={Boolean(valueNameError)}
                          error={newCriteriaErrors[index]?.values}
                          autoComplete="SENDER NAME"
                          label=""
                          name="values"
                          className="location-field input-field-styling top-margin-cohorts"
                          value={criteria?.values}
                          onChange={(e) => handleInputChange1(e, index)}
                          InputLabelProps={{
                            className: 'label-style-create-franchisee edit-address-label'
                          }} />

                      ) : selectedOperators[index] === 'BETWEEN' ? (
                        <Grid container mt={0.2} spacing={2}>
                          <Grid item>
                          <CustomDatePicker
                            disablePast
                            name="values"
                            fieldValue={criteria.values[0] ? moment(criteria.values[0]).format("MM-DD-YYYY") : ''}
                            maxDate={criteria?.values[1] ? new Date(moment(criteria?.values[1])?.format("MM-DD-YYYY")) : null}
                            onChange={(e) => handleInputChange2(e, index)}
                          />

                          </Grid>
                          <Grid item>
                            <CustomDatePicker
                            disablePast
                             // disabled={!criteria?.values[0]}
                              name="values"
                              minDate={new Date(moment(criteria?.values[0])?.format("MM-DD-YYYY"))}
                              fieldValue={
                                criteria.values[1] 
                                  ? moment(criteria?.values[1]).format("MM-DD-YYYY")
                                  : new Date(moment(criteria.values[1]).format("MM-DD-YYYY")) < new Date(moment(criteria.values[0]).format("MM-DD-YYYY"))
                                    ? ''
                                  : ""
                                }
                              // value={moment(criteria.values[1]).format("MM-DD-YYYY")}
                              onChange={(e) => handleInputChange4(e, index)}
                            />
                             {newCriteriaErrors[index]?.endDateError && (
                        <FormHelperText>
                           Date is required
                        </FormHelperText>
                      )}
                          </Grid>
                        </Grid>
                      ) : (
                        <><Grid mt={2}>
                          <CustomDatePicker
                            name="values"
                            disablePast
                            // value={formatDate(criteria.values[0])}
                            fieldValue={criteria?.values[0] ? moment(criteria?.values[0]).format("MM-DD-YYYY") : ''}
                            onChange={(e) => handleInputChange3(e, index)} />
                        </Grid></>
                        // Render a normal text field for other operators
                      )}
                      {newCriteriaErrors[index]?.values && (
                        <FormHelperText>
                          { selectedOperators[index] === 'CONTAINS' ? 'Value is required' : 'Date is required'}
                        </FormHelperText>
                      )}
                    </Grid>
                    { 
                    <Grid mt={5}>
                    {index !== 0 && <Button className='cohorts-outlined-btn' variant='outlined' onClick={() => handleRemoveCriteria(index)}>
                          Remove
                        </Button>}
                    </Grid>
                     }
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="left"
                    ml={2}
                    mt={2}
                    gap={2}
                  >
                    <Grid item>
                      {index === criteriaCount - 1 &&
                        <Button className='cohorts-outlined-btn' variant='outlined' onClick={addCreteriaOpen}>
                          Add Filter
                        </Button>}
                    </Grid>

                    <Grid item>
                      {index === criteriaCount - 1 &&
                        <Button className='cohorts-outlined-btn' variant='outlined' >
                          Add Criteria
                        </Button>}


                    </Grid>
                   
                  </Grid>
                </>
              );
            })}
          </Card>
          <Grid
            container
            direction="row"
            justifyContent="flex-end" // This aligns items to the right
            alignItems="right" // This aligns items to the right
            gap={2}
            mt={5}
          >
            <Grid item>
              <Button className="cohorts-outlined-btn" variant="outlined"
               onClick={handleResetStates}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={(e) => handleSubmit()}
                className="cohorts-outlined-btn"
                variant="contained"
              >
                Update Cohort
              </Button>
            </Grid>
          </Grid>
        </ServiceDetailsWrapper>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleConfirmClose('Cancel')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
            Discard Changes?
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                  You have unsaved changes. 
                  <Typography className="title-text-sub">What do you want to do? </Typography>
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleConfirmClose('Discard')}
            >
              Discard changes
            </Button>
            <Divider className="vertical-divider" />

            <Button
              className="font-17 no-hover"
              onClick={() => handleConfirmClose('Save')}
            >
              Go Back to Editing
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
        open={openConfirmationPop}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClosedialog('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
           Save Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Please confirm that you want to save your changes?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClosedialog('No')}
          >
           Go Back to Editing
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClosedialog('Yes')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

        <Dialog
          open={openAddDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenAddDialog(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
            Go back to editing
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                  Are you sure you want to save?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 text-black no-hover"
              onClick={() => setOpenAddDialog(false)}
            >
              Cancel
            </Button>
            <Divider className="vertical-divider" />

            <Button
              className="font-17 no-hover"
              onClick={() => handleConfirmClose('Add')}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};