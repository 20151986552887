/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import {
    Box,
    Avatar,
    Card,
    CardHeader,
    IconButton,
    useTheme,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableContainer,
    Checkbox,
    Grid,
    Menu,
    MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import Loader from '../../../components/Loader';


export const CohortsTable
    = (props) => {
        const {
            searchQuery,
            isLoading,
            loading,
            setShowScreen,
            selectedRows,
            selectedRow,
            setSelectedRow,
            setSelectedStatus,
            handleBulkAdd,
            btnLoading,
            rowData,
            setRowData
        } = props;
        const theme = useTheme();
        const [searchText, setSearchText] = useState('');
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const [anchor, setAnchor] = useState(null);
        const [selcetedItem, setSelectedItem] = useState('');
       // const [isLoading, setIsLoading] = useState(false);
        const [showMessage, setShowMessage] = useState(false);
        const [errorMessage, setErrorMessage] = useState('');
        const openDetail = (row) => {

            // const updatedRowData = [row];
            console.log(row, 'rowDatares');
            // Update the state with the modified array
            setSelectedRow(row);
            setShowScreen(1)
        }


        const applyFilters = (tableRows) => {
            let arrayOfMatchedObjects = [];
            if (searchQuery) {
                arrayOfMatchedObjects = tableRows.filter((object) => {
                    return JSON.stringify(object)
                        .toString()
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());
                });
            } else {
                arrayOfMatchedObjects = tableRows;
            }
            return arrayOfMatchedObjects;
        };

        // Apply filter from object and below is the pagination order
        // const filteredRows = applyFilters(tableRows);
        // const paginatedRows = applyPagination(filteredRows);

        const isSelected = (id) => selectedRows.indexOf(id) !== -1;

        useEffect(() => {
            const searchText = searchQuery.toLowerCase();
            setSearchText(searchText);
            setPage(0);
        }, [searchQuery]);

        const filteredData = rowData.filter((item) => {
            return Object.values(item).some((value) =>
                value?.toString()?.toLowerCase()?.includes(searchText)
            );
        });

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };
        function convertIsoToNormalDate(isoDate) {
            const normalDate = moment(isoDate).format('MM-DD-YYYY');
            return normalDate;
        }
        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0); // Reset page when rows per page changes
        };

        const paginatedData = filteredData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        const openMenu = (staffItem, event) => {
            // setStaffId(staffItem.staffId);
            console.log(staffItem, 'hgfhfghf');
            setSelectedItem(staffItem)
            event.preventDefault();
            event.stopPropagation();
            setAnchor(event.currentTarget);

        };
        const closeMenu = (event) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchor(null);
        };
        const onMenuItemClick = (event, value, row,item) => {
            event.preventDefault();
            event.stopPropagation();
            // console.log(row, 'arrrr')
            setAnchor(null);

            if (value === 'edit') {
                setShowScreen(3)
                setSelectedRow(selcetedItem);
                console.log('row',row.sortKey)
                //   setShowScreen(4);
                //   getStaffDetailById();
            }
            if (value === 'duplicate') {
                setShowScreen(2)
                const updatedRowData = [...rowData, selcetedItem];
                console.log('row',selcetedItem)
                setSelectedRow(selcetedItem);
                // Update the state with the modified array
                 setRowData(updatedRowData);

                //   setClockType('ClockIn');
                //   setOpenClock(true);
                //   // getStaffDetails(staffId);
                //   setOpenClock(true);
            }
        };



        console.log(paginatedData, 'paginatedDatapaginatedData');
        return (
            <Box>
                      <Loader IsLoading={isLoading} />

                <Card
                    sx={{
                        padding: '15px',
                        borderRadius: '19px 19px 0px 0px'
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        ml={2}
                    >
                        <Grid item>
                            {/* <CardHeader title="All Services" className="table-header" /> */}
                            {/* <h2 className="table-heading">All Services</h2> */}
                            <Typography className="table-heade header-blue-20">
                                {' '}
                                All Cohorts
                            </Typography>
                        </Grid>
                    </Grid>
                    <TableContainer className="location-table-container">
                        <Table>
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell>Cohort Name</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {paginatedData.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            key={row.sortKey}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}
                                            className="table-row cursor-pointer"
                                            onClick={(e) => openDetail(row, e)}
                                        >
                                            <TableCell className="table-td">
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    color="text.primary"
                                                    sx={{ marginTop: 1 }}
                                                    gutterBottom
                                                    noWrap
                                                >
                                                    {row.Name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-td">
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    color="text.primary"
                                                    gutterBottom
                                                    noWrap
                                                >
                                                    {convertIsoToNormalDate(row.CreationDate)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-td">
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    color="text.primary"
                                                    gutterBottom
                                                    noWrap
                                                >
                                                    {row.createdBy}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-td">
                                                {/* <IconButton
                                                    sx={{
                                                        '&:hover': {
                                                            background: theme.colors.primary.lighter
                                                        },
                                                        color: theme.palette.primary.main
                                                    }}
                                                    color="inherit"
                                                    size="small"
                                                    className="edit-btn"
                                                    id={'edit_btn_' + row.id}
                                                    onClick={(e) => openDetail(row.sortKey, 4, e)}
                                                >
                                                    Edit
                                                </IconButton> */}
                                                <MoreVertIcon
                                                    onClick={(e) => openMenu(row, e)}
                                                    color="grey"
                                                    variant="contained"
                                                    className="three-dots-icon"
                                                />

                                                <Menu
                                                    open={Boolean(anchor)}
                                                    anchorEl={anchor}
                                                    onClose={closeMenu}
                                                    keepMounted
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: 40 * 5,
                                                            width: '25ch'
                                                        }
                                                    }}
                                                >
                                                    {(selcetedItem.createdBy !== 'Franchisor') && 
                                                   (
                                                   <MenuItem
                                                        onClick={(e) =>
                                                            onMenuItemClick(
                                                                e,
                                                                'edit',
                                                                row,selcetedItem
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                   ) }
                                                    <MenuItem
                                                        onClick={(e) =>
                                                            onMenuItemClick(
                                                                e,
                                                                'duplicate',
                                                                row,selcetedItem
                                                            )
                                                        }
                                                    >
                                                        Duplicate
                                                    </MenuItem>

                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box p={2}>
                        {/* <TablePagination
            component="div"
            count={filteredRows?.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={tablePagination.page}
            rowsPerPage={tablePagination.limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          /> */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Card>
            </Box>
        );
    };
