import { Helmet } from 'react-helmet-async';
import { Container, Grid, Box } from '@mui/material';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import ProductListAPI from './ProductListAPI';
import Footer from 'src/components/Footer';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalData from '../../../global';
import Header from '../../../components/Header';
import AddProduct from './AddProduct';
// import { HeaderButtonContext } from 'src/contexts/HeaderButtonContext';

function FranchiseProduct() {
  const navigate = useNavigate();
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Products');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Products';
  const [showScreen, setShowScreen] = useState(0);
  // const { showScreen, setShowScreen,setIsToggleComponent} = useContext(HeaderButtonContext);
  // useEffect(() => {
  //   setIsToggleComponent(false)
  // }, [])
  const childRef = useRef(null);

  // show the add product popup
  // const [openAddProduct, setAddProduct] = React.useState(false);
  // new state to open the add product popup
  // const [openProductPopup, setopenProductPopup] = React.useState(false);
  // const [showScreen, setShowScreen] = useState(0);

  // Get the title from global data
  const title = GlobalData[0].title;

  useEffect(() => {
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, []);

  // const addNewProduct = () => {
  //   // Now set false for all teams
  //   setShowScreen(1)
  //   // setAddProduct(true);
  //   // console.log("openAddProduct", openAddProduct);
  //   // setopenProductPopup(true);
  // };

  // Method to close the franchisee form
  // const refreshPage = () => {

  //   // trigger the parent method to refresh the data
  //   childRef.current.refreshData();
  //   // handleClose();
  // }

  const closeAddProduct = () => {
    childRef.current.refreshProductData();
  };
  // const handleButtonClick = () => {
  //   setShowScreen(1);
  // };

  // const buttons = [
  //   {
  //     label: 'Add New',
  //     onClick: handleButtonClick,
  //     theam: true
  //   }
  // ];
  return (
    <>
      <Helmet>
        <title> Products | {title}</title>
      </Helmet>
      {/* maxWidth="lg" */}
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <Box sx={{ width: '100%' }}>
                <AddProduct
                  showScreen={showScreen}
                  setShowScreen={setShowScreen}
                  refreshData={closeAddProduct}
                />
              </Box>
            );
          default:
            return (
              <Container maxWidth="100%" className="main-container">
                {showScreen === 0 && <Header title="Products" />}
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item width={'100%'}>
                    {/* Now draw the location table */}
                    <ProductListAPI
                      showScreen={showScreen}
                      setShowScreen={setShowScreen}
                      ref={childRef}
                    />
                  </Grid>
                </Grid>
              </Container>
            );
        }
      })()}

      <Footer />
    </>
  );
}

export default FranchiseProduct;
