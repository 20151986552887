import { Helmet } from 'react-helmet-async';
import { Button, Card, CardHeader, CardMedia, Grid, Box, Container, Autocomplete, TextField } from '@mui/material';
// import { Button, Grid, Box, Autocomplete, TextField } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
// import Footer from 'src/components/Footer';
import { useNavigate } from 'react-router-dom';
import GlobalData from '../../../global';
import Header from '../../../components/Header';
// import Reports from './Report';
import { LocationIcon } from 'src/assets/SVG/SvgIcons';
import { getLocalData } from '../../../utils';
import { ApiService } from 'src/services/ApiService';
import { setLocalData } from 'src/utils/index';
// import SwitchLocationPopup from './SwitchLocationPopup';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from 'src/contexts/UserContext';
import { CustomRevenueCards, ColumnDashboardWidget, LineGraphDashboardWidgets } from '../../../components/MainPageComponents';
import PercentIcon from '@mui/icons-material/Percent';
import AddCardIcon from '@mui/icons-material/AddCard';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { franchiseApiRequest, franchisorApiRequest } from './ApiServiseswidgets';

function Home() {
  const navigate = useNavigate();


  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });



  // Set the header text
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Home');
  useEffect(() => {
    if (
      getLocalData('userRole') === 'Front Desk' ||
      getLocalData('userRole') === 'Trainer'
    ) {
      navigate('/staff-dashboard');
    }
  }, []);
  // Get the title from global data
  const title = GlobalData[0].title;
  const [locationOptions, setLocationOptions] = useState([]);

  const [locationName, setLocationName] = useState('');
  const [toggleLocationIcon, setToggleLocationIcon] = useState(false);

  // const [openSwitchLocationPopup, setOpenSwitchLocationPopup] = useState(false);
  const autoCompleteClasses = useAutocompleteStyles();

  // get user role and provide access of that roles
  const franchiseeId = getLocalData('franchiseeId');
  const isFranchisor = getLocalData('isFranchisor');
  const userRole = getLocalData('userRole');
  const userId = getLocalData('userId');
  const locationIdAvailable = getLocalData('locationId');
  const { isNotification, setNotification } = useContext(UserContext)
  const [totalRevenue, setTotalRevenue] = useState([])
  const [yOYChange, setYOYChange] = useState([]);
  const [newClient, setNewClient] = useState([]);
  const [existingClient, setExistingClient] = useState([])
  let ApiurlTotalRevenue = `${process.env.REACT_APP_API_END_POINT}reports/getFranchiseTotalRevenue`;
  let ApiurlYOYChange = `${process.env.REACT_APP_API_END_POINT}reports/calculateYOYChange`;
  let ApiurlNewClientSalesByWeeks = `${process.env.REACT_APP_API_END_POINT}/reports/getNewClientSalesByWeeks`;
  let ApiurlExistingClientSalesByWeeks = `${process.env.REACT_APP_API_END_POINT}reports/getExistingClientSalesByWeeks`;
  // let ApiurlAttritionRate = `${process.env.REACT_APP_API_END_POINT}reports/reports/getAttritionRate`;

  let FranchisorTotalRevenue = `${process.env.REACT_APP_API_END_POINT}reports/getFranchisorTotalRevenue`;

  // console.log(newClient.weeklyData  , "StateWidgetStateWidget");
  console.log(existingClient, 'FranchiseTotalRevenue')

 const[newClientSalesArray,setNewClientSalesArray]= useState([]);
 const[existingClientSalesArray,setExistingClientSalesArray] = useState([]);
  let ClientSalesArray = [];
  let existingClientArray=[];

  

  const ClassOccupancy = [
    { Weeks: 'Week1', AttendencePerWeek: 10 },
    { Weeks: 'Week2', AttendencePerWeek: 25 },
    { Weeks: 'Week3', AttendencePerWeek: 98 },
    { Weeks: 'Week4', AttendencePerWeek: 97 },
    { Weeks: 'Week5', AttendencePerWeek: 14 },
    { Weeks: 'Week6', AttendencePerWeek: 36 },
    { Weeks: 'Week7', AttendencePerWeek: 34 },
    { Weeks: 'Week8', AttendencePerWeek: 45 },
    { Weeks: 'Week9', AttendencePerWeek: 66 },
    { Weeks: 'Week10', AttendencePerWeek: 45 },
    { Weeks: 'Week11', AttendencePerWeek: 56 },
    { Weeks: 'Week12', AttendencePerWeek: 45 },
    { Weeks: 'Week13', AttendencePerWeek: 10 },
    { Weeks: 'Week14', AttendencePerWeek: 25 },
    { Weeks: 'Week15', AttendencePerWeek: 98 },
    { Weeks: 'Week16', AttendencePerWeek: 97 },
    { Weeks: 'Week17', AttendencePerWeek: 14 },
    { Weeks: 'Week18', AttendencePerWeek: 36 },
    { Weeks: 'Week19', AttendencePerWeek: 34 },
    { Weeks: 'Week20', AttendencePerWeek: 45 },
    { Weeks: 'Week21', AttendencePerWeek: 66 },
    { Weeks: 'Week22', AttendencePerWeek: 45 },
    { Weeks: 'Week23', AttendencePerWeek: 56 },
    { Weeks: 'Week24', AttendencePerWeek: 45 }
  ];

  const SalesByMonth = [
    { x: 'Jan', y: 95 },
    { x: 'Feb', y: 28 },
    { x: 'Mar', y: 34 },
    { x: 'Apr', y: 32 },
    { x: 'May', y: 40 },
    { x: 'June', y: 32 }

  ];

  const NewPayingClients = [
    { x: 'Week1', y: 10 },
    { x: 'Week2', y: 25 },
    { x: 'Week3', y: 98 },
    { x: 'Week4', y: 97 },
    { x: 'Week5', y: 14 },
    { x: 'Week6', y: 36 },
    { x: 'Week7', y: 34 },
    { x: 'Week8', y: 45 },
    { x: 'Week9', y: 66 },
    { x: 'Week10', y: 45 },
    { x: 'Week11', y: 56 },
    { x: 'Week12', y: 45 },
    { x: 'Week13', y: 10 },
    { x: 'Week14', y: 25 },
    { x: 'Week15', y: 98 },
    { x: 'Week16', y: 97 },
    { x: 'Week17', y: 14 },
    { x: 'Week18', y: 36 },
    { x: 'Week19', y: 34 },
    { x: 'Week20', y: 45 },
    { x: 'Week21', y: 66 },
    { x: 'Week22', y: 45 },
    { x: 'Week23', y: 56 },
    { x: 'Week24', y: 45 }
  ];

  const ClassOccupancyFranchisor = [
    { Weeks: 'Week1', AttendencePerWeek: 10 },
    { Weeks: 'Week2', AttendencePerWeek: 25 },
    { Weeks: 'Week3', AttendencePerWeek: 98 },
    { Weeks: 'Week4', AttendencePerWeek: 97 },
    { Weeks: 'Week5', AttendencePerWeek: 14 },
    { Weeks: 'Week6', AttendencePerWeek: 36 },
    { Weeks: 'Week7', AttendencePerWeek: 34 },
    { Weeks: 'Week8', AttendencePerWeek: 45 },
    { Weeks: 'Week9', AttendencePerWeek: 66 },
    { Weeks: 'Week10', AttendencePerWeek: 45 },
    { Weeks: 'Week11', AttendencePerWeek: 56 },
    { Weeks: 'Week12', AttendencePerWeek: 45 },
    { Weeks: 'Week13', AttendencePerWeek: 10 },
    { Weeks: 'Week14', AttendencePerWeek: 25 },
    { Weeks: 'Week15', AttendencePerWeek: 98 },
    { Weeks: 'Week16', AttendencePerWeek: 97 },
    { Weeks: 'Week17', AttendencePerWeek: 14 },
    { Weeks: 'Week18', AttendencePerWeek: 36 },
    { Weeks: 'Week19', AttendencePerWeek: 34 },
    { Weeks: 'Week20', AttendencePerWeek: 45 },
    { Weeks: 'Week21', AttendencePerWeek: 66 },
    { Weeks: 'Week22', AttendencePerWeek: 45 },
    { Weeks: 'Week23', AttendencePerWeek: 56 },
    { Weeks: 'Week24', AttendencePerWeek: 45 }
  ];



  useEffect(() => {

    if (isFranchisor === 'true') {
      franchisorApiRequest(FranchisorTotalRevenue, setTotalRevenue)
    } else {
      franchiseApiRequest(ApiurlTotalRevenue, franchiseeId, setTotalRevenue)
      franchiseApiRequest(ApiurlYOYChange, franchiseeId, setYOYChange)
      franchiseApiRequest(ApiurlNewClientSalesByWeeks, franchiseeId, setNewClient)
      franchiseApiRequest(ApiurlExistingClientSalesByWeeks, franchiseeId, setExistingClient)
      //  franchiseApiRequest(ApiurlAttritionRate, franchiseeId, setFranchiseWidgetdata)
    }


  }, [])

  // console.log(newClient.weeklyData, 'clentdataclentdataclentdata')
  const getSalesByWeekData = () => {
    if (newClient?.length !== 0) {
      // Check if newClient is defined, and if not, return an empty array
      ClientSalesArray = newClient?.weeklyData.map((item) => ({
        x:`${item.startDate + '  -   ' + item.endDate}`,
        y: item.totalSales // Example: modify the object
      })) || [];

      console.log(ClientSalesArray, "DATAARRAY");
      // Use dataArray for further processing or set the state
    }

    setNewClientSalesArray(ClientSalesArray);

  };

  const getExistingSalesByWeekData = () => {
    if (existingClient?.length !== 0) {
      // Check if newClient is defined, and if not, return an empty array
      existingClientArray = existingClient?.weeklyData.map((item) => ({
        x:`${item.startDate + '  -  ' + item.endDate}`,
        y: item.totalSales // Example: modify the object
      })) || [];

      console.log(existingClientArray, "DATAARRAY");
      // Use dataArray for further processing or set the state
    }

    setExistingClientSalesArray(existingClientArray);

  };

  useEffect(() => {
    getSalesByWeekData()
  }, [newClient])

  useEffect(() => {
    getExistingSalesByWeekData()
  }, [existingClient])

  useEffect(async () => {
    // check if user is login or not
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
    // Now call the get locations api
    await getLocationList();
  }, []);

  // method to get the location list
  const getLocationList = async () => {
    let getLocationsUrl = `${process.env.REACT_APP_API_END_POINT}locations/getUserLocations`;
    let data = {
      userId: userId,
      franchiseeId: franchiseeId,
      role: userRole === 'Super Manager' ? 'Manager' : userRole,
      isFranchisor: isFranchisor
    };
    try {
      let response = await ApiService.post(getLocationsUrl, data);
      let Item = response?.data?.Items ?? [];
      // setLocationName(Item[0]?.locationName);
      setLocationOptions(Item);

      // Check if location is available in local storage then do not set the location by default, after location switch and revisit, default going to be set
      if ((!locationIdAvailable || locationIdAvailable === 'undefined') && Item[0]?.locationId) {
        setLocationName(Item[0]?.locationName);
        setLocalData('locationId', Item[0]?.locationId ?? '');
        setLocalData('locationName', Item[0]?.locationName ?? '');
        setLocalData('locationStatus', Item[0]?.status ?? '');
      } else {
        let lname = localStorage.getItem('locationName');
        if (lname) setLocationName(lname);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // method to handle the menu item click

  const onMenuItemClick = (event) => {
    const { locationId, locationName, status } = event.target.value;

    // Set the location name
    if (locationName) {
      setLocationName(locationName);
      // set the location name in local storage
      localStorage.setItem('locationName', locationName);
      localStorage.setItem('locationStatus', status);
    }

    // check if location is avaibale then set into the local storage
    if (locationId) {
      // set the location in local storage
      localStorage.setItem('locationId', locationId);
    }
  };
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box sx={{ paddingX: '30px' }}>
        <Header title="Home" />
      </Box>
      {/* Now draw the location icon to change the location */}
      {isFranchisor === 'false' && (
        <Grid container spacing={2} xs={12}>
          <Grid item xs={12}>
            <Box className="d-flex justify-content-end mb-5">
              {locationName && (
                <Box className="page-header-text font-20 align-self-center d-flex justify-content-end mr-10 border-orange weight-400">
                  {locationName}
                </Box>
              )}

              {locationOptions &&
                locationOptions.length > 0 &&
                (toggleLocationIcon ? (
                  <Box sx={{ background: 'white' }} mr={1}>
                    <Autocomplete
                      classes={autoCompleteClasses}
                      onChange={(event, newValue) => {
                        if (newValue && newValue) {
                          setNotification(!isNotification);
                          onMenuItemClick({
                            target: { name: 'locationType', value: newValue }
                          });
                          setToggleLocationIcon(false);
                        } else {
                          onMenuItemClick({
                            target: { name: 'locationType', value: '' }
                          });
                          setToggleLocationIcon(false);
                        }
                      }}
                      onBlur={() => {
                        setToggleLocationIcon(false); // This will run when you click outside the Autocomplete component
                      }}
                      className="status-field field-label-css custom-field home_searchbox"
                      options={locationOptions}
                      getOptionLabel={(option) => option?.locationName || ''}
                      sx={{
                        width: '200px',
                        borderRadius: '19px',
                        padding: '0px !important',

                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                          border: '1px solid #e35205 !important'
                        }
                      }}
                      style={{ padding: '0px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={params?.label || 'Search Location'}
                          InputLabelProps={{ className: 'home_mt-4' }}
                          sx={{ paddingTop: '3px !important' }}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <Button
                    disableRipple
                    startIcon={<LocationIcon />}
                    className={`list-btn-link no-hover cursor-pointer p-0`}
                    onClick={() => setToggleLocationIcon(true)}
                  />
                ))}
            </Box>
          </Grid>
          <Container maxWidth="100%">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <CustomRevenueCards
                  title="REVENUE"
                  subTitle="90 Days"
                  Icon={<PercentIcon />}
                  Units={
                    totalRevenue?.totalRevenue
                      ? `$${parseFloat(totalRevenue?.totalRevenue)?.toFixed(2)}`
                      : '$ 0.00'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <CustomRevenueCards
                  title="ATTRITION RATE"
                  subTitle="30 Days"
                  Icon={<AddCardIcon />}
                  Units="38.5%"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <CustomRevenueCards
                  title="YOY REVENUE"
                  subTitle="90 Days"
                  Icon={<AddCardIcon />}
                  Units={
                    yOYChange?.yoyChange && typeof yOYChange?.yoyChange === 'number'
                      ? `${parseFloat(yOYChange?.yoyChange)?.toFixed(1)}%`
                      : '0.0%'
                  }
                />


              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="100%">
            <Grid container sx={{ mt: 3 }} spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Card sx={{ backgroundColor: '#F8FBFF', border: '0.4px solid #C5C5C5', pb: 1 }}>
                  <CardHeader className="font-18 drodown_menu" title="NEW CLIENT SALES" action={<MoreVertIcon />} />
                  <Container>
                    <CardMedia>
                    {newClientSalesArray && (
                        <ColumnDashboardWidget
                          chartId="ch-1"
                          graphData={newClientSalesArray || '0'}
                          xAxisTitle="Start date End date"
                          yAxisTitle="totalSales"
                        />
                      )}
                    </CardMedia>
                  </Container>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Card sx={{ backgroundColor: '#F8FBFF', border: '0.4px solid #C5C5C5', pb: 1 }}>
                  <CardHeader className="font-18 drodown_menu" title="EXISTING CLIENT SALES" action={<MoreVertIcon />} />
                  <Container>
                    <CardMedia>
                    {existingClientSalesArray  && (
                        <ColumnDashboardWidget
                          chartId="ch-2"
                          graphData={existingClientSalesArray || '0'}
                          xAxisTitle="Start date End date"
                          yAxisTitle="Sales purchase"
                        />
                      )}
                      {/* <ColumnDashboardWidget chartId="ch-2" graphData={ExistingClientSales} xAxisTitle="Weeks" yAxisTitle="Sales purchase" /> */}
                    </CardMedia>
                  </Container>
                </Card>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12} sm={12} lg={12} xl={12}>
                <Card
                  // className="pos-card"
                  sx={{
                    backgroundColor: '#F8FBFF',
                    border: '0.4px solid #C5C5C5',
                    pb: 1,
                    marginTop: '10px'
                  }}
                >
                  <CardHeader
                    className="font-18 drodown_menu"
                    title="CLASS OCCUPANCY"
                    action={<MoreVertIcon />}
                  />
                  <Container>
                    <CardMedia>
                      <LineGraphDashboardWidgets graphData={ClassOccupancy} xAxisTitle="Weeks" yAxisTitle="AttendencePerWeek" />
                    </CardMedia>
                  </Container>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      )}
      {isFranchisor === 'true' && (
        <Container maxWidth="100%">
          <Container maxWidth="100%">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <CustomRevenueCards
                  title="REVENUE"
                  subTitle="90 Days"
                  Icon={<PercentIcon />}
                  Units={totalRevenue?.totalRevenue
                    ? `$${parseFloat(totalRevenue?.totalRevenue)?.toFixed(2)}`
                    : '$ 0.00'
                  }
                />

              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <CustomRevenueCards
                  title="ATTRITION RATE"
                  subTitle="30 Days"
                  Icon={<AddCardIcon />}
                  Units="38%"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <CustomRevenueCards
                  title="YOY REVENUE"
                  subTitle="90 Days"
                  Icon={<AddCardIcon />}
                  Units="38.5%"
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="100%">
            <Grid container sx={{ mt: 3 }} spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Card sx={{ backgroundColor: '#F8FBFF', border: '0.4px solid #C5C5C5', pb: 1 }}>
                  <CardHeader className="font-18 drodown_menu" title="NEW CLIENT SALES" action={<MoreVertIcon />} />
                  <Container>
                    <CardMedia>
                      <ColumnDashboardWidget chartId="ch-3" xAxisTitle="Months" yAxisTitle="Sales of month" graphData={SalesByMonth} />
                    </CardMedia>
                  </Container>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Card sx={{ backgroundColor: '#F8FBFF', border: '0.4px solid #C5C5C5', pb: 1 }}>
                  <CardHeader className="font-18 drodown_menu" title="EXISTING CLIENT SALES" action={<MoreVertIcon />} />
                  <Container>
                    <CardMedia>
                      <ColumnDashboardWidget chartId="ch-4" xAxisTitle="Weeks" yAxisTitle="Clients purchase per week" graphData={NewPayingClients} />
                    </CardMedia>
                  </Container>
                </Card>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12} sm={12} lg={12} xl={12}>
                <Card
                  // className="pos-card"
                  sx={{
                    backgroundColor: '#F8FBFF',
                    border: '0.4px solid #C5C5C5',
                    pb: 1,
                    marginTop: '10px'
                  }}
                >
                  <CardHeader
                    className="font-18 drodown_menu"
                    title="CLASS OCCUPANCY"
                    action={<MoreVertIcon />}
                  />
                  <Container>
                    <CardMedia>
                      <LineGraphDashboardWidgets xAxisTitle="Weeks" yAxisTitle="AttendencePerWeek" graphData={ClassOccupancyFranchisor} />
                    </CardMedia>
                  </Container>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}
      {/* has context menu */}
      {/* End of location icon */}
      {/* Now draw the reports */}
      {/* <Reports /> */}
      {/* Now draw the footer */}
      {/* <Footer /> */}
      {/* {isFranchisor === 'true' && (
        <SwitchLocationPopup
          setOpenSwitchLocationPopup={setOpenSwitchLocationPopup}
          openSwitchLocationPopup={openSwitchLocationPopup}
          locationOptions={locationOptions}
        />
      )} */}
    </>
  );
}

export default Home;
