import { Helmet } from 'react-helmet-async';
import { Container, Grid, Box } from '@mui/material';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import ProductListAPI from './ProductListAPI';
import Footer from 'src/components/Footer';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalData from '../../../global';
import Header from '../../../components/Header';

import { ApiService } from 'src/services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import AddProduct from './AddProduct';
// import { HeaderButtonContext } from 'src/contexts/HeaderButtonContext';

function ProductList() {
  const navigate = useNavigate();
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Products');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Products';
  const [showScreen, setShowScreen] = useState(0);
  // const { showScreen, setShowScreen,setIsToggleComponent} = useContext(HeaderButtonContext);
  // useEffect(() => {
  //   setIsToggleComponent(false)
  // }, [])
  const childRef = useRef(null);

  const [skuCode, setskuCode] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // show the add product popup
  // const [openAddProduct, setAddProduct] = React.useState(false);
  // new state to open the add product popup
  // const [openProductPopup, setopenProductPopup] = React.useState(false);
  // const [showScreen, setShowScreen] = useState(0);

  // Get the title from global data
  const title = GlobalData[0].title;

  useEffect(() => {
    let isLogin = sessionStorage.getItem('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    getAutoGeneratedSkuCode();
  }, []);

  // const addNewProduct = () => {
  //   // Now set false for all teams
  //   setShowScreen(1)
  //   // setAddProduct(true);
  //   // console.log("openAddProduct", openAddProduct);
  //   // setopenProductPopup(true);
  // };

  // Method to close the franchisee form
  // const refreshPage = () => {

  //   // trigger the parent method to refresh the data
  //   childRef.current.refreshData();
  //   // handleClose();
  // }

  const closeAddProduct = () => {
    console.log(childRef, childRef.current);
    if (childRef.current) childRef.current.refreshProductData();
  };
  const handleButtonClick = () => {
    setShowScreen(1);
  };

  const buttons = [
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theam: true
    }
  ];
  // function to get sku code==>
  const getAutoGeneratedSkuCode = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}products/maxSku`
      );
      if (!response?.data?.error) {
        setskuCode(response?.data?.sku || '');
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.message || 'Something went wrong with state');
      }
    } catch (error) {
      console.log(error);

      setShowErrorMessage(true);
      setErrorMessage(error.message || 'Internal Server Error');
    }
  };

  return (
    <>
      <Helmet>
        <title>Product Management | {title}</title>
      </Helmet>

      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {/* maxWidth="lg" */}
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <Box sx={{ width: '100%' }}>
                {/* showScreen={showScreen} */}
                <AddProduct
                  setShowScreen={setShowScreen}
                  refreshData={closeAddProduct}
                  autoGeneratedSkuCode={skuCode}
                  getAutoGeneratedSkuCode={getAutoGeneratedSkuCode}
                />
              </Box>
            );
          default:
            return (
              <Container maxWidth="100%" className="main-container">
                {showScreen === 0 && (
                  <Header title="Product Management" buttons={buttons} />
                )}
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item width={'100%'}>
                    {/* Now draw the location table */}
                    <ProductListAPI
                      skuCode={skuCode}
                      showScreen={showScreen}
                      setShowScreen={setShowScreen}
                      ref={childRef}
                    />
                  </Grid>
                </Grid>
              </Container>
            );
        }
      })()}

      <Footer />
    </>
  );
}

export default ProductList;
