/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-useless-computed-key */
/* eslint-disable spaced-comment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable react/self-closing-comp */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Slide,
  Typography
} from '@mui/material';
// import { DateTime } from 'luxon';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { ApiService } from '../../../services/ApiService';
import { getLocalData } from 'src/utils';
import { DateTime } from 'luxon';
import Loader from '../../../components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function SetOperationHoursModal({
  open,
  setOpen,
  setModalOpen,
  getAllOperatingHours,
  setData,
  setIsEdit,
  data,
  isEdit,
  // setFromEndTime,
  // setStartFromTime
}) {
  const [formData, setFormData] = useState({
    selectedDay: '',
    fromTime: '',
    toTime: ''
  });

  const [errors, setErrors] = useState({
    selectedDay: '',
    fromTime: '',
    toTime: ''
  });
  function convertUTCToUserTime(utcTime) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDateTime = DateTime.fromFormat(utcTime, 'h:mm a', { zone: 'UTC' });
    const userTime = utcDateTime.setZone(timeZone);
    return userTime.toFormat('hh:mm a');
  }
  
  useEffect(() => {
    if (isEdit) {
      setFormData({
        selectedDay: data.day,
        fromTime: convertUTCToUserTime(data.startTime),
        toTime: convertUTCToUserTime(data.endTime)
      });
      console.log("After setting formData:", formData);
    }
  }, [isEdit]);
  
console.log(formData,'gfgdfgdfgdfgdfg');
  const [isLoading, setIsLoading] = useState(false);
  const locationId = getLocalData('locationId');
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.selectedDay) {
      newErrors.selectedDay = 'Please select a day.';
      isValid = false;
    }

    if (!formData.fromTime) {
      newErrors.fromTime = 'Please select a start time.';
      isValid = false;
    }

    if (!formData.toTime) {
      newErrors.toTime = 'Please select an end time.';
      isValid = false;
    }

    if (
      formData.fromTime &&
      formData.toTime &&
      new Date(`01/01/2023 ${formData.fromTime}`) >=
        new Date(`01/01/2023 ${formData.toTime}`)
    ) {
      newErrors.toTime = 'End time must be after start time.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     fromTime: startFromTime,
  //     toTime: startFromEndTime
  //   });
  // }, [startFromTime, startFromEndTime]);
  function convertTimeToUTC(timeString) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userTime = DateTime.fromFormat(timeString, 'h:mm a', { zone: timeZone });
    const utcTime = userTime.toUTC();
    return utcTime.toFormat('hh:mm a');
  }

  const handleSave = async () => {
    if (validateForm()) {
      //  setIsLoading(true);
      let payload = {
        locationId: locationId,
        createdBy: 'User',
        timeZone: 'America/New_York',
        operatingHours: [
          {
            day: formData.selectedDay,
            endTime: convertTimeToUTC(formData.toTime),
            startTime: convertTimeToUTC(formData.fromTime)
          }
        ]
      };
       setIsLoading(true);
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/addLocationOperatingHours`,
        payload
      );
      console.log(response, 'respomnse');
      if (!response.error) {
        setIsLoading(false);
        getAllOperatingHours();
        handleClose();
        // setData(true)
      
      }
    }
  };
  console.log(isEdit, 'formDataformData');
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false)
    setFormData({
      selectedDay: '',
      fromTime: '',
      toTime: ''
    });
    // setFromEndTime('');
    // setStartFromTime('');
    setErrors({
      selectedDay: '',
      fromTime: '',
      toTime: ''
    });
  };
  console.log(formData, 'formdatatata');
  const dayOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
  ];

  const timeOptions = [
    { value: '12:00 AM', label: '12:00 AM' },
    { value: '12:30 AM', label: '12:30 AM' },
    { value: '01:00 AM', label: '01:00 AM' },
    { value: '01:30 AM', label: '01:30 AM' },
    { value: '02:00 AM', label: '02:00 AM' },
    { value: '02:30 AM', label: '02:30 AM' },
    { value: '03:00 AM', label: '03:00 AM' },
    { value: '03:30 AM', label: '03:30 AM' },
    { value: '04:00 AM', label: '04:00 AM' },
    { value: '04:30 AM', label: '04:30 AM' },
    { value: '05:00 AM', label: '05:00 AM' },
    { value: '05:30 AM', label: '05:30 AM' },
    { value: '06:00 AM', label: '06:00 AM' },
    { value: '06:30 AM', label: '06:30 AM' },
    { value: '07:00 AM', label: '07:00 AM' },
    { value: '07:30 AM', label: '07:30 AM' },
    { value: '08:00 AM', label: '08:00 AM' },
    { value: '08:30 AM', label: '08:30 AM' },
    { value: '09:00 AM', label: '09:00 AM' },
    { value: '09:30 AM', label: '09:30 AM' },
    { value: '10:00 AM', label: '10:00 AM' },
    { value: '10:30 AM', label: '10:30 AM' },
    { value: '11:00 AM', label: '11:00 AM' },
    { value: '11:30 AM', label: '11:30 AM' },
    { value: '12:00 PM', label: '12:00 PM' },
    { value: '12:30 PM', label: '12:30 PM' },
    { value: '01:00 PM', label: '01:00 PM' },
    { value: '01:30 PM', label: '01:30 PM' },
    { value: '02:00 PM', label: '02:00 PM' },
    { value: '02:30 PM', label: '02:30 PM' },
    { value: '03:00 PM', label: '03:00 PM' },
    { value: '03:30 PM', label: '03:30 PM' },
    { value: '04:00 PM', label: '04:00 PM' },
    { value: '04:30 PM', label: '04:30 PM' },
    { value: '05:00 PM', label: '05:00 PM' },
    { value: '05:30 PM', label: '05:30 PM' },
    { value: '06:00 PM', label: '06:00 PM' },
    { value: '06:30 PM', label: '06:30 PM' },
    { value: '07:00 PM', label: '07:00 PM' },
    { value: '07:30 PM', label: '07:30 PM' },
    { value: '08:00 PM', label: '08:00 PM' },
    { value: '08:30 PM', label: '08:30 PM' },
    { value: '09:00 PM', label: '09:00 PM' },
    { value: '09:30 PM', label: '09:30 PM' },
    { value: '10:00 PM', label: '10:00 PM' },
    { value: '10:30 PM', label: '10:30 PM' },
    { value: '11:00 PM', label: '11:00 PM' },
    { value: '11:30 PM', label: '11:30 PM' }
  ];
  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        className="enrollClassDialog"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="custom-scroll -team-container-white-box">
          <Box
            sx={{ width: '100%' }}
            paddingBottom="20px"
            className="Main-container common-form-container"
          >
            <Box className="custom-form">
              <Grid mt={0} marginBottom="8px">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  gap={'15px'}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'left',
                      width: '100%'
                    }}
                    mb={2}
                  >
                     <Typography className={'-dialogPopupHeader'}>
                      {' '}
                      Operating Hours
                    </Typography>
                    {/* <Typography className={'-dialogPopupHeade'}>
                      {' '}
                      Operating Hours
                    </Typography> */}
                    <CancelOutlinedIcon
                      color="error"
                      sx={{
                        width: '32px',
                        height: '32px',
                        cursor: 'pointer'
                      }}
                      onClick={handleClose}
                    />
                  </Box>
                  {/* <h5 className="title"
                    style={{ textTransform: "capitalize", color: "#003087", margin: 0, marginTop: '-60px', marginBottom: '50px' }}>Add
                    Operating Hours</h5> */}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: '30px'
                    }}
                  >
                    <FormControl
                      pt={2}
                      variant="outlined"
                      fullWidth
                      sx={{
                        width: '47.89%'
                      }}
                    >
                      <InputLabel className="text-field-styling">
                        SELECT DAY
                      </InputLabel>
                      <Select
                      disabled={isEdit}
                        value={formData?.selectedDay }
                        onChange={(event) => {
                          return (
                            setFormData({
                              ...formData,
                              selectedDay: event.target.value
                            }),
                            setErrors({ ...errors, selectedDay: '' })
                          );
                        }}
                        label="SELECT DAY"
                        name="selectedDay"
                        
                        // error={!!errors.selectedDay}
                        helperText={errors.selectedDay}
                        className="status-field field-label-css"
                        IconComponent={KeyboardArrowDownIcon}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 200 } }
                        }}
                      >
                        {/* <MenuItem key={1} id={1} value={'ALL'}>
                          {' '}
                          {'All'}{' '}
                        </MenuItem> */}
                        {/* dont remove below code this is required in future */}
                        {dayOptions?.length > 0 &&
                          dayOptions?.map((item, index) => (
                            <MenuItem key={index} id={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                      </Select>
                      {errors.selectedDay && (
                        <Typography color="#d13a00">
                          {errors.selectedDay}
                        </Typography>
                      )}
                    </FormControl>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        gap: '30px'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: '10px'
                        }}
                      >
                        <FormControl
                          pt={2}
                          variant="outlined"
                          fullWidth
                          sx={{
                            width: '100%'
                          }}
                        >
                          <InputLabel className="text-field-styling">
                            From
                          </InputLabel>
                          <Select
                            value={formData.fromTime}
                            onChange={(event) => {
                              return (
                                setFormData({
                                  ...formData,
                                  fromTime: event.target.value
                                }),
                                setErrors({ ...errors, fromTime: '' })
                              );
                            }}
                            label="Time"
                            name="fromTime"
                            // error={!!errors.fromTime}
                            helperText={errors.fromTime}
                            className="status-field field-label-css"
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {timeOptions?.length > 0 &&
                              timeOptions?.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  id={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.fromTime && (
                            <Typography color="#d13a00">
                              {errors.fromTime}
                            </Typography>
                          )}
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          gap: '10px'
                        }}
                      >
                        <FormControl
                          pt={2}
                          variant="outlined"
                          fullWidth
                          sx={{
                            width: '100%'
                          }}
                        >
                          <InputLabel className="text-field-styling">
                            To
                          </InputLabel>
                          <Select
                            value={formData.toTime}
                            // onChange={(event) => setFormData({ ...formData, toTime: event.target.value })}
                            onChange={(event) => {
                              return (
                                setFormData({
                                  ...formData,
                                  toTime: event.target.value
                                }),
                                setErrors({ ...errors, toTime: '' })
                              );
                            }}
                            label="To"
                            // error={!!errors.toTime}
                            helperText={errors.toTime}
                            name="toTime"
                            className="status-field field-label-css"
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {timeOptions?.length > 0 &&
                              timeOptions?.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  id={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.toTime && (
                            <Typography color="#d13a00">
                              {errors.toTime}
                            </Typography>
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      className="top-button-style"
                      sx={{ padding: '15px' }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
