/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Checkbox,
  Typography,
  Card,
  Grid,
  Paper,
  Divider,
  Autocomplete,
  TextField,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

export const InventoryFilter = ({ setFilters, filters, allInventoryData, categoryData }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));
  // create a custom theme with updated hover and close icon colors
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });
  const autoCompleteClasses = useAutocompleteStyles();
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    manufacturer: false,
    categoryName: false
  });

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      manufacturer: field === 'manufacturer' ? !prevState.manufacturer : false,
      categoryName: field === 'categoryName' ? !prevState.categoryName : false,
      bestSeller: field === 'bestSeller' ? !prevState.bestSeller : false
    }));
  };

  const handleChangeFilter = (value, type) => {
    if (type === "isBestSeller" && !value) {
      value = null;
    }

    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const clearFilters = () => {
    setFilters({
      isBestSeller: null,
      manufacturer: null,
      categoryName: []
    });

    setOpenFilter({
      manufacturer: false,
      categoryName: false,
      bestSeller: false
    });
  };

  useEffect(() => {
    if (filters.categoryName?.length > 0) {
      setOpenFilter((prevState) => ({
        ...prevState,
        categoryName: true,
        manufacturer: false,
        bestSeller: false,
      }));
    }
    // check for manufacturer fields and open it
    if (filters.manufacturer && !filters.categoryName?.length > 0) {
      setOpenFilter((prevState) => ({
        ...prevState,
        manufacturer: true,
        bestSeller: false,
        categoryName: false
      }));
    }
    if (filters.isBestSeller && !filters.categoryName?.length > 0 && !filters.manufacturer) {
      setOpenFilter((prevState) => ({
        ...prevState,
        bestSeller: true,
        manufacturer: false,
        categoryName: false
      }));
    }

  }, [filters.categoryName, filters.manufacturer, filters.isBestSeller]);

  return (
    <>
      <Card className="filter-content custom-filter-fields">
        {/* <Typography className="filter-label" pt={2} pb={1}>
          BestSeller
        </Typography>

          <Box marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  className="check-box-span"
                  checked={filters.isBestSeller ?? ''}
                  onChange={(event) =>
                    handleChangeFilter(event.target.checked, 'isBestSeller')
                  }
                  color="default"
                  sx={{
                    '&.Mui-checked': {
                      color: '#003087'
                    }
                  }}
                />
              }
              label={
                <Typography className="check-box-text">Best Seller</Typography>
              }
            />
          </Box>

        <Divider /> */}

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('categoryName')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.categoryName && classes.closeY,
                    openFilter.categoryName && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Category</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.categoryName && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              {categoryData && categoryData?.length ? (
                <>
                  <InputLabel>Select Category</InputLabel>
                  <Select
                    // multiple
                    onChange={(event) =>
                      handleChangeFilter(event.target.value, 'categoryName')
                    }
                    value={filters.categoryName}
                    sx={{
                      width: '100%'
                    }}
                    label="Select Category"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          overflowY: 'auto',
                        },
                      },
                    }}
                    className="status-field field-label-css height-auto"
                  >
                    {/* <MenuItem value="">None</MenuItem> */}
                    {categoryData &&
                      categoryData?.length > 0 &&
                      categoryData.map((item) => {
                        const name = encodeURIComponent(item.categoryName);
                        return (
                          <MenuItem key={item.sortKey} value={name}>
                            {item.categoryName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </>
              ) : (
                <Typography pl={3}>No Category available</Typography>
              )}
            </FormControl>
            {/* <FormControl fullWidth variant="outlined">
              {categoryData?.length ? (
                <>
                  <InputLabel>Select Category</InputLabel>
                  <Select
                    multiple
                    onChange={(event) =>
                      handleChangeFilter(event.target.value, 'categoryName')
                    }
                    value={filters.categoryName}
                    sx={{
                      width: '100%'
                    }}
                    label="Select Category"
                    className="status-field field-label-css"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          overflowY: 'auto',
                        },
                      },
                    }}
                  >
                    {categoryData && categoryData?.length > 0 &&
                      [
                        ...new Set(
                          categoryData.map((option) => option.categoryName)
                        )
                      ]?.map((categoryName) => (
                        <MenuItem id={categoryName} value={categoryName}>
                          {categoryName}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              ) : (
                <Typography pl={3}>No Category available</Typography>
              )}
            </FormControl> */}
          </Box>
          //  {allInventoryData?.category?.map((item) => (
          //   <MenuItem id={item.categoryId} value={item.categoryName}>
          //     {item.categoryName}
          //   </MenuItem>
          // ))}
          // <Box marginTop={1}>
          //   <Autocomplete
          //     // classes={autoCompleteClasses}
          //     multiple
          //     value={filters.categoryName}
          //     onChange={(event, newValue) => {
          //         handleChangeFilter(event.target.value, 'categoryName')
          //     }}
          //     // onInputChange={(event, newInputValue) => {
          //     //   handleCategoryChange(newInputValue);
          //     // }}
          //     className="status-field field-label-css"
          //     options={allInventoryData.map((option) => option.manufacturer)}
          //     sx={{
          //       width: '100%',
          //       borderRadius: '19px ! important'
          //     }}
          //     renderInput={(params) => (
          //       <TextField
          //         {...params}
          //         className="primary-color"
          //         label="Search Category"
          //       />
          //     )}
          //   />
          // </Box>
        )}

        <Grid
          item
          onClick={() => handleOpenFilter('manufacturer')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.manufacturer && classes.closeY,
                    openFilter.manufacturer && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Manufacturer</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.manufacturer && (
          <Box marginTop={1}>
            {/* <Autocomplete
              disablePortal
              value={filters?.manufacturer}
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                handleChangeFilter(newValue, 'manufacturer');
              }}
              className="status-field field-label-css height-auto"
              options={allInventoryData && allInventoryData?.length > 0 && allInventoryData.map((option) => option.manufacturer)}
              sx={{
                width: '100%'
              }}
              renderInput={(params) => (
                <TextField {...params} label={params?.label || 'Select Manufacturer'} />
              )}
            /> */}
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Manufacturer</InputLabel>
              <Select
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'manufacturer')
                }
                value={filters.manufacturer}
                sx={{
                  width: '100%'
                }}
                label="Select Manufacturer"
                className="status-field field-label-css height-auto"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflowY: 'auto',
                      maxWidth: 100
                    },
                  },
                }}
              >
                {allInventoryData?.length > 0 &&
                  [
                    ...new Set(
                      allInventoryData.map((option) => option.manufacturer)
                    )
                  ]?.map((manufacturer) => (
                    <MenuItem id={manufacturer} value={manufacturer}>
                      {manufacturer}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> 
          </Box>
        )}
        <Grid
          xs={12}
          onClick={() => handleOpenFilter('bestSeller')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.bestSeller && classes.closeY,
                    openFilter.bestSeller && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>BestSeller</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.bestSeller && (
          <Box marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  className="check-box-span"
                  checked={filters.isBestSeller ?? ''}
                  onChange={(event) =>
                    handleChangeFilter(event.target.checked, 'isBestSeller')
                  }
                  color="default"
                  sx={{
                    '&.Mui-checked': {
                      color: '#003087'
                    }
                  }}
                />
              }
              label={
                <Typography className="check-box-text">Best Seller</Typography>
              }
            />
          </Box>
        )}

      </Card>
    </>
  );
};
