import { Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import Home from '../content/dashboards/Home';
// Franchisor functionlaity
import LocationManagement from '../content/applications/LocationManagement';
import FranchiseeManagement from '../content/applications/FranchiseeManagement';
import ProductManagement from '../content/applications/ProductManagement';
import UserManagement from '../content/applications/UserManagement';
import ClientManagement from '../content/applications/ClientManagement';
import StaffManagement from '../content/applications/StaffManagement';
import TransferHistory from '../content/applications/TransferHistory';
import WithdrawInventory from '../content/applications/WithdrawInventory/InventoryExpiry';
import PurchaseOrder from '../content/applications/PurchaseOrder';
import InventoryApp from '../content/applications/Inventory';
import InventoryTransfer from '../content/applications/Inventory/Transfer';
import TransferEditInventory from '../content/applications/Inventory/TransferEdit';
import InventoryRevise from '../content/applications/Inventory/Revise';
import AdjustmentHistory from '../content/applications/Inventory/Adjustment';
import Services from '../content/applications/Services';
import Marketing from '../content/applications/Marketing';
import FranchiseeMarketingManagement from '../content/applications/FranchiseeCohorts/Index';
import FranchisorCohorts from '../content/applications/FranchisorCohorts';
import AddCampaign from '../content/applications/Marketing/addCampaign';
import Liability from 'src/content/applications/LiabilityWaiver';
import ManageSuppliers from 'src/content/applications/ManageSuppliers/ManageSuppliers';
import ContractManagement from 'src/content/applications/Contract';
import GiftCards from 'src/content/applications/GiftCards/GiftCardTable';
import PricingOptions from 'src/content/applications/PricingOptions';
import CheckoutManagement from 'src/content/applications/Checkout';
import Memberships from 'src/content/applications/Membership';
import PromotionManagement from 'src/content/applications/PromotionManagement';
import FeaturedItems from 'src/content/applications/FeaturedItems';
import Bundles from 'src/content/applications/Bundles';
import AppointmentSchedules from 'src/content/applications/Schedules';
import PosDashboard from 'src/content/applications/PosDashboard';
import FranchiseeServices from 'src/content/applications/FranchiseService';
import FranchiseProducts from 'src/content/applications/FranchiseProduct';
import Trophy from 'src/content/applications/Trophy';
import SalesHistoryMain from 'src/content/applications/SalesHistory';
import FranchiseeTemplate from 'src/content/applications/FranchiseeTemplate';
import FranchisorTemplate from 'src/content/applications/FranchisorTemplate';
import StaffDashboard from 'src/content/applications/StaffDashboard/Index';
// import FranchiseeJourney from 'src/content/applications/FranchiseeJourney';
import Journey from 'src/content/applications/Journey';
import Holiday from 'src/content/applications/Holiday';
// import Rooms from 'src/components/Schedules/AllRooms';
import Rooms from 'src/content/applications/FranchiseeRooms';

// Reports
import PerformanceReport from 'src/content/dashboards/Home/PerformanceReport';
import AvgSpendingOverTimeReport from 'src/content/dashboards/Home/AvgSpendingOverTimeReport';
import StaffRentationReport from 'src/content/dashboards/Home/StaffRentationReport';
import BusinessEntities from 'src/content/applications/Business Entities';
import InventoryManagementDashboard from 'src/content/applications/InventoryManagementDashboard';
import Campaign from 'src/content/applications/Campaign';
import Unauthorized from '../content/pages/Status/Unauthorized';
import FranchisorMemberships from 'src/content/applications/FranchisorManagement/FranchisorMembership';
import FrachisorBundle from 'src/content/applications/FranchisorManagement/FranchisorBundle';
import FrachisorContract from 'src/content/applications/FranchisorManagement/Contract';
import FrachisorPromotion from 'src/content/applications/FranchisorManagement/PromotionManagement';
import OperatingHours from 'src/content/applications/OperationHoursSet';
import StaffTimeOff from 'src/content/applications/StaffTimeOff';
import RecieveInventory from 'src/content/applications/RecieveInventory'
import InventoryOnHand from '../content/applications/InventoryOnHand';

export const privateRoutes = [
  {
    path: '',
    element: <Navigate to="home" replace />
  },
  {
    path: 'home',
    element: <Home />
  },
  {
    path: 'staff-dashboard',
    element: (
      <ProtectedRoute
        allowedRoles={['Trainer', 'Front Desk']}
        element={<StaffDashboard />}
      />
    )
  },
  {
    path: 'franchisee-management',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<FranchiseeManagement />}
      />
    )
  },
  {
    path: 'location-management',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<LocationManagement />}
      />
    )
  },
  {
    path: 'product-management',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<ProductManagement />}
      />
    )
  },
  {
    path: 'services',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<Services />}
      />
    )
  },
  {
    path: 'user-management',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<UserManagement />}
      />
    )
  },
  {
    path: 'client-management',
    element: (
      <ProtectedRoute
        allowedRoles={[
          'Admin',
          'Super Manager',
          'Owner',
          'Manager',
          'Trainer',
          'Front Desk'
        ]}
        element={<ClientManagement />}
      />
    )
  },
  {
    path: 'staff-management',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<StaffManagement />}
      />
    )
  },
  {
    path: 'transfer-history',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<TransferHistory />}
      />
    )
  },
  {
    path: 'withdraw-products',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<WithdrawInventory />}
      />
    )
  },
  {
    path: 'purchase-order',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<PurchaseOrder />}
      />
    )
  },
  {
    path: 'inventory-adjustment',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<InventoryApp />}
      />
    )
  },
  {
    path: 'inventory-transfer',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<InventoryTransfer />}
      />
    )
  },
  {
    path: 'inventory-transfer-edit',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<TransferEditInventory />}
      />
    )
  },
  {
    path: 'inventory-revise',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<InventoryRevise />}
      />
    )
  },
  {
    path: 'adjustment-history',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<AdjustmentHistory />}
      />
    )
  },
  {
    path: 'marketing',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<Marketing />}
      />
    )
  },
  // {
  //   path: 'cohorts',
  //   element: (
  //     <ProtectedRoute
  //       allowedRoles={['Owner', 'Manager']}
  //       element={<FranchiseeMarketingManagement />}
  //     />
  //   )
  // },
  // {
  //   path: 'franchisor-cohorts',
  //   element: (
  //     <ProtectedRoute
  //       allowedRoles={['Admin']}
  //       element={<FranchisorCohorts />}
  //     />
  //   )
  // },
  // {
  //   path: 'template',
  //   element: (
  //     <ProtectedRoute
  //       allowedRoles={['Owner', 'Manager']}
  //       element={<FranchiseeTemplate />}
  //     />
  //   )
  // },
  // {
  //   path: 'franchisor-template',
  //   element: (
  //     <ProtectedRoute
  //       allowedRoles={['Admin','Super Manager']}
  //       element={<FranchisorTemplate />}
  //     />
  //   )
  // },
  {
    path: 'cohorts',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<FranchiseeMarketingManagement />}
      />
    )
  },
  {
    path: 'franchisor-cohorts',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<FranchisorCohorts />}
      />
    )
  },
  {
    path: 'template',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<FranchiseeTemplate />}
      />
    )
  },
  {
    path: 'franchisor-template',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<FranchisorTemplate />}
      />
    )
  },
  {
    path: 'campaign',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<AddCampaign />}
      />
    )
  },
  {
    path: 'liability',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<Liability />}
      />
    )
  },
  {
    path: 'manage-suppliers',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<ManageSuppliers />}
      />
    )
  },
  {
    path: 'contract-management',
    element: (
      <ProtectedRoute
        allowedRoles={[
          'Owner',
          'Manager',
          'Front Desk'
          // 'Admin',
          // 'Super Manager'            // We have franchisor contract below
        ]}
        element={<ContractManagement />}
      />
    )
  },
  {
    path: 'gift-cards',
    element: (
      <ProtectedRoute
        allowedRoles={[
          'Owner',
          'Manager',
          'Front Desk', // 'Admin', 'Super Manager',
          'Trainer'
        ]}
        element={<GiftCards />}
      />
    )
  },
  {
    path: 'pricing-options',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Owner', 'Manager', 'Front Desk']}
        element={<PricingOptions />}
      />
    )
  },
  {
    path: 'checkout',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<CheckoutManagement />}
      />
    )
  },
  {
    path: 'memberships',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<Memberships />}
      />
    )
  },
  {
    path: 'pos',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<PosDashboard />}
      />
    )
  },
  {
    path: 'promotion-management',
    element: (
      <ProtectedRoute
        allowedRoles={[
          'Owner',
          'Manager',
          'Front Desk'
          // 'Admin',
          // 'Super Manager'          // We have franchisor promotion below
        ]}
        element={<PromotionManagement />}
      />
    )
  },
  {
    path: 'featured-items',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<FeaturedItems />}
      />
    )
  },
  {
    path: 'bundles-management',
    element: (
      <ProtectedRoute
        allowedRoles={[
          'Owner',
          'Manager',
          'Front Desk'
          // 'Admin',
          // 'Super Manager'
        ]}
        element={<Bundles />}
      />
    )
  },

  {
    path: 'schedules',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Trainer', 'Front Desk']}
        element={<AppointmentSchedules />}
      />
    )
  },
  {
    path: 'performance-report',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<PerformanceReport />}
      />
    )
  },
  {
    path: 'avg-spendingOverTime-report',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<AvgSpendingOverTimeReport />}
      />
    )
  },
  {
    path: 'staffRentation-report',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<StaffRentationReport />}
      />
    )
  },
  {
    path: 'franchise-services',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<FranchiseeServices />}
      />
    )
  },
  {
    path: 'franchise-products',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<FranchiseProducts />}
      />
    )
  },
  {
    path: 'business-entities',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Front Desk']}
        element={<BusinessEntities />}
      />
    )
  },
  {
    path: 'inventory-managment',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Front Desk', 'Manager']}
        element={<InventoryManagementDashboard />}
      />
    )
  },
  {
    path: 'trophy-management',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<Trophy />}
      />
    )
  },
  {
    path: 'campaign-management',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<Campaign />}
      />
    )
  },
  // {
  //   path: 'sales-history',
  //   element: (
  //     <ProtectedRoute
  //       allowedRoles={['Owner', 'Manager', 'Front Desk', 'Trainer']}
  //       element={<SalesHistoryMain />}
  //     />
  //   )
  // },
  {
    path: '/unauthorized',
    element: <Unauthorized />
  },
  {
    path: 'journey-management',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager', 'Owner', 'Manager']}
        element={<Journey />}
      />
    )
  },
  {
    path: 'holiday',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<Holiday />}
      />
    )
  },
  {
    path: 'sales-history',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Trainer', 'Front Desk']}
        element={<SalesHistoryMain />}
      />
    )
  },
  {
    path: 'sales-history/:id',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager', 'Trainer', 'Front Desk']}
        element={<SalesHistoryMain />}
      />
    )
  },
  {
    path: 'franchisor-memberships',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<FranchisorMemberships />}
      />
    )
  },
  {
    path: 'franchisor-bundle',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<FrachisorBundle />}
      />
    )
  },
  {
    path: 'franchisor-contract',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<FrachisorContract />}
      />
    )
  },
  {
    path: 'franchisor-promotion',
    element: (
      <ProtectedRoute
        allowedRoles={['Admin', 'Super Manager']}
        element={<FrachisorPromotion />}
      />
    )
  },
  {
    path: 'operating-hours',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<OperatingHours />}
      />
    )
  },
  {
    path: 'staff-timeoff',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<StaffTimeOff />}
      />
    )
  },
  {
    path: 'rooms',
    element: (
      <ProtectedRoute
        allowedRoles={['Owner', 'Manager']}
        element={<Rooms />}
      />
    )
  },
  {
    path:'receive-inventory',
    element:(
      <ProtectedRoute
        allowedRoles={['Front Desk']}
        element={<RecieveInventory />}
      />
    )
  },
  {
    path:'inventory-onhand',
    element:(
      <ProtectedRoute
        allowedRoles={['Front Desk']}
        element={<InventoryOnHand/>}
      />
    )
  }
  // {
  //   path: 'franchisee-journey',
  //   element: (
  //     <ProtectedRoute
  //       allowedRoles={['Owner', 'Manager']}
  //       element={<FranchiseeJourney />}
  //     />
  //   )
  // }
];
