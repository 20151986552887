import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Day,
  Week,
  ScheduleComponent,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
  Inject
} from '@syncfusion/ej2-react-schedule';
import './style.css';
import { formatDateTimeCalender, convertFrom12To24Format } from 'src/utils';
import { Stack, Typography, Avatar, Button, Box, Grid } from '@mui/material';
import { DateTime } from 'luxon';


export const CalenderSchedular = (props) => {
  const {
    view,
    currentDate,
    data,
    trainerData,
    handleOpenModal,
    setDialog,
    dialog,
    startHour,
    endHour,
    operatHrsData
  } = props;
  // eslint-disable-next-line no-unused-vars
  let scheduleObj = useRef < ScheduleComponent > (null);
  // const workHours = {
  //   highlight: true, start: '01:00', end: '20:00'
  // };
  const compareDate = new Date(currentDate);

  const navigate = useNavigate();
  let filterApointmentdata = [];
  if (view === 'week') {
    filterApointmentdata = data.filter((item) => item.trainerName !== 'Timeoff');
  } else {
    filterApointmentdata = data;
  }

  const [startHour1, setStartHour1] = useState('00:00');
  const [endHour1, setEndHour1] = useState('24:00');
  const [lastClickedTime, setLastClickedTime] = useState(new Date().getTime());

  useEffect(() => {
    setStartHour1(startHour);
    setEndHour1(endHour);
  }, [startHour, endHour, compareDate]);

  const onClassDetail = (args, key) => {
    handleOpenModal(args, 'data', key);
  };
  const onPopupOpen = (args) => {
    let currentClickedTime = new Date().getTime();
    if (currentClickedTime - lastClickedTime < 100) {
      args.cancel = true;
    }
    setLastClickedTime(new Date().getTime());
    let unAvailable = false;
    if (view === 'day') unAvailable = checkTrainerAvailability(args);
    if (args.data !== undefined) {
      // if clicked on the specific event or appointment
      if (
        args.target &&
        args.target.classList.contains('e-appointment')
      ) {
        // if (args.data.scheduleType !== 'enrollment') {
        //   handleOpenModal(args, 'data');
        // }
      } else 
      if (args.data.isBlock || unAvailable || args.target.classList.contains('e-week-unavailable')) {
        handleOpenModal(args, 'invalid');
        
      } else {
        handleOpenModal(args, 'valid');
      }
    }
    if (
      args.type === 'QuickInfo' ||
      args.type === 'Editor' ||
      args.target.classList.contains('e-selected-cell') ||
      (args.target && args.target.classList.contains('e-work-cells'))
    ) {
      if (args.data.scheduleType !== 'enrollment' && args.data.scheduleType !== 'appointment') {
        args.cancel = true;
      }
    }    
  };
  // check Trainer availability while clicking on the calander
  const checkTrainerAvailability = (args) => {
    let inValid = true;
    try {
      let trainer = args?.data?.trainerId ? trainerData.filter((item) => item.sortKey === args?.data?.trainerId) : [];
      if (trainer.length > 0) {


        let startTime = formatDateTimeCalender(args?.data?.startTime, 'time') ?? '00:00';
        let endTime = formatDateTimeCalender(args?.data?.endTime, 'time') ?? '00:00';
        let day = formatDateTimeCalender(args?.data?.startTime, 'day');
        let availability = trainer[0]?.trainerAvailability ?? [];

        startTime = convertFrom12To24Format(startTime) ?? '00:00';
        endTime = convertFrom12To24Format(endTime) ?? '00:00';

        availability?.forEach((availability) => {
          if (availability?.day === day) {
            let formateStartTime = convertUTCToUserTime(availability?.startTime)
            let formateEndTime = convertUTCToUserTime(availability?.endTime)
            let sTime = convertFrom12To24Format(formateStartTime) ?? '00:00';
            let eTime = convertFrom12To24Format(formateEndTime) ?? '00:00';
            if (startTime >= sTime && startTime < eTime || endTime > sTime && endTime <= eTime) inValid = false;
          }
        });
      }
    } catch (e) {
      inValid = true;
    }
    return inValid;
  }
  function convertUTCToUserTime(utcTime) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDateTime = DateTime.fromFormat(utcTime, 'h:mm a', { zone: 'UTC' });
    const userTime = utcDateTime.setZone(timeZone);
    return userTime.toFormat('hh:mm a');
  }
  const onRenderCell = (args) => {
    if (
      (args.element.classList.contains('e-work-cells') &&
        args.element.classList.length === 1) ||
      (args.element.classList.contains('e-work-cells') &&
        args.element.classList.contains('e-alternate-cells') &&
        args.element.classList.length === 2)
    ) {
      args.element.style.backgroundColor = '#FFFF';
    }
    if (
      (args.element.classList.contains('e-header-cells') ||
        args.element.classList.contains('e-all-day-cells')) &&
      view === 'day'
    ) {
      args.element.style.display = 'none';
    }
  
    try {
      // VV - added below logic for gray slots as per the unavailability of trainer and operating hours
      if (args.element.classList.contains('e-work-cells') || args.element.classList.contains('e-alternate-cells')) {
        let currentday = '';
        let hour = '';
        let minute = '';
        const cellDate = new Date(args?.date);
        if (cellDate?.toString() !== "Invalid Date" && view === 'week') {
          currentday = cellDate?.toLocaleString('en-US', { weekday: 'long' });
          let filterData = operatHrsData.filter((item) => item.day === currentday);
  
          if (filterData && filterData?.length > 0) {
            let sTime = convertUTCToUserTime(filterData[0]?.startTime);
            let eTime = convertUTCToUserTime(filterData[0]?.endTime);
            let startTime = sTime ? convertFrom12To24Format(sTime) : '';
            let endTime = eTime ? convertFrom12To24Format(eTime) : '';
  
            if (startTime && endTime) {
              hour = cellDate.getHours();
              minute = cellDate.getMinutes();
  
              if (`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}` < startTime || `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}` >= endTime) {
                args.element.style.backgroundColor = '#F5F5F5';
                args.element.classList.add('e-week-unavailable');
              } else {
                args.element.style.backgroundColor = '#FFF';
              }
            } else {
              // Mark the entire day as unavailable if there are no operating hours
              args.element.style.backgroundColor = '#F5F5F5';
              args.element.classList.add('e-week-unavailable');
            }
          } else {
            // Mark the entire day as unavailable if there are no operating hours
            args.element.style.backgroundColor = '#F5F5F5';
            args.element.classList.add('e-week-unavailable');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const resourceHeaderTemplate = (props) => {
    return (
      <Stack flexDirection="row">
        <Avatar
          alt={props?.resourceData?.trainerName}
          src={props?.resourceData?.trainerProfileImage}
        />
        <Stack flexDirection="column" marginLeft="10px">
          <Typography variant="h4">
            {props?.resourceData?.trainerName}
          </Typography>
          <Typography variant="body1">Trainer</Typography>
        </Stack>
      </Stack>
    );
  };
  const contentTemplate = (props) => {
    return (
      <Box className="enrollPopup">
        <Button onClick={() => onClassDetail(props)}>View Class Details</Button>
        <Button
          onClick={() => {
            onClassDetail(props, 'outSideData');
            setDialog({ ...dialog, bookClass: true });
          }}
        >
          Enroll New Pet
        </Button>
      </Box>
    );
  };


  const EventTemplate = (props) => {
    // const resource = trainerData.find((item) => item.trainerId === props.trainerId);
    // console.log(props, 'resourceresource');
    // if (props?.availableschedules) {
    //     return null;    
    // }
    return (
      <div
      // className={resource?.eventClass}
      // style={{
      //   backgroundColor: resource?.color
      // }}
      >
        {/* VV - holidaya and timeoff show as per the below condition */}
        {props?.IsBlock && (
          <Stack flexDirection="column">
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="white" className='holidayName'> {props?.trainerName}</Typography>
            </Grid>
          </Stack>
        )}

        {/* VV - appoiments are create as per the below condition */}
        {!props?.IsBlock && (
          <Stack flexDirection="column">
            <Stack flexDirection="row" justifyContent="space-between">
              <Stack sx={{ fontSize: '12px' }} justifyContent="flex-start">
                {props.serviceName}
              </Stack>
              <Stack sx={{ fontSize: '12px' }} justifyContent="flex-end">
                {props.scheduleType === 'enrollment' ? (
                  // This part will be rendered if props.scheduleType is 'enrollment'
                  <>
                    {props?.clientDetails?.length || 0}
                    /
                    {props?.maximumCapacity || 0}
                  </>
                ) : <>
                {props?.clientDetails?.length || 0}
                /
                {props?.clientDetails?.length || 0}
              </>}

              </Stack>
            </Stack>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography color="gray"> {props?.trainerName}</Typography>
              <Typography variant="body1" color="gray">
                {props?.room?.name}
              </Typography>
            </Grid>
            {/* {view === 'day' && ( */}
            {/* )} */}
          </Stack>
        )}
      </div>
    );
  };

  const onEventRendered = (args) => {
    // const resource = trainerData.find((item) => item.trainerId === args.trainerId);
    if (args.data.isBlock) {
      if (view === 'day') {
        args.element.className = 'e-appointment';
        args.element.style.width = '100%';
        args.element.style.backgroundColor = '#5CA4BA';
      } else {
        args.element.className = 'e-appointment';
        args.element.style.width = '100%';
        args.element.style.backgroundColor = 'transparent';
        args.element.style.border = 'none';
        args.element.style.display = 'none';
      }
    }

    // set holiday and timeoff color
    if (args?.data?.IsBlock) {
      args.element.className = 'e-appointment';
      args.element.style.width = '100%';
      if (args?.data?.Timeoff) {
        args.element.style.backgroundColor = '#F5F5F5';
        if (view === 'week') {
          args.element.className = 'e-appointment';
          args.element.style.width = '100%';
          args.element.style.backgroundColor = 'transparent';
          args.element.style.border = 'none';
          args.element.style.display = 'none';
        }
      } else {
        args.element.style.backgroundColor = '#F5F5F5';
      }
    }
    // in this we can also apply custom styling to events whole template
  };

  // navigate to staff managment
  const navigateToAddStaff = () => {
    navigate('/staff-management');
  };

  return (
    <div className="schedule-control-section">
      <div className="col-lg-12 control-section">
        {trainerData.length > 0 ? (
          <div className="control-wrapper">
            <ScheduleComponent
              allowSwiping={false}
              showTimeIndicator
              ref={(schedule) => (scheduleObj = schedule)}
              cssClass={"schedule-cell-dimension"}
              width="100%"
              height="750px"
              showHeaderBar={false}
              selectedDate={compareDate}
              currentView={view === 'day' ? 'Day' : 'Week'}
              readOnly
              eventRendered={onEventRendered}
              quickInfoTemplates={{
                content: contentTemplate,
                header: () => {
                  return null;
                }
              }}
              resourceHeaderTemplate={resourceHeaderTemplate}
              eventSettings={{
                dataSource: filterApointmentdata,
                template: EventTemplate
              }}
              popupOpen={onPopupOpen}
              renderCell={onRenderCell}
              startHour={startHour1 ?? '00:00'}
              endHour={endHour1 ?? '24:00'}
              firstDayOfWeek={1}
              workWeek={[1, 2, 3, 4, 5, 6, 0]}
            // enablePersistence
            >
              <ResourcesDirective>
                <ResourceDirective
                  field="trainerId"
                  title="Trainers"
                  name="Trainer"
                  dataSource={trainerData}
                  textField="trainerName"
                  idField="trainerId"
                  allowMultiple
                  colorField="color"
                  cssClassField="eventClass"
                  startHourField="startHour"
                  endHourField="endHour"
                />
              </ResourcesDirective>
              <ViewsDirective>
                {view === 'day' && (
                  <ViewDirective
                    option="Day" allowVirtualScrolling={false}
                    group={{ resources: ['Trainer'], enableCompactView: false }}
                  />
                )}
                <ViewDirective option="Week" />
              </ViewsDirective>
              <Inject services={[Day, Week]} />
            </ScheduleComponent>
          </div>
        ) : (
          <Box mt={5} align={'center'}>
            <Typography variant="h1" className='schedule-header' mb={5}>
              You first need to add a trainer staff to schedule a class or appointment.
            </Typography>
            <Button
              variant="contained"
              className="top-button-style"
              sx={{ padding: "15px" }}
              onClick={navigateToAddStaff}
              mt={5}
            > Add Trainer </Button>
          </Box>
        )}
      </div>
    </div>
  );
};
