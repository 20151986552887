/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { getLocalData } from '../../../utils';
import {
  Box,
  Hidden,
  Stack,
  Typography,
  CircularProgress,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { CustomButton, SearchInput } from 'src/components/Global';
// import {tableData} from './cohorts'
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../components/Loader';
import { ApiService } from '../../../services/ApiService';
// import { Summary } from './Summary';
import GlobalData from 'src/global';
import Header from 'src/components/Header';
import {CohortsDetail} from './CohortsDetail';
import {CreateChotorts} from './CreateCohorts';
import  CohortsFilter  from './CohortsFilter';
import { CohortsTable } from './CohortsTable';
import {EditCohorts} from './EditCohorts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Campaign = () => {
  const franchiseeId = getLocalData('franchiseeId');
  const title = GlobalData[0].title;
  // const BASE_URL = 'https://vtqf4ke0yj.execute-api.us-east-1.amazonaws.com/dev';
  const userId = sessionStorage.getItem('UserId');
  const [loading, setLoading] = useState(true);
  const [showScreen, setShowScreen] = useState(0);
  const [isInitialState, setIsInitialState] = useState(false);
  // const [servicesData, setServicesData] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allPreReq, setAllPreReq] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [serviceDetails, setServiceDetails] = useState();
  const [isServiceEdit, setIsServiceEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rowData, setRowData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // check updated or saved
  const [getFranchisee, setFranchisee] = useState('');

  const [filters, setFilters] = useState({
    // isActive: null,
    status: null,
    createdBy: null,
    createdDate: null,
  });
  // setServicesData(tableData)
  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);


  // const handleOpenScreen = (value) => {
  //   setShowScreen(value);
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // };
  const getFranchiseeName = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}franchisees/getFranchiseeDetail?franchiseeId=${encodedFranchiseeId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
          setFranchisee(Item.franchiseeName)
     
    } catch (error) {
    
      console.log(error);
    }
  };
  console.log(getFranchisee,'Item');
  useEffect(() => {
    getFranchiseeName();
    
  }, []);
  const handleResetStates = () => {
    setIsInitialState(true);
    setShowScreen(1);
    setIsServiceEdit(false);
    setServiceDetails();
    setSelectedRows([]);
    setSelectedRowsData([]);
    setSelectedStatus('');

  };

  function findObjectById(id) {
    return rowData?.find((obj) => obj.sortKey === id);
  }


  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  //   ===============call api here=============
  const getCohorts = async () => {
    setIsLoading(true);
    try{
    let encodedFranchiseeId = encodeURIComponent(franchiseeId)
    console.log(encodedFranchiseeId,'encodedFranchiseeId')
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

      const response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllFranchiseeCohorts?${queryString}&franchiseeId=${encodedFranchiseeId}`
      )
     
        if (response.statusCode && !response.error) {
          // Set the list of location
          setIsLoading(false);
          setRowData(response.data.Items);
          // Set the list of location count
          // setdataCount(data.data.TotalCount);
          // // Now hide the loader
          // setIsLoading(false);
          // let elm = document.getElementsByClassName('page-header-text')[0];
          // if (elm) elm.innerHTML = 'Location Management';
        }else{
          setShowErrorMessage(true);
      setErrorMessage('Something went wrong, try later.');
          setIsLoading(false);
        }
      
      
    }catch(err){
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, try later.');
    }
  };
  
  // setFranchiseeId(locationList.partitionKey);
  // gets call the method to get the data
console.log(rowData,'response');
console.log(selectedRow, 'rowDatares');
  useEffect(() => {
    // Not call the get location method
    getCohorts();
  }, [JSON.stringify(filters)]);


  // const getAllBusinessEntities = async () => {
  //   let encodedLocationId = encodeURIComponent(locationId);
  //   setLoading(true);
  //   setTableLoading(true);
  //   let queryString = filtersQuery();

  //   try {
  //     const [servicesResponse] = await Promise.all([
  //       axios.get(
  //         `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntities?locationId=${encodedLocationId}&${queryString}`
  //       )
  //     ]);

  //     if (!servicesResponse?.data?.data?.error) {
  //       const modifiedData = servicesResponse?.data?.data?.Items?.map(
  //         (item) => ({
  //           ...item,
  //           id: item.serviceId
  //         })
  //       );
  //       setServicesData(modifiedData);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //       setShowErrorMessage(true);
  //       setErrorMessage(
  //         servicesResponse.message || 'Something went wrong with Services'
  //       );
  //     }
  //     setLoading(false);
  //     setTableLoading(false);
  //   } catch (error) {
  //     console.warn('API Error', error);
  //     setLoading(false);
  //     setTableLoading(false);
  //     setShowErrorMessage(true);
  //     setErrorMessage('Internal Server Error');
  //   }
  // };

  

  // useEffect(() => {
  //   // fetchData();
  //   servicesData();
  // }, [JSON.stringify(filters)]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  function getServiceIds() {
    let array = [];
    selectedRowsData?.map((item) => array.push(item.sortKey));

    return array;
  }

  // const handleButtonClick = () => {
  //   handleOpenScreen(2);
  // };
  // const buttons = [
  //   {
  //     label: 'New Campaign',
  //     onClick: handleButtonClick,
  //     theam: true
  //   }
  // ];

  console.log(serviceDetails, 'servicedetails');
  return (
    <>
      <Helmet>
        <title>Cohorts | {title}</title>
      </Helmet>
      {/* <Loader IsLoading={loading} /> */}
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          getDetails={rowData}
        />
      )}

      <Container maxWidth="100%" className="custom-container">
      {(() => {
                switch (showScreen) {
                    case 1:
                        return (
                            <Box justifyContent="center" direction="column">
                                {/* AddCampaign component */}
                                <CohortsDetail
                                setShowScreen={setShowScreen}
                                selectedRow = {selectedRow}
                                rowData = {rowData}
                                getCohorts={getCohorts}
                                />

                            </Box>
                        );
                        case 2:
                          return (
                              <Box justifyContent="center" direction="column">
                                  {/* AddCampaign component */}
                                  <CreateChotorts
                                  setShowScreen={setShowScreen}
                                  selectedRow = {selectedRow}
                                  getFranchisee={getFranchisee}
                                  getCohorts={getCohorts}
                                  />
  
                              </Box>
                          );
                          case 3:
                          return (
                              <Box justifyContent="center" direction="column">
                                  {/* AddCampaign component */}
                                  <EditCohorts
                                  setShowScreen={setShowScreen}
                                  selectedRow = {selectedRow}
                                  getFranchisee={getFranchisee}
                                  getCohorts={getCohorts}
                                  />
                              </Box>
                          );
                    default:
                        return (
                <Box justifyContent="center" direction="column">
                  {/* <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    gap="16px"
                  >
                    <Grid item>
                      <CustomButton
                        text="Add New"
                        variant="contained"
                        onClick={() => handleOpenScreen(2)}
                      >
                        Add New
                      </CustomButton>
                    </Grid> */}
                  {/* <Grid item>
                      <CustomButton variant="outlined">Manage</CustomButton>
                    </Grid> */}
                  {/* </Grid> */}
                  <Header title="Cohorts" />
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid item width={'100%'}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          <Grid item marginY="20px">
                            <CustomButton
                              onClick={() => setIsFilters(!isFilters)}
                              variant={isFilters ? 'contained' : 'outlined'}
                            >
                              Filters
                              <KeyboardArrowDownRoundedIcon
                                className={clsx(
                                  !isFilters && classes.closeY,
                                  isFilters && classes.openY
                                )}
                              />
                            </CustomButton>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            marginY={{ sm: '20px' }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <SearchInput
                              value={searchQuery}
                              setSearchQuery={setSearchQuery}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                    <>
                      {isFilters ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <CohortsFilter
                            filters={filters}
                            setFilters={setFilters}
                            tableData={rowData}
                            getFranchisee={getFranchisee}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        <CohortsTable
                        setIsLoading={setIsLoading}
                        setRowData={setRowData}
                        isLoading={isLoading}
                        rowData={rowData}
                        setSelectedRow={setSelectedRow}
                        selectedRow={selectedRow}
                          tableRows={rowData}
                          searchQuery={searchQuery}
                          setShowScreen={setShowScreen}
                          loading={tableLoading}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          selectedStatus={selectedStatus}
                          setSelectedStatus={setSelectedStatus}
                          btnLoading={btnLoading}
                        />
                      </Grid>
                    </>
                    {/* )} */}
                  </Grid>
                </Box>
           );
          }
        })()}
      </Container>
    </>
  );
};

export default Campaign;