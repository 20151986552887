import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  // useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer
} from '@mui/material';
import moment from 'moment';

export const UpcomingScheduleTable = (props) => {
  const {
    tableRows,
    searchQuery
  } = props;
  // const theme = useTheme();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  
  useEffect(() => {
    setPage(0);
  }, [tableRows]);
  
  const dateFormate = (date) => {
    // let utc = moment(date).format('MMMM Do YYYY, h:mm:ss A');
    let utc;
    // utc = (date) ? moment(date).format('MM-DD-YYYY, h:mm A') : '';
    utc = (date) ? moment(date).format('MM/DD/YYYY, h:mm A') : '';
    let utc2 = utc && convertToUTC(utc);
    return utc2;
    // let dateFor = date && new Date(date);
    // // let utc = dateFor.toUTCString();
    // let finaldate = dateFor.toLocaleString();
    // return finaldate;
  }
  function convertToUTC(inputDate) {
    // Split the input date and time
    const [dateString, timeString] = inputDate.split(',');
    // Parse the date string into parts (month, day, year)
    const [, month, day, year] = dateString.match(/(\d+)\/(\d+)\/(\d+)/);
    // Parse the time string to get the hours and minutes
    // const [, hours, minutes, ampm] = timeString.match(/(\d+):(\d+)\s+(AM|PM)/);
    const [, hours, minutes, ampm] = timeString ? timeString.toUpperCase().match(/(\d+):(\d+)\s+(AM|PM)/) : '00:00';
    // Create a Date object for the given date and time in UTC
    const utcDate = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes)));
    // Adjust the UTC date if the time is in PM (and not 12 PM)
    if (ampm === 'PM' && hours !== '12') {
        utcDate.setUTCHours(utcDate.getUTCHours() + 12);
    } else if (ampm === 'AM' && hours === '12') {
        // If it's 12 AM, subtract one day (24 hours) to change the date
        utcDate.setUTCDate(utcDate.getUTCDate() - 1);
    }
    // Get the user's current timezone dynamically
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Convert the UTC date to the user's timezone
    // const options = { timeZone: userTimeZone, year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    const options = { timeZone: userTimeZone, year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedDate = utcDate.toLocaleString('en-US', options);
    return formattedDate;
  }

  return (
    <div>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        {/* <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="table-heade header-blue-20">
              {' '}
              All Alerts
            </Typography>
          </Grid>
        </Grid> */}
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Date and Time</TableCell>
                <TableCell>Schedule Name</TableCell>
                <TableCell>Dog</TableCell>
                <TableCell>Trainer</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData && paginatedData.length > 0 && paginatedData.map((row) => {
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    key={row.sortKey}
                    role="checkbox"
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    // selected={isItemSelected}
                    className="table-row cursor-pointer"
                   // onClick={(e) => openDetail(row.sortKey, 3, e)}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId
                        }}
                        sx={{ color: '#003087' }}
                        onClick={(event) => handleClick(event, row.sortKey)}
                      />
                    </TableCell> */}
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        // sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row?.fromDate && dateFormate(row?.fromDate)}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.serviceName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.clientDetails && row?.clientDetails?.length > 0 && row?.clientDetails[0]?.clientChildName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.trainerName}
                      </Typography>
                    </TableCell>
                                        
                    {/* <TableCell className="table-td">
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        className="edit-btn"
                        id={'edit_btn_' + row.sortKey}
                        onClick={(e) => openDetail(row.sortKey, 2, e)}
                      >
                        Edit
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              }) || (
                <TableRow>
                  <TableCell colSpan={6} className="table-td">
                    <Typography

                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ marginTop: 1 }}
                      gutterBottom
                      noWrap
                    >
                      No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
