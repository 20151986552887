/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { forwardRef, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Slide,
  Typography,
  Button,
  Box,
  Tabs,
  Tab,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Avatar,
  FormHelperText,
  Card,
  Select,
  MenuItem
} from '@mui/material';
import {
  CustomTextField,
  CustomSelect,
  CustomButton,
  Toggle
} from 'src/components/Global';
import { CustomDatePicker } from 'src/components/Trophy/CustomDatePicker';
import dayjs from 'dayjs';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { getLocalData } from 'src/utils'; // setFullLocationId
import { PictureSnapshot } from 'src/assets/SVG/SvgIcons';
import { ApiService } from '../../../services/ApiService';
import Loader from '../../../components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import heic2any from 'heic2any';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

let valueArr = [];
export const AddTrophy = (props) => {
  const { isServiceEdit, setIsServiceEdit, trophyDetails, handleResetStates } =
    props;

  const addRef = useRef(null);
  const userId = sessionStorage.getItem('UserId');
  // const locationId = setFullLocationId(getLocalData('locationId'));
  const locationId = getLocalData('locationId');
  const franchisorId = getLocalData('franchiseeId');
  const [isImageUpload, setIsImageUpload] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [serviceType, setServiceType] = useState('enrollment');
  const [pricingLoading, setPricingLoading] = useState(true);
  const [allPricingOptions, setAllPricingOptions] = useState([]);
  const [trophyId, setTrophyId] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [preValues, setPreValues] = useState({});
  const [emailError, setEmailError] = useState('');
  const [initialValue, setInitialValue] = useState({
    title: '',
    description: '',
    juniorTrophy: false,
    staffAssigned: false,
    displayOnShelf: true,
    status: '',
    sortKey: ''
  });
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setIsError] = useState({
    title: false,
    trophyImage: false
  });
  const [selectedPricingOptions, setSelectedPricingOptions] = useState({});
  const [attributeData, setAttributeData] = useState([]);
  const [operatorData, setOperatorData] = useState([]);
  const [imgObjClient, setimgObjClient] = useState([]);
  const [imageSelected, setImageSelected] = useState(false);
  const [imgUrl, setimgUrl] = useState('');
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [newCriteriaErrors, setNewCriteriaErrors] = useState({
    attribute: false,
    operator: false,
    values: false,
    dateError: false,
    endDateError: false
  });

  const [imageErrorMsg, setImageErrorMsg] = useState('');
  const [pricingDialog, setPricingDialog] = useState(false);
  console.log('image selected', imgObjClient, imageSelected, imgUrl);
  const [criteriaCount, setCriteriaCount] = useState(1);
  const [inputValues, setInputValues] = useState([
    { attribute: '', operator: '', values: [] }
  ]);
  const [selectedOperators, setSelectedOperators] = useState([]);
  console.log(inputValues, 'trophyDetailssssss');
  console.log(trophyDetails, 'trophyDetails');
  const [dialog, setDialog] = useState({
    state: false,
    isCreated: false
  });
  // const [selectedStatus, setSelectedStatus] = useState('');

  const onInputClick = (event) => {
    event.target.value = '';
  };

  const addCreteriaOpen = () => {
    setInputValues([
      ...inputValues,
      { attribute: '', operator: '', values: [] }
    ]);
    // setNewCriteriaErrors({ attribute: false, operator: false, values: false, dateError: false, endDateError: false });
    // Clear the selected attribute
    // setAttributeName('');
    setCriteriaCount(criteriaCount + 1);
  };

  console.log(inputValues, 'trophyDetailstrophyDetails');
  useEffect(() => {
    if (isServiceEdit && trophyDetails) {
      if (trophyDetails.Segments) {
        setInputValues(trophyDetails?.Segments[0]?.DimensionsAttributes);
        setCriteriaCount(
          trophyDetails?.Segments[0]?.DimensionsAttributes?.length
        );
        const criteriaData = trophyDetails?.Segments[0]?.DimensionsAttributes;
        if (criteriaData && criteriaData.length > 0) {
          const extractedOperators = criteriaData.map(
            (criteria) => criteria.operator
          );
          setSelectedOperators(extractedOperators);
        }
      }

      // setServiceType(trophyDetails?.typeOfService && trophyDetails?.typeOfService.toLowerCase());
      let data = {
        sortKey: trophyDetails?.sortKey,
        title: trophyDetails?.title,
        description: trophyDetails?.description,
        juniorTrophy: trophyDetails?.juniorTrophy,
        staffAssigned: trophyDetails?.staffAssigned,
        displayOnShelf: trophyDetails?.displayOnShelf,
        trophyImage: trophyDetails?.trophyImage,
        status: trophyDetails?.status
      };

      // if trophy image available then set the imageError to be false else true
      if (trophyDetails?.trophyImage) {
        setImageError(false);
        setImageSelected(true);
        setimgUrl(trophyDetails?.trophyImage);
      } else {
        setImageError(true);
        setImageErrorMsg('Image is required');
      }
      // setIsFranchisor(trophyDetails?.isFranchisor);

      // TODO: To enable the toggle button if value is true
      // if staff assigned is truen then set the staff assigned to be true
      if (trophyDetails?.staffAssigned) {
        let elm = document.querySelector('.staff-assigned');
        if (elm) elm.click();
      }
      // if juniour trophy is truen then set the staff assigned to be true
      if (trophyDetails?.juniorTrophy) {
        let elm = document.querySelector('.junior-trophy');
        if (elm) elm.click();
      }
      // if display on self is truen then set the staff assigned to be true
      if (!trophyDetails?.displayOnShelf) {
        let elm = document.querySelector('.display-on-shelf');
        if (elm) elm.click();
      }
      setInitialValue(data);
      setPreValues(data);
    }
  }, []);
  const handleInputChange1 = (e, i) => {
    // setFieldUpdated(true);
    // setNewCriteriaErrors((prevErrors) => {
    //   const updatedErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
    //   updatedErrors[i] = { attribute: false, operator: false, values: false, dateError: false, endDateError: false};
    //   return updatedErrors;
    // });
    let updatedErrors = { ...newCriteriaErrors[i] }; // Copy the current error state

    if (e.target.name === 'attribute') {
      updatedErrors.attribute = false;
    }
    if (e.target.name === 'operator' && e.target.value !== '') {
      updatedErrors.operator = false;
    }
    if (e.target.name === 'values') {
      updatedErrors.values = false;
    }
    // Update the error state for the current field
    setNewCriteriaErrors((prevErrors) => {
      const newErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
      newErrors[i] = updatedErrors;
      return newErrors;
    });
    let newForm = [...inputValues];
    if (e.target.name === 'operator') {
      newForm[i]['values'] = [];
      const updatedSelectedOperators = [...selectedOperators];
      updatedSelectedOperators[i] = e.target.value;
      setSelectedOperators(updatedSelectedOperators);
    }
    if (e.target.name === 'values') {
      const valuesArray = e.target.value
        .split(',')
        .map((value) => value.trim()); // Split and trim values
      newForm[i][e.target.name] = valuesArray;
    } else {
      newForm[i][e.target.name] = e.target.value;
    }
    // newForm[i][e.target.name] = e.target.value;
    setInputValues(newForm);
  };
  // const handleInputChange2 = (e, i) => {

  //   let newForm = [...inputValues];

  //   // Check if the values array exists, and if not, create it
  //   if (!newForm[i]['values']) {
  //     newForm[i]['values'] = [];
  //   }

  //   if (selectedOperators[i] === 'BETWEEN') {
  //     const formattedCurrentDateTime = dayjs(e, {
  //       format: 'MM-DD-YYYY'
  //     }).format('YYYY-MM-DDTHH:mm:ss[Z]');
  //     console.log(formattedCurrentDateTime, 'formattedCurrentDateTime');
  //     newForm[i]['values'].push(formattedCurrentDateTime);
  //   } else {
  //     const formattedCurrentDateTime = dayjs(e, {
  //       format: 'MM-DD-YYYY'
  //     }).format('YYYY-MM-DDTHH:mm:ss[Z]');
  //     newForm[i]['values'] = [formattedCurrentDateTime];
  //   }

  //   setInputValues(newForm);
  // };
  // const handleServiceInfo = (type, value) => {
  //   setFieldUpdated(true);
  //   setInitialValue({ ...initialValue, [type]: value });
  //   setIsError({ ...isError, [type]: false });
  // };
  const handleInputChange2 = (dateValue, i) => {
    let newForm = [...inputValues];
    let valueArr = newForm[i]['values'];

    // Assuming that dateValue is a valid date string
    const formattedCurrentDateTime = dayjs(dateValue, {
      format: 'MM-DD-YYYY'
    }).format('YYYY-MM-DDTHH:mm:ss[Z]');

    if (dateValue !== '') {
      // When dateValue is not empty, remove the values error
      let updatedErrors = { ...newCriteriaErrors[i] };
      updatedErrors.values = false;

      setNewCriteriaErrors((prevErrors) => {
        const newErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
        newErrors[i] = updatedErrors;
        return newErrors;
      });
    }

    if (valueArr[1] && formattedCurrentDateTime < valueArr[1]) {
      // When the start date is less than the end date, remove the dateError
      let updatedErrors = { ...newCriteriaErrors[i] };
      updatedErrors.dateError = false;

      setNewCriteriaErrors((prevErrors) => {
        const newErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
        newErrors[i] = updatedErrors;
        return newErrors;
      });
    }

    valueArr[0] = formattedCurrentDateTime;
    newForm[i]['values'] = valueArr;

    setInputValues(newForm);
  };

  console.log(inputValues, 'inputvalues are consoled here');

  const handleInputChange4 = (e, i) => {
    let valueArr = ['', ''];
    // setIsFiendUpdated(true);
    let newForm = [...inputValues];
    valueArr = newForm[i]['values'];
    const formattedCurrentDateTime = dayjs(e, { format: 'MM-DD-YYYY' }).format(
      'YYYY-MM-DDTHH:mm:ss[Z]'
    );
    valueArr[1] = formattedCurrentDateTime;
    newForm[i]['values'] = valueArr;
    if (valueArr[0] && valueArr[1]) {
      newCriteriaErrors[i] = { ...newCriteriaErrors[i], endDateError: false };
    }
    setInputValues(newForm);
  };
  //   useEffect(()=> {
  //     setInputValues()
  //   },[betweenValue])

  const handleInputChange3 = (dateValue, i) => {
    let updatedErrors = { ...newCriteriaErrors[i] }; // Copy the current error state

    // Assuming that dateValue is a valid date string
    if (dateValue !== '') {
      updatedErrors.values = false;
    }

    // Update the error state for the current field
    setNewCriteriaErrors((prevErrors) => {
      const newErrors = Array.isArray(prevErrors) ? [...prevErrors] : [];
      newErrors[i] = updatedErrors;
      return newErrors;
    });
    // setIsFiendUpdated(true);
    let valueArr = ['', ''];
    let newForm = [...inputValues];
    valueArr = newForm[i]['values'];
    const formattedCurrentDateTime = dayjs(dateValue, {
      format: 'MM-DD-YYYY'
    }).format('YYYY-MM-DDTHH:mm:ss[Z]');
    valueArr[0] = formattedCurrentDateTime;
    newForm[i]['values'] = valueArr;
    setInputValues(newForm);
  };
  const handleServiceInfo = (type, value) => {
    setFieldUpdated(true);
    if (type === 'Badges') type = 'juniorTrophy';
    if (type === 'title' || type === 'description') {
      // Update the title with the new value
      setInitialValue((prevInitialValue) => ({
        ...prevInitialValue,
        [type]: value
      }));
    } else if (type === 'duration' && value < 0) {
      // Check for negative values for the 'duration' field
      return; // Exit the function if the value is negative
    } else {
      // For other fields, toggle the value as you were doing before
      setInitialValue((prevInitialValue) => ({
        ...prevInitialValue,
        [type]: !prevInitialValue[type]
      }));
    }

    // Reset the error for the current field
    setIsError({ ...isError, [type]: false });
  };

  console.log('initialValuesd', initialValue);

  const handlePricingSelect = (option) => {
    setInitialValue({
      ...initialValue,
      pricingOptionId: option.sortKey
      // isFree: option.free,
      // servicePrice: option.price,
      // numberOfSessions: option.numberOfSessions
    });
    setSelectedPricingOptions(option);
  };

  const clearStates = () => {
    // setServiceType('enrollment');
    setInitialValue({
      title: '',
      description: '',
      juniorTrophy: false,
      staffAssigned: false,
      displayOnShelf: false,
      trophyImage: ''
    });
  };

  function removeLastEmptyCell() {
    setTimeout(() => {
      const container = document.querySelector('.last-cell-removed');
      if (container) {
        const rows = container.querySelectorAll('.MuiDataGrid-row');
        rows.forEach((row) => {
          const lastCell = row.lastChild;
          if (
            lastCell &&
            !lastCell.classList.contains('MuiDataGrid-cell--withRenderer')
          ) {
            lastCell.remove();
          }
        });
      }
    }, 100);
  }

  const valuesAreDifferent = () => {
    if (!isServiceEdit) {
      return Object.entries(initialValue).some(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0; // Consider non-empty arrays as different
        } else {
          return value !== ''; // Consider non-empty strings as different
        }
      });
    } else {
      const keys = Object.keys(initialValue);
      return keys.some((key) => initialValue[key] !== preValues[key]);
    }
  };

  const handleCloseScreen = () => {
    if (valuesAreDifferent()) {
      setDialog({ ...dialog, state: true });
    } else {
      handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        state: false,
        isCreated: false
      });
      // handleResetStates();
    } else if (value === 'Discard') {
      handleResetStates();
      setDialog({
        state: false,
        isCreated: false
      });
    } else if (value === 'Cancel') {
      handleCloseScreen();
    } else if (value === 'Close') {
      handleResetStates();
    }
  };

  const getNameById = (id, array) => {
    const matchedObject = array.find((obj) => obj.sortKey === id);
    const name = matchedObject ? matchedObject.name : '';
    return name;
  };

  const convertToObjects = (ids) => {
    return ids.map((id) => ({ id }));
  };
  // Function to format a date string
  function formatDate(dateString) {
    return moment(dateString).format('MM-DD-YYYY');
  }
  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    const alwaysCheck = ['title', 'trophyImage'];
    for (const key of alwaysCheck) {
      const value = initialValue[key];
      const isEmpty =
        value === '' || (Array.isArray(value) && value.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }

    setIsError(updatedErrors);
    return hasError;
  }

  console.log(inputValues, 'newCriteriaErrorsnewCriteriaErrors');

  /* const handleFormSubmission = async (e) => {
    e.preventDefault();
    // Check if the form is already being submitted
    if (isSubmitting) {
      return;
    }
    // Check for criteria-related errors
    if (!initialValue.staffAssigned) {
      let newCriteriaErrors = new Array(inputValues.length).fill({
        attribute: false,
        operator: false,
        values: false,
        dateError: false,
        endDateError: false
      });

      let hasAttributeErrors = false;
      let hasOperatorErrors = false;
      let hasValueErrors = false;

      // Loop through each criteria field and check for errors
      for (let i = 0; i < inputValues.length; i++) {
        const criteria = inputValues[i];
        console.log(criteria, 'criteria is consoled ehre');

        // Check if the attribute field is empty
        if (!criteria.attribute) {
          hasAttributeErrors = true;
          newCriteriaErrors[i] = { ...newCriteriaErrors[i], attribute: true };
        }

        // Check if the operator field is empty
        if (!criteria.operator) {
          hasOperatorErrors = true;
          newCriteriaErrors[i] = { ...newCriteriaErrors[i], operator: true };
        }

        if (!criteria.values || criteria.values.length === 0) {
          hasValueErrors = true;
          newCriteriaErrors[i] = { ...newCriteriaErrors[i], values: true };
        }

        // Check if the image is selected
        if (!imageSelected) {
          setImageError(true); // Set imageError to true when no image is selected
          setImageErrorMsg('Image is required');
        }

        if (criteria.operator === 'BETWEEN') {
          if (criteria?.values[1] && criteria?.values[1] < criteria.values[0]) {
            hasValueErrors = true;
            newCriteriaErrors[i] = { ...newCriteriaErrors[i], dateError: true };
          }
          if (criteria?.values[0] && !criteria?.values[1]) {
            hasValueErrors = true;
            newCriteriaErrors[i] = {
              ...newCriteriaErrors[i],
              endDateError: true
            };
          }
        }
      }
      const hasEmptyValueErrors = checkForEmptyValues();

      // Set error states based on the results
      setNewCriteriaErrors(newCriteriaErrors);

      // Check if any of the criteria fields have errors or empty values
      if (
        hasAttributeErrors ||
        hasOperatorErrors ||
        hasValueErrors ||
        hasEmptyValueErrors
      ) {
        console.log('Please fill all the required fields');
        return;
      }
    }

    // Check for empty values

    // Check for title and image errors
    let hasTitleError = false;
    let hasImageError = false;
    let imageErrorMsg = '';

    if (!initialValue.title) {
      hasTitleError = true;
    }

    if (!imageSelected) {
      hasImageError = true;
      imageErrorMsg = 'Image is required';
    }

    // Set title and image errors if needed
    // if (hasTitleError || hasImageError) {
    //   console.log('Please fill in the title and select an image');
    //   setImageError(hasImageError);
    //   setImageErrorMsg(imageErrorMsg);
    // } else if (isServiceEdit) {
    //   handleUpdateTrophy();
    // } else {
    //   CreateTrophy();
    // }
    try {
      // Set title and image errors if needed
      if (hasTitleError || hasImageError) {
        // console.log('Please fill in the title and select an image');
        setImageError(hasImageError);
        setImageErrorMsg(imageErrorMsg);
      } else if (isServiceEdit) {
        await handleUpdateTrophy();
      } else {
        await CreateTrophy();
      }
      // If there are no errors, proceed to call the CreateTrophy API
    } catch (error) {
      console.error('An error occurred while submitting the form:', error);
      setIsSubmitting(false);
    }
    // If there are no errors, proceed to call the CreateTrophy API
  }; */

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    // Check if the form is already being submitted
    // if (isSubmitting) {
    //   return;
    // }

    // setIsSubmitting(true); // Set a flag to indicate that the form is being submitted

    // Check for criteria-related errors
    if (!initialValue.staffAssigned) {
      let newCriteriaErrors = new Array(inputValues.length).fill({
        attribute: false,
        operator: false,
        values: false,
        dateError: false,
        endDateError: false
      });

      let hasAttributeErrors = false;
      let hasOperatorErrors = false;
      let hasValueErrors = false;

      // Loop through each criteria field and check for errors
      for (let i = 0; i < inputValues.length; i++) {
        const criteria = inputValues[i];
        console.log(criteria, 'criteria is consoled ehre');

        // Check if the attribute field is empty
        if (!criteria.attribute) {
          hasAttributeErrors = true;
          newCriteriaErrors[i] = { ...newCriteriaErrors[i], attribute: true };
        }

        // Check if the operator field is empty
        if (!criteria.operator) {
          hasOperatorErrors = true;
          newCriteriaErrors[i] = { ...newCriteriaErrors[i], operator: true };
        }

        if (!criteria.values || criteria.values.length === 0) {
          hasValueErrors = true;
          newCriteriaErrors[i] = { ...newCriteriaErrors[i], values: true };
        }

        // Check if the image is selected
        if (!imageSelected) {
          setImageError(true); // Set imageError to true when no image is selected
          setImageErrorMsg('Image is required');
        }

        if (criteria.operator === 'BETWEEN') {
          if (criteria?.values[1] && criteria?.values[1] < criteria.values[0]) {
            hasValueErrors = true;
            newCriteriaErrors[i] = { ...newCriteriaErrors[i], dateError: true };
          }
          if (criteria?.values[0] && !criteria?.values[1]) {
            hasValueErrors = true;
            newCriteriaErrors[i] = {
              ...newCriteriaErrors[i],
              endDateError: true
            };
          }
        }
      }
      const hasEmptyValueErrors = checkForEmptyValues();

      // Set error states based on the results
      setNewCriteriaErrors(newCriteriaErrors);

      // Check if any of the criteria fields have errors or empty values
      if (
        hasAttributeErrors ||
        hasOperatorErrors ||
        hasValueErrors ||
        hasEmptyValueErrors
      ) {
        console.log('Please fill all the required fields');
        return;
      }
    }

    // Check for empty values

    // Check for title and image errors
    let hasTitleError = false;
    let hasImageError = false;
    let imageErrorMsg = '';

    if (!initialValue.title) {
      hasTitleError = true;
    }

    if (!imageSelected) {
      hasImageError = true;
      imageErrorMsg = 'Image is required';
    }

    try {
      // Set title and image errors if needed
      if (hasTitleError || hasImageError) {
        console.log('Please fill in the title and select an image');
        setImageError(hasImageError);
        setImageErrorMsg(imageErrorMsg);
      } else if (isServiceEdit) {
        setConfirmEdit(true);
      } else if (!imageSelected || imageError) {
        console.log('Please select a valid image');
      } else {
        await CreateTrophy();
      }
      // }
      // If there are no errors, proceed to call the CreateTrophy API
    } catch (error) {
      console.error('An error occurred while submitting the form:', error);
      setIsSubmitting(false);
    }

    /* try {
      // Set title and image errors if needed
      if (hasTitleError || hasImageError) {
        console.log('Please fill in the title and select an image');
        setImageError(hasImageError);
        setImageErrorMsg(imageErrorMsg);
      } else if (isServiceEdit) {
        await handleUpdateTrophy();
      } else {
        await CreateTrophy();
      }
      // If there are no errors, proceed to call the CreateTrophy API
    } catch (error) {
      console.error('An error occurred while submitting the form:', error);
      setIsSubmitting(false);
    } */
  };

  // const closePopup = () => {
  //   if(isServiceEdit){
  //     handleResetStates();
  //     setIsServiceEdit(true);
  //     setFieldUpdated(false);
  //     setConfirmEdit(false);
  //     setDialog({
  //       isCreated: true,
  //       state: true
  //     });
  //   }else{
  //     handleResetStates();

  //     // handleCloseScreen();
  //     setShowSuccessMessage(false);
  //     setConfirmEdit(false);
  //   }

  //  // getCohorts();
  // };

  const handleEditDialog = (value) => {
    if (value === 'Continue') {
      handleUpdateTrophy();
      setConfirmEdit(false);
    } else if (value === 'Discard') {
      setBtnLoading(false);
      setConfirmEdit(false);
    } 
  };

  // const handleAddTrophy = async () => {
  //   // let encodedLocationId = encodeURIComponent(locationId);
  //   // debugger
  //   // create form data and append all the fields
  //   const formData = new FormData();
  //   formData.append('title', initialValue.title);
  //   formData.append('juniorTrophy', initialValue.juniorTrophy);
  //   formData.append('description', initialValue.description);
  //   formData.append('staffAssigned', initialValue.staffAssigned);
  //   formData.append('displayOnShelf', initialValue.displayOnShelf)
  //   formData.append('createdBy', userId);
  //   formData.append('trophyImage', imgObjClient);
  //   formData.append('status', initialValue.status === '' ? 1 : initialValue.status);

  //   // const data = {
  //   //   title: initialValue.title,
  //   //   juniorTrophy: initialValue.juniorTrophy,
  //   //   description: initialValue.description,
  //   //   assignmentOnly: initialValue.assignmentOnly,
  //   //   createdBy: userId,
  //   //   trophyImage: imgObjClient,
  //   //   status: initialValue.status === '' ? 1 : initialValue.status,
  //   // };

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_END_POINT}/trophy`,
  //       formData
  //     );

  //     if (!response.data?.error) {
  //       // toast.success(response.data?.message);
  //       console.log(response.data?.message);
  //       console.log(response, 'hhhshshhshsh');
  //       // setInitialValue({
  //       //   ...initialValue,
  //       //   serviceId: encodeURIComponent(response.data?.data?.serviceId)
  //       // });

  //       setIsServiceEdit(false);
  //       setFieldUpdated(false);
  //       setConfirmEdit(false);
  //       setDialog({
  //         isCreated: true,
  //         state: true
  //       });
  //       handleResetStates();
  //       // setSelectedTab(2);
  //       // handleCloseScreen();
  //     } else {
  //       // toast.error(response.data?.message || 'Something went wrong with add Service');
  //       console.log(
  //         response.data?.message || 'Something went wrong with add Service'
  //       );
  //     }

  //     setBtnLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     console.error(error);
  //     setBtnLoading(false);
  //     // toast.error('Something went wrong, Try again!');
  //     console.log('Something went wrong, Try again!');
  //   }
  // };
  const CreateTrophy = async () => {
    setIsLoading(true);
    const data = {
      title: initialValue.title,
      juniorTrophy: initialValue.juniorTrophy,
      description: initialValue.description,
      staffAssigned: initialValue.staffAssigned,
      displayOnShelf: initialValue.displayOnShelf,
      createdBy: 'Name'
    };

    const valuesArray = inputValues.map((item) => ({
      ...item,
      values: Array.isArray(item.values)
        ? item.values // Use the array as is
        : item.values.split(',').map((value) => value.trim()) // Split and trim only if it's a string
    }));

    if (!initialValue.staffAssigned) {
      data.logicalOperator = 'AND';
      data.Segments = [{ DimensionsAttributes: valuesArray }];
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}trophy/cohort/createCohortTrophy`,
        data
      );

      if (!response.data?.error && response.data.data.sortKey) {
        setIsLoading(false);

        console.log(response.data.sortkey, 'responseresponse');
        if (imgObjClient) {
          const formData = new FormData();
          formData.append('franchisorId', response.data.data.partitionKey);
          formData.append('trophyId', response.data.data.sortKey);
          formData.append('trophyImage', imgObjClient);

          await Promise.all([
            addTrophyImage(formData)
            // Add the second API call here if needed
          ]);
        }
        // setShowSuccessMessage(true);
        // setSuccessMessage('Trophy created successfully');
      } else {
        setIsLoading(false);
        setShowMessage(true);
        setErrorMessage(
          response.data?.message || 'Something went wrong, try later.'
        );
        console.log(
          response.data?.message || 'Something went wrong with add Service'
        );
      }
      setBtnLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later.');
      setBtnLoading(false);
      console.error(error);
      console.log('Something went wrong, Try again!');
    }
  };

  const addTrophyImage = async (formData) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}trophy/cohort/addTrophyImage`,
        formData
      );
      if (!response.data?.error) {
        setIsLoading(false);

        setIsServiceEdit(false);
        setFieldUpdated(false);
        setIsImageUpload(false);
        setConfirmEdit(false);
        setDialog({
          isCreated: true,
          state: true
        });
        // setShowSuccessMessage(true);
        // setSuccessMessage('Trophy created successfully');
        // handleResetStates();
        // return true;
      } else {
        setIsLoading(false);

        // toast.error(response.data?.message || 'Something went wrong with add Service');
        console.log(
          response.data?.message || 'Something went wrong with add Service'
        );
      }

      setBtnLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      console.error(error);
      setBtnLoading(false);
      // toast.error('Something went wrong, Try again!');
      console.log('Something went wrong, Try again!');
    }
  };
  console.log(trophyDetails, 'initialValueinitialValue');
  const handleUpdateTrophy = async () => {
    // const encodedLocationId = encodeURIComponent(locationId);=
    setIsLoading(true);
    const data = {
      title: initialValue.title,
      juniorTrophy: initialValue.juniorTrophy,
      franchisorId: trophyDetails.partitionKey,
      description: initialValue.description,
      staffAssigned: initialValue.staffAssigned,
      displayOnShelf: initialValue.displayOnShelf,
      updatedBy: 'name',
      trophyId: initialValue.sortKey,
      Id: trophyDetails.Id
    };
    if (!initialValue.staffAssigned) {
      data.logicalOperator = 'AND';
      data.Segments = [{ DimensionsAttributes: inputValues }];
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}trophy/cohort/updateCohortTrophy`,
        data
      );
      if (!response.data?.error) {
        setIsLoading(false);
        if (isImageUpload && imgObjClient) {
          console.log('Calling addTrophyImage');
          const formData = new FormData();
          formData.append(
            'franchisorId',
            response.data.data.Attributes.partitionKey
          );
          formData.append('trophyId', response.data.data.Attributes.sortKey);
          formData.append('trophyImage', imgObjClient);

          await Promise.all([
            addTrophyImage(formData)
            // Add the second API call here if needed
          ]);
        }

        //handleResetStates();
        setIsServiceEdit(true);
        setFieldUpdated(false);
        setConfirmEdit(false);
        setDialog({
          isCreated: true,
          state: true
        });
        // handleCloseScreen();
      } else {
        // toast.error( response.data?.message || 'Something went wrong with update Service');
        console.log(
          response.data?.message || 'Something went wrong with update Service'
        );
      }

      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      // toast.error('Something went wrong, Try again!');
      console.log('Something went wrong, Try again!');
    }
  };

  // const handleUploadTrophyImage = async (e) => {
  //   const selectedImage = e.target.files[0];
  //   setIsImageUpload(true);

  //   if (selectedImage) {
  //     const allowedFormats = [
  //       'image/jpeg',
  //       'image/jpg',
  //       'image/png',
  //       'image/heic'
  //     ];
  //     const fileExtension = selectedImage.name.split('.').pop().toLowerCase();

  //     if (!allowedFormats.includes(`image/${fileExtension}`)) {
  //       // Display an error message for invalid format
  //       setImageSelected(false);
  //       setImageError(true);
  //       setImageErrorMsg(
  //         'Invalid file format. Only jpg, jpeg, png, and heic files are allowed.'
  //       );
  //     } else {
  //       setImageSelected(true);
  //       setImageErrorMsg('');
  //       setImageError(false);

  //       if (fileExtension === 'heic') {
  //         // Convert HEIC to JPEG
  //         const jpegData = await heic2any({ blob: selectedImage });
  //         const jpegBlob = new Blob([jpegData], { type: 'image/jpeg' });
  //         const jpegFile = new File(
  //           [jpegBlob],
  //           selectedImage.name.replace(/\.heic$/, '.jpg')
  //         );
  //         setimgObjClient(jpegFile);
  //         setimgUrl(URL.createObjectURL(jpegFile));
  //       } else {
  //         setimgObjClient(selectedImage);
  //         setimgUrl(URL.createObjectURL(selectedImage));
  //       }

  //       setIsFieldUpdated(true);
  //       setFieldUpdated(true);
  //     }
  //   } else {
  //     // Display an error message for no image selected
  //     setImageSelected(false);
  //     setImageError(true);
  //     setImageErrorMsg('Image is required');
  //   }
  // };

  const handleUploadTrophyImage = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();
      setIsImageUpload(true);

      console.log(file, 'filefile');

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg')
      ) {
        setimgUrl(URL.createObjectURL(file));
        setImageSelected(true);
        setimgObjClient(file);
        setImageError(false);
      } else if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({ blob: file });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }

          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });

          setimgUrl(URL.createObjectURL(jpegFile));
          setImageSelected(true);
          setimgObjClient(jpegFile);
          setImageError(false);
        } catch (error) {
          setImageSelected(false);
          console.error('Error handling HEIC file:', error);
          setImageError(true);
          setimgUrl('');
          setimgObjClient([]);
          setImageErrorMsg('Error handling HEIC file.');
        }
      } else {
        setImageSelected(false);
        setImageError(true);
        setimgUrl('');
        setimgObjClient([]);
        setImageErrorMsg(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
  };

  /* const handleUploadTrophyImage = (e) => {
    const selectedImage = e.target.files[0];
    setIsImageUpload(true);
    if (selectedImage) {
      setImageSelected(true);
      setImageErrorMsg('');
      setImageError(false);
      setimgObjClient(selectedImage);
      setimgUrl(URL.createObjectURL(selectedImage));
      setIsFieldUpdated(true);
      setFieldUpdated(true); // No error when an image is selected
    } else {
      setImageSelected(false);
      setImageError(true); // Set imageError to true when no image is selected
      setImageErrorMsg('Image is required');
    }
  }; */

  // Cross icon handler
  const removeImage = () => {
    // const updatedImages = newImages.filter((image) => image.id !== idToRemove);
    // setNewImages(updatedImages);
    setImageSelected(false);
    setimgUrl('');
    setimgObjClient([]);
    setImageError(true);
    setImageErrorMsg('Image is required');
  };
  const getAllAttribute = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAllCriteria`
      );

      if (response.statusCode && !response.error) {
        setAttributeData(response?.data?.Items);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllOperator = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAllOperators`
      );

      if (response.statusCode && !response.error) {
        setOperatorData(response?.data?.Items);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllOperator();
    getAllAttribute();
  }, []);
  useEffect(() => {
    console.log('ImageError', imageError);
  }, [imageError, initialValue.title]);
  console.log('staffAssignedstaffAssigned', initialValue);
  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {/* {showSucessMessage &&(
   <SuccessMessage message={sucessMessage} setShowMessage={setSuccessMessage} closePopup={closePopup} />
        )} */}
      <div className="service-container">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={1}
          mb={4}
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleCloseScreen()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {isServiceEdit ? 'Edit Trophy' : 'Create a Trophy'}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              {/* <CustomButton
              text="Save"
              variant="contained"
              loading={btnLoading['update_service']}
              onClick={() =>
                document.getElementById('service-save').click()
              }
            >
              Save
            </CustomButton>
            <button hidden type="submit" id="service-save">
              save
            </button> */}
              <CustomButton
                variant="contained"
                type="submit"
                onClick={handleFormSubmission}
                className="common-button"
                loading={btnLoading}
              >
                <Typography>{isServiceEdit ? 'Update' : 'Save'}</Typography>
              </CustomButton>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{ width: '100%' }}
          paddingBottom="20px"
          className="Main-container common-form-container"
        >
          <form className="custom-form" onSubmit={handleFormSubmission}>
            <div>
              <Grid container spacing={2} columns={12} mt={5}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    type="text"
                    name="title"
                    label="TITLE"
                    fullWidth
                    // error={isError.name}
                    required={Boolean(true)}
                    helperText={isError.title && 'Title is required'}
                    value={initialValue.title}
                    onChange={handleServiceInfo}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={4} paddingRight="50px">
                  <Toggle
                    fullWidth
                    spaceBetween
                    name="Badges"
                    label="Badges"
                    labelPlacement="start"
                    value={Boolean(initialValue.juniorTrophy)}
                    onChange={handleServiceInfo}
                    className="junior-trophy"
                  />
                </Grid>
                <Grid item xs={12} sm={4} paddingRight="50px">
                  <Toggle
                    fullWidth
                    spaceBetween
                    name="staffAssigned"
                    label="Staff Assigned"
                    labelPlacement="start"
                    value={initialValue.staffAssigned}
                    onChange={handleServiceInfo}
                    className="staff-assigned"
                  />
                </Grid>
                <Grid item xs={12} sm={4} paddingRight="50px">
                  <Toggle
                    fullWidth
                    spaceBetween
                    name="displayOnShelf"
                    label="Display On The Shelf"
                    labelPlacement="start"
                    value={initialValue.displayOnShelf}
                    onChange={handleServiceInfo}
                    className="display-on-shelf"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="DESCRIPTION"
                    multiline
                    rows="6"
                    type="text"
                    name="description"
                    value={initialValue.description}
                    onChange={handleServiceInfo}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={10} md={10}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    className={`${imageSelected ? null : 'mt-10'}`}
                  >
                    {imageSelected ? (
                      <Box className="relative">
                        <Avatar
                          className="image-avatar"
                          src={imgUrl !== '' ? imgUrl : null}
                          sx={{
                            marginTop: '40px',
                            height: '165px',
                            width: '165px',
                            borderRadius: 'none',
                            marginRight: '30px'
                          }}
                        />
                        <div
                          className="cross-imageicon text-blue"
                          role="button"
                          onClick={() => removeImage()}
                          onKeyDown={() => removeImage()}
                          tabIndex={0}
                        >
                          x
                        </div>
                      </Box>
                    ) : (
                      // <svg
                      //   width="100"
                      //   height="101"
                      //   viewBox="0 0 100 101"
                      //   fill="none"
                      //   xmlns="http://www.w3.org/2000/svg"
                      // >
                      //   <mask
                      //     id="mask0_896_42450"
                      //     style={{ maskType: 'alpha' }}
                      //     maskUnits="userSpaceOnUse"
                      //     x="0"
                      //     y="0"
                      //     width="100"
                      //     height="101"
                      //   >
                      //     <circle cx="50" cy="50.7671" r="50" fill="#D9D9D9" />
                      //   </mask>
                      //   <g mask="url(#mask0_896_42450)"></g>
                      //   <g clipPath="url(#clip0_896_42450)">
                      //     <circle
                      //       cx="62"
                      //       cy="50.7671"
                      //       r="35.5"
                      //       fill="#F8FBFF"
                      //       stroke="#B2C1D4"
                      //     />
                      //     <path
                      //       d="M50.3684 61.9538L59.3158 53.1443L62.68 56.4567C61.6958 58.06 61.1053 59.9453 61.1053 61.9538H50.3684ZM55.7368 46.9777C55.7368 45.5153 54.5379 44.3348 53.0526 44.3348C51.5674 44.3348 50.3684 45.5153 50.3684 46.9777C50.3684 48.44 51.5674 49.6205 53.0526 49.6205C54.5379 49.6205 55.7368 48.44 55.7368 46.9777ZM61.2663 63.7157H48.5789V35.5253H61.1053V44.3348H70.0526V51.541C70.6432 51.4529 71.2337 51.3824 71.8421 51.3824C72.4505 51.3824 73.0411 51.4529 73.6316 51.541V42.5729L62.8947 32.0015H48.5789C46.5926 32.0015 45 33.5872 45 35.5253V63.7157C45 65.6715 46.5926 67.2396 48.5789 67.2396H62.5547C61.9284 66.1648 61.4811 64.9843 61.2663 63.7157ZM70.0526 54.9062V60.1919H64.6842V63.7157H70.0526V69.0015H73.6316V63.7157H79V60.1919H73.6316V54.9062H70.0526Z"
                      //       fill="#003087"
                      //     />
                      //   </g>
                      //   <defs>
                      //     <clipPath id="clip0_896_42450">
                      //       <rect
                      //         width="75"
                      //         height="75"
                      //         fill="white"
                      //         transform="translate(24 15.3218)"
                      //       />
                      //     </clipPath>
                      //   </defs>
                      // </svg>
                      <PictureSnapshot />
                    )}

                    <Box
                      className={`required-field ${imageSelected ? 'mt-40' : null
                        } select-image`}
                      sx={{
                        marginLeft: imageSelected ? '0px' : '50px',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography className="add-picture-text weight-700 text-black font-16 required-label">
                        {isServiceEdit ? 'Edit' : 'Upload'} Trophy Image
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        id="select-file"
                        name="profileImage"
                        style={{ display: 'none' }}
                        onClick={onInputClick}
                        onChange={(event) => handleUploadTrophyImage(event)}
                      />
                      <label htmlFor="select-file">
                        <Button
                          type="button"
                          variant="contained"
                          component="span"
                          className="upload-image image-with-txt common-button"
                          sx={{ marginTop: '24px' }}
                        >
                          <Typography className="upload-text">
                            Upload
                          </Typography>
                        </Button>
                      </label>
                    </Box>
                  </Box>
                  {imageError && (
                    <FormHelperText>{imageErrorMsg}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              {/* {isServiceEdit && (
              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    fullWidth
                    name="status"
                    value={initialValue.status}
                    onChange={handleServiceInfo}
                    label="STATUS"
                    options={[
                      {
                        label: 'Active',
                        value: 1
                      },
                      {
                        label: 'Inactive',
                        value: 0
                      }
                    ]}
                  />
                </Grid>
              </Grid>
            )} */}
            </div>
            <Card
              disabled
              sx={{
                padding: '15px',
                borderRadius: '19px 19px 0px 0px',
                mt: 5,
                opacity: initialValue.staffAssigned ? '0.5' : '1',
                pointerEvents: initialValue.staffAssigned ? 'none' : 'auto'
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                ml={2}
              >
                <Grid item>
                  <Typography className="table-heade header-blue-20">
                    {' '}
                    Criteria
                  </Typography>
                </Grid>
              </Grid>
              {Array.from({ length: criteriaCount }, (_, index) => {
                const criteria = inputValues[index];
                console.log(
                  criteria.values[1] < criteria.values[0],
                  '123456789'
                );
                return (
                  <>
                    {index > 0 && (
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                        ml={2}
                        sx={12}
                        sm={12}
                      ></Grid>
                    )}
                    {console.log(formatDate(criteria.values[0]), 'jjjjjjjjjj')}
                    <Grid container mt={3} ml={2} gap={3} mb={2}>
                      <Grid item xs={3.8} sm={3.8}>
                        <Typography className="cohorts-detail-header">
                          Attribute
                        </Typography>
                        <FormControl
                          className="top-margin-cohorts"
                          fullWidth
                          error={newCriteriaErrors[index]?.attribute}
                        >
                          <Select
                            onChange={(e) => handleInputChange1(e, index)}
                            name="attribute"
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            className="input-field-styling cohort-input-styling"
                            value={criteria.attribute}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {attributeData?.map((option) => (
                              <MenuItem value={option.name} key={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {newCriteriaErrors[index]?.attribute && (
                            <FormHelperText>
                              Attribute Name is required
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={3.8} sm={3.8}>
                        <Typography className="cohorts-detail-header">
                          Operator
                        </Typography>
                        <FormControl
                          className="top-margin-cohorts"
                          fullWidth
                          error={newCriteriaErrors[index]?.operator}
                        >
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            className="input-field-styling cohort-input-styling"
                            name="operator"
                            onChange={(e) => handleInputChange1(e, index)}
                            value={criteria.operator}
                          >
                            {operatorData?.map((option) => (
                              <MenuItem
                                value={option.name}
                                key={option.sortKey}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {newCriteriaErrors[index]?.operator && (
                          <FormHelperText>
                            Operator Name is required
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={3.8} sm={3.8}>
                        <Typography className="cohorts-detail-header">
                          {' '}
                          {selectedOperators[index] === 'BETWEEN'
                            ? 'Values'
                            : 'Value'}{' '}
                        </Typography>

                        {selectedOperators[index] === 'IS' ||
                          selectedOperators[index] === 'IS NOT' ||
                          selectedOperators[index] === 'CONTAINS' ? (
                          <TextField
                            fullWidth
                            // error={Boolean(valueNameError)}
                            error={newCriteriaErrors[index]?.values}
                            autoComplete="SENDER NAME"
                            label=""
                            name="values"
                            className="location-field input-field-styling top-margin-cohorts"
                            value={criteria.values}
                            onChange={(e) => handleInputChange1(e, index)}
                            InputLabelProps={{
                              className:
                                'label-style-create-franchisee edit-address-label'
                            }}
                          />
                        ) : selectedOperators[index] === 'BETWEEN' ? (
                          <Grid container mt={0.2} spacing={2}>
                            <Grid item>
                              <CustomDatePicker
                                name="values"
                                disablePast
                                fieldValue={
                                  criteria.values[0]
                                    ? moment(criteria.values[0]).format(
                                      'MM-DD-YYYY'
                                    )
                                    : ''
                                }
                                // maxDate={moment(criteria.values[1]).format("MM-DD-YYYY")}
                                // value={moment(criteria.values[0]).format("MM-DD-YYYY")}
                                onChange={(e) => handleInputChange2(e, index)}
                              />
                            </Grid>
                            <Grid item>
                              <CustomDatePicker
                                disabled={!criteria?.values[0]}
                                name="values"
                                minDate={
                                  new Date(
                                    moment(criteria?.values[0])?.format(
                                      'MM-DD-YYYY'
                                    )
                                  )
                                }
                                // fieldValue={
                                //   criteria.values[1]
                                //     ? criteria.values[1] > criteria.values[0]
                                //       ? moment(criteria?.values[1]).format("MM-DD-YYYY")
                                //       : moment(criteria?.values[0]).format("MM-DD-YYYY")
                                //     : null
                                //   }
                                fieldValue={
                                  criteria.values[1]
                                    ? moment(criteria.values[1]).format(
                                      'MM-DD-YYYY'
                                    )
                                    : ''
                                }
                                // value={moment(criteria.values[1]).format("MM-DD-YYYY")}
                                onChange={(e) => handleInputChange4(e, index)}
                              />
                              {newCriteriaErrors[index]?.endDateError && (
                                <FormHelperText>
                                  Date is required
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            <Box></Box>
                            <Grid mt={2}>
                              <CustomDatePicker
                                disablePast
                                name="values"
                                // disablePast
                                // value={formatDate(criteria.values[0])}
                                fieldValue={
                                  criteria.values[0]
                                    ? moment(criteria.values[0]).format(
                                      'MM-DD-YYYY'
                                    )
                                    : ''
                                }
                                onChange={(e) => handleInputChange3(e, index)}
                              />
                            </Grid>
                          </>
                          // Render a normal text field for other operators
                        )}
                        {newCriteriaErrors[index]?.values && (
                          <FormHelperText>
                            {selectedOperators[index] === 'CONTAINS'
                              ? 'Value is required'
                              : 'Date is required'}
                          </FormHelperText>
                        )}
                        {newCriteriaErrors[index]?.dateError && (
                          <FormHelperText>
                            End date should be after the start date
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="left"
                      ml={2}
                      mt={4}
                      gap={2}
                    >
                      <Grid item>
                        {index === criteriaCount - 1 && (
                          <Button
                            className="cohorts-outlined-btn"
                            variant="outlined"
                            onClick={addCreteriaOpen}
                          >
                            Add Filter
                          </Button>
                        )}
                      </Grid>

                      <Grid item>
                        {index === criteriaCount - 1 && (
                          <Button
                            className="cohorts-outlined-btn"
                            variant="outlined"
                          >
                            Add Criteria
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Card>
          </form>

          <Dialog
            open={dialog.state}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleDialog('Cancel')}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="alert-title align-center">
              {dialog.isCreated ? 'Congratulations!' : 'Discard Changes?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box display="flex" justifyContent="center" direction="column">
                  {!dialog.isCreated ? (
                    <Typography className="title-text-sub">
                      You have unsaved changes. 
                      <Typography className="title-text-sub">What do you want to do?</Typography>
                    </Typography>
                  ) : (
                    <Typography className="title-text-sub">
                      {`Trophy has been ${isServiceEdit ? 'updated' : 'created'
                        } successfully!`}
                    </Typography>
                  )}
                </Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className="alert-button-control">
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleDialog(dialog.isCreated ? 'Close' : 'Discard')
                }
              >
                {dialog.isCreated ? 'Close' : 'Discard Changes'}
              </Button>
              {!dialog.isCreated && <Divider className="vertical-divider" />}
              {!dialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleDialog('Continue')}
                >
                  Go Back to Editing
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            open={confirmEdit}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleEditDialog('Cancel')}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="alert-title align-center">
              Save Changes
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                Please confirm that you want to save your changes?
              </Typography>
                </Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className="alert-button-control">
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleEditDialog(dialog.isCreated ? 'Discard' : 'Discard')
                }
              >
                Go Back to Editing
              </Button>
              {!dialog.isCreated && <Divider className="vertical-divider" />}
              {!dialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleEditDialog('Continue')}
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      </div>
    </>
  );
};
