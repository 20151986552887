/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
    Container,
    Box,
    Button,
    styled,
    Grid,
    Typography,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import ReactQuill from 'react-quill';
import { getLocalData } from 'src/utils';
import Loader from '../../../components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ServiceDetailsWrapper = styled(Container)(
    ({ theme }) => `
          margin-top: ${theme.spacing(2)};
  `
);

export const EditTemplate = (props) => {
    const { selectedRows, setShowScreen,  getTemplate } = props;
    const [oneRowData, setOneRowData] = useState([selectedRows])
    const [isFiendUpdated, setIsFiendUpdated] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [message, setMessage] = useState('');
    const [messageSMSError, setMessageSMSError] = useState('');
    const [open, setOpen] = useState(false)
    const [templateNameError, setTemplateNameError] = useState('')
    const [messageError, setMessageError] = useState('')
    const franchiseeId = getLocalData('franchiseeId');
    const locationId = getLocalData('locationId');
    const [openSave, setOpenSave] = useState(false)
    const htmlContent = selectedRows?.HtmlPart || '';
    const [isLoading, setIsLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSucessMessage, setShowSuccessMessage] = useState(false);
    const [sucessMessage, setSuccessMessage] = useState('')
    const [subjectName, setSubjeName] = useState('');
    const [subjectError, setSubjectError] = useState('');

    // Create a temporary DOM element (a <div> in this case)
    const Editor = {};
    Editor.modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    Editor.formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video', 'HTML'
    ]
    useEffect(() => {
        let selectedRow = oneRowData[0];
        // Set the location details
        setTemplateData(selectedRow);
        setOpen(false);
    }, [oneRowData]);
    console.log(templateName, 'oneRowData');


    const setTemplateData = (selectedRow) => {
        /* setTemplateName(selectedRow.TemplateName)
        console.log(selectedRow.TemplateName, "selectedRow.TemplateNameselectedRow.TemplateName")
        setMessage(htmlContent)
        setMessage(selectedRow.Body) */
        setTemplateName(selectedRow.TemplateName);
        setSubjeName(selectedRow.Subject);
        setTemplateNameError(''); // Clear any previous errors

        if (selectedRow.TemplateType === 'EMAIL') {
            // Set message to htmlContent
            setMessage(htmlContent);
            setMessageError(''); // Clear any previous errors
        } else if (selectedRow.TemplateType === 'SMS') {
            // Set message to selectedRow.Body
            setMessage(selectedRow?.Body);
            setMessageSMSError(''); // Clear any previous errors
        }
    }
    console.log(message, 'message');
    const handleTemplateClose = () => {
        if (isFiendUpdated) {
            setOpen(true);
        } else {
            setShowScreen(0);
            setOpen(false);
        }
    };
  /*   const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'templateName') {
            if (value === '') {
                setTemplateName('');
                setTemplateNameError('Template name is required');
            } else {
                setTemplateNameError('');
                setTemplateName(value);
                setIsFiendUpdated(true);
            }
        }
        if (name === 'message') {
            if (value === '') {
                setMessage('');
                setMessageSMSError('Template name is required');
            } else {
                setMessageSMSError('');
                setMessage(value);
                setIsFiendUpdated(true);
            }
        }
        } */

        const handleInputChange = (e) => {
            const { name, value } = e.target;
          
            if (name === 'templateName') {
              const regex = /^[a-zA-Z0-9][a-zA-Z0-9_-]{0,127}$/;
          
              if (!regex.test(value)) {
                setTemplateNameError('Template name is not valid.It can contain letters, digits, underscores, and hyphens');
              } else {
                setTemplateNameError(''); // Clear the error message when the input is valid
              }
          
              setTemplateName(value);
          
              setIsFiendUpdated(true);
            } else if (name === 'message') {
              setMessage(value);
          
              setIsFiendUpdated(true);
            }
            if(name=== 'subjectName'){
                setSubjeName(value)
               }
               let subVal = e.target.value
               if(subVal){
                setSubjectError('')
               }
               let msgVal = e.target.value
               if(msgVal){
                setMessageSMSError('')
               }
          };

        const handleQuillChange = (value) => {
            setMessage(value);
            setIsFiendUpdated(true);
            // Check if the message is blank and set the error message accordingly
            if (value.trim() === '') {
                setMessageError('Message cannot be blank');
            } else {
                setMessageError('');
            }
        };
    const handleConfirmClose = (val) => {
        if (val === 'Save') {
            setOpen(false);
            // setIsLoading(true)
        } else if (val === 'Discard') {
            setOpen(false);
            setShowScreen(0);
            setIsFiendUpdated(false);
            setTemplateData();
        }
    };
    const handleCloseSave = (val) => {
        if (val === 'Save') {
            setOpenSave(false);
          //  setIsLoading(true)
            editTemplate();
           // setShowScreen(0)
        } else if (val === 'Discard') {
            setOpenSave(false);
           // setShowScreen(0);

        }
    };
    console.log(selectedRows, 'odneRowData');
    const editTemplate = async () => {
        setIsLoading(true)
        let body
        if (selectedRows.TemplateType === 'SMS') {
            // Construct payload for SMS
             body = JSON.stringify({
            locationId: locationId || '',
            franchiseeId: franchiseeId || '',
            sortKey: selectedRows.sortKey,
            TemplateType: selectedRows.TemplateType,
            // TemplateName: templateName,
            Version: selectedRows.Version,
            createdBy: selectedRows.createdBy,
            // CreationDate: selectedRows.CreationDate,
            TemplateDescription: selectedRows.TemplateDescription,
            LastModifiedDate: selectedRows.LastModifiedDate,
            Body: message
            });
        } else if (selectedRows.TemplateType === 'EMAIL') {
            // Construct payload for other TemplateTypes
             body = JSON.stringify({
                locationId: locationId || '',
                franchiseeId: franchiseeId || '',
                sortKey: selectedRows.sortKey,
                TemplateType: selectedRows.TemplateType,
               // TemplateName: templateName,

                // TemplateName: templateName,
                Version: selectedRows.Version,
                createdBy: selectedRows.createdBy,
                // CreationDate: selectedRows.CreationDate,
                TemplateDescription: selectedRows.TemplateDescription,
                LastModifiedDate: toString(selectedRows.LastModifiedDate),
                HtmlPart: message,
                Subject: subjectName || selectedRows?.Subject 
            });
        }

        // let encodedLocationId = encodeURIComponent(locationId);
        // let encodedSupplierId = encodeURIComponent(SupplierId);
        try {
       let response = await fetch(`${process.env.REACT_APP_API_END_POINT}pinpoint/updateEmailTemplate`,
            {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: body
            }
        )
        const data = await response.json();
        if (data?.statusCode && !data?.error) {
           getTemplate();
           setIsLoading(false);
           setShowSuccessMessage(true);
           setSuccessMessage('Template details has been updated successfully');
         //  setShowScreen(0);
         } else {
           setIsLoading(false);
           setShowMessage(true);
           setErrorMessage('Something went wrong, try later');
         }
       } catch (error) {
         setIsLoading(false);
         console.error('Error:', error);
         setShowMessage(true);
         setErrorMessage('Something went wrong, try later');
       }
    };
   /*  const handleSubmit = (e) => {
        // e.preventDefault();
        // e.stopPropagation();
        if (templateNameError === 'Template name is not valid.It can contain letters, digits, underscores, and hyphens') {
            // Do not submit the form if there is a space in templateName
      
            return;
          }
        if (!templateName) {
            setTemplateNameError('Template name is required');
        } if (!message) {
            setMessageSMSError('Message is required');
        }
        else if (isFiendUpdated) {
            setOpenSave(true);
            editTemplate();
        }
    } */

    const handleSubmit = () => {
        if (templateNameError === 'Template name is not valid.It can contain letters, digits, underscores, and hyphens') {
          // Do not submit the form if there is a space in templateName
    
          return;
        }
    
        if (!templateName) {
          setTemplateNameError('Template name is required');
          
        }
        if (!message && selectedRows?.TemplateType === 'SMS') {
            setMessageSMSError('Message is required');
            return
          }
          if (!subjectName && selectedRows?.TemplateType === 'EMAIL') {
            setSubjectError('Subject is required');
            return;
          }
      //  editTemplate();
      setOpenSave(true);
      };

      const closePopup = () => {
        setShowSuccessMessage(false);
        setShowScreen(0)
        getTemplate();
      };

    return (
        <><Loader IsLoading={isLoading} /><div className="custom-summary">
              {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
       {showSucessMessage &&(
   <SuccessMessage message={sucessMessage} setShowMessage={setSuccessMessage} closePopup={closePopup} />
        )}
            <ServiceDetailsWrapper
                maxWidth={'100%'}
                className="location-details-wrapper p-0"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box
                                onClick={() => handleTemplateClose()}
                                className="back-arrow-close"
                            >
                                <ArrowLeft />
                            </Box>

                            <Typography className="header-blue-32">
                                Edit Template
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Button
                                onClick={(e) => handleSubmit()}
                                variant="contained"
                                className="btn-primary edit-button template-edit-btn"
                            >
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box className="location-details-container" mt={5} ml={3}>
                    <Grid container xs={12} sm={12} mt={2}>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                fullWidth
                                name="templateName"
                                autoComplete="TEMPLATE NAME"
                                label="TEMPLATE NAME"
                                placeholder="Template Name"
                                className="location-field input-field-styling"
                                value={templateName}
                                error={Boolean(templateNameError)}
                                helperText={templateNameError}
                                onChange={handleInputChange}
                                InputLabelProps={{
                                    className: 'label-style-create-franchisee edit-address-label'
                                }} />
                        </Grid>
                    </Grid>
                </Box>
                {selectedRows.TemplateType === 'EMAIL' && (
                     <>
                    <Box className="location-details-container" ml={3} >
                    <Grid container xs={12} sm={12} mt={2}>
                    <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        name="subjectName"
                        autoComplete="SUBJECT"
                        label="SUBJECT*"
                        helperText={subjectError}
                        // error={Boolean(templateNameError)}                    
                        placeholder="Subject"
                        className="location-field input-field-styling"
                        value={subjectName}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            className:
                            'label-style-create-franchisee edit-address-label'
                        }}
                        />
                       
                        </Grid>
                        </Grid>
                    </Box>

                    <Box className="location-details-container" ml={3}>
                        <ReactQuill
                            name="message"
                            className="mt-20 react-quill-height"
                            modules={Editor.modules}
                            formats={Editor.formats}
                            value={message}
                            // helperText={messageError}
                            // onChange={setMessage}
                            onChange={handleQuillChange} />
                        {messageError && <p className="error-text">{messageError}</p>}
                        {/* <Grid container xs={12} sm={12} mt={2}>
                <Grid item xs={6} sm={6} >
                    <TextField
                        fullWidth
                        multiline
                        id="my-textarea"
                        name="message"
                        autocomplete="message"
                        label="MESSAGE"
                        placeholder="Message "
                        variant="outlined"
                        error={Boolean(messageError)}
                        helperText={messageError}
                        value={message || ''}
                        className="field-top-margin-edit-location text-message-template"
                        onChange={handleInputChange}
                        InputLabelProps={{
                            className: 'label-style-create-franchisee'
                        }}
                        InputProps={{ rows: 6 }}
                    />
                </Grid>
            </Grid> */}
                    </Box>
                    </>
                )}
                {selectedRows.TemplateType === 'SMS' && (
                    <Box className="location-details-container" ml={3}>

                        <Grid container xs={12} sm={12} mt={2}>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    fullWidth
                                    multiline
                                    id="my-textarea"
                                    name="message"
                                    autocomplete="message"
                                    label="MESSAGE*"
                                    placeholder="Message "
                                    variant="outlined"
                                   // error={Boolean(messageSMSError)}
                                    helperText={messageSMSError}
                                    value={message}
                                    className="field-top-margin-edit-location text-message-template"
                                    onChange={handleInputChange}
                                    InputLabelProps={{
                                        className: 'label-style-create-franchisee'
                                    }}
                                    InputProps={{ rows: 6 }} />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </ServiceDetailsWrapper>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleConfirmClose('Cancel')}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="alert-title align-center">
                    Discard Changes?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        className="alert-decription align-center"
                    >
                        <Box display="flex" justifyContent="center" direction="column">
                            <Typography className="title-text-sub">
                                You have unsaved changes. 
                                <Typography className="title-text-sub">What do you want to do? </Typography>
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions className="alert-button-control">
                    <Button
                        className="font-17 text-black no-hover"
                        onClick={() => handleConfirmClose('Discard')}
                    >
                        Discard changes
                    </Button>
                    <Divider className="vertical-divider" />

                    <Button
                        className="font-17 no-hover"
                        onClick={() => handleConfirmClose('Save')}
                    >
                        Go Back to Editing
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openSave}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleCloseSave('Cancel')}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="alert-title align-center">
                {'Save Changes'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        className="alert-decription align-center"
                    >
                        <Box display="flex" justifyContent="center" direction="column">
                            <Typography className="title-text-sub">
                            Please confirm that you want to save your changes?
                               
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions className="alert-button-control">
                    <Button
                        className="font-17 text-black no-hover"
                        onClick={() => handleCloseSave('Discard')}
                    >
                     Go Back to Editing
                    </Button>
                    <Divider className="vertical-divider" />

                    <Button
                        className="font-17 no-hover"
                        onClick={() => handleCloseSave('Save')}
                    >
                    Save
                    </Button>

                </DialogActions>
            </Dialog>

        </div></>
    );
};