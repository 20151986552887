import axios from 'axios';

export const ApiService = {
  // Function to make a GET request
  get: async (url) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      let msg = error?.response?.data?.message || error.message;
      throw new Error(msg);
      // throw new Error(error.message);
    }
  },

  // Function to make a POST request
  post: async (url, data) => {
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      let msg = error?.response?.data?.message || error.message;
      throw new Error(msg);
      // throw new Error(error.message);
    }
  },

  // Function to make a PUT request
  put: async (url, data) => {
    try {
      const response = await axios.put(url, data);
      return response.data;
    } catch (error) {
      let msg = error?.response?.data?.message || error.message;
      throw new Error(msg);
      // throw new Error(error.message);
    }
  },

  // Function to make a DELETE request
  delete: async (url, data) => {
    try {
      const response = await axios.delete(url, { data:data});
      return response.data;
    } catch (error) {
      let msg = error?.response?.data?.message || error.message;
      throw new Error(msg);
      // throw new Error(error.message);
    }
  },
};
 
