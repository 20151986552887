import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import Footer from 'src/components/Footer';
import Header from '../../../components/Header';
import {
  Box,
  Container,
  Grid,
  // Typography
} from '@mui/material';
import RecieveInventoryListAPI from './RecieveInventoryListAPI';
import { useNavigate } from 'react-router-dom';
import GlobalData from '../../../global';
// import { ArrowLeft } from 'src/assets/SVG/SvgIcons';

function TransferHistory() {
  const navigate = useNavigate();
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Transfer History');

  // Get the title from global data
  const title = GlobalData[0].title;
  // const childRef = useRef(null);

  useEffect(() => {
    // let elm = document.getElementsByClassName('page-header-text')[0];
    // if (elm) elm.innerHTML = 'Transfer History';
    let isLogin = sessionStorage.getItem("Login_Sucess");
    if (isLogin !== 'true') {
      navigate("/login");
    }
  }, [])

//   const handleCloseDetails = () => {
//     navigate('/inventory-managment');
//   };

  return (
    <>
      <Helmet>
        <title>Receive Inventory | {title}</title>
      </Helmet>
      <Box sx={{ paddingX: "22px" }} className='mt-10' >
        <Header title="Receive Inventory" />
        {/* <Typography
          variant="variant2"
          sx={{
            fontSize: '24px',
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
          className='text-primary weight-600'
        >
          
          <Typography className="header-blue-40 ">
            Received Inventory
          </Typography>
        </Typography> */}
      </Box>
      <Container maxWidth="100%" sx={{ mt: 3 }} className='main-container relative'>

        {/* container */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item width={'100%'}>
            {/* Now draw the franchisee table */}
            <RecieveInventoryListAPI />
          </Grid>
        </Grid>

      </Container>
      <Footer />

    </>
  );
}

export default TransferHistory;