/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  // DynamicTabs,
  FilterAndSearchComponent
} from '../../../components/Contract';
import { getLocalData } from '../../../utils';
import { ApiService } from '../../../services/ApiService';
import {
  Box, Typography
} from '@mui/material';
import { filtersQuery } from '../../../utils/index';
import Loader from '../../../components/Loader';

export const ContractTargetTable = ({ selected, setSelected, pageType, setIsFieldEdited }) => {
  const locationId = getLocalData('locationId');
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('service');
  const [allServices, setAllServices] = useState([]);
  const [tabList, setTabList] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [categoryList, setCategoryList] = useState([]);
  // console.log(tabList, errorMessage, showErrorMessage);

  const [filters, setSidebarFilters] = useState({
    typeOfService: null,
    billingCycle: null,
    status: 1,
    // category: null
    categoryName: [],
    sellOnline: ''
  });

  // Get all service categories
  const getAllServiceCategory = async () => {
    // let encodedLocationId = encodeURIComponent(locationId);
    // ?locationId=${encodedLocationId}
    try {
      // const categoriesResponse = await ApiService.get(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}services/getAllServiceCategories?locationId=${encodedLocationId}`);
      const categoriesResponse = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}pricing-option/getAllServiceCategories`);
      if (!categoriesResponse?.data?.data?.error) {
        // console.log('all category', categoriesResponse?.data?.data?.Items);
        setAllCategories(categoriesResponse?.data?.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          categoriesResponse.message ||
          'Something went wrong with Service categories'
        );
      }

    } catch (error) {
      console.warn('API Error', error);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const getAllServices = async () => {
    // Show loader
    setIsLoading(true);
    let queryString = await filtersQuery(filters);
    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(`${BASE_URL}contracts/getAllPricingOptions?locationId=${encodedLocationId}${filtersParams}`);
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        setAllServices(Item);
        // Hide loader
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      // Hide loader
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllServiceCategory();
    getAllServices();
  }, []);

  useEffect(() => {
    if (type === 'service') {
      getAllServices();
    }
  }, [filters]);

  useEffect(() => {
    if (allServices && allCategories) {
      setTabList([
        {
          label: tables[0]?.label,
          customLabel: 'Services',
          component: (
            <FilterAndSearchComponent
              sideBarFilters={filters}
              filterOptions={filtersOptions}
              loading={loading}
              setSidebarFilters={setSidebarFilters}
              title={tables[0]?.label}
              dataList={allServices}
              isCheckBox
              columns={tables[0]?.columns}
              selected={selected}
              setSelected={setSelected}
              handleChangeFilter={() => handleChangeFilter('service')}
            />
          )
        }
      ]);
      setLoading(false);
    }
  }, [selected, allServices, allCategories]);

  const [tables] = useState([
    {
      label: 'All Pricing Options',
      columns: [
        { field: 'name', headerName: 'Pricing Option', sortable: false },
        // {
        //   field: 'categoryName',
        //   headerName: 'Category',
        //   sortable: false
        // },
        {
          field: 'numberOfSessions',
          sortable: false,
          headerName: '# of Session'
        },
        {
          field: 'price',
          sortable: false,
          headerName: 'Price',
          renderCell: (params) => {
            return (
              <Typography level="body1">${params.row.price || 0}</Typography>
            );
          }
        }
      ]
    }
  ]);

  const [filtersOptions, setFiltersOptions] = useState([
    {
      label: 'Status',
      value: 'status',
      enable: false,
      options: [
        { label: 'Active', value: 1 }
      ],
      subFilters: [
        {
          label: 'Category',
          value: 'categoryName',
          enable: false,
          options: allCategories?.map((item) => ({
            label: item.name,
            value: item.name
          }))
        }
      ]
    }
  ]);

  useEffect(() => {
    setFiltersOptions([
      {
        label: 'Status',
        value: 'status',
        enable: false,
        options: [
          // { label: 'All', value: '' },
          { label: 'Active', value: 1 }
          // { label: 'Inactive', value: 0 },
          // { label: 'Expired', value: 3 }
        ],
        subFilters: [
          {
            label: 'Sell Online',
            value: 'sellOnline',
            enable: false,
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' }
            ]
          },
          {
            label: 'Category',
            value: 'categoryName',
            enable: false,
            options: allCategories?.map((item) => ({
              label: item.name,
              value: item.name
            }))
          }
        ]
      }
    ]);
  }, [allCategories]);

  const handleChangeFilter = (type) => {
    setType(type);
    if (type === 'service') {
      filtersOptions[0].enable = true;
      filtersOptions[0].subFilters[0].enable = true;
      filtersOptions[0].subFilters[1].enable = true;
    }
    setFiltersOptions(filtersOptions);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Box sx={{ marginBottom: '25px' }}>
        <FilterAndSearchComponent
          sideBarFilters={filters}
          filterOptions={filtersOptions}
          loading={loading}
          setSidebarFilters={setSidebarFilters}
          title={tables[0]?.label}
          dataList={allServices}
          isCheckBox
          columns={tables[0]?.columns}
          selected={selected}
          setSelected={setSelected}
          handleChangeFilter={() => handleChangeFilter('service')}
          IsContractEdit={pageType === 'edit'}
          setIsFieldEdited={setIsFieldEdited}
        />
      </Box>
    </>
  );
};

export default ContractTargetTable;
