/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import ErrorMessage from '../../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../../components/Loader';
// import { getLocalData } from 'src/utils';
import { TrophyEarnedTable } from './TrophyEarnedTable';

const TrophyEarned = (props) => {
  const {clientId, petId} = props;
  // const locationId = getLocalData('locationId');
  // const userId = sessionStorage.getItem('UserId');
  const [loading, setLoading] = useState(true);
  const [servicesData, setServicesData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [filters, setFilters] = useState({
    status: null,
    createdAt: null,
    startDate: null,
    endDate: null
  });

  //   ===============call api here=============

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined ||
        (Array.isArray(filtersParams[key]) && filtersParams[key].length === 0)
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  const getAllEarnedTrophy = async () => {
    let cid = localStorage.getItem('clientId');
    let encodedClientId = encodeURIComponent(clientId) || encodeURIComponent(cid);
    let encodedPetId = encodeURIComponent(petId);
    setLoading(true);
    let queryString = filtersQuery();
    try {
      const [
        trophyResponse
      ] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}trophy/getTrophyByPet?clientId=${encodedClientId}&petId=${encodedPetId}${queryString}`
        )
      ]);

      if (!trophyResponse?.data?.data?.error) {
        const modifiedData = trophyResponse?.data?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.sortKey
          })
        );
        setServicesData(modifiedData);
        setLoading(false);
      } else {
        setLoading(false);
        // setShowErrorMessage(true);
        setErrorMessage(
          trophyResponse.message || 'Something went wrong with Services'
        );
      }
      setLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setLoading(false);
      // setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  useEffect(() => {
    if(petId) getAllEarnedTrophy();
  }, [JSON.stringify(filters), petId]);

  return (
    <>
      <Loader IsLoading={loading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          getDetails={getAllEarnedTrophy}
        />
      )}

      <Container maxWidth="100%" className="custom-container p-0">
        <Box justifyContent="center" direction="column">

          <Grid
            container
            spacing={2}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Grid
              item
              width={`100%`}
            >
              <TrophyEarnedTable
                tableRows={servicesData}
                searchQuery={searchQuery}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default TrophyEarned;
