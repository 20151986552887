/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import React from 'react';
import {
  Button,
  Box,
  Grid,
  Avatar,
  MenuItem,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Checkbox,
  FormHelperText,
  Select,
  InputLabel
} from '@mui/material';
import Loader from '../../../components/Loader';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { getLocalData } from '../../../utils';
import { ApiService } from '../../../services/ApiService';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddAnotherPet({ data, setShowScreen, showScreen, getEventToRefresh }) {
  /* State to open confirmation dialog box */
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  /* State to check whether the value is added/updated  */
  // const [isUpdated, setIsUpdated] = useState(false);
  /* State for checking the state of field update */
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  /* Variable getting user id from local storage */
  let userId = sessionStorage.getItem('UserId');
  /* States for setting details of pet information */
  const [petFirstName, setpetFirstName] = useState('');
  const [petLastName, setpetLastName] = useState('');
  const [birthDate, setbirthDate] = useState('');
  const [gender, setgender] = useState('');
  const [acquisitionSource, setacquisitionSource] = useState('');
  const [breed, setbreed] = useState('');
  const [veterinarian, setveterinarian] = useState('');
  const [imgObj, setimgObj] = useState([]);
  const [imgUrl, setimgUrl] = useState('');
  const [allergies, setallergies] = useState([]);
  const [bark, setbark] = useState('');
  const [strangers, setstrangers] = useState('');
  const [biting, setbiting] = useState('');
  const [possessive, setpossessive] = useState('');
  const [advanced, setadvanced] = useState('');
  const [social, setsocial] = useState('');
  const [orientation, setorientation] = useState(false);
  const [evaluation, setevaluation] = useState(false);
  const [agility, setagility] = useState(false);
  const [tricks, settricks] = useState(false);
  const [rabiesExpiration, setrabiesExpiration] = useState('');
  const [bordetellaExpiration, setbordetellaExpiration] = useState('');
  const [dhppExpiration, setdhppExpiration] = useState('');
  const [notes, setnotes] = useState('');
  // const [attachChildProfile, setattachChildProfile] = useState('');
  const currentDate = new Date();
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 20);
  const minDateValue = new Date(minDate);
  /* State Error message for pet information validation */
  const [petFirstNameError, setpetFirstNameError] = useState('');
  const [birthDateError, setbirthDateError] = useState('');
  const [genderError, setgenderError] = useState('');
  const [acquisitionSourceError, setacquisitionSourceError] = useState('');
  const [breedError, setbreedError] = useState('');
  // const [veterinarianError, setveterinarianError] = useState("");
  // const [allergiesError, setallergiesError] = useState('');
  // const [orientationError, setorientationError] = useState('');
  // const [evaluationError, setevaluationError] = useState('');
  const [breeds, setbreeds] = useState([]);
  // const [activeStep, setActiveStep] = useState(0);

  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [veterinarianList, setVeterinarianList] = useState([]);
  const [acquisitionList, setAcquisitionList] = useState([]);
  const [allergiesList, setAllergiesList] = useState([]);
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const locationId =
    isFranchisor === 'true'
      ? localStorage.getItem('SelectedLocationId')
      : getLocalData('locationId');
  // const [uploadData, setUploadData] = useState([]);
  // const [rejectData, setRejectData] = useState([]);
  // const [isUploadError, setIsUploadError] = useState(false);
  // const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [multipleFile, setMultipleFile] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [imageError, setImageError] = useState('');

  // use effect to see the changes in the state
  useEffect(() => {
    getAllAcquisition();
    getBreed();
    getAllAllergies();
    getVeterinarian();
    setOpen(false);
    // setIsUpdated(false);
  }, []);

  /* Function to get breeds */

  const getBreed = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}breed-list`
      );
      if (response.statusCode === 200) {
        setbreeds(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get getAllAcquisition */

  const getAllAcquisition = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}breed-list/getAllAcquisition`
      );
      if (response.statusCode === 200) {
        setAcquisitionList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get getAllAllergies */

  const getAllAllergies = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}breed-list/getAllAllergies`
      );
      if (response.statusCode === 200) {
        setAllergiesList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get Veterinarian */

  const getVeterinarian = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntities?locationId=${encodedLocationId}&businessEntityType=Veterinarian`
      );
      if (response.statusCode === 200) {
        setVeterinarianList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to upload Image */

  const handleImageUpload = (e) => {
    setimgObj(e.target.files[0]);
    setimgUrl(URL.createObjectURL(e.target.files[0]));
    setIsFieldUpdated(true);
  };

  /* Functions to set values of various fields */

  const SetPetfirstName = (val) => {
    if (!val) {
      setpetFirstNameError('First name is required');
      setpetFirstName('');
    } else {
      setpetFirstNameError('');
      setpetFirstName(val);
      setIsFieldUpdated(true);
    }
  };

  /* Function to close the add pet form when click on back button */
  const handleCloseDetails = () => {
    if (isFieldUpdated) {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
      // Here redirecting to the client details page.
      setShowScreen(3);
    }
  };

  const SetPetLastName = (val) => {
    setpetLastName(val);
    setIsFieldUpdated(true);
  };

  const SetBirthDate = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    if (!val) {
      setbirthDateError('Birth date is required');
    } else {
      setbirthDateError('');
      setbirthDate(val);
      setIsFieldUpdated(true);
    }
  };

  const SetGender = (val) => {
    if (!val) {
      setgenderError('Gender is required');
    } else {
      setgenderError('');
      setgender(val);
      setIsFieldUpdated(true);
    }
  };

  const SetacquisitionSource = (val) => {
    if (!val) {
      setacquisitionSourceError('AcquisitionSource is required');
    } else {
      setacquisitionSourceError('');
      setacquisitionSource(val);
      setIsFieldUpdated(true);
    }
  };

  const SetBreed = (val) => {
    if (!val) {
      setbreedError('Breed is required');
    } else {
      setbreed(val);
      setbreedError('');
      setIsFieldUpdated(true);
    }
  };

  const SetVeterinarian = (val) => {
    if (val) {
      setveterinarian(val);
      setIsFieldUpdated(true);
    } else {
      setveterinarian(false);
      setIsFieldUpdated(true);
    }
  };

  const SetAllergies = (val) => {
    if (!val) {
      setallergies('');
      // setallergiesError('Allergies is required');
    } else {
      setallergies(val);
      // setallergiesError('');
      setIsFieldUpdated(true);
    }
  };

  const SetBark = (val) => {
    setbark(val);
    setIsFieldUpdated(true);
  };

  const SetStrangers = (val) => {
    setstrangers(val);
    setIsFieldUpdated(true);
  };

  const SetBiting = (val) => {
    setbiting(val);
    setIsFieldUpdated(true);
  };

  const SetPossessive = (val) => {
    setpossessive(val);
    setIsFieldUpdated(true);
  };

  const SetAdvanced = (val) => {
    setadvanced(val);
    setIsFieldUpdated(true);
  };

  const SetSocial = (val) => {
    setsocial(val);
    setIsFieldUpdated(true);
  };

  const SetOrientation = (val) => {
    if (val) {
      setorientation(true);
      setIsFieldUpdated(true);
    } else {
      setorientation(false);
      setIsFieldUpdated(true);
    }
  };

  const SetEvaluation = (val) => {
    if (val) {
      setevaluation(true);
      setIsFieldUpdated(true);
    } else {
      setevaluation(false);
      setIsFieldUpdated(true);
    }
  };

  const SetAgility = (val) => {
    setagility(val);
    setIsFieldUpdated(true);
  };

  const SetTricks = (val) => {
    settricks(val);
    setIsFieldUpdated(true);
  };

  const SetRabiesExpiration = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    setrabiesExpiration(val);
    setIsFieldUpdated(true);
  };

  const SetBordetellaExpiration = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    setbordetellaExpiration(val);
    setIsFieldUpdated(true);
  };

  const SetDhppExpiration = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    setdhppExpiration(val);
    setIsFieldUpdated(true);
  };

  /* Function to add client and pet  */

  const createClient = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!petFirstName) {
      setpetFirstNameError('First name is required');
    }
    if (!birthDate) {
      setbirthDateError('Birth date is required');
    }
    if (!gender) {
      setgenderError('Gender is required');
    }
    if (!acquisitionSource) {
      setacquisitionSourceError(' Acquisition Source is required');
    }
    if (!breed) {
      setbreedError(' Breed is required');
    }

    // if (!allergies) {
    //   setallergiesError('Allergies is required');
    // }

    // Now Update the location if all fields are valid
    if (
      isFieldUpdated &&
      acquisitionSource &&
      breed &&
      gender &&
      // allergies &&
      petFirstName &&
      birthDate
    ) {
      addPet(); // Call the method to add the pet
    }
  };

  // Method to add the pet
  const addPet = async () => {
    setShowError(false);
    setErrorMessage('');
    let clId = data.clientId || localStorage.getItem('clientId');
    if (clId) {
      // Now show the loader
      setIsLoading(true);
      let formData = new FormData();
      formData.append('clientId', clId);
      formData.append('firstName', petFirstName);
      formData.append('lastName', petLastName);
      formData.append('profileImage', imgObj);
      formData.append('birthDate', birthDate || '');
      formData.append('gender', gender);
      formData.append('acquisitionSource', acquisitionSource);
      formData.append('breed', breed);
      formData.append('notes', notes);
      formData.append('veterinarian', veterinarian);
      formData.append('allergies', allergies);
      // formData.append('barking', bark ? 'Yes' : 'No');
      // formData.append('strangers', strangers ? 'Yes' : 'No');
      // formData.append('biting', biting ? 'Yes' : 'No');
      // formData.append('possessive', possessive ? 'Yes' : 'No');
      // formData.append('advanced', advanced ? 'Yes' : 'No');
      // formData.append('social', social ? 'Yes' : 'No');
      formData.append('barking', bark);
      formData.append('strangers', strangers);
      formData.append('biting', biting);
      formData.append('possessive', possessive);
      formData.append('advanced', advanced);
      formData.append('social', social);
      formData.append('completed[orientation]', orientation ? 'Yes' : 'No');
      formData.append('completed[evaluation]', evaluation ? 'Yes' : 'No');
      formData.append('completed[agility]', agility ? 'Yes' : 'No');
      formData.append('completed[tricks]', tricks ? 'Yes' : 'No');
      formData.append('rabiesExpiration', rabiesExpiration || '');
      formData.append('bordetellaExpiration', bordetellaExpiration || '');
      formData.append('dhppExpiration', dhppExpiration || '');
      formData.append('createdBy', userId);

      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}client/addChild`,
        formData
      );
      const data = response;
      if (data.statusCode === 200) {
        setIsLoading(false);
        let childId = data.data.childId;
        let clId = localStorage.getItem('clientId');
        let clientId = localStorage.getItem('clientId');
        // Check the length and call the method to add the pet document
        if (multipleFile && multipleFile.length > 0) {
          sessionStorage.setItem('cId', clientId);
          sessionStorage.setItem('petId', childId);
          // for (const file of multipleFile) {
          for (let i = 0; i < multipleFile.length; i++) {
            let clientId = clId || sessionStorage.getItem('cId');
            let petId = childId || sessionStorage.getItem('petId');

            // Now call the method to add the pet document
            addPetDocument(clientId, petId, multipleFile[i]);
          }
        }

        setIsLoading(false);
        setOpen(true);
        getEventToRefresh();
      } else {
        setShowError(true);
        setErrorMessage(data.message);
        setIsLoading(false);
      }
    }
  };

  /* Function to close confirm dialog */

  const handleClose = (val) => {
    if (val === 'Discard') {
      setOpen(false);
      // Here redirecting to the client details page.
      setShowScreen(3);
    } else if (val === 'Save') {
      setOpen(false);
      setShowScreen(0);
      setIsFieldUpdated(false);
      clearAll();
    }
  };

  const handleCloseConfirmation = (val) => {
    if (val === 'Save') {
      setOpenPopup(false);
    }

    if (val === 'Discard') {
      setOpenPopup(false);
      // Here redirecting to the client details page.
      setShowScreen(3);
    }
  };

  /* Function to clear all fields and error */

  const clearAll = () => {};

  const removeFile = (idToRemove) => {
    const updatedFiles = multipleFile.filter((file) => file.id !== idToRemove);
    setMultipleFile(updatedFiles);

    // check if multiple file lenght is less than or equal to 5
    if (multipleFile.length <= 5) {
      setImageError('');
    }
  };

  useEffect(() => {
    if (multipleFile) {
      let images = multipleFile.map((item) => item.name);
      setProductImage(images);

      // check if multiple file lenght is less than or equal to 5
      if (multipleFile.length <= 5) {
        setImageError('');
      }
    }
  }, [multipleFile]);

  /* Function to get uploaded image */
  const handleFileInput = (event) => {
    /* Maximum allowed size in bytes
        5MB Example
        Change first operand(multiplier) for your needs */
    const maxAllowedSize = 5 * 1024 * 1024;
    let filesLength = event.target.files.length;
    let total = multipleFile.length + filesLength || 0;

    if (
      event.target.files.length !== 0 &&
      filesLength <= 5 &&
      multipleFile.length <= 5 &&
      total <= 5
    ) {
      if (event.target.files[0].type === 'image/gif') {
        // alert("Sorry! GIF format is not supported.");
        setImageError('Please upload a supported file size and format.');
      } else {
        const fileList = event.target.files;
        const uploadedImages = Array.from(fileList).map((file) => {
          // let fileArr = {};
          // Create an object for each file with a unique ID and the file itself
          if (file.size > maxAllowedSize) {
            setImageError(
              'File size exceeds the maximum allowed limit of 5 MB'
            );
            // return false;
          }
          return {
            // name: URL.createObjectURL(file),
            name: file.name,
            id: Math.random().toString(36).substring(7),
            file: file
          };
        });
        setMultipleFile([...multipleFile, ...uploadedImages]);

        setImageError('');
        // set field updated to true
        setIsFieldUpdated(true);
      }
    } else if (filesLength > 5 || multipleFile.length > 5 || total > 5) {
      setImageError(
        'Number of 5 files allowed only. Please select/remove extra files.'
      );
    }
  };

  // Method to add the pet
  const addPetDocument = async (clId, petId, docObj) => {
    let clientId = clId || sessionStorage.getItem('cId');
    let childId = petId || sessionStorage.getItem('petId');
    let uploadData = docObj;

    if (clientId && childId) {
      // Now show the loader
      // setIsLoading(true);
      let formData = new FormData();
      formData.append('clientId', clientId);
      formData.append('childId', childId);
      formData.append('documentImage', uploadData?.file);
      formData.append('documentName', uploadData?.name);

      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}client/addDocumentProfile`,
        formData
      );
      if (response.statusCode === 200) {
        // localStorage.removeItem('clientId');
        // setIsLoading(false);
        // setOpen(true);
        // getEventToRefresh();
      } else {
        setShowError(true);
        setErrorMessage(response.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Box sx={{ padding: '2rem' }}>
        <Box
          component="form"
          onSubmit={createClient}
          sx={{ margin: 'auto' }}
          className=""
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseDetails()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>
                <Typography
                  className="header-blue-32"
                  // style={{ marginTop: '-5px', marginLeft: '5px' }}
                >
                  Add Dog
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                  variant="contained"
                  type="submit"
                  className="btn-primary m-0"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: '5px' }}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography className="header-blue-22">
                Dog Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                fullWidth
                name="First name"
                autocomplete="First name"
                label="FIRST NAME"
                placeholder="FIRST NAME"
                value={petFirstName}
                onChange={(e) => SetPetfirstName(e.target.value)}
                error={Boolean(petFirstNameError)}
                helperText={petFirstNameError}
                className="input-field-styling required-field"
                InputLabelProps={{
                  className: 'label-style-create-franchisee required-label'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                fullWidth
                name="Last name"
                autocomplete="Last name"
                label="LAST NAME"
                placeholder="LAST NAME"
                value={petLastName}
                onChange={(e) => SetPetLastName(e.target.value)}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{}}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <FormControl
                error={genderError}
                fullWidth
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  GENDER
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="GENDER"
                  className="input-field-styling"
                  value={gender}
                  onChange={(e) => SetGender(e.target.value)}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
                {genderError && (
                  <FormHelperText className="error-message">
                    Gender is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl
                error={acquisitionSourceError}
                fullWidth
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  ACQUISITION SOURCE
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  className="input-field-styling"
                  label="ACQUISITION SOURCE"
                  value={acquisitionSource}
                  onChange={(e) => SetacquisitionSource(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        overflowY: 'auto'
                      }
                    }
                  }}
                >
                  {acquisitionList &&
                    acquisitionList.map((data) => (
                      <MenuItem
                        key={data.sortKey}
                        value={data.acquisitionSource}
                      >
                        {data.acquisitionSource}
                      </MenuItem>
                    ))}
                  {/* <MenuItem value="Shelter/Rescue">Shelter/Rescue</MenuItem>
                  <MenuItem value="Breeder">Breeder</MenuItem>
                  <MenuItem value="Friend/Family">Friend/Family</MenuItem>
                  <MenuItem value="Pet Store">Pet Store</MenuItem>
                  <MenuItem value="Others">Others</MenuItem> */}
                </Select>
                {acquisitionSourceError && (
                  <FormHelperText className="error-message">
                    AcquisitionSource is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl
                error={breedError}
                fullWidth
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  BREED
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  className="input-field-styling"
                  label="BREED"
                  value={breed}
                  onChange={(e) => SetBreed(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        overflowY: 'auto'
                      }
                    }
                  }}
                >
                  {breeds.map((data) => (
                    <MenuItem value={data.breedName}>{data.breedName}</MenuItem>
                  ))}
                </Select>
                {breedError && (
                  <FormHelperText className="error-message">
                    Breed is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              className="form-datepicker-container"
            >
              {/* <TextField
                fullWidth
                name="Birthday"
                label="BIRTHDAY"
                placeholder="BIRTHDAY"
                type="date"
                defaultValue={birthDate}
                onChange={(e) => SetBirthDate(e.target.value)}
                helperText={birthDateError}
                className="input-field-styling required-field"
                InputLabelProps={{
                  className: 'label-style-create-franchisee required-label',
                  shrink: true
                }}
                InputProps={{ min: minDateValue, max: currentDate }}
              /> */}
              {/* <CustomDatePicker
                label="BIRTHDAY"
                onChange={SetBirthDate}
                required={Boolean(true)}
                minDate={minDateValue}
                maxDate={currentDate}
              /> */}
              <CustomDatePickerForAllBrowser
                label={'BIRTHDAY'}
                onChange={SetBirthDate}
                required={Boolean(true)}
                minDate={minDateValue}
                maxDate={currentDate}
              />
              {birthDateError && (
                <FormHelperText>Birth date is required</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Grid item xs={12} md={12} sm={12}>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      className="check-box-span text-blue"
                      color="default"
                      sx={{
                        '&.Mui-checked': {
                          color: '#003087'
                        }
                      }}
                      onChange={(e) => SetVeterinarian(e.target.checked)}
                    />
                  }
                  label={
                    <Typography className="check-box-text" sx={{ color: '#003087 !important' }}>
                      VETERINARIAN
                    </Typography>
                  }
                /> */}
                <FormControl
                  fullWidth
                  className="select-drop-down-label-styling"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling"
                  >
                    VETERINARIAN
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    className="input-field-styling"
                    label="VETERINARIAN"
                    value={veterinarian}
                    onChange={(e) => SetVeterinarian(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          overflowY: 'auto'
                        }
                      }
                    }}
                  >
                    {veterinarianList &&
                      veterinarianList.map((data) => (
                        <MenuItem key={data.sortKey} value={data.businessName}>
                          {data.businessName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={4}>
              <TextField
                fullWidth
                name="Allergies"
                autocomplete="Allergies"
                label="ALLERGIES"
                placeholder="Allergies"
                value={allergies}
                onChange={(e) => SetAllergies(e.target.value)}
                // error={Boolean(allergiesError)}
                // helperText={allergiesError}
                className="input-field-styling "
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
                InputProps={{}}
              />
            </Grid> */}
            <Grid item xs={12} sm={4} md={4}>
              <FormControl fullWidth className="select-drop-down-label-styling">
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling"
                >
                  ALLERGIES
                </InputLabel>
                <Select
                  multiple
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  className="input-field-styling"
                  label="ALLERGIES"
                  value={allergies}
                  onChange={(e) => SetAllergies(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        overflowY: 'auto'
                      }
                    }
                  }}
                >
                  {allergiesList &&
                    allergiesList.map((data) => (
                      <MenuItem key={data.sortKey} value={data.allergyName}>
                        {data.allergyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={1.5} md={1}>
              <Avatar
                src={imgUrl !== '' ? imgUrl : null}
                sx={{ height: '70px', width: '70px' }}
              />
            </Grid>
            <Grid item xs={12} sm={2.5} md={3}>
              <Box>
                <Typography className="check-box-text">
                  Upload Profile Image
                </Typography>
                <input
                  accept="image/*"
                  multiple
                  type="file"
                  id="select-pet-file"
                  name="profileImage"
                  style={{ display: 'none' }}
                  onChange={(event) => handleImageUpload(event)}
                />
                <label htmlFor="select-pet-file">
                  <Button
                    variant="contained"
                    component="span"
                    className="upload-image"
                    sx={{ marginTop: '6px !important' }}
                  >
                    <Typography className="upload-text">Upload</Typography>
                  </Button>
                </label>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Typography className="heading-text">
                Tell us about your dog
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Box className="radio-button-heading" sx={{ marginTop: '15px' }}>
                Does your dog bark at other dogs when out on a walk or at you
                for attention?
              </Box>
              <FormControl className=" radio-button-text">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(e) => SetBark(e.target.value)}
                >
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio className="radio-button-style" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio className="radio-button-style" />}
                      label="No"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Box className="radio-button-heading" sx={{ marginTop: '15px' }}>
                Is your dog afraid of stranger or certain groups of people?
              </Box>
              <FormControl className=" radio-button-text">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(e) => SetStrangers(e.target.value)}
                >
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio className="radio-button-style" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio className="radio-button-style" />}
                      label="No"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <Box className="radio-button-heading" sx={{ marginTop: '15px' }}>
                Has your dog ever bitten a person or another dog, regardless or
                injury(not including nipping or play biting)?
              </Box>
              <FormControl className=" radio-button-text">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(e) => SetBiting(e.target.value)}
                >
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio className="radio-button-style" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio className="radio-button-style" />}
                      label="No"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Box className="radio-button-heading" sx={{ marginTop: '15px' }}>
                Is your dog very possessive about his/her food or toys?
              </Box>
              <FormControl className=" radio-button-text">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(e) => SetPossessive(e.target.value)}
                >
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio className="radio-button-style" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio className="radio-button-style" />}
                      label="No"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Box className="radio-button-heading" sx={{ marginTop: '15px' }}>
                Do you believe your dog is able to skip basic obedience and take
                advanced classes or agility?
              </Box>
              <FormControl className=" radio-button-text">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(e) => SetAdvanced(e.target.value)}
                >
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio className="radio-button-style" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio className="radio-button-style" />}
                      label="No"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Box className="radio-button-heading" sx={{ marginTop: '15px' }}>
                Is your dog social?
              </Box>
              <FormControl className=" radio-button-text">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(e) => SetSocial(e.target.value)}
                >
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio className="radio-button-style" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio className="radio-button-style" />}
                      label="No"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Grid item xs={12} md={11} sm={11}>
                <TextField
                  fullWidth
                  multiline
                  id="my-textarea"
                  name="Notes"
                  autocomplete="Notes"
                  label="NOTES"
                  placeholder="Notes here . . . "
                  variant="outlined"
                  value={notes}
                  className="text-area-styl"
                  onChange={(e) => setnotes(e.target.value)}
                  InputLabelProps={{
                    className: 'label-style-create-franchisee'
                  }}
                  InputProps={{ rows: 6 }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sm={6} className="-d-none">
              <Typography className="text-blue font-16 weight-600">
                COMPLETED
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        className="check-box-span text-blue"
                        color="default"
                        sx={{
                          '&.Mui-checked': {
                            color: '#003087'
                          }
                        }}
                        onChange={(e) => SetOrientation(e.target.checked)}
                      />
                    }
                    label={
                      <Typography className="check-box-text text-blue">
                        Orientation
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        className="check-box-span text-blue"
                        color="default"
                        sx={{
                          '&.Mui-checked': {
                            color: '#003087'
                          }
                        }}
                        onChange={(e) => SetEvaluation(e.target.checked)}
                      />
                    }
                    label={
                      <Typography className="check-box-text text-blue">
                        Evaluation
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '5px' }}>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        className="check-box-span text-blue"
                        color="default"
                        sx={{
                          '&.Mui-checked': {
                            color: '#003087'
                          }
                        }}
                        onChange={(e) => SetAgility(e.target.value)}
                      />
                    }
                    label={
                      <Typography className="check-box-text text-blue">
                        Agility
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        className="check-box-span text-blue"
                        color="default"
                        sx={{
                          '&.Mui-checked': {
                            color: '#003087'
                          }
                        }}
                        onChange={(e) => SetTricks(e.target.checked)}
                      />
                    }
                    label={
                      <Typography className="check-box-text text-blue">
                        Tricks
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography className="header-blue-22">
                Vaccination Details
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              className="form-datepicker-container"
            >
              {/* <TextField
                fullWidth
                name="Rabbis Expiration"
                autocomplete="Rabbis Expiration"
                label="RABIES EXP DATE"
                placeholder="RABIES EXPIRATION"
                type="date"
                value={rabiesExpiration}
                onChange={(e) => SetRabiesExpiration(e.target.value)}
                className="input-field-styling required-fiel"
                InputLabelProps={{
                  className: 'label-style-create-franchisee required-labe',
                  shrink: 'true'
                }}
                InputProps={{ min: currentDate }}
              /> */}
              {/* <CustomDatePicker
                label="RABIES EXPIRATION"
                onChange={SetRabiesExpiration}
                minDate={currentDate}
              /> */}
              <CustomDatePickerForAllBrowser
                label={'RABIES EXPIRATION'}
                onChange={SetRabiesExpiration}
                minDate={currentDate}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              className="form-datepicker-container"
            >
              {/* <TextField
                fullWidth
                name="Bordetella Expiration"
                autocomplete="Bordetella Expiration"
                label="BORDETELLA EXP DATE"
                placeholder=" EXPIRATION"
                type="date"
                value={bordetellaExpiration}
                onChange={(e) => SetBordetellaExpiration(e.target.value)}
                className="input-field-styling required-fiel"
                InputLabelProps={{
                  className: 'label-style-create-franchisee required-labe',
                  shrink: 'true'
                }}
                InputProps={{ min: currentDate }}
              /> */}
              {/* <CustomDatePicker
                label="BORDETELLA EXPIRATION"
                onChange={SetBordetellaExpiration}
                minDate={currentDate}
              /> */}
              <CustomDatePickerForAllBrowser
                label={'BORDETELLA EXPIRATION'}
                onChange={SetBordetellaExpiration}
                minDate={currentDate}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              className="form-datepicker-container"
            >
              {/* <TextField
                fullWidth
                name="DHPP Expiration"
                autocomplete="DHPP Expiration"
                label="COMBO SHOT EXP DATE"
                placeholder="COMBO SHOT EXP DATE"
                value={dhppExpiration}
                type="date"
                onChange={(e) => SetDhppExpiration(e.target.value)}
                className="input-field-styling required-fiel"
                InputLabelProps={{
                  className: 'label-style-create-franchisee required-labe',
                  shrink: 'true'
                }}
                InputProps={{ min: currentDate }}
              /> */}
              {/* <CustomDatePicker
                label="COMBO SHOT EXP DATE"
                onChange={SetDhppExpiration}
                minDate={currentDate}
              /> */}
              <CustomDatePickerForAllBrowser
                label={'COMBO SHOT EXP DATE'}
                onChange={SetDhppExpiration}
                minDate={currentDate}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={4} md={4}>
              <Box
                className="Profile-parent"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <label
                  htmlFor="select-child-profile"
                  className="label-select"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  ATTACH DOCUMENT
                </label>
                <input
                  type="file"
                  accept=".pdf, .doc, .heif, .hevc"
                  multiple
                  hidden
                  id="select-child-profile"
                  // onChange={handleChildFileUpload}
                  // id="select-product-add-file"
                  onChange={(event) => handleFileInput(event)}
                />
              </Box>
              <i className="text-blue headerTopCloseIcon p-5">
                {multipleFile.length} of 5 files uploaded
              </i>
            </Grid>

            {/* <Grid item xs={12} sm={8} md={8}>
              <Box className="card-image-carousel m-0">
                {uploadData && uploadData.length > 0 && uploadData.map((file, index) => (
                  <Box className="single-uploaded-doc" key={index}>
                    <Box className='word-break'>{file.name}</Box>
                    <Box
                      className="remove-image text-blue"
                      onClick={() => removeImage(file.size)}
                    >
                      x
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid> */}
            {/* {isUploadError && (
              <FormHelperText className="error-message">
                <Box>{rejectData}</Box>
                <Box>{uploadErrorMessage}</Box>
              </FormHelperText>
            )} */}
            <Grid item xs={12} sm={12} md={12}>
              <Box className="card-image-carousel m-0">
                {/* Render the uploaded images */}
                {multipleFile.map((file, index) => (
                  <div
                    className="single-uploaded-image single-uploaded-files"
                    key={file.id}
                  >
                    <Box className="word-break" id={index}>
                      {file.name}
                    </Box>
                    <Box
                      className="remove-imag remove-file text-blue"
                      onClick={() => removeFile(file.id)}
                    >
                      x
                    </Box>
                  </div>
                ))}
              </Box>
              <Box sx={{ float: 'left', clear: 'both' }}>
                {/* <i>{multipleFile.length} of 5 files uploaded</i> */}
                {imageError && (
                  <FormHelperText className="error-message">
                    {imageError}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            {showError && <Box className="error-message">{errorMessage}</Box>}
          </Grid>
        </Box>
      </Box>

      {/* Confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Congratulations!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Dog has been added successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            Close
          </Button>
          {/* {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Continue
            </Button>
          )} */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseConfirmation('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes. 
                <Typography className="title-text-sub">What do you want to do?</Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirmation('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleCloseConfirmation('Save')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddAnotherPet;
