import React from "react";
import { Box, Typography } from "@mui/material";
import { DynamicButton } from "../../../components/Checkout";
// import { getLocalData, setFullLocationId } from '../../../../utils';


export const ReturnSuccess = ({ setActive }) => {
  // const locationId = setFullLocationId(getLocalData('locationId'));

  return (
    <>
      <Box className="orderSuccess">
        <Typography
          variant="h1"
          align="center"
          color="primary"
          style={{ marginBottom: "15px" }}
        >
          Return Successful
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{
            maxWidth: "369px",
            fontWeight: "400",
            fontSize: "16px",
            color: "#000000",
            margin: "0 auto 35px"
          }}
        >
          Congratulations! Your return has been successfully processed. Thank you for your purchase.
        </Typography>
        <Box
          style={{
            maxWidth: "330px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 auto"
          }}
        >
          <DynamicButton
            title={"Print Receipt"}
            variant="contained"
            onClick={() => window.print()}
            style={{
              padding: "10px 23px",
              borderRadius: "10px",
              textTransform: "capitalize",
              maxWidth: "max-content",
              fontWeight: "600",
              fontSize: "16px",
              margin: "0 auto 24px",
              border: "2px solid #003087"
            }}
          />
          <DynamicButton
            title={"Email Receipt"}
            variant="contained"
            // onClick={handleNotification}
            style={{
              padding: "10px 23px",
              borderRadius: "10px",
              textTransform: "capitalize",
              maxWidth: "max-content",
              fontWeight: "600",
              fontSize: "16px",
              margin: "0 auto 24px",
              background: "#fff",
              color: "#003087",
              border: "2px solid #003087"
            }}
          />
        </Box>
        <DynamicButton
          title={"Back to Sales History"}
          onClick={() => setActive(0)}
          variant="text"
          style={{
            padding: "0",
            maxWidth: "max-content",
            background: "transparent",
            textDecoration: "underline",
            fontWeight: "600",
            fontSize: "15px",
            margin: "0 auto",
            display: "block"
          }}
        />
      </Box>

    </>
  );
};
